import React, { useContext, useState } from 'react';
import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@castiron/components';
import PlansDisplay from './PlanComponents/PlansDisplay';
import SelectPaymentFrequency from './PlanComponents/SelectPaymentFrequency';
import SubscriptionPayment from './PlanComponents/SubscriptionPayment';
import PaymentSuccess from './PlanComponents/PaymentSuccess'
import CancelPlan from './PlanComponents/CancelPlan';
import { PlanContext, PlanContextData } from './PlanContext';
import { Plan, Price } from "@castiron/domain";
import { useAppDispatch, useAppSelector } from '../../hooks';
import { openModal } from '../../store/reducers/modalConductor';

export type SelectedSubscription = {
  plan?: Plan;
  price?: Price;
  takeRate?: number;
}

export type Props = {
  onSuccess?: () => void;
  setHeader?: (header: string) => void;
  selectedSub?: SelectedSubscription;
  couponCode?: string;
  context?: 'freeCustomDomainModal';
};

const useStyles = makeStyles((theme: Theme) => ({
  successEmoji: {
    marginBottom: 32,
    '& p': {
      fontSize: 63,
    },
  },
  successHeader: {
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '28px',
    marginTop: '16px 0',
  },
  successText: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
  },
  successWrapper: {
    marginTop: 8,
    maxWidth: '60%',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%',
    },
  },
}));

export type NextStep = (curStep: number, sub?: SelectedSubscription) => void;

const PlanFlow: React.FC<Props> = (props: Props) => {
  const { onSuccess, setHeader, selectedSub, couponCode, context } = props;
  const classes = useStyles();
  const { step, setStep } = useContext<PlanContextData>(PlanContext);

  const [successMessage, setSuccessMessage] = useState(null);
  const [planSelection, setPlanSelection] = useState<SelectedSubscription>(selectedSub);
  const rerouteToFreeCustomDomainModal = context === 'freeCustomDomainModal' && planSelection?.price?.frequency === 'yearly';
  const dispatch = useAppDispatch();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const openFreeCustomDomainModal = () => {
    dispatch(
      openModal({
        modalType: 'FREE_CUSTOM_DOMAIN_MODAL',
        modalProps: {
          show: true,
        },
      }),
    );
  };

  const successMessages = {
    'payment-success': (
      <Grid
        container
        spacing={2}
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.successWrapper}
      >
        <div className={classes.successEmoji}>
          <Typography variant="body1">🎉</Typography>
        </div>
        <Typography variant="h6" className={classes.successHeader}>
          Your Subscription Has Been Updated!
        </Typography>
        <Typography variant="body1" className={classes.successText}>
          You’re all set with {planSelection?.plan?.name}. <span style={{ fontWeight: 700 }}>Please note:</span> It can
          take up to a minute for changes to reflect on your account.
        </Typography>
      </Grid>
    ),
    cancel: (
      <Typography variant="body1">
        You’ve successfully switched to the <b>Starter Plan</b>.
      </Typography>
    ),
  };

  const nextStep: NextStep = (curStep, plan) => {
    plan !== planSelection && setPlanSelection(plan)
    switch (curStep) {
      case 2:
        setSuccessMessage(successMessages['payment-success']);
        setHeader('Success');
        break;
      case 4:
        setSuccessMessage(successMessages['cancel']);
        break;
    }
    setStep(step === 4 ? 3 : curStep + 1);
  }

  return (
    <>
      {step === 0 && <PlansDisplay nextStep={nextStep} />}
      {step === 1 && (
        <SelectPaymentFrequency
          nextStep={nextStep}
          selectedSub={planSelection}
          setModalHeader={setHeader}
          couponCode={couponCode}
        />
      )}
      {step === 2 && <SubscriptionPayment nextStep={nextStep} selectedSub={planSelection} couponCode={couponCode} />}
      {step === 3 && (
        <PaymentSuccess
          message={successMessage}
          onSuccess={rerouteToFreeCustomDomainModal ? openFreeCustomDomainModal : onSuccess}
          successCtaText={rerouteToFreeCustomDomainModal && 'Claim Free Domain'}
        />
      )}
      {step === 4 && <CancelPlan nextStep={nextStep} />}
    </>
  );
};

export default PlanFlow;
