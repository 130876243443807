import React, { ReactElement, useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import * as yup from 'yup';
import { Field, Formik, FormikProps } from 'formik';
import Dinero from 'dinero.js';
import { Grid, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { openModal } from '../../../store/reducers/modalConductor';
import { updateChecklistAction } from '../../../store/reducers/shops';
import { Banner, ButtonV2, Dropdown, Pill, Typography, DiscardButton, SaveButton } from '@castiron/components';
import { ChecklistValues, Coupon } from '@castiron/domain';
import { couponRepository, marketingSendRepository, sendLogRepository, transactionRepository } from '../../../domain';
import { MessageType, SubscriberCouponMarketingSend } from '@castiron/domain';
import { useHistory } from 'react-router-dom';
import { useTracking } from '@castiron/utils';
import { LayoutPageProps } from '../../Layout';
import ButtonGroup from '../../ButtonGroup';
import Spinner from '../../Spinner';
import IconWithLines from './IconWithLines';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import WatchLaterOutlinedIcon from '@material-ui/icons/WatchLaterOutlined';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import { getService } from '../../../firebase';
import { getWelcomeSeriesStats } from '../Automations';

const sendPreviewEmailService = getService('messaging', 'sendpreviewemail');

export type FormValues = {
  status: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  containerGrey: {
    borderRadius: 12,
    background: `${theme.branding.v2.gray[50]}`,
    padding: 16,
  },
  containerWhite: {
    borderRadius: 12,
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    background: `${theme.branding.v2.gray[0]}`,
    padding: 16,
  },
  menuContainer: {
    color: theme.branding.v2.gray[500],
    '&:hover': {
      color: theme.branding.v2.gray[800],
    },
    position: 'absolute',
    top: '8px',
    right: '8px',
    height: '40px',
    width: '40px',
  },
  section: {
    padding: '12px 0px',
  },
}));

const EditWelcomeSeries: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { trackEvent } = useTracking();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [pageRefresh, setPageRefresh] = useState<boolean>(false);
  const [welcomeSeriesLoading, setWelcomeSeriesLoading] = useState<boolean>(true);
  const [welcomeCoupon, setWelcomeCoupon] = useState<Coupon | null>(null);
  const [welcomeStats, setWelcomeStats] = useState(null);
  const [lastSent, setLastSent] = useState<number>(null);
  const formikRef = useRef<FormikProps<any>>();

  const { account, shop } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
  }));

  const sendTestEmails = async () => {
    try {
      await sendPreviewEmailService({ to: shop.email, marketingSendId: 'welcome-series', shopId: shop.id });
      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT_V2',
          modalProps: {
            show: true,
            title: 'Success!',
            content: 'Test emails sent successfully!',
            buttonText: 'Done',
          },
        }),
      );
    } catch (err) {
      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT',
          modalProps: {
            show: true,
            content: 'Error sending test emails.',
          },
        }),
      );
    }
  };

  const mobileMenu = () => (
    <Paper className={classes.menuContainer}>
      <Grid container style={{ height: '100%' }} justify="center" alignItems="center">
        <Grid item>
          <Dropdown
            variant="ellipsis"
            options={[
              {
                label: 'Send Myself Test Emails',
                onClick: () => {
                  dispatch(
                    openModal({
                      modalType: 'SIMPLE_ACTION_ALERT',
                      modalProps: {
                        show: true,
                        title: 'Send Test Emails',
                        content: (
                          <Typography variant="body1" style={{ textAlign: 'center' }}>
                            3 consecutive emails will be sent to <b>{shop.email}</b>
                          </Typography>
                        ),
                        buttonText: 'Send Test Emails',
                        actionText: 'Sending',
                        color: 'default',
                        handleAction: sendTestEmails,
                      },
                    }),
                  );
                },
              },
              // {
              //   label: `${welcomeSeriesMarketingSend?.status === 'active' ? 'Turn Off' : 'Turn On'}`,
              //   color: welcomeSeriesMarketingSend?.status === 'active' ? 'error' : null,
              //   onClick:
              //     welcomeSeriesMarketingSend?.status === 'active'
              //       ? () => {
              //           dispatch(
              //             openModal({
              //               modalType: 'SIMPLE_ACTION_ALERT',
              //               modalProps: {
              //                 show: true,
              //                 title: 'Render Inactive?',
              //                 content: 'The automation will stop sending to new subscribers.',
              //                 buttonText: 'Turn Offer Off',
              //                 color: 'red',
              //                 handleAction: () => {
              //                   formikRef?.current?.setFieldValue('status', 'inactive');
              //                   formikRef?.current?.handleSubmit();
              //                 },
              //               },
              //             }),
              //           );
              //         }
              //       : () => {
              //           formikRef?.current?.setFieldValue('status', 'active');
              //           formikRef?.current?.handleSubmit();
              //         },
              // },
            ]}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          />
        </Grid>
      </Grid>
    </Paper>
  );

  useEffect(() => {
    setPageTitle('Welcome Series');
    setBackLocation('/marketing/automations');
    setHeaderCTAs(
      isMobile
        ? [mobileMenu()]
        : [
            <ButtonV2
              variant="outlined"
              onClick={() =>
                dispatch(
                  openModal({
                    modalType: 'SIMPLE_ACTION_ALERT',
                    modalProps: {
                      show: true,
                      title: 'Send Test Emails',
                      content: (
                        <Typography variant="body1" style={{ textAlign: 'center' }}>
                          3 consecutive emails will be sent to <b>{shop.email}</b>
                        </Typography>
                      ),
                      buttonText: 'Send Test Emails',
                      color: 'default',
                      handleAction: sendTestEmails,
                    },
                  }),
                )
              }
            >
              Send Test Emails
            </ButtonV2>,
          ],
    );
    setFooterCTAs([
      // <DiscardButton
      //   isSubmitting={submitLoading}
      //   customText="Cancel"
      //   onClick={() => history.push('/marketing/automations')}
      // />,
      // <SaveButton
      //   handleSubmit={() => {
      //     window.scrollTo(0, 0);
      //     formikRef?.current?.handleSubmit();
      //   }}
      //   isSubmitting={submitLoading}
      // />,
    ]);

    return () => {
      setPageTitle('');
      setBackLocation(false);
    };
  }, [isMobile]);

  const getTotalSends = async (sendType: MessageType) => {
    const sends = await Promise.resolve(sendLogRepository.findSendsByType(shop.id, sendType));
    if (sends.length > 0 && sendType === 'welcome_series') {
      setLastSent(sends[0]?.createdAt);
    }
    const total = sendType === 'welcome_series' ? _.uniqBy(sends, 'customerId')?.length : sends?.length;

    return total;
  };

  const getTotalRevenueBySend = async (utmSource: string, findBy: 'source' | 'campaign') => {
    const transactions = await Promise.resolve(transactionRepository.findTransactionsByUtm(shop.id, utmSource, findBy));

    const totals = transactions.map(t => t.totals.totalWithTax);
    const revenue = !_.isEmpty(totals) ? totals.reduce((acc, curr) => acc + curr, 0) : 0;

    return revenue;
  };

  useEffect(() => {
    const getWelcomeSeriesMarketingSend = async () => {
      setWelcomeSeriesLoading(true);
      const couponId = shop?.config?.marketing?.welcomeSeriesCoupon?.couponId;

      const welcomeSeriesStats = await getWelcomeSeriesStats(shop.id);
      setWelcomeStats({
        totalSends: welcomeSeriesStats?.totalSends,
        totalRevenue: welcomeSeriesStats?.totalRevenue,
      });

      if (couponId) {
        const getCouponResponse = await couponRepository.get(couponId);
        setWelcomeCoupon(getCouponResponse);
      } else {
        const subscriberMarketingSendResponse = await marketingSendRepository.findActiveOfTypeForShop(
          shop.id,
          'subscriber_coupon',
        );
        if (!!subscriberMarketingSendResponse.length) {
          const marketingSend = subscriberMarketingSendResponse[0] as SubscriberCouponMarketingSend;
          const getCouponResponse = await couponRepository.get(marketingSend?.couponId);
          setWelcomeCoupon(getCouponResponse);
        }
      }
      setWelcomeSeriesLoading(false);
    };

    if (shop?.id) {
      getWelcomeSeriesMarketingSend();
    }
  }, [shop]);

  useEffect(() => {
    if (pageRefresh) {
      window.location.reload();
    }
  }, [pageRefresh]);

  const onSubmit = async (values: FormValues) => {
    // setSubmitLoading(true);
    // await marketingSendRepository.updateProps(welcomeSeriesMarketingSend.id, {
    //   status: values.status,
    // });
    // trackEvent('Welcome Series Updated', { welcomeSeriesMarketingSend: marketingSendRepository });
    // setSubmitLoading(false);
    // dispatch(
    //   openModal({
    //     modalType: 'SIMPLE_ALERT_V2',
    //     modalProps: {
    //       show: true,
    //       title: 'Success!',
    //       content: 'The Automation has been Updated.',
    //       buttonText: 'Done',
    //       handleAction: () => setPageRefresh(true),
    //     },
    //   }),
    // );
  };

  const formSchema = yup.object().shape({
    status: yup.string().required('Active value required'),
  });

  const getCouponValue = (coupon: Coupon): string => {
    return `${
      coupon.discount.type === 'amount'
        ? `${Dinero({ amount: coupon.discount.value }).toFormat('$0.00')} Off`
        : `${coupon.discount.value}% Off`
    }`;
  };

  const handleAddCoupon = () => {
    dispatch(
      openModal({
        modalType: 'MANAGE_COUPON_MODAL',
        modalProps: {
          couponId: welcomeCoupon?.id || '',
          variant: 'welcome-series',
          setPageRefresh: setPageRefresh,
        },
      }),
    );
  };

  const Wait2Days = () => (
    <Grid container xs={12} justify="flex-start" alignItems="center" style={{ margin: '16px 0px' }}>
      <Grid item style={{ marginRight: 5 }}>
        <IconWithLines
          icon={<WatchLaterOutlinedIcon style={{ color: theme.branding.v2.gray[400] }} />}
          lineHeight="20px"
          offset="-16px"
        />
      </Grid>
      <Grid item>
        <Typography variant="body2">Wait 2 Days</Typography>
      </Grid>
    </Grid>
  );

  return (
    <>
      {welcomeSeriesLoading || submitLoading ? (
        <Grid justify="center" container>
          <Spinner show={true} size={'relative'} />
        </Grid>
      ) : (
        <Formik
          validateOnMount
          onSubmit={onSubmit}
          validationSchema={formSchema}
          innerRef={formikRef}
          initialValues={{
            status: 'active',
          }}
        >
          {({ errors, setFieldValue, touched, values }): ReactElement => (
            <Grid container xs={12} style={{ padding: isMobile ? 16 : 0 }}>
              <Grid container item sm={12} md={7} style={{ padding: isMobile ? 0 : '0px 16px' }}>
                <Typography variant="subtitle1">Automated Emails</Typography>
                <Grid container className={classes.containerGrey}>
                  <Grid container xs={12} justify="flex-start" alignItems="center" style={{ margin: '16px 0px' }}>
                    <Grid item style={{ marginRight: 5 }}>
                      <IconWithLines
                        showTopLine={false}
                        icon={<FiberManualRecordIcon style={{ color: theme.branding.v2.gray[400] }} />}
                        lineHeight="22px"
                        offset="-16px"
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">Immediately after a new subscription</Typography>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.containerWhite} xs={12} justify="flex-start" alignItems="center">
                    <Grid item xs={12}>
                      <Typography variant="caption">EMAIL 1/3</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">Welcome offer (contains discount).</Typography>
                    </Grid>
                    {welcomeCoupon ? (
                      <>
                        <Grid item xs={12} className={classes.section}>
                          <Grid
                            container
                            justify="center"
                            alignItems="center"
                            style={{
                              border: `2px dashed ${theme.branding.v2.gray[300]}`,
                              color: theme.branding.v2.gray[300],
                              padding: '10px 0px',
                            }}
                          >
                            <ConfirmationNumberOutlinedIcon style={{ marginRight: 5 }} />
                            <Typography style={{ color: theme.branding.v2.gray[800] }}>{`${getCouponValue(
                              welcomeCoupon,
                            )} Coupon`}</Typography>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={12} className={classes.section}>
                          <Banner variant="info-blue">
                            Studies show 86% of customers are more likely to buy when the marketing includes a special
                            offer.
                          </Banner>
                        </Grid>
                        <Grid item xs={12} className={classes.section}>
                          <ButtonV2 variant="contained" fullWidth onClick={handleAddCoupon}>
                            + Add a Coupon
                          </ButtonV2>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Wait2Days />
                  <Grid container className={classes.containerWhite} xs={12} justify="flex-start" alignItems="center">
                    <Grid item xs={12}>
                      <Typography variant="caption">EMAIL 2/3</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">Invite shoppers to follow on social media.</Typography>
                    </Grid>
                  </Grid>
                  <Wait2Days />
                  <Grid container className={classes.containerWhite} xs={12} justify="flex-start" alignItems="center">
                    <Grid item xs={12}>
                      <Typography variant="caption">EMAIL 3/3</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">Introduce shoppers to your best selling items. </Typography>
                    </Grid>
                  </Grid>
                  <Grid container xs={12} justify="flex-start" alignItems="center" style={{ margin: '16px 0px' }}>
                    <Grid item style={{ marginRight: 5 }}>
                      <IconWithLines
                        showBottomLine={false}
                        icon={<FiberManualRecordIcon style={{ color: theme.branding.v2.gray[400] }} />}
                        lineHeight="22px"
                        offset="-16px"
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">End</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item sm={12} md={5} spacing={2} style={{ padding: '0px 16px' }}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Description</Typography>
                  <Typography variant="body1">Welcome new subscribers to your shop.</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Audience</Typography>
                  <Typography variant="body1">New email subscribers</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Timing</Typography>
                  <Typography variant="body1">Ongoing</Typography>
                  {lastSent && (
                    <Typography variant="body2" style={{ color: theme.branding.v2.gray[500] }}>
                      {`Last Sent ${moment.unix(lastSent).format('MMMM D, YYYY')}`}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Grid container justify="space-between">
                    <Typography variant="subtitle1">Coupon</Typography>
                    <ButtonV2 onClick={handleAddCoupon} style={{ padding: 0 }}>
                      {welcomeCoupon ? 'Manage' : '+ Add'}
                    </ButtonV2>
                  </Grid>
                  {welcomeCoupon ? (
                    <Pill content={getCouponValue(welcomeCoupon)} variant="gray" />
                  ) : (
                    <Typography variant="body1">None</Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Status</Typography>
                  <Field
                    required
                    as={ButtonGroup}
                    error={touched.status && errors.status}
                    disabled={true}
                    name="status"
                    value={values.status}
                    onChange={(value): void => {
                      switch (value) {
                        case 'active':
                          setFieldValue('status', 'active');
                          break;
                        case 'inactive':
                          setFieldValue('status', 'inactive');
                          break;
                      }
                    }}
                    buttons={['active', 'inactive']}
                  />
                  <Typography variant="body2">
                    In order for new subscribers to receive subscription confirmation, this automation must remain
                    active and cannot be turned off. Remove or manage the associated coupon in Email 1 to customize this
                    experience.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Activity</Typography>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="body2">Sent</Typography>
                      <Typography variant="body1">{welcomeStats?.totalSends || 0}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">Revenue</Typography>
                      <Typography variant="body1">
                        {typeof welcomeStats?.totalRevenue === 'number'
                          ? Dinero({ amount: welcomeStats?.totalRevenue }).toFormat('$0,0.00')
                          : welcomeStats?.totalRevenue}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Formik>
      )}
    </>
  );
};

export default EditWelcomeSeries;
