import { BaseProduct, Product, TicketedEvent } from '@castiron/domain';
import { Theme, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Typography from '../Typography';
import momentTimezone from 'moment-timezone';

interface Props {
  product: BaseProduct;
  timeZone?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  soldOutBanner: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    top: 15,
    backgroundColor: theme.branding.gray[800],
    width: 87,
    height: 31,
  },
  soldOutBannerFont: {
    color: theme.branding.gray[200],
  },
}));

const SoldOutBanner: React.FC<Props> = (props: Props) => {
  const { product, timeZone } = props;

  const classes = useStyles();
  
  if (product?.type === 'event') {
    const nowUnix = momentTimezone()
      .tz(timeZone)
      .unix();
    const event = product as TicketedEvent;
    const isPastEvent = event?.eventDetails?.date?.startTime < nowUnix;
    if (isPastEvent) {
      return (
        <div className={classes.soldOutBanner}>
          <Typography variant="body1" className={classes.soldOutBannerFont}>
            Past Event
          </Typography>
        </div>
      );
    }
  }

  return (product.type !== 'custom' && !product.unlimitedInventory && !(product as Product).inventory && (
    <div className={classes.soldOutBanner}>
      <Typography variant="body1" className={classes.soldOutBannerFont}>
        Sold Out
      </Typography>
    </div>
  ));
};

export default SoldOutBanner;
