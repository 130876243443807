import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import React from 'react';
import { ButtonV2, StoreIcon } from '@castiron/components';
import { toQueryString, useTracking } from '@castiron/utils';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { closeModal } from '../../store/reducers/modalConductor';
import { VisibilityOutlined } from '@material-ui/icons';
import { createOpenShopButtonClickedEvent } from '../../lib/events/commonEvents';
import { nanoid } from '@reduxjs/toolkit';
import { getShopAction } from '../../store/reducers/shops';

type Props = {
  show: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  launchDialogCloseButton: {
    position: 'absolute',
    top: '24px',
    right: '24px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const ShopLiveModal: React.FC<Props> = (props: Props) => {
  const { show } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { trackEvent } = useTracking();

  const { me, shop } = useAppSelector(state => ({
    me: state.users.me,
    shop: state.shops.shop,
  }));

  const handleClose = async () => {
    await dispatch(getShopAction(shop.id));
    dispatch(closeModal());
  };

  const handleViewShop = () => {
    trackEvent('Open Shop Button Clicked', createOpenShopButtonClickedEvent(shop, me));
    const cacheBypass = nanoid();
    let queryParams = {
      [cacheBypass]: true,
    };
    if (shop.status === 'prelaunch') {
      queryParams = { ...queryParams, previewMode: true };
    }
    window.open(`${process.env.REACT_APP_SHOP_URL}${shop?.websiteUrl}?${toQueryString(queryParams)}`, '_blank');
  };

  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle style={{ paddingTop: '65px' }}>
        <Close className={classes.launchDialogCloseButton} onClick={handleClose} />
        <Grid container direction="column" alignItems="center" spacing={3}>
          <Grid item>
            <StoreIcon style={{ color: theme.branding.blue.primary }} />
          </Grid>
          <Grid item>
            <Typography variant="h2">Congrats! Your Shop is Live!</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" align="center">
          View your shop and copy the link to share it on Facebook, Twitter, Instagram, and anywhere you have an
          audience to being receiving sales.
        </Typography>
      </DialogContent>
      <DialogActions style={{ padding: '40px 24px 32px' }}>
        <ButtonV2 variant="outlined" onClick={handleViewShop}>
          <VisibilityOutlined />
          &nbsp;&nbsp;View Shop
        </ButtonV2>
      </DialogActions>
    </Dialog>
  );
};

export default ShopLiveModal;
