import { Banner, ButtonV2, ChipSelectInput, Typography } from '@castiron/components';
import { BaseProduct, getProductStatus, holidays, occasions, specialToTag } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { Grid } from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { Formik } from 'formik';
import _ from 'lodash';
import React, { useState } from 'react';
import * as yup from 'yup';
import { productRepository, shopRepository, specialRepository } from '../../domain';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { closeModal, openModal } from '../../store/reducers/modalConductor';
import { getProductsAction } from '../../store/reducers/products';
import { getShopAction } from '../../store/reducers/shops';
import AdminForm from '../AdminForm';
import GeneralModal from '../RootModal/GeneralModal';
import ProductStatus from './EditProduct/FormComponents/ProductStatus';

export type Props = {
  context: 'eventTags' | 'status';
  onSuccess?: () => void;
  products: BaseProduct[];
};

const useStyles = makeStyles((theme: Theme) => ({
  marketplaceLink: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: theme.branding.v2.blue[500],
  },
  paperClass: {
    [theme.breakpoints.up('sm')]: {
      height: 'fit-content',
      maxHeight: 624,
    },
  },
}));

const BulkProductActionsModal: React.FC<Props> = (props: Props) => {
  const { context, onSuccess, products } = props;

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { trackEvent } = useTracking();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const productTypeName = products?.some(p => p.type === 'event') ? 'event' : 'product';

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const validationSchema = yup.object().shape(
    context === 'status'
      ? {
          status: yup.string().oneOf(['active', 'inactive']),
          schedule: yup
            .object({
              startTime: yup.number().nullable(),
              endTime: yup
                .number()
                .nullable()
                .moreThan(yup.ref('startTime'), 'End time must be after start time'),
            })
            .nullable(),
        }
      : {
          holidays: yup.array().of(yup.string()),
          occasions: yup.array().of(yup.string()),
        },
  );

  const initialValues =
    context === 'status'
      ? {
          status: 'active',
          schedule: {},
        }
      : {
          holidays: [],
          occasions: [],
        };

  const handleSubmit = async values => {
    try {
      setIsSubmitting(true);
      const activeShopEvents = await specialRepository.findActiveShopEvents(shop?.config?.timeZone);
      const activeShopEventTags = activeShopEvents?.map(e => e.tag) || [];
      let eventPagesToActivate = [];
      if (context === 'status') {
        trackEvent('Bulk Product Action', { action: 'Status Update' });
        await Promise.all(
          products.map(async product => {
            const productStatus = getProductStatus(
              {
                ...product,
                status: values.status,
                schedule: values.schedule,
              },
              shop?.config?.timeZone,
            );
            const productShopEventTags = _.intersection(product?.eventTags || [],  activeShopEventTags);
            if (productShopEventTags.length > 0 && productStatus === 'active') {
              eventPagesToActivate = _.uniq([...productShopEventTags, ...eventPagesToActivate]);
            }
            await productRepository.updateProps(product.id, {
              status: values.status,
              schedule: values.schedule,
            });
          }),
        );
        await dispatch(getProductsAction(shop.id));
        dispatch(
          openModal({
            modalType: 'SIMPLE_ALERT',
            modalProps: {
              show: true,
              celebrate: true,
              content: <>{_.capitalize(productTypeName)} statuses updated.</>,
            },
          }),
        );
        onSuccess && onSuccess();
      } else {
        trackEvent('Bulk Product Action', { action: 'Event Tags Update' });
        const hTags = values.holidays.map(h => specialToTag(h));
        const oTags = values.occasions.map(o => specialToTag(o));
        await Promise.all(
          products.map(async product => {
            const productStatus = getProductStatus(product, shop?.config?.timeZone);
            const cleanEventTags = _.uniq([...hTags, ...oTags, ...(product?.eventTags || [])]);
            const productShopEventTags = _.intersection(cleanEventTags,  activeShopEventTags);
            if (productShopEventTags.length > 0 && productStatus === 'active') {
              eventPagesToActivate = _.uniq([...productShopEventTags, ...eventPagesToActivate]);
            }
            await productRepository.updateProps(product.id, {
              eventTags: cleanEventTags,
            });
          }),
        );
        await dispatch(getProductsAction(shop.id));
        dispatch(
          openModal({
            modalType: 'SIMPLE_ALERT',
            modalProps: {
              show: true,
              celebrate: true,
              content: <>Holidays & occasions updated.</>,
            },
          }),
        );
        onSuccess && onSuccess();
      }

      let dataUpdated = false;
      let newEvents = shop?.shopSubpageData?.events ? _.cloneDeep(shop?.shopSubpageData?.events) : [];
      eventPagesToActivate.forEach(eventTag => {
        const currentEventPage = newEvents?.find(event => event.tag === eventTag);
        if (!currentEventPage?.enabled || !currentEventPage?.showPopup) {
          dataUpdated = true;
          const content = activeShopEvents?.find(e => e.tag === eventTag)?.shopContent?.page;
          const newEventPage = {
            tag: eventTag,
            enabled: true,
            headline: currentEventPage?.headline || content?.bannerHeadline,
            description: currentEventPage?.description || content?.bannerDescription,
            showPopup: true,
          };
          if (currentEventPage) {
            newEvents = newEvents?.map(event => (event?.tag === eventTag ? newEventPage : event));
          } else {
            newEvents.push(newEventPage);
          }
        }
      });

      if (dataUpdated) {
        await shopRepository.updateProps(shop.id, {
          'shopSubpageData.events': newEvents,
        });
      }
        
      await dispatch(getShopAction(shop.id));

      setIsSubmitting(false);
    } catch (err) {
      console.log(err);
      setIsSubmitting(false);
    }
  };

  return (
    <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values }) => (
        <AdminForm style={{ width: '100%' }}>
          <GeneralModal
            paperClass={classes.paperClass}
            header={
              context === 'status' ? `Update ${_.capitalize(productTypeName)} Status` : 'Add Holidays & Occasions'
            }
            content={
              <Grid container item direction="column" wrap="nowrap" style={{ gap: 16, textAlign: 'left' }}>
                {context === 'status' ? (
                  <>
                    <Banner variant="info-blue" backgroundColor={theme.branding.v2.gray[50]}>
                      <Typography variant="body2">
                        Applying these changes will update all selected {productTypeName} statuses. This may hide{' '}
                        {productTypeName}s from your current view based on your filters.
                      </Typography>
                    </Banner>
                    <ProductStatus context="bulkProductActions" />
                  </>
                ) : (
                  <>
                    <Typography variant="body2">
                      Help customers find your {productTypeName}s on the{' '}
                      <span
                        onClick={() => window.open('https://shopcastiron.com/', '_blank')}
                        className={classes.marketplaceLink}
                      >
                        Castiron Marketplace
                      </span>
                      . Holidays or occasions selected below will be added to any existing holidays or occasions already
                      associated to your selected {productTypeName}s.
                    </Typography>
                    <ChipSelectInput
                      label="Holidays"
                      secondaryLabel={`Select all major holidays this ${productTypeName} caters to, such as Halloween.`}
                      name="holidays"
                      options={holidays}
                      value={values.holidays}
                    />
                    <ChipSelectInput
                      label="Occasions"
                      secondaryLabel={`Select all ongoing occasions this ${productTypeName} caters to, such as Weddings and Birthdays.`}
                      name="occasions"
                      options={occasions}
                      value={values.occasions}
                    />
                  </>
                )}
              </Grid>
            }
            actions={[
              <ButtonV2 variant="outlined" onClick={handleClose} disabled={isSubmitting}>
                Cancel
              </ButtonV2>,
              <ButtonV2
                variant="contained"
                type="submit"
                loading={isSubmitting}
                disabled={
                  isSubmitting ||
                  _.isEmpty(products) ||
                  (context === 'eventTags' && _.isEmpty(values.holidays) && _.isEmpty(values.occasions))
                }
                onClick={() => handleSubmit(values)}
              >
                Apply
              </ButtonV2>,
            ]}
            onClose={handleClose}
            show={true}
          />
        </AdminForm>
      )}
    </Formik>
  );
};

export default BulkProductActionsModal;
