import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack, ArrowDownward, ArrowForward, ArrowUpward } from '@material-ui/icons';
import React, {ReactNode} from 'react';

type Props = {
  direction: 'up' | 'down' | 'left' | 'right';
  containerClass?: string;
  arrowClass?: string;
  backgroundColor?: string;
  arrowColor?: string;
  children?: ReactNode;
};

const useStyles = makeStyles((theme: Theme) => ({
  arrowIcon: {
    fontSize: 20,
  },
  arrowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    borderRadius: 8,
  },
  successBg: {
    backgroundColor: `${theme.branding.mint}0D`,
  },
  errorBg: {
    backgroundColor: `${theme.branding.error}0D`,
  },
  success: {
    color: theme.branding.mint,
  },
  error: {
    color: theme.branding.error,
  },
}));

const ArrowIcon: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { direction, containerClass, arrowClass, backgroundColor, arrowColor } = props;

  return (
    <div
      className={`${classes.arrowContainer} ${containerClass}`}
      style={backgroundColor ? { backgroundColor: backgroundColor } : {}}
    >
      {direction === 'up' && <ArrowUpward className={`${classes.arrowIcon} ${arrowClass}`} style={arrowColor ? { color: arrowColor } : {}} />}
      {direction === 'down' && <ArrowDownward className={`${classes.arrowIcon} ${arrowClass}`} style={arrowColor ? { color: arrowColor } : {}} />}
      {direction === 'left' && <ArrowBack className={`${classes.arrowIcon} ${arrowClass}`} style={arrowColor ? { color: arrowColor } : {}} />}
      {direction === 'right' && <ArrowForward className={`${classes.arrowIcon} ${arrowClass}`} style={arrowColor ? { color: arrowColor } : {}} />}
    </div>
  );
};

export default ArrowIcon;
