import moment from 'moment-timezone';
import { BaseProduct, CustomProduct, Product, ProductSearchResult } from '.';
import { Shop, FulfillmentOption } from '../shop';
import { Presale } from '../presale';
import { convertRegionName } from '../_lib/stateUtils';
import _ from 'lodash';

/* Also in utils/src/productUtils */
export const hasSchedule = (product): boolean =>
  product?.schedule?.startTime &&
  product?.schedule?.endTime &&
  product?.status !== 'deleted' &&
  product?.status !== 'hidden';

/* Also in utils/src/productUtils */
export const getProductStatus = (product, timeZone) => {
  const now = moment()
    .tz(timeZone || 'America/Chicago')
    .unix();
  let status = '';
  if (hasSchedule(product)) {
    const isScheduleActive = product?.schedule?.startTime <= now && now <= product?.schedule?.endTime;
    const isScheduleFuture = now < product?.schedule?.startTime;
    status = isScheduleActive ? 'active' : isScheduleFuture ? 'scheduled' : 'inactive';
  } else {
    status = product?.status;
  }
  return status;
};

export const getImageUrl = (
  item: BaseProduct | Presale | ProductSearchResult,
  size: 'smallVersion' | 'mediumVersion' | '',
) => {
  return (
    (item?.images?.length >= 1 &&
      ((!!size && item?.images[0]?.[size]?.downloadUrl) || item?.images[0]?.downloadUrl || '')) ||
    (!!size && (item as BaseProduct)?.imageObj?.[size]?.downloadUrl) ||
    (item as BaseProduct)?.imageObj?.downloadUrl ||
    ''
  );
};

export const getPresaleImageUrl = (presale: Presale, size: 'smallVersion' | 'mediumVersion' | '') => {
  let presaleImageUrl;
  const presaleCoverImageUrl = getImageUrl(presale, size);
  if (presaleCoverImageUrl) {
    presaleImageUrl = presaleCoverImageUrl;
  } else if (presale?.products?.length > 0) {
    for (const product of presale?.products) {
      const productImageUrl = getImageUrl(product, size);
      if (productImageUrl) {
        presaleImageUrl = productImageUrl;
        break;
      }
    }
  }
  return presaleImageUrl;
};

const kebabCase = str => (str.match(/[a-zA-Z0-9]+/g) || []).join('-').toLowerCase() || 'na';

const getAllTaxonomy = product => {
  if (product.taxonomy) {
    let all = [];
    product.taxonomy?.map(t => {
      all.push(t.top);
      all.push(t.mid);
      all.push(t.leaf);
    });
    return all;
  }
  return undefined;
};

export const productToGMC = async (product: Product | CustomProduct, shop: Shop, fulfillments: FulfillmentOption[]) => {
  const isNational = shop?.tags?.includes('National');

  const activeShopFulfillments = fulfillments.filter(f => f.status === 'active') || [];
  const shippingPrice = Math.max(...(activeShopFulfillments?.map(f => f.fee || 0) || []), 0);
  const shippingMinHandlingTime = Math.max(
    ...(activeShopFulfillments?.map(f =>
      f?.processingTime?.incrementType === 'week'
        ? f?.processingTime?.increments * 7
        : f?.processingTime?.incrementType === 'day'
        ? f?.processingTime?.increments
        : 0,
    ) || []),
    0,
  );

  const region = shop?.physicalAddress?.region || shop?.seoMetadata?.address?.addressRegion;
  const price =
    (product.type === 'standard' && (product as Product)?.price) || (product as CustomProduct)?.startingPrice;
  const imageLink = getImageUrl(product, 'mediumVersion');
  if (price > 0 && imageLink) {
    const newProduct = {
      id: product.id,
      offerId: product.id,
      title: product.gmcMetadata?.title || product.title,
      description: product.gmcMetadata?.description || product.description,
      link: `https://shopcastiron.com/shop/${shop.websiteUrl}/p/${kebabCase(product.title)}/${product.id}`,
      imageLink: imageLink,
      contentLanguage: 'en',
      targetCountry: 'US',
      channel: 'online',
      availability: 'in stock',
      condition: 'new',
      productTypes: _.uniq(getAllTaxonomy(product)),
      identifierExists: false,
      price: {
        value: `${(price / 100).toFixed(2)}`,
        currency: 'USD',
      },
      brand: `${shop.businessName}`,
      shipping: [
        {
          price: {
            value: `${(shippingPrice / 100).toFixed(2)}`,
            currency: 'USD',
          },
          country: 'US',
          region: region ? region : '',
          minHandlingTime: shippingMinHandlingTime.toString(),
        },
      ],
      taxes: [
        {
          country: 'US',
          rate: shop.paymentSettings?.taxRate || 0,
        },
      ],
    };
    return newProduct;
  } else {
    return null;
  }
};
