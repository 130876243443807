import { ButtonV2, Pill, ProductImage, Typography } from '@castiron/components';
import { BaseProduct, getImageUrl } from '@castiron/domain';
import { Grid } from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import NoPhoto from '../../../assets/img/no-photo-thumbnail.png';
import SearchModal from './SearchModal';

type Props = {
  products: BaseProduct[];
};

const useStyles = makeStyles((theme: Theme) => ({
  productImage: {
    borderRadius: 12,
    overflow: 'hidden',
  },
  productTile: {
    padding: 16,
    gap: 8,
  },
  productTilesContainer: {
    border: `1px solid ${theme.branding.gray[300]}`,
    borderRadius: 12,
  },
}));

const PresaleProducts: React.FC<Props> = (props: Props) => {
  const { products } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { values }: any = useFormikContext();

  const [showSearchModal, setShowSearchModal] = useState<boolean>(false);

  const presaleProducts = products?.filter(product => values?.productIds?.includes(product?.id));

  const productTile = (product: BaseProduct, index: number) => (
    <Grid
      container
      item
      wrap="nowrap"
      key={`productTile${index}`}
      className={classes.productTile}
      style={
        presaleProducts.length !== 1 && index !== presaleProducts.length - 1
          ? { borderBottom: `1px solid ${theme.branding.gray[300]}` }
          : {}
      }
      alignItems="center"
    >
      <ProductImage
        src={getImageUrl(product, 'smallVersion') || NoPhoto}
        alt={`productImage${index}`}
        height="64px"
        width="64px"
        containerClassName={classes.productImage}
      />
      <Typography variant="body1">{product?.title}</Typography>
    </Grid>
  );

  return (
    <>
      {presaleProducts?.length === 0 ? (
        <ButtonV2 variant="outlined" onClick={() => setShowSearchModal(true)}>
          + Add Products to Presale
        </ButtonV2>
      ) : (
        <>
          <Grid container item justify="space-between" wrap="nowrap">
            <Grid container item justify="flex-start" style={{ gap: 8 }}>
              <Typography variant="subtitle1">Products</Typography>
              <Pill content={presaleProducts?.length} variant="blue" />
            </Grid>
            <ButtonV2 variant="text" onClick={() => setShowSearchModal(true)}>
              Manage
            </ButtonV2>
          </Grid>
          <Grid container item direction="column" wrap="nowrap" className={classes.productTilesContainer}>
            {presaleProducts?.map((product, index) => productTile(product, index))}
          </Grid>
        </>
      )}
      <SearchModal
        context="product"
        items={products}
        onClose={() => setShowSearchModal(false)}
        show={showSearchModal}
      />
    </>
  );
};

export default PresaleProducts;
