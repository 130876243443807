import React, { useEffect, useState, useRef, ReactElement } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as yup from 'yup';
import { Formik, FormikProps } from 'formik';
import _ from 'lodash';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import {
  ToggleButton,
  ToggleButtonOption,
  Typography,
  Banner,
  Button,
  IosShareIcon,
  ProductCard,
  DiscardButton,
  SaveButton,
} from '@castiron/components';
import { getProductStatus, useTracking } from '@castiron/utils';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { openModal } from '../../../store/reducers/modalConductor';
import { getProductsAction } from '../../../store/reducers/products';
import { updateShopAction } from '../../../store/reducers/shops';
import { LayoutPageProps } from '../../Layout';
import ViewShopButton from '../../Layout/Header/ViewShopButton';
import AdminForm from '../../AdminForm';
import { Shop } from '@castiron/domain';

interface Props extends LayoutPageProps {}

interface FormValues {
  isActive: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  bannerLink: {
    textDecoration: 'underline',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  container: {
    '& > form': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
  },
  errorBanner: {
    marginTop: 24,
    [theme.breakpoints.down('sm')]: {
      margin: '0 0 24px 0',
    },
  },
  infoBanner: {
    marginBottom: 24,
  },
  mobileShareIcon: {
    color: theme.branding.v2.gray[400],
  },
  noActiveProducts: {
    width: '100%',
    backgroundColor: theme.branding.v2.gray[100],
    padding: 16,
    borderRadius: 12,
  },
  productCard: {
    opacity: '64%',
  },
  productsHeader: {
    marginBottom: 8,
  },
  rightColumn: {
    fontWeight: 600,
    lineHeight: '24px',
  },
  toggleButton: {
    fontWeight: 600,
    lineHeight: '24px',
    '& button': {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '16px 0px',
    },
  },
  toggleCaption: {
    fontWeight: 400,
    color: theme.branding.v2.gray[700],
  },
}));

const ShopPage: React.FC<LayoutPageProps> = (props: Props) => {
  const { setPageTitle, setPageIsProFeature, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const formRef = useRef<FormikProps<FormValues>>();
  const { trackEvent } = useTracking();
  const history = useHistory();

  const { shop, products } = useAppSelector(state => ({
    shop: state.shops.shop,
    products: state.products.products,
  }));

  const standardProducts = products.filter(p => p.type === 'standard' && getProductStatus(p, shop?.config?.timeZone) === 'active');
  const hasStandardProducts = !_.isEmpty(standardProducts);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleShareClick = () => {
    dispatch(
      openModal({
        modalType: 'SHARE_LINKS_MODAL',
        modalProps: {
          show: true,
          subdirectory: '',
        },
      }),
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    setPageTitle('Shop');
    setPageIsProFeature(false);
    setBackLocation(true);
    setHeaderCTAs([
      isMobile ? (
        <IosShareIcon className={classes.mobileShareIcon} onClick={handleShareClick} />
      ) : (
        <Button variant="outlined" onClick={handleShareClick}>
          <IosShareIcon />
          &nbsp;&nbsp; Share
        </Button>
      ),
      <ViewShopButton subdirectory="/" />,
    ]);

    return () => {
      setBackLocation(false);
      setPageTitle('');
      setPageIsProFeature(false);
      setHeaderCTAs([]);
    };
  }, [isMobile]);

  useEffect(() => {
    setFooterCTAs([
      <DiscardButton isSubmitting={isSubmitting} backLocation="/store/pages" />,
      <SaveButton formikState={formRef.current} isSubmitting={isSubmitting} />,
    ]);

    return () => {
      setFooterCTAs([]);
    };
  }, [isSubmitting, formRef]);

  useEffect(() => {
    const getProducts = async () => {
      dispatch(getProductsAction(shop.id));
    };

    if (shop) getProducts();
  }, [shop]);

  const onSubmit = async (values: FormValues) => {
    const { isActive } = values;
    if (isActive !== shop?.shopSubpageData?.isShopPageEnabled) {
      setIsSubmitting(true);
      try {
        const newShop = {
          ...shop,
          shopSubpageData: {
            ...shop.shopSubpageData,
            isShopPageEnabled: isActive,
          },
        } as Shop;

        await dispatch(updateShopAction({ shop: newShop }));

        trackEvent('Shop Page Status Updated');

        dispatch(
          openModal({
            modalType: 'SIMPLE_ALERT',
            modalProps: {
              show: true,
              celebrate: true,
              content: (
                <>
                  <Typography variant="h4">Shop Page Updated!</Typography>
                </>
              ),
            },
          }),
        );

        setIsSubmitting(false);
        history.push('/store/pages');
      } catch (error) {
        setIsSubmitting(false);
        console.error('Error Submitting Shop Quotes Page Form: ', error);
      }
    }
  };

  const toggleButtonOptions: ToggleButtonOption[] = [
    {
      value: true,
      label: 'Active',
    },
    {
      value: false,
      label: 'Inactive',
    },
  ];

  const getActiveBannerBlock = () => (
    <Banner variant="info-white" className={classes.infoBanner}>
      <Typography variant="body4">
        <span style={{ fontWeight: 700 }}>Note:</span> This page is populated by your instant checkout products. To make
        edits to the page, visit and edit your{' '}
        <Link to="/products" style={{ color: theme.branding.v2.gray[600], textDecoration: 'underline' }}>
          instant checkout products
        </Link>
        .
      </Typography>
    </Banner>
  );

  const getInactiveBannerBlock = () => (
    <Banner variant="error" className={classes.errorBanner}>
      <Typography variant="body4" style={{ color: theme.branding.v2.red[900] }}>
        Please add at least one{' '}
        <Link to="/products" className={classes.bannerLink}>
          instant checkout product
        </Link>{' '}
        to change the status to active.
      </Typography>
    </Banner>
  );

  const getToggleButtonBlock = (setFieldValue, values: FormValues) => (
    <Grid container item direction="column" className={classes.toggleButton}>
      <Typography variant="subtitle2">Status</Typography>
      <ToggleButton
        value={values.isActive}
        exclusive
        onChange={(e: React.MouseEvent<HTMLElement>, value): void => {
          setFieldValue('isActive', value);
        }}
        aria-label="page visibility"
        buttonOptions={toggleButtonOptions}
        disabled={!hasStandardProducts}
      />
      <Typography variant="caption" className={classes.toggleCaption}>
        Status for this page is automatically set to active when you have at least one active instant checkout product.
      </Typography>
    </Grid>
  );

  const initialValues: FormValues = {
    isActive: (shop?.shopSubpageData?.isShopPageEnabled && hasStandardProducts) || false,
  };

  const homeSchema = yup.object().shape({
    isActive: yup.boolean(),
  });

  return (
    <Grid container justify="center" className={classes.container}>
      <Helmet>
        <title>Shop | Castiron</title>
      </Helmet>
      <Formik
        initialValues={initialValues}
        validationSchema={homeSchema}
        onSubmit={onSubmit}
        innerRef={formRef}
        enableReinitialize
      >
        {({ setFieldValue, values }): ReactElement => (
          <AdminForm>
            <Grid container item xs={12} direction={isMobile ? 'column' : 'row'} spacing={!isMobile && 6} wrap="nowrap">
              {isMobile && (
                <>
                  {getToggleButtonBlock(setFieldValue, values)}
                  {hasStandardProducts ? getActiveBannerBlock() : getInactiveBannerBlock()}
                </>
              )}

              <Grid container item xs={12} md={8} direction="column">
                {!isMobile && hasStandardProducts && getActiveBannerBlock()}
                <Grid container item direction="column" style={{ marginBottom: 40 }}>
                  <Typography variant="subtitle2" className={classes.productsHeader}>
                    Active Products
                  </Typography>
                  {hasStandardProducts ? (
                    <Grid container item spacing={2}>
                      {_.sortBy(standardProducts, [
                        product => getProductStatus(product, shop?.config?.timeZone) !== 'active',
                        product => product.title.toLowerCase(),
                      ]).map(product => (
                        <Grid className={classes.productCard} key={product.id} item xs={6} sm={4}>
                          <ProductCard disabled product={product} onClick={() => {}} type="product" timeZone={shop?.config?.timeZone} />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Grid container justify="center" alignItems="center" className={classes.noActiveProducts}>
                      <Typography variant="body2">No active products to display.</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid container item xs={12} md={4} direction="column" className={classes.rightColumn}>
                {!isMobile && getToggleButtonBlock(setFieldValue, values)}
                {!isMobile && !hasStandardProducts && getInactiveBannerBlock()}
              </Grid>
            </Grid>
          </AdminForm>
        )}
      </Formik>
    </Grid>
  );
};

export default ShopPage;
