import { ButtonV2 } from '@castiron/components';
import { useTracking } from '@castiron/utils';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { shopRepository } from '../../../domain';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeModal } from '../../../store/reducers/modalConductor';
import { getShopAction } from '../../../store/reducers/shops';
import GeneralModal from '../../RootModal/GeneralModal';

const useStyles = makeStyles((theme: Theme) => ({
  modalPaperClass: {
    [theme.breakpoints.up('sm')]: {
      height: 'fit-content',
    },
  },
  turnOffButton: {
    backgroundColor: theme.branding.v2.red[500],
    border: `1px solid ${theme.branding.v2.red[500]}`,
    color: theme.branding.v2.gray[0],
    '&:hover': {
      backgroundColor: theme.branding.v2.red[400],
    },
  },
}));

export interface Props {}

const TurnOffAutomationModal: React.FC<Props> = (props: Props) => {
  const {} = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleClose = () => {
    dispatch(closeModal());
  };

  const turnOfferOff = async () => {
    setIsSubmitting(true);
    await shopRepository.updateProps(shop.id, {
      'config.marketing.thankYouCoupon.enabled': false,
    });
    trackEvent('Automation Turned Off', { type: 'Thank You' });
    await dispatch(getShopAction(shop.id));
    setIsSubmitting(false);
    handleClose();
  };

  return (
    <GeneralModal
      paperClass={classes.modalPaperClass}
      show={true}
      header="Thank You Offer"
      content={
        <Grid container item direction="column" wrap="nowrap" style={{ textAlign: 'left' }}>
          <Typography variant="body1" style={{ marginBottom: '16px' }}>
            Your offer coupon will still be active but we will no longer send the offer to new customers.
          </Typography>
          <Typography variant="body1" style={{ fontWeight: 700 }}>
            Are you sure you want to turn off this offer?
          </Typography>
        </Grid>
      }
      actions={[
        <ButtonV2 variant="outlined" onClick={handleClose}>
          Leave Offer On
        </ButtonV2>,
        <ButtonV2
          variant="outlined"
          className={classes.turnOffButton}
          onClick={turnOfferOff}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Turn Off Offer
        </ButtonV2>,
      ]}
    />
  );
};

export default TurnOffAutomationModal;
