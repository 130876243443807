import React, { useState } from 'react';
import _ from 'lodash';
import { Button, Grid, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { EmptyProductIcon, SvgIcon, Typography } from '@castiron/components';
import { useFormikContext } from 'formik';
import { InputField, ProductType } from '@castiron/domain/src';
import { nanoid } from '@reduxjs/toolkit';
import SingleVariation, { initialVariationOptionValues } from './SingleVariation';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';

type Props = {
  productId: string;
  type: ProductType;
  placeholders?: Record<string, string>;
  isEditMode: boolean;
  isTemplate?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  defaultInformationContainer: {
    background: theme.branding.v2.blue[50],
    borderRadius: 12,
    padding: '24px 16px',
    marginBottom: 16,
  },
  gridContainer: {
    maxWidth: 680,
    width: '100%',
  },
  icon: {
    height: 24,
    width: 24,
    color: theme.branding.v2.blue[500],
  },
  iconContainer: {
    height: 32,
    width: 32,
    borderRadius: 8,
    padding: 4,
    background: theme.branding.v2.blue[100],
    marginRight: 20,
  },
  label: {
    marginRight: 8
  },
  subLabel: {
    color: theme.branding.gray[600],
    fontWeight: 600,
    marginTop: '8px',
  },
  addVariationButton: {
    marginTop: '8px',
    padding: '13px 32px',
    border: `1px solid ${ theme.branding.gray[400] }`
  },
  previewButton: {
    border: `1px solid ${ theme.branding.gray[400] }`,
    color: theme.branding.blue.primary,
    marginLeft: 'auto',
    '& svg path': {
      fill: theme.branding.blue.primary,
    },
  },
  variationHeader: {
    alignItems: 'center',
    display: 'flex'
  }
}));

// a little function to help us with reordering the result
function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

const Variations: React.FC<Props> = (props: Props) => {
  const { type, isEditMode, placeholders, productId, isTemplate = false } = props;
  const { values, setFieldValue }: any = useFormikContext();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [variationExpanded, setVariationExpanded] = useState(false);

  const addVariation = async () => {
    setVariationExpanded(true);
    await setFieldValue('variations', [
      ...values.variations,
      {
        id: nanoid(),
        type: 'select',
        name: '',
        values: [{ ...initialVariationOptionValues, id: nanoid() }],
        required: false,
        position: values.variations.length - 1
      },
    ]);
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const sortedVariations = reorder<InputField>(
      values.variations,
      result.source.index,
      result.destination.index
    );

    const newVariations = sortedVariations.map((v, index) => ({
      ...v,
      position: index
    }));

    await setFieldValue('variations', newVariations);
  }

  const orderFormInformationTile = (
    <Grid container className={classes.defaultInformationContainer}>
      <Typography variant="subtitle1">We collect the following by default:</Typography>
      <Grid container item style={{ marginTop: 16 }}>
        <SvgIcon className={classes.iconContainer}>
          <PersonOutlineOutlinedIcon className={classes.icon} />
        </SvgIcon>
        <Grid container item xs direction="column">
          <Typography variant="subtitle1">{type === 'event' ? 'Attendee Info' : 'Customer Contact Info'}</Typography>
          <Typography variant="body2">
            {type === 'event' ? 'Full Name, Email, Marketing Opt-In' : 'Full Name, Email, Phone, and Marketing Opt-in'}
          </Typography>
        </Grid>
      </Grid>
      {type !== 'event' && (
        <Grid container item style={{ marginTop: 16 }}>
          <SvgIcon className={classes.iconContainer}>
            <LocationOnOutlinedIcon className={classes.icon} />
          </SvgIcon>
          <Grid container item xs direction="column">
            <Typography variant="subtitle1">Fulfillment Info</Typography>
            <Typography variant="body2">
              Order Fulfillment Date, Preference (Pickup, Delivery or Shipping), and Address
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );

  const emptyProductTile = (
    <Grid container direction="column" alignItems="center" style={{margin: '32px 0px 16px 0px', textAlign: 'center'}}>
      <EmptyProductIcon />
      <Typography style={{marginTop: 16}} variant="h2">No Variations to Display</Typography>
      <Typography style={{marginTop: 8}} variant="body1">If you have any colors, flavors, or other choices, you can add them here.</Typography>
    </Grid>
  );

  return <DragDropContext onDragEnd={ onDragEnd }>
    <Grid container className={ classes.gridContainer } direction="column" alignItems="center">
      { (type === 'custom' || type === 'event') && orderFormInformationTile }
      { values.variations?.length === 0 && type === 'standard' && emptyProductTile }
      { values.variations?.length > 0 && <Droppable droppableId="variations" type="VARIATION">
        { (provided, snapshot) => (<div ref={ provided.innerRef } style={{width: '100%'}}>
          { _.sortBy(values.variations, v => v.position).map((variation: InputField, index: number) => (
            <Draggable draggableId={ variation.id } index={ index } key={ variation.id }>
              { (provided, snapshot) => (
                <div ref={ provided.innerRef } {...provided.draggableProps}>
                  <SingleVariation
                    variation={variation}
                    index={index}
                    type={type}
                    isEditMode={isEditMode}
                    variationExpanded={variationExpanded}
                    placeholders={placeholders}
                    dragHandleProps={provided.dragHandleProps}
                    productId={productId}
                    isTemplate={isTemplate}
                  />
                </div>) }
            </Draggable>
          )) }
          {provided.placeholder}
        </div>)
        }
      </Droppable>
      }
      <Button
        onClick={ addVariation }
        className={ classes.addVariationButton }
        variant={values.variations?.length === 0 && (type === 'standard' || type === 'event') ? "contained" : "outlined"}
        color="primary"
      >
        Add a { (type === 'standard' || type === 'event') ? 'Variation' : 'Question' }
      </Button>
    </Grid>
  </DragDropContext>;
};

export default Variations;
