import { ButtonV2, Pill, Typography } from '@castiron/components';
import { FulfillmentOption, getFulfillmentDateInfo } from '@castiron/domain';
import { Grid } from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useState } from 'react';
import SearchModal from './SearchModal';

type Props = {
  fulfillments: FulfillmentOption[];
};

const useStyles = makeStyles((theme: Theme) => ({
  fulfillmentTile: {
    padding: 16,
  },
  fulfillmentTilesContainer: {
    border: `1px solid ${theme.branding.gray[300]}`,
    borderRadius: 12,
  },
}));

const PresaleFulfillments: React.FC<Props> = (props: Props) => {
  const { fulfillments } = props;
  const classes = useStyles();
  const theme = useTheme();

  const { values }: any = useFormikContext();

  const [showSearchModal, setShowSearchModal] = useState<boolean>(false);

  const presaleFulfillments = fulfillments?.filter(fulfillment => values?.fulfillmentIds?.includes(fulfillment?.id));

  const fulfillmentTile = (fulfillment: FulfillmentOption, index: number) => {
    const fulfillmentDateInfo = getFulfillmentDateInfo(fulfillment, 2);
    return (
      <Grid
        container
        item
        direction="column"
        wrap="nowrap"
        key={`fulfillmentTile${index}`}
        className={classes.fulfillmentTile}
        style={
          presaleFulfillments.length !== 1 && index !== presaleFulfillments.length - 1
            ? { borderBottom: `1px solid ${theme.branding.gray[300]}` }
            : {}
        }
      >
        <Typography variant="body1">{fulfillment?.displayName}</Typography>
        <Typography variant="body2" style={{ color: theme.branding.v2.gray[600] }}>
          {_.capitalize(fulfillment?.type)}
          {fulfillmentDateInfo ? ` • ${fulfillmentDateInfo}` : ''}
        </Typography>
      </Grid>
    );
  };

  return (
    <>
      {presaleFulfillments?.length === 0 ? (
        <ButtonV2 variant="outlined" onClick={() => setShowSearchModal(true)}>
          + Add Fulfillments to Presale
        </ButtonV2>
      ) : (
        <>
          <Grid container item justify="space-between" wrap="nowrap">
            <Grid container item justify="flex-start" style={{ gap: 8 }}>
              <Typography variant="subtitle1">Fulfillments</Typography>
              <Pill content={presaleFulfillments?.length} variant="blue" />
            </Grid>
            <ButtonV2 variant="text" onClick={() => setShowSearchModal(true)}>
              Manage
            </ButtonV2>
          </Grid>
          <Grid container item direction="column" wrap="nowrap" className={classes.fulfillmentTilesContainer}>
            {presaleFulfillments?.map((fulfillment, index) => fulfillmentTile(fulfillment, index))}
          </Grid>
        </>
      )}
      <SearchModal
        context="fulfillment"
        items={fulfillments}
        onClose={() => setShowSearchModal(false)}
        show={showSearchModal}
      />
    </>
  );
};

export default PresaleFulfillments;
