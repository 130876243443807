import { Customer, newSubscriberStatusState } from "@castiron/domain";
import { Grid } from "@material-ui/core";
import React from "react";
import Chip from "../Chip";

interface Props {
  customer: Customer;
};

const SubscriberStatusPill: React.FC<Props> = (props: Props) => {
  const { customer } = props;

  const subscriberStatus = newSubscriberStatusState(customer)?.toLowerCase();

  return subscriberStatus === 'email/sms' ? (
    <Grid container style={{ gap: 8 }}>
      <Chip bold colorScheme="success">
        EMAIL
      </Chip>
      <Chip bold colorScheme="success">
        SMS
      </Chip>
    </Grid>
  ) : subscriberStatus === 'email' ? (
    <Chip bold colorScheme="success">
      {subscriberStatus?.toUpperCase()}
    </Chip>
  ) : subscriberStatus === 'sms' ? (
    <Chip bold colorScheme="success">
      {subscriberStatus?.toUpperCase()}
    </Chip>
  ) : (
    <>-</>
  );
};

export default SubscriberStatusPill;
