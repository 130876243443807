import React, { ReactNode } from 'react';
import { Input } from '@castiron/components';
import { makeStyles } from '@material-ui/core';
import InputLabel from '../../../InputLabel';

type Props = {
  autocomplete?: string;
  primaryLabel?: ReactNode;
  name?: string;
  fullWidth?: boolean;
  error?: any;
  placeholder?: string;
  containerClass?: string;
  onChange?: (event: any) => void;
  value?: number;
  hideArrows?: boolean;
  disabled?: boolean;
};

const useStyles = makeStyles(() => ({
  priceLabel: {
    position: 'relative',
    top: 12,
    marginBottom: 0,
  },
}));

const PriceInput: React.FC<Props> = (props: Props) => {
  const { autocomplete, primaryLabel, name, fullWidth, error, placeholder, containerClass, onChange, value, hideArrows, disabled } = props;
  const classes = useStyles();

  return (
    <Input
      autocomplete={autocomplete}
      fullWidth={fullWidth}
      priceInput
      label={primaryLabel && <InputLabel containerClass={classes.priceLabel} primaryLabel={primaryLabel} />}
      name={name}
      error={error}
      placeholder={placeholder}
      type="number"
      min={0}
      containerClass={containerClass}
      {...(onChange ? { onChange } : {})}
      onWheel={e => e.target.blur()}
      value={value}
      hideArrows={hideArrows}
      disabled={disabled}
    />
  );
};

export default PriceInput;
