import { upload } from '@castiron/castiron-firebase';
import {
  Banner,
  ButtonV2,
  CheckboxInput,
  CollapsableCard,
  SvgIcon,
  TextInput,
  Typography,
  useFeatures,
} from '@castiron/components';
import { InputField, ProductType } from '@castiron/domain/src';
import { removeEmpty, useTracking } from '@castiron/utils';
import {
  ButtonBase,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Theme,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DragIndicator from '@material-ui/icons/DragIndicator';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import NotesIcon from '@material-ui/icons/Notes';
import RadioButtonCheckedOutlinedIcon from '@material-ui/icons/RadioButtonCheckedOutlined';
import { nanoid } from '@reduxjs/toolkit';
import Dinero from 'dinero.js';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { openModal } from '../../../../../src/store/reducers/modalConductor';
import { productRepository } from '../../../../domain';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import EllipsisMenu from '../../../Menus/EllipsisMenu';
import ModalActions from '../../../RootModal/ModalActions';
import ModalHeader from '../../../RootModal/ModalHeader';
import ModalWrapper from '../../../RootModal/ModalWrapper';
import Tooltip from '../../../Tooltip';
import PriceInput from './PriceInput';

const useStyles = makeStyles((theme: Theme) => ({
  addBottomMargin: {
    marginBottom: 24,
  },
  decorator: {
    color: '#FC6E26',
  },
  dragDots: {
    color: theme.branding.v2.gray[800],
  },
  dialog: {
    position: 'relative',
    overflow: 'hidden',
    '& .MuiPaper-root': {
      [theme.breakpoints.up('sm')]: {
        maxHeight: 624,
        width: 576,
      },
    },
  },
  divider: {
    color: theme.branding.v2.gray[200],
    height: 8,
    margin: '20px -24px 8px -24px',
    width: 'calc(100% + 48px)',
  },
  dragging: {
    backgroundColor: theme.branding.v2.blue[50],
  },
  formControl: {
    marginBottom: 16,
    width: '100%',
  },
  image: {
    aspectRatio: '1/1',
    borderRadius: 12,
    maxHeight: 357,
    maxWidth: 313,
  },
  imageContainer: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 12,
    overflow: 'hidden',
    padding: 16,
    position: 'relative',
    width: '100%',
  },
  menuButton: {
    zIndex: 5,
    backgroundColor: theme.branding.v2.gray[0],
    right: 0,
    margin: 8,
    borderRadius: 12,
    width: 40,
    height: 40,
    position: 'absolute',
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    '& svg': {
      color: theme.branding.v2.gray[500],
    },
    '& svg:hover': {
      color: theme.branding.v2.gray[800],
    },
  },
  pill: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: '100px',
    padding: '4px 8px',
    width: 'fit-content',
  },
  pillRequired: {
    background: theme.branding.v2.gray[100],
    border: `1px solid ${theme.branding.v2.gray[100]}`,
  },
  requiredCheckbox: {
    padding: 0,
    paddingRight: 8,
  },
  responseTypeIcon: {
    color: theme.branding.v2.blue[500],
    height: 24,
    marginRight: 16,
    width: 24,
  },
  textCutoff: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    wordBreak: 'break-all',
  },
  totalPriceContainer: {
    '& .MuiTextField-root': {
      background: theme.branding.v2.gray[100],
      borderRadius: 12,
    },
  },
  variationContent: {
    margin: '24px 4px 24px 24px',
    overflowY: 'scroll',
    'scrollbar-width': 'thin',
    'scrollbar-color': theme.branding.v2.gray[400],
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.branding.v2.gray[600],
      '-webkit-border-radius': '12px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.branding.v2.gray[0],
    },
    '&::-webkit-scrollbar-track-piece:start': {
      background: 'transparent',
    },
    '&-webkit-scrollbar-track-piece:end': {
      background: 'transparent',
    },
  },
  variationDisplay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 12,
    marginTop: '8px',
    position: 'relative',
    backgroundColor: theme.branding.gray[100],
    padding: '12px 24px',
  },
  variationTitle: {
    marginBottom: '8px',
    textAlign: 'left',
  },
}));

const nameOptions = [
  'Flavor',
  'Topping',
  'Filling',
  'Color',
  'Theme',
  'Size',
  'Servings',
  'Add-ons',
  'Allergens',
  'Dietary',
  'Custom',
];

export const initialVariationOptionValues = { name: '', value: '', cost: undefined };

interface Props {
  productId: string;
  variation: InputField;
  index: number;
  type: ProductType;
  isEditMode: boolean;
  placeholders?: Record<string, string>;
  variationExpanded: boolean;
  dragHandleProps: any;
  isTemplate?: boolean;
}

const SingleVariation: React.FC<Props> = (props: Props) => {
  const {
    variation,
    index,
    type,
    isEditMode,
    variationExpanded,
    placeholders,
    dragHandleProps,
    productId,
    isTemplate = false,
  } = props;
  const { values, setFieldValue, errors, handleSubmit, resetForm, initialValues }: any = useFormikContext();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { trackEvent } = useTracking();
  const dispatch = useAppDispatch();
  const features = useFeatures();

  const [nameValue, setNameValue] = useState(nameOptions.includes(variation.name) ? variation.name : 'Custom');
  const [typeValue, setTypeValue] = useState(variation.type);
  const [isText, setIsText] = useState<boolean>(variation.type === 'text');
  const [expanded, setExpanded] = useState(variationExpanded);
  const [imageUrl, setImageUrl] = useState(variation?.imageObj?.downloadUrl);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const typeOptions = [
    {
      label: 'Single Choice',
      value: 'select',
      icon: <RadioButtonCheckedOutlinedIcon className={classes.responseTypeIcon} />,
    },
    { label: 'Multiple Choice', value: 'multiselect', icon: <CheckBoxIcon className={classes.responseTypeIcon} /> },
    { label: 'Written Response', value: 'text', icon: <NotesIcon className={classes.responseTypeIcon} /> },
    { label: 'Photo Upload', value: 'photo', icon: <ImageOutlinedIcon className={classes.responseTypeIcon} /> },
  ];

  const handleFileUploadSuccess = async (downloadUrl, metadata, options) => {
    console.debug('handleFileUploadSuccess: ', downloadUrl);

    const newVariationImageObj = {
      id: metadata.id,
      shopId: shop.id,
      downloadUrl,
      metadata: metadata,
      options,
    };

    const newVariations = removeEmpty(
      values.variations.map((v, i) => {
        if (v.id === variation.id) {
          return {
            ...v,
            imageObj: newVariationImageObj,
          };
        }
        return v;
      }),
    );
    await setFieldValue(`variations[${index}].imageObj`, newVariationImageObj);
    if (!isTemplate) {
      await productRepository.updateProps(productId, {
        variations: newVariations,
      });
    }
    setImageUrl(newVariationImageObj?.downloadUrl);
  };

  const handleFile = async (item, assetType) => {
    const { file } = item;
    const id = nanoid();
    //TODO: this was failing when a file did not exist and was not updating products
    //this seemed to fix it (prevents upload) but I'm not sure if that is what should actually be done
    if (file) {
      const metadata = {
        shopId: shop.id,
        originalFilename: id,
        id,
        assetType,
      };
      const options = {
        folder: `user/${shop.id}`,
      };
      const callbacks = {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        success: handleFileUploadSuccess,
      };
      const context = {
        shop: shop,
      };
      await upload(file, metadata, options, callbacks, context);
    }
    return id;
  };

  const deleteVariation = async (id: string) => {
    const newVariations = removeEmpty(
      values.variations
        .filter((v: InputField) => v.id !== id)
        .map((v, i) => ({
          ...v,
          position: i,
        })),
    );
    await setFieldValue('variations', newVariations);
    setShowErrors(false);
    if (!isTemplate) {
      await productRepository.updateProps(productId, {
        variations: newVariations,
      });
    }
  };

  const handleDiscard = async () => {
    const originalVariation = initialValues?.variations?.find(v => v.id === variation.id);
    if (!originalVariation) {
      deleteVariation(variation.id);
    } else {
      await setFieldValue(`variations[${index}]`, originalVariation);
      setShowErrors(false);
      setImageUrl(originalVariation?.imageObj?.downloadUrl);
    }
    handleExpand();
  };

  const handleSave = async () => {
    setShowErrors(true);
    if (errors.variations && errors.variations[index]) {
      /* trigger error validation */
      handleSubmit();
      const element = document.getElementById('error');
      element && element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      setIsSubmitting(true);
      const originalVariation = initialValues?.variations?.find(v => v.id === variation.id);
      const originalVariationHasFile = Object.keys(originalVariation?.imageObj || {})?.includes('preview');

      const newVariations = removeEmpty(
        values.variations.map(v => {
          if (v.id === variation.id && !originalVariationHasFile) {
            return {
              ...originalVariation,
              ..._.omit(v, 'imageObj'),
              values: v.values.map(val => ({ ...val, cost: Math.round((val.cost || 0) * 100) })),
            };
          } else if (v.id === variation.id) {
            return {
              ..._.omit(v, 'imageObj'),
              values: v.values.map(val => ({ ...val, cost: Math.round((val.cost || 0) * 100) })),
            };
          } else {
            return {
              ...v,
              values: v.values.map(val => ({ ...val, cost: Math.round((val.cost || 0) * 100) })),
            };
          }
        }),
      );

      if (!isTemplate) {
        await productRepository.updateProps(productId, {
          variations: newVariations,
        });
      }

      const previousImageObj = originalVariation?.imageObj;
      const newImageObj = values.variations && values.variations[index] && values.variations[index].imageObj;

      if (imageUrl && previousImageObj !== newImageObj) {
        trackEvent('Variation Photo Added');
        handleFile(newImageObj, 'variationImage');
      }

      resetForm({ values });
      setShowErrors(false);
      setIsSubmitting(false);
      handleExpand();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleNameChange = async event => {
    setNameValue(event.target.value);
    if (event.target.value !== 'Custom') {
      await setFieldValue(`variations[${index}].name`, event.target.value);
    } else {
      await setFieldValue(`variations[${index}].name`, '');
    }
    await setFieldValue(`variations[${index}].type`, typeValue);
  };

  const handleTypeChange = async event => {
    await setFieldValue(`variations[${index}].type`, event.target.value);
    setTypeValue(event.target.value);
    setIsText(event.target.value === 'text');
    if (event.target.value === 'text')
      await setFieldValue(`variations[${index}].values`, [{ ...initialVariationOptionValues, id: nanoid() }]);

    if (event.target.value === 'photo' && !values.variations[index].name) {
      await setFieldValue(`variations[${index}].name`, 'Share your inspiration photos');
    }
  };

  const addOptionValue = async () => {
    const id = nanoid();
    await setFieldValue(`variations[${index}].values`, [...variation.values, { ...initialVariationOptionValues, id }]);
    const optionElement = document.getElementById(`option-${id}`);
    optionElement && optionElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const isExistingOption = optionId => {
    const existingVariation = initialValues?.variations?.find(v => v.id === variation.id);
    const existingOption = !!existingVariation?.values.find(v => v.id === optionId);
    return existingOption;
  };

  const deleteVariationValue = async (id: string) => {
    await setFieldValue(
      `variations[${index}].values`,
      variation.values.filter(v => v.id !== id),
    );
  };

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const isCustom = type === 'custom';

  const variationTitle = () => {
    if (variation.name !== '') {
      return variation.name;
    } else if (isCustom) {
      return `Question #${index + 1}`;
    } else {
      return `Variation #${index + 1}`;
    }
  };

  const checkVariationErrors = (loc: string) => {
    if (showErrors) {
      if (errors.variations && errors.variations[index]) {
        return errors.variations[index][loc];
      } else {
        return false;
      }
    }
  };

  const checkVariationValuesErrors = (loc: string, i: number, isPhoto?: boolean) => {
    if (showErrors) {
      if (
        errors.variations &&
        errors.variations[index] &&
        errors.variations[index].values &&
        errors.variations[index].values[i]
      ) {
        return errors.variations[index].values[i][loc];
      } else {
        return false;
      }
    }
  };

  const optionalOrRequiredPill = (
    <Typography className={`${classes.pill} ${variation?.required ? classes.pillRequired : ''}`} variant="caption">
      {variation?.required ? 'Required' : 'Optional'}
    </Typography>
  );

  const variationDisplay = (
    <ButtonBase onClick={handleExpand} className={classes.variationDisplay} disableRipple>
      <Grid container item wrap="nowrap" alignItems="center">
        <span {...dragHandleProps}>
          <SvgIcon className={classes.dragDots}>
            <DragIndicator />
          </SvgIcon>
        </span>
        <Grid container item direction="column" style={{ marginLeft: 16 }}>
          <Typography
            variant="h4"
            className={`${classes.variationTitle} ${classes.textCutoff}`}
            id={`${type === 'custom' ? 'Question' : 'Variation'}${index + 1}`}
          >
            <span style={{ color: theme.branding.v2.blue[500], marginRight: 4 }}>{index + 1}.</span> {variationTitle()}
          </Typography>
          {optionalOrRequiredPill}
        </Grid>
      </Grid>
      <ButtonV2 variant="text" onClick={handleExpand}>
        Edit
      </ButtonV2>
    </ButtonBase>
  );

  const title = (
    <div className={classes.addBottomMargin}>
      <TextInput error={checkVariationErrors('name')} label="Title" name={`variations[${index}].name`} required />
    </div>
  );

  const responseType = (
    <FormControl variant="outlined" className={classes.formControl}>
      <Typography variant="subtitle2">
        Response Type <span className={classes.decorator}>*</span>
      </Typography>
      <Select
        value={typeValue}
        onChange={handleTypeChange}
        style={{
          height: '56px',
        }}
        placeholder="Customers can select only one option"
      >
        {typeOptions.map((option, index) => {
          if (type === 'standard' && option.value === 'photo') {
            return null;
          }
          return (
            <MenuItem key={`label-${index}`} value={option.value}>
              <Grid container wrap="nowrap">
                {option.icon}
                <Typography variant="body1">{option.label}</Typography>
              </Grid>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const variationCategory = type === 'standard' && (
    <FormControl variant="outlined" className={classes.formControl}>
      <Typography variant="subtitle2">
        {' '}
        Variation Category <span className={classes.decorator}>*</span>
      </Typography>
      <Select
        placeholder="Select a Category"
        value={nameValue}
        onChange={handleNameChange}
        style={{
          height: '48px',
        }}
      >
        {nameOptions.map((option, index) => (
          <MenuItem key={`category-${option}-${index}`} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const costInputAndDisplay = (value, optionIndex) => (
    <Grid container item style={{ gap: 8 }} wrap="nowrap">
      <Grid item xs={6}>
        <PriceInput
          primaryLabel="Additional Cost"
          name={`variations[${index}].values[${optionIndex}].cost`}
          placeholder="0.00"
          hideArrows
        />
      </Grid>
      <Grid item xs={6}>
        <PriceInput
          containerClass={classes.totalPriceContainer}
          disabled
          primaryLabel="Total Cost"
          value={((values.price || 0) + (value.cost || 0)).toFixed(2)}
        />
      </Grid>
    </Grid>
  );

  const textVariation = (value, optionIndex) => (
    <>
      <TextInput
        error={checkVariationValuesErrors('name', optionIndex)}
        helpText="What customers see before providing a response."
        label="Placeholder Text"
        name={`variations[${index}].values[${optionIndex}].name`}
        placeholder={placeholders && placeholders[value.id]}
      />
      {type !== 'custom' && costInputAndDisplay(value, optionIndex)}
    </>
  );

  const optionVariation = (value, optionIndex) => (
    <div id={`option-${value.id}`} style={{ width: '100%' }}>
      <CollapsableCard
        title={
          <Grid container direction="column">
            <Typography variant="body1">{value?.name || `Option ${optionIndex + 1}`}</Typography>
            {type !== 'custom' && (
              <Typography variant="body2">
                {Dinero({ amount: Math.round((value.cost || 0) * 100) }).toFormat('$0.00')}
              </Typography>
            )}
          </Grid>
        }
        noScroll
        expanded={showErrors || !isExistingOption(value.id)}
      >
        <Grid container item key={value.id}>
          <TextInput
            error={checkVariationValuesErrors('name', optionIndex)}
            label="Option"
            name={`variations[${index}].values[${optionIndex}].name`}
            placeholder={(placeholders && placeholders[value.id]) || `Option ${optionIndex + 1}`}
            required
          />
          {type !== 'custom' && costInputAndDisplay(value, optionIndex)}
          {(optionIndex !== 0 || variation.values.length > 1) && (
            <>
              <hr className={classes.divider} />
              <Grid container item justify="flex-end">
                <IconButton onClick={() => deleteVariationValue(value.id)}>
                  <DeleteOutlinedIcon />
                </IconButton>
              </Grid>
            </>
          )}
        </Grid>
      </CollapsableCard>
    </div>
  );
  const optionsContainer = (
    <Grid container item style={{ marginTop: 24 }}>
      {!isText && <Typography variant="subtitle2">Options</Typography>}
      {variation.values.map((value, optionIndex) => {
        if (isText) {
          return textVariation(value, optionIndex);
        }
        return optionVariation(value, optionIndex);
      })}
    </Grid>
  );

  const requiredCheckbox = (
    <CheckboxInput
      className={classes.requiredCheckbox}
      label={<Typography variant="body2">Require a selection</Typography>}
      name={`variations[${index}].required`}
      variant="label-right"
    />
  );

  const photoNotesContainer = (
    <Grid container direction="column" style={{ marginTop: 24 }}>
      <Grid container item wrap="nowrap" alignItems="center">
        <Typography variant="subtitle2">Photo Notes</Typography>
        <Tooltip arrow title="A field for notes is provided for each photo uploaded." />
      </Grid>
      <Grid item>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            defaultValue="optional"
            value={variation.notesRequired ? 'required' : 'optional'}
            onChange={e =>
              setFieldValue(`variations[${index}].notesRequired`, e.target.value === 'required' ? true : false)
            }
          >
            <MenuItem key={'optional'} value={'optional'}>
              Optional
            </MenuItem>
            <MenuItem key={'required'} value={'required'}>
              Required
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );

  const handleFileDrop = async file => {
    if (!features?.includes('admin.products.variations.photo')) {
      dispatch(
        openModal({
          modalType: 'LEGACY_GATING_MODAL',
          modalProps: {
            show: true,
          },
        }),
      );
      return;
    }

    if (!file || !file[0]) return;
    const newURL = URL.createObjectURL(file[0]);
    setImageUrl(newURL);
    await setFieldValue(`variations[${index}].imageObj`, { file: file[0], preview: newURL, downloadUrl: newURL });
  };

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: handleFileDrop,
    maxFiles: 1,
    noDrag: false,
    noClick: false,
    disabled: false,
    multiple: false,
  });

  const variationPhotoInputs = (
    <>
      <TextInput className={classes.addBottomMargin} label="Description" name={`variations[${index}].description`} />
      {features?.includes('admin.products.variations.photo') && (
        <>
          <Typography variant="subtitle2">Photo</Typography>
          {imageUrl ? (
            <Grid
              container
              className={`${classes.imageContainer} ${classes.addBottomMargin}`}
              justify="center"
              alignItems="center"
            >
              <Grid style={{ position: 'relative' }}>
                <Grid container justify="center" className={classes.menuButton}>
                  <EllipsisMenu
                    options={[
                      {
                        display: 'Delete Photo',
                        color: 'error',
                        action: () => {
                          setFieldValue(`variations[${index}].imageObj`, '');
                          setImageUrl('');
                        },
                        icon: <DeleteOutlinedIcon />,
                      },
                    ]}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  />
                </Grid>
                <img className={classes.image} src={imageUrl} />
              </Grid>
            </Grid>
          ) : (
            <div {...getRootProps({ className: `${isDragActive ? classes.dragging : ''} ${classes.addBottomMargin}` })}>
              <input {...getInputProps()} />
              <ButtonV2 variant="outlined" fullWidth>
                <ImageOutlinedIcon style={{ marginRight: 8 }} />
                Add a Photo
              </ButtonV2>
            </div>
          )}
        </>
      )}
    </>
  );

  return (
    <>
      {variationDisplay}
      <ModalWrapper show={expanded} onClose={handleDiscard} className={classes.dialog} fullWidth={isMobile}>
        <ModalHeader
          title={
            <Typography className={classes.textCutoff} variant="h3">
              {variationTitle()}
            </Typography>
          }
          handleClose={handleDiscard}
        />
        <Grid className={classes.variationContent}>
          <Grid style={{ marginRight: 20 }}>
            <div id="error">
              {showErrors && errors.variations && errors.variations[index] && (
                <Banner className={classes.addBottomMargin} variant="error">
                  <Typography style={{ color: 'inherit' }} variant="body2">
                    Please fill out all required information
                  </Typography>
                </Banner>
              )}
            </div>
            {variation.type !== 'photo' && variationCategory}
            {title}
            {isCustom && variationPhotoInputs}
            {responseType}
            {requiredCheckbox}
            {variation.type !== 'photo' ? (
              <>
                {optionsContainer}
                {!isText && (
                  <Grid container item justify="center">
                    <ButtonV2 onClick={addOptionValue} variant="outlined">
                      Add Option
                    </ButtonV2>
                  </Grid>
                )}
              </>
            ) : (
              photoNotesContainer
            )}
          </Grid>
        </Grid>
        <ModalActions>
          <Grid container justify="space-between">
            <ButtonV2
              onClick={() => {
                deleteVariation(variation.id);
                handleExpand();
              }}
              style={{ color: theme.branding.v2.red[500], justifySelf: 'flex-start' }}
              variant="outlined"
            >
              Delete
            </ButtonV2>
            <Grid item>
              <ButtonV2 style={{ marginRight: 8 }} variant="outlined" onClick={handleDiscard}>
                Discard
              </ButtonV2>
              <ButtonV2 variant="contained" onClick={handleSave} disabled={isSubmitting}>
                Save
              </ButtonV2>
            </Grid>
          </Grid>
        </ModalActions>
      </ModalWrapper>
    </>
  );
};

export default SingleVariation;
