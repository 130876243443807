import React, {ReactNode} from 'react';
import { useHistory } from 'react-router-dom';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Button } from '../';
import clsx from 'clsx';

type Props = {
  backLocation: string;
  isSubmitting?: boolean;
  onDelete?: () => void;
  className?: string;
  children?: ReactNode;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.branding.red.primary,
  },
}));

const DeleteButton: React.FC<Props> = (props: Props) => {
  const { backLocation, className, isSubmitting, onDelete } = props;

  const history = useHistory();
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      disabled={isSubmitting}
      onClick={() => {
        onDelete;
        history.push(backLocation);
      }}
      className={clsx[classes.button, className]}
    >
      Delete
    </Button>
  );
};

export default DeleteButton;
