import _ from 'lodash';
import moment from 'moment-timezone';
import { Special } from '.';

export const filterScheduledSpecials = (special: Special, timeZone, filterForShop?: boolean) => {
  const now = moment()
    .tz(timeZone || 'America/Chicago')
    .unix();
  const startDate = filterForShop ? special?.shopContent?.schedule?.startDate : special?.schedule?.startDate;
  const endDate = filterForShop ? special?.shopContent?.schedule?.endDate : special?.schedule?.endDate;

  const isScheduleActive = startDate <= now && now <= endDate;

  if (startDate && endDate) {
    return isScheduleActive
  } else {
    return filterForShop ? special?.shopContent?.schedule?.active : special?.schedule?.active;
  } 
};

export const holidays = [
  'Easter',
  'May 4th',
  'Cinco de Mayo',
  "Mother's Day",
  'Memorial Day',
  "Father's Day",
  'Pride Month',
  '4th of July',
  'Eid',
  'Thanksgiving',
  'Christmas',
  'Passover',
  'Halloween',
  "Veteran's Day",
  "New Year's",
  'Labor Day',
  "St. Patrick's Day",
  "Valentine's Day",
  'Mardi Gras',
  'Diwali',
  'Hanukkah',
  'Kwanzaa',
  'Lunar New Year',
  'Juneteenth',
  'Rosh Hashanah',
];

export const occasions = [
  'Bridal Shower',
  'Graduation',
  'Baby Shower',
  'College Student',
  'Team Celebrations',
  'Wake / Shiva',
  'Bachelorette',
  'Back to School',
  'Summer Picnic',
  'Kids Birthdays',
  'Adult Birthdays',
  'Fall',
  'Classes / Services',
  'Get Well Soon',
  'Teacher Appreciation',
  'Nurses Appreciation',
  'Anniversary',
  'Promotion Celebration',
  'Employee Appreciation',
  'Retirement',
  'Welcome Home Baby',
  'Host/Hostess Gift',
  'Weddings',
  'Weird and Wonderful',
  'House Warming',
  'Oktoberfest',
  'DIY',
  'Corporate Gifting',
  'Baptisms',
  'Confirmations',
  'Spring',
  'Summer',
  'Winter',
  'Book Club',
  "Groom's Dinner",
  'Just Because',
];

export const specialToTag = special => {
  const tagMap = _.replace(
    _.join(
      _.words(special).map(s => _.capitalize(s)),
      '',
    ),
    /[\W_]/,
    '',
  );

  if (special === 'Teacher Appreciation') return 'Teacher';
  if (special === 'DIY') return 'DIY';
  return tagMap;
};
