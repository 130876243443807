import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';
import { Grid, useMediaQuery, ButtonBase, Popover, Tooltip } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import AddPhotoAlternateOutlinedIcon from '@material-ui/icons/AddPhotoAlternateOutlined';
import {
  SaveButton,
  DiscardButton,
  TextInput,
  ToggleButton,
  ToggleButtonOption,
  Typography,
  Banner,
  TextAreaInput,
  Button,
  IosShareIcon,
  useFeatures,
} from '@castiron/components';
import { Asset, Shop, shopToEventModel, LayoutOption } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { upload, useConfig } from '@castiron/castiron-firebase';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { assetRepository } from '../../../domain';
import { openModal, closeModal } from '../../../store/reducers/modalConductor';
import { updateShopAction } from '../../../store/reducers/shops';
import { LayoutPageProps } from '../../Layout';
import AdminForm from '../../AdminForm';
import ViewShopButton from '../../Layout/Header/ViewShopButton';
import UnsavedChangesPrompt from '../../UnsavedChangesPrompt.tsx';
import HomePageLayout from './HomePageLayout';

interface Props extends LayoutPageProps {}

interface FormValues {
  isHomePageEnabled: boolean;
  homePageHeadline: string;
  homePageDescription: string;
  homePageBackground: Asset;
  homePageLayout: LayoutOption[];
}

const useStyles = makeStyles((theme: Theme) => ({
  buttonsContainer: {
    paddingTop: 50,
    paddingBottom: 30,
    gap: 8,
    width: '100%',
  },
  footerButton: {
    margin: '0px 4px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  container: {
    '& > form': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
  },
  descriptionField: {
    paddingTop: '16px',
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: '12px 12px 0px 0px',
    maxWidth: '100%',
    '& div': {
      border: 'none',
    },
    '& div.ql-toolbar': {
      borderRadius: '0px 0px 12px 12px',
    },
  },
  imageBox: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 16,
    position: 'relative',
    padding: 16,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  imagePreview: {
    aspectRatio: '1/1',
    objectFit: 'cover',
    borderRadius: 12,
    maxWidth: 328,
    width: '100%',
  },
  infoBanner: {
    marginBottom: 24,
  },
  mobileShareIcon: {
    color: theme.branding.v2.gray[400],
  },
  moreMenuIcon: {
    height: 48,
    width: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12,
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    position: 'absolute',
    backgroundColor: theme.branding.v2.gray[0],
    right: 8,
    top: 8,
    '& svg': {
      color: theme.branding.v2.gray[900],
    },
  },
  optionIcon: {
    color: theme.branding.v2.gray[500],
    marginRight: 8,
  },
  popoverContent: {
    padding: '8px 0',
    borderRadius: 12,
    width: 172,
  },
  popoverOption: {
    padding: '12px 8px',
    display: 'flex',
    flexDirection: 'row',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.branding.v2.gray[100],
    },
  },
  rightColumn: {
    marginTop: 24,
    fontWeight: 600,
    lineHeight: '24px',
  },
  toggleButton: {
    marginTop: 24,
    fontWeight: 600,
    lineHeight: '24px',
    '& button': {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
      width: '50%',
    },
    '& .MuiToggleButtonGroup-root': {
      color: theme.branding.v2.blue[500],
      marginBottom: 8,
    },
    [theme.breakpoints.down('sm')]: {
      margin: '16px 0px 32px',
    },
  },
}));

const HomePage: React.FC<LayoutPageProps> = (props: Props) => {
  const { setPageTitle, setPageIsProFeature, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const history = useHistory();
  const formRef = useRef<FormikProps<FormValues>>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [backgroundImageObj, setBackgroundImageObj] = useState(undefined);
  const [galleryAssets, setGalleryAssets] = useState([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? 'simple-popover' : undefined;

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  //presales feature flag
  const config = useConfig();
  const features = useFeatures();
  const isPresalesEnabled = features.includes('admin.presales') && config.featureFlag('feature_presales', shop);

  const findBackgroundImg = useCallback(async () => {
    const randomIndex = _.random(0, 13);
    const homepageBackgrounds = await assetRepository.findHomepageBackgrounds();
    const filteredBackgrounds = homepageBackgrounds.filter(img => img.metadata?.originalFilename?.includes('abstract'));
    const asset = filteredBackgrounds[randomIndex];

    const newImageObj = {
      id: asset.id,
      shopId: shop.id,
      downloadUrl: asset.downloadUrl,
      metadata: asset.metadata,
      options: asset.options,
    };

    setBackgroundImageObj(newImageObj);
  }, [backgroundImageObj]);

  const handleShareClick = () => {
    dispatch(
      openModal({
        modalType: 'SHARE_LINKS_MODAL',
        modalProps: {
          show: true,
          subdirectory: 'home',
        },
      }),
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    setPageTitle('Home');
    setPageIsProFeature(false);
    setBackLocation(true);
    setHeaderCTAs([
      isMobile ? (
        <IosShareIcon className={classes.mobileShareIcon} onClick={handleShareClick} />
      ) : (
        <Button variant="outlined" onClick={handleShareClick}>
          <IosShareIcon />
          &nbsp;&nbsp;Share
        </Button>
      ),
      <ViewShopButton subdirectory="home" />,
    ]);

    return () => {
      setBackLocation(false);
      setPageTitle('');
      setPageIsProFeature(false);
    };
  }, [isMobile]);

  useEffect(() => {
    setFooterCTAs([
      <DiscardButton isSubmitting={isSubmitting} backLocation="/store/pages" />,
      <SaveButton formikState={formRef.current} isSubmitting={isSubmitting} />,
    ]);

    return () => {
      setFooterCTAs([]);
    };
  }, [isSubmitting]);

  useEffect(() => {
    if (!backgroundImageObj) {
      //since we want to use gallery photos first if there are any, have to predicate the entire initial backgroundImageObj on whether they're loaded in
      shop
        .getGalleryPhotos()
        .then(result => {
          if (!!result) {
            const formattedAssets = result
              .sort((a, b) => a.position - b.position)
              .map(img => ({
                id: img.id,
                shopId: shop.id,
                downloadUrl: img.photo.downloadUrl,
                metadata: img.photo.metadata,
                options: img.photo.options,
              }));
            setGalleryAssets(result);

            if (shop && shop.shopSubpageData?.home?.backgroundImageObj) {
              const originalImageObj = shop.shopSubpageData.home.backgroundImageObj;
              const newImageObj = originalImageObj && {
                id: originalImageObj.id,
                shopId: originalImageObj.shopId,
                downloadUrl: originalImageObj.downloadUrl,
                metadata: originalImageObj.metadata,
                options: originalImageObj.options,
              };

              setBackgroundImageObj(newImageObj);
            } else if (result.length > 0) {
              const formattedAsset = formattedAssets[0];
              setBackgroundImageObj(formattedAsset);
            } else {
              findBackgroundImg().catch(err => console.error('Error getting background image: ', err));
            }
          }
        })
        .catch(err => {
          console.error('Error getting gallery photos: ', err);
        });
    }
  }, [findBackgroundImg]);

  const toggleButtonOptions: ToggleButtonOption[] = [
    {
      value: true,
      label: 'Active',
    },
    {
      value: false,
      label: 'Inactive',
    },
  ];

  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  const handleChangePhoto = () => {
    dispatch(
      openModal({
        modalType: 'BACKGROUND_PHOTO_MODAL',
        modalProps: {
          show: true,
          backgroundImageObj,
          setBackgroundImageObj,
          shop,
          galleryAssetsProp: galleryAssets,
        },
      }),
    );
  };

  const handleEditPhoto = () => {
    dispatch(
      openModal({
        modalType: 'EDIT_PHOTO_MODAL',
        modalProps: {
          show: true,
          imageLocation: 'home',
          imageObj: backgroundImageObj,
          cropShape: 'rect',
          aspectRatio: 4 / 3,
          onClose: croppedImage => {
            handleCroppedImage(croppedImage, backgroundImageObj, 'profile');
          },
          onCancel: () => dispatch(closeModal()),
        },
      }),
    );
  };

  const handleCroppedImage = async (croppedImage: File, oldImage, loc: string) => {
    const metadata = {
      id: oldImage.id,
      shopId: shop.id,
      originalFilename: oldImage.metadata.originalFilename,
      assetType: loc,
    };

    const callbacks = {
      success: handleCropUploadSuccess,
    };

    const options = {
      folder: `user/${shop.id}`,
    };

    const context = {
      shop,
    };

    await upload(croppedImage, metadata, options, callbacks, context);

    dispatch(closeModal());
  };

  const handleCropUploadSuccess = async (downloadUrl, metadata, options) => {
    const newImageObj = {
      id: metadata.id,
      shopId: shop.id,
      downloadUrl,
      metadata: metadata,
      options,
    };

    setBackgroundImageObj(newImageObj);
  };

  const onSubmit = async (values: FormValues, formikProps: FormikProps<FormValues>) => {
    setIsSubmitting(true);

    try {
      const { homePageDescription, homePageHeadline, isHomePageEnabled, homePageBackground, homePageLayout } = values;

      if (isHomePageEnabled !== shop.shopSubpageData?.home?.enabled) {
        trackEvent('Shop Page Visibility Set', {
          page: 'home',
          enabled: isHomePageEnabled,
          shop: shopToEventModel(shop),
        });
      }

      const newShop = {
        ...shop,
        shopSubpageData: {
          ...shop.shopSubpageData,
          home: {
            ...shop.shopSubpageData?.home,
            enabled: isHomePageEnabled,
            description: homePageDescription,
            headline: homePageHeadline,
            backgroundImageObj: homePageBackground,
            layout: homePageLayout,
          },
        },
      } as Shop;

      await dispatch(updateShopAction({ shop: newShop }));

      trackEvent('Shop Home Page Updated', {
        shop: shopToEventModel(shop),
      });

      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT',
          modalProps: {
            show: true,
            celebrate: true,
            content: (
              <>
                <Typography variant="h4">Home Page Updated!</Typography>
              </>
            ),
          },
        }),
      );

      setIsSubmitting(false);
      resetForm(formikProps);
      history.push('/store/pages');
    } catch (error) {
      setIsSubmitting(false);
      console.error('Error Submitting Shop Home Page Form: ', error);
    }
  };

  const popoverOptions = [
    {
      name: 'Edit Photo',
      icon: <CreateOutlinedIcon />,
      disabled: !backgroundImageObj,
      action: () => handleEditPhoto(),
    },
    {
      name: 'Replace Photo',
      icon: <AddPhotoAlternateOutlinedIcon />,
      action: () => handleChangePhoto(),
    },
  ];

  const initialLayout: LayoutOption[] = isPresalesEnabled
    ? [
        { name: 'standard', position: 0, checked: true },
        { name: 'custom', position: 1, checked: true },
        { name: 'presales', position: 2, checked: true },
        { name: 'events', position: 3, checked: true },
        { name: 'about', position: 4, checked: true },
        { name: 'gallery', position: 5, checked: true },
        { name: 'contact', position: 6, checked: true },
        { name: 'email', position: 7, checked: true },
      ]
    : [
        { name: 'standard', position: 0, checked: true },
        { name: 'custom', position: 1, checked: true },
        { name: 'events', position: 2, checked: true },
        { name: 'about', position: 3, checked: true },
        { name: 'gallery', position: 4, checked: true },
        { name: 'contact', position: 5, checked: true },
        { name: 'email', position: 6, checked: true },
      ];

  const shopHomePageLayout = shop?.shopSubpageData?.home?.layout;
  let homePageLayout: LayoutOption[] = [];

  if (shopHomePageLayout) {
    // compare existing layout and see if the shop is missing new pages in the layout
    const newPages = initialLayout.filter(initialOption =>
      shopHomePageLayout.every(shopPageOption => shopPageOption.name !== initialOption.name),
    );
    const newPagesLayout = newPages.map((newPageOption, index) => ({
      name: newPageOption.name,
      position: shopHomePageLayout.length + index,
      checked: false,
    }));
    homePageLayout = [...shopHomePageLayout, ...newPagesLayout];
  } else {
    homePageLayout = initialLayout;
  }

  const initialValues: FormValues = {
    isHomePageEnabled: shop?.shopSubpageData?.home ? shop.shopSubpageData.home.enabled : false,
    homePageHeadline: shop?.shopSubpageData?.home?.headline || shop?.businessName,
    homePageDescription:
      shop?.shopSubpageData?.home?.description !== undefined
        ? shop?.shopSubpageData?.home?.description
        : shop?.physicalAddress?.city && shop?.physicalAddress?.regionName
        ? `Serving the ${shop?.physicalAddress?.city}, ${shop?.physicalAddress?.regionName} area.`
        : '',
    homePageBackground: backgroundImageObj,
    homePageLayout,
  };

  const layoutOptionSchema = yup.object().shape({
    name: yup.string(),
    position: yup.number(),
    checked: yup.boolean(),
  });

  const homeSchema = yup.object().shape({
    isHomePageEnabled: yup.boolean(),
    homePageDescription: yup.string().nullable(),
    homePageHeadline: yup.string().when('isHomePageEnabled', {
      is: true,
      then: yup.string().required('Headline is required if Home page is enabled'),
    }),
    homePageBackground: yup.object(),
    homePageLayout: yup.array().of(layoutOptionSchema),
  });

  const resetForm = (formikProps: FormikProps<FormValues>) => {
    formikProps.setSubmitting(false);
    formikProps.resetForm();
    history.push(`/store/pages`);
  };

  return (
    <Grid container justify="center" className={classes.container}>
      <Helmet>
        <title>Home | Castiron</title>
      </Helmet>
      <Formik
        initialValues={initialValues}
        validationSchema={homeSchema}
        onSubmit={onSubmit}
        innerRef={formRef}
        enableReinitialize
      >
        {({ dirty, errors, setFieldValue, touched, values }): ReactElement => (
          <AdminForm>
            <Grid
              container
              item
              xs={12}
              direction={isMobile ? 'column' : 'row'}
              spacing={!isMobile && 6}
              wrap={isMobile ? 'wrap' : 'nowrap'}
            >
              {isMobile && (
                <Grid container item direction="column" className={classes.toggleButton}>
                  <Typography variant="subtitle2">Status</Typography>
                  <ToggleButton
                    value={values.isHomePageEnabled}
                    exclusive
                    onChange={(e: React.MouseEvent<HTMLElement>, value): void => {
                      setFieldValue('isHomePageEnabled', value);
                    }}
                    aria-label="page visibility"
                    buttonOptions={toggleButtonOptions}
                  />
                </Grid>
              )}

              <Grid container item xs={12} md={8} direction="column">
                <Banner variant="info-white" className={classes.infoBanner}>
                  <Typography variant="body4">
                    <span style={{ fontWeight: 700 }}>Shop Now</span> and{' '}
                    <span style={{ fontWeight: 700 }}>Request Custom Order</span> buttons are visible when at least one
                    product is active.
                  </Typography>
                </Banner>
                <TextInput
                  label={values.isHomePageEnabled ? 'Headline *' : 'Headline'}
                  name="homePageHeadline"
                  placeholder={''}
                  error={touched.homePageHeadline && errors.homePageHeadline}
                  maxLength={50}
                  maxLengthPadding={false}
                />
                <Grid container direction="column" item style={{ marginTop: 24 }}>
                  <Typography variant="subtitle2">Description</Typography>
                  <TextAreaInput name="homePageDescription" maxLength={120} rowCount={6} />
                  {touched.homePageDescription && errors.homePageDescription && (
                    <Typography variant="caption" color="error">
                      {errors.homePageDescription}
                    </Typography>
                  )}
                </Grid>
                <HomePageLayout />
              </Grid>

              <Grid container item xs={12} md={4} direction="column" className={classes.rightColumn}>
                <Grid container item direction="column">
                  <Grid container item direction="row" style={{ marginBottom: 8 }}>
                    <Typography variant="subtitle1">Background Photo</Typography>
                    <Tooltip
                      title="We recommend a minimum image size of 1200px x 500px."
                      style={{ marginLeft: 5, width: 20 }}
                    >
                      <InfoOutlinedIcon />
                    </Tooltip>
                  </Grid>
                  <Grid container item className={classes.imageBox}>
                    <ButtonBase className={classes.moreMenuIcon} onClick={handleMoreClick}>
                      <MoreVertOutlinedIcon />
                    </ButtonBase>
                    <Popover
                      id={popoverId}
                      open={popoverOpen}
                      onClose={handleMoreClose}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      style={{ marginTop: 8 }}
                    >
                      <Grid container direction="column" className={classes.popoverContent}>
                        {popoverOptions.map((option, index) => (
                          <ButtonBase
                            key={index}
                            className={classes.popoverOption}
                            disabled={option.disabled || false}
                            onClick={() => {
                              option.action();
                              handleMoreClose();
                            }}
                          >
                            <Grid className={classes.optionIcon}>{option.icon}</Grid>
                            <Grid container alignItems="center">
                              <Typography variant="subtitle2">{option.name}</Typography>
                            </Grid>
                          </ButtonBase>
                        ))}
                      </Grid>
                    </Popover>
                    <img className={classes.imagePreview} src={backgroundImageObj?.downloadUrl} />
                  </Grid>
                </Grid>

                {!isMobile && (
                  <Grid container item direction="column" className={classes.toggleButton}>
                    <Typography variant="subtitle2">Status</Typography>
                    <ToggleButton
                      value={values.isHomePageEnabled}
                      exclusive
                      onChange={(e: React.MouseEvent<HTMLElement>, value): void => {
                        setFieldValue('isHomePageEnabled', value);
                      }}
                      aria-label="page visibility"
                      buttonOptions={toggleButtonOptions}
                    />
                  </Grid>
                )}
              </Grid>
              {!isSubmitting && <UnsavedChangesPrompt when={dirty} />}
            </Grid>
          </AdminForm>
        )}
      </Formik>
    </Grid>
  );
};

export default HomePage;
