import * as yup from 'yup';
import { idField } from '../base/model';
import { BaseDocument } from '../base/repository';
import { addressSchema } from '../address';

export interface TimePeriod {
  id: string;
  startTime: number;
  endTime: number;
  repeatWeekly?: boolean;
}
export const timePeriodSchema = yup
  .object({
    id: idField.required(),
    startTime: yup
      .number()
      .positive()
      .required(),
    endTime: yup
      .number()
      .moreThan(yup.ref('startTime'))
      .required(),
  })
  .default(undefined);

export const processingTimeSchema = yup
  .object({
    increments: yup.number().required(),
    incrementType: yup
      .string()
      .oneOf(['hour', 'day', 'week'])
      .required(),
  })
  .default(undefined);

export interface FulfillmentOptionSchedule {
  id: string;
  type: 'flexible' | 'fixed';
  dates?: TimePeriod[];
  description?: string;
}
export const fulfillmentScheduleSchema = yup
  .object({
    id: idField,
    type: yup.string().oneOf(['flexible', 'fixed']),
    dates: yup.array(timePeriodSchema),
    description: yup.string(),
    repeatWeekly: yup.boolean(),
  })
  .default(undefined);

export const fulfillmentOptionSchema = yup
  .object()
  .shape({
    status: yup
      .string()
      .oneOf(['active', 'inactive', 'archived'])
      .nullable(),
    leadTime: processingTimeSchema.nullable(),
    type: yup
      .string()
      .oneOf(['pickup', 'delivery', 'shipping', 'custom', 'inperson', 'virtual'])
      .required(),
    displayName: yup.string().required(),
    description: yup.string().required(),
    isExpired: yup.boolean().nullable(),
    afterPurchaseDetails: yup.string().nullable(),
    postalCode: yup.string().nullable(),
    fee: yup.number().nullable(),
    minimum: yup.number().nullable(),
    date: yup.number().nullable(), // legacy, needs deprecated
    fulfillmentNotes: yup.string().nullable(),
    notes: yup.string().nullable(),
    startDate: yup.number().nullable(), // legacy, needs deprecated
    endDate: yup.number().nullable(), // legacy, needs deprecated
    recipient: yup.string().nullable(),
    address: addressSchema(false),
    processingTime: processingTimeSchema.nullable(),
    schedule: fulfillmentScheduleSchema.nullable(),
    sendPickupReminderEmail: yup.boolean().nullable(),
    useBusinessAddress: yup.boolean().nullable(),
    source: yup.string().nullable(),
  })
  .default(undefined);

export type CalendarEventType = 'availability';
/* available exists, but will not actually ever be stored in the db */
export type AvailabilitySubtype = 'available' | 'limited' | 'unavailable';
export interface CalendarEvent extends BaseDocument<CalendarEvent> {
  type: CalendarEventType;
  subtype: AvailabilitySubtype;
  startTime: number;
  endTime: number;
  title?: string;
  description?: string;
}

/* SEO, property naming reflects structured data props */
export interface ShopSeoLocation {
  /* basically the city */
  addressLocality: string;
  /* state abbreviation */
  addressRegion: string;
  addressCountry: string;
  postalCode: string;
}

export interface ShopSeoMetadata {
  manuallyUpdated?: boolean;
  address: ShopSeoLocation;
  shopTitle: string;
  shopDescription: string;
  socialImage?: string;
  socialPageTitle: string;
  socialPageDescription: string;
}
