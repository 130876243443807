import React, { ReactNode } from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CheckCircleOutline, ErrorOutline, InfoOutlined, StarBorder, VisibilityOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { DomainVerificationOutlinedIcon, MegaphoneOutlinedIcon, MoneyIcon } from '../Icons';
import { ShopTheme } from '@castiron/domain';

interface Props {
  variant:
    | 'success'
    | 'success-no-icon'
    | 'error'
    | 'info-blue'
    | 'info-white'
    | 'info-yellow'
    | 'star-blue'
    | 'money'
    | 'eye-blue'
    | 'domain-blue'
    | 'important-red'
    | 'info-plum'
    | 'star-plum'
    | 'eye-plum'
    | 'domain-plum';
  children: ReactNode;
  className?: string;
  noIcon?: boolean;
  iconAtStart?: boolean;
  customIcon?: ReactNode;
  backgroundColor?: string;
  color?: string;
  themeProps?: ShopTheme;
}

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  blueContainer: {
    color: theme.branding.v2.blue[500],
    backgroundColor: theme.branding.v2.blue[50],
  },
  plumContainer: {
    color: theme.branding.v2.plum[500],
    backgroundColor: theme.branding.v2.plum[50],
  },
  blueIcon: {
    color: theme.branding.v2.blue[500],
  },
  plumIcon: {
    color: theme.branding.v2.plum[500],
  },
  container: {
    position: 'relative',
    width: '100%',
    padding: 16,
    borderRadius: '12px',
    backgroundColor: props =>
      props.themeProps
        ? theme.branding.v2[props.themeProps.shopButtonColor || 'blue'][50]
        : props.backgroundColor || undefined,
    '& > *': {
      color: props => props.color || undefined,
    },
    '& svg': {
      color: props =>
        props.themeProps
          ? theme.branding.v2[props.themeProps.shopButtonColor || 'blue'][500]
          : props.color || undefined,
    },
  },
  domainContainer: {
    color: theme.branding.v2.gray[900],
    backgroundColor: theme.branding.v2.blue[50],
  },
  domainContainerPlum: {
    color: theme.branding.v2.gray[900],
    backgroundColor: theme.branding.v2.plum[50],
  },
  icon: {
    height: '20px',
    width: '20px',
  },
  infoWhite: {
    color: theme.branding.v2.gray[700],
    backgroundColor: theme.branding.v2.gray[100],
  },
  infoWhiteIcon: {
    color: theme.branding.v2.gray[800],
  },
  infoYellow: {
    color: theme.branding.orange.primary,
    backgroundColor: theme.branding.yellow.light,
  },
  infoYellowIcon: {
    color: theme.branding.orange.primary,
  },
  money: {
    color: theme.branding.green.primary,
    backgroundColor: theme.branding.green.light,
  },
  noIcon: {
    paddingLeft: 16,
  },
  redContainer: {
    color: theme.branding.v2.red[500],
    backgroundColor: theme.branding.v2.red[50],
  },
  redIcon: {
    color: theme.branding.v2.red[500],
  },
  starIcon: {
    top: '16px',
  },
  success: {
    color: theme.branding.green.primary,
    backgroundColor: theme.branding.green.light,
  },
  successIcon: {
    color: theme.branding.green.primary,
  },
}));

const Banner: React.FC<Props> = (props: Props) => {
  const { variant, children, className, noIcon, iconAtStart, customIcon, backgroundColor, color, themeProps } = props;

  const classes = useStyles(props);

  let icon, containerClass;
  switch (variant) {
    case 'success':
      icon = <CheckCircleOutline className={clsx(classes.icon, classes.successIcon)} />;
      containerClass = classes.success;
      break;
    case 'success-no-icon':
      icon = <></>;
      containerClass = classes.success;
      break;
    case 'error':
      icon = <ErrorOutline className={clsx(classes.icon, classes.redIcon)} />;
      containerClass = classes.redContainer;
      break;
    case 'info-blue':
      icon = <InfoOutlined className={clsx(classes.icon, classes.blueIcon)} />;
      containerClass = classes.blueContainer;
      break;
    case 'info-plum':
      icon = <InfoOutlined className={clsx(classes.icon, classes.plumIcon)} />;
      containerClass = classes.plumContainer;
      break;
    case 'info-white':
      icon = <InfoOutlined className={clsx(classes.icon, classes.infoWhiteIcon)} />;
      containerClass = classes.infoWhite;
      break;
    case 'info-yellow':
      icon = <InfoOutlined className={clsx(classes.icon, classes.infoYellowIcon)} />;
      containerClass = classes.infoYellow;
      break;
    case 'money':
      icon = <MoneyIcon className={clsx(classes.icon, classes.money)} />;
      containerClass = classes.money;
      break;
    case 'star-blue':
      icon = <StarBorder className={clsx(classes.icon, classes.starIcon)} />;
      containerClass = classes.blueContainer;
      break;
    case 'star-plum':
      icon = <StarBorder className={clsx(classes.icon, classes.starIcon)} />;
      containerClass = classes.plumContainer;
      break;
    case 'eye-blue':
      icon = <VisibilityOutlined className={clsx(classes.icon, classes.blueIcon)} />;
      containerClass = classes.blueContainer;
      break;
    case 'eye-plum':
      icon = <VisibilityOutlined className={clsx(classes.icon, classes.plumIcon)} />;
      containerClass = classes.plumContainer;
      break;
    case 'domain-blue':
      icon = <DomainVerificationOutlinedIcon className={clsx(classes.icon, classes.blueIcon)} />;
      containerClass = classes.domainContainer;
      break;
    case 'domain-plum':
      icon = <DomainVerificationOutlinedIcon className={clsx(classes.icon, classes.plumIcon)} />;
      containerClass = classes.domainContainerPlum;
      break;
    case 'important-red':
      icon = <MegaphoneOutlinedIcon className={clsx(classes.icon, classes.redIcon)} />;
      containerClass = classes.redContainer;
      break;
  }

  return (
    <Grid
      container
      item
      wrap="nowrap"
      alignItems={iconAtStart ? 'flex-start' : 'center'}
      className={clsx(classes.container, containerClass, className, noIcon && classes.noIcon)}
    >
      {!noIcon && <Grid style={{ marginRight: 16 }}>{customIcon || icon}</Grid>}
      {children}
    </Grid>
  );
};

export default Banner;
