import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { Box, Button, Grid, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FavoriteBorder, PhotoLibraryOutlined, HelpOutline, SendOutlined } from '@material-ui/icons/';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import { FeatureFlag } from '@castiron/castiron-firebase';
import {
  CalendarIcon,
  Chip,
  CottageIcon,
  ProFeatureChip,
  RequestQuoteIcon,
  Typography,
  useFeatures,
} from '@castiron/components';
import { ChecklistValues, GalleryPhoto, FeatureName, Presale } from '@castiron/domain';
import { getProductStatus } from '@castiron/utils';
import { useHistory } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../hooks';
import { getShopAction } from '../../../store/reducers/shops';
import { getProductsAction } from '../../../store/reducers/products';
import { LayoutPageProps } from '../../Layout';
import ViewShopButton from '../../Layout/Header/ViewShopButton';
import { SettingLink } from '../SettingsDashboard';
import { openModal } from '../../../store/reducers/modalConductor';
import ShoppingBasketOutlined from '@material-ui/icons/ShoppingBasketOutlined';
import { getService } from '../../../firebase';
import { specialRepository } from '../../../domain';
import Spinner from '../../Spinner';

const getAllPresalesService = getService('presales', 'getallpresalesv2');

interface Props extends LayoutPageProps {
  browseButtonRef?: React.MutableRefObject<any>;
}

interface PagesSettingLink extends SettingLink {
  activeTag: boolean;
  backgroundColor?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: '16px 16px 18px 16px',
    border: `1px solid ${theme.branding.gray[400]}`,
    '&:hover': {
      backgroundColor: theme.branding.gray[100],
      border: `1px solid ${theme.branding.blue.primary}`,
    },
    justifyContent: 'flex-start',
    height: '100%',
  },
  buttonContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: '8px 16px',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '12px',
    },
  },
  buttonContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  buttonText: {
    marginLeft: '16px',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  chip: {
    marginTop: 4,
  },
  proChip: {
    marginLeft: 8,
  },
  icon: {
    height: '30px',
    width: '30px',
  },
  iconContainer: {
    color: theme.branding.v2.blue[500],
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 12,
    height: '70px',
    width: '92px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const ShopPages: React.FC<LayoutPageProps> = (props: Props) => {
  const { setPageTitle, setPageIsProFeature, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const features = useFeatures();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [galleryPhotos, setGalleryPhotos] = useState<GalleryPhoto[]>([]);
  const [galleryActiveTag, setGalleryActiveTag] = useState<boolean>(false);
  const [shopPresales, setShopPresales] = useState<Presale[]>([]);
  const [specialPages, setSpecialPages] = useState<PagesSettingLink[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { account, shop, products } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
    products: state.products.products,
  }));

  const hasStandardProducts = !_.isEmpty(
    products.filter(p => p.type === 'standard' && getProductStatus(p, shop?.config?.timeZone) === 'active'),
  );
  const hasQuoteProducts = !_.isEmpty(
    products.filter(p => p.type === 'custom' && getProductStatus(p, shop?.config?.timeZone) === 'active'),
  );
  const hasTicketedEventProducts = !_.isEmpty(
    products.filter(p => p.type === 'event' && getProductStatus(p, shop?.config?.timeZone) === 'active'),
  );

  const hasShopPresales = !_.isEmpty(shopPresales);

  const getPresales = async () => {
    const response = (await getAllPresalesService({
      shopId: shop?.id
    }))?.filter(p => ['active', 'scheduled'].includes(getProductStatus(p, shop?.config?.timeZone)));
    setShopPresales(response);
  };

  useEffect(() => {
    getPresales();
  }, []);

  useEffect(() => {
    const getActiveEventPages = async () => {
      const activeShopEvents = await specialRepository.findActiveShopEvents(shop?.config?.timeZone);
      const activePages = activeShopEvents.map(event => {
        const eventTag = event.tag;
        const hasSpecialProducts = !_.isEmpty(
          products.filter(
            p => p?.eventTags?.includes(eventTag) && getProductStatus(p, shop?.config?.timeZone) === 'active',
          ),
        );
        return {
          name: event.name,
          subtitle: event?.shopContent?.dashboard?.subtitle,
          icon: event?.shopContent?.icon,
          path: `/store/event/${event?.slug}`,
          feature: 'shop.subpages',
          backgroundColor: event?.shopContent?.dashboard?.backgroundColor,
          activeTag:
            shop?.shopSubpageData?.events?.find(e => e?.tag == eventTag)?.enabled !== false && hasSpecialProducts,
        };
      });

      setSpecialPages(activePages as PagesSettingLink[]);
      setIsLoading(false);
    };
    getActiveEventPages();
  });

  const addPagesChecklist = async () => {
    await shop.addToChecklist(ChecklistValues.Pages);
    await dispatch(getShopAction(shop.id));
  };

  useEffect(() => {
    getShop(shop);

    setPageTitle('Pages');
    setPageIsProFeature(false);
    setBackLocation('/store/dashboard');
    setHeaderCTAs([<ViewShopButton />]);
    setFooterCTAs([]);
    if (!shop.checklistCompletions.includes(ChecklistValues.Pages)) {
      addPagesChecklist();
    }

    return () => {
      setBackLocation(false);
      setFooterCTAs([]);
      setPageTitle('');
      setPageIsProFeature(false);
    };
  }, []);

  const getGalleryPhotos = async () => {
    const photos = await shop.getGalleryPhotos();
    setGalleryPhotos(photos);
    return photos;
  };

  const getStoreProducts = async shop => {
    await dispatch(getProductsAction(shop.id));
  };

  const getShop = async shop => {
    await dispatch(getShopAction(shop.id));
  };

  useEffect(() => {
    if (shop) {
      getGalleryPhotos();
      getStoreProducts(shop);
    }
  }, [shop]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (shop.shopSubpageData?.isGalleryPageEnabled) {
      setGalleryActiveTag(true);
    } else if (shop.shopSubpageData?.isGalleryPageEnabled === false) {
      setGalleryActiveTag(false);
    } else if (galleryPhotos.length > 0) {
      // ok to be falsey but not false
      setGalleryActiveTag(true);
    }
  }, [shop, galleryPhotos]);

  const links: PagesSettingLink[] = [
    ...specialPages,
    {
      name: 'Home',
      subtitle: 'Frame the message you want your customers to see first.',
      icon: <CottageIcon className={classes.icon} />,
      path: '/store/home',
      feature: 'shop.subpages',
      activeTag: shop?.shopSubpageData?.home?.enabled,
    },
    {
      name: 'Shop',
      subtitle: 'Your online shop for instant checkout products.',
      icon: <ShoppingCartOutlinedIcon className={classes.icon} />,
      path: '/store/shop',
      feature: 'shop.subpages',
      activeTag: shop?.shopSubpageData?.isShopPageEnabled && hasStandardProducts,
    },
    {
      name: 'Order Forms',
      subtitle: 'Your streamlined process of getting quotes started.',
      icon: <RequestQuoteIcon className={classes.icon} />,
      path: '/store/order-forms',
      feature: 'shop.subpages',
      activeTag: shop?.shopSubpageData?.quotes?.enabled && hasQuoteProducts,
    },
    {
      name: 'Events',
      subtitle: 'Your online shop for experiences, classes, and other ticketed events.',
      icon: <ConfirmationNumberOutlinedIcon className={classes.icon} />,
      path: '/store/events',
      feature: 'shop.subpages',
      activeTag: shop?.shopSubpageData?.ticketedEvents?.enabled && hasTicketedEventProducts,
    },
    {
      name: 'Presales',
      subtitle: 'Launch product offerings for a limited time.',
      icon: <ShoppingBasketOutlined className={classes.icon} />,
      path: '/store/presales',
      feature: 'admin.presales',
      flag: 'feature_presales',
      activeTag: shop?.shopSubpageData?.presales?.enabled && hasShopPresales,
    },
    {
      name: 'Gallery',
      subtitle: 'Showcase your current and past work.',
      icon: <PhotoLibraryOutlined className={classes.icon} />,
      path: '/store/gallery',
      feature: 'shop.gallery',
      activeTag: galleryActiveTag,
    },
    {
      name: 'Availability',
      subtitle: 'Provide a calendar on your site with your general availability.',
      icon: <CalendarIcon className={classes.icon} />,
      path: '/store/availability',
      feature: 'admin.calendar.availability',
      proFeature: true,
      activeTag: shop.shopSubpageData?.availability?.enabled,
    },
    {
      name: 'Custom Page',
      subtitle: 'Create a fully custom page on your website for whatever information you need to share with customers.',
      icon: <DashboardOutlinedIcon className={classes.icon} />,
      path: '/store/custom',
      feature: 'shop.subpages.custom',
      proFeature: true,
      activeTag: !!shop?.shopSubpageData?.custom?.enabled,
    },
    {
      name: 'About',
      subtitle: 'Tell customers your story and about your business.',
      icon: <FavoriteBorder className={classes.icon} />,
      path: '/store/about',
      feature: 'shop.subpages',
      activeTag: shop.shopSubpageData?.isAboutPageEnabled === undefined || shop.shopSubpageData?.isAboutPageEnabled,
    },
    {
      name: 'FAQ',
      subtitle: 'Outline common questions and their answers.',
      icon: <HelpOutline className={classes.icon} />,
      path: '/store/faq',
      feature: 'shop.subpages',
      activeTag: shop.shopSubpageData?.isFaqPageEnabled,
    },
    {
      name: 'Contact',
      subtitle: 'Allow customer to send messages to your email.',
      icon: <SendOutlined className={classes.icon} />,
      path: '/store/contact',
      feature: 'shop.subpages',
      activeTag: shop.shopSubpageData?.isContactPageEnabled,
    },
  ] as PagesSettingLink[];

  /* wish I could've found a faster way to avoid reproducing this exact same code block here */
  const isQualified = useCallback(
    (feature: FeatureName) => {
      return !feature || features.includes(feature);
    },
    [features],
  );

  const displayProFeatureChip = useCallback(
    (link: SettingLink) => {
      return link.proFeature && !account.isCastironPlus() && (!features.includes(link.feature) || account.isInTrial());
    },
    [account, features],
  );

  return (
    <Grid container className={classes.buttonsWrapper} justify={!isMobile ? 'flex-start' : 'center'}>
      <Helmet>
        <title>Pages | Castiron</title>
      </Helmet>
      <Spinner show={isLoading} size="fullscreen" />
      {links.map((link, index) =>
        displayProFeatureChip(link) || !isQualified(link.feature) ? (
          undefined
        ) : (
          <FeatureFlag name={link.flag} key={index}>
            <Grid
              container
              xs={12}
              sm={6}
              lg={4}
              item
              className={classes.buttonContainer}
              justify={!isMobile ? 'flex-start' : 'center'}
            >
              <Button
                onClick={() => {
                  if (isQualified(link.feature)) {
                    history.push(link.path);
                  } else {
                    dispatch(
                      openModal({
                        modalType: 'LEGACY_GATING_MODAL',
                        modalProps: {
                          show: true,
                        },
                      }),
                    );
                  }
                }}
                variant="outlined"
                className={classes.button}
                fullWidth
              >
                <Grid className={classes.buttonContent}>
                  <Grid
                    className={classes.iconContainer}
                    style={link.backgroundColor ? { backgroundColor: link.backgroundColor, fontSize: '24px' } : {}}
                  >
                    {link.icon}
                  </Grid>
                  <Grid container direction="column" className={classes.buttonText}>
                    <Grid item>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item container>
                          <Typography variant="subtitle1">{link.name}</Typography>
                          {displayProFeatureChip(link) && (
                            <Box className={classes.proChip}>
                              <ProFeatureChip />
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item container direction="column">
                      <Typography variant="body4">{link.subtitle}</Typography>
                      <Chip className={classes.chip} colorScheme={link.activeTag ? 'success' : 'warning'}>
                        {link.activeTag ? 'Live' : 'Inactive'}
                      </Chip>
                    </Grid>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          </FeatureFlag>
        ),
      )}
    </Grid>
  );
};

export default ShopPages;
