import React, { useCallback } from 'react';
import { ListItem, TextField, Grid } from '@material-ui/core';
import Autocomplete, { AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Field, getIn, useFormikContext } from 'formik';
import _ from 'lodash';
import InputWrapper, { BaseInputProps } from '../InputWrapper';
import Typography from '../../Typography';
import ListboxComponent from './ListboxComponent';

export type GroupedSelectOption = {
  value: any;
  selectedDisplay?: string;
  listDisplay?: string;
};

interface Props extends BaseInputProps {
  name: string;
  options: GroupedSelectOption[];
  groupBy: (option) => string;
  label?: string;
  disabled?: boolean;
  getListDisplay?: (option) => string;
  getSelectedDisplay?: (option) => string;
}

const useStyles = makeStyles((theme: Theme) => ({
  autocompleteRoot: {
    width: '100%',
  },
  disabled: {
    textAlign: 'left',
    backgroundColor: theme.branding.v2.gray[50],
    borderRadius: 12,
  },
  input: {
    fontSize: 16,
    fontWeight: 400,
    padding: 12,
  },
  inputRoot: {
    minHeight: 56,
    width: '100%',
    border: 'none',
    borderRadius: '12px',
  },
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
}));

const GroupedSelectInput: React.FC<Props> = (props: Props) => {
  const { name, options, groupBy, label, disabled, error, required, getListDisplay, getSelectedDisplay } = props;
  const { values, setFieldValue, errors, setFieldTouched } = useFormikContext();
  const classes = useStyles();

  const renderGroup = (params: AutocompleteRenderGroupParams) => [
    <Grid key={params.key}>
      <Typography variant="subtitle2">{params.group}</Typography>
    </Grid>,
    params.children,
  ];

  const renderInputCallback = useCallback(
    params => (
      <div ref={params.InputProps.ref}>
        <InputWrapper required={required} label={!!label ? label : name} error={error}>
          <TextField
            {...params}
            name={name}
            value={getIn(values, name)}
            variant="outlined"
            InputProps={{ classes: { root: classes.inputRoot, input: classes.input } }}
            style={{ margin: 0 }}
            disabled={disabled}
            error={!!error}
          />
        </InputWrapper>
      </div>
    ),
    [values, error],
  );

  return (
    <Field name={name} value={getIn(values, name)}>
      {props => (
        <Autocomplete
          freeSolo
          value={getIn(values, name)}
          blurOnSelect
          classes={{ listbox: classes.listbox, root: classes.autocompleteRoot }}
          ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
          renderGroup={renderGroup}
          options={options}
          groupBy={option => groupBy(option)}
          renderInput={params => renderInputCallback(params)}
          getOptionLabel={option => option.selectedDisplay || getSelectedDisplay(option)}
          renderOption={option => (
            <ListItem value={option.value} key={`selectOption${option.value}`}>
              <Typography variant="body2">{option.listDisplay || getListDisplay(option)}</Typography>
            </ListItem>
          )}
          onBlur={event => setFieldTouched(name)}
          onChange={(event, value) => setFieldValue(name, value)}
        />
      )}
    </Field>
  );
};

export default GroupedSelectInput;
