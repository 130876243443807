import firebase from 'firebase/compat/app';
import _firestore from '@google-cloud/firestore';
import { nanoid } from '@reduxjs/toolkit';
import _, { find } from 'lodash';
import moment from 'moment';
import dataOnly from '../_lib/dataOnly';
import { AccountTier } from '../account';
import { Address } from '../address';
import { Attribution } from '../attribution';
import { Condition, FieldFunctions } from '../base/repository';
import { Coupon } from '../coupon';
import { Customer } from '../customer';
import { SelectedInputFieldValue } from '../inputfield/model';
import { LineItem, Order, ProcessorInfo } from '../order';
import { Product } from '../product';
import { ShopRelatedDocument, ShopRelatedRepository } from '../shop';
import { FulfillmentOptionSchedule } from '../shop/model';

export interface Fees {
  stripeFees?: number;
  castIronFees?: number;
  totalCustomerFees?: number;
  castironCustomerFees?: number;
  totalArtisanFees?: number;
  castironArtisanFees?: number;
  totalFees?: number;
  customerPaidStripeFees?: boolean;
  customerFeePercent?: number;
  artisanFeePercent?: number;
  applicationFees?: number;
  takeRate?: number;
}

export interface TransactionTotals extends Fees {
  subtotal: number;
  taxes: number;
  fulfillmentFee: number;
  coupon: number;
  tip: number;
  totalWithTax: number;
  totalWithoutFees: number;
  totalWithoutFeesOrTip: number;
  total: number;
  subTotalsWithFulfillment?: number;
  subTotalWithCoupon?: number;
  subTotalWithTip?: number;
  tippingPresetPercentage?: number;
}

export interface ShippingInfo {
  recipientName: string;
  message?: string;
  address: Address;
}

export interface LegalInfo {
  hasAgreedToOrderDetails: boolean;
  hasAgreedToTerms: boolean;
}

export interface CartItem {
  quantity: number;
  shopId?: string;
  product: Product;
  selectedVariationValues?: SelectedInputFieldValue[];
  fromPage?: string;
}

export type CarrierOption = 'usps' | 'ups' | 'fedex' | 'dhlexpress' | 'other';

export interface FulfillOrderInfo {
  note: string;
  trackingNumber?: string;
  shippingCarrier?: CarrierOption;
  shippingCarrierLabel?: string;
  sendArtisanEmail?: boolean;
  sendCustomerEmail?: boolean;
  trackingNumberUrl?: string;
  orderFulfilledAt?: number;
}

export type FrontendTransactionState =
  | 'archived'
  | 'canceled'
  | 'completed'
  | 'draft'
  | 'fulfilled'
  | 'new'
  | 'open'
  | 'paid'
  | 'partially paid'
  | 'pending'
  | 'rejected'
  | 'sent'
  | 'unknown';
export type TransactionContext = 'order' | 'quote';

export interface Transaction extends ShopRelatedDocument<Transaction> {
  type: 'transaction' | 'sub-transaction';
  amountType?: 'dollars' | 'percentage';
  parentId?: string;
  customer?: string;
  customerObj?: Customer;
  order: Order;
  products?: CartItem[];
  totals?: TransactionTotals;
  transactionStatus: 'requested' | 'pending' | 'succeeded' | 'failed' | 'partially-paid';
  notes?: string;
  status:
    | 'active'
    | 'inactive'
    | 'deleted'
    | 'open'
    | 'fulfilled'
    | 'completed'
    | 'canceled'
    | 'partially-paid'
    | 'proposed'
    | 'rejected'
    | 'agreed';
  coupon?: Coupon; // Deprecated, moving Order.payments[].coupon
  processor?: ProcessorInfo; // Deprecated, moving Order.payments[].processor
  isGift?: boolean;
  shippingInfo?: ShippingInfo;
  tier: AccountTier;
  artisanNotes?: string;
  legalInfo?: LegalInfo;
  fulfillOrderInfo?: FulfillOrderInfo;
  rejectionNote?: string;
  attribution?: Attribution;
  isArchived?: boolean;
  pickupReminderSent?: boolean;
  thankYouCoupon?: {
    lastSentDate: number;
    timesSent: number;
  };
  frontendState?: (context?: TransactionContext) => FrontendTransactionState;
  updateDueDate?: (start: number, end?: number) => Promise<Transaction>;

  getSubTransactions?: () => Promise<Transaction[]>;
}

export const backendStateToFrontendState = (
  tx: Transaction,
  context?: TransactionContext,
): FrontendTransactionState => {
  if (tx) {
    const { status, transactionStatus: txStatus, isArchived, type } = tx;
    const ctx = context || tx.order.stage;

    /* order page statuses */
    if (ctx === 'order') {
      if (tx?.order?.fulfillmentOption?.type === 'inperson') return 'fulfilled';
      if (status === 'agreed' && (txStatus === 'succeeded' || txStatus === 'partially-paid')) return 'open';
      if (status === 'open' && (txStatus === 'succeeded' || txStatus === 'partially-paid')) return 'open';
      if (status === 'completed' && (txStatus === 'succeeded' || txStatus === 'partially-paid')) return 'completed';
      if (status === 'fulfilled' && txStatus === 'succeeded') return 'fulfilled';
      if (status === 'rejected') return 'rejected';
      if (status === 'deleted' || status === 'canceled') return 'canceled';
    }

    /* these represent quote page statuses */
    if (ctx === 'quote') {
      if (isArchived) return 'archived';
      if (txStatus === 'partially-paid') return 'partially paid';
      if (txStatus === 'succeeded') return 'paid';
      if (status === 'open') return 'new';
      if (status === 'proposed') return 'draft';
      if (status === 'agreed') return 'pending';
      if (status === 'rejected') return 'rejected';
      if (status === 'deleted' || status === 'canceled') return 'canceled';
    }
  }

  return 'unknown';
};

export class TransactionRepository extends ShopRelatedRepository<Transaction> {
  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, fieldFunctions?: FieldFunctions) {
    super(firestore, 'transactions', fieldFunctions);
  }

  private findStandardTransactions(): Condition<Transaction>[] {
    return [
      { field: 'order.type', operator: '==', value: 'standard' },
      { field: 'status', operator: '!=', value: 'deleted' },
      { field: 'type', operator: '==', value: 'transaction' },
    ];
  }

  private findCustomTransactions(): Condition<Transaction>[] {
    return [
      { field: 'order.type', operator: '==', value: 'custom' },
      { field: 'status', operator: '!=', value: 'deleted' },
      { field: 'type', operator: '==', value: 'transaction' },
    ];
  }

  public async getStandardTransactions(shopId: string, limit?: number): Promise<Transaction[]> {
    return this.find({
      where: [this.whereShopIs(shopId), ...this.findStandardTransactions()],
      orderBy: [
        { field: 'status', direction: 'asc' },
        { field: 'createdAt', direction: 'desc' },
      ],
      limit,
    });
  }

  public async getCustomTransactions(shopId: string, limit?: number): Promise<Transaction[]> {
    return this.find({
      where: [this.whereShopIs(shopId), ...this.findCustomTransactions()],
      orderBy: [
        { field: 'status', direction: 'asc' },
        { field: 'createdAt', direction: 'desc' },
      ],
      limit,
    });
  }

  public async getAllTransactions(shopId: string, limit?: number): Promise<Transaction[]> {
    return this.find({
      where: [this.whereShopIs(shopId), { field: 'type', operator: '==', value: 'transaction' }],
      orderBy: [
        { field: 'status', direction: 'asc' },
        { field: 'createdAt', direction: 'desc' },
      ],
      limit,
    });
  }

  public async getHistoricalTransactions({
    shopId,
    startDate,
    endDate,
  }: {
    shopId: string;
    startDate: number;
    endDate: number;
  }) {
    const results = await this.find({
      where: [
        this.whereShopIs(shopId),
        { field: 'type', operator: '==', value: 'transaction' },
        { field: 'transactionStatus', operator: '==', value: 'succeeded' },
        { field: 'createdAt', operator: '>=', value: startDate },
        { field: 'createdAt', operator: '<', value: endDate },
      ],
      orderBy: [{ field: 'createdAt', direction: 'desc' }],
    });
    /* need to do this because firestore isn't happy and about multiple property inequalities in a single query,
     * should be very rare that this causes any performance issues, as the time series should reduce the number of transactions significantly
     */
    return results.filter((tx: Transaction): boolean => tx.status !== 'deleted');
  }

  public async findTransactionsByUtm(
    shopId: string,
    term: string,
    findBy: 'source' | 'campaign',
  ): Promise<Transaction[]> {
    return this.find({
      where: [
        this.whereShopIs(shopId),
        { field: 'type', operator: '==', value: 'transaction' },
        {
          field: `attribution.utmParams.${findBy}`,
          operator: '==',
          value: term,
        },
      ],
    });
  }

  public findTransactionsForCustomerAndCoupon(
    shopId: string,
    customerId: string,
    couponId: string,
  ): Promise<Transaction[]> {
    if (shopId && customerId && couponId) {
      const comparisons: Condition<Transaction>[] = [
        {
          field: 'shopId',
          operator: '==',
          value: shopId,
        },
        {
          field: 'customer',
          operator: '==',
          value: customerId,
        },
        {
          field: 'coupon.id',
          operator: '==',
          value: couponId,
        },
        { field: 'type', operator: '==', value: 'transaction' },
      ];
      return this.find({ where: comparisons });
    }
    return Promise.resolve([]);
  }

  public async create(transaction: Transaction): Promise<Transaction> {
    const accountRef = this.db.collection('accounts').doc(transaction.shopId);
    const newTxRef = this.db.collection('transactions').doc();
    return this.db.runTransaction(async tx => {
      const account = await tx.get(accountRef);
      const orderNumberCounter = account.data().orderNumberCounter + 1;
      tx.update(accountRef, {
        orderNumberCounter,
      });
      const newTx = {
        ...transaction,
        createdAt: moment().unix(),
        order: {
          ...transaction.order,
          orderNumber: orderNumberCounter.toString(),
        },
      };
      tx.set(newTxRef, dataOnly(newTx));
      return this.bless({
        id: newTxRef.id,
        ...newTx,
      } as Transaction);
    });
  }

  public async findByPaymentDueDate(start: number, end: number): Promise<Transaction[]> {
    return this.find({
      where: [
        { field: 'order.paymentDueDate', operator: '>=', value: start },
        { field: 'order.paymentDueDate', operator: '<', value: end },
        { field: 'transactionStatus', operator: '==', value: 'requested' },
        { field: 'status', operator: '==', value: 'agreed' },
        { field: 'type', operator: '==', value: 'transaction' },
      ],
    });
  }

  public async findByFutureStandardPickupReminder(): Promise<Transaction[]> {
    return this.find({
      where: [
        { field: 'order.type', operator: '==', value: 'standard' },
        { field: 'order.fulfillmentOption.sendPickupReminderEmail', operator: '==', value: true },
        { field: 'status', operator: '==', value: 'open' },
        { field: 'transactionStatus', operator: '==', value: 'succeeded' },
        { field: 'type', operator: '==', value: 'transaction' },
      ],
    });
  }

  public async findByFutureCustomPickupReminder(): Promise<Transaction[]> {
    return this.find({
      where: [
        { field: 'order.type', operator: '==', value: 'custom' },
        { field: 'order.fulfillmentOption.sendPickupReminderEmail', operator: '==', value: true },
        { field: 'transactionStatus', operator: '==', value: 'succeeded' },
        { field: 'type', operator: '==', value: 'transaction' },
      ],
    });
  }

  public async findByFutureEventReminder(): Promise<Transaction[]> {
    return this.find({
      where: [
        { field: 'order.type', operator: '==', value: 'standard' },
        { field: 'status', operator: '==', value: 'open' },
        { field: 'transactionStatus', operator: '==', value: 'succeeded' },
        { field: 'type', operator: '==', value: 'transaction' },
      ],
    });
  }

  public async updateDueDate(tx: Transaction, startTime: number, endTime?: number) {
    if (tx && startTime && endTime) {
      /* we want to create a new one-off schedule */
      const newSchedule: FulfillmentOptionSchedule = {
        id: nanoid(),
        type: 'fixed',
        dates: [
          {
            id: nanoid(),
            startTime,
            endTime,
          },
        ],
      };

      const newTx = {
        ...tx,
        order: {
          ...tx.order,
          fulfillmentOption: {
            ...tx.order.fulfillmentOption,
            schedule: newSchedule,
            /* hack here to support backwards compatibility with calendar */
            date: startTime,
          },
        },
      };
      return this.update(newTx);
    }

    if (tx && startTime) {
      /* we only want a start date, so we should save it there appropriately and delete any existing schedule */
      const newTx = {
        ...tx,
        order: {
          ...tx.order,
          fulfillmentOption: {
            ..._.omit(tx.order.fulfillmentOption, 'schedule'),
            date: startTime,
          },
        },
      };
      return this.update(newTx);
    }
  }

  public async findFulfilledTransactions(): Promise<Transaction[]> {
    return this.find({
      where: [
        { field: 'status', operator: '==', value: 'fulfilled' },
        { field: 'transactionStatus', operator: '==', value: 'succeeded' },
        { field: 'type', operator: '==', value: 'transaction' },
      ],
    });
  }

  public async getSubTransactions(parentId: string): Promise<Transaction[]> {
    return this.find({
      where: [
        { field: 'parentId', operator: '==', value: parentId },
        { field: 'type', operator: '==', value: 'sub-transaction' },
      ],
    });
  }

  protected enrichWith(): any {
    return {
      _repository: this,
      frontendState(context?: TransactionContext) {
        return backendStateToFrontendState(this, context);
      },
      updateDueDate(startTime: number, endTime?: number) {
        return this._repository.updateDueDate(this, startTime, endTime);
      },
      getSubTransactions() {
        return this._repository.getSubTransactions(this.id);
      },
      data() {
        return dataOnly(_.omit(this, ['_repository']));
      },
    };
  }
}

export const cartItemToOrderedProduct = (cartProduct: CartItem): LineItem => ({
  id: cartProduct.product.id,
  title: cartProduct.product.title,
  type: cartProduct.product.type,
  price: (cartProduct.product as Product).price,
  description: cartProduct.product.description,
  category: cartProduct.product.category,
  quantity: cartProduct.quantity,
  total: cartProduct.product.price * cartProduct.quantity,
});

export const isCustomOrder = (items: LineItem[]): boolean => {
  const custOrder = items ? items.find(p => p?.type === 'custom') : null;
  return !!custOrder;
};
