import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Button, CircularProgress, Grid, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SaveButton, DiscardButton, ToggleButton, ToggleButtonOption, Typography } from '@castiron/components';
import { ShopFaq, shopToEventModel } from '@castiron/domain';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { updateShopAction } from '../../../store/reducers/shops';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { LayoutPageProps } from '../../Layout';
import { Formik, FormikProps, useFormikContext } from 'formik';
import * as yup from 'yup';
import AdminForm from '../../AdminForm';
import { useTracking } from '@castiron/utils';
import { useHistory } from 'react-router-dom';
import ViewShopButton from '../../Layout/Header/ViewShopButton';
import UnsavedChangesPrompt from '../../UnsavedChangesPrompt.tsx';
import { nanoid } from '@reduxjs/toolkit';
import SingleFaq from './SingleFaq';
import { shopRepository } from '../../../domain';
import _ from 'lodash';
import { openModal } from '../../../store/reducers/modalConductor';
import { Helmet } from 'react-helmet';

interface Props extends LayoutPageProps {}

interface FormValues {
  faqs: ShopFaq[];
  isFaqPageEnabled: boolean;
}

// a little function to help us with reordering the result
function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

const useStyles = makeStyles((theme: Theme) => ({
  addFaqButton: {
    marginTop: 24,
    marginBottom: 22,
    padding: 16,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 136,
    },
  },
  buttonsContainer: {
    paddingTop: 50,
    paddingBottom: 30,
    gap: 8,
    width: '100%',
  },
  footerButton: {
    margin: '0px 4px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  container: {
    '& > form': {
      width: '100%',
    },

    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
  },
  descriptionField: {
    paddingTop: '16px',
  },
  input: {
    marginBottom: 24,
  },
  toggleWrapper: {
    marginTop: 24,
    fontWeight: 600,
    lineHeight: '24px',
    '& button': {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
      width: '50%',
    },
    '& .MuiToggleButtonGroup-root': {
      color: theme.branding.blue.primary,
      marginBottom: 8,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 24,
    },
  },
  title: {
    fontWeight: 600,
    lineHeight: '21px',
    fontSize: 14,
  },
  statusText: {
    color: theme.palette.grey[800],
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '21px',
  },
  loadingSpinner: {
    color: theme.branding.gray[500],
    height: 12,
    width: 12,
    marginLeft: 8,
  },
  loadingSpinnerContainer: {
    marginTop: 24,
  },
}));

const faqSchema = yup.object().shape({
  faqs: yup.array().of(
    yup.object().shape({
      id: yup.string(),
      question: yup.string().when('isFaqPageEnabled', {
        is: true,
        then: yup.string().required('A question is required'),
      }),
      answer: yup.string().when('isFaqPageEnabled', {
        is: true,
        then: yup.string().required('An answer is required'),
      }),
      position: yup.number(),
    }),
  ),
  isFaqPageEnabled: yup.boolean(),
});

const FaqPage: React.FC<LayoutPageProps> = (props: Props) => {
  const { setPageTitle, setPageIsProFeature, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const history = useHistory();
  const formRef = useRef<FormikProps<FormValues>>();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shopFaqs, setShopFaqs] = useState<ShopFaq[]>([]);
  const [sortedShopFaqs, setSortedShopFaqs] = useState<ShopFaq[]>([]);
  const [faqExpanded, setFaqExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  useEffect(() => {
    setPageTitle('FAQ');
    setPageIsProFeature(false);
    setBackLocation(true);
    setHeaderCTAs([<ViewShopButton subdirectory="faq" />]);

    return () => {
      setBackLocation(false);
      setPageTitle('');
      setPageIsProFeature(false);
    };
  }, []);

  useEffect(() => {
    setFooterCTAs([
      <DiscardButton isSubmitting={isSubmitting} backLocation="/store/pages" />,
      <SaveButton formikState={formRef.current} isSubmitting={isSubmitting} />,
    ]);

    return () => {
      setFooterCTAs([]);
    };
  }, [isSubmitting]);

  useEffect(() => {
    if (shop) {
      shopFaqs.length === 0 && setIsLoading(true);
      shopRepository
        .getShopFaqs(shop.id)
        .then(shopFaqs => {
          const hasFaqs = shopFaqs.length > 0;
          const emptyFaq = {
            id: nanoid(),
            question: '',
            answer: '',
            position: 0,
          };
          const sortedFaqs = _.sortBy(shopFaqs, v => v.position);
          setShopFaqs(hasFaqs ? sortedFaqs : [emptyFaq]);
          setSortedShopFaqs(hasFaqs ? sortedFaqs : [emptyFaq]);
          formRef.current?.setFieldValue('faqs', hasFaqs ? sortedFaqs : [emptyFaq]);
          setIsLoading(false);
        })
        .catch(error => {
          console.error('Error getting shop faqs: ', error);
          setIsLoading(false);
        });
    }
  }, [shop]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const initialValues: FormValues = {
    faqs: sortedShopFaqs,
    isFaqPageEnabled: shop?.shopSubpageData?.isFaqPageEnabled ? shop.shopSubpageData.isFaqPageEnabled : false,
  };

  const resetForm = (formikProps: FormikProps<FormValues>) => {
    formikProps.setSubmitting(false);
    formikProps.resetForm();
    history.push(`/store/pages`);
  };

  const onSubmit = async (values: FormValues, formikProps: FormikProps<FormValues>) => {
    setIsSubmitting(true);
    if (hasErrors) {
      setIsSubmitting(false);
      return;
    } else {
      try {
        const { faqs, isFaqPageEnabled } = values;

        if (isFaqPageEnabled !== shop.shopSubpageData?.isFaqPageEnabled) {
          trackEvent('Shop Page Visibility Set', {
            page: 'faq',
            enabled: isFaqPageEnabled,
            shop: shopToEventModel(shop),
          });
        }

        let newFaqs = faqs.map((faq, index) => {
          return {
            ...faq,
            position: index,
          };
        });

        newFaqs.forEach(async faq => {
          if (!faq.question && !faq.answer) {
            return;
          } else {
            await shopRepository.addShopFaq(shop.id, faq);
          }
        });

        const newShopSubpageData = {
          ...shop.shopSubpageData,
          isFaqPageEnabled,
        };

        await dispatch(updateShopAction({ shop: { ...shop, shopSubpageData: newShopSubpageData } }));

        trackEvent('Shop FAQ Page Updated', {
          shop: shopToEventModel(shop),
        });

        dispatch(
          openModal({
            modalType: 'SIMPLE_ALERT',
            modalProps: {
              show: true,
              celebrate: true,
              content: (
                <>
                  <Typography variant="h4">FAQ Page Updated!</Typography>
                </>
              ),
            },
          }),
        );

        setIsSubmitting(false);
        resetForm(formikProps);
        history.push('/store/pages');
      } catch (error) {
        setIsSubmitting(false);
        console.error('Error Saving Shop Faq Page: ', error);
      }
    }
  };

  const toggleButtonOptions: ToggleButtonOption[] = [
    {
      value: true,
      label: 'Active',
    },
    {
      value: false,
      label: 'Inactive',
    },
  ];

  const addFaq = () => {
    setFaqExpanded(true);
    const len = formRef.current?.values.faqs.length;
    formRef.current?.setFieldValue('faqs', [
      ...formRef.current?.values.faqs,
      {
        id: nanoid(),
        question: '',
        answer: '',
        position: len === 0 ? 0 : len - 1,
      },
    ]);
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const sortedFaqs = reorder<ShopFaq>(formRef.current?.values.faqs, result.source.index, result.destination.index);

    formRef.current?.setFieldValue(
      'faqs',
      sortedFaqs.map((v, index) => ({
        ...v,
        position: index,
      })),
    );

    setSortedShopFaqs(sortedFaqs);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid container justify="center" className={classes.container}>
        <Helmet>
          <title>FAQ | Castiron</title>
        </Helmet>
        <Formik initialValues={initialValues} validationSchema={faqSchema} onSubmit={onSubmit} innerRef={formRef}>
          {({ dirty, errors, setFieldValue, submitCount, touched, values }): ReactElement => (
            <AdminForm>
              <Grid
                container
                item
                xs={12}
                direction={isMobile ? 'column-reverse' : 'row'}
                spacing={!isMobile && 6}
                wrap={isMobile ? 'wrap' : 'nowrap'}
              >
                <Grid container item xs={12} md={8} direction="column">
                  <Typography variant="body1" className={classes.title}>
                    Questions
                  </Typography>
                  {values.faqs?.length > 0 && (
                    <Droppable droppableId="faqs" type="FAQ">
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef}>
                          {_.sortBy(values.faqs, v => v.position).map((faq: ShopFaq, index: number) => (
                            <Draggable draggableId={faq.id} index={index} key={faq.id}>
                              {(provided, snapshot) => (
                                <div ref={provided.innerRef} {...provided.draggableProps}>
                                  <SingleFaq
                                    faq={faq}
                                    index={index}
                                    faqExpanded={faqExpanded}
                                    dragHandleProps={provided.dragHandleProps}
                                    didChange={(value: boolean) => {
                                      setHasUnsavedChanges(value);
                                    }}
                                    faqLength={values.faqs.length}
                                    pageIsEnabled={values.isFaqPageEnabled}
                                    shopId={shop.id}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </div>
                      )}
                    </Droppable>
                  )}
                  {isLoading && (
                    <Grid container alignItems="center" className={classes.loadingSpinnerContainer}>
                      <Typography variant="body1">Loading FAQs...</Typography>
                      <CircularProgress className={classes.loadingSpinner} size={24} />
                    </Grid>
                  )}
                  <Button
                    onClick={addFaq}
                    className={classes.addFaqButton}
                    fullWidth={isMobile}
                    variant="outlined"
                    color="primary"
                  >
                    Add Question
                  </Button>
                </Grid>
                <Grid container item xs={12} md={4} direction="column" className={classes.toggleWrapper}>
                  <Typography variant="subtitle1" className={classes.statusText}>
                    Status
                  </Typography>
                  <ToggleButton
                    value={values.isFaqPageEnabled}
                    exclusive
                    onChange={(e: React.MouseEvent<HTMLElement>, value): void => {
                      setFieldValue('isFaqPageEnabled', value);
                      setHasUnsavedChanges(true);
                    }}
                    aria-label="page visibility"
                    buttonOptions={toggleButtonOptions}
                  />
                  <Typography variant="caption">
                    Setting this page to active makes it immediately visible on your website.
                  </Typography>
                  {values.faqs?.map((faq: ShopFaq, index: number) => {
                    if ((faq.question && !faq.answer) || (!faq.question && faq.answer)) {
                      setHasErrors(true);
                      return (
                        <Typography variant="body1" color="error" key={index}>
                          {`FAQ #${index + 1} is missing a question or answer`}
                        </Typography>
                      );
                    } else {
                      setHasErrors(false);
                    }
                  })}
                </Grid>
                {!isSubmitting && <UnsavedChangesPrompt when={hasUnsavedChanges} />}
              </Grid>
            </AdminForm>
          )}
        </Formik>
      </Grid>
    </DragDropContext>
  );
};

export default FaqPage;
