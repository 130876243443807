import { upload } from '@castiron/castiron-firebase';
import {
  DiscardButton,
  ImageUploader,
  SaveButton,
  TextInput,
  ToggleButton,
  ToggleButtonOption,
  Typography,
} from '@castiron/components';
import { Asset } from '@castiron/domain';
import { kebabCase, stripHtml, useTracking } from '@castiron/utils';
import { Grid, useMediaQuery } from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { convertToAsset } from '../../../lib/imageUtils';
import { Formik, FormikProps } from 'formik';
import { nanoid } from 'nanoid';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { shopRepository } from '../../../domain';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeModal, openModal } from '../../../store/reducers/modalConductor';
import { getShopAction } from '../../../store/reducers/shops';
import AdminForm from '../../AdminForm';
import { LayoutPageProps } from '../../Layout';
import ViewShopButton from '../../Layout/Header/ViewShopButton';
import EllipsisMenu from '../../Menus/EllipsisMenu';
import RichTextInput from '../../RichTextEditor';
import Spinner from '../../Spinner';
import UnsavedChangesPrompt from '../../UnsavedChangesPrompt.tsx';

interface Props extends LayoutPageProps {}

interface FormValues {
  enabled: boolean;
  headline: string;
  description: string;
  title: string;
  imageObj: Asset;
}

const useStyles = makeStyles((theme: Theme) => ({
  caption: {
    fontWeight: 400,
    color: theme.branding.v2.gray[700],
  },
  container: {
    '& > form': {
      width: '100%',
    },

    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
  },
  descriptionField: {
    paddingTop: '16px',
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: '12px 12px 0px 0px',
    maxWidth: '100%',
    '& div': {
      border: 'none',
    },
    '& div.ql-toolbar': {
      borderRadius: '0px 0px 12px 12px',
    },
  },
  image: {
    aspectRatio: '1/1',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  imageContainer: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 12,
    overflow: 'hidden',
    position: 'relative',
    height: 200,
    width: 200,
  },
  menuButton: {
    zIndex: 25,
    backgroundColor: theme.branding.v2.gray[0],
    right: 0,
    margin: 8,
    borderRadius: 12,
    width: 40,
    height: 40,
    position: 'absolute',
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    '& svg': {
      color: theme.branding.v2.gray[500],
    },
    '& svg:hover': {
      color: theme.branding.v2.gray[800],
    },
  },
  rightColumn: {
    [theme.breakpoints.down('sm')]: {
      margin: '24px 0px',
    },
  },
  statusText: {
    color: theme.branding.v2.gray[700],
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '21px',
  },
  toggleButton: {
    fontWeight: 600,
    lineHeight: '24px',
    '& button': {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
      width: '50%',
    },
  },
}));

const customSchema = () =>
  yup.object().shape({
    enabled: yup.boolean(),
    headline: yup.string().when('enabled', {
      is: true,
      then: yup.string().required('Headline is required if Custom page is enabled'),
    }),
    description: yup.string().when('enabled', {
      is: true,
      then: yup.string().required('Description is required if Custom page is enabled'),
    }),
    title: yup.string().when('enabled', {
      is: true,
      then: yup
        .string()
        .required('Title is required if Custom page is enabled')
        .test(
          'This is an existing title, please use a different title',
          'This is an existing title, please use a different title',
          value => {
            const existing = [
              'about',
              'availability',
              'checkout',
              'contact',
              'events',
              'faq',
              'gallery',
              'home',
              'order-forms',
              'presales',
              'request',
              'shop',
              'signup',
            ];
            return !existing.includes(kebabCase(value || ''));
          },
        ),
    }),
    image: yup.object().nullable(),
  });

const CustomPage: React.FC<LayoutPageProps> = (props: Props) => {
  const { setPageTitle, setPageIsProFeature, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const history = useHistory();
  const formRef = useRef<FormikProps<FormValues>>();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const { setFieldValue } = formRef?.current || {};

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [isModalPopped, setIsModalPopped] = useState<boolean>(false);
  const [imageObj, setImageObj] = useState(undefined);
  const [photoUploaded, setPhotoUploaded] = useState('');
  const assetType = 'customPageImage';

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  useEffect(() => {
    setPageTitle('Custom Page');
    setPageIsProFeature(false);
    setBackLocation(true);
    setHeaderCTAs([<ViewShopButton subdirectory="custom" />]);

    return () => {
      setBackLocation(false);
      setPageTitle('');
      setPageIsProFeature(false);
    };
  }, []);

  useEffect(() => {
    setFooterCTAs([
      <DiscardButton isSubmitting={isSubmitting} backLocation="/store/pages" />,
      <SaveButton formikState={formRef.current} isSubmitting={isSubmitting} />,
    ]);

    return () => {
      setFooterCTAs([]);
    };
  }, [isSubmitting]);

  useEffect(() => {
    if (imageObj === undefined && shop?.shopSubpageData?.custom?.imageObj?.downloadUrl) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setImageObj(shop?.shopSubpageData?.custom?.imageObj);
    }
  }, [shop]);

  useEffect(() => {
    if (photoUploaded === 'customPageImage') {
      dispatch(
        openModal({
          modalType: 'EDIT_PHOTO_MODAL',
          modalProps: {
            show: true,
            imageObj: imageObj,
            cropShape: 'rect',
            onClose: croppedImage => {
              handleCroppedImage(croppedImage, assetType);
            },
            onCancel: () => onCropCancel(assetType, true),
          },
        }),
      );
    }
  }, [photoUploaded]);

  const handleFile = async (item, assetType) => {
    const { file } = item;
    const id = nanoid();
    //TODO: this was failing when a file did not exist and was not updating products
    //this seemed to fix it (prevents upload) but I'm not sure if that is what should actually be done
    if (file) {
      const metadata = {
        shopId: shop.id,
        originalFilename: id,
        id,
        assetType,
      };
      const options = {
        folder: `user/${shop.id}`,
      };
      const callbacks = {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        success: handleFileUploadSuccess,
      };
      const context = {
        shop: shop,
      };
      await upload(file, metadata, options, callbacks, context);
    }
    return id;
  };

  const handleFileUploadSuccess = async (downloadUrl, metadata, options) => {
    console.debug('handleFileUploadSuccess: ', downloadUrl);

    await shopRepository.updateProps(shop.id, {
      'shopSubpageData.custom.imageObj': {
        id: metadata.id,
        shopId: shop.id,
        downloadUrl,
        metadata: metadata,
        options,
      },
    });
    dispatch(getShopAction(shop.id));
  };

  const handleCroppedImage = async (croppedImage: File, loc: string) => {
    const metadata = {
      id: imageObj?.id,
      shopId: shop.id,
      originalFilename: imageObj?.metadata?.originalFilename,
      assetType,
      contentType: imageObj?.metadata?.contentType,
    };

    const callbacks = {
      success: handleFileUploadSuccess,
    };

    const options = {
      folder: `user/${shop.id}`,
    };

    const context = {
      shop,
    };

    const croppedImageObj = convertToAsset(
      URL.createObjectURL(croppedImage),
      assetType,
      metadata?.originalFilename,
      shop,
      metadata?.contentType,
    );

    await upload(croppedImage, metadata, options, callbacks, context);
    setImageObj(croppedImageObj);
    setFieldValue('imageObj', croppedImageObj);

    dispatch(closeModal());
  };

  const onCropCancel = (loc: string, isNew: boolean) => {
    if (isNew) {
      setImageObj('');
      setFieldValue('imageObj', '');
    } else {
      setImageObj(shop?.shopSubpageData?.custom?.imageObj);
      setFieldValue('imageObj', shop?.shopSubpageData?.custom?.imageObj);
    }

    setPhotoUploaded('');
    dispatch(closeModal());
  };

  const imageMenuOptions = (loc: 'customPageImage') => {
    type option = {
      display: string;
      color?: 'error';
      action: () => void;
    };
    let options: option[] = [
      {
        display: 'Edit Photo',
        action: () => {
          dispatch(
            openModal({
              modalType: 'EDIT_PHOTO_MODAL',
              modalProps: {
                show: true,
                imageObj: imageObj,
                cropShape: 'rect',
                onClose: croppedImage => {
                  handleCroppedImage(croppedImage, loc);
                },
                onCancel: () => onCropCancel(loc, false),
              },
            }),
          );
        },
      },
      {
        display: 'Delete Photo',
        color: 'error',
        action: () => {
          setImageObj('');
          setFieldValue('imageObj', '');
          setPhotoUploaded('');
        },
      },
    ];
    return options;
  };

  const initialValues: FormValues = {
    enabled: !!shop?.shopSubpageData?.custom?.enabled,
    headline: shop?.shopSubpageData?.custom?.headline || '',
    description: shop?.shopSubpageData?.custom?.description || '',
    title: shop?.shopSubpageData?.custom?.title || '',
    imageObj: shop?.shopSubpageData?.custom?.imageObj || null,
  };

  const resetForm = (formikProps: FormikProps<FormValues>) => {
    formikProps.setSubmitting(false);
    formikProps.resetForm();
    history.push(`/store/pages`);
  };

  const onSubmit = async (values: FormValues, formikProps: FormikProps<FormValues>) => {
    if (values.enabled && !stripHtml(values.description)) {
      // This is a hack to get around the fact that the rich text editor is not setting the value to an empty string
      formikProps.setFieldError('description', 'Description is required if Custom page is enabled');
      return;
    } else {
      try {
        setIsSubmitting(true);
        const { enabled, headline, description, title, imageObj } = values;

        if (enabled !== shop.shopSubpageData?.custom?.enabled) {
          trackEvent('Shop Page Visibility Set', {
            page: 'custom',
            enabled,
          });
        }

        await shopRepository.updateProps(shop.id, {
          'shopSubpageData.custom': {
            ...shop?.shopSubpageData?.custom,
            enabled,
            headline,
            description,
            title,
          },
        });

        trackEvent('Shop Custom Page Updated');

        dispatch(getShopAction(shop.id));

        dispatch(
          openModal({
            modalType: 'SIMPLE_ALERT',
            modalProps: {
              show: true,
              celebrate: true,
              content: (
                <>
                  <Typography variant="h4">Custom Page Updated!</Typography>
                </>
              ),
            },
          }),
        );

        if (imageObj) {
          if (!shop || imageObj.id !== shop.shopSubpageData?.custom?.imageObj?.id) {
            await handleFile(imageObj, assetType);
          }
        }
        setIsSubmitting(false);
        resetForm(formikProps);
        history.push('/store/pages');
      } catch (error) {
        setIsSubmitting(false);
        console.error('Error Submitting Shop Custom Page Form: ', error);
      }
    }
  };

  const imageContent = (
    <Grid id="custom-page-image" style={!isMobile ? { marginBottom: 24 } : {}}>
      <Typography variant="subtitle1">Image</Typography>
      {imageObj?.downloadUrl ? (
        <Grid className={classes.imageContainer}>
          <Grid container justify="center" className={classes.menuButton}>
            <EllipsisMenu
              options={imageMenuOptions(assetType)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            />
          </Grid>
          <img className={classes.image} src={imageObj.downloadUrl} />
        </Grid>
      ) : (
        <ImageUploader
          onFileDrop={(files): void => {
            const tempAsset = convertToAsset(
              URL.createObjectURL(files[0]),
              'profile',
              files[0].name,
              shop,
              files[0].type,
            );
            setImageObj(tempAsset);
            setFieldValue('imageObj', tempAsset);
            setPhotoUploaded(assetType);
          }}
        />
      )}
    </Grid>
  );

  const getToggleContent = values => (
    <Grid container item direction="column" className={classes.toggleButton}>
      <Typography variant="subtitle1" className={classes.statusText}>
        Status
      </Typography>
      <ToggleButton
        value={values.enabled}
        exclusive
        onChange={(e: React.MouseEvent<HTMLElement>, value): void => {
          setFieldValue('enabled', value);
        }}
        aria-label="page visibility"
        buttonOptions={toggleButtonOptions}
      />
      <Typography variant="caption" className={classes.caption}>
        Setting this page to active makes it immediately visible on your website.
      </Typography>
    </Grid>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleButtonOptions: ToggleButtonOption[] = [
    {
      value: true,
      label: 'Active',
    },
    {
      value: false,
      label: 'Inactive',
    },
  ];

  return (
    <>
      <Spinner show={showSpinner} label="Generating Shop Description" size="fullscreen" />
      <Grid container justify="center" className={classes.container}>
        <Helmet>
          <title>Custom | Castiron</title>
        </Helmet>
        <Formik initialValues={initialValues} validationSchema={customSchema} onSubmit={onSubmit} innerRef={formRef}>
          {({ dirty, errors, setFieldValue, touched, values, setFieldTouched, setFieldError }): ReactElement => (
            <AdminForm>
              <Grid
                container
                item
                xs={12}
                direction={isMobile ? 'column-reverse' : 'row'}
                spacing={!isMobile && 6}
                wrap={isMobile ? 'wrap' : 'nowrap'}
              >
                <Grid container item xs={12} md={8} direction="column" style={{ gap: 24 }}>
                  <Grid container direction="column">
                    <TextInput
                      label={values.enabled ? 'Page Title *' : 'Page Title'}
                      maxLength={12}
                      name="title"
                      placeholder="Title"
                      error={touched.title && errors.title}
                    />
                    <Typography style={{ marginTop: 4 }} className={classes.caption} variant="caption">
                      This will be used in your website navigation so keep it short!
                    </Typography>
                  </Grid>
                  {isMobile && getToggleContent(values)}
                  {isMobile && imageContent}
                  <TextInput
                    label={values.enabled ? 'Headline *' : 'Headline'}
                    name="headline"
                    placeholder="Headline for Your Custom Page"
                    error={touched.headline && errors.headline}
                  />
                  <Grid container direction="column" item>
                    <Typography variant="subtitle2">Description {values.enabled && <span>*</span>}</Typography>
                    <RichTextInput
                      name="description"
                      placeholder="The site description will appear on your site’s custom page"
                      className={classes.descriptionField}
                    />
                    {touched.description && errors.description && (
                      <Typography variant="caption" color="error">
                        {errors.description}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid container item xs={12} md={4} direction="column" className={classes.rightColumn}>
                  {!isMobile && imageContent}
                  {!isMobile && getToggleContent(values)}
                </Grid>
                {!isSubmitting && !isModalPopped && <UnsavedChangesPrompt when={dirty} />}
              </Grid>
            </AdminForm>
          )}
        </Formik>
      </Grid>
    </>
  );
};

export default CustomPage;
