import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import clsx from 'clsx';
import Dinero from 'dinero.js';
import _ from 'lodash';
import InputWrapper, { BaseInputProps } from '../InputWrapper';

interface Props extends BaseInputProps {
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  onChanged?: (newValue: number) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  variant?: 'small' | 'medium';
  disabledStyle?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  disabledStyle: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.branding.v2.gray[100],
    },
    '& .MuiInputBase-input': {
      color: theme.branding.v2.gray[900],
    },
  },
  small: {
    '& .MuiOutlinedInput-root': {
      height: '48px',
    },
  },
  inputError: {
    '& div': {
      '& fieldset': {
        borderColor: theme.palette.error.main,
      },
    },
  },
}));

const maskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 5,
  allowNegative: false,
  allowLeadingZeroes: false,
};

const MoneyTextMask = props => {
  const { inputRef, ...other } = props;

  const mask = createNumberMask(maskOptions);

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
    />
  );
};

const MoneyInput: React.FC<Props> = (props: Props) => {
  const {
    label,
    secondaryLabel,
    helpText,
    required,
    error,
    name,
    placeholder,
    disabled,
    disabledStyle = false,
    onChanged,
    onKeyPress,
    variant = 'medium',
  } = props;

  const classes = useStyles();
  const formik = useFormikContext();

  const [fieldValue, setFieldValue] = useState<string>(null);

  const handleChange = event => {
    const newValue = event.target.value as string;
    setFieldValue(newValue);
  };

  const handleChanged = event => {
    const newValue = event.target.value as string;
    const dollars = Number.parseFloat(newValue.replaceAll(/[$,]+/g, ''));
    /* || 0 accounts for NaN when the newValue is '' */
    const cents = Math.round(dollars * 100) || 0;
    formik.setFieldValue(name, cents);
    onChanged && onChanged(cents);
  };

  useEffect(() => {
    const value = _.get(formik.values, name) as number;
    const formattedValue = value ? Dinero({ amount: value }).toFormat('$0.00') : null;

    setFieldValue(formattedValue);
  }, [formik]);

  return (
    <InputWrapper label={label} secondaryLabel={secondaryLabel} helpText={helpText} required={required} error={error}>
      <TextField
        style={{ margin: '0px' }}
        variant="outlined"
        fullWidth
        name={name}
        placeholder={placeholder || '$0.00'}
        value={fieldValue}
        onChange={handleChange}
        onBlur={handleChanged}
        onKeyPress={onKeyPress}
        disabled={disabled}
        InputProps={{
          inputComponent: MoneyTextMask,
        }}
        className={clsx(
          disabledStyle && classes.disabledStyle,
          variant === 'small' && classes.small,
          error && classes.inputError,
        )}
      />
    </InputWrapper>
  );
};

export default MoneyInput;
