import React, {useEffect, useState} from 'react';
import { Grid, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { Button, Checkbox } from '@castiron/components';
import { backendStateToFrontendState, FrontendTransactionState, Transaction } from '@castiron/domain';
import { defaultTimeZone, useTracking } from '@castiron/utils';
import { shopRepository, transactionRepository } from '../../../domain';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeModal, openModal } from '../../../store/reducers/modalConductor';
import { listCustomTransactionsAction, listTransactionsAction } from '../../../store/reducers/transactions';
import ModalWrapper from '../../RootModal/ModalWrapper';
import Spinner from '../../Spinner';
import { getService } from '../../../firebase';
import * as Sentry from '@sentry/react';

export interface Props {
  show: boolean;
  transaction: Transaction;
}

const sendQuoteService = getService('orders', 'sendquoterequestv2');

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    padding: '32px 24px',
  },
  headerText: {
    fontWeight: 700,
  },
  headerDetail: {
    marginTop: '16px',
  },
  noteSection: {
    marginTop: '24px',
  },
  noteLabel: {
    fontSize: '14px',
    fontWeight: 600,
  },
  copySelfSection: {
    marginTop: '16px',
  },
  buttonSection: {
    marginTop: '24px',
  },
}));

const QuoteSendModal: React.FC<Props> = (props: Props) => {
  const { show, transaction } = props;
  const [artisanNotes, setArtisanNotes] = useState(transaction.artisanNotes);
  const [sendCopyToSelf, setSendCopyToSelf] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();

  const existingStatus = backendStateToFrontendState(transaction, 'quote');
  const trackStatusChange = (newStatus: FrontendTransactionState): void => {
    trackEvent('quoteStatusChanged', {
      transactionId: transaction.id,
      url: window.location.href,
      previousStatus: existingStatus,
      newStatus,
    });
  };

  const { me, shop } = useAppSelector(state => ({
    me: state.users.me,
    shop: state.shops.shop,
  }));

  const sendQuote = async () => {
    const sendTx = Sentry.getCurrentHub()
      .getScope()
      .getTransaction();
    setShowSpinner(true);
    const updateSpan = sendTx?.startChild({
      op: 'update',
      description: 'Update transaction',
    });
    await transactionRepository.updateProps(transaction.id, {
      artisanNotes: artisanNotes || '',
      status: 'agreed',
    });
    updateSpan?.finish();
    /* hack a new transaction here to confirm new status */
    trackStatusChange(backendStateToFrontendState({ ...transaction, status: 'agreed' }, 'quote'));
    dispatch(listTransactionsAction(shop.id));
    dispatch(listCustomTransactionsAction(shop.id));
    const sendSpan = sendTx?.startChild({
      op: 'send',
      description: 'Send Quote Service',
    });
    const { success } = await sendQuoteService({ transactionId: transaction.id, sendCopyToArtisan: sendCopyToSelf });


    sendSpan?.finish();
    setShowSpinner(false);
    if (success) {
      trackEvent('Owner Sent Quote', {
        transaction
      });

      handleClose();
      dispatch(
        openModal({
          modalType: 'QUOTE_SENT_MODAL',
          modalProps: {
            show: true,
            transaction,
          },
        }),
      );
      /* Similar to fulfillment, since we are saving fulfillment dates, let's check and see if a timezone is set in the config */
      if (!shop.config?.timeZone) {
        /* if not, set one */
        await shopRepository.updateProps(shop.id, {
          config: {
            ...shop.config,
            timeZone: defaultTimeZone,
          },
        });
      }
    } else {
      handleClose();
    }
    sendTx?.finish();
  };

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  return (
    <ModalWrapper size="xs" show={show}>
      <Spinner show={showSpinner} size="fullscreen" />
      <Grid container className={classes.modalContainer}>
        <Grid item xs={12}>
          <Typography className={classes.headerText}>Ready to send?</Typography>
        </Grid>
        <Grid item xs={12} className={classes.headerDetail}>
          <Typography>
            Your customer will receive an email notification that their quote is ready for review and payment.
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.noteSection}>
          <Typography className={classes.noteLabel}>Note to customer (optional)</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="artisanNotesTextField"
            placeholder="Add a note for your customer"
            value={artisanNotes}
            multiline
            rows={3}
            variant="outlined"
            fullWidth
            onChange={(e): void => setArtisanNotes(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} className={classes.copySelfSection}>
          <Checkbox
            name="sendCopyToSelf"
            checked={sendCopyToSelf}
            label={`Send me a copy (${shop.email})`}
            onChange={(e): void => setSendCopyToSelf(e.target.checked)}
          />
        </Grid>
        <Grid container item xs={12} direction="row-reverse" spacing={2} className={classes.buttonSection}>
          <Grid item>
            <Button variant="contained" onClick={sendQuote}>
              Send
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};

export default QuoteSendModal;
