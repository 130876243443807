import { Theme } from '@material-ui/core';
import { Shop } from '@castiron/domain';

export const getSmsTemplates = async (theme: Theme, isPresalesEnabled: boolean, activeEventTags: string[]) => {
  //in order that they should display in modal
  const templates = [
    {
      icon: '🛒',
      iconBackgroundColor: theme.branding.v2.gray[50],
      name: 'Product Promo Text',
      subheader: 'Drive sales for a specific product.',
      route: '/marketing/single-send-sms?type=product-promo',
      event: 'Product Promo SMS Created',
      category: 'Basic',
    },
    {
      icon: '🤑',
      iconBackgroundColor: theme.branding.v2.gray[50],
      name: 'Coupon Promo Text',
      subheader: 'Drive sales through active coupons and offers.',
      route: '/marketing/single-send-sms?type=coupon-promo',
      event: 'Coupon Promo SMS Created',
      category: 'Basic',
    },
    {
      icon: '👩‍🏫',
      iconBackgroundColor: theme.branding.v2.gray[50],
      name: 'Ticketed Event Promo Text',
      subheader: 'Drive ticket sales for an upcoming in person or virtual event.',
      route: '/marketing/single-send-sms?type=ticketed-event-promo',
      event: 'Ticketed Event Promo SMS Created',
      category: 'Basic',
    },
    {
      icon: '👋',
      iconBackgroundColor: theme.branding.v2.gray[50],
      name: 'Event Promo Text',
      subheader: 'Drive in person interaction and early shopping for an upcoming event.',
      route: '/marketing/single-send-sms?type=event-promo',
      event: 'Event Promo SMS Created',
      category: 'Basic',
    },
    ...(isPresalesEnabled
      ? [
          {
            icon: '🛍️',
            iconBackgroundColor: theme.branding.v2.gray[50],
            name: 'Presale Promo Text',
            subheader: 'Drive sales for an active presale.',
            route: '/marketing/single-send-sms?type=presale-promo',
            event: 'Presale Promo SMS Created',
            category: 'Basic',
          },
        ]
      : []),
    {
      icon: '🎇',
      iconBackgroundColor: theme.branding.v2.red[50],
      name: '4th of July Product Promo Text',
      subheader: 'Drive sales for a specific 4th of July product.',
      route: '/marketing/single-send-sms?type=4th-of-july-promo',
      event: '4th of July Promo SMS Created',
      eventTag: '4thOfJuly',
      category: '4th of July',
    },
    {
      icon: '🎇',
      iconBackgroundColor: theme.branding.v2.red[50],
      name: '4th of July Coupon Promo Text',
      subheader: 'Drive 4th of July sales with an active coupon.',
      route: '/marketing/single-send-sms?type=4th-of-july-coupon-promo',
      event: '4th of July Coupon Promo SMS Created',
      eventTag: '4thOfJuly',
      category: '4th of July',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Announcement SMS',
      subheader: 'Introduce your back-to-school offerings.',
      route: '/marketing/single-send-sms?type=back-to-school-announcement',
      event: 'Back-to-School SMS Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Reminder SMS',
      subheader: 'Remind customers to shop your back-to-school offerings.',
      route: '/marketing/single-send-sms?type=back-to-school-reminder',
      event: 'Back-to-School SMS Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Coupon SMS',
      subheader: 'Share a coupon to entice customers to shop.',
      route: '/marketing/single-send-sms?type=back-to-school-coupon',
      event: 'Back-to-School SMS Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Shop SMS',
      subheader: 'Promote your back-to-school offerings.',
      route: '/marketing/single-send-sms?type=back-to-school-shop',
      event: 'Back-to-School SMS Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Custom Order SMS',
      subheader: 'Share your back-to-school custom orders.',
      route: '/marketing/single-send-sms?type=back-to-school-customs',
      event: 'Back-to-School SMS Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Last Call SMS',
      subheader: 'Last call for back-to-school orders!',
      route: '/marketing/single-send-sms?type=back-to-school-last-call',
      event: 'Back-to-School SMS Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '🍁',
      iconBackgroundColor: theme.branding.v2.warmWhite[50],
      name: 'Fall Announcement',
      subheader: 'Introduce customers to your fall delicacies.',
      route: '/marketing/single-send-sms?type=fall-announcement',
      event: 'Fall SMS Created',
      eventTag: 'Fall',
      category: 'Fall',
    },
    {
      icon: '🍁',
      iconBackgroundColor: theme.branding.v2.warmWhite[50],
      name: 'Fall Coupon',
      subheader: 'Share a coupon to entice customers to shop.',
      route: '/marketing/single-send-sms?type=fall-coupon',
      event: 'Fall SMS Created',
      eventTag: 'Fall',
      category: 'Fall',
    },
    {
      icon: '🍁',
      iconBackgroundColor: theme.branding.v2.warmWhite[50],
      name: 'Fall Reminder',
      subheader: 'Notify customers about your fall delicacies.',
      route: '/marketing/single-send-sms?type=fall-reminder',
      event: 'Fall SMS Created',
      eventTag: 'Fall',
      category: 'Fall',
    },
    {
      icon: '🍁',
      iconBackgroundColor: theme.branding.v2.warmWhite[50],
      name: 'Shop Fall Products',
      subheader: 'Engage customers with your autumn-filled offerings.',
      route: '/marketing/single-send-sms?type=shop-fall',
      event: 'Fall SMS Created',
      eventTag: 'Fall',
      category: 'Fall',
    },
    {
      icon: '🍁',
      iconBackgroundColor: theme.branding.v2.warmWhite[50],
      name: 'Custom Order for Fall',
      subheader: 'Share your Fall custom orders.',
      route: '/marketing/single-send-sms?type=fall-customs',
      event: 'Fall SMS Created',
      eventTag: 'Fall',
      category: 'Fall',
    },
    {
      icon: '🍁',
      iconBackgroundColor: theme.branding.v2.warmWhite[50],
      name: 'Last-Call for Fall Products',
      subheader: "Get customers to shop before it's too late.",
      route: '/marketing/single-send-sms?type=last-call-fall',
      event: 'Fall SMS Created',
      eventTag: 'Fall',
      category: 'Fall',
    },
    {
      icon: '🎃',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Halloween Announcement',
      subheader: 'Introduce your spooky offerings.',
      route: '/marketing/single-send-sms?type=halloween-announcement',
      event: 'Halloween SMS Created',
      eventTag: 'Halloween',
      category: 'Halloween',
    },
    {
      icon: '🎃',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Halloween Coupon',
      subheader: 'Share a fun coupon to entice customers to shop.',
      route: '/marketing/single-send-sms?type=halloween-coupon',
      event: 'Halloween SMS Created',
      eventTag: 'Halloween',
      category: 'Halloween',
    },
    {
      icon: '🎃',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Halloween Reminder',
      subheader: 'Remind customers to shop your Halloween offerings.',
      route: '/marketing/single-send-sms?type=halloween-reminder',
      event: 'Halloween SMS Created',
      eventTag: 'Halloween',
      category: 'Halloween',
    },
    {
      icon: '🎃',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Shop Halloween Products',
      subheader: 'Encourage shoppers to check out your Halloween offerings.',
      route: '/marketing/single-send-sms?type=shop-halloween',
      event: 'Halloween SMS Created',
      eventTag: 'Halloween',
      category: 'Halloween',
    },
    {
      icon: '🎃',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Custom Halloween-Themed Order SMS',
      subheader: 'Offer personal treats for a better holiday.',
      route: '/marketing/single-send-sms?type=halloween-customs',
      event: 'Halloween SMS Created',
      eventTag: 'Halloween',
      category: 'Halloween',
    },
    {
      icon: '🎃',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Last-Call for Halloween Treats',
      subheader: "Get customers to shop before it's too late.",
      route: '/marketing/single-send-sms?type=last-call-halloween',
      event: 'Halloween SMS Created',
      eventTag: 'Halloween',
      category: 'Halloween',
    },
    {
      icon: '🦃',
      iconBackgroundColor: theme.branding.v2.yellow[50],
      name: 'Thanksgiving Announcement',
      subheader: 'Introduce customers to your Thanksgiving delicacies.',
      route: '/marketing/single-send-sms?type=thanksgiving-announcement',
      event: 'Thanksgiving SMS Created',
      eventTag: 'Thanksgiving',
      category: 'Thanksgiving',
    },
    {
      icon: '🦃',
      iconBackgroundColor: theme.branding.v2.yellow[50],
      name: 'Thanksgiving Coupon',
      subheader: 'Share a coupon to entice customers to shop.',
      route: '/marketing/single-send-sms?type=thanksgiving-coupon',
      event: 'Thanksgiving SMS Created',
      eventTag: 'Thanksgiving',
      category: 'Thanksgiving',
    },
    {
      icon: '🦃',
      iconBackgroundColor: theme.branding.v2.yellow[50],
      name: 'Thanksgiving Reminder',
      subheader: 'Notify customers about your Thanksgiving delicacies.',
      route: '/marketing/single-send-sms?type=thanksgiving-reminder',
      event: 'Thanksgiving SMS Created',
      eventTag: 'Thanksgiving',
      category: 'Thanksgiving',
    },
    {
      icon: '🦃',
      iconBackgroundColor: theme.branding.v2.yellow[50],
      name: 'Shop Thanksgiving Products',
      subheader: 'Engage customers with your Thanksgiving offerings.',
      route: '/marketing/single-send-sms?type=shop-thanksgiving',
      event: 'Thanksgiving SMS Created',
      eventTag: 'Thanksgiving',
      category: 'Thanksgiving',
    },
    {
      icon: '🦃',
      iconBackgroundColor: theme.branding.v2.yellow[50],
      name: 'Custom Order for Thanksgiving',
      subheader: 'Share your Thanksgiving custom orders.',
      route: '/marketing/single-send-sms?type=thanksgiving-customs',
      event: 'Thanksgiving SMS Created',
      eventTag: 'Thanksgiving',
      category: 'Thanksgiving',
    },
    {
      icon: '🦃',
      iconBackgroundColor: theme.branding.v2.yellow[50],
      name: 'Last-Call for Thanksgiving Products',
      subheader: "Get customers to shop before it's too late.",
      route: '/marketing/single-send-sms?type=last-call-thanksgiving',
      event: 'Thanksgiving SMS Created',
      eventTag: 'Thanksgiving',
      category: 'Thanksgiving',
    },
    {
      icon: '🎄',
      iconBackgroundColor: theme.branding.v2.red[50],
      name: 'Christmas Announcement',
      subheader: 'Introduce your Christmas offerings.',
      route: '/marketing/single-send-sms?type=christmas-announcement',
      event: 'Christmas SMS Created',
      eventTag: 'Christmas',
      category: 'Christmas',
    },
    {
      icon: '🎄',
      iconBackgroundColor: theme.branding.v2.red[50],
      name: 'Christmas Coupon',
      subheader: 'Share a fun coupon to entice customers to shop.',
      route: '/marketing/single-send-sms?type=christmas-coupon',
      event: 'Christmas SMS Created',
      eventTag: 'Christmas',
      category: 'Christmas',
    },
    {
      icon: '🎄',
      iconBackgroundColor: theme.branding.v2.red[50],
      name: 'Christmas Reminder',
      subheader: 'Remind customers to shop your Christmas offerings.',
      route: '/marketing/single-send-sms?type=christmas-reminder',
      event: 'Christmas SMS Created',
      eventTag: 'Christmas',
      category: 'Christmas',
    },
    {
      icon: '🎄',
      iconBackgroundColor: theme.branding.v2.red[50],
      name: 'Shop Christmas Products',
      subheader: 'Encourage shoppers to check out your Christmas offerings.',
      route: '/marketing/single-send-sms?type=shop-christmas',
      event: 'Christmas SMS Created',
      eventTag: 'Christmas',
      category: 'Christmas',
    },
    {
      icon: '🎄',
      iconBackgroundColor: theme.branding.v2.red[50],
      name: 'Custom Christmas Order SMS',
      subheader: 'Offer personal treats for a better holiday.',
      route: '/marketing/single-send-sms?type=christmas-customs',
      event: 'Christmas SMS Created',
      eventTag: 'Christmas',
      category: 'Christmas',
    },
    {
      icon: '🎄',
      iconBackgroundColor: theme.branding.v2.red[50],
      name: 'Last-Call for Christmas Treats',
      subheader: "Get customers to shop before it's too late.",
      route: '/marketing/single-send-sms?type=last-call-christmas',
      event: 'Christmas SMS Created',
      eventTag: 'Christmas',
      category: 'Christmas',
    },

    //no corresponding event tag but keeping for next year!
    //black friday
    // {
    //   icon: '🏷️',
    //   iconBackgroundColor: theme.branding.v2.brown[50],
    //   name: 'Black Friday Announcement 1',
    //   subheader: 'Check out my shop for Black Friday!',
    //   route: '/marketing/single-send-sms?type=black-friday-announcement-1',
    //   event: 'Black Friday SMS Created',
    //   category: 'Black Friday',
    // },
    // {
    //   icon: '🏷️',
    //   iconBackgroundColor: theme.branding.v2.brown[50],
    //   name: 'Black Friday Announcement 2',
    //   subheader: "Don't miss out this Black Friday!",
    //   route: '/marketing/single-send-sms?type=black-friday-announcement-2',
    //   event: 'Black Friday SMS Created',
    //   category: 'Black Friday',
    // },
    // {
    //   icon: '🏷️',
    //   iconBackgroundColor: theme.branding.v2.brown[50],
    //   name: 'Black Friday Announcement 3',
    //   subheader: 'Looking for a new Black Friday shopping experience?',
    //   route: '/marketing/single-send-sms?type=black-friday-announcement-3',
    //   event: 'Black Friday SMS Created',
    //   category: 'Black Friday',
    // },
    //small business saturday
    // {
    //   icon: '🏪',
    //   iconBackgroundColor: theme.branding.v2.brown[50],
    //   name: 'Small Business Saturday Announcement 1',
    //   subheader: 'Celebrate Small Business Saturday!',
    //   route: '/marketing/single-send-sms?type=small-business-saturday-1',
    //   event: 'Small Business Saturday SMS Created',
    //   category: 'Small Business Saturday',
    // },
    // {
    //   icon: '🏪',
    //   iconBackgroundColor: theme.branding.v2.brown[50],
    //   name: 'Small Business Saturday Announcement 2',
    //   subheader: 'Shop small on this Small Business Saturday!',
    //   route: '/marketing/single-send-sms?type=small-business-saturday-2',
    //   event: 'Small Business Saturday SMS Created',
    //   category: 'Small Business Saturday',
    // },
    // {
    //   icon: '🏪',
    //   iconBackgroundColor: theme.branding.v2.brown[50],
    //   name: 'Small Business Saturday Announcement 3',
    //   subheader: 'Embrace all things Small Business Saturday!',
    //   route: '/marketing/single-send-sms?type=small-business-saturday-3',
    //   event: 'Small Business Saturday SMS Created',
    //   category: 'Small Business Saturday',
    // },
    //cyber monday
    // {
    //   icon: '💻',
    //   iconBackgroundColor: theme.branding.v2.brown[50],
    //   name: 'Cyber Monday Announcement 1',
    //   subheader: 'This Cyber Monday!',
    //   route: '/marketing/single-send-sms?type=cyber-monday-announcement-1',
    //   event: 'Cyber Monday SMS Created',
    //   category: 'Cyber Monday',
    // },
    // {
    //   icon: '💻',
    //   iconBackgroundColor: theme.branding.v2.brown[50],
    //   name: 'Cyber Monday Announcement 2',
    //   subheader: 'Embrace Cyber Monday!',
    //   route: '/marketing/single-send-sms?type=cyber-monday-announcement-2',
    //   event: 'Cyber Monday SMS Created',
    //   category: 'Cyber Monday',
    // },
    // {
    //   icon: '💻',
    //   iconBackgroundColor: theme.branding.v2.brown[50],
    //   name: 'Cyber Monday Announcement 3',
    //   subheader: 'Looking to support a small business on Cyber Monday?',
    //   route: '/marketing/single-send-sms?type=cyber-monday-announcement-3',
    //   event: 'Cyber Monday SMS Created',
    //   category: 'Cyber Monday',
    // },
  ];
  const filteredTemplates = templates.filter(template => {
    if (!template.eventTag || activeEventTags.includes(template.eventTag)) return template;
    else return;
  });

  return filteredTemplates;
};

export const smsCategoriesMap = [
  {
    icon: '👋',
    name: 'Basic',
    description: 'Drive immediate engagement for offers, events, and more.',
  },
  {
    icon: '🧢',
    name: "Father's Day",
    description: 'Notify customers Father’s Day offerings.',
    eventTag: 'FathersDay',
  },
  {
    icon: '🎇',
    name: '4th of July',
    description: 'Market 4th of July specific items with ease.',
    eventTag: '4thOfJuly',
  },
  {
    icon: '📚',
    name: 'Back to School',
    description: 'Incite customers to interact with your back to school offerings.',
    eventTag: 'BackToSchool',
  },
  {
    icon: '🍁',
    iconBackground: '#F9F5EE',
    name: 'Fall',
    description: 'Share all about your Fall delicacies.',
    eventTag: 'Fall',
  },
  {
    icon: '🎃',
    iconBackground: '#EDE7F6',
    name: 'Halloween',
    description: 'Notify customers about your Halloween-themed offerings.',
    eventTag: 'Halloween',
  },
  {
    icon: '🦃',
    iconBackground: '#FFFDE7',
    name: 'Thanksgiving',
    description: 'Notify customers about your Thanksgiving offerings.',
    eventTag: 'Thanksgiving',
  },
  //no corresponding event tag but keeping for next year!
  // {
  //   icon: '🏷️',
  //   iconBackground: '#FAF7EC',
  //   name: 'Black Friday',
  //   description: 'Notify customers about your Black Friday offerings.',
  // },
  // {
  //   icon: '🏪',
  //   iconBackground: '#FAF7EC',
  //   name: 'Small Business Saturday',
  //   description: 'Notify customers about your Small Business Saturday offerings.',
  // },
  // {
  //   icon: '💻',
  //   iconBackground: '#FAF7EC',
  //   name: 'Cyber Monday',
  //   description: 'Notify customers about your Cyber Monday offerings.',
  // },
  {
    icon: '🎄',
    iconBackground: '#FFEBEE',
    name: 'Christmas',
    description: 'Notify customers about your Christmas offerings.',
    eventTag: 'Christmas',
  },
];
