import React from 'react';
import { useHistory } from 'react-router';
import { Grid, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import _ from 'lodash';
import { useTracking } from '@castiron/utils';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import moment from 'moment';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { showFirstMonthPromoBlock } from '../../ReferralPrompts/PromoBlock';

const useStyles = makeStyles((theme: Theme) => ({
  arrowForward: {
    height: 24,
    marginLeft: 4,
    width: 24,
  },
  trialExpirationBanner: {
    '& button': {
      padding: 0,
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
  },
  infoOutlined: {
    color: theme.branding.orange.primary,
    height: 20,
    marginRight: 10,
    width: 20,
  },
  trialExpirationBannerContainer: {
    backgroundColor: theme.branding.yellow.light,
    minHeight: 56,
    padding: '0px 72px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 24px',
    },
  },
}));

const TrialExpirationBanner: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { trackEvent } = useTracking();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { account, shop, subscription, tier } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
    subscription: state.shops.account.subscription,
    tier: state.shops.account.tier,
  }));

  const trialDaysLeft = moment.unix(subscription?.trialEndDate).diff(moment(), 'days') + 1;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const showFirstMonthPromo = showFirstMonthPromoBlock(account);

  return (
    <Grid
      container
      item
      className={classes.trialExpirationBannerContainer}
      xs={12}
      style={showFirstMonthPromo ? { backgroundColor: theme.branding.v2.green[50] } : {}}
    >
      <Grid
        container
        item
        justify="space-between"
        alignItems="center"
        className={classes.trialExpirationBanner}
        xs={12}
      >
        <Grid container item wrap="nowrap" alignItems="center" xs={12}>
          <InfoOutlined
            className={classes.infoOutlined}
            style={showFirstMonthPromo ? { color: theme.branding.v2.green[500] } : {}}
          />
          <Typography variant="body2">
            Explore how Castiron can elevate your business! Your free trial ends in {trialDaysLeft} days.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TrialExpirationBanner;
