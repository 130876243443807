import {
  AddToCartButton,
  AmountButtons,
  ButtonV2,
  OverImageCloseButton,
  ProductImages,
  ProductInformation,
  ProductTypePill,
  ProductVariations,
} from '@castiron/components';
import { BaseProduct, InputField, Product, ProductStatus, SelectedInputFieldValue, Shop, TicketedEvent } from '@castiron/domain';
import { removeEmpty, useTracking } from '@castiron/utils';
import { Box, Divider, Grid, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeModal } from '../../../store/reducers/modalConductor';
import ModalWrapper from '../../RootModal/ModalWrapper';
import { Formik, FormikProps } from 'formik';
import ModalActions from '../../RootModal/ModalActions';
import { productTemplateRepository, shopRepository } from '../../../domain';
import { getService } from '../../../firebase';
import _ from 'lodash';
import { createTemplateProductAction } from '../../../store/reducers/products';

const generateDescriptionService = getService('products', 'generatedescription');

export type Props = {
  show: boolean;
  product: Product | TicketedEvent;
  initialAmount: number;
  templateId?: string;
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  amountButtons: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '16px 24px 24px 24px',
    width: '100%',
  },
  divider: {
    background: theme.branding.v2.gray[200],
    height: '8px !important',
  },
  footer: {
    alignItems: 'center',
    bottom: '0',
    display: 'flex',
    flexDirection: 'row',
    minHeight: 80,
    justifyContent: 'center',
    padding: '0px 24px 24px 24px',
    position: 'sticky',
    width: '100%',
    zIndex: 1000,
  },
  paperClass: {
    width: '100%',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      borderRadius: 12,
      width: 504,
    },
  },
  section: {
    padding: '24px 24px 0px 24px',
  },
  templateContent: {
    overflowY: 'scroll',
    'scrollbar-width': 'thin',
    'scrollbar-color': theme.branding.v2.gray[400],
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.branding.v2.gray[400],
      '-webkit-border-radius': '12px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.branding.v2.gray[200],
      borderRadius: props => (!props.templateId ? '0px 20px 20px 20px' : ''),
    },
    '&::-webkit-scrollbar-track-piece:start': {
      background: 'transparent',
    },
    '&-webkit-scrollbar-track-piece:end': {
      background: 'transparent',
    },
  },
  templateFooter: {
    backgroundColor: theme.branding.v2.gray[0],
    borderTop: `1px solid ${theme.branding.v2.gray[200]}`,
    paddingTop: 16,
  },
}));

const ProductPreviewModal: React.FC<Props> = (props: Props) => {
  const { show, product, initialAmount, templateId } = props;
  const [amount, setAmount] = useState(initialAmount || 1);
  const classes = useStyles(props);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { trackEvent } = useTracking();
  const theme = useTheme();
  const isMobile = useMediaQuery('xs');
  const [isCreatingProduct, setIsCreatingProduct] = useState<boolean>(false);

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const [seller, setSeller] = useState<Shop>(shop);

  const handleClose = useCallback((): void => {
    if (shop && product) {
      dispatch(closeModal());
    }
  }, [shop, product]);

  const createProduct = async () => {
    setIsCreatingProduct(true);
    const result = await productTemplateRepository.get(templateId);
    if (result?.template) {
      const productTemplate = result?.template;
      const generateDescriptionResponse = await generateDescriptionService({
        title: productTemplate?.title,
        category: productTemplate?.category?.name,
        allergens: productTemplate?.allergen?.join(', '),
        dietary: productTemplate?.dietary?.join(', '),
      });

      const newProduct = _.omit(
        {
          ...productTemplate,
          type: productTemplate?.type || 'standard',
          unlimitedInventory: productTemplate?.type === 'standard',
          description: generateDescriptionResponse?.description || productTemplate?.description,
          status: 'inactive' as ProductStatus,
          shopId: shop.id,
          productTemplateId: templateId,
        },
        'category',
        'imageObj',
        'inventory',
        'minimum',
      );

      const createProductResponse = await dispatch(createTemplateProductAction(newProduct as BaseProduct));
      const createdProduct = createProductResponse?.payload as BaseProduct;
      if (createdProduct?.id) {
        trackEvent('Product Created', {
          product: removeEmpty({
            ...createdProduct,
            productTemplateTitle: result?.displayName,
            method: 'admin',
          }),
          addMethod: 'template',
        });

        trackEvent(createdProduct.type === 'standard' ? 'Standard Product Created' : 'Custom Product Created', {
          product: removeEmpty({
            ...createdProduct,
            productTemplateTitle: result?.displayName,
            method: 'admin',
          }),
          addMethod: 'template',
        });
        history.push(`/products/edit/${createdProduct?.id}`, {
          fromCreate: true,
          generationId: generateDescriptionResponse?.generationId,
          generatedDescription: generateDescriptionResponse?.description,
        });
      }
    }
    setIsCreatingProduct(false);
    handleClose();
  };

  // This allows us to use useFormikContext for preview inputs without running into undefined errors
  const initialValues = {
    fullName: '',
    email: '',
    mobileNumber: '',
    subscribed: true,
    type: '',
    date: 0,
    fullAddress: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    region: '',
    postalCode: '',
    country: '',
    selectedFields:
      product?.variations?.map(
        (variation: InputField): SelectedInputFieldValue => {
          return {
            inputFieldId: variation.id,
            inputFieldName: variation.name,
            selectedValues: [],
          };
        },
      ) || [],
  };

  useEffect(() => {
    const getSeller = async() => {
      if (product?.sellerId) {
        const seller = await shopRepository.get(product.sellerId);
        if (seller) {
          setSeller(seller);
        }
      }
    }
    getSeller();
  }, []);

  const productContentBlock = () => (
    <>
      <ProductImages product={product} />
      {!!templateId && (
        <Box style={{ margin: '8px' }}>
          <ProductTypePill type={product.type} />
        </Box>
      )}
      <Grid container item className={classes.section} style={!!templateId ? { paddingTop: 0 } : {}}>
        <ProductInformation product={product} readonly seller={seller} timeZone={shop?.config?.timeZone} />
      </Grid>
      <Divider className={classes.divider} />
      <Box>
        {product.variations && product.variations.length > 0 && (
          <Box className={classes.section}>
            {product.variations.map((variation: InputField, index: number) => (
              <ProductVariations
                product={product}
                key={variation.id}
                index={index}
                variation={variation}
                shop={shop}
                readonly
              />
            ))}
          </Box>
        )}
      </Box>
      {product?.type !== 'event' && (
        <AmountButtons
          containerClass={classes.amountButtons}
          onIncreaseClick={(e): void => {
            if (e) e.preventDefault();
            if (product?.unlimitedInventory) {
              setAmount(amount + 1);
            } else if (!product.unlimitedInventory && amount < product?.inventory) {
              setAmount(amount + 1);
            }
          }}
          onDecreaseClick={(e): void => {
            if (e) e.preventDefault();
            if (amount >= 2) {
              setAmount(amount - 1);
            }
          }}
          value={amount}
          product={product}
          isInModal
        />
      )}
    </>
  );

  return !product ? (
    <></>
  ) : (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {
        return;
      }}
    >
      {(formikProps: FormikProps<any>) => (
        <ModalWrapper fullWidth={isMobile} size="md" show={show} paperClass={classes.paperClass}>
          <OverImageCloseButton action={handleClose} isTemplate={!!templateId} />
          <Grid container item className={classes.templateContent}>
            {productContentBlock()}
          </Grid>
          <Box className={`${classes.footer} ${!!templateId ? classes.templateFooter : ''}`}>
            {!!templateId ? (
              <Grid container spacing={1} justify="flex-end">
                <Grid item>
                  <ButtonV2 variant="outlined" onClick={handleClose}>
                    Cancel
                  </ButtonV2>
                </Grid>
                <Grid item>
                  <ButtonV2
                    loading={isCreatingProduct}
                    disabled={isCreatingProduct}
                    variant="contained"
                    onClick={createProduct}
                  >
                    Use & Customize
                  </ButtonV2>
                </Grid>
              </Grid>
            ) : (
              <AddToCartButton
                price={product?.price * 100}
                disabled
                isSoldOut={!(product.unlimitedInventory || product.inventory)}
              />
            )}
          </Box>
        </ModalWrapper>
      )}
    </Formik>
  );
};

export default ProductPreviewModal;
