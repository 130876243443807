import firebase from 'firebase/compat';
import _firestore from '@google-cloud/firestore';
import { BaseDocument, BaseRepository, FieldFunctions } from '../base/repository';

export type PriceFrequency = 'one-time' | 'monthly' | 'yearly';

export interface Price {
  id: string;
  amount: number;
  discount?: number;
  frequency: PriceFrequency;
  integrations: {
    stripe: {
      priceId: string;
    };
  };
}

export interface PlanComparisonItem {
  name: string;
  description?: string;
  category: string;
  order: number;
  comparison: boolean | string;
}

export interface Plan extends BaseDocument<Plan> {
  name: string;
  description: string;
  position: number;
  tier: {
    id: string;
    name: string;
  };
  prices: Price[];
  integrations: {
    stripe: {
      productId: string;
    };
  };
  trialDays?: number;
  notes?: string[];
  planComparisonItems?: PlanComparisonItem[];
}

export class PlanRepository extends BaseRepository<Plan> {
  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, fieldFunctions?: FieldFunctions) {
    super(firestore, 'plans', fieldFunctions);
  }

  public async list(): Promise<Plan[]> {
    return this.find({
      where: [{ field: 'status', operator: '==', value: 'active' }],
      orderBy: [{ field: 'position', direction: 'asc' }],
    });
  }

  public async findByStripeProductIds(stripeProductIds: string[]): Promise<Plan[]> {
    return this.find({
      where:[
        { field: 'integrations.stripe.productId', operator: 'in', value: stripeProductIds },
        { field: 'status', operator: '==', value: 'active' }
      ]
    })
  }
}
