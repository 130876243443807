import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import _ from 'lodash';
import clsx from 'clsx';
import { Formik, FormikProps } from 'formik';
import { nanoid } from 'nanoid';
import { useAppSelector, useAppDispatch } from '../../../../hooks';
import { Box, ButtonBase, FormControl, Grid, makeStyles, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { Theme } from '@material-ui/core/styles';
import {
  ImageUploader,
  Typography,
  Chip,
  CustomSwitch,
  FormErrorList,
  SaveButton,
  DiscardButton,
  ButtonV2,
  SelectOption,
  SelectInput,
  useFeatures,
} from '@castiron/components';
import { Asset, ChecklistValues, Shop, ButtonColorOption, ButtonRoundnessOption } from '@castiron/domain';
import { shopRepository } from '@castiron/client-admin/src/domain';
import { useTracking, findFontFamily, removeEmpty } from '@castiron/utils';
import { FeatureFlag, upload, useConfig } from '@castiron/castiron-firebase';
import { getCastIronAssetsAction } from '../../../../store/reducers/assets';
import { openModal, closeModal } from '../../../../store/reducers/modalConductor';
import { getShopAction, updateShopAction } from '../../../../store/reducers/shops';
import { convertToAsset } from '../../../../lib/imageUtils';
import AdminForm from '../../../AdminForm';
import UnsavedChangesPrompt from '../../../UnsavedChangesPrompt.tsx';
import ViewShopButton from '../../../Layout/Header/ViewShopButton';
import { LayoutPageProps } from '../../../Layout';
import EllipsisMenu from '../../../Menus/EllipsisMenu';
import ThemePreview from '../../../ThemePreview';

const useStyles = makeStyles((theme: Theme) => ({
  appearanceModalMediaAdjustments: {
    '& img': {
      height: '100%',
      width: '100%',
    },
  },
  appearanceModalContentAdjustments: {
    maxWidth: '100% !important',
  },
  button: {
    minWidth: 0,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 50,
    paddingBottom: 30,
    gap: 8,
    width: '100%',
  },
  changeThemeButton: {
    borderTop: `1px solid ${theme.branding.v2.gray[200]}`,
    padding: '24px 0px',
    width: '100%',
    borderRadius: '0 0 11px 11px',
  },
  changeThemeText: {
    color: theme.branding.v2.blue[500],
    fontWeight: 600,
  },
  chip: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  colorOption: {
    height: 40,
    width: 40,
    margin: '0px 8px 8px 0px',
    borderRadius: 100,
  },
  fontOptionPreview: {
    backgroundColor: theme.branding.v2.gray[0],
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 12,
    padding: 24,
    textAlign: 'center',
  },
  imageBox: {
    border: `1px solid ${theme.branding.v2.gray[300]}`,
    borderRadius: '8px',
    display: 'inline-block',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  imagePreview: {
    aspectRatio: '1/1',
    objectFit: 'cover',
    borderRadius: '50%',
    margin: '16px',
    maxWidth: '300px',
    width: 'calc(100% - 32px)',
  },
  imageTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '8px',
  },
  logoAndShop: {
    border: `1px solid ${theme.branding.v2.gray[300]}`,
    borderRadius: '8px',
    padding: '13px 16px 16px 16px',
    marginTop: '15px',
  },
  menuButton: {
    zIndex: 25,
    backgroundColor: theme.palette.common.white,
    right: 0,
    margin: 8,
    borderRadius: 12,
    width: 40,
    height: 40,
    position: 'absolute',
    border: `1px solid ${theme.branding.gray[400]}`,
    '& svg': {
      color: theme.branding.v2.gray[500],
    },
    '& svg:hover': {
      color: theme.branding.v2.gray[800],
    },
  },
  menu: {
    inset: '8px -58px !important',
  },
  mobileSection: {
    [theme.breakpoints.down('sm')]: {
      padding: '12px 0px',
    },
  },
  optionsContainer: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 16,
    width: '100%',
    marginBottom: 16,
  },
  optionsPreview: {
    backgroundColor: theme.branding.v2.gray[50],
    padding: '24px 24px 16px 24px',
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  previewBlurb: {
    //there just...really wasnt a good match for this text
    fontWeight: 400,
    color: theme.branding.v2.gray[500],
    marginBottom: 16,
  },
  previewButton: {
    maxWidth: 295,
    padding: '16px auto !important',
    width: '100%',
  },
  roundnessOption: {
    border: `4px solid ${theme.branding.v2.gray[0]}`,
    padding: 4,
    maxWidth: 208,
  },
  roundnessOptionInner: {
    height: 56,
    width: '100%',
    maxWidth: 192,
    backgroundColor: theme.branding.v2.gray[200],
  },
  roundnessOptionSelected: {
    border: `4px solid ${theme.branding.v2.blue[500]}`,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 22,
  },
  switch: {
    marginTop: 8,
  },
  tabPanel: {
    [theme.breakpoints.down('sm')]: {
      padding: 17,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  themeDisplay: {
    borderRadius: 12,
    border: `1px solid ${theme.branding.v2.gray[200]}`,
  },
  trashIcon: {
    right: '0px',
    margin: '4px',
    position: 'absolute',
    '& svg': {
      color: theme.branding.v2.gray[500],
    },
  },
}));

const Appearance: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const formikRef = useRef<FormikProps<Shop>>();
  const features = useFeatures();
  const history = useHistory();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const fromChecklist = urlParams.get('fromChecklist');
  const isShopThemeEnabled = features.includes('shop.themes');

  const { setFieldValue } = formikRef?.current || {};

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));
  const shopTheme = shop?.config?.shopTheme;

  const [logoImageObj, setLogoImageObj] = useState(undefined);
  const [submitting, setSubmitting] = useState(false);
  const [selectedButtonColor, setSelectedButtonColor] = useState(shopTheme?.shopButtonColor || 'blue');
  const [selectedButtonRoundness, setSelectedButtonRoundness] = useState(shopTheme?.shopButtonRoundness || '12px');
  const [photoUploaded, setPhotoUploaded] = useState('');

  const addBrandToChecklist = async () => {
    await shop.addToChecklist(ChecklistValues.Brand);
    await dispatch(getShopAction(shop.id));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!shop?.checklistCompletions?.includes(ChecklistValues.Brand)) {
      addBrandToChecklist();
    }
  }, []);

  useEffect(() => {
    if (logoImageObj === undefined && shop?.logoImageObj?.downloadUrl) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setLogoImageObj(shop.logoImageObj);
    }
  }, [shop]);

  useEffect(() => {
    if (shop?.config?.shopTheme?.shopButtonColor) {
      setSelectedButtonColor(shop?.config?.shopTheme?.shopButtonColor);
    }
    if (shop?.config?.shopTheme?.shopButtonRoundness) {
      setSelectedButtonRoundness(shop?.config?.shopTheme?.shopButtonRoundness);
    }
  }, [shop]);

  useEffect(() => {
    if (fromChecklist) window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [fromChecklist]);

  useEffect(() => {
    dispatch(getCastIronAssetsAction());
  }, [dispatch]);

  useEffect(() => {
    setPageTitle('Appearance');
    setBackLocation(true);
    setHeaderCTAs([<ViewShopButton />]);

    return () => {
      setPageTitle('');
      setBackLocation(false);
      setHeaderCTAs([]);
    };
  }, []);

  useEffect(() => {
    setFooterCTAs([
      <DiscardButton isSubmitting={submitting} backLocation="/store/dashboard" />,
      <SaveButton isSubmitting={submitting} formikState={formikRef.current} />,
    ]);

    return () => {
      setFooterCTAs([]);
    };
  }, [submitting]);

  const openGatingModal = () => {
    dispatch(
      openModal({
        modalType: 'LEGACY_GATING_MODAL',
        modalProps: {
          show: true,
        },
      }),
    );
  };

  const handleFile = async (item, assetType) => {
    const { file } = item;
    const id = nanoid();
    //TODO: this was failing when a file did not exist and was not updating products
    //this seemed to fix it (prevents upload) but I'm not sure if that is what should actually be done
    if (file) {
      const metadata = {
        shopId: shop.id,
        originalFilename: id,
        id,
        assetType,
      };
      const options = {
        folder: `user/${shop.id}`,
      };
      const callbacks = {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        success: handleFileUploadSuccess,
      };
      const context = {
        shop: shop,
      };
      await upload(file, metadata, options, callbacks, context);
    }
    return id;
  };

  const handleFileUploadSuccess = async (downloadUrl, metadata, options) => {
    console.debug('handleFileUploadSuccess: ', downloadUrl);

    const newProps = removeEmpty({
      [`${metadata.assetType}ImageObj.id`]: metadata.id,
      [`${metadata.assetType}ImageObj.shopId`]: shop.id,
      [`${metadata.assetType}ImageObj.downloadUrl`]: downloadUrl,
      [`${metadata.assetType}ImageObj.metadata`]: metadata,
      [`${metadata.assetType}ImageObj.options`]: options,
    });

    await shopRepository.updateProps(shop.id, newProps);
    dispatch(getShopAction(shop.id));
  };

  const storeSchema = yup.object().shape({
    logo: yup.object().nullable(),
    useLogoAndName: yup.boolean(),
    bannerEnabled: yup.boolean(),
    bannerMessage: yup.string().nullable(),
    buttonColor: yup.string().nullable(),
    buttonRoundness: yup.string().nullable(),
    fontPairing: yup.object().shape({
      primary: yup.string(),
      secondary: yup.string(),
    }),
  });

  const emptyValues = {
    logo: '',
    useLogoAndName: !!shop.useLogoAndName,
    bannerMessage: '',
    bannerEnabled: false,
  };

  const initialValues = {
    ...emptyValues,
    ...shop,
    logo: shop?.logoImageObj,
    bannerMessage: shop?.config?.banner?.message || '',
    bannerEnabled: shop?.config?.banner?.enabled || false,
    buttonColor: selectedButtonColor,
    buttonRoundness: selectedButtonRoundness,
    fontPairing: !isShopThemeEnabled
      ? JSON.stringify({ primary: 'nunito-sans', secondary: 'nunito-sans' })
      : !!shop?.config?.shopTheme?.shopFont
      ? JSON.stringify({
          primary: shop.config.shopTheme.shopFont.primary,
          secondary: shop.config.shopTheme.shopFont.secondary,
        })
      : JSON.stringify({ primary: 'nunito-sans', secondary: 'nunito-sans' }),
  };

  const onSubmit = async (values, formikProps) => {
    setSubmitting(true);
    try {
      const { logo, bannerMessage, bannerEnabled, buttonColor, buttonRoundness, fontPairing, ...restOfValues } = values;

      const config = {
        ...shop?.config,
        banner: {
          enabled: bannerEnabled,
          message: bannerMessage,
        },
        shopTheme: {
          ...shop?.config?.shopTheme,
          shopButtonColor: buttonColor,
          shopButtonRoundness: buttonRoundness,
          shopFont: JSON.parse(fontPairing),
        },
      };

      const newShop: Shop = {
        ...shop,
        ...restOfValues,
        config,
      };

      if (!logo) newShop.logoImageObj = null;

      const newCompletions = [];

      const updateShopResponse = await dispatch(updateShopAction({ shop: newShop, newCompletions }));

      if (updateShopResponse.meta.requestStatus === 'fulfilled') {
        dispatch(
          openModal({
            modalType: 'SIMPLE_ALERT',
            modalProps: {
              show: true,
              celebrate: true,
              content: 'Changes have been saved!',
            },
          }),
        );
      }

      if (logo) {
        if (!shop || logo.id !== shop.logoImageObj?.id) {
          trackEvent('Update Shop Logo', {
            shop: _.pick(shop, ['id', 'websiteUrl', 'businessName', 'email']),
          });
          await handleFile(logo, 'logo');
        }
      }

      if (buttonColor) {
        if (!shop || buttonColor !== shop.config?.shopTheme?.shopButtonColor) {
          trackEvent('Shop Button Colors Updated', {
            shop: _.pick(shop, ['id', 'websiteUrl', 'businessName', 'email']),
          });
        }
      }

      if (buttonRoundness) {
        if (!shop || buttonRoundness !== shop.config?.shopTheme?.shopButtonRoundness) {
          trackEvent('Shop Button Roundness Updated', {
            shop: _.pick(shop, ['id', 'websiteUrl', 'businessName', 'email']),
          });
        }
      }

      setSubmitting(false);
      formikProps.resetForm();
    } catch (err) {
      setSubmitting(false);
      console.error('Error Submitting Shop Form: ', err);
    }
  };

  useEffect(() => {
    if (photoUploaded === 'logo') {
      dispatch(
        openModal({
          modalType: 'EDIT_PHOTO_MODAL',
          modalProps: {
            show: true,
            imageLocation: 'logo',
            imageObj: logoImageObj,
            cropShape: 'rect',
            onClose: croppedImage => {
              handleCroppedImage(croppedImage, 'logo');
            },
            onCancel: () => onCropCancel('logo', true),
          },
        }),
      );
    }
  }, [photoUploaded]);

  const handleCroppedImage = async (croppedImage: File, loc: string) => {
    const metadata = {
      id: logoImageObj?.id,
      shopId: shop.id,
      originalFilename: logoImageObj?.metadata?.originalFilename,
      assetType: loc,
      contentType: logoImageObj?.metadata?.contentType,
    };

    const callbacks = {
      success: handleFileUploadSuccess,
    };

    const options = {
      folder: `user/${shop.id}`,
    };

    const context = {
      shop,
    };

    const croppedImageObj = convertToAsset(
      URL.createObjectURL(croppedImage),
      loc,
      metadata?.originalFilename,
      shop,
      metadata?.contentType,
    );

    await upload(croppedImage, metadata, options, callbacks, context);
    setLogoImageObj(croppedImageObj);
    setFieldValue('logo', croppedImageObj);
    trackEvent('Update Shop Logo', {
      shop: _.pick(shop, ['id', 'websiteUrl', 'businessName', 'email']),
    });
    dispatch(closeModal());
  };

  const onCropCancel = (loc: string, isNew: boolean) => {
    if (isNew) {
      setLogoImageObj('');
      setFieldValue('logo', '');
    } else {
      setLogoImageObj(shop.logoImageObj);
      setFieldValue('logo', shop.logoImageObj);
    }

    setPhotoUploaded('');
    dispatch(closeModal());
  };

  const profileMenuOptions = (loc: 'logo') => {
    type option = {
      display: string;
      icon?: React.ReactNode;
      color?: 'error';
      action: () => void;
    };
    let options: option[] = [
      {
        display: 'Edit Photo',
        action: () => {
          dispatch(
            openModal({
              modalType: 'EDIT_PHOTO_MODAL',
              modalProps: {
                show: true,
                imageLocation: 'logo',
                imageObj: logoImageObj,
                cropShape: 'rect',
                onClose: croppedImage => {
                  handleCroppedImage(croppedImage, loc);
                },
                onCancel: () => onCropCancel(loc, false),
              },
            }),
          );
        },
      },
      {
        display: 'Delete Photo',
        // icon: <DeleteOutlineIcon />,
        color: 'error',
        action: () => {
          setLogoImageObj('');
          setFieldValue('logo', '');
          setPhotoUploaded('');
        },
      },
    ];
    return options;
  };

  const roundnessOptions = ['12px', '100px', '0px'];
  const colorOptions: { color: ButtonColorOption; fontColor: string }[] = [
    { color: 'blue', fontColor: theme.branding.v2.gray[0] },
    { color: 'indigo', fontColor: theme.branding.v2.gray[0] },
    { color: 'deepPurple', fontColor: theme.branding.v2.gray[0] },
    { color: 'lightBlue', fontColor: theme.branding.v2.gray[900] },
    { color: 'cyan', fontColor: theme.branding.v2.gray[900] },
    { color: 'teal', fontColor: theme.branding.v2.gray[900] },
    { color: 'green', fontColor: theme.branding.v2.gray[0] },
    { color: 'lightGreen', fontColor: theme.branding.v2.gray[900] },
    { color: 'lime', fontColor: theme.branding.v2.gray[900] },
    { color: 'pink', fontColor: theme.branding.v2.gray[0] },
    { color: 'lightRed', fontColor: theme.branding.v2.gray[0] },
    { color: 'deepOrange', fontColor: theme.branding.v2.gray[900] },
    { color: 'gray', fontColor: theme.branding.v2.gray[0] },
  ];
  const buttonTextColor = colorOptions.filter(option => option.color === selectedButtonColor)[0].fontColor;

  const formatText = text => {
    return text.replaceAll('-', ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  };

  const findFontDisplay = (primaryFont: string, secondaryFont: string) => {
    const formattedPrimary = formatText(primaryFont);
    const formattedSecondary = formatText(secondaryFont);

    return (
      <Grid container direction="column">
        <Typography variant="h3" style={{ fontFamily: findFontFamily(shop, features, 'primary', primaryFont) }}>
          {formattedPrimary}
        </Typography>
        <Typography
          variant="placeholder1"
          style={{ fontFamily: findFontFamily(shop, features, 'secondary', secondaryFont) }}
        >
          {formattedSecondary}
        </Typography>
      </Grid>
    );
  };

  const fontPairings = [
    {
      primary: 'syne',
      secondary: 'inter',
    },
    {
      primary: 'montserrat',
      secondary: 'open-sans',
    },
    {
      primary: 'abril-fatface',
      secondary: 'poppins',
    },
    {
      primary: 'ultra',
      secondary: 'pt-serif',
    },
    {
      primary: 'jost',
      secondary: 'jost',
    },
    {
      primary: 'pacifico',
      secondary: 'lato',
    },
    {
      primary: 'playfair-display',
      secondary: 'lato',
    },
    {
      primary: 'dela-gothic-one',
      secondary: 'roboto',
    },
    {
      primary: 'nunito-sans',
      secondary: 'nunito-sans',
    },
    {
      primary: 'yeseva-one',
      secondary: 'josefin-sans',
    },
    {
      primary: 'philosopher',
      secondary: 'mulish',
    },
    {
      primary: 'shrikhand',
      secondary: 'lora',
    },
    {
      primary: 'lemon',
      secondary: 'cambay',
    },
    {
      primary: 'lobster',
      secondary: 'montserrat',
    },
    {
      primary: 'sail',
      secondary: 'montserrat',
    },
  ];

  //have to manually stringify the option because mui select attempts to stringify non-string values, which returns [object Object]
  const fontOptions: SelectOption[] = fontPairings.map(option => ({
    display: findFontDisplay(option.primary, option.secondary),
    value: JSON.stringify(option),
  }));

  const announcmentBanner = values => (
    <FormControl className={classes.mobileSection}>
      <Grid container direction="column">
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="subtitle1">Announcement banner</Typography>
          <FormControl>
            <CustomSwitch
              name="bannerEnabled"
              checked={values.bannerEnabled}
              onChange={e => {
                setFieldValue('bannerEnabled', !values.bannerEnabled);
              }}
              className={classes.switch}
            />
          </FormControl>
        </Grid>
        <Typography variant="body4">
          Show a message to all shoppers in a bar at the top of your shop page. You can highlight a new promotion, when
          a new product will be launching, a vacation, or anything else that you want to make sure shoppers don’t miss.
        </Typography>
      </Grid>
      <TextField
        value={values.bannerMessage || ''}
        placeholder="We are currently on vacation. Any orders placed will ship after November 15th. 🏝"
        rows={1}
        onChange={e => setFieldValue('bannerMessage', e.target.value)}
        variant="outlined"
        inputProps={{ maxLength: 144 }}
      />
    </FormControl>
  );

  return (
    <Grid container>
      <Helmet>
        <title>Appearance | Shop | Castiron</title>
      </Helmet>
      <Grid item xs={12} className={classes.tabPanel}>
        <Formik
          initialValues={initialValues}
          validationSchema={storeSchema}
          onSubmit={onSubmit}
          innerRef={formikRef}
          enableReinitialize
        >
          {({ setFieldValue, values, isSubmitting, errors, submitCount, dirty }) => (
            <AdminForm>
              <Grid spacing={!isMobile && 6} container>
                <Grid className={classes.section} item xs={12} md={8}>
                  {!isMobile && announcmentBanner(values)}

                  {isShopThemeEnabled && (
                    <FeatureFlag name="feature_shop_themes">
                      <Grid container item className={classes.section} xs={12}>
                        <Typography variant="subtitle1">Website Theme</Typography>
                        <Grid container className={classes.themeDisplay}>
                          <ThemePreview
                            themeName={shopTheme?.name ? formatText(shopTheme.name) : 'Blueberry Parfait'}
                          />
                          <ButtonBase
                            onClick={() => {
                              isShopThemeEnabled ? history.push('/store/theme') : openGatingModal();
                            }}
                            className={classes.changeThemeButton}
                          >
                            <Grid container justify="center" alignItems="center">
                              <Typography variant="body1" className={classes.changeThemeText}>
                                Change Theme
                              </Typography>
                            </Grid>
                          </ButtonBase>
                        </Grid>
                      </Grid>
                    </FeatureFlag>
                  )}

                  <Grid container item className={classes.section} xs={12}>
                    <Typography variant="subtitle1">Buttons</Typography>
                    <Grid className={classes.optionsContainer} direction="column" container item>
                      <Grid container item direction="column" style={{ padding: 24 }}>
                        <Grid container item direction="column">
                          <Grid item>
                            <Typography variant="button">Button Roundness</Typography>
                          </Grid>
                          <Grid container item direction="row" justify="space-between">
                            {roundnessOptions.map((option: ButtonRoundnessOption, index) => (
                              <Grid
                                item
                                xs={4}
                                key={index}
                                style={{ borderRadius: values.buttonRoundness === '12px' ? 20 : option }}
                                onClick={() => {
                                  setFieldValue('buttonRoundness', option);
                                  setSelectedButtonRoundness(option);
                                }}
                                className={clsx([
                                  classes.roundnessOption,
                                  selectedButtonRoundness === option && classes.roundnessOptionSelected,
                                ])}
                              >
                                <ButtonBase
                                  disableRipple
                                  name="buttonRoundness"
                                  value={values.buttonRoundness}
                                  style={{ borderRadius: option }}
                                  className={classes.roundnessOptionInner}
                                ></ButtonBase>
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                        <Grid container item direction="column">
                          <Typography variant="button" style={{ margin: '16px 0 8px 0' }}>
                            Button Color
                          </Typography>
                          <Grid container item direction="row">
                            {colorOptions.map((option, index) => (
                              <Grid
                                container
                                item
                                justify="center"
                                alignItems="center"
                                onClick={() => {
                                  setFieldValue('buttonColor', option.color);
                                  setSelectedButtonColor(option.color);
                                }}
                                className={classes.colorOption}
                                style={{
                                  backgroundColor:
                                    theme.branding.v2[option.color][
                                      option.color === 'gray' || option.color === 'green' ? 900 : 500
                                    ],
                                }}
                              >
                                <Grid item>
                                  <ButtonBase disableRipple name="buttonColor" value={values.buttonColor}>
                                    {option.color === selectedButtonColor && (
                                      <CheckIcon style={{ color: theme.branding.v2.gray[0] }} />
                                    )}
                                  </ButtonBase>
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid className={classes.optionsPreview} container item direction="column">
                        <Typography variant="button">Preview</Typography>
                        <Typography variant="caption2" className={classes.previewBlurb}>
                          This is how buttons will appear on your website.
                        </Typography>
                        <ButtonV2
                          variant="contained"
                          style={{
                            backgroundColor:
                              theme.branding.v2[selectedButtonColor][
                                selectedButtonColor === 'gray' || selectedButtonColor === 'green' ? 900 : 500
                              ],
                            borderRadius: selectedButtonRoundness,
                            color: buttonTextColor,
                            maxWidth: 295,
                          }}
                        >
                          Button
                        </ButtonV2>
                      </Grid>
                    </Grid>
                  </Grid>

                  {isShopThemeEnabled && (
                    <FeatureFlag name="feature_shop_themes">
                      <Grid container item className={classes.section} xs={12}>
                        <Typography variant="subtitle1">Fonts</Typography>
                        <Grid className={classes.optionsContainer} direction="column" container item>
                          <Grid container item direction="column" style={{ padding: 24 }}>
                            <Grid container item direction="column">
                              <Typography variant="button" style={{ margin: '16px 0 8px 0' }}>
                                Font Pairing
                              </Typography>
                              {isShopThemeEnabled ? (
                                <SelectInput name={'fontPairing'} options={fontOptions} />
                              ) : (
                                <ButtonBase disableRipple onClick={openGatingModal} style={{ borderRadius: 12 }}>
                                  <SelectInput disabled name={'fontPairing'} options={fontOptions} />
                                </ButtonBase>
                              )}
                            </Grid>
                          </Grid>
                          <Grid className={classes.optionsPreview} container item direction="column">
                            <Typography variant="button">Preview</Typography>
                            <Typography variant="caption2" className={classes.previewBlurb}>
                              This is how the fonts will appear on your website.
                            </Typography>
                            <Grid container item direction="column" className={classes.fontOptionPreview}>
                              <Typography
                                variant="h3"
                                style={{
                                  fontFamily: findFontFamily(
                                    shop,
                                    features,
                                    'primary',
                                    JSON.parse(values.fontPairing).primary,
                                  ),
                                  letterSpacing: '0.15px',
                                  lineHeight: '28px',
                                }}
                              >
                                Claim Your 10% Off Coupon
                              </Typography>
                              <Typography
                                variant="placeholder2"
                                style={{
                                  fontFamily: findFontFamily(
                                    shop,
                                    features,
                                    'secondary',
                                    JSON.parse(values.fontPairing).secondary,
                                  ),
                                  letterSpacing: '0.25px',
                                }}
                              >
                                Receive 10% off and receive occasional emails with the latest and greatest. (We’ll never
                                share your info and you can unsubscribe any time)
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </FeatureFlag>
                  )}
                  {isMobile && announcmentBanner(values)}
                </Grid>

                <Grid className={classes.section} item xs={12} md={4}>
                  <Grid id="logo" className={classes.mobileSection}>
                    <Grid className={classes.imageTitle}>
                      <Typography variant="subtitle1">Shop Logo</Typography>
                      <Chip colorScheme="info" className={classes.chip}>
                        <Typography variant="h6">Recommended</Typography>
                      </Chip>
                    </Grid>
                    <Typography variant="body4" style={{ marginBottom: '8px' }}>
                      Appears on the top of your shop’s homepage, checkout process, and shop emails. Don’t have a logo
                      yet? We’ll use your shop name instead.
                    </Typography>
                    {logoImageObj?.downloadUrl ? (
                      <Grid item className={classes.imageBox}>
                        <Grid container justify="center" className={classes.menuButton}>
                          <EllipsisMenu options={profileMenuOptions('logo')} className={classes.menu} />
                        </Grid>
                        <img className={classes.imagePreview} src={logoImageObj?.downloadUrl} />
                      </Grid>
                    ) : (
                      <ImageUploader
                        onFileDrop={(files): void => {
                          const tempAsset = convertToAsset(
                            URL.createObjectURL(files[0]),
                            'profile',
                            files[0].name,
                            shop,
                            files[0].type,
                          );
                          setLogoImageObj(tempAsset);
                          setFieldValue('logo', tempAsset);
                          setPhotoUploaded('logo');
                        }}
                      />
                    )}
                  </Grid>
                  <Grid className={classes.logoAndShop}>
                    <Grid container direction="row" alignItems="center" style={{ marginBottom: '5px' }}>
                      <FormControl>
                        <CustomSwitch
                          name="useLogoAndName"
                          checked={values.useLogoAndName}
                          onChange={e => {
                            setFieldValue('useLogoAndName', !values.useLogoAndName);
                          }}
                          className={classes.switch}
                        />
                      </FormControl>
                      <Typography variant="h5" style={{ margin: '8px 0px 0px 16px ' }}>
                        Use Logo & Shop Name
                      </Typography>
                    </Grid>
                    <Typography variant="body4">
                      If left unselected, only the logo will be displayed at the top of your shop’s homepage.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {!!submitCount && !!errors && !isSubmitting && (
                <Box className={classes.buttonsContainer}>
                  <FormErrorList errors={errors} />
                </Box>
              )}
              <UnsavedChangesPrompt when={dirty} />
            </AdminForm>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default Appearance;
