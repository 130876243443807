import {
  ButtonV2,
  Card,
  CheckboxInput,
  OverImageCloseButton,
  PoliciesModal,
  ProductImages,
  ProductInformation,
  ProductTypePill,
  ProductVariations,
  Typography,
  useFeatures,
} from '@castiron/components';
import { BaseProduct, CustomProduct, InputField, ProductStatus, SelectedInputFieldValue, Shop } from '@castiron/domain';
import { removeEmpty, useTracking } from '@castiron/utils';
import { Box, Divider, Grid, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeModal } from '../../../store/reducers/modalConductor';
import ModalWrapper from '../../RootModal/ModalWrapper';
import CustomerDetailsPreview from './CustomerDetailsPreview';
import FulfillmentDetailsPreview from './FulfillmentDetailsPreview';
import { productTemplateRepository, shopRepository } from '../../../domain';
import { getService } from '../../../firebase';
import _ from 'lodash';
import { createTemplateProductAction } from '../../../store/reducers/products';

const generateDescriptionService = getService('products', 'generatedescription');

export type Props = {
  show: boolean;
  product: CustomProduct;
  initialAmount: number;
  templateId?: string;
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  checkbox: {
    padding: 0,
  },
  container: {
    gap: 8,
    padding: '8px 24px 24px 24px',
    '& section:nth-last-child(2)': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  divider: {
    background: theme.branding.v2.gray[200],
    height: '8px !important',
  },
  footer: {
    backgroundColor: theme.branding.gray[100],
    bottom: '0',
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    minHeight: 72,
    position: 'sticky',
    paddingRight: 16,
    zIndex: 1000,
  },
  paperClass: {
    width: '100%',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      borderRadius: 12,
      width: 504,
    },
  },
  policies: {
    color: theme.branding.v2.blue[400],
    cursor: 'pointer',
  },
  submitContainer: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    marginTop: -8,
    width: '100%',
  },
  submitContent: {
    padding: 24,
  },
  templateContent: {
    overflowY: 'scroll',
    'scrollbar-width': 'thin',
    'scrollbar-color': theme.branding.v2.gray[400],
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.branding.v2.gray[400],
      '-webkit-border-radius': '12px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.branding.v2.gray[200],
      marginBottom: 8,
      borderRadius: props => (!props.templateId ? '0px 20px 20px 20px' : ''),
    },
    '&::-webkit-scrollbar-track-piece:start': {
      background: 'transparent',
    },
    '&-webkit-scrollbar-track-piece:end': {
      background: 'transparent',
    },
  },
}));

const CustomProductPreviewModal: React.FC<Props> = (props: Props) => {
  const { show, product, templateId } = props;
  const classes = useStyles(props);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { trackEvent } = useTracking();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const features = useFeatures();
  const [showPolicies, setShowPolicies] = useState<boolean>(false);
  const [isCreatingProduct, setIsCreatingProduct] = useState<boolean>(false);

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const [seller, setSeller] = useState<Shop>(shop);

  const handleClose = useCallback((): void => {
    if (shop && product) {
      dispatch(closeModal());
    }
  }, [shop, product]);

  const createProduct = async () => {
    setIsCreatingProduct(true);
    const result = await productTemplateRepository.get(templateId);
    if (result?.template) {
      const productTemplate = result?.template;
      const generateDescriptionResponse = await generateDescriptionService({
        title: productTemplate?.title,
        category: productTemplate?.category?.name,
        allergens: productTemplate?.allergen?.join(', '),
        dietary: productTemplate?.dietary?.join(', '),
      });

      const newProduct = _.omit(
        {
          ...productTemplate,
          type: productTemplate?.type || 'standard',
          unlimitedInventory: productTemplate?.type === 'standard',
          description: generateDescriptionResponse?.description || productTemplate?.description,
          status: 'inactive' as ProductStatus,
          shopId: shop.id,
          productTemplateId: templateId,
        },
        'category',
        'imageObj',
        'inventory',
        'minimum',
      );

      const createProductResponse = await dispatch(createTemplateProductAction(newProduct as BaseProduct));
      const createdProduct = createProductResponse?.payload as BaseProduct;
      if (createdProduct?.id) {
        trackEvent('Product Created', {
          product: removeEmpty({
            ...createdProduct,
            productTemplateTitle: result?.displayName,
            method: 'admin',
          }),
          addMethod: 'template',
        });

        trackEvent(createdProduct.type === 'standard' ? 'Standard Product Created' : 'Custom Product Created', {
          product: removeEmpty({
            ...createdProduct,
            productTemplateTitle: result?.displayName,
            method: 'admin',
          }),
          addMethod: 'template',
        });
        history.push(`/order-forms/edit/${createdProduct?.id}`, {
          fromCreate: true,
          generationId: generateDescriptionResponse?.generationId,
          generatedDescription: generateDescriptionResponse?.description,
        });
      }
    }
    setIsCreatingProduct(false);
    handleClose();
  };

  // This allows us to use useFormikContext for preview inputs without running into undefined errors
  const initialValues = {
    fullName: '',
    email: '',
    mobileNumber: '',
    subscribed: true,
    type: '',
    date: 0,
    fullAddress: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    region: '',
    postalCode: '',
    country: '',
    selectedFields:
      product?.variations?.map(
        (variation: InputField): SelectedInputFieldValue => {
          return {
            inputFieldId: variation.id,
            inputFieldName: variation.name,
            selectedValues: [],
          };
        },
      ) || [],
    policiesAgreement: true,
  };

  useEffect(() => {
    const getSeller = async() => {
      if (product?.sellerId) {
        const seller = await shopRepository.get(product.sellerId);
        if (seller) {
          setSeller(seller);
        }
      }
    }
    getSeller();
  }, []);

  const productContentBlock = () => (
    <>
      <ProductImages isPreview product={product} />
      <Grid className={classes.container} container item>
        {!!templateId && <ProductTypePill type={product.type} />}
        <ProductInformation product={product} readonly sectionRoundness="12px" seller={seller} />
        <CustomerDetailsPreview />
        <FulfillmentDetailsPreview />
        {product?.variations && product.variations.length > 0 && (
          <Card noMargin removeChildPadding title="Request Details">
            <Box style={{ padding: 24 }}>
              {product.variations.map((variation: InputField, index: number) => (
                <ProductVariations
                  /* Needed an easy way to tell if the previous variation had a divider so I don't add a double divider */
                  removeTopDivider={!!product.variations[index - 1]?.imageObj?.downloadUrl}
                  product={product}
                  key={variation.id}
                  index={index}
                  variation={variation}
                  readonly
                  features={features}
                />
              ))}
            </Box>
          </Card>
        )}
        <Box className={classes.submitContainer}>
          <Divider className={classes.divider} />
          <Grid item className={classes.submitContent}>
            <CheckboxInput
              className={classes.checkbox}
              label={
                <Typography style={{ marginLeft: 16 }} variant="body1">
                  I’ve read and agree to {shop?.businessName}’s{' '}
                  <span className={classes.policies} onClick={() => setShowPolicies(true)}>
                    policies
                  </span>
                </Typography>
              }
              name="policiesAgreement"
              variant="label-right"
            />
            <ButtonV2 disabled={true} variant="contained" fullWidth style={{ marginTop: 24 }}>
              Submit Request
            </ButtonV2>
          </Grid>
        </Box>
      </Grid>
    </>
  );

  return !product ? (
    <></>
  ) : (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={() => {
          return;
        }}
      >
        {(formikProps: FormikProps<any>) => (
          <ModalWrapper fullWidth={isMobile} show={show} size="md" paperClass={classes.paperClass}>
            <OverImageCloseButton action={handleClose} isCustom isTemplate={!!templateId} />
            <Grid container item className={classes.templateContent}>
              {productContentBlock()}
            </Grid>
            {!!templateId && (
              <Grid container className={classes.footer} justify="flex-end" alignItems="center" spacing={1}>
                <Grid item>
                  <ButtonV2 variant="outlined" onClick={handleClose}>
                    Cancel
                  </ButtonV2>
                </Grid>
                <Grid item>
                  <ButtonV2
                    variant="contained"
                    loading={isCreatingProduct}
                    disabled={isCreatingProduct}
                    onClick={createProduct}
                  >
                    Use & Customize
                  </ButtonV2>
                </Grid>
              </Grid>
            )}
          </ModalWrapper>
        )}
      </Formik>
      {showPolicies && <PoliciesModal handleClose={() => setShowPolicies(false)} text={product.policies} />}
    </>
  );
};

export default CustomProductPreviewModal;
