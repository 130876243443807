import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import { BusinessAddressInput, Checkbox, CollapsableCard, TextAreaInput, Typography } from '@castiron/components';
import { Address, FulfillmentOption } from '@castiron/domain';
import DateTimeOptions from './DateTimeOptions';
import {useAppSelector} from '../../../../hooks';
import AddressAddAndDisplay from '../../../AddressAddAndDisplay';

const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    marginTop: 24,
  },
  checkbox: {
    [theme.breakpoints.down('xs')]: {
      '& .MuiFormControlLabel-label': {
        paddingRight: 0,
      },
    },
  },
}));

const PickupDetails: React.FC = () => {
  const { setFieldValue, values } = useFormikContext<FulfillmentOption>();
  const classes = useStyles();

  const [expanded, setExpanded] = useState<boolean>(true);

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const setAddress = async (address: Address) => {
    await setFieldValue('address', {
      fullAddress: address?.fullAddress || '',
      addressLine1: address?.addressLine1 || '',
      addressLine2: address?.addressLine2 || '',
      city: address?.city || '',
      region: address?.region || '',
      regionName: address?.regionName || '',
      country: address?.country || '',
      postalCode: address?.postalCode || '',
    });
  };

  const handleBusinessAddressCheckbox = async () => {
    const address = !values.useBusinessAddress ? shop?.physicalAddress : undefined;
    await setFieldValue('useBusinessAddress', !values.useBusinessAddress);
    await setAddress(address);
  };

  return (
    <Grid container direction="column">
      <Grid item className={classes.cardContainer}>
        <DateTimeOptions fulfillmentType="pick up" />
      </Grid>
      <Grid item className={classes.cardContainer}>
        <CollapsableCard title="Pickup Location" handleExpand={toggleExpand} expanded={expanded} noScroll>
          <Grid container direction="column">
            <Typography variant="subtitle2">Pickup Address</Typography>
            <Typography variant="body2">Where will customers go to collect their orders?</Typography>
            <Grid item style={{ margin: '16px 0px 8px' }}>
              <Checkbox
                checked={values.useBusinessAddress}
                onChange={handleBusinessAddressCheckbox}
                name="useBusinessAddress"
                checkboxClass={classes.checkbox}
                label={<Typography variant="body2">Use Business Address</Typography>}
              />
            </Grid>
            <Grid item style={{ marginBottom: 32 }}>
              {values.useBusinessAddress ? (
                <AddressAddAndDisplay
                  address={values.address}
                  context="fulfillment"
                  onSuccess={address => setAddress(address)}
                />
              ) : (
                <BusinessAddressInput removeBusinessInfo />
              )}
            </Grid>
            <Grid item>
              <TextAreaInput
                label={
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="subtitle2">Pickup Notes (Optional)</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        Let your customers know anything that will help them pick up their items.
                      </Typography>
                    </Grid>
                  </Grid>
                }
                name="afterPurchaseDetails"
                rowCount={5}
              />
            </Grid>
          </Grid>
        </CollapsableCard>
      </Grid>
    </Grid>
  );
};

export default PickupDetails;
