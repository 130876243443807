import React, { MouseEvent, ReactElement, useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Grid, Theme, Tooltip, useMediaQuery, useTheme } from '@material-ui/core';
import { GridCellParams, GridColumns } from '@material-ui/data-grid';
import { CheckCircleOutline, DeleteOutline } from '@material-ui/icons';
import {
  Banner,
  Button,
  Chip,
  DomainVerificationOutlinedIcon,
  DropDownOption,
  Dropdown,
  PendingOutlinedIcon,
  Typography, FeatureGate, useFeatures,
} from '@castiron/components';
import { useTracking } from '@castiron/utils';
import { getService } from '../../../firebase';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { openModal } from '../../../store/reducers/modalConductor';
import { getShopAction } from '../../../store/reducers/shops';
import DataGrid from '../../DataGrid/DataGrid';
import { LayoutPageProps } from '../../Layout';
import Spinner from '../../Spinner';
import moment from 'moment';
import { useConfig } from '@castiron/castiron-firebase';
import FreeCustomDomainBanner from './FreeCustomDomainBanner';
import { existingAnnualUser } from './StartDomainConnectModal';

export interface DomainRow {
  id: number;
  domain: string;
  primary: boolean;
  status: boolean;
  canDelete: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    marginTop: 24,
    [theme.breakpoints.down('sm')]: {
      margin: '24px 16px 0px 16px',
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
    },
  },
  bannerCta: {
    minWidth: 'fit-content',
    '&:hover': {
      backgroundColor: 'inherit',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 8,
      padding: 0,
    },
  },
  bannerYellow: {
    background: '#FFFDE7',
  },
  freeCustomDomainBanner: {
    margin: '24px 16px 0px 16px',
    [theme.breakpoints.up('sm')]: {
      margin: '16px',
    },
    [theme.breakpoints.up('md')]: {
      margin: '0px 0px 16px 0px',
    },
  },
  iconContainer: {
    height: 40,
    width: 40,
    background: theme.branding.blue.light,
    borderRadius: 12,
    marginRight: 24,
    [theme.breakpoints.down('xs')]: {
      marginRight: 10,
      fontSize: 14,
    },
  },
  headerButton: {
    minWidth: 190,
  },
  mobileContainer: {
    margin: '16px 16px 0px 16px',
  },
  mobileItem: {
    border: `1px solid ${theme.branding.gray[300]}`,
    borderRadius: 12,
    padding: 8,
    width: '100%',
  },
  mobileFontSize: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  primaryText: {
    color: theme.branding.blue.primary,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  statusChip: {
    width: 14,
    marginRight: 5,
  },
}));

const DomainsLandingPage: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setBackLocation, setPageTitle, setPageIsProFeature, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  /* "Add a custom domain" button text was overflowing for the XS breakpoint. This adjusts that */
  const isMdMobile = useMediaQuery(theme.breakpoints.down(1023));
  const now = moment();
  const ffconfig = useConfig();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [status, setStatus] = useState<DomainStatus[]>([]);
  const [isDomainPending, setIsDomainPending] = useState<boolean>(false);
  const [isDomainPendingIssue, setIsDomainPendingIssue] = useState<boolean>(false);
  const [domain, setDomain] = useState<string>('');

  const hasFreeDomainPerm = useFeatures().includes('shop.freeDomain');

  interface DomainStatus {
    domain: string;
    dnsReady: boolean;
    certReady: boolean;
  }

  const { account, shop, isShopLoading, userState } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
    isShopLoading: state.shops.loading,
    userState: state.shops.userState,
  }));

  const isFreeCustomDomainsEnabled = ffconfig?.featureFlag('feature_free_custom_domains', shop) && hasFreeDomainPerm;
  const isExistingAnnualUser = existingAnnualUser(account?.subscription);
  const existingFreeCustomDomain = shop?.config?.freeCustomDomain;

  const isDomainFailed = shop?.config?.domainStatus === 'failed';

  const domainStatusService = getService('shops', 'domainstatus', { version: 2 });

  const getShop = async () => {
    await dispatch(getShopAction(shop.id));
  };

  const refreshDomains = async () => {
    setShowSpinner(true);
    const resp = await domainStatusService({});
    const status = resp.status;
    const pendingDomain = status?.find(d => !(d?.dnsReady && d?.certReady))?.domain || '';
    setStatus(status);
    setIsDomainPending(!!pendingDomain);
    setIsDomainPendingIssue((now.diff(moment.unix(shop?.config?.domainRequestedAt), 'hours') > 48) && !!pendingDomain);
    setDomain(pendingDomain);
    setShowSpinner(false);
  };

  const OverrideTooltip = withStyles({
    tooltip: {
      padding: 16,
      background: theme.branding.gray[800],
      borderRadius: 12,
      fontSize: 14,
      color: theme.branding.ghostWhite,
      width: 212,
    },
    arrow: {
      color: theme.branding.gray[800],
    },
  })(Tooltip);

  const domainButton = isMdMobile => (
    <Button
      variant="contained"
      fullWidth={isMdMobile}
      onClick={() => {
        trackEvent('Add Domain Clicked');
        openDomainModal();
      }}
      className={classes.headerButton}
    >
      Add a Custom Domain
    </Button>
  );

  const disabledDomainButton = isMdMobile => (
    <OverrideTooltip
      title="You've reached your domain limit. Please remove an existing domain to enable adding a new one."
      arrow
    >
      <span>
        <Button
          disabled={true}
          fullWidth={isMdMobile}
          variant="contained"
          onClick={() => {
            openDomainModal();
          }}
          className={classes.headerButton}
        >
          Add a Custom Domain
        </Button>
      </span>
    </OverrideTooltip>
  );

  useEffect(() => {
    setPageTitle('Domains');
    setPageIsProFeature(true);
    setBackLocation(true);
    window.scrollTo(0, 0);
    getShop();

    return () => {
      setPageTitle('');
      setPageIsProFeature(false);
      setBackLocation(false);
    };
  }, []);

  const openDomainModal = () => {
    dispatch(
      openModal({
        modalType: (isFreeCustomDomainsEnabled && !isExistingAnnualUser && userState !== 'legacyNewSubscriber') ? 'START_DOMAIN_CONNECT_MODAL': 'ADD_DOMAIN_MODAL',
        modalProps: {
          show: true,
        },
      }),
    );
  };

  useEffect(() => {
    const domainDisabled = shop?.config?.domains && shop?.config?.domains?.length !== 0;
    const button = domainDisabled ? disabledDomainButton(isMdMobile) : domainButton(isMdMobile);
    const headerCTAs = isMdMobile ? [] : [button];
    setHeaderCTAs(headerCTAs);

    isMdMobile ? setFooterCTAs([button]) : setFooterCTAs([]);

    refreshDomains();
  }, [isMdMobile, shop]);

  // Not sure what this needs to be yet
  const onCellClick = (params: GridCellParams): void => { };

  const DomainInfo = (domain: string, primary: boolean, isMobile: boolean) => (
    <Grid container justify="flex-start" alignItems="center">
      <Grid container item className={classes.iconContainer} alignContent="center" justify="center">
        <DomainVerificationOutlinedIcon />
      </Grid>
      <Grid item wrap="nowrap" alignContent="center" alignItems="center" justify="flex-start">
        <Grid item sm={12}>
          <Typography noWrap className={classes.mobileFontSize} variant="body1">
            {domain}
          </Typography>
        </Grid>
        {primary && (
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.primaryText}>
              Primary Domain
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  // Need logic for other statuses
  const Status = status =>
    status ? (
      <Chip colorScheme="success">
        <Grid container justify="center">
          <CheckCircleOutline className={classes.statusChip} />
          <span>Connected</span>
        </Grid>
      </Chip>
    ) : (
      <Chip colorScheme="warning">
        <Grid container justify="center">
          <PendingOutlinedIcon className={classes.statusChip} />
          <span>Processing</span>
        </Grid>
      </Chip>
    );

  const rowActions = (row: DomainRow) => {
    const menuOptions = [
      row.canDelete && {
        label: 'Delete',
        icon: <DeleteOutline />,
        color: 'error',
        onClick: (event: MouseEvent<HTMLButtonElement>) => {
          dispatch(
            openModal({
              modalType: 'DOMAIN_MANAGEMENT_MODAL',
              modalProps: {
                show: true,
                domain: row.domain,
                type: row.domain === existingFreeCustomDomain ? 'deleteFreeCustomDomain' : 'delete',
              },
            }),
          );
        },
      },
    ].filter(x => !!x) as DropDownOption[];
    return shop?.config?.domains?.length > 0 && <Dropdown variant="ellipsis" options={menuOptions} />;
  };

  const columns: GridColumns = [
    {
      field: 'domain',
      flex: isMobile ? 0 : 0.65,
      renderCell: (params: GridCellParams): ReactElement => {
        return DomainInfo(params.row.domain, params.row.primary, isMobile);
      },
    },
    {
      field: 'status',
      flex: 0.25,
      renderCell: (params: GridCellParams): ReactElement => {
        return Status(params.row.status);
      },
    },
    {
      field: 'actions',
      /* we don't want a header name to show here */
      headerName: ' ',
      flex: 0.1,
      renderCell: (params: GridCellParams): ReactElement => rowActions(params.row as DomainRow),
    },
  ];

  const intialDomain: DomainRow = {
    id: 1,
    domain: `shop.castiron.me/${shop?.websiteUrl}`,
    primary: shop?.config?.domains ? false : true,
    status: true,
    canDelete: false,
  };

  const rows: DomainRow[] = [];

  rows.push(intialDomain);
  //need to add logic to determine primary
  status
    ?.filter(value => !value?.domain?.startsWith('www'))
    ?.map((status, index) => {
      rows.push({
        id: index + 2,
        domain: status?.domain,
        primary: true,
        status: status?.dnsReady && status?.certReady,
        canDelete: true,
      });
    });

  const getBanner = () => {
    if (!(isDomainFailed || isDomainPendingIssue || isDomainPending)) return;
    return (
      <Banner className={`${classes.banner} ${isDomainFailed || isDomainPendingIssue ? '' : classes.bannerYellow}`} variant={isDomainFailed || isDomainPendingIssue ? "error" : "info-white"}>
        <Grid container alignItems="center" justify="space-between" wrap={isMobile ? "wrap" : "nowrap"}>
        {isDomainFailed
          ?  <Typography variant="body2"><span style={{color: theme.branding.blue.primary}}>{domain}</span> is no longer connected with Castiron. Your customer can still visit your shop by visiting <span style={{color: theme.branding.blue.primary}}>{domain}</span>. Please reconnect your domain or delete it if you prefer to no longer have it associated with your shop.</Typography>
          : isDomainPendingIssue
            ?  <Typography variant="body2">We’re having issues connecting to your domain. Please delete the domain and try again, or visit our help center to troubleshoot.</Typography>
            :  <Typography variant="body2"><b>Please note</b>: It can take up to 48 hours to verify your domain. If it has been more than 48 hours, please delete the domain and try again, or visit our help center to troubleshoot.</Typography>
        }
        <Button
          className={classes.bannerCta}
          onClick={() => {
            if (isDomainFailed) {
              openDomainModal();
            } else {
              window.open('https://castiron.helpscoutdocs.com/article/111-custom-domains-with-castiron', '_blank');
            }
          }}
          variant="text"
        >
          {isDomainFailed ? "Reconnect" : "Visit Help Center"}
        </Button>
        </Grid>
      </Banner>
    );
  };

  return (
    <Grid container>
      <Spinner show={isShopLoading || showSpinner} />
      <FreeCustomDomainBanner className={classes.freeCustomDomainBanner} context="domains"/>
      {isMobile ? (
        <Grid container>
          {rows.map(row => (
            <Grid item xs={12} className={classes.mobileContainer}>
              <Box className={classes.mobileItem}>
                <Grid container>
                  <Grid item xs={11}>
                    <Grid container justify="flex-start" alignItems="center">
                      <Grid item xs={12}>
                        {DomainInfo(row.domain, row.primary, isMobile)}
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 15, marginLeft: 45 }}>
                        {Status(row.status)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                    <Box style={{ marginTop: '6px' }}>{rowActions(row)}</Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <DataGrid
          onCellClick={onCellClick}
          columns={columns}
          rows={rows}
          sortingOrder={['asc', 'desc', null]}
          rowHeight={72}
          pageSize={20}
          hideFooter={true}
        />
      )}
      {domain && getBanner()}
    </Grid>
  );
};

export default DomainsLandingPage;
