import { SelectedCustomerProps } from '@castiron/domain';
import { Button } from '../';
import React, {ReactNode} from "react";


type Props = {
  isSubmitting: boolean;
  sendAction: () => void;
  selectedCustomers?: SelectedCustomerProps[];
  children?: ReactNode;
};


const SendButton: React.FC<Props> = (props: Props) => {
  const { selectedCustomers, sendAction, isSubmitting } = props;

  return (
    <Button
      onClick={() => sendAction()}
      variant='contained'
      color='primary'
      disabled={isSubmitting}
    >
      {selectedCustomers?.length > 1 ? `Send ${selectedCustomers?.length} emails` : 'Send email'}
    </Button>
  );
};

export default SendButton;
