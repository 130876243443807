import * as React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const DomainVerificationOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <svg width="32" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_436_17161)">
        <path
          d="M22.1329 14.5066L20.2395 12.6133L14.5862 18.2799L11.7595 15.4399L9.86621 17.3333L14.5862 22.0533L22.1329 14.5066Z"
          fill="currentColor"
        />
        <path
          d="M25.3333 5.33325H6.66667C5.18667 5.33325 4 6.53325 4 7.99992V23.9999C4 25.4666 5.18667 26.6666 6.66667 26.6666H25.3333C26.8 26.6666 28 25.4666 28 23.9999V7.99992C28 6.53325 26.8133 5.33325 25.3333 5.33325ZM25.3333 23.9999H6.66667V10.6666H25.3333V23.9999Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_436_17161">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
