import firebase from 'firebase/compat/app';
import _firestore from '@google-cloud/firestore';
import { ShopRelatedDocument, ShopRelatedRepository } from '../shop';
import { Asset } from '../asset/model';
import { FieldFunctions } from '../base/repository';
import { TimePeriod } from '../shop/model';
import { getProductStatus } from '../product/utils';
import { BaseProduct } from '../product';
import { FulfillmentOption } from '../shop';

export type PresaleStatus = 'active' | 'inactive' | 'scheduled' | 'deleted' | 'hidden' | 'archived';

export interface Metrics {
  numSold: number;
  totalRevenue: number;
}

export interface BasePresale extends ShopRelatedDocument<BasePresale> {
  title: string;
  description: string;
  status: PresaleStatus;
  fulfillmentIds: string[];
  productIds: string[];
  images?: Asset[];
  schedule?: TimePeriod;
  presaleTemplateId?: string;
  metrics?: Metrics;
  isFeatured?: boolean;
  showLowInventoryCount?: boolean;
    // Not saved in document, but returned by getpresalev2
    fulfillments?: FulfillmentOption[];
    products?: BaseProduct[];
}

export interface Presale extends BasePresale {}

export class PresaleRepository extends ShopRelatedRepository<BasePresale> {
  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, fieldFunctions?: FieldFunctions) {
    super(firestore, 'presales', fieldFunctions);
  }

  public async findActive(shopId: string, timeZone: string): Promise<BasePresale[]> {
    return this.find({
      where: [this.whereShopIs(shopId)],
      orderBy: [{ field: 'title', direction: 'asc' }],
    }).then(presales => presales.filter(p => getProductStatus(p, timeZone) === 'active'));
  }

  public async findAll(shopId: string): Promise<BasePresale[]> {
    return this.find({
      where: [this.whereShopIs(shopId)],
      orderBy: [{ field: 'title', direction: 'asc' }],
    });
  }
}
