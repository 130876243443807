import React, {ReactNode} from 'react';
import { createStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useField, useFormikContext } from 'formik';
import moment from 'moment';
const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      width: 200,
    },
  }),
);

type Props = {
  name: string;
  defaultValue?: string;
  children?: ReactNode;
};

const DatePicker: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  return (
    <TextField
      type="datetime-local"
      onChange={(e): void => {
        const date = moment(e.target.value).unix();
        console.log(date);
        setFieldValue(field.name, date);
      }}
      className={classes.textField}
      {...props}
    />
  );
};

export default DatePicker;
