import * as React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const ProcessingIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 56 56" {...props}>
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="54" height="54" rx="27" stroke="#005FFF" stroke-width="2"/>
      <circle cx="14" cy="28" r="4" fill="#005FFF"/>
      <circle cx="28" cy="28" r="4" fill="#005FFF"/>
      <circle cx="42" cy="28" r="4" fill="#005FFF"/>
    </svg>
  </SvgIcon>
);


