import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks';
import { getService } from '../../firebase';
import { useConfig } from '@castiron/castiron-firebase';

const PRODUCT_ID = process.env.REACT_APP_CELLO_PRODUCT_ID;

declare global {
  interface Window {
    cello: {
      cmd: any[];
    };
  }
}

const getTokenService = getService('subscriptions', 'createcellotoken');

const CelloReferralConfiguration: React.FC = props => {
  const { children } = props;

  const { shop, fromOnboarding } = useAppSelector(state => ({
    shop: state.shops.shop,
    fromOnboarding: state.shops.fromOnboarding,
  }));

  const [celloBooted, setCelloBooted] = useState<boolean>(false);

  const featureFlagConfig = useConfig();

  useEffect(() => {
    const celloFeature = featureFlagConfig?.featureFlag('feature_cello_referrals', shop);
    if (shop && celloFeature && !celloBooted && !window?.location?.pathname.includes('signup')) {
      getTokenService({}).then(resp => {
        const { token } = resp;

        window.cello = window.cello || { cmd: [] };

        window.cello.cmd.push(function(cello) {
          console.debug('Booting Cello', {
            productId: PRODUCT_ID,
            token: token,
            showOnBoot: true,
          });
          const resp = cello.boot({
            productId: PRODUCT_ID,
            token: token,
            showOnBoot: true,
            customLauncherSelector: '.celloLauncher',
          });
          console.debug('Cello Boot Result', resp);
        });

        setCelloBooted(true);
      });
    }
  }, [shop, celloBooted, featureFlagConfig, fromOnboarding]);

  return <>{children}</>;
};

export default CelloReferralConfiguration;
