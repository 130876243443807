import React, { ReactNode } from 'react';
import { Box, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@castiron/components';
import { Button, HeaderText, MultipleSelect, PageHeader } from '@castiron/components';
import SearchInput from '../SearchInput';
import clsx from 'clsx';

type Props = {
  isMobile?: boolean;
  onFilterChange?: (event: React.MouseEvent<HTMLElement>, value: string[]) => void;
  onAddClick?: () => void;
  filters?: any;
  filterOptions?: any;
  search?: boolean;
  content?: {
    header?: string;
    smallHeader?: string;
    subheader?: string;
    button?: string;
  };
  showImport?: boolean;
  onSearchInputChange?: (e) => void;
  searchInputValue?: string;
  extraFields?: ReactNode;
  customButton?: ReactNode;
  contactButton?: ReactNode;
  placeholderPrefix?: string;
  className?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  bodyText: {
    color: theme.palette.text.secondary,
    flexShrink: 2,
  },
  subheader: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  actionOnly: {
    justifyContent: 'end',
  },
  actionBox: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    '& .MuiButton-root': {
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      '& .MuiButton-fullWidth': {
        marginLeft: 0,
      },
    },
    '& button + button': {
      marginLeft: 16,
    },
  },
  searchIcon: {
    width: 'auto',
    height: 48,
  },
  buttonContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '8px',
    },
  },
}));

const ListHeader: React.FC<Props> = (props: Props) => {
  const {
    isMobile,
    search,
    extraFields,
    onFilterChange,
    showImport,
    onAddClick,
    filters,
    filterOptions,
    content,
    onSearchInputChange,
    searchInputValue,
    customButton,
    contactButton,
    placeholderPrefix,
    className,
  } = props;
  const classes = useStyles();

  const actionOnly = !content?.header && !content?.smallHeader;

  return (
    <div className={clsx([className])}>
      {content?.subheader ? (
        <>
          {content?.header && <PageHeader>{content?.header}</PageHeader>}
          <Box className={classes.subheader}>
            {content?.subheader && (
              <Typography className={classes.bodyText} variant="body1">
                {content?.subheader}
              </Typography>
            )}
            <Box className={classes.actionBox}>
              {!isMobile ? (
                <>
                  {search && <SearchInput onChange={onSearchInputChange} />}
                  {filters && (
                    <MultipleSelect
                      value={filters}
                      onChange={onFilterChange}
                      options={filterOptions}
                    />
                  )}
                  {extraFields}
                  {showImport}
                </>
              ) : (
                <>
                  {filters && (
                    <MultipleSelect
                      value={filters}
                      onChange={onFilterChange}
                      options={filterOptions}
                    />
                  )}
                </>
              )}
              {content?.button && (
                <Button onClick={onAddClick} fullWidth={isMobile} variant="contained">
                  {content?.button}
                </Button>
              )}
              {customButton && customButton}
              {contactButton && contactButton}
            </Box>
          </Box>
        </>
      ) : (
        <Box className={clsx([classes.subheader, actionOnly ? classes.actionOnly : undefined])}>
          {content?.header && <PageHeader>{content?.header}</PageHeader>}
          {content?.smallHeader && (
            <HeaderText presetFont="heading--xs" fontSize={18} lineHeight={24} fontWeight={600}>
              {content?.smallHeader}
            </HeaderText>
          )}
          <Box className={classes.actionBox}>
            {!isMobile ? (
              <>
                {search && <SearchInput onChange={onSearchInputChange} />}
                {filters && (
                  <MultipleSelect
                    value={filters}
                    onChange={onFilterChange}
                    options={filterOptions}
                    placeholderPrefix={placeholderPrefix}
                  />
                )}
                {extraFields}
                {showImport}
              </>
            ) : (
              <>
                {filters && (
                  <MultipleSelect
                    value={filters}
                    onChange={onFilterChange}
                    options={filterOptions}
                    placeholderPrefix={placeholderPrefix}
                    fullWidth={!!content?.button || !!customButton || !!contactButton}
                  />
                )}
              </>
            )}
            {content?.button && (
              <Box className={classes.buttonContainer}>
                <Button onClick={onAddClick} fullWidth={isMobile} variant="contained">
                  {content?.button}
                </Button>
              </Box>
            )}
            {customButton && <Box className={classes.buttonContainer}>{customButton}</Box>}
            {contactButton && <Box className={classes.buttonContainer}>{contactButton}</Box>}
          </Box>
        </Box>
      )}
    </div>
  );
};

export default ListHeader;
