import React, { ReactNode, useCallback, useRef, useState, useEffect } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { DialogContent, DialogTitle, Grid, IconButton, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import * as yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { BackButton, Typography } from '@castiron/components';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { updateProductAction, deleteProductAction, getProductsAction } from '../../../store/reducers/products';
import { closeModal } from '../../../store/reducers/modalConductor';
import ModalWrapper from '../../RootModal/ModalWrapper';
import Duplicate from './Duplicate';
import ProductTypeSelection from './ProductTypeSelection';
import StartingPoint from './StartingPoint';
import {
  Asset,
  BaseProduct,
  ProductPageContext,
  ProductType,
  occasions,
  holidays,
  specialToTag,
} from '@castiron/domain';
import NameAndPrice from './NameAndPrice';
import PhotoSection from '../EditProduct/PhotosSection';
import TagProduct from './TagProduct';

export interface Props {
  show: boolean;
  context?: ProductPageContext;
  fromChecklist?: boolean;
}

export type Step = 'start' | 'duplicate' | 'productType' | 'nameAndPrice' | 'tagProduct' | 'image';

export interface GeneratedDescription {
  id: string;
  description: string;
}

export interface ProductModalProps {
  context?: ProductPageContext;
  productType?: ProductType;
  fromChecklist?: boolean;
  product?: BaseProduct;
  generatedDescription?: GeneratedDescription;
  setTitle?: (title: string) => void;
  setStep?: (step: Step) => void;
  setFooter?: (node: ReactNode) => void;
  setProduct?: (product: BaseProduct) => void;
  setStepNumber?: (stepNumber: number) => void;
}

interface StyleProps {
  footer?: ReactNode;
}

interface FormValues {
  title: string;
  price: number;
  generateDesc: boolean;
  description?: string;
  generatedId?: string;
  holidays: string[];
  occasions: string[];
  images: Asset[];
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  closeIcon: {
    height: '24px',
    width: '24px',
  },
  content: {
    padding: '24px',
    [theme.breakpoints.up(510)]: {
      width: '450px',
      height: props => (props.footer ? '440px' : '520px'),
    },
  },
  header: {
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
    height: '80px',
  },
  headerNoTitle: {
    height: '0px',
  },
  step: {
    color: theme.branding.v2.blue[500],
  },
}));

const AddProductModal: React.FC<Props> = (props: Props) => {
  const { show, context, fromChecklist } = props;
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const history = useHistory();

  /* modal is 450, but enforces padding in view port, so acts funny a bit larger */
  const isMobile = useMediaQuery(theme.breakpoints.down(510));

  const [step, setStep] = useState<Step>(fromChecklist ? 'productType' : 'start');
  const [stepNumber, setStepNumber] = useState<number>(0);
  const [title, setTitle] = useState('');
  const [productType, setProductType] = useState<ProductType>(null);
  const [footer, setFooter] = useState<ReactNode>(null);
  const [product, setProduct] = useState<BaseProduct>(null);
  const [createdProduct, setCreatedProduct] = useState<boolean>(false);

  const newProductLocation = !fromChecklist ? context : productType === 'custom' ? 'order-forms' : productType === 'standard' ? 'products' : 'events';

  const classes = useStyles({ footer });

  const { products } = useAppSelector(state => ({
    products: state.products.products,
  }));

  useEffect(() => {
    if (context === 'order-forms') {
      setProductType('custom');
    } else if (context === 'products') {
      setProductType('standard');
    } else if (context === 'events') {
      setProductType('event');
    } else setProductType(null);
  }, [context]);

  const handleClose = (): void => {
    if (product && !createdProduct) {
      dispatch(deleteProductAction(product?.id));
    }
    dispatch(closeModal());
  };

  const backClick = useCallback(() => {
    /* weird use case that needs to be handled, please remove this when more options exist */
    if (step === 'nameAndPrice' && products.length === 0) {
      setStep('start');
    } else if (fromChecklist) {
      switch (step) {
        case 'start':
          setStep('productType');
          break;
        default:
          setStep('start');
      }
    } else {
      switch (step) {
        case 'nameAndPrice':
          setStep(!!context ? 'start' : 'productType');
          !context && setProductType(null);
          break;
        case 'tagProduct':
          setStep('nameAndPrice');
          break;
        case 'image':
          setStep('tagProduct');
          break;
        case 'duplicate':
        case 'productType':
        default:
          setStep('start');
      }
    }
  }, [step]);

  const productModalProps = {
    context,
    productType,
    fromChecklist,
    product,
    setTitle,
    setStep,
    setFooter,
    setProduct,
    setStepNumber,
  };

  const findInitialTags = events => {
    const filteredEvents = events.filter(e => product.eventTags.includes(e.value));
    return filteredEvents.map(e => e.display);
  };

  const holidaysTags = holidays.map(holiday => ({
    display: holiday,
    value: specialToTag(holiday),
  }));

  const occasionsTags = occasions.map(occasion => ({
    display: occasion,
    value: specialToTag(occasion),
  }));

  const schema = yup.object({
    title: yup.string().required('Please include a title'),
    price: yup
      .number()
      .moreThan(0, 'Please set a price')
      .required('Please include a price'),
    holidays: yup
      .array()
      .of(yup.string())
      .nullable(),
    ocassions: yup
      .array()
      .of(yup.string())
      .nullable(),
    images: yup.array().of(yup.object()),
  });

  const initialState = {
    title: product?.title ? product?.title : '',
    price: 0.0,
    generateDesc: product?.description ? true : false,
    holidays: !_.isEmpty(product?.eventTags) ? findInitialTags(holidaysTags) : [],
    occasions: !_.isEmpty(product?.eventTags) ? findInitialTags(occasionsTags) : [],
    images: product?.images || [],
  };
  const formikRef = useRef<FormikProps<FormValues>>();

  const submit = async (values: FormValues) => {
    setCreatedProduct(true);
    const { title, description, generatedId, holidays, occasions } = values;
    const hTags = holidays.map(h => specialToTag(h));
    const oTags = occasions.map(o => specialToTag(o));
    const cleanEventTags = _.uniq([...hTags, ...oTags]);
    const productToEdit = {
      ...product,
      title,
      description,
      eventTags: cleanEventTags,
    };

    await dispatch(
      updateProductAction({
        product: productToEdit,
        deleteSchedule: null,
      }),
    );
    await history.push(`${newProductLocation}/edit/${product.id}`, {
      fromCreate: true,
      generatedId,
      generatedDescription: description,
    });
    dispatch(closeModal());
  };

  return (
    <ModalWrapper fullScreen={isMobile} size="sm" show={show} onClose={handleClose}>
      <DialogTitle className={title ? classes.header : classes.headerNoTitle}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid container item alignItems="center">
                <Typography variant="h3">{title}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton>
              <Close className={classes.closeIcon} onClick={handleClose} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Formik onSubmit={submit} validationSchema={schema} initialValues={initialState} innerRef={formikRef}>
          <>
            {stepNumber !== 0 && (
              <Grid container spacing={2}>
                <Grid item>
                  <Typography variant="body2" className={classes.step}>
                    STEP {stepNumber}/3
                  </Typography>
                </Grid>
              </Grid>
            )}
            {step === 'start' && <StartingPoint {...productModalProps} />}
            {step === 'productType' && <ProductTypeSelection {...productModalProps} setProductType={setProductType} />}
            {step === 'duplicate' && <Duplicate {...productModalProps} />}
            {step === 'nameAndPrice' && <NameAndPrice {...productModalProps} />}
            {step === 'tagProduct' && <TagProduct {...productModalProps} />}
            {step === 'image' && <PhotoSection fromProductModal={true} {...productModalProps} />}
          </>
        </Formik>
      </DialogContent>
      {footer}
    </ModalWrapper>
  );
};

export default AddProductModal;
