import React, {ReactNode} from 'react';
import { TypographyVariant } from '@material-ui/core';
import Dinero from 'dinero.js';
import Typography from '../Typography';

type Props = {
  amount: number;
  textClass?: any;
  textColor?: string;
  variant?: TypographyVariant;
  children?: ReactNode;
};

const Price: React.FC<Props> = (props: Props) => {
  const { amount, textClass, textColor, variant } = props;

  return (
    <Typography variant={variant || 'subtitle1'} className={textClass && textClass} component="div" style={textColor ? { color: textColor } : {}}>
      {amount ? Dinero({ amount }).toFormat('$0.00') : '-'}
    </Typography>
  );
};

export default Price;
