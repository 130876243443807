import React from "react";
import { Switch } from "@material-ui/core";
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useFormikContext } from "formik";
import _ from 'lodash';
import InputWrapper, { BaseInputProps } from "../InputWrapper";

interface Props extends BaseInputProps {
  name: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '38px',
    height: '21px',
    padding: 0,
    marginRight: '8px',
  },
  switchBase: {
    padding: '1px',
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.branding.v2.gray[200],
      '& + $track': {
        backgroundColor: theme.branding.v2.blue[500],
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: theme.branding.blue.primary,
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: '18px',
    height: '18px',
  },
  track: {
    borderRadius: '11px',
    backgroundColor: theme.branding.v2.gray[200],
    opacity: 1,
  },
  checked: {},
  focusVisible: {},
}));

const SwitchInput: React.FC<Props> = (props: Props) => {
  const { name } = props;
  const classes = useStyles();

  const { values, handleChange } = useFormikContext();

  return (
    <InputWrapper variant="label-right" {...props}>
      <Switch
        name={name}
        checked={!!_.get(values, name)}
        onChange={handleChange}
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
      />
    </InputWrapper>
  );
};

export default SwitchInput;