import moment from 'moment';
import { FulfillmentOption, Shop } from '.';
import _ from 'lodash';

export const getFulfillmentDateInfo = (fulfillment: FulfillmentOption, maxDatesShown: number) => {
  const now = moment().unix();
  const upcomingDates = _.sortBy(
    fulfillment.schedule?.dates?.filter(tp => tp?.endTime > now),
    tp => tp.startTime,
  );
  let fulfillmentDateInfo;
  if (fulfillment.schedule?.type === 'fixed' && fulfillment.schedule?.dates?.some(tp => tp.repeatWeekly)) {
    fulfillmentDateInfo = 'Weekly';
  } else if (fulfillment.schedule?.type === 'fixed' && upcomingDates.length > 0) {
    const dateFormat = 'M/D/YYYY';
    const datesShown = upcomingDates.slice(0, maxDatesShown);
    const datesShownText = datesShown.map(date => moment.unix(date.startTime).format(dateFormat)).join(', ');
    fulfillmentDateInfo =
      datesShown.length === upcomingDates.length
        ? datesShownText
        : `${datesShownText}, and ${upcomingDates.length - datesShown.length} more`;
  } else if (fulfillment.type !== 'shipping') {
    fulfillmentDateInfo = 'Flexible';
  }
  return fulfillmentDateInfo;
};

export const getDaysSinceShopCreation = (shopCreationDateUnix: number) => {
  const today = moment();
  const shopCreationDate = moment.unix(shopCreationDateUnix);
  return today.diff(shopCreationDate, 'days');
};

export const shopToGmc = (shop: Shop) => ({
  name: `${shop.businessName}`,
  websiteUrl: `https://shopcastiron.com/shop/${shop.websiteUrl}`,
  sellerId: `${shop.id}`,
  adultContent: false,
  businessInformation: {
    address: {
      streetAddress: shop?.physicalAddress?.addressLine1,
      locality: shop?.physicalAddress?.city || shop?.seoMetadata?.address?.addressLocality,
      region: shop?.physicalAddress?.region || shop?.seoMetadata?.address?.addressRegion,
      postalCode: shop?.physicalAddress?.postalCode || shop?.seoMetadata?.address?.postalCode,
      country: 'US',
    },
  },
});

export const shippingInfoToGMC = async (shop: Shop, merchantIdGMC: string, fulfillments: FulfillmentOption[]) => {
  const postalCode = shop?.physicalAddress?.postalCode || shop?.seoMetadata?.address?.postalCode;
  const isNational = shop?.tags?.includes('National');
  const activeShopFulfillments = fulfillments.filter(f => f.status === 'active') || [];
  const shippingPrice = Math.max(...(activeShopFulfillments?.map(f => f.fee || 0) || []), 0).toFixed(2);
  const shippingMinHandlingTime = Math.max(
    ...(activeShopFulfillments?.map(f =>
      f?.processingTime?.incrementType === 'week'
        ? f?.processingTime?.increments * 7
        : f?.processingTime?.incrementType === 'day'
        ? f?.processingTime?.increments
        : 0,
    ) || []),
    0,
  );

  const getPostalCodeRanges =
    shop?.serviceArea?.postalCodes?.map(pc => ({
      postalCodeRangeBegin: pc,
    })) || [];

  const postalCodeRange =
    getPostalCodeRanges?.length == 0
      ? [
          {
            name: `${shop.businessName} postalCodes`,
            country: 'US',
            postalCodeRanges: [
              {
                postalCodeRangeBegin: postalCode,
              },
            ],
          },
        ]
      : [
          {
            name: `${shop.businessName} postalCodes`,
            country: 'US',
            postalCodeRanges: getPostalCodeRanges,
          },
        ];

  const nationalShippingSettings = {
    accountId: merchantIdGMC,
    services: [
      {
        name: 'Standard shipping',
        active: true,
        currency: 'USD',
        deliveryCountry: 'US',
        deliveryTime: {
          minHandlingTimeInDays: shippingMinHandlingTime,
          maxHandlingTimeInDays: shippingMinHandlingTime + 2,
        },
        rateGroups: [
          {
            applicableShippingLabels: ['standard'],
            singleValue: {
              flatRate: {
                value: shippingPrice,
                currency: 'USD',
              },
            },
          },
        ],
      },
    ],
  };
  const shippingSettings = {
    accountId: merchantIdGMC,
    services: [
      {
        name: 'Local shipping',
        active: true,
        currency: 'USD',
        deliveryCountry: 'US',
        deliveryTime: {
          minHandlingTimeInDays: shippingMinHandlingTime,
          maxHandlingTimeInDays: shippingMinHandlingTime + 2,
        },
        rateGroups: [
          {
            applicableShippingLabels: ['local'],
            singleValue: {
              flatRate: {
                value: shippingPrice,
                currency: 'USD',
              },
            },
          },
        ],
      },
    ],
    postalCodeGroups: postalCode ? postalCodeRange : [],
  };

  return isNational ? nationalShippingSettings : shippingSettings;
};
