import * as React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const CouponOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 18 18" {...props}>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect opacity="0.01" width="18" height="18" rx="4" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9943 3.375H4.00565C3.24204 3.375 2.625 3.9947 2.625 4.75576V7.45664C2.625 7.76715 2.85858 8.02339 3.16048 8.06097L3.23729 8.06572C3.74685 8.06572 4.16172 8.48238 4.16172 9C4.16172 9.48527 3.79709 9.88181 3.33164 9.92948L3.23729 9.93428C2.89913 9.93428 2.625 10.207 2.625 10.5434V13.2442C2.625 14.0053 3.24204 14.625 4.00565 14.625H13.9943C14.758 14.625 15.375 14.0053 15.375 13.2442V10.9292C15.375 10.6187 15.1414 10.3625 14.8395 10.3249L14.7627 10.3201C14.2531 10.3201 13.8383 9.90346 13.8383 9.38584C13.8383 8.90057 14.2029 8.50403 14.6684 8.45636L14.7627 8.45156C15.1009 8.45156 15.375 8.17886 15.375 7.84248V4.75576C15.375 3.9947 14.758 3.375 13.9943 3.375ZM13.9944 4.59315L14.0353 4.59888C14.1008 4.61759 14.1504 4.68002 14.1504 4.75575V7.32181L14.0373 7.35899C13.2067 7.65707 12.6137 8.45257 12.6137 9.38584L12.6173 9.51221C12.6681 10.3904 13.2444 11.1282 14.0373 11.4127L14.1504 11.4492V13.2442C14.1504 13.3351 14.079 13.4068 13.9944 13.4068H4.00565L3.96469 13.4011C3.89917 13.3824 3.84958 13.32 3.84958 13.2442V11.063L3.96272 11.0268C4.79334 10.7288 5.3863 9.93326 5.3863 9L5.38266 8.87362C5.3319 7.99544 4.75559 7.25768 3.96272 6.97315L3.84958 6.9363V4.75575C3.84958 4.66487 3.92099 4.59315 4.00565 4.59315H13.9944ZM10.9209 6.46172C11.2591 6.46172 11.5332 6.73442 11.5332 7.0708C11.5332 7.38131 11.2996 7.63755 10.9977 7.67513L10.9209 7.67988H7.0791C6.74094 7.67988 6.46681 7.40718 6.46681 7.0708C6.46681 6.76029 6.70039 6.50405 7.00229 6.46647L7.0791 6.46172H10.9209ZM10.9209 10.3201C11.2591 10.3201 11.5332 10.5928 11.5332 10.9292C11.5332 11.2397 11.2996 11.4959 10.9977 11.5335L10.9209 11.5383H7.0791C6.74094 11.5383 6.46681 11.2656 6.46681 10.9292C6.46681 10.6187 6.70039 10.3625 7.00229 10.3249L7.0791 10.3201H10.9209Z"
        fill="#4CAF50"
      />
    </svg>
  </SvgIcon>
);
