import React, { ReactElement, useEffect, useState, useRef } from 'react';
import { useLocation, useParams } from 'react-router';
import { Grid, useMediaQuery, useTheme, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Banner, ButtonV2, ProductImage, SvgIcon, Typography } from '@castiron/components';
import { GenericLayoutOption } from '@castiron/domain';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';
import ActionsMenu from '../ActionsMenu';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicator from '@material-ui/icons/DragIndicator';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { shopRepository } from '../../domain';
import { openModal } from '../../store/reducers/modalConductor';
import { getShopAction } from '../../store/reducers/shops';
import { getProductsAction } from '../../store/reducers/products';
import { LayoutPageProps } from '../Layout';

interface Params {
  categoryName?: string;
}

const layoutSchema = yup.object().shape({
  name: yup.string(),
  position: yup.number(),
});

const schema = yup.array().of(layoutSchema);

const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    marginBottom: 15,
  },
  bannerColor: {
    color: theme.branding.blue.primary,
  },
  container: {
    padding: 16,
  },
  containerBorder: {
    padding: 16,
    borderTop: `1px solid ${theme.branding.v2.gray[200]}`,
  },
  dragDots: {
    '& svg > path': {
      fill: theme.branding.v2.gray[400],
    },
  },
  organizeCategories: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 12,
  },
  statusPillDecorator: {
    fontSize: 16,
    marginRight: 4,
    color: theme.branding.v2.green[500],
  },
  statusText: {
    color: theme.branding.v2.gray[500],
  },
}));

const OrganizeProducts: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobilexs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const formRef = useRef<FormikProps<any>>();
  const dispatch = useAppDispatch();
  const [submitted, setSubmitted] = useState(false);
  const { categoryName } = useParams<Params>();
  const location = useLocation();

  const { shop, products } = useAppSelector(state => ({
    shop: state.shops.shop,
    products: state.products.products,
  }));

  const isOrderForm = location.pathname.includes('order-forms');

  useEffect(() => {
    setPageTitle(categoryName.length > 27 ? `${categoryName.substring(0, 27)}...` : categoryName);
    setBackLocation(isOrderForm ? '/order-forms?tab=Categories' : '/products?tab=Categories');
    if (isMobile) {
      setFooterCTAs([
        <ButtonV2 variant="contained" fullWidth style={{ height: 46 }} onClick={submit}>
          Save
        </ButtonV2>,
      ]);
    } else {
      setFooterCTAs([
        <Grid container item xs={12} justify="flex-end" style={{ paddingRight: 76 }}>
          <ButtonV2 variant="contained" onClick={submit} style={{ height: 46, width: 160 }}>
            Save
          </ButtonV2>
        </Grid>,
      ]);
    }
    const getProducts = async () => {
      dispatch(getProductsAction(shop.id));
    };

    if (shop?.id) getProducts();
  }, [shop, isMobile]);

  const layout = isOrderForm
    ? shop?.customProductCategoryLayouts?.find(l => l.categoryName === categoryName)
    : shop?.productCategoryLayouts?.find(l => l.categoryName === categoryName);

  const filteredProducts = products.filter(
    p =>
      (categoryName === 'Other' ? p?.category?.name == null : p?.category?.name === categoryName) &&
      (isOrderForm ? p.type === 'custom' : p.type === 'standard'),
  );

  let structuredProducts = [];
  let position = 0;
  filteredProducts.map(p => {
    const existingOrder = layout?.layout?.find(o => o.name === p.title);
    let product = {
      name: p.title,
      id: p.id,
      image: p?.imageObj?.downloadUrl,
      status: p.status,
      position: existingOrder ? existingOrder.position : 999,
    };
    structuredProducts.push(product);
    position++;
  });

  // sort intially
  structuredProducts = _.sortBy(structuredProducts, p => p.position);

  if (!layout) {
    structuredProducts.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (a.name == 'OTHER') return 1;
      if (b.name == 'OTHER') return -1;
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    });
  }

  // a little function to help us with reordering the result
  function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  const onDragEnd = result => {
    setSubmitted(false);
    if (!result.destination) {
      return;
    }

    const sortedCategories = reorder<GenericLayoutOption>(
      structuredProducts,
      result.source.index,
      result.destination.index,
    );

    structuredProducts = sortedCategories;

    formRef.current.setFieldValue(
      'structuredProducts',
      sortedCategories.map((v, index) => ({
        ...v,
        position: index,
      })),
    );
  };

  const submit = async () => {
    const sanitizedProducts = formRef.current.values.structuredProducts.map(p => _.omit(p, ['status', 'image']));
    let updatedCategoryLayout = (isOrderForm ? shop?.customProductCategoryLayouts : shop?.productCategoryLayouts) || [];
    if (layout) {
      updatedCategoryLayout = updatedCategoryLayout.filter(l => l.categoryName !== categoryName);
      updatedCategoryLayout = [...updatedCategoryLayout, { categoryName: categoryName, layout: sanitizedProducts }];
    } else {
      updatedCategoryLayout = [...updatedCategoryLayout, { categoryName: categoryName, layout: sanitizedProducts }];
    }

    if (isOrderForm) {
      await shopRepository.updateProps(shop.id, {
        customProductCategoryLayouts: updatedCategoryLayout,
      });
    } else {
      await shopRepository.updateProps(shop.id, {
        productCategoryLayouts: updatedCategoryLayout,
      });
    }
    await dispatch(
      openModal({
        modalType: 'SIMPLE_ALERT',
        modalProps: {
          show: true,
          celebrate: true,
          content: (
            <>
              <Typography variant="h4">{`Products in ${categoryName} have been updated!`}</Typography>
            </>
          ),
        },
      }),
    );
    await dispatch(getShopAction(shop.id));
  };

  return (
    <Formik initialValues={{ structuredProducts }} validationSchema={schema} onSubmit={submit} innerRef={formRef}>
      {({ dirty, errors, setFieldValue, touched, values }): ReactElement => (
        <Grid container justify="center" alignItems="center" className={classes.container}>
          <Banner variant="info-blue" className={classes.banner}>
            <Typography className={classes.bannerColor}>
              This is the order your products will show in your {categoryName} category on your{' '}
              <b>{isOrderForm ? 'Custom Orders Page' : 'Shop Page'}</b>.
            </Typography>
          </Banner>
          <Grid item xs={12}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="organizeCategories" type="CATEGORY">
                {(provided, snapshot) => (
                  <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    direction="column"
                    wrap="nowrap"
                    ref={provided.innerRef}
                    className={classes.organizeCategories}
                  >
                    {values.structuredProducts.map((product, index) => (
                      <Draggable draggableId={product.name} index={index} key={product.name}>
                        {(provided, snapshot) => (
                          <Grid
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            container
                            item
                            key={product.name}
                            justify="space-between"
                            alignItems="center"
                            xs={11}
                          >
                            <Grid
                              container
                              justify="space-between"
                              alignItems="center"
                              className={index === 0 ? classes.container : classes.containerBorder}
                            >
                              <Grid item xs={1}>
                                <SvgIcon className={classes.dragDots}>
                                  <DragIndicator />
                                </SvgIcon>
                              </Grid>
                              <Grid item xs={11}>
                                <Grid container justify="space-between">
                                  <Grid item>
                                    <Grid container>
                                      <Grid item justify="flex-start" alignItems="center">
                                        <ProductImage
                                          height="64px"
                                          width="64px"
                                          src={product.image}
                                          alt={`${product.title} Image`}
                                        />
                                      </Grid>
                                      <Grid item justify="center" alignItems="center" style={{ marginLeft: 20 }}>
                                        <Grid container item justify="flex-start">
                                          <Typography variant="subtitle1">
                                            {isMobilexs
                                              ? product.name.length > 8
                                                ? `${product.name.substring(0, 8)}...`
                                                : product.name
                                              : product.name.length > 37
                                              ? `${product.name.substring(0, 37)}...`
                                              : product.name}
                                          </Typography>
                                        </Grid>
                                        <Grid>
                                          <Typography variant="caption">
                                            <span
                                              className={classes.statusPillDecorator}
                                              style={
                                                product.status !== 'active' ? { color: theme.branding.v2.red[500] } : {}
                                              }
                                            >
                                              •
                                            </span>
                                            {_.capitalize(product.status)}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item>
                                    <ActionsMenu
                                      type="organize-products"
                                      id={product.id}
                                      productCardContext={isOrderForm ? 'order-forms' : 'products'}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default OrganizeProducts;
