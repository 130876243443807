export const states = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'District Of Columbia', abbreviation: 'DC' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Puerto Rico', abbreviation: 'PR' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
];

//duplicated in domain/lib but wanted to have it here as well in case it ends up being used elsewhere
export const convertRegionName = (input: string, toAbbr?: boolean) => {
  let convertedName = states
    .filter(state => state.name === input || state.abbreviation === input)
    .map(state => (toAbbr ? state.abbreviation : state.name));

  return convertedName;
};

export const stateShippingList = {
  AL: ['local-shipping'],
  AK: [],
  AZ: ['local-shipping'],
  AR: ['local-shipping', 'nationwide-shipping'],
  CA: ['local-shipping'],
  CO: ['local-shipping'],
  CT: [],
  DE: ['local-shipping', 'nationwide-shipping'],
  DC: ['local-shipping'],
  FL: ['local-shipping', 'nationwide-shipping'],
  GA: ['local-shipping'],
  HI: [],
  ID: ['local-shipping', 'nationwide-shipping'],
  IL: ['local-shipping'],
  IN: ['local-shipping', 'nationwide-shipping'],
  IA: ['local-shipping', 'nationwide-shipping'],
  KS: ['local-shipping', 'nationwide-shipping'],
  KY: [],
  LA: ['local-shipping', 'nationwide-shipping'],
  ME: ['local-shipping', 'nationwide-shipping'],
  MD: ['local-shipping'],
  MA: [],
  MI: [],
  MN: [],
  MS: [],
  MO: ['local-shipping'],
  MT: [],
  NE: ['local-shipping', 'nationwide-shipping'],
  NV: [],
  NH: ['local-shipping', 'nationwide-shipping'],
  NJ: [],
  NM: ['local-shipping'],
  NY: ['local-shipping'],
  NC: ['local-shipping', 'nationwide-shipping'],
  ND: [],
  OH: ['local-shipping'],
  OK: ['local-shipping', 'nationwide-shipping'],
  OR: ['local-shipping', 'nationwide-shipping'],
  PA: ['local-shipping', 'nationwide-shipping'],
  //  PR: [],
  RI: ['local-shipping'],
  SC: ['local-shipping', 'nationwide-shipping'],
  SD: [],
  TN: ['local-shipping'],
  TX: ['local-shipping'],
  UT: ['local-shipping', 'nationwide-shipping'],
  VT: ['local-shipping'],
  VA: [],
  WA: [],
  WV: ['local-shipping', 'nationwide-shipping'],
  WI: ['local-shipping'],
  WY: [],
};
