import React, { ReactNode, useState } from 'react';
import { Grid, ButtonBase, Collapse } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Typography from '../Typography';
import clsx from 'clsx';

interface Props {
  defaultExpanded?: boolean;
  expanded?: boolean;
  title: ReactNode;
  className?: string;
  contentClassName?: string;
  headerClassName?: string;
  children?: ReactNode;
  supertitle?: string;
  cta?: ReactNode;
  handleExpand?: () => void;
  id?: string;
  noScroll?: boolean;
  noBorders?: boolean;
  removeInnerPadding?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  chevron: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  collapseBorder: {
    borderBottom: `solid ${theme.branding.gray[400]} 1px`,
  },
  container: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 12,
    backgroundColor: theme.branding.gray[100],
    marginBottom: 8,
    [theme.breakpoints.down('sm')]: {
      margin: '4px 0px',
    },
  },
  ctaContainer: {
    bottom: 0,
    zIndex: 5,
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    padding: '12px 24px',
  },
  header: {
    padding: '14px 24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  innerContent: {
    overflowY: 'scroll',
    padding: '24px',
    [theme.breakpoints.up('sm')]: {
      maxHeight: '450px',
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '316px',
      padding: '8px 24px',
    },
  },
  innerContentNoPadding: {
    overflowY: 'scroll',
    [theme.breakpoints.up('sm')]: {
      maxHeight: '450px',
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '316px',
    },
  },
  noBorder: {
    border: 'none',
  },
  noScroll: {
    overflowY: 'auto',
    maxHeight: 'none',
  },
  supertitle: {
    color: theme.branding.blue.primary,
    textAlign: 'left',
  },
  titles: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
  },
}));

const CollapsableCard: React.FC<Props> = (props: Props) => {
  const {
    defaultExpanded = false,
    expanded: controlledExpanded,
    title,
    children,
    className,
    supertitle,
    cta,
    handleExpand,
    id,
    noScroll,
    noBorders = false,
    contentClassName,
    headerClassName,
    removeInnerPadding,
  } = props;
  const classes = useStyles();

  /* local control overwritten by passed in arguments */
  const [localExpanded, setLocalExpanded] = useState(defaultExpanded || false);

  const defaultHandleExpand = () => setLocalExpanded(e => !e);

  const expanded = controlledExpanded || localExpanded;

  return (
    <Grid
      container
      className={clsx(classes.container, className, noBorders && classes.noBorder)}
      direction="column"
      id={id}
    >
      <Grid container item>
        <ButtonBase
          disableRipple
          onClick={handleExpand || defaultHandleExpand}
          className={clsx(
            classes.header,
            expanded && classes.collapseBorder,
            noBorders && classes.noBorder,
            headerClassName,
          )}
        >
          <Grid item xs={10} className={classes.titles}>
            <Typography variant="caption" className={classes.supertitle}>
              {supertitle}
            </Typography>
            <Grid style={{ textAlign: 'left' }}>
              {typeof title === 'string' ? <Typography variant="subtitle1">{title}</Typography> : title}
            </Grid>
          </Grid>
          <Grid item xs={2} className={classes.chevron}>
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Grid>
        </ButtonBase>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Grid item container direction="column">
          <Grid
            className={clsx(
              removeInnerPadding ? classes.innerContentNoPadding : classes.innerContent,
              noScroll && classes.noScroll,
              contentClassName,
            )}
          >
            {children}
          </Grid>
          {cta && <Grid className={classes.ctaContainer}>{cta}</Grid>}
        </Grid>
      </Collapse>
    </Grid>
  );
};

export default CollapsableCard;
