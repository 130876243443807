import { Address } from '@castiron/domain';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { getIn, useFormikContext } from 'formik';
import React, { useState } from 'react';
import Typography from '../../Typography';
import AddressInput from './StandardAddressInput';
import { BaseInputProps } from '../InputWrapper';
import TextInput from '../TextInput';

interface Props extends BaseInputProps {
  label?: string;
  name?: string;
  removeBusinessInfo?: boolean;
  customColor?: string;
}

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  addressLine1: {
    '& div': {
      margin: 0,
    },
  },
  addressLineTwoContainer: {
    height: 56,
    width: '100%',
  },
  addressLine2CTA: {
    color: props => props.customColor || theme.branding.v2.blue[500],
    '&:hover': {
      cursor: 'pointer',
    },
  },
  caption: {
    color: theme.branding.gray[700],
    fontWeight: 400,
  },
  input: {
    fontSize: 16,
    fontWeight: 400,
    padding: 12,
  },
  inputRoot: {
    height: 48,
    width: '100%',
    border: 'none',
    borderRadius: '12px',
  },
}));

const BusinessAddressInput: React.FC<Props> = (props: Props) => {
  const { label, name = 'address', removeBusinessInfo, customColor } = props;
  const classes = useStyles(props);

  const { errors, setFieldValue, touched, values }: any = useFormikContext();

  const [showaddressLine2Input, setShowaddressLine2Input] = useState<boolean>(getIn(values, `${name}.addressLine2`));

  const onAddressChange = async (address: Address) => {
    await setFieldValue(name, {
      fullAddress: address.fullAddress || '',
      addressLine1: address.addressLine1 || '',
      addressLine2: address.addressLine2 || '',
      city: address.city || '',
      region: address.region || '',
      regionName: address.regionName || '',
      postalCode: address.postalCode || '',
      country: address.country || '',
    });
  };

  return (
    <Grid container item direction="column" alignItems="flex-start" justify="center" style={{ gap: '8px' }}>
      <Grid container className={classes.addressLine1}>
        <AddressInput
          label={label ? label : `${removeBusinessInfo ? '' : 'Business '}Address`}
          required
          addressFields={{
            address: `${name}.fullAddress`,
            addressLine1: `${name}.addressLine1`,
            city: `${name}.city`,
            region: `${name}.region`,
            regionName: `${name}.regionName`,
            postalCode: `${name}.postalCode`,
            country: `${name}.country`,
          }}
          onAddressChange={onAddressChange}
          error={getIn(touched, `${name}.fullAddress`) && getIn(errors, `${name}.fullAddress`)}
        />
      </Grid>
      <Grid item style={{ width: '100%' }}>
        {showaddressLine2Input ? (
          <Grid container item direction="column" wrap="nowrap">
            <Typography variant="button" style={{ marginBottom: '' }}>
              Apt/Ste/Floor #
            </Typography>
            <TextInput
              name={`${name}.addressLine2`}
              className={classes.addressLineTwoContainer}
              placeholder="Apt/Ste/Floor #"
            />
          </Grid>
        ) : (
          <Typography
            variant="button"
            className={classes.addressLine2CTA}
            onClick={() => setShowaddressLine2Input(true)}
          >
            + Add Apt/Ste/Floor #
          </Typography>
        )}
      </Grid>
      {!removeBusinessInfo && (
        <Typography className={classes.caption} variant="caption">
          This is to direct local shoppers to your products via marketplace & internet search, and to suggest other
          location/pickup settings to you.
        </Typography>
      )}
    </Grid>
  );
};

export default BusinessAddressInput;
