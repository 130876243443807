import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import _ from "lodash";

export interface DebugState {
  stripe?: {
    testClock?: number;
  },
  domains?: {
    liveMode?: boolean;
  }
}

const initialState: DebugState = {};

type SetDebug = {
  path: string;
  value: string;
}

const debugSlice = createSlice({
  name: 'debug',
  initialState,
  reducers: {
    setDebugParam(state: DebugState, action: PayloadAction<SetDebug>) {
      const value = ['true', 'false'].includes(action.payload.value) ? action.payload.value === 'true' : action.payload.value;
      console.debug(`Setting Debug State: [${action.payload.path}: ${value}]`);
      _.set(state, action.payload.path, value);
    }
  },
  extraReducers: builder => {
  },
});

export const {
  setDebugParam: setDebugParam,
} = debugSlice.actions;

export default debugSlice.reducer;
