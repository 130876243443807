/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Checkbox, Chip, Grid, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router';
import ActionsMenu from '../ActionsMenu';
import { BaseProduct, ProductPageContext, specialToTag } from '@castiron/domain';
import { Dropdown, ProductCard, Typography, CloseIcon } from '@castiron/components';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getProductStatus } from '@castiron/utils';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Pencil from '@material-ui/icons/Edit';
import { openModal } from '../../store/reducers/modalConductor';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FilterListModal from './FilterListModal';

interface Props {
  context?: ProductPageContext;
}

const useStyles = makeStyles((theme: Theme) => ({
  activeFilterChip: {
    backgroundColor: theme.branding.v2.blue[50],
    padding: '0px 6px',
    gap: '8px',
    '&:focus': {
      backgroundColor: theme.branding.v2.blue[50],
    },
    '& .MuiChip-label': {
      paddingLeft: '6px',
      paddingRight: 0,
    },
  },
  activeFilterContainer: {
    overflowX: 'scroll',
    gap: '16px',
    'scrollbar-width': 'thin',
    'scrollbar-color': theme.branding.v2.gray[400],
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.branding.v2.gray[600],
      '-webkit-border-radius': '12px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-track-piece:start': {
      background: 'transparent',
    },
    '&-webkit-scrollbar-track-piece:end': {
      background: 'transparent',
    },
  },
  checkbox: {
    color: theme.branding.v2.gray[300],
    '&.Mui-checked': {
      color: theme.branding.v2.blue[500],
    },
  },
  container: {
    paddingTop: 24,
    [theme.breakpoints.down('sm')]: {
      padding: '8px 16px 0px 16px',
    },
  },
  noProductsContainer: {
    width: '100%',
    padding: '32px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  productActions: {
    padding: 8,
    borderTop: `1px solid ${theme.branding.v2.gray[200]}`,
  },
  productCard: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 12,
  },
  productContent: {
    padding: 8,
    borderBottom: 'none',
    marginBottom: 'auto',
  },
  productsContainer: {
    gap: 16,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(192px, 1fr))',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(172px, 1fr))',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
    },
  },
}));

const ProductList: React.FC<Props> = (props: Props) => {
  const { context } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const isXsMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useAppDispatch();

  const productType = context === 'order-forms' ? 'custom' : context === 'products' ? 'standard' : 'event';
  const productsName = context?.replace('-', ' ');

  const [initialProducts, setInitialProducts] = useState<BaseProduct[]>([]);
  const [statusFilter, setStatusFilter] = useState('all');
  const [specialFilters, setSpecialFilters] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState<BaseProduct[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<BaseProduct[]>([]);

  const { fulfillments, products, shop } = useAppSelector(state => ({
    fulfillments: state.shops.fulfillments,
    products: state.products.products,
    shop: state.shops.shop,
  }));

  const handleAddClick = (): void => {
    history.push(`/${context}/add`);
  };

  const handleCardClick = (product): void => {
    history.push(`/${context}/edit/${product.id}`);
  };

  useEffect(() => {
    !!context
      ? setInitialProducts(
          products.filter(
            product => product.type === productType && ['active', 'inactive', 'scheduled'].includes(product.status),
          ),
        )
      : setInitialProducts(products.filter(product => ['active', 'inactive', 'scheduled'].includes(product.status)));
  }, [products]);

  useEffect(() => {
    const statusMap = {
      active: 'Active',
      inactive: 'Inactive',
      archived: 'Archived',
      scheduled: 'Scheduled',
    };
    const specialFiltersTags = specialFilters.map(special => specialToTag(special));

    const statusFiltered =
      statusFilter === 'archived'
        ? products.filter(product => product.type === productType && product.status === 'archived')
        : statusFilter === 'all'
        ? initialProducts
        : initialProducts.filter(
            product => statusMap[statusFilter] === statusMap[getProductStatus(product, shop?.config?.timeZone)],
          );

    const specialFiltered = _.isEmpty(specialFilters)
      ? statusFiltered
      : statusFiltered.filter(product => !_.isEmpty(_.intersection(product?.eventTags, specialFiltersTags)));
    setFilteredProducts(specialFiltered);
  }, [initialProducts, statusFilter, specialFilters]);

  const selectProductsDropdown = (
    <Dropdown
      title={
        <Grid container wrap="nowrap">
          <Checkbox className={classes.checkbox} checked={selectedProducts?.length > 0} onChange={() => {}} />
          <Typography variant="body1">{selectedProducts?.length || ''}</Typography>
        </Grid>
      }
      options={[
        ...(initialProducts.length !== filteredProducts.length &&
        !filteredProducts.every(p => selectedProducts.includes(p))
          ? [
              {
                label: `Select ${
                  filteredProducts?.length === 1 ? `1 ${productsName.slice(0, -1)}` : `all ${filteredProducts?.length} ${productsName}`
                } currently shown`,
                onClick: () => {
                  setSelectedProducts(filteredProducts);
                },
              },
            ]
          : []),
        ...(selectedProducts?.length !== initialProducts?.length
          ? [
              {
                label: `Select ${
                  initialProducts?.length === 1 ? `1 ${productsName.slice(0, -1)}` : `all ${initialProducts?.length} ${productsName}`
                }`,
                onClick: () => {
                  setSelectedProducts(initialProducts);
                },
              },
            ]
          : []),
        ...(selectedProducts?.length > 0
          ? [
              {
                label: 'Deselect all',
                onClick: () => {
                  setSelectedProducts([]);
                },
              },
            ]
          : []),
      ]}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    />
  );

  const bulkActionsDropdown = (
    <Dropdown
      title={isXsMobile ? <MoreVertIcon /> : 'Actions'}
      options={[
        {
          icon: <Pencil />,
          label: `Update Status`,
          onClick: () => {
            dispatch(
              openModal({
                modalType: 'BULK_PRODUCT_ACTIONS_MODAL',
                modalProps: {
                  context: 'status',
                  onSuccess: () => setSelectedProducts([]),
                  products: selectedProducts,
                },
              }),
            );
          },
        },
        {
          icon: <StarBorderIcon />,
          label: `Add Holidays & Occasions`,
          onClick: () => {
            dispatch(
              openModal({
                modalType: 'BULK_PRODUCT_ACTIONS_MODAL',
                modalProps: {
                  context: 'eventTags',
                  onSuccess: () => setSelectedProducts([]),
                  products: selectedProducts,
                },
              }),
            );
          },
        },
      ]}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    />
  );

  const handleFilterChange = (status: string, specials: string[], tagToRemove?: string) => {
    const newSpecials = tagToRemove ? specialFilters.filter(tag => tag !== tagToRemove) : specials;

    setStatusFilter(status);
    setSpecialFilters(newSpecials);
  };

  const handleFilterDelete = (filter: string) => {
    const statusFilters = ['Active', 'Inactive', 'Archived', 'Scheduled'];
    if (statusFilters.includes(filter)) {
      setStatusFilter('all');
    } else {
      const newFilters = specialFilters.filter(activeFilter => filter !== activeFilter);
      setSpecialFilters(newFilters);
    }
  };

  return (
    <Grid container className={classes.container} item xs={12} style={{ gap: '16px' }}>
      <Grid container item direction="column" xs={12} style={{ gap: '16px', minWidth: 0 }}>
        <Grid container item justify="space-between" style={isXsMobile ? { gap: '16px' } : {}}>
          <Grid container item wrap="nowrap" xs={6} style={{ gap: '8px' }}>
            {selectProductsDropdown}
            {bulkActionsDropdown}
          </Grid>
          <FilterListModal
            applyFilters={handleFilterChange}
            initialSpecials={specialFilters}
            initialStatus={statusFilter}
            context={context}
          />
        </Grid>
        {(statusFilter !== 'all' || !_.isEmpty(specialFilters)) && (
          <Grid container direction="row" wrap="nowrap" className={classes.activeFilterContainer}>
            {[statusFilter !== 'all' ? _.capitalize(statusFilter) : null, ...specialFilters].map(
              filter =>
                !!filter && (
                  <Chip
                    label={<Typography variant="body1">{filter}</Typography>}
                    onDelete={() => handleFilterDelete(filter)}
                    deleteIcon={<CloseIcon style={{ color: theme.branding.v2.gray[500] }} />}
                    className={classes.activeFilterChip}
                  />
                ),
            )}
          </Grid>
        )}
      </Grid>
      {_.isEmpty(filteredProducts) ? (
        <Grid container item justify="center" className={classes.noProductsContainer}>
          <Typography variant="subtitle1">
            No {productsName} found. Try adjusting your filters.
          </Typography>
        </Grid>
      ) : (
        <Grid container item className={classes.productsContainer}>
          {_.sortBy(filteredProducts, [
            product => {
              switch (getProductStatus(product, shop?.config?.timeZone)) {
                case 'active':
                  return 1;
                case 'scheduled':
                  return 2;
                case 'inactive':
                  return 3;
                case 'archived':
                  return 4;
                default:
                  return 100;
              }
            },
            product => product.title.toLowerCase(),
          ]).map(product => (
            <Grid
              key={product.id}
              container
              item
              direction="column"
              wrap="nowrap"
              className={classes.productCard}
              style={
                selectedProducts?.includes(product)
                  ? { borderColor: theme.branding.v2.blue[500], background: theme.branding.v2.blue[50] }
                  : {}
              }
            >
              <Grid container item className={classes.productContent}>
                <ProductCard
                  product={product}
                  onClick={() => handleCardClick(product)}
                  type="product"
                  timeZone={shop?.config?.timeZone}
                  shopFulfillments={fulfillments}
                />
              </Grid>
              <Grid
                container
                item
                wrap="nowrap"
                className={classes.productActions}
                alignItems="center"
                style={selectedProducts?.includes(product) ? { borderColor: theme.branding.v2.blue[500] } : {}}
              >
                <Checkbox
                  className={classes.checkbox}
                  checked={selectedProducts?.includes(product)}
                  onChange={() => {
                    setSelectedProducts(
                      selectedProducts.includes(product)
                        ? selectedProducts.filter(sp => sp !== product)
                        : [...selectedProducts, product],
                    );
                  }}
                />
                <ActionsMenu product={product} type="products" location="productCard" productCardContext={context} />
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(ProductList);
