import { Account } from './index';

export const accountToEventModel = (account: Account) => ({
  billingAddress: account.billingAddress,
  attribution: account?.attribution,
  isStripeConnected: !!account?.integrations?.stripe?.accountId,
  status: account?.status,
  subscription: {
    id: account?.subscription?.plan.id,
    name: account?.subscription?.plan.name,
    frequency: account?.subscription?.price?.frequency,
    amount: account?.subscription?.price?.amount,
    status: account?.subscription?.status,
    inTrial: !account?.subscription?.isTrialOver,
    attribution: account?.subscription?.attribution,
  },
});
