import React, { ReactElement } from 'react';
import { useHistory } from 'react-router';
import _ from 'lodash';
import moment from 'moment';
import Dinero from 'dinero.js';
import { Transaction } from '@castiron/domain';
import { OrderStatusPill, Typography } from '@castiron/components';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { GridCellParams, GridColumns } from '@material-ui/data-grid';
import DataGrid from '../../DataGrid/DataGrid';
import ActionsMenu from '../../ActionsMenu';
import { defaultTimeZone, useTracking } from '@castiron/utils';
import { prepareQuoteSegmentData } from '../QuoteUtils';
import { useAppSelector } from '../../../hooks';

interface Props {
  quotes: Transaction[];
  total?: number;
  page?: number;
  retrieveQuotes?: (page: number, searchText?: string) => Promise<void>;
}

const useStyles = makeStyles((theme: Theme) => ({
  dateTime: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const DesktopContent: React.FC<Props> = (props: Props) => {
  const { quotes, total, page, retrieveQuotes } = props;
  const classes = useStyles();
  const history = useHistory();
  const { trackEvent } = useTracking();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const tz = shop.config?.timeZone || defaultTimeZone;

  const columns: GridColumns = [
    {
      field: 'orderNumber',
      headerName: 'Order #',
      valueGetter: (params: GridCellParams): string => {
        return params.row.order.orderNumber;
      },
      flex: 1,
    },
    {
      field: 'customerName',
      headerName: 'Customer',
      valueGetter: (params: GridCellParams): string => {
        const customer = params.row.customerObj;
        return customer?.firstName || customer?.lastName
          ? `${customer?.firstName || ''} ${customer?.lastName || ''}`
          : `${customer?.email || ''}`;
      },
      flex: 1.5,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: (params: GridCellParams): ReactElement => {
        const quote = params.row as Transaction;
        return <OrderStatusPill transaction={quote} context="quote" />;
      },
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Type',
      valueGetter: (params: GridCellParams): string => {
        return _.capitalize(params.row.order.fulfillmentOption?.type) || '';
      },
      flex: 0.75,
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      type: 'number',
      renderCell: (params: GridCellParams) => {
        let date;
        let startTime;
        let endTime;

        if (
          params.row.order.fulfillmentOption?.schedule?.dates[0]?.startTime &&
          params.row.order.fulfillmentOption?.schedule?.type === 'fixed'
        ) {
          date = moment
            .unix(params.row.order.fulfillmentOption.schedule.dates[0].startTime)
            .tz(tz)
            .format('MM/DD/YY');
          startTime = moment
            .unix(params.row.order.fulfillmentOption.schedule.dates[0].startTime)
            .tz(tz)
            .format('h:mma');
          endTime = moment
            .unix(params.row.order.fulfillmentOption.schedule.dates[0].endTime)
            .tz(tz)
            .format('h:mma');
        } else if (params.row.order.fulfillmentOption?.schedule?.dates[0]?.startTime) {
          date = moment
            .unix(params.row.order.fulfillmentOption.schedule.dates[0].startTime)
            .tz(tz)
            .format('MM/DD/YY');
        } else if (params.row.order.fulfillmentOption?.date) {
          date = moment
            .unix(params.row.order.fulfillmentOption.date)
            .tz(tz)
            .format('MM/DD/YY');
        }

        return (
          <Grid container direction="column" className={classes.dateTime}>
            <Typography variant="body2">{date}</Typography>
            <Typography variant="body2">{!!startTime ? `${startTime} - ${endTime}` : ''}</Typography>
          </Grid>
        );
      },
      valueGetter: (params: GridCellParams): number =>
        params.row.order.fulfillmentOption?.schedule?.dates[0]
          ? params.row.order.fulfillmentOption?.schedule.dates[0]
          : params.row.order.fulfillmentOption?.date,
      flex: 1.5,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'total',
      headerName: 'Total',
      type: 'number',
      valueFormatter: (params: GridCellParams): string => {
        const total = params.row?.totals?.total || 0;
        return Dinero({ amount: total }).toFormat('$0.00');
      },
      valueGetter: (params: GridCellParams): number => params.row?.totals?.total,
      flex: 0.75,
    },
    {
      field: 'action',
      headerName: ' ',
      renderCell: (params: GridCellParams): any => {
        const quote = params.row as Transaction;
        return <ActionsMenu transaction={quote} type="quotes" />;
      },
    },
  ];

  const onClick = (params: GridCellParams): void => {
    const quote = params.row as Transaction;
    trackEvent('Quote Details Clicked', {
      ...prepareQuoteSegmentData(quote),
    });
    history.push(`/quotes/edit/${params.row.id}`);
  };

  return (
    <DataGrid
      onCellClick={onClick}
      columns={columns}
      rows={quotes}
      sortModel={[{ field: 'orderNumber', sort: 'desc' }]}
      pageSize={20}
      onPageChange={retrieveQuotes}
      rowCount={total}
      page={page}
    />
  );
};

export default DesktopContent;
