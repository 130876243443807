import React from 'react';
import { DialogContent, DialogTitle, Grid, makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { ButtonV2, Typography } from '@castiron/components';
import ModalWrapper from '../../RootModal/ModalWrapper';
import { getService } from '../../../firebase';
import { useAppDispatch } from '../../../hooks';
import { openModal } from '../../../store/reducers/modalConductor';
import { TicketedEvent, Transaction } from '@castiron/domain';
import { useHistory } from 'react-router-dom';

const sendEmailUpdateService = getService('products', 'sendeventupdatedemail');

export type Props = {
  event: TicketedEvent;
  eventTransactions: Transaction[];
  fromChecklist?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    padding: '32px 40px',
  },
  modalActions: {
    gap: '16px',
    padding: '0px 40px 40px',
  },
  modalWrapper: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '550px',
      borderRadius: '32px',
      [theme.breakpoints.down('xs')]: {
        borderRadius: 0,
      },
    },
  },
}));

const EventUpdatedModal: React.FC<Props> = (props: Props) => {
  const { event, eventTransactions, fromChecklist } = props;
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = (notificationSent?: boolean): void => {
    if (fromChecklist) {
      history.push('/');
    } else {
      history.push(`/events`);
    }

    dispatch(
      openModal({
        modalType: 'SIMPLE_ALERT',
        modalProps: {
          show: true,
          celebrate: true,
          content: (
            <>
              Event <strong>{event?.title}</strong> was updated
              {notificationSent ? ' & new information was sent to existing ticket holders.' : '.'}
            </>
          ),
        },
      }),
    );
  };

  const handleSendUpdate = async () => {
    await Promise.all(
      eventTransactions.map(async transaction => {
        sendEmailUpdateService({ transaction: JSON.parse(JSON.stringify(transaction)), eventId: event?.id });
      }),
    );
    handleClose(true);
  };

  return (
    <ModalWrapper size="sm" show={true} fullScreen={isMobile} className={classes.modalWrapper}>
      <DialogTitle id="alert-dialog-title" style={{ padding: '40px 40px 0' }}>
        <Grid container>
          <Typography variant="h2">Changes Made</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <Grid container direction="column" className={classes.dialogContent}>
          <Typography variant="body2" style={{ marginBottom: '16px' }}>
            You’ve made changes to this event.
          </Typography>
          <Typography variant="body2">
            Would you like to send an email notification with the latest event information to existing ticket holders?
          </Typography>
        </Grid>
      </DialogContent>
      <Grid container direction="column" className={classes.modalActions}>
        <ButtonV2 variant="contained" fullWidth onClick={handleSendUpdate} color="primary">
          Yes, Send Notification
        </ButtonV2>
        <ButtonV2 variant="outlined" fullWidth onClick={() => handleClose(false)} color="primary">
          No, Don't Send Notification
        </ButtonV2>
      </Grid>
    </ModalWrapper>
  );
};

export default EventUpdatedModal;
