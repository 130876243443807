import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DialogContent, DialogTitle, Grid, IconButton, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Typography } from "@castiron/components";
import Close from "@material-ui/icons/Close";
import ModalWrapper from "../../RootModal/ModalWrapper";
import { PlanContext, PlanContextData } from "../PlanContext";
import { useAppDispatch } from "../../../hooks";
import PlanFlow, { SelectedSubscription } from "../PlanFlow";
import { closeModal } from "../../../store/reducers/modalConductor";
import Spinner from '../../Spinner';


export type Props = {
  open: boolean;
  fullscreen: boolean;
  onSuccess?: () => void;
  selectedPlan?: SelectedSubscription;
  couponCode?: string;
  context?: 'freeCustomDomainModal';
};

const useStyles = makeStyles((theme: Theme) => ({
  backArrow: {
    height: '20px',
    width: '20px',
    color: theme.branding.gray[800],
  },
  backArrowContainer: {
    height: '40px',
    width: '40px',
    padding: '9px 11px 10px 9px',
    border: `1px solid ${ theme.branding.gray[300] }`,
    borderRadius: '12px',
    marginRight: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  backArrowHeader: {
    padding: '16px !important'
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
  },
  header: {
    borderBottom: `1px solid ${ theme.branding.gray[400] }`,
    padding: '20px 16px',
    maxHeight: 71,
    '& h6': {
      color: theme.branding.gray[800],
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '28px',
    },
  },
}));


const ModalContent: React.FC<Props> = (props: Props) => {
  const { open, onSuccess, fullscreen, selectedPlan, couponCode, context } = props;

  const dispatch = useAppDispatch();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [modalHeader, setModalHeader] = useState('Select A Plan');

  const { step, setStep, planCardDataIsLoading } = useContext<PlanContextData>(PlanContext);

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const classes = useStyles();

  return <ModalWrapper fullScreen={ isMobile || fullscreen } size={ step === 2 ? 'sm' : 'lg' } show={ open }>
    {planCardDataIsLoading && step === 0 ? ( 
      <Spinner show={ true } size={ 'transparent' } /> 
    ) : (
      <>
        <DialogTitle className={ `${ step === 2 && classes.backArrowHeader } ${ classes.header }` }>
          <Grid container direction='row' justify='flex-start' alignItems='center'>
            { (step === 2 || step === 4) &&
            <Grid
              item
              className={ classes.backArrowContainer }
              onClick={ () => {
                setStep((s: number) => s - 1);
              } }
            >
              <ArrowBackIcon className={ classes.backArrow }/>
            </Grid> }
            <Grid item>
              <Typography variant="h3">{ modalHeader }</Typography>
            </Grid>
          </Grid>
          <IconButton className={ classes.closeIcon } onClick={ handleClose }>
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent style={ { padding: 0 } }>
          <PlanFlow setHeader={ setModalHeader } selectedSub={ selectedPlan } couponCode={ couponCode } onSuccess={onSuccess} context={context} />
        </DialogContent>
      </>
    )}
  </ModalWrapper>;
}

export default ModalContent;
