import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { nanoid } from '@reduxjs/toolkit';
import * as helpscout from '../../lib/helpscount';
import { Avatar, Button, ButtonBase, Divider, Grid, Popover, PopoverOrigin } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { GiftOutlinedIcon, Typography, useFeatures } from '@castiron/components';
import { toQueryString, useTracking } from '@castiron/utils';
import { FeatureFlag } from '@castiron/castiron-firebase';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { createOpenShopButtonClickedEvent } from '../../lib/events/commonEvents';
import { signOutAction } from '../../store/reducers/users';
import { openModal } from '../../store/reducers/modalConductor';
import PromoBlock, { showFirstMonthPromoBlock } from '../ReferralPrompts/PromoBlock';

interface Props {
  variant?: 'small' | 'large';
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

const useStyles = makeStyles((theme: Theme) => ({
  accountMenu: {
    width: '100%',
    borderTop: `1px solid ${theme.branding.gray[200]}`,
    padding: '16px 8px 19px 16px',
  },
  avatarLarge: {
    border: `1px solid ${theme.branding.gray[400]}`,
    background: theme.branding.blue.primary,
    height: '36px',
    width: '36px',
    fontSize: '16px',
    '& img': {
      borderRadius: '50%',
    },
  },
  avatarSmall: {
    border: `1px solid ${theme.branding.gray[400]}`,
    background: theme.branding.blue.primary,
    height: '36px',
    width: '36px',
    fontSize: '16px',
  },
  businessName: {
    //there's not really a good typography option for this currently, so I have to adjust it manually. it will be caption2 after the design overhaul, though
    fontWeight: 400,
    lineHeight: '20px',
    color: theme.branding.gray[700],
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  displayName: {
    textOverflow: 'ellipsis',
    wrap: 'nowrap',
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  divider: {
    background: theme.branding.v2.gray[200],
    width: '100%',
  },
  giftIcon: {
    color: theme.branding.v2.green[500],
    height: 18,
    width: 18,
  },
  helpscoutIcon: {
    color: theme.branding.v2.green[500],
    height: 12,
    width: 12,
  },
  menuActive: {
    backgroundColor: theme.branding.gray[200],
  },
  menuOption: {
    '& span': {
      color: theme.branding.gray[800],
    },
  },
  moreIcon: {
    margin: '8px',
    color: theme.branding.gray[600],
  },
  moreIconActive: {
    color: theme.branding.gray[800],
  },
  signOutButton: {
    marginLeft: 'auto',
  },
  storeName: {
    whiteSpace: 'nowrap',
    marginRight: 10,
    maxWidth: '80%',
    display: 'inline-block',
    fontSize: 13,
    color: theme.palette.text.secondary,
    position: 'relative',
    '&:hover': {
      color: theme.palette.text.secondary,
    },
  },
  trialPill: {
    backgroundColor: theme.branding.blue.light,
    borderRadius: 100,
    color: `${theme.branding.v2.blue[500]} !important`,
    fontWeight: 700,
    marginLeft: 8,
    padding: '4px 12px',
    width: 'fit-content',
  },
  userActions: {
    display: 'flex',
    alignItems: 'center',
  },
  userActionsButton: {
    padding: 0,
    bottom: 0,
  },
  userActionsPopup: {
    '& .MuiPopover-paper': {
      borderRadius: '16px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& .MuiButton-sizeLarge': {
        border: `2px solid ${theme.branding.gray[100]}`,
        width: '100%',
        justifyContent: 'flex-start',
      },
    },
  },
  userName: {
    margin: '0px 8px',
    textAlign: 'left',
  },
}));

const AvatarMenu: React.FC<Props> = (props: Props) => {
  const {
    variant = 'small',
    anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin = {
      vertical: 'top',
      horizontal: 'right',
    },
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const theme = useTheme();
  const features = useFeatures();
  const showSubscriptions = features.includes('admin.subscriptions');

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { account, shop, me, userState } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
    me: state.users.me,
    userState: state.shops.userState,
  }));

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const splittedDisplayName = me.displayName?.split(' ');
  const initials = splittedDisplayName
    ?.slice(0, 2)
    .map(name => (name.length > 0 ? name.charAt(0) : ''))
    .join('');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleViewShopClick = () => {
    trackEvent('Open Shop Button Clicked', createOpenShopButtonClickedEvent(shop, me));
    const cacheBypass = nanoid();
    let queryParams = {
      [cacheBypass]: true,
    };
    if (shop.status === 'prelaunch') {
      queryParams = { ...queryParams, previewMode: true };
    }
    window.open(`${process.env.REACT_APP_SHOP_URL}${shop?.websiteUrl}?${toQueryString(queryParams)}`, '_blank');
    setAnchorEl(null);
  };

  const handleAccountClick = () => {
    history.push('/account-settings');
    setAnchorEl(null);
  };

  const handleShareLinksClick = () => {
    setAnchorEl(null);
    dispatch(
      openModal({
        modalType: 'SHARE_LINKS_MODAL',
        modalProps: {
          show: true,
        },
      }),
    );
  };

  const handleSignOutClick = (): void => {
    dispatch(signOutAction('/'));
  };

  const trialPill = (
    <Typography className={classes.trialPill} variant="caption">
      TRIAL
    </Typography>
  );

  const accountMenuOptions = [
    {
      text: `${shop.status === 'active' ? 'V' : 'Prev'}iew My Shop`,
      action: handleViewShopClick,
      class: null,
    },
    {
      text: 'Share My Shop',
      action: handleShareLinksClick,
      class: null,
    },
    {
      divider: true,
    },
    {
      text: 'Chat With Support',
      icon: <FiberManualRecordIcon className={classes.helpscoutIcon} />,
      action: () => {
        helpscout.popChat();
        handleClose();
      },
    },
    {
      text: 'Help Center',
      action: () => window.open('https://castiron.helpscoutdocs.com/', '_blank'),
      class: null,
    },
    {
      text: 'The Kitchen Community',
      action: () => window.open('https://www.facebook.com/groups/thekitchencommunity', '_blank'),
      class: null,
    },
    {
      divider: true,
    },
    {
      text: 'My Subscription',
      action: () => {
        history.push('/store/plans');
        setAnchorEl(null);
      },
      class: null,
      pill: userState === 'inTrial' || userState === 'legacyInTrial' ? trialPill : null,
    },
    showFirstMonthPromoBlock(account)
      ? {
          element: <PromoBlock location="admin-menu" onClick={handleClose} />,
        }
      : {
          featureFlag: 'feature_cello_referrals',
          text: 'Get 1 Month Free',
          icon: <GiftOutlinedIcon className={classes.giftIcon} />,
          action: () => {
            trackEvent('Referral CTA Clicked', { location: 'admin-menu' });
            handleClose();
          },
          class: 'celloLauncher',
        },
    {
      text: 'Payouts',
      action: () => {
        history.push('/store/payments');
        setAnchorEl(null);
      },
      class: null,
    },
    {
      text: 'Account Settings',
      action: handleAccountClick,
      class: null,
    },
    {
      text: 'Sign Out',
      action: handleSignOutClick,
      class: classes.signOutButton,
    },
  ];

  const getButtonBlock = (option, index) => (
    <Button
      startIcon={option.icon}
      key={`avatarMenu${index}`}
      onClick={option.action}
      className={`${classes.menuOption} ${option.class}`}
    >
      <Typography variant="subtitle2">{option.text}</Typography>
      {option.pill}
    </Button>
  );

  return (
    <>
      {variant == 'small' && (
        <ButtonBase onClick={handleClick}>
          <Avatar src={me.profileImg || undefined} className={classes.avatarSmall}>
            {!me.profileImg && initials}
          </Avatar>
        </ButtonBase>
      )}
      {variant == 'large' && (
        <Button onClick={handleClick} className={classes.userActionsButton} disableRipple>
          <Grid className={`${anchorEl && classes.menuActive} ${classes.accountMenu}`}>
            <Grid className={classes.userActions} container alignItems="center" direction="row" wrap="nowrap">
              <Grid container item wrap="nowrap" direction="row">
                <Avatar src={me.profileImg || undefined} className={classes.avatarLarge}>
                  {!me.profileImg && initials?.toUpperCase()}
                </Avatar>
                <Grid
                  container
                  justify="center"
                  alignItems="flex-start"
                  direction="column"
                  className={classes.userName}
                >
                  <Typography variant="button" className={classes.displayName}>
                    {me.displayName}
                  </Typography>
                  <Typography variant="caption2" className={classes.businessName}>
                    {shop?.businessName}
                  </Typography>
                </Grid>
              </Grid>
              <MoreVertIcon className={`${classes.moreIcon} ${anchorEl && classes.moreIconActive}`} />
            </Grid>
          </Grid>
        </Button>
      )}
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        className={classes.userActionsPopup}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {accountMenuOptions?.map((option, index) => {
          if (option.element) {
            return <Fragment key={`avatarMenu${index}`}>{option.element}</Fragment>;
          } else if (option.divider) {
            return <Divider key={`avatarMenu${index}`} className={classes.divider} />;
          } else if (option.featureFlag) {
            return (
              <Fragment key={`avatarMenu${index}`}>
                <FeatureFlag name={option.featureFlag}>{getButtonBlock(option, index)}</FeatureFlag>
              </Fragment>
            );
          } else {
            return getButtonBlock(option, index);
          }
        })}
      </Popover>
    </>
  );
};

export default AvatarMenu;
