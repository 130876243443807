import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, DialogActions, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ListAlt } from '@material-ui/icons';
import { BoltIcon, ButtonV2, Typography } from '@castiron/components';
import { BaseProduct, productTypeDisplayName } from '@castiron/domain';
import { removeEmpty, useTracking } from '@castiron/utils';
import clsx from 'clsx';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeModal } from '../../../store/reducers/modalConductor';
import { duplicateProductAction } from '../../../store/reducers/products';
import { ProductModalProps } from './AddProductModal';

const useStyles = makeStyles((theme: Theme) => ({
  customIcon: {
    color: theme.branding.green.primary,
  },
  grayText: {
    color: theme.branding.v2.gray[500],
  },
  image: {
    height: '100%',
    width: '100%',
    borderRadius: '12px',
    objectFit: 'cover',
  },
  productImageContainer: {
    position: 'relative',
    /* half of the view port width minus the padding around the blocks */
    height: 'calc(0.5 * (100vw - 64px))',
    width: 'calc(0.5 * (100vw - 64px))',
    borderRadius: '12px',
    backgroundColor: theme.branding.gray[300],
    [theme.breakpoints.up(510)]: {
      height: '180px',
      width: '180px',
    },
  },
  radioButton: {
    position: 'absolute',
    top: '12px',
    left: '12px',
  },
  selected: {
    border: `4px solid ${theme.branding.v2.blue[500]}`,
    borderRadius: '16px',
    padding: '4px',
  },
  standardIcon: {
    color: theme.branding.blue.primary,
  },
  submitContainer: {
    width: '100%',
    padding: '0 24px 24px',
  },
  titleText: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    paddingLeft: '2px',
  },
  unselectedImage: {
    opacity: '.5',
  },
}));

interface FormValues {
  productId: string;
}
const schema = yup.object({
  productId: yup.string(),
});
const initialState = {
  productId: '',
};

const Duplicate: React.FC<ProductModalProps> = (props: ProductModalProps) => {
  const { context, productType, setTitle, setFooter } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { trackEvent } = useTracking();

  const [formChanges, setFormChanges] = useState(0);
  const [localContext, setLocalContext] = useState(context);

  const { products } = useAppSelector(state => ({
    products: state.products.products,
  }));

  useEffect(() => {
    if (!localContext && productType) {
      productType === 'custom' ? setLocalContext('order-forms') : productType === 'standard' ? setLocalContext('products') : setLocalContext('events');
    }
  });

  const filteredProducts = productType ? products.filter(product => product.type === productType) : products;
  const formikRef = useRef<FormikProps<FormValues>>();

  const submit = (values: FormValues) => {
    dispatch(duplicateProductAction(values.productId)).then(res => {
      trackEvent('Product Created', {
        product: removeEmpty({
          ...(res.payload as BaseProduct),
          method: 'admin',
        }),
        addMethod: 'duplicate',
      });
      trackEvent(
        (res.payload as BaseProduct).type === 'standard' ? 'Standard Product Created' : (res.payload as BaseProduct).type === 'custom' ? 'Custom Product Created' : 'Event Created',
        {
          product: removeEmpty({
            ...(res.payload as BaseProduct),
            method: 'admin',
          }),
          addMethod: 'duplicate',
        },
      );
      history.push(`/${localContext}/edit/${(res.payload as BaseProduct).id}`, {
        fromCreate: true,
      });
      dispatch(closeModal());
    });
  };

  useEffect(() => {
    setTitle(`Select ${localContext === 'order-forms' ? 'an Order Form' : localContext === 'products' ? 'a Product' : 'an Event'}`);
  }, []);

  /* I could not get a useEffect to trigger from the formikRef :shrug: */
  const handleOnChange = () => {
    setFormChanges(before => before + 1);
  };

  useEffect(() => {
    setFooter(
      formikRef.current.values.productId && (
        <DialogActions style={{ padding: 0 }}>
          <Box className={classes.submitContainer}>
            <ButtonV2 variant="contained" fullWidth onClick={() => formikRef.current.submitForm()}>
              Duplicate & Edit
            </ButtonV2>
          </Box>
        </DialogActions>
      ),
    );

    return () => {
      setFooter(null);
    };
  }, [formChanges]);

  return (
    <Formik onSubmit={submit} validationSchema={schema} initialValues={initialState} innerRef={formikRef}>
      {({ values }): ReactElement => (
        <Form onChange={handleOnChange}>
          <Grid container spacing={2}>
            {filteredProducts.map(product => (
              <Grid item key={`product${product.id}`} xs={6}>
                <label style={{ width: '100%', marginBottom: 0 }}>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container justify="center">
                        <Grid item>
                          <Box
                            className={clsx([
                              classes.productImageContainer,
                              values.productId === product.id && classes.selected,
                            ])}
                          >
                            <Field type="radio" name="productId" value={product.id} className={classes.radioButton} />
                            {product.imageObj ? (
                              <img
                                src={
                                  product.imageObj.smallVersion?.downloadUrl ||
                                  product.imageObj.mediumVersion?.downloadUrl ||
                                  product.imageObj.downloadUrl
                                }
                                className={clsx([
                                  classes.image,
                                  values.productId && values.productId !== product.id && classes.unselectedImage,
                                ])}
                              />
                            ) : (
                              <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
                                <Grid item>
                                  <Typography
                                    component="h2"
                                    variant="h1"
                                    className={
                                      values.productId && values.productId !== product.id && classes.unselectedImage
                                    }
                                  >
                                    {product.title.substring(0, 2)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" className={classes.titleText}>
                        {product.title}
                      </Typography>
                      {!!localContext ? (
                        <></>
                      ) : (
                        <>
                          {product.type === 'standard' && <BoltIcon className={classes.standardIcon} />}
                          {product.type === 'custom' && <ListAlt className={classes.customIcon} />}
                          <Typography component="span" variant="body2" className={classes.grayText}>
                            {productTypeDisplayName(product.type)}
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </label>
              </Grid>
            ))}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default Duplicate;
