import React, { useEffect, useState } from 'react';
import { Box, Divider, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dinero from 'dinero.js';
import { useAppSelector } from '../../../hooks';

type Props = {
  data: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    fontSize: 20,
    fontWeight: 800,
    marginTop: 18,
  },
  statsSummaryContainer: {
    marginTop: 38,
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-around',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 24,
    },
  },
  statContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stat: {
    fontFamily: 'Roboto',
    fontSize: 54,
    fontWeight: 700,
    lineHeight: '40px',
  },
  statTitle: {
    fontSize: 15,
    lineHeight: '16px',
    marginTop: 8,
    color: theme.branding.gray[700],
  },
}));

const CustomerStats: React.FC<Props> = (props: Props) => {
  const { data } = props;
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalOrderValue, setTotalOrderValue] = useState(0);
  const [averageOrderValue, setAverageOrderValue] = useState(0);
  const classes = useStyles();
  const { transactions } = useAppSelector(state => ({
    transactions: state.transactions.transactions,
    isTransactionsLoading: state.transactions.loading,
  }));

  useEffect(() => {
    if (transactions) {
      let orderLength = 0;
      let curTotalOrderValue = 0;
      let curAverageOrderValue = 0;
      const customerID = data.id;
      const curCustomerTransactions = transactions.filter(curTransaction => curTransaction.customer === customerID && curTransaction.transactionStatus === 'succeeded');
      orderLength = curCustomerTransactions.length;
      if (curCustomerTransactions?.length > 0) {
        curTotalOrderValue = curCustomerTransactions.reduce(
          (acc, transaction) => acc + transaction?.totals?.total || 0,
          0,
        );
      }
      curAverageOrderValue = Math.round(curTotalOrderValue / orderLength);
      setTotalOrderValue(curTotalOrderValue);
      setTotalOrders(orderLength);
      setAverageOrderValue(curAverageOrderValue);
    }
  }, [data, transactions]);

  return (
    <>
      <Typography variant="h3" className={classes.subtitle}>
        Contact stats
      </Typography>
      <Box className={classes.statsSummaryContainer}>
        <Box className={classes.statContainer}>
          <Typography className={classes.stat}>{totalOrders}</Typography>
          <Typography className={classes.statTitle}>Total orders</Typography>
        </Box>
        <Divider flexItem={true} orientation="vertical" />
        <Box className={classes.statContainer}>
          <Typography className={classes.stat}>
            {Dinero({ amount: averageOrderValue || 0 }).toFormat('$0.00')}
          </Typography>
          <Typography className={classes.statTitle}>Average order</Typography>
        </Box>
        <Divider flexItem={true} orientation="vertical" />
        <Box className={classes.statContainer}>
          <Typography className={classes.stat}>{Dinero({ amount: totalOrderValue || 0 }).toFormat('$0.00')}</Typography>
          <Typography className={classes.statTitle}>Total orders</Typography>
        </Box>
      </Box>
    </>
  );
};

export default CustomerStats;
