import React, { useState } from 'react';
import {
  Box,
  ButtonBase,
  Collapse,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import TrashIcon from '../../../assets/img/trash.svg';
import ChevronUpIcon from '../../../assets/img/chevron-up.svg';
import ChevronDownIcon from '../../../assets/img/chevron-down.svg';
import { nanoid } from '@reduxjs/toolkit';
import { Input, SvgIcon, TextAreaInput, Typography } from '@castiron/components';
import CloseIcon from '@material-ui/icons/Close';
import { ShopFaq } from '@castiron/domain';
import DragIndicator from '@material-ui/icons/DragIndicator';
import { shopRepository } from '../../../domain';

const useStyles = makeStyles((theme: Theme) => ({
  answerContainer: {
    marginBottom: 24,
  },
  closeIcon: {
    width: '50px',
  },
  collapseHeader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: 14,
    fontWeight: 700,
  },
  deleteButton: {
    width: 40,
    height: 40,
  },
  expandIcon: {
    width: 40,
    height: 40,
  },
  faqContainer: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 12,
    marginTop: '16px',
    position: 'relative',
    backgroundColor: theme.branding.gray[100],
  },
  faqHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 24px',
  },
  faqIndexContainer: {
    width: '32px',
    height: '32px',
    paddingTop: '3px',
    textAlign: 'center',
    backgroundColor: theme.branding.blue.light,
    borderRadius: '8px',
    minWidth: '32px',
  },
  faqIndex: {
    color: theme.branding.blue.dark,
  },
  faqTitle: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    marginLeft: '16px',
    marginBottom: '3px',
    maxWidth: '85%',
  },
  faqActions: {
    padding: 16,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  faqContent: {
    margin: '21px 24px',
  },
  dragHandle: {
    paddingTop: '2px',
    marginRight: '10px',
  },
  dragDots: {
    color: theme.branding.gray[400],
  },
  questionLabel: {},
  answerLabel: {
    marginBottom: 16,
    marginTop: 16,
  },
}));

interface Props {
  faq: ShopFaq;
  index: number;
  faqExpanded: boolean;
  dragHandleProps: any;
  didChange?: (value: boolean) => void;
  faqLength: number;
  pageIsEnabled: boolean;
  shopId: string;
}

const SingleFaq: React.FC<Props> = (props: Props) => {
  const { faq, index, faqExpanded, dragHandleProps, didChange, faqLength, pageIsEnabled, shopId } = props;
  const { values, setFieldValue, setFieldError, errors, setFieldTouched, submitCount }: any = useFormikContext();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [expanded, setExpanded] = useState(faqExpanded);

  const deleteVariation = async (id: string) => {
    await shopRepository.deleteShopFaq(shopId, id);
    setFieldValue(
      'faqs',
      values.faqs.filter((faq: ShopFaq) => faq.id !== id),
    );
  };

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Grid key={faq.id} className={classes.faqContainer}>
      <Box>
        <Grid container direction="row" className={classes.faqHeader}>
          <ButtonBase onClick={handleExpand} className={classes.collapseHeader} disableRipple>
            <Box style={{ display: 'flex', width: '100%' }}>
              <span {...dragHandleProps} className={classes.dragHandle}>
                <SvgIcon className={classes.dragDots}>
                  <DragIndicator />
                </SvgIcon>
              </span>
              <Typography variant="body1" className={classes.faqTitle} id={`'Question'${index + 1}`}>
                {faq.question}
              </Typography>
            </Box>
            {faqLength > 1 && (
              <SvgIcon onClick={handleExpand} className={classes.expandIcon}>
                {expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </SvgIcon>
            )}
          </ButtonBase>
        </Grid>
        <Collapse in={index === 0 && faqLength === 1 ? true : expanded} timeout="auto" unmountOnExit>
          <Divider />
          <Grid className={classes.faqContent}>
            <Grid container direction="column">
              <Typography variant="subtitle2" className={classes.questionLabel}>
                Question*
              </Typography>
              <Grid style={{ marginTop: '-17px' }}>
                <Input
                  name={`faqs[${index}].question`}
                  onChange={value => {
                    setFieldValue(`faqs[${index}].question`, value.target.value);
                    didChange && didChange(true);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container direction="column" className={classes.answerContainer}>
              <Typography variant="subtitle2" className={classes.answerLabel}>
                Answer*
              </Typography>
              <Grid style={{ marginTop: '-17px' }}>
                <TextAreaInput
                  onChange={value => {
                    setFieldValue(`faqs[${index}].answer`, value.target.value);
                    didChange && didChange(true);
                  }}
                  value={values.faqs[index].answer}
                  name={`faqs[${index}].answer`}
                  rowCount={5}
                />
              </Grid>
            </Grid>
          </Grid>

          <Divider />
          <Grid className={classes.faqActions}>
            <IconButton focusRipple onClick={() => deleteVariation(faq.id)}>
              <SvgIcon className={classes.deleteButton}>
                <TrashIcon />
              </SvgIcon>
            </IconButton>
          </Grid>
        </Collapse>
      </Box>
    </Grid>
  );
};

export default SingleFaq;
