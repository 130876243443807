import React, { ReactNode } from 'react';
import { FormHelperText, Grid, Paper, IconButton, InputBase, InputAdornment, TextField } from '@material-ui/core';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Field, FormikErrors } from 'formik';
import InputLabel from '../../InputLabel';

interface Props {
  autoFocus?: boolean;
  label?: ReactNode;
  name?: string;
  required?: boolean;
  placeholder?: string;
  onChange: (event: any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  containerClass: {
    marginBottom: 4,
  },
  input: {
    width: '100%',
    fontSize: 16,
    fontWeight: 400,
    border: 'none',
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.branding.gray[100],
      margin: 4,
      borderRadius: 12,
      '&:hover fieldset': {
        border: `4px solid ${theme.branding.blue.light} !important`,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${theme.branding.blue.primary} !important`,
      },
    },
  },
}));

const SearchInput: React.FC<Props> = (props: Props) => {
  const { label, name, placeholder, onChange } = props;
  const classes = useStyles();

  return (
    <Grid container>
      {label && (
        <Grid item xs={12}>
          <InputLabel containerClass={classes.containerClass} primaryLabel={label} />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          className={classes.input}
          name={name}
          onChange={onChange}
          variant="outlined"
          placeholder={placeholder}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchOutlinedIcon />
              </IconButton>
            ),
          }}
        ></TextField>
      </Grid>
    </Grid>
  );
};

export default SearchInput;
