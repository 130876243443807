import React, { ReactNode, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import {
  AccessTimeOutlined,
  ControlPointOutlined,
  DeleteOutline,
  FileCopyOutlined,
  LocationOnOutlined,
  MonetizationOnOutlined,
  ToggleOffOutlined,
} from '@material-ui/icons';
import DriveEtaOutlinedIcon from '@material-ui/icons/DriveEtaOutlined';
import _ from 'lodash';
import moment from 'moment';
import Dinero from 'dinero.js';
import { Banner, Button, Chip, Typography } from '@castiron/components';
import { DeliveryTruckIcon } from '@castiron/components/src/Icons';
import { FulfillmentOption, FulfillmentType, fulfillmentTypeDisplayName, shopToEventModel } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { openModal } from '../../../store/reducers/modalConductor';
import { getShopAction } from '../../../store/reducers/shops';
import Dropdown, { DropDownOption } from '../../Dropdown';
import { LayoutPageProps } from '../../Layout';
import EllipsisMenu, { EllipsisMenuOption } from '../../Menus/EllipsisMenu';
import DeleteDialog from './DeleteDialog';
import EmptyPage, { TipsForYou } from '../../EmptyPage';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@material-ui/icons/UnarchiveOutlined';
import { getSubscriptionStatus } from '../../../lib/accountUtils';

import obModalDesktopScreen1 from '../../../assets/img/onboardingModals/fulfillmentModal/FulfillmentDesktopScreen1.png';
import obModalDesktopScreen2 from '../../../assets/img/onboardingModals/fulfillmentModal/FulfillmentDesktopScreen2.png';
import obModalDesktopScreen3 from '../../../assets/img/onboardingModals/fulfillmentModal/FulfillmentDesktopScreen3.png';
import obModalMobileScreen1 from '../../../assets/img/onboardingModals/fulfillmentModal/FulfillmentMobileScreen1.png';
import obModalMobileScreen2 from '../../../assets/img/onboardingModals/fulfillmentModal/FulfillmentMobileScreen2.png';
import obModalMobileScreen3 from '../../../assets/img/onboardingModals/fulfillmentModal/FulfillmentMobileScreen3.png';

const useStyles = makeStyles((theme: Theme) => ({
  bannerColor: {
    color: theme.branding.v2.blue[500],
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  emptyContainer: {
    padding: '24px',
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '12px',
  },
  emptyPageContainer: {
    padding: '0px 16px',
  },
  filterSelected: {
    color: theme.branding.blue.primary,
  },
  fulfillmentCardContainer: {
    position: 'relative',
    height: '200px',
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '12px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  fulfillmentCardContentContainer: {
    padding: '16px 24px 24px',
  },
  fulfillmentCardInfoIcon: {
    color: theme.branding.gray[700],
    marginRight: '8px',
  },
  fulfillmentCardMenu: {
    position: 'absolute',
    top: '32px',
    right: '16px',
    color: theme.branding.gray[600],
  },
  fulfillmentCardTop: {
    height: '10px',
    borderRadius: '11px 11px 0px 0px',
  },
  fulfillmentActive: {
    background: theme.branding.blue.primary,
  },
  fulfillmentInactive: {
    background: theme.branding.gray[400],
  },
  fulfillmentInfo: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  fulfillmentExpired: {
    background: theme.branding.red.primary,
  },
  icon: {
    height: 104,
    width: 248,
  },
  infoBanner: {
    marginBottom: 16,
  },
  truncation: {
    wordBreak: 'break-all',
  },
  truncationWrapper: {
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  fulfillmentModalMediaAdjustments: {
    '& img': {
      height: '100%',
      width: '100%',
    },
  },
  fulfillmentModalContentAdjustments: {
    maxWidth: '100%',
  },
}));

type FilterValue = FulfillmentType | 'all';

const Fulfillment: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();

  const { account, shop, fulfillments } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
    fulfillments: state.shops.fulfillments,
  }));

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileXs = useMediaQuery(theme.breakpoints.down('xs'));

  const [filterValue, setFilterValue] = useState<FilterValue>('all');
  const [optionToDelete, setOptionToDelete] = useState<FulfillmentOption>();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const filteredFulfillments = fulfillments.filter(ff => filterValue === 'all' || filterValue === ff.type);
  const activeFulfillments: FulfillmentOption[] = [];
  const inactiveFulfillments: FulfillmentOption[] = [];
  const archivedFulfillments: FulfillmentOption[] = [];
  filteredFulfillments.forEach(ff => {
    switch (ff.status) {
      case 'active':
        activeFulfillments.push(ff);
        break;
      case 'inactive':
        inactiveFulfillments.push(ff);
        break;
      case 'archived':
        archivedFulfillments.push(ff);
        break;
    }
  });
  const now = moment().unix();
  const [currentActiveFulfillments, expiredFulfillments] = _.partition(activeFulfillments, ff => {
    return (
      ff.schedule?.type !== 'fixed' ||
      ff.schedule.dates.some(tp => tp.repeatWeekly) ||
      ff.schedule.dates.some(tp => tp.endTime > now)
    );
  });
  const hasOnlyOnboardingFulfillments = fulfillments.every(ff => ff?.source && ff.source === 'onboarding');

  const filterDisplay = (fv: FilterValue, inOptions: boolean) => {
    let displayText;
    switch (fv) {
      case 'all':
        displayText = 'All Types';
        break;
      default:
        displayText = fulfillmentTypeDisplayName(fv as FulfillmentType);
    }
    return (
      <Typography variant="button" className={inOptions && fv === filterValue ? classes.filterSelected : ''}>
        {displayText}
      </Typography>
    );
  };

  const fulfillmentTypeToFilterOption = (fv: FilterValue): DropDownOption => ({
    label: filterDisplay(fv, true),
    onClick: () => setFilterValue(fv),
  });
  const filterOptions: FilterValue[] = ['all', 'pickup', 'delivery', 'shipping'];
  const dropDownOptions = filterOptions.map(fv => fulfillmentTypeToFilterOption(fv));

  useEffect(() => {
    window.scrollTo(0, 0);
    setPageTitle('Shop Fulfillment');
    setBackLocation(true);

    dispatch(getShopAction(shop.id));

    return () => {
      setPageTitle('');
      setBackLocation(false);
    };
  }, []);

  const openAddFulfillmentModal = () => {
    dispatch(
      openModal({
        modalType: 'ADD_FULFILLMENT_MODAL',
        modalProps: {
          open: true,
        },
      }),
    );
  };

  useEffect(() => {
    const headerCTAs = isMobile
      ? []
      : [
          <Button
            variant="contained"
            onClick={() => {
              openAddFulfillmentModal();
            }}
          >
            Add an Option
          </Button>,
        ];
    setHeaderCTAs(headerCTAs);

    isMobile
      ? setFooterCTAs([
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              openAddFulfillmentModal();
            }}
          >
            Add an Option
          </Button>,
        ])
      : setFooterCTAs([]);
  }, [isMobile]);

  const fulfillmentModalContent = [
    {
      header: 'Let Your Customers Know How They Can Receive Their Orders.',
      body: isMobileXs
        ? 'To create a fulfillment option, simply select a type and enter your preferences such as dates, times, and pricing.'
        : 'All customers are required to select a fulfillment method upon checkout. To create a fulfillment option, simply select a type and enter your preferences such as dates, times, and pricing.',
      media: isMobileXs ? (
        <img src={obModalMobileScreen1} alt="Welcome Fulfillment Screen 1" />
      ) : (
        <img src={obModalDesktopScreen1} alt="Welcome Fulfillment Screen 1" />
      ),
      mediaClassName: classes.fulfillmentModalMediaAdjustments,
      contentClassName: classes.fulfillmentModalContentAdjustments,
    },
    {
      header: 'Add as Many Options as You Want!',
      body:
        'Life is busy and we know you are too! Create as many fulfillment options as you need to set some boundaries, while also giving your customers flexibility.',
      media: isMobileXs ? (
        <img src={obModalMobileScreen2} alt="Welcome Fulfillment Screen 2" />
      ) : (
        <img src={obModalDesktopScreen2} alt="Welcome Fulfillment Screen 2" />
      ),
      mediaClassName: classes.fulfillmentModalMediaAdjustments,
      contentClassName: classes.fulfillmentModalContentAdjustments,
    },
    {
      header: 'See Your Fulfillment Options in Action!',
      body: (
        <>
          After editing, click <b>View My Shop</b> located on your home tab. Add a product to your cart and check out to
          see what your customers will experience.
        </>
      ),
      media: isMobileXs ? (
        <img src={obModalMobileScreen3} alt="Welcome Fulfillment Screen 3" />
      ) : (
        <img src={obModalDesktopScreen3} alt="Welcome Fulfillment Screen 3" />
      ),
      mediaClassName: classes.fulfillmentModalMediaAdjustments,
      contentClassName: classes.fulfillmentModalContentAdjustments,
    },
  ];

  const openWelcomeFulfillmentModal = () => {
    dispatch(
      openModal({
        modalType: 'ONBOARDING_MODAL',
        modalProps: {
          show: true,
          stepContent: fulfillmentModalContent,
          onboardingModalType: 'fulfillmentModal',
        },
      }),
    );
  };

  type FulfillmentStatusType = 'active' | 'inactive' | 'archived' | 'expired';
  const ffStatusDisplay = (ffst: FulfillmentStatusType) => _.capitalize(ffst);
  const fulfillmentCard = (fulfillment: FulfillmentOption, ffStatus: FulfillmentStatusType) => {
    let statusClass: string;
    switch (ffStatus) {
      case 'active':
        statusClass = classes.fulfillmentActive;
        break;
      case 'expired':
        statusClass = classes.fulfillmentExpired;
        break;
      case 'inactive':
      default:
        statusClass = classes.fulfillmentInactive;
    }

    let firstInfoLine: ReactNode;
    let secondInfoLine: ReactNode;
    if (fulfillment.type === 'shipping') {
      const fulfillmentFeeText = fulfillment.fee ? Dinero({ amount: fulfillment.fee }).toFormat('$0.00') : 'No';
      firstInfoLine = (
        <Typography variant="body2" className={classes.truncation}>
          <MonetizationOnOutlined className={classes.fulfillmentCardInfoIcon} />
          {`${fulfillmentFeeText} ${fulfillmentTypeDisplayName(fulfillment.type, 'short')} Fee`}
        </Typography>
      );
      secondInfoLine = (
        <Typography variant="body2" className={classes.truncation}>
          <ControlPointOutlined className={classes.fulfillmentCardInfoIcon} />
          {`${fulfillment.minimum ? Dinero({ amount: fulfillment.minimum }).toFormat('$0.00') : 'No'} Order Minimum`}
        </Typography>
      );
    } else {
      const now = moment().unix();
      const upcomingDates = _.sortBy(
        fulfillment.schedule?.dates?.filter(tp => tp?.endTime > now),
        tp => tp.startTime,
      );
      if (fulfillment.schedule?.type === 'fixed' && fulfillment.schedule?.dates?.some(tp => tp.repeatWeekly)) {
        firstInfoLine = (
          <Typography variant="body2" className={classes.truncation}>
            <AccessTimeOutlined className={classes.fulfillmentCardInfoIcon} />
            Weekly
          </Typography>
        );
      } else if (fulfillment.schedule?.type === 'fixed' && upcomingDates.length > 0) {
        const dateFormat = 'M/D/YY';
        const firstDate = moment.unix(_.head(upcomingDates).startTime);
        let dateText = firstDate.format(dateFormat);
        const rest = _.tail(upcomingDates);
        if (rest.length > 0) {
          const secondDate = moment.unix(_.head(rest).startTime);
          dateText = dateText + `, ${secondDate.format(dateFormat)}`;
          const restOfRest = _.tail(rest);
          if (restOfRest.length > 0) {
            dateText = dateText + `, and ${restOfRest.length} more`;
          }
        }
        firstInfoLine = (
          <Typography variant="body2" className={classes.truncation}>
            <AccessTimeOutlined className={classes.fulfillmentCardInfoIcon} />
            {dateText}
          </Typography>
        );
      } else {
        firstInfoLine = (
          <Typography variant="body2" className={classes.truncation}>
            <AccessTimeOutlined className={classes.fulfillmentCardInfoIcon} />
            Flexible
          </Typography>
        );
      }
      secondInfoLine = (
        <Typography variant="body2" className={classes.truncation}>
          <LocationOnOutlined className={classes.fulfillmentCardInfoIcon} />
          {fulfillment.address?.postalCode
            ? `${fulfillment.address?.addressLine1}, ${fulfillment.address?.city}, ${fulfillment.address?.region} ${fulfillment.address?.postalCode}`
            : `${fulfillment.postalCode}`}{' '}
          {/* {fulfillment.postalCode} is legacy */}
        </Typography>
      );
    }

    const actions: EllipsisMenuOption[] = [
      ...(fulfillment.status !== 'archived'
        ? [
            {
              display: `Set to ${fulfillment.status === 'active' ? 'Ina' : 'A'}ctive`,
              icon: <ToggleOffOutlined />,
              action: async () => {
                const newStatus = fulfillment.status === 'active' ? 'inactive' : 'active';
                await shop.updateFulfillmentOption(fulfillment.id, { status: newStatus });
                trackEvent('Shop Fulfillment Action', {
                  shop: shopToEventModel(shop),
                  subscriptionStatus: getSubscriptionStatus(account),
                  action: `${newStatus === 'active' ? '' : 'de'}activated`,
                  fulfillment: {
                    ...fulfillment,
                    status: newStatus,
                  },
                });
                await dispatch(getShopAction(shop.id));
              },
            },
          ]
        : []),
      {
        display: 'Duplicate',
        icon: <FileCopyOutlined />,
        action: async () => {
          const newOption = await shop.addFulfillmentOption({
            ..._.omit(fulfillment, ['id', 'createdAt', 'updatedAt']),
            displayName: `Copy of ${fulfillment.displayName}`,
          });
          trackEvent('Shop Fulfillment Action', {
            shop: shopToEventModel(shop),
            subscriptionStatus: getSubscriptionStatus(account),
            action: 'created',
            fulfillment: newOption,
          });
          await dispatch(getShopAction(shop.id));
          history.push(`/store/fulfillment/edit/${newOption.id}`);
        },
      },
      {
        display: fulfillment.status === 'archived' ? 'Unarchive' : 'Archive',
        icon: fulfillment.status === 'archived' ? <UnarchiveOutlinedIcon /> : <ArchiveOutlinedIcon />,
        action: async () => {
          const newStatus = fulfillment.status === 'archived' ? 'active' : 'archived';
          await shop.updateFulfillmentOption(fulfillment.id, { status: newStatus });
          trackEvent('Shop Fulfillment Action', {
            shop: shopToEventModel(shop),
            subscriptionStatus: getSubscriptionStatus(account),
            action: newStatus === 'archived' ? 'archived' : 'activated',
            fulfillment: {
              ...fulfillment,
              status: newStatus,
            },
          });
          await dispatch(getShopAction(shop.id));
        },
      },
      {
        display: 'Delete',
        icon: <DeleteOutline />,
        color: 'error',
        action: async () => {
          setOptionToDelete(fulfillment);
          setDeleteDialogOpen(true);
          trackEvent('Shop Fulfillment Action', {
            shop: shopToEventModel(shop),
            subscriptionStatus: getSubscriptionStatus(account),
            action: 'deleted',
            fulfillment,
          });
        },
      },
    ];

    return (
      <Box className={classes.fulfillmentCardContainer}>
        <Box className={classes.fulfillmentCardMenu}>
          <EllipsisMenu
            options={actions}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          />
        </Box>
        <Box className={[classes.fulfillmentCardTop, statusClass].filter(a => !!a).join(' ')} />
        <Grid
          container
          direction="column"
          spacing={1}
          onClick={() => history.push(`/store/fulfillment/edit/${fulfillment.id}`)}
          className={classes.fulfillmentCardContentContainer}
        >
          <Grid item className={classes.truncationWrapper} style={{ width: '90%' }}>
            <Typography variant="subtitle1" className={classes.truncation}>
              {fulfillment.displayName}
            </Typography>
          </Grid>
          <Grid item>
            <Chip colorScheme="gray">
              <Typography variant="caption">{fulfillmentTypeDisplayName(fulfillment.type)}</Typography>
            </Chip>
          </Grid>
          <Grid item className={classes.truncationWrapper} style={{ marginTop: '16px' }}>
            {firstInfoLine}
          </Grid>
          <Grid item className={classes.truncationWrapper} style={{ marginTop: '8px' }}>
            {secondInfoLine}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const fulfillmentSection = (ffStatus: FulfillmentStatusType, options: FulfillmentOption[]) => (
    <Grid item style={{ marginTop: '24px' }}>
      <Grid container direction="column">
        <Grid item style={{ marginBottom: '8px' }}>
          <Typography variant="subtitle1">{ffStatusDisplay(ffStatus)}</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={3}>
            {options.map(option => (
              <Grid key={`ffcard-${option.id}`} item xs={12} md={6} lg={4}>
                {fulfillmentCard(option, ffStatus)}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const tipsForYou: TipsForYou[] = [
    {
      icon: 'preview',
      title: 'View an Example',
      description: 'Get an idea of what you and your customers will see.',
      ctaAction: () => openWelcomeFulfillmentModal(),
    },
    {
      icon: 'article',
      title: 'Read Our Fulfillment Guide',
      description: 'Learn how to make your fulfillment options last.',
      ctaAction: () =>
        window
          .open('https://castiron.helpscoutdocs.com/article/102-how-to-setup-fulfillment-within-castiron', '_blank')
          .focus(),
    },
  ];

  const emptyFulfillment = () => (
    <Grid className={classes.emptyPageContainer}>
      <EmptyPage
        icon={<DeliveryTruckIcon className={classes.icon} />}
        title="Add a Fulfillment Option"
        description="There are no fulfillment options to display. Add an option to let your customers know how they can retrieve their orders."
        ctaAction={() => openAddFulfillmentModal()}
        ctaText="Add an Option"
        tipsForYou={tipsForYou}
        page="Fulfillment"
      />
    </Grid>
  );

  const displayFulfillments = () => (
    <Grid container direction="column" className={classes.container}>
      <Grid item>
        {hasOnlyOnboardingFulfillments && (
          <Grid container item className={classes.infoBanner}>
            <Banner variant="info-blue" customIcon={<DriveEtaOutlinedIcon className={classes.bannerColor} />}>
              <Typography variant="body2" className={classes.bannerColor}>
                We created these fulfillment options to get you started!
              </Typography>
            </Banner>
          </Grid>
        )}
        <Grid container justify="flex-end">
          <Grid item style={{ width: '166px' }}>
            <Dropdown title={filterDisplay(filterValue, false)} options={dropDownOptions} fullWidth />
          </Grid>
        </Grid>
      </Grid>
      {currentActiveFulfillments.length > 0 && (
        <Grid item>{fulfillmentSection('active', currentActiveFulfillments)}</Grid>
      )}
      {inactiveFulfillments.length > 0 && <Grid item>{fulfillmentSection('inactive', inactiveFulfillments)}</Grid>}
      {expiredFulfillments.length > 0 && <Grid item>{fulfillmentSection('expired', expiredFulfillments)}</Grid>}
      {archivedFulfillments.length > 0 && <Grid item>{fulfillmentSection('archived', archivedFulfillments)}</Grid>}
    </Grid>
  );

  return (
    <>
      <Helmet>
        <title>Shop Fulfillment | {shop.businessName}</title>
      </Helmet>
      {fulfillments.length ? displayFulfillments() : emptyFulfillment()}
      <DeleteDialog open={deleteDialogOpen} option={optionToDelete} onClear={() => setDeleteDialogOpen(false)} />
    </>
  );
};

export default Fulfillment;
