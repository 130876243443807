import React, { useEffect } from 'react';
import userflow from 'userflow.js';
import momentTimezone from 'moment-timezone';
import { ChecklistValues } from '@castiron/domain';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { updateChecklistAction } from '../../store/reducers/shops';

interface Props {}

export const UserflowTracking: React.FC<Props> = (props: Props) => {
  const { me } = useAppSelector(state => ({
    me: state.users.me,
  }));

  useEffect(() => {
    if (me?.uid) {
      userflow.init(process.env.REACT_APP_USERFLOW);
      userflow.identify(me.uid);
    }
  }, [me]);

  return <></>;
};

export const UserflowOnboardingTracking: React.FC<Props> = (props: Props) => {
  const dispatch = useAppDispatch();
  const { account, me, shop } = useAppSelector(state => ({
    account: state.shops.account,
    me: state.users.me,
    shop: state.shops.shop,
  }));

  useEffect(() => {
    if (me?.uid && !shop.checklistCompletions?.includes(ChecklistValues.UserflowChecklist)) {
      dispatch(updateChecklistAction({ shop, items: [ChecklistValues.UserflowChecklist] }));
      userflow.identify(me?.uid, {
        name: me?.displayName,
        email: me?.email,
        signed_up_at: momentTimezone().toISOString(),
        theme: account?.onboardingQuestions?.theme,
        persona: account?.onboardingQuestions?.persona,
        productTypes: account?.onboardingQuestions?.productTypes,
        fulfillmentOptions: account?.onboardingQuestions?.fulfillmentOptions?.map(opt => opt.type),
        castironIntent: account?.onboardingQuestions?.castironIntent,
      });
    }
  }, [me]);

  return <></>;
};
