import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { useAppSelector } from "../../../hooks";
import moment from "moment";
import StatsBox, { Stats } from "../../StatsBox";
import { Customer } from "@castiron/domain";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '4px',
    },
    marginBottom: '16px',
    marginLeft: '4px'
  }
}));
const lastWeekMoment = moment().subtract(1, 'week');
const lastWeek = lastWeekMoment.unix();

const calculateStats = (customers: Customer[], getDate: (customer: Customer) => number): Stats => {
  const totalCustomers = customers.filter(c => c.status !== 'deleted');
  const lastWeekCustomers = customers.filter(c => getDate(c) >= lastWeek);

  return {
    number: totalCustomers.length,
    change: lastWeekCustomers.length,
  }
}

const pluralize = (base:string, amount: number) => {
  return (amount == 1) ? base : `${base}s`;
}

const SubscriberStats: React.FC = () => {
  const classes = useStyles();
  const [contactStats, setContactStats] = useState<Stats>();
  const [emailSubscriberStats, setEmailSubscriberStats] = useState<Stats>();
  const [smsSubscriberStats, setSmsSubscriberStats] = useState<Stats>();
  const [customerStats, setCustomerStats] = useState<Stats>();
  const { customers } = useAppSelector(state => ({
    customers: state.customers.customers
  }));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    if (customers) {
      setContactStats(calculateStats(customers, customer => customer.createdAt));
      setEmailSubscriberStats(calculateStats(customers.filter(c => c.subscribed), customer => customer.dateLastSubscribed));
      setSmsSubscriberStats(calculateStats(customers.filter(c => c.messagingPreferences?.sms?.enabled), customer => customer.dateLastSubscribed));
      setCustomerStats(calculateStats(customers.filter(c => !!c.metrics?.dateLastPurchased), customer => customer.metrics?.dateLastPurchased));
    }
  }, [customers]);

  return (
    <Grid container xs={12} direction="row" spacing={2} className={classes.container}>
      <StatsBox
        title={pluralize('Contact', contactStats?.number)}
        helpText="Contacts are individuals who have been added to your list but have not previously purchased from your Castiron shop. They can have any subscription status."
        stats={contactStats}
        xs={6}
      />
      <StatsBox
        title={pluralize('Customer', customerStats?.number)}
        helpText="Customers are individuals who have previously purchased from your Castiron shop. They can have any subscription status.
If a customer is not also a subscriber, you can only message them through Castiron about an order.
"
        stats={customerStats}
        xs={6}
      />
      <StatsBox
        title={pluralize('Email Subscriber', emailSubscriberStats?.number)}
        helpText="Subscribers are individuals who have been added to your list and have opted in to be subscribed to your marketing promos.
You can send messaging through Castiron to a subscriber about pop-ups, events, flash sales, and more.
"
        stats={emailSubscriberStats}
        xs={6}
      />
      <StatsBox
        title={pluralize('SMS Subscriber', smsSubscriberStats?.number)}
        helpText="Subscribers are individuals who have been added to your list and have opted in to be subscribed to your marketing promos.
You can send messaging through Castiron to a subscriber about pop-ups, events, flash sales, and more.
"
        stats={smsSubscriberStats}
        xs={6}
      />
    </Grid>
  );
}

export default SubscriberStats;
