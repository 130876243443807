import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Image from 'next/image';
import { useTracking } from '@castiron/utils';
import Typography from '../Typography';

type Props = {
  show: boolean;
  size?: 'fullscreen' | 'content' | 'relative' | 'transparent';
  label?: string;
  children?: ReactNode;
};

const useStyles = makeStyles(() => ({
  image: {
    height: 90,
    width: 90,
  },
  containerAbsolute: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    zIndex: 9999,
  },
  containerRelative: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
  },
  containerContent: {
    position: 'absolute',
    left: 272,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    zIndex: 10000,
  },
  transparent: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 10000,
    background: 'rgba(255, 255, 255, 0.9)',
    opacity: '0.8',
  },
}));

const NouryshSpinner: React.FC<Props> = (props: Props) => {
  const { label, size, show } = props;

  console.debug(`Spinner show [${show}] with message [${label}]`);

  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [message, setMessage] = useState<ReactElement>(label ? <>{label}</> : undefined);
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const { trackEvent } = useTracking();

  const getClass = () => {
    switch (size) {
      case 'fullscreen':
        return classes.containerAbsolute;
      case 'relative':
        return classes.containerRelative;
      case 'content':
        return classes.containerContent;
      case 'transparent':
        return classes.transparent;
      default:
        return classes.containerContent;
    }
  };

  useEffect(() => {
    setMessage(<>{label}</>);
    if (show) {
      setTimer(
        setTimeout(() => {
          //setMessage("Things seem to be taking longer than expected. Please refresh your browser.");
          setMessage(
            <Typography color="error" variant="h5">
              Things seem to be taking longer than expected. Please refresh your browser.
            </Typography>,
          );
        }, 20000),
      );
    } else {
      if (timer) {
        clearTimeout(timer);
        setTimer(undefined);
      }
    }
  }, [show]);

  return (
    <>
      {show && (
        <div className={getClass()}>
          <Image
            className={classes.image}
            src="/assets/nouryshSpinner.gif"
            alt="Nourysh loading spinner"
            height={90}
            width={90}
          />
          {message && (
            <>
              <Typography style={{ marginTop: 16 }} variant="h5">
                {message}
              </Typography>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default NouryshSpinner;
