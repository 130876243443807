import React, {ReactNode} from 'react';
import { useHistory } from 'react-router-dom';
import { FormikProps } from 'formik';
import { Button } from '../';

type Props = {
  isSubmitting: boolean;
  backLocation?: string;
  fromChecklist?: string;
  className?: string;
  customText?: string;
  onClick?: () => void;
  children?: ReactNode;
};

const DiscardButton: React.FC<Props> = (props: Props) => {
  const { backLocation, className, isSubmitting, fromChecklist, customText, onClick } = props;

  const history = useHistory();

  const click = () => {
    if (onClick) {
      return onClick;
    } else {
      return () => {
        !fromChecklist ? history.push(backLocation) : history.push('/');
      };
    }
  };

  return (
    <Button variant="outlined" disabled={isSubmitting} onClick={click()} className={className}>
      {customText || 'Discard'}
    </Button>
  );
};

export default DiscardButton;
