import { ButtonV2, SimpleStyledRadio, TextInput } from '@castiron/components';
import { useTracking } from '@castiron/utils';
import { CircularProgress, Grid, Theme, Typography, makeStyles, useTheme } from '@material-ui/core';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { getService } from '../../../../firebase';
import { useAppSelector } from '../../../../hooks';
import { getSubscriptionStatus } from '../../../../lib/accountUtils';

const domainSearchService = getService('shops', 'domainsearch', { version: 2 });

const useStyles = makeStyles((theme: Theme) => ({
  caption: {
    color: theme.branding.v2.gray[500],
    letterSpacing: '0.4px',
  },
  result: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 12,
    cursor: 'pointer',
    marginTop: 8,
    padding: 16,
  },
  searchButton: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  searchInput: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  unavailableBanner: {
    background: theme.branding.v2.gray[50],
    borderRadius: 16,
    padding: 16,
  },
  unavailableIcon: {
    color: theme.branding.v2.gray[400],
  },
}));

const Search: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { trackEvent } = useTracking();

  const { setFieldValue, values }: any = useFormikContext();

  const [results, setResults] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentSearchTerm, setCurrentSearchTerm] = useState<string>(values?.searchTerm);

  const { account, liveMode } = useAppSelector(state => ({
    account: state.shops.account,
    liveMode: state.debug?.domains?.liveMode,
  }));

  const getSearchTermAndTld = (searchTerm: string = '') => {
    const searchTermNoProtocol = searchTerm.replace(/^(https?\:\/\/)?(www.)?/i, '');
    const tldStart = searchTermNoProtocol.lastIndexOf('.');
    const searchTermNoTld = tldStart !== -1 ? searchTermNoProtocol.substring(0, tldStart) : searchTermNoProtocol;
    const topLevelDomain = tldStart !== -1 ? searchTermNoProtocol.substring(tldStart + 1) || 'com' : 'com';
    return {
      searchTerm: (searchTermNoTld.match(/[a-zA-Z0-9]+/g) || []).join('').toLowerCase(),
      topLevelDomain,
    };
  };

  const search = async () => {
    setIsLoading(true);
    const cleanSearchTerm = getSearchTermAndTld(values?.searchTerm).searchTerm;
    if (cleanSearchTerm) {
      trackEvent('Custom Domain Search', {
        domain: cleanSearchTerm,
        subscriptionStatus: getSubscriptionStatus(account),
      });
      const results = await domainSearchService({
        search: cleanSearchTerm,
        liveMode,
      });
      setCurrentSearchTerm(values?.searchTerm);
      /* Filter out any results that start with "shop" for now since it's causing an issue with our castiron domain and rerouting */
      setResults([...results.matches, ...results.suggestions].filter(result => !result.startsWith('shop')));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    search();
  }, []);

  const unavailableBanner = () => {
    const { searchTerm, topLevelDomain } = getSearchTermAndTld(currentSearchTerm);
    return (
      currentSearchTerm &&
      !results.includes(`${searchTerm}.${topLevelDomain}`) && (
        <Grid className={classes.unavailableBanner} container item style={{ gap: 16, marginTop: 16 }} wrap="nowrap">
          <NotInterestedIcon className={classes.unavailableIcon} />
          <Typography style={{ wordBreak: 'break-word' }} variant="body2">
            {`${searchTerm}.${topLevelDomain}`} is unavailable
          </Typography>
        </Grid>
      )
    );
  };

  return (
    <Grid container>
      <Grid container item wrap="nowrap">
        <TextInput className={classes.searchInput} name="searchTerm" startAdornment={<SearchOutlinedIcon />} />
        <ButtonV2 className={classes.searchButton} disabled={isLoading} onClick={search} variant="contained">
          <SearchOutlinedIcon />
        </ButtonV2>
      </Grid>
      {isLoading ? (
        <Grid container item justify="center" style={{ marginTop: 16 }}>
          <CircularProgress size={16} />
        </Grid>
      ) : (
        <>
          {unavailableBanner()}
          <Typography style={{ marginTop: 16 }} variant="subtitle1">
            Available Domains
          </Typography>
          {results.map((result, index) => (
            <Grid
              alignItems="center"
              className={classes.result}
              container
              item
              key={`${result}-${index}`}
              justify="space-between"
              onClick={async () => await setFieldValue('domain', result)}
              wrap="nowrap"
            >
              <Grid container direction="column" item>
                <Typography style={{ wordBreak: 'break-word' }} variant="body1">
                  {result}
                </Typography>
                <Typography className={classes.caption} style={{ marginTop: 10 }} variant="caption">
                  <span style={{ color: theme.branding.v2.blue[500] }}>FREE</span> first year
                </Typography>
                <Typography className={classes.caption} variant="caption">
                  $15/year after that
                </Typography>
              </Grid>
              <Grid item>
                <SimpleStyledRadio
                  checked={result === values.domain}
                  value={result}
                  onChange={async event => await setFieldValue('domain', event.target.value)}
                />
              </Grid>
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
};

export default Search;
