import React from 'react';
import { makeStyles, TextField, Theme } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import InputWrapper, { BaseInputProps } from '../InputWrapper';

interface Props extends BaseInputProps {
  className?: string;
  name?: string;
  onBlur?: (event: any) => void;
  placeholder?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    fontSize: 16,
    fontWeight: 400,
    padding: 12,
    zIndex: 1,
  },
  inputRoot: {
    height: 48,
    width: '100%',
    border: 'none',
    borderRadius: '12px',
  },
}));

const EmailInput: React.FC<Props> = (props: Props) => {
  const { error, label, secondaryLabel, name, onBlur, required, placeholder, className } = props;
  const classes = useStyles();

  const { handleBlur } = useFormikContext();

  // Allows us to use custom onBlur function while still calling formik's handleBlur
  const blurHandler = e => {
    if (onBlur) {
      onBlur(e);
    }
    if (handleBlur) {
      handleBlur(e);
    }
  };

  return (
    <InputWrapper label={label} secondaryLabel={secondaryLabel} required={required} error={error}>
      <Field
        style={{ margin: '0px' }}
        as={TextField}
        error={!!error}
        InputProps={{
          classes: {
            root: `${className && className} ${classes.inputRoot}`,
            input: classes.input,
          },
          notched: false,
        }}
        name={name}
        onBlur={blurHandler}
        placeholder={placeholder}
        type="email"
        variant="outlined"
      ></Field>
    </InputWrapper>
  );
};

export default EmailInput;
