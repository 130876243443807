import * as yup from 'yup';

export const attributionSchema = yup
  .object()
  .shape({
    source: yup.string().nullable(),
    campaignId: yup.string().nullable(),
    referringSourceUrl: yup.string().nullable(),
    utmParams: yup
      .object({
        source: yup.string(),
        medium: yup.string(),
        campaign: yup.string(),
        term: yup.string(),
        content: yup.string(),
      })
      .nullable(),
    gaTracking: yup
      .object({
        ga_client_id: yup.string().nullable(),
        ga_session_id: yup.string().nullable(),
      })
      .nullable(),
    anonymousId: yup.string().nullable(),
    userAgent: yup.string().nullable(),
    ip: yup.string().nullable(),
    entryUrl: yup.string().nullable(),
    entryType: yup
      .string()
      .oneOf(['campaign', 'seo', 'organic', 'direct', 'social'])
      .nullable(),
    tags: yup
      .array()
      .of(yup.string())
      .nullable(),
    trackingIds: yup
      .object({
        gmcId: yup.string().nullable(),
        gclId: yup.string().nullable(),
      })
      .nullable(),
  })
  .default(undefined);
