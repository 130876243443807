import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { CollapsableCard, TextAreaInput, TextInput } from '@castiron/components';
import { FulfillmentOption } from '@castiron/domain';
import DateTimeOptions from './DateTimeOptions';

const DeliveryDetails: React.FC = () => {
  const formik = useFormikContext<FulfillmentOption>();

  const [expanded, setExpanded] = useState<boolean>(true);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container direction="column">
      <Grid item style={{ marginTop: 24 }}>
        <DateTimeOptions fulfillmentType="delivery" />
      </Grid>
      <Grid item style={{ marginTop: 24 }}>
        <CollapsableCard title="Delivery Details" handleExpand={toggleExpand} expanded={expanded} noScroll>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <TextInput
                label="Neighborhood, Borough, or Zip Code"
                name="postalCode"
                required
                error={formik.touched.postalCode && formik.errors.postalCode}
              />
            </Grid>
            <Grid item>
              <TextAreaInput
                label="Details"
                secondaryLabel="Provide your customers with the info they need to make an informed selection at checkout."
                required
                name="description"
                rowCount={5}
                error={formik.touched.description && formik.errors.description}
              />
            </Grid>
          </Grid>
        </CollapsableCard>
      </Grid>
    </Grid>
  );
};

export default DeliveryDetails;
