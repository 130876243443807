import * as yup from 'yup';
import { BaseDocument } from '../base/repository';
import { idField } from '../base/model';
import { Asset, assetSchema } from '../asset/model';

export interface SelectedFieldValue {
  id: string;
  name: string;
  cost?: number;
}
export const selectedFieldValueSchema = yup.object({
  id: idField.required(),
  name: yup.string().required(),
  cost: yup.number(),
});

export interface SelectedAsset {
  id: string;
  title: string;
  asset: Asset;
  notes?: string;
}
export const selectedAssetSchema = yup.object({
  id: idField.required(),
  title: yup.string().required(),
  asset: assetSchema.required(),
  notes: yup.string(),
});

export interface InputField {
  id?: string;
  type: 'text' | 'select' | 'multiselect' | 'photo';
  name: string;
  notesRequired?: boolean;
  values?: SelectedFieldValue[];
  required: boolean;
  position?: number;
  description?: string;
  imageObj?: Asset;
}
export const inputFieldSchema = yup.object({
  id: idField,
  type: yup
    .string()
    .oneOf(['text', 'select', 'multiselect', 'photo'])
    .required(),
  name: yup.string().required(),
  notesRequired: yup.boolean(),
  values: yup.array().of(selectedFieldValueSchema),
  required: yup.boolean().required(),
  position: yup.number().nullable(),
  description: yup.string(),
  imageObj: assetSchema,
});

export interface SelectedInputFieldValue {
  inputFieldId: string;
  inputFieldName: string;
  selectedValues: SelectedFieldValue[];
  selectedAssets?: SelectedAsset[];
}
export const selectedInputFieldValueSchema = yup
  .object({
    inputFieldId: idField,
    inputFieldName: yup.string().required(),
    selectedValues: yup
      .array()
      .of(selectedFieldValueSchema)
      .required(),
    selectedAssets: yup.array().of(selectedAssetSchema),
  })
  .default(undefined);

export interface SelectedInputFields {
  selectedFields: SelectedInputFieldValue[];
}
export const selectedInputFieldsSchema = yup.object({
  selectedFields: yup.array(selectedInputFieldValueSchema).required(),
});

export interface PredefinedInputField extends BaseDocument<PredefinedInputField>, InputField {}
