import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { planRepository, tierRepository } from '../../domain';
import { PlanCardData, PlanContext } from './PlanContext';
import {
  Account,
  areSubscriptionFeaturesActive,
  BusinessGoal,
  BusinessStage,
  OnboardingQuestions,
  Plan,
} from '@castiron/domain';
import { useAppSelector } from '../../hooks';
import { UserState } from '../../store/reducers/shops';

interface Props {
  startingStep?: number;
  onSetStep?: (step: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({}));

const PRO_STAGES: BusinessStage[] = ['enterprise-business', 'full-time-entrepreneur'];

const PRO_GOALS: BusinessGoal[] = ['custom-order', 'manage-business'];

const BASIC_STAGES: BusinessStage[] = ['brand-new'];

const recommendPlan = (onboardingQuestions: OnboardingQuestions) => {
  if (!onboardingQuestions) return 'Pro';

  const { businessStage, goal } = onboardingQuestions;

  let recommendedPlan;
  if (
    (PRO_STAGES.includes(businessStage) || PRO_GOALS.some(proGoal => goal?.includes(proGoal))) &&
    !BASIC_STAGES.includes(businessStage)
  ) {
    recommendedPlan = 'Pro';
  } else {
    recommendedPlan = 'Basic';
  }

  return recommendedPlan;
};

const plansToPlanCardData = async (plans: Plan[], account: Account, userState: UserState): Promise<PlanCardData[]> => {
  const recommendedPlan = recommendPlan(account?.onboardingQuestions);
  const planCardData: Promise<PlanCardData>[] = plans.map(async plan => {
    const planTier = await tierRepository.get(plan.tier.id);

    return {
      plan,
      takeRate: planTier?.castironTakeRate,
      features: planTier?.features,
      isRecommended: userState !== 'currentSubscriber' && plan.name === recommendedPlan,
      isCurrentPlan: areSubscriptionFeaturesActive(account?.subscription)
        ? plan.id === account?.subscription.plan.id
        : plan.name === 'Starter',
      ctaText: 'Select',
      notes: plan.notes || [],
      comparisonItems: plan.planComparisonItems,
    };
  });

  return await Promise.all(planCardData);
};

const PlanFlowConfiguration: React.FC<Props> = props => {
  const { onSetStep, startingStep } = props;
  const classes = useStyles();
  const [planCardData, setPlanCardData] = useState<PlanCardData[]>([]);
  const [step, setStep] = useState<number>(startingStep || 0);
  const [planCardDataIsLoading, setPlanCardDataIsLoading] = useState<boolean>(false);

  const { account, userState } = useAppSelector(state => ({
    account: state.shops.account,
    userState: state.shops.userState,
  }));

  useEffect(() => {
    setPlanCardDataIsLoading(true);
    const getPlanCardData = async () => {
      const plans = await planRepository.list();
      const planCardData = await plansToPlanCardData(plans, account, userState);
      setPlanCardData(planCardData);
      setPlanCardDataIsLoading(false);
    };
    getPlanCardData();
  }, [account, userState]);

  const setStepWrapper = useCallback(
    (s: number | ((number) => number)) => {
      if (typeof s === 'function') {
        setStep(st => {
          const newStep = s(st);
          onSetStep && onSetStep(newStep);
          return newStep;
        });
      } else {
        setStep(s);
        onSetStep && onSetStep(s);
      }
    },
    [step, onSetStep],
  );

  return (
    <PlanContext.Provider
      value={{
        step,
        setStep: setStepWrapper,
        planCardData,
        planCardDataIsLoading,
      }}
    >
      {props.children}
    </PlanContext.Provider>
  );
};

export default PlanFlowConfiguration;
