import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const EmptyEventsIcon = (props: SvgIconProps) => (
  <SvgIcon width="172" height="112" viewBox="0 0 172 112" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="undraw_educator_re_ju47 1" clip-path="url(#clip0_321_12698)">
      <path
        id="Vector"
        d="M158.695 54.082H19.2225C17.353 54.082 15.832 52.5698 15.832 50.7108V3.37105C15.832 1.51224 17.353 0 19.2225 0H158.695C160.565 0 162.086 1.51224 162.086 3.37105V50.7108C162.086 52.5698 160.565 54.082 158.695 54.082ZM19.2225 0.401904C17.5759 0.401904 16.2362 1.7339 16.2362 3.37105V50.7108C16.2362 52.348 17.5759 53.6801 19.2225 53.6801H158.695C160.342 53.6801 161.682 52.348 161.682 50.7108V3.37105C161.682 1.7339 160.342 0.401904 158.695 0.401904H19.2225Z"
        fill="#ADCCFF"
      />
      <path id="uuid-dac6dc9f-03bc-4283-a90c-97bcdcbf45dc" d="M172 110.636H0V110.974H172V110.636Z" fill="#E6E6E6" />
      <path id="uuid-2f9646f7-bb94-4523-8d2e-fd8f0877664c" d="M172 93.3535H0V93.6917H172V93.3535Z" fill="#E6E6E6" />
      <g id="Group">
        <path
          id="uuid-bb35f39c-94aa-41c4-b5d9-34ca9a9e88f1-154"
          d="M78.385 55.823C78.5085 57.3663 79.4095 58.5539 80.3973 58.4756C81.3851 58.3973 82.0855 57.0829 81.9617 55.539C81.9208 54.9217 81.7318 54.323 81.4104 53.7932L80.8041 47.2607L77.7271 47.609L78.6515 54.0119C78.4185 54.5858 78.3271 55.2068 78.385 55.823Z"
          fill="#FFB6B6"
        />
        <path
          id="Vector_2"
          d="M74.709 25.347C74.709 25.347 77.5853 23.8331 79.0438 26.0955C80.5023 28.3579 81.509 52.8263 81.509 52.8263L77.4665 53.2555L76.7001 36.0132L74.709 25.347Z"
          fill="#E6E6E6"
        />
        <g id="Group_2">
          <path
            id="Vector_3"
            d="M68.7392 92.0774L71.0811 92.0772L72.1952 83.0957L68.7388 83.0959L68.7392 92.0774Z"
            fill="#FFB6B6"
          />
          <path
            id="Vector_4"
            d="M76.7626 92.0246C76.8355 92.1467 76.874 92.5408 76.874 92.6828C76.874 93.1193 76.5182 93.4731 76.0792 93.4731H68.8266C68.5272 93.4731 68.2844 93.2317 68.2844 92.9339V92.6337C68.2844 92.6337 67.9256 91.7314 68.6643 90.6192C68.6643 90.6192 69.5823 91.4901 70.9541 90.1261L71.3587 89.3975L74.2869 91.5268L75.91 91.7255C76.2651 91.7689 76.58 91.7187 76.7626 92.0246Z"
            fill="#2F2E41"
          />
        </g>
        <g id="Group_3">
          <path
            id="Vector_5"
            d="M82.2913 91.6919L84.5603 91.1153L83.404 82.1392L80.0552 82.99L82.2913 91.6919Z"
            fill="#FFB6B6"
          />
          <path
            id="Vector_6"
            d="M90.0523 89.6662C90.1533 89.7666 90.2887 90.1389 90.324 90.2765C90.4327 90.6994 90.176 91.1298 89.7506 91.2378L82.7237 93.0226C82.4336 93.0963 82.1383 92.9222 82.0641 92.6337L81.9894 92.3428C81.9894 92.3428 81.4172 91.5568 81.856 90.2975C81.856 90.2975 82.9623 90.9153 83.9519 89.2562L84.1624 88.4507L87.5297 89.7932L89.1517 89.5862C89.5066 89.5409 89.7991 89.4148 90.0523 89.6662Z"
            fill="#003080"
          />
        </g>
        <path
          id="Vector_7"
          d="M66.802 23.4163L66.6909 22.6151L68.0107 19.4104L71.6368 19.0098L72.4197 23.5978L66.802 23.4163Z"
          fill="#FFB6B6"
        />
        <path
          id="Vector_8"
          opacity="0.1"
          d="M66.802 23.4163L66.6909 22.6151L68.0107 19.4104L71.6368 19.0098L72.4197 23.5978L66.802 23.4163Z"
          fill="black"
        />
        <path
          id="Vector_9"
          d="M62.5711 46.05L60.9595 50.4566L62.1682 52.8601L68.2117 87.7121H72.2296L71.4349 56.8661L80.7016 87.7121H84.6782L81.4804 66.881L79.09 47.6524L77.6401 46.7618L70.6291 42.8452L62.5711 46.05Z"
          fill="#003080"
        />
        <path
          id="Vector_10"
          d="M72.8452 49.0545L70.4278 49.4551L67.2046 41.8438H74.4568L72.8452 49.0545Z"
          fill="#E6E6E6"
        />
        <path
          id="Vector_11"
          d="M77.6799 47.0517L72.8451 49.0546L71.2335 45.4493L70.4277 49.4552L61.9668 46.7472L63.1755 41.8439L61.9668 25.82L66.2335 22.9918L66.8016 22.6152L72.0393 23.0158L72.4986 23.2642L75.6654 25.0188L76.4712 38.2385L77.6799 47.0517Z"
          fill="#E6E6E6"
        />
        <path
          id="Vector_12"
          d="M72.499 23.2642L70.2266 32.8305L66.2339 22.9918L66.802 22.6152L72.0397 23.0158L72.499 23.2642Z"
          fill="#E6E6E6"
        />
        <path
          id="Vector_13"
          d="M69.8236 21.814C71.9375 21.814 73.6512 20.1101 73.6512 18.0083C73.6512 15.9065 71.9375 14.2026 69.8236 14.2026C67.7097 14.2026 65.9961 15.9065 65.9961 18.0083C65.9961 20.1101 67.7097 21.814 69.8236 21.814Z"
          fill="#FFB6B6"
        />
        <path
          id="Vector_14"
          d="M67.3774 18.342L68.2044 18.7009L68.5653 16.5476C68.5653 16.5476 70.009 15.8299 71.0918 16.1888C72.1746 16.5476 73.9793 16.1888 73.9793 16.1888C73.9793 16.1888 73.9793 12.6001 71.4528 12.6001C68.9263 12.6001 66.3997 12.959 66.3997 14.0356C66.3997 15.1122 65.3169 16.1888 65.3169 16.1888C65.3169 16.1888 63.8732 18.342 65.3169 19.7775C66.7607 21.2129 67.4825 21.2129 67.4825 21.2129L67.3774 18.342Z"
          fill="#2F2E41"
        />
        <path
          id="uuid-97233535-ec91-4408-928f-bf1e5273ca3e-155"
          d="M59.9504 55.7107C59.9084 57.2583 60.6775 58.5343 61.6681 58.5607C62.6586 58.5871 63.4954 57.354 63.5372 55.8059C63.5624 55.1876 63.4384 54.5724 63.1754 54.0117L63.27 47.4521L60.1731 47.4736L60.4087 53.9379C60.1157 54.484 59.9586 55.0919 59.9504 55.7107Z"
          fill="#FFB6B6"
        />
        <path
          id="Vector_15"
          d="M63.7819 25.011C63.7819 25.011 61.1631 24.2098 59.9544 26.6134C58.7457 29.017 60.3573 53.4534 60.3573 53.4534H64.4228L63.3438 36.2277L63.7819 25.011Z"
          fill="#E6E6E6"
        />
      </g>
      <g id="Group_4">
        <path
          id="Vector_16"
          d="M164.244 70.5359L154.478 67.5634C154.261 67.4976 154.035 67.4736 153.81 67.4927L104.313 71.681C103.608 71.7408 103.43 72.6872 104.067 72.9956L105.652 73.7636H105.61V110.528H107.104V74.4668L112.073 76.8737C112.453 77.0579 112.878 77.1331 113.298 77.0908L113.827 77.0377V110.528H115.321V76.8877L153.604 73.0428V110.528H155.098V72.8928L161.447 72.2551V110.528H162.941V72.105L164.1 71.9887C164.913 71.907 165.025 70.7738 164.244 70.5359Z"
          fill="#E0ECFF"
        />
        <path
          id="Vector_17"
          d="M118.683 71.5354C119.616 71.5354 133.436 69.8643 133.436 69.8643L135.49 72.6495L121.11 75.4346L118.683 71.5354Z"
          fill="white"
        />
        <g id="Group_5">
          <path
            id="Vector_18"
            d="M133.849 109.1L131.448 109.096L130.322 99.8857L133.866 99.892L133.849 109.1Z"
            fill="#A0616A"
          />
          <path
            id="Vector_19"
            d="M125.624 109.032C125.549 109.157 125.509 109.561 125.508 109.706C125.508 110.154 125.872 110.517 126.322 110.518L133.757 110.53C134.064 110.531 134.313 110.284 134.314 109.979L134.315 109.671C134.315 109.671 134.684 108.746 133.929 107.605C133.929 107.605 132.986 108.496 131.582 107.095L131.168 106.348L128.163 108.526L126.498 108.726C126.134 108.77 125.811 108.718 125.624 109.032Z"
            fill="#003080"
          />
        </g>
        <g id="Group_6">
          <path
            id="Vector_20"
            d="M146.735 104.817L144.841 106.238L138.428 99.6494L141.224 97.5527L146.735 104.817Z"
            fill="#A0616A"
          />
          <path
            id="Vector_21"
            d="M140.213 109.642C140.229 109.785 140.44 110.128 140.527 110.242C140.795 110.595 141.3 110.666 141.655 110.399L147.521 106C147.763 105.818 147.811 105.475 147.629 105.234L147.444 104.992C147.444 104.992 147.181 104.044 145.901 103.593C145.901 103.593 145.693 104.854 143.746 104.583L142.972 104.239L141.91 107.738L140.719 108.883C140.459 109.134 140.173 109.284 140.213 109.642Z"
            fill="#003080"
          />
        </g>
        <path
          id="Vector_22"
          d="M141.717 57.2189C141.717 59.4203 139.923 61.2049 137.709 61.2049C135.495 61.2049 133.7 59.4203 133.7 57.2189C133.7 55.0175 135.495 53.2329 137.709 53.2329C139.923 53.2329 141.717 55.0175 141.717 57.2189Z"
          fill="#A0616A"
        />
        <path
          id="Vector_23"
          d="M141.34 80.2013C141.34 80.2013 126.579 79.3706 125.744 81.032C124.908 82.6935 130.261 105.515 130.261 105.515L135.036 104.52L132.706 88.5084C132.706 88.5084 145.023 91.7518 146.694 87.8752C148.365 83.9985 147.884 82.555 147.884 82.555L141.34 80.2013Z"
          fill="#003080"
        />
        <path
          id="Vector_24"
          d="M145.121 77.4126C145.121 77.4126 130.666 80.4938 130.302 82.3157C129.938 84.1377 141.172 104.738 141.172 104.738L145.512 102.523L139.006 87.6946C139.006 87.6946 151.747 87.5827 152.327 83.4047C152.907 79.2266 152.059 77.961 152.059 77.961L145.121 77.4126Z"
          fill="#003080"
        />
        <path
          id="Vector_25"
          d="M146.771 61.7871H139.826L135.074 69.5404C135.074 69.5404 133.96 74.5509 135.074 75.0916C136.188 75.6323 136.188 81.4473 136.188 81.4473L147.885 82.5549C147.885 82.5549 149.555 65.11 148.998 63.4485C148.442 61.7871 146.771 61.7871 146.771 61.7871Z"
          fill="#005FFF"
        />
        <path
          id="Vector_26"
          d="M135.77 66.9097L135.073 75.0914L127.242 71.7868L125.373 70.8522C125.007 70.3776 124.532 69.997 123.988 69.7424C122.634 69.0865 121.197 69.2489 120.777 70.1051C120.358 70.9613 121.114 72.1869 122.468 72.8424C123.005 73.1118 123.599 73.2494 124.201 73.2436L129.67 76.3318L129.676 76.3197L129.7 76.3628L138.276 79.9241L140.249 69.1834L135.77 66.9097Z"
          fill="#A0616A"
        />
        <path
          id="Vector_27"
          d="M134.934 57.772C134.934 57.772 130.757 58.3258 132.428 54.726C132.428 54.726 132.173 52.7746 133.414 52.6427C134.656 52.5108 136.327 50.2956 136.327 50.2956C136.327 50.2956 140.158 48.3572 142.141 50.2956C144.125 52.2339 149.973 63.5869 149.973 63.5869L146.074 67.7405C146.074 67.7405 143.73 61.6137 141.003 62.3234C138.276 63.0331 135.77 65.1492 135.77 65.1492C135.77 65.1492 133.002 63.7721 134.247 62.5719C135.491 61.3717 134.934 57.772 134.934 57.772Z"
          fill="#2F2E41"
        />
        <path
          id="Vector_28"
          d="M140.504 63.8637C140.504 63.8637 138.555 61.3716 137.162 63.3099C135.77 65.2482 134.656 67.7404 134.656 67.7404L140.226 69.6787L140.504 63.8637Z"
          fill="#005FFF"
        />
        <path
          id="Vector_29"
          d="M161.652 109.977L155.181 89.5257L155.182 89.5256H155.197L155.171 89.4922L155.17 89.4914L154.709 88.0348L154.71 88.0346C155.412 87.433 155.89 86.5733 155.985 85.5836L153.027 69.4072C153.231 67.283 151.593 65.4263 149.447 65.3501L142.773 65.3334C140.875 65.2666 139.232 66.6294 138.956 68.4974L138.808 69.4889L136.763 83.3202C136.689 83.8178 136.718 84.308 136.836 84.7666L135.365 84.9579L134.918 85.0173L133.153 85.2457L133.148 85.2475L132.928 85.2754H132.688V91.2171H132.703V110.208C132.703 110.331 132.804 110.431 132.928 110.431C133.053 110.431 133.153 110.331 133.153 110.208V91.2171H135.772L138.384 110.186C138.401 110.298 138.496 110.379 138.608 110.379C138.618 110.379 138.629 110.379 138.638 110.377C138.761 110.361 138.847 110.247 138.831 110.125L136.227 91.2171H148.79C150.037 91.2171 151.193 90.8235 152.134 90.1496L157.115 110.151C157.141 110.253 157.232 110.32 157.333 110.32C157.351 110.32 157.37 110.318 157.388 110.313C157.509 110.282 157.582 110.161 157.551 110.041L152.522 89.8459C152.589 89.7905 152.656 89.7314 152.719 89.6704L154.718 89.5535L161.223 110.111C161.254 110.206 161.342 110.267 161.438 110.267C161.46 110.267 161.483 110.264 161.505 110.257C161.624 110.22 161.69 110.094 161.652 109.977ZM154.282 88.3521C154.299 88.3428 154.314 88.3317 154.329 88.3224L154.377 88.4741L154.282 88.3521Z"
          fill="#E0ECFF"
        />
      </g>
      <g id="Group_7">
        <g id="Group_8">
          <path
            id="Vector_30"
            d="M34.6748 75.3114L39.03 72.5576L40.8006 73.6196L34.6748 80.6183V75.3114Z"
            fill="#FFB6B6"
          />
          <path
            id="Vector_31"
            d="M28.7189 64.6527C28.7189 64.6527 25.8262 65.3295 27.1875 69.2208C28.5488 73.1122 32.973 81.2333 34.3343 80.7257C35.6956 80.2181 35.8658 75.1425 35.8658 75.1425C35.8658 75.1425 35.6956 75.3116 35.0149 74.6349C34.3343 73.9581 34.3343 74.2672 34.3343 73.5205C34.3343 72.7738 33.7776 72.2203 33.7776 72.2203L33.1432 70.9127C33.1432 70.9127 33.1432 64.8219 30.5907 64.4835C28.0383 64.1451 28.7189 64.6527 28.7189 64.6527Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_32"
            d="M42.2339 73.438C43.3636 72.3148 43.7137 70.8418 43.016 70.148C42.3182 69.4543 40.8368 69.8024 39.7071 70.9257C38.5774 72.0489 38.2273 73.5219 38.925 74.2156C39.6228 74.9094 41.1042 74.5612 42.2339 73.438Z"
            fill="#FFB6B6"
          />
        </g>
        <g id="Group_9">
          <path
            id="Vector_33"
            d="M17.4517 87.07L20.4235 91.9608L37.7419 90.9419L33.1305 85.0322L17.4517 87.07Z"
            fill="#E0ECFF"
          />
          <path
            id="Vector_34"
            d="M22.6263 109.945C22.5255 109.945 22.4376 109.871 22.4235 109.769L17.2485 72.1892C17.2331 72.0776 17.3116 71.9748 17.4237 71.9596C17.5358 71.9435 17.6392 72.0223 17.6546 72.1338L22.8296 109.713C22.845 109.825 22.7665 109.928 22.6544 109.943C22.6449 109.944 22.6356 109.945 22.6263 109.945Z"
            fill="#E0ECFF"
          />
          <path
            id="Vector_35"
            d="M17.4515 109.992C17.3383 109.992 17.2466 109.901 17.2466 109.789V72.1686C17.2466 72.0561 17.3384 71.9648 17.4515 71.9648C17.5647 71.9648 17.6565 72.0561 17.6565 72.1686V109.789C17.6565 109.901 17.5647 109.992 17.4515 109.992Z"
            fill="#E0ECFF"
          />
          <path
            id="Vector_36"
            d="M43.4293 109.843C43.3422 109.843 43.2614 109.787 43.2339 109.7L30.988 70.9999C30.954 70.8925 31.014 70.7781 31.122 70.7442C31.2299 70.7106 31.3451 70.7702 31.379 70.8775L43.6249 109.578C43.6588 109.685 43.5988 109.8 43.4909 109.833C43.4704 109.84 43.4497 109.843 43.4293 109.843Z"
            fill="#E0ECFF"
          />
          <path
            id="Vector_37"
            d="M39.6889 109.89C39.5973 109.89 39.514 109.829 39.4904 109.737L30.9849 75.5812C30.957 75.4721 31.0233 75.3612 31.133 75.3334C31.243 75.3059 31.3543 75.3716 31.3822 75.4807L39.8877 109.636C39.9155 109.745 39.8492 109.856 39.7396 109.884C39.7226 109.888 39.7056 109.89 39.6889 109.89Z"
            fill="#E0ECFF"
          />
          <path
            id="Vector_38"
            d="M17.4517 72.7678L31.6446 72.5508L34.2577 87.3758H17.6566L17.4517 72.7678Z"
            fill="#E0ECFF"
          />
        </g>
        <g id="Group_10">
          <path
            id="Vector_39"
            d="M39.2624 94.5674L35.6855 100.829L39.8386 101.738L41.57 97.2845L39.2624 94.5674Z"
            fill="#FFB6B6"
          />
          <path
            id="Vector_40"
            d="M36.9703 99.6468C36.9703 99.6468 36.8313 98.6257 36.0142 98.9498C35.1971 99.2738 32.8389 100.478 32.8389 100.478C32.8389 100.478 38.9897 110.415 40.157 109.952C41.3243 109.489 39.927 106.68 39.927 106.68L40.2354 101.715L36.9703 99.6468Z"
            fill="#003080"
          />
          <path
            id="Vector_41"
            d="M23.444 82.4177C23.444 82.4177 22.1678 88.2548 26.0815 90.285C29.9952 92.3153 43.3529 87.3242 43.3529 87.3242L37.2271 96.4505L41.4812 98.1524C41.4812 98.1524 51.1804 85.4631 50.4997 83.4329C49.8191 81.4026 45.2247 81.2334 45.2247 81.2334L34.8448 81.741L32.1222 80.895L23.444 82.4177Z"
            fill="#003080"
          />
        </g>
        <g id="Group_11">
          <path
            id="Vector_42"
            d="M33.9708 97.3462L28.3477 101.884L31.8833 104.234L35.1258 100.712L33.9708 97.3462Z"
            fill="#FFB6B6"
          />
          <path
            id="Vector_43"
            d="M29.9771 101.248C29.9771 101.248 30.2214 100.246 29.3417 100.252C28.462 100.259 25.8247 100.527 25.8247 100.527C25.8247 100.527 27.9171 112.008 29.1738 112C30.4305 111.991 30.157 108.869 30.157 108.869L32.2614 104.356L29.9771 101.248Z"
            fill="#003080"
          />
          <path
            id="Vector_44"
            d="M23.6832 80.3062C23.6832 80.3062 20.3582 85.2813 23.2606 88.5884C26.163 91.8955 40.4316 92.0794 40.4316 92.0794L31.3857 98.3637L34.7253 101.488C34.7253 101.488 48.4035 93.1779 48.5126 91.0406C48.6216 88.9032 44.4041 87.0834 44.4041 87.0834L34.5501 83.8007L32.3237 82.0277L23.6832 80.3062Z"
            fill="#003080"
          />
        </g>
        <path
          id="Vector_45"
          d="M32.2246 68.7825C32.2246 68.7825 28.9915 67.5982 27.4601 67.5982C27.3137 67.5982 27.1691 67.6151 27.0244 67.6456C26.0443 67.852 25.1425 68.6895 24.7102 69.1463C24.5418 69.3256 24.4448 69.4457 24.4448 69.4457C24.4448 69.4457 24.455 69.329 24.4754 69.1243C24.5758 68.0482 24.9468 64.5173 25.6121 62.2569C25.8503 61.4498 26.1243 60.8052 26.4391 60.4922C27.1725 59.7613 27.5111 58.8849 27.6676 58.2319C27.8004 57.6718 27.8004 57.2776 27.8004 57.2776L26.5599 56.7464L26.3302 55.2541C26.2893 55.4301 26.1005 55.7735 25.4658 56.4317C25.4113 56.4875 25.3517 56.5349 25.2888 56.5755C24.3733 57.1474 22.5066 56.1525 21.501 55.5265C21.9196 54.0799 22.6785 52.7569 24.0568 52.5403C27.2899 52.0328 27.2899 50.1717 29.3318 52.2019C29.5003 52.3694 29.6705 52.5302 29.8406 52.6858C30.494 53.9277 30.7323 55.5925 30.8174 56.7498C30.8786 57.5602 30.8633 58.1236 30.8633 58.1236C30.8633 58.1236 31.7141 63.1993 33.0754 64.2144C34.4367 65.2296 32.2246 68.7825 32.2246 68.7825Z"
          fill="#2F2E41"
        />
        <path id="Vector_46" d="M25.9286 60.6616L23.6294 64.0148L28.8213 65.3989V60.6616H25.9286Z" fill="#FFB6B6" />
        <path
          id="Vector_47"
          opacity="0.1"
          d="M25.9286 60.6616L23.6294 64.0148L28.8213 65.3989V60.6616H25.9286Z"
          fill="black"
        />
        <path
          id="Vector_48"
          d="M29.1442 63.8911L24.2095 61.8608C24.2095 61.8608 17.5732 64.7371 18.0837 70.3203C18.5942 75.9036 23.3587 85.5474 23.3587 85.5474L32.8878 81.4868L31.8668 70.6587L29.1442 63.8911Z"
          fill="#E6E6E6"
        />
        <path
          id="Vector_49"
          d="M27.0284 62.1584C29.4328 62.1584 31.382 60.2204 31.382 57.8297C31.382 55.439 29.4328 53.501 27.0284 53.501C24.624 53.501 22.6748 55.439 22.6748 57.8297C22.6748 60.2204 24.624 62.1584 27.0284 62.1584Z"
          fill="#FFB6B6"
        />
        <path
          id="Vector_50"
          d="M32.9051 56.2625C32.0543 57.1084 31.3736 57.9544 31.3736 57.9544L31.0333 56.6009C31.0333 56.6009 30.955 56.6584 30.8172 56.7498C30.2778 57.1152 28.8297 58.0339 27.6675 58.2319C27.1502 58.3198 26.6908 58.264 26.3913 57.9544C25.7566 57.2962 25.5677 56.9528 25.5269 56.7768L25.2971 58.2691L24.0567 58.8003C24.0567 58.8003 24.0567 60.658 25.418 62.0149C25.4843 62.0809 25.549 62.1621 25.6119 62.2569C26.262 63.2314 26.7282 65.617 27.0243 67.6456C27.2863 69.4491 27.4123 70.9685 27.4123 70.9685C27.4123 70.9685 26.1241 69.3645 24.7101 69.1463C24.6318 69.1344 24.5535 69.126 24.4753 69.1243C24.4497 69.1209 24.4225 69.1209 24.397 69.1209C22.8655 69.1209 19.6325 70.3052 19.6325 70.3052C19.6325 70.3052 17.4203 66.7523 18.7816 65.7371C20.1429 64.722 20.9937 59.6463 20.9937 59.6463C20.9937 59.6463 20.9376 57.4739 21.5008 55.5265C21.9194 54.0799 22.6784 52.7569 24.0567 52.5403C27.2897 52.0328 27.2897 50.1717 29.3317 52.2019C29.5001 52.3694 29.6703 52.5302 29.8405 52.6858C31.7429 54.4251 33.6861 55.4859 32.9051 56.2625Z"
          fill="#2F2E41"
        />
        <path id="Vector_51" d="M13.8107 78.9741H12.1938V110.725H13.8107V78.9741Z" fill="#E0ECFF" />
        <path id="Vector_52" d="M53.0197 78.9741H51.4028V110.725H53.0197V78.9741Z" fill="#E0ECFF" />
        <path id="Vector_53" d="M42.7122 65.5103H32.0005V76.1607H42.7122V65.5103Z" fill="#005FFF" />
        <g id="Group_12">
          <path
            id="Vector_54"
            d="M25.8262 76.8339L30.1814 74.0801L31.952 75.142L25.8262 82.1407V76.8339Z"
            fill="#FFB6B6"
          />
          <path
            id="Vector_55"
            d="M19.8703 66.1752C19.8703 66.1752 16.9776 66.8519 18.3389 70.7433C19.7002 74.6346 24.1244 82.7557 25.4857 82.2482C26.847 81.7406 27.0171 76.6649 27.0171 76.6649C27.0171 76.6649 26.847 76.8341 26.1663 76.1573C25.4857 75.4806 25.4857 75.7897 25.4857 75.043C25.4857 74.2963 24.929 73.7427 24.929 73.7427L24.2945 72.4352C24.2945 72.4352 24.2945 66.3443 21.7421 66.006C19.1897 65.6676 19.8703 66.1752 19.8703 66.1752Z"
            fill="#E6E6E6"
          />
          <path
            id="Vector_56"
            d="M33.3853 74.9605C34.5149 73.8372 34.8651 72.3643 34.1673 71.6705C33.4696 70.9767 31.9882 71.3249 30.8585 72.4481C29.7288 73.5714 29.3786 75.0443 30.0764 75.7381C30.7741 76.4319 32.2556 76.0837 33.3853 74.9605Z"
            fill="#FFB6B6"
          />
        </g>
        <path id="Vector_57" d="M56.2532 77.7681H7.34326V81.3852H56.2532V77.7681Z" fill="#E0ECFF" />
      </g>
      <path
        id="Vector_58"
        d="M105.945 48.8315H98.7219C98.1492 48.8315 97.6851 49.2931 97.6851 49.8624V52.8245C97.6851 53.3938 98.1492 53.8553 98.7219 53.8553H105.945C106.518 53.8553 106.982 53.3938 106.982 52.8245V49.8624C106.982 49.2931 106.518 48.8315 105.945 48.8315Z"
        fill="#ADCCFF"
      />
      <path
        id="Vector_59"
        d="M121.103 51.7935H113.88C113.307 51.7935 112.843 52.255 112.843 52.8243V52.8243C112.843 53.3937 113.307 53.8552 113.88 53.8552H121.103C121.676 53.8552 122.14 53.3937 122.14 52.8243C122.14 52.255 121.676 51.7935 121.103 51.7935Z"
        fill="#ADCCFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_321_12698">
        <rect width="172" height="112" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
