import { Shop } from '@castiron/domain';

const artisanThemes = {
  // theme is the key and then category.name or subcategory.name that maps to the theme
  baking: [
    'bagels',
    'bread',
    'sweet pastries',
    'savory pastries',
    'other baked goods',
    'cookies',
    'cakes',
    'cupcakes',
    'breads',
    'sweet pastries',
    'baked goods',
    'desserts & baked goods',
    'pasta-grains',
  ],
  beverages: [
    'coffee',
    'tea',
    'juices',
    'other beverages',
    'juices',
    'coffee/tea',
    'other beverages',
    'beverages',
    'drinks',
    'coffee-tea',
  ],
  classes: ['cooking classes'],
  charcuterie: ['charcuterie boards', 'charcuterie'],
  desserts: [
    'candy',
    'cakes',
    'cupcakes',
    'cookies',
    'other desserts',
    'chocolate',
    'ice cream',
    'ice-cream',
    'other desserts',
    'desserts',
    'desserts & baked goods',
    'chocolates',
  ],
  'prepared foods': [
    'event catering',
    'meal prep',
    'soups',
    'ready-to-eat meals',
    'other meals',
    'event catering',
    'ready to eat meals',
    'meal prep.',
    'meals',
    'prepared meals',
    'hot food',
    'personal-chef',
    'prepared-meals',
    'meal-prep',
  ],
  'sauces & spices': [
    'honey',
    'hot sauce',
    'spice mixes',
    'jams, jellies, preserves',
    'other sauce or spices',
    'sauces, spices, & more',
    'shelf-stable products',
    'syrups',
    'hot-sauce-salsas',
  ],
  snacks: [
    'popcorn',
    'pickled products',
    'snack mixes',
    'other snacks',
    'bagels',
    'pickled products',
    'savory pastries',
    'snacks',
    'appetizers & snacks',
  ],
  wholesaler: ['whole ingredients'],
  other: ['other', 'dairy', 'meat'],
};
const formatArtisanCategory = (shop: Shop): string[] => {
  let themes = [];
  if (shop.artisanCategory?.name) {
    // onboarding v2, v3, and v4 shops
    const cat = shop.artisanCategory.name.toLowerCase();
    const subcats = shop.artisanCategory.subcategories;

    for (const theme in artisanThemes) {
      if (artisanThemes[theme].includes(cat)) {
        themes.push(theme);
      }
      if (subcats) {
        subcats.forEach(subcat => {
          if (artisanThemes[theme].includes(subcat)) {
            themes.push(theme);
          }
        });
      }
    }
  } else if (shop.productTypes?.length) {
    // legacy onboarding v1 shops
    shop.productTypes.forEach(type => {
      for (const theme in artisanThemes) {
        if (artisanThemes[theme].includes(type.toLowerCase())) {
          themes.push(theme);
        }
      }
    });
  } else {
    themes.push('other');
  }

  return themes;
};

export default formatArtisanCategory;
