import React, { ReactNode } from "react";
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Box } from "@material-ui/core";
import { PageContainer } from "@castiron/components";

interface Props {
  helmetTitle: string;
  children: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    width: '100%',
    padding: '24px 16px',
  },
}));

const AdminPageContainer: React.FC<Props> = (props: Props) => {
  const { helmetTitle, children } = props;
  const classes = useStyles();

  return (
    <PageContainer helmetTitle={`${helmetTitle} | Castiron`}>
      <Box className={classes.pageContainer}>
        {children}
      </Box>
    </PageContainer>
  );
};

export default AdminPageContainer;