import React, { useEffect } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { ProductCard, Typography } from '@castiron/components';
import { ProductPageContext, ProductTemplate } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { openModal } from '../../store/reducers/modalConductor';

export interface categoryTemplates {
  categoryName: string;
  categoryTemplates: ProductTemplate[];
}

type Props = {
  context: ProductPageContext;
  templates?: categoryTemplates[];
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: 24,
    [theme.breakpoints.down('sm')]: {
      padding: '24px 16px',
    },
  },
  header: {
    background: theme.branding.blue.light,
    borderRadius: 16,
    maxHeight: 240,
    padding: '0px 32px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 16px',
      maxHeight: 112,
    },
  },
  headerText: {
    margin: '64px 0px',
    [theme.breakpoints.down('xs')]: {
      margin: '24px 0px',
    },
  },
  image: {
    [theme.breakpoints.down('xs')]: {
      height: '80%',
    },
  },
  imageContainer: {
    marginLeft: 8,
    width: 'fit-content',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 110,
    },
  },
  linkText: {
    color: theme.branding.blue.primary,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  templatesContainer: {
    gap: 16,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(192px, 1fr))',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(172px, 1fr))',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
    },
  },
}));

const Templates: React.FC<Props> = (props: Props) => {
  const { context, templates } = props;

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { trackEvent } = useTracking();

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openAddProductModal = () => {
    dispatch(
      openModal({
        modalType: 'ADD_PRODUCT_MODAL',
        modalProps: {
          show: true,
          context,
        },
      }),
    );
  };

  const openPreviewModal = (template: ProductTemplate) => {
    trackEvent('Template Preview Opened', { productTemplateId: template.id });
    const placeholders = template.placeholders;
    const product = template.template;
    /* overwrite variation from placeholders for preview */
    const variations = product.variations?.map(variation => {
      return {
        ...variation,
        values: variation.values.map(valueObj => ({ ...valueObj, name: placeholders[valueObj.id] || valueObj.name })),
      };
    });
    const previewProduct = { ...product, variations };
    dispatch(
      openModal({
        modalType: template.template.type === 'standard' ? 'PRODUCT_MODAL' : 'CUSTOM_PRODUCT_MODAL',
        modalProps: {
          show: true,
          product: previewProduct,
          templateId: template.id,
        },
      }),
    );
  };

  return (
    <Grid container className={classes.container}>
      <Grid container wrap="nowrap" className={classes.header}>
        <Grid container item direction="column" justify="center" className={classes.headerText} wrap="nowrap">
          <Grid item>
            <Typography variant={isMobile ? 'h2' : 'h1'}>
              {context === 'order-forms' ? 'Order Form' : 'Product'} Templates Baked For Any Occasion
            </Typography>
          </Grid>
          {!isMobile && (
            <Grid item style={{ marginTop: 8 }}>
              <Typography variant="body1">
                Build your {context === 'order-forms' ? 'order forms' : 'products'} faster with pre-filled questions,
                descriptions, photos, and more. Prefer to start from scratch?{' '}
                <span className={classes.linkText} onClick={openAddProductModal}>
                  Start Here
                </span>
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid container item className={classes.imageContainer} alignItems="flex-end" justify="flex-end">
          <img src="/assets/img/products/templates-header.png" className={classes.image} />
        </Grid>
      </Grid>
      {templates.map((category, index) => {
        const header = (
          <Typography style={{ marginBottom: 8 }} variant="h2">
            {category.categoryName}
          </Typography>
        );
        const templates = category.categoryTemplates.map((template, index) => {
          return (
            <ProductCard
              key={`productCard-${template?.id}-${index}`}
              onClick={() => openPreviewModal(template)}
              product={template.template}
              type="template"
              timeZone={shop?.config?.timeZone}
            />
          );
        });
        return (
          <Grid container key={`${category.categoryName}-${index}`} style={{ marginTop: 24 }}>
            {header}
            <Grid className={classes.templatesContainer} container>
              {templates}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Templates;
