import { Attribution, EntryType, getSource, Session, TrafficSource } from '@castiron/domain';
import { getGATracking, SESSION_ATTRIBUTION_KEY } from './index';
import axios from 'axios';
import { removeEmpty } from '../removeEmpty';
import getCookie from '../getCookie';
import { nanoid } from 'nanoid';

declare global {
  interface Window {
    analytics: any;
  }
}

const getSessionService = async () => {
  const resp = await axios.get('/session/get', {
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });
  return resp.data as Session;
};

const getAnonId = () => {
  let id = nanoid();
  console.debug(`Generated ID [${id}]`);
  try {
    if (window?.analytics) {
      const user = window.analytics.user();
      id = user.anonymousId();
      console.debug(`Segment anon ID [${id}]`, {
        user,
      });
    } else {
      id = getCookie('ajs_anonymous_id');
      console.debug(`Cookie anon ID [${id}]`);
    }

    if (!id) {
      id = window.analytics.user().anonymousId();
    }
  } catch (err) {
    console.error('Error finding anonymous ID', err);
  }
  return id;
};

const hasUTMParams = (params: URLSearchParams) =>
  !!Array.from(params.keys()).find(k => k.toLowerCase().startsWith('utm_'));

const SEO_SOURCES: TrafficSource[] = ['google', 'bing'];
const SOCIAL_SOURCES: TrafficSource[] = ['facebook', 'instagram', 'linkedin', 'tiktok', 'twitter'];

export const propagateAttribution = async (config: { gaStreamId: string }) => {
  if (typeof window === 'undefined') return;

  let attribution: Attribution;
  try {
    const attributionJson = sessionStorage.getItem(SESSION_ATTRIBUTION_KEY);
    if (attributionJson) {
      attribution = JSON.parse(attributionJson);
    } else {
      const session = await getSessionService();

      if (session.attribution) {
        attribution = session.attribution;
        sessionStorage.setItem(SESSION_ATTRIBUTION_KEY, JSON.stringify(attribution));
      } else {
        const referrer = window.document.referrer;
        const params = new URLSearchParams(window.location.search);

        //GMC tracking
        const paramSource = params.get('src');
        const isGMC = !!paramSource && paramSource === 'gmc';
        const gmcId = params.get('srsltid');

        const anonId = getAnonId();
        const source = isGMC ? 'gmc' : getSource(referrer);
        const gclId = params.get('gclid');

        let entryType: EntryType;
        if (isGMC) {
          //TODO: update once we have GMC ad posts
          entryType = 'organic';
        } else if (hasUTMParams(params)) {
          entryType = 'campaign';
        } else if (SEO_SOURCES.includes(source)) {
          entryType = 'seo';
        } else if (SOCIAL_SOURCES.includes(source)) {
          entryType = 'social';
        } else if (source !== 'castiron') {
          entryType = 'direct';
        } else {
          entryType = 'organic';
        }

        attribution = removeEmpty({
          anonymousId: anonId,
          referringSourceUrl: referrer,
          source,
          userAgent: window && window.navigator.userAgent,
          entryUrl: window && window.location.href,
          entryType,
          trackingIds: {
            gmcId, //GMC "slstid"; for tracking auto-tagged GMC listing clicks
            gclId, //google click identifier id; for tracking google ad clicks
          },
          utmParams: {
            campaign: params.get('utm_campaign'),
            source: params.get('utm_source'),
            term: params.get('utm_term'),
            medium: params.get('utm_medium'),
            content: params.get('utm_content'),
          },
        } as Attribution);
        sessionStorage.setItem(SESSION_ATTRIBUTION_KEY, JSON.stringify(attribution));
      }
    }

    const gaTracking = getGATracking(config.gaStreamId);

    return {
      ...attribution,
      gaTracking,
    };
  } catch (err) {
    console.error('Error getting propagated attribution', err);
    return undefined;
  }
};
