import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import clsx from 'clsx';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { Formik } from 'formik';
import {
  Box,
  ButtonBase,
  Chip,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import InstagramIcon from '@material-ui/icons/Instagram';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import {
  Banner,
  BusinessAddressInput,
  DiscardButton,
  FacebookIcon,
  FormErrorList,
  PhoneInput,
  SaveButton,
  SimpleAddressInput,
  SvgIcon,
  TextInput,
  TikTokIcon,
  Typography,
} from '@castiron/components';
import {
  ChecklistValues,
  Shop,
  ServiceAreaLocation,
  SocialMediaInfo,
  addressSchema,
  formatPhoneNumber,
  phoneRegExp,
} from '@castiron/domain';
import { allergenOptions, useTracking } from '@castiron/utils';
import { accountRepository } from '../../../../domain';
import { getService } from '../../../../firebase';
import { getCastIronAssetsAction } from '../../../../store/reducers/assets';
import { openModal } from '../../../../store/reducers/modalConductor';
import { getShopAction, getWebsiteUrlsAction, updateShopAction } from '../../../../store/reducers/shops';
import CheckboxSelector from '../../../Products/EditProduct/FormComponents/CheckboxSelector';
import UnsavedChangesPrompt from '../../../UnsavedChangesPrompt.tsx';
import AdminForm from '../../../AdminForm';
import { LayoutPageProps } from '../../../Layout';
import ViewShopButton from '../../../Layout/Header/ViewShopButton';
import ScrollToError from '../../../ScrollToError';

const reindexProductsService = getService('products', 'reindex');

const useStyles = makeStyles((theme: Theme) => ({
  captionText: {
    color: theme.branding.v2.gray[700],
    fontWeight: 400,
  },
  chipDeleteIcon: {
    color: theme.branding.v2.gray[800],
    margin: 0,
    width: 16,
    height: 16,
  },
  displayChips: {
    gap: 4,
  },
  editButton: {
    color: theme.branding.v2.blue[500],
    paddingRight: 14,
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 24,
    gap: 8,
    width: '100%',
  },
  formContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: 17,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  infoBox: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: '8px',
    padding: '24px',
    gap: '24px',
  },
  inputURL: {
    backgroundColor: theme.branding.v2.gray[200],
    padding: '16px',
    borderRadius: '12px 0 0 12px',
    [theme.breakpoints.down('xs')]: {
      width: '125px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  locationChip: {
    gap: 8,
    padding: '4px 12px',
    height: 28,
    backgroundColor: theme.branding.v2.gray[100],
    '& .MuiChip-label': {
      padding: 0,
    },
  },
  lock: {
    width: '20px',
  },
  openNew: {
    color: theme.branding.v2.blue[500],
    width: '24px',
    height: '24px',
  },
  previewShopContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '4px',
    '&:hover': {
      textDecoration: 'none !important',
    },
    '& span': {
      color: theme.branding.v2.blue[500],
      fontSize: '16px',
    },
  },
  radioLabel: {
    margin: 0,
    gap: '8px',
    '& .MuiRadio-root': {
      padding: 0,
    },
    '& .Mui-checked': {
      color: theme.branding.v2.blue[500],
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  socialIcon: {
    '& svg > path': {
      fill: theme.branding.v2.gray[800],
    },
    '& svg': {
      width: '22px',
    },
  },
  subsectionContainer: {
    gap: '16px',
  },
  textLink: {
    color: theme.branding.v2.blue[500],
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: theme.branding.v2.blue[500],
    },
  },
  tiktokIcon: {
    '& svg': {
      width: '16px',
    },
  },
  websiteInput: {
    padding: 0,
    width: '100%',
  },
}));

const BusinessDetails: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const theme = useTheme();
  const onMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { account, shop, castIronAssets, blockedWebsiteUrls } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
    castIronAssets: state.assets.castIronAssets,
    blockedWebsiteUrls: state.shops.blockedWebsiteUrls,
  }));

  const locationSearchService = getService('locations', 'search');

  const history = useHistory();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const focus = urlParams.get('focus');
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const formikRef = useRef() as any;
  const [submitting, setSubmitting] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState('');
  const [displayValues, setDisplayValues] = useState([]);
  const [locationValues, setLocationValues] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(shop?.serviceArea?.locations)) {
      //reload options from onboarding Qs
      const displays = shop?.serviceArea?.locations?.map(area => area.display) || [];
      const locationObjs = shop?.serviceArea?.locations?.map(area => ({
        fullAddress: area.display,
        postalCode: area.postalCodes,
      })) || [];
      setDisplayValues(displays);
      setLocationValues(locationObjs);
    } else if (shop?.physicalAddress?.city && shop?.physicalAddress?.region && _.isEmpty(displayValues)) {
      //add business address city to display values if it exists
      const existingAddress = `${shop.physicalAddress.city}, ${shop?.physicalAddress?.region}`;
      setDisplayValues([existingAddress]);
      setLocationValues([
        {
          fullAddress: existingAddress,
          city: shop.physicalAddress.city,
          region: shop.physicalAddress.region,
        },
      ]);
    }
  }, [shop]);

  const onAddressChange = useCallback(
    location => {
      setError('');

      let parsedLocation;
      const postalCodeInput = location.fullAddress.split(' ').find(word => /\d/.test(word));

      if (postalCodeInput !== undefined) {
        const cleanPostalCode = _.replace(postalCodeInput, ',', '');
        parsedLocation = `${cleanPostalCode}, ${location.city}, ${location.region}`;
      } else {
        parsedLocation = _.replace(location.fullAddress, ', USA', '');
      }

      setLocationValues(arr => [
        ...arr,
        {
          ...location,
          fullAddress: parsedLocation,
        },
      ]);
      setDisplayValues(arr => {
        const exists = arr.includes(parsedLocation);
        if (!exists) {
          return [parsedLocation, ...arr];
        } else {
          setError(`Location has already been selected: ${parsedLocation}`);
          return arr;
        }
      });
    },
    [displayValues, locationValues, error],
  );

  yup.addMethod(yup.string, 'blockedWebsiteUrl', function() {
    // Checks for own shop name here because its faster than
    //    filtering it out of the array.
    // @ts-ignore
    return this.test({
      name: 'name',
      message: 'That name is already taken.',
      test: value => {
        if (!blockedWebsiteUrls) return false;
        return (shop && value === shop.websiteUrl) || !blockedWebsiteUrls.includes(value);
      },
    });
  });

  const storeSchema = yup.object().shape({
    businessName: yup.string().required('Please enter a shop name'),
    email: yup
      .string()
      .email('Please enter a valid email address')
      .required('Please enter an email address'),
    websiteUrl: yup
      .string()
      // @ts-ignore
      .blockedWebsiteUrl('Please choose a different URL')
      .lowercase()
      .trim()
      .required('Please enter a shop URL'),
    address: addressSchema(true),
    facebook: yup
      .string()
      .url('Please enter a valid Facebook URL that follows the format https://facebook.com/your-username.')
      .max(255)
      .matches(
        /^http(s)?:\/\/(www\.)?facebook.com\/(.*?)/gi,
        'Please enter a valid Facebook URL that follows the format https://facebook.com/your-username.',
      )
      .nullable(),
    instagram: yup
      .string()
      .url('Please enter a valid Instagram URL that follows the format https://instagram.com/your-username.')
      .max(255)
      .matches(
        /^http(s)?:\/\/(www\.)?instagram.com\/(.*?)/gi,
        'Please enter a valid Instagram URL that follows the format https://instagram.com/your-username.',
      )
      .nullable(),
    tiktok: yup
      .string()
      .url('Please enter a valid TikTok URL that follows the format https://tiktok.com/@your-username.')
      .max(255)
      .matches(
        /^http(s)?:\/\/(www\.)?tiktok.com\/(.*?)/gi,
        'Please enter a valid TikTok URL that follows the format https://tiktok.com/@your-username.',
      )
      .nullable(),
    phoneNumber: yup.string().matches(phoneRegExp, 'Please enter a valid 10-digit phone number'),
  });

  const emptyValues = {
    businessName: '',
    email: '',
    websiteUrl: '',
    address: {
      fullAddress: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      region: '',
      regionName: '',
      postalCode: '',
      country: '',
    },
    facebook: '',
    instagram: '',
    tiktok: '',
    allergens: [],
    phoneNumber: '',
  };

  const shopAddress = shop?.physicalAddress;

  const initialValues = {
    ...emptyValues,
    ...shop,
    address: {
      fullAddress: shopAddress?.fullAddress || '',
      addressLine1: shopAddress?.addressLine1 || '',
      addressLine2: shopAddress?.addressLine2 || '',
      city: shopAddress?.city || '',
      region: shopAddress?.region || '',
      regionName: shopAddress?.regionName || '',
      postalCode: shopAddress?.postalCode || '',
      country: shopAddress?.country || '',
    },
    facebook: shop?.socialMedia?.facebookLink || '',
    instagram: shop?.socialMedia?.instagramLink || '',
    tiktok: shop?.socialMedia?.tiktokLink || '',
    phoneNumber: account?.phoneNumber || '',
    shipping: shop?.serviceArea?.shipping || 'none',
  };

  const allergenRef = useRef<HTMLDivElement>();

  const socialRef = useRef<HTMLDivElement>();

  const onWebsiteUrlChange = (event, setFieldValue) => {
    setFieldValue(
      'websiteUrl',
      event.target.value
        .toLowerCase()
        .replace(/\s+/gi, '-')
        .replace(/[^0-9a-zA-Z_-]/gi, ''),
    );
  };

  useEffect(() => {
    setPageTitle('Business Details');
    setBackLocation(true);
    setHeaderCTAs([<ViewShopButton />]);

    setFooterCTAs([
      <DiscardButton isSubmitting={submitting} backLocation="/store/dashboard" />,
      <SaveButton formikState={formikRef.current} isSubmitting={submitting} />,
    ]);

    return () => {
      setPageTitle('');
      setBackLocation(false);
      setHeaderCTAs([]);
      setFooterCTAs([]);
    };
  }, [submitting]);

  useEffect(() => {
    if (castIronAssets.length) {
      if (focus === 'social-media') {
        document.getElementById('social-media').scrollIntoView();
      } else if (focus === 'allergens') {
        document.getElementById('allergens').scrollIntoView();
      }
    }
  }, [focus, castIronAssets]);

  useEffect(() => {
    dispatch(getWebsiteUrlsAction());
  }, []);

  useEffect(() => {
    dispatch(getCastIronAssetsAction());
  }, [dispatch]);

  const handleLocationDeletion = deletedLocation => {
    const newDisplayValues = displayValues.filter(dv => dv !== deletedLocation);
    const newLocationValues = locationValues.filter(lv => lv.fullAddress !== deletedLocation);
    setDisplayValues(newDisplayValues);
    setLocationValues(newLocationValues);
  };

  const onSubmit = async (values, formikProps) => {
    setSubmitting(true);
    try {
      const facebook = values.facebook;
      delete values.facebook;
      const instagram = values.instagram;
      delete values.instagram;
      const tiktok = values.tiktok;
      delete values.tiktok;

      const { websiteUrl, ...restOfValues } = values;

      const socialMedia: SocialMediaInfo = {};

      if (facebook) socialMedia.facebookLink = facebook;
      if (instagram) socialMedia.instagramLink = instagram;
      if (tiktok) socialMedia.tiktokLink = tiktok;

      const seoMetadataManuallyUpdated =
        restOfValues.address.city !== shop?.seoMetadata?.address?.addressLocality ||
        restOfValues.address.region !== shop?.seoMetadata?.address?.addressRegion;

      if (locationValues.length == 0) {
        setError('You need at least one service area');
        setSubmitting(false);
        return;
      }

      let fullLocations: ServiceAreaLocation[] = [];
      const postalCodes = await Promise.all(
        locationValues.flatMap(async loc => {
          if (!!loc.postalCode) {
            fullLocations.push({
              postalCodes: loc.postalCode,
              display: loc.fullAddress,
            });

            return loc.postalCode;
          } else if (!!loc.city && !!loc.region) {
            const esLocations = await locationSearchService({
              filter: {
                city: loc.city.replaceAll('-', ' '),
                regionAbv: loc.region,
              },
            });

            const esPostalCodes = esLocations.locations.map(doc => doc.postalCode);

            fullLocations.push({
              postalCodes: esPostalCodes,
              display: loc.fullAddress,
            });
            return esPostalCodes;
          } else {
            console.debug('Could not parse postal codes for location: ', loc);
            fullLocations.push({
              postalCodes: [],
              display: loc.fullAddress,
            });
          }
        }),
      );

      const cleanPostalCodes = _.uniq(_.flattenDeep(postalCodes));
      const updatedTags =
        restOfValues.shipping === 'nationwide'
          ? _.uniq([...shop.tags, 'National'])
          : !_.isEmpty(shop.tags) && shop.tags.includes('National')
          ? shop.tags.filter(t => t !== 'National')
          : shop.tags;

      const newShop: Shop = {
        ...shop,
        ..._.omit(restOfValues, ['address', 'phoneNumber', 'shipping']),
        socialMedia,
        websiteUrl: websiteUrl
          .trim()
          .replace(/\s+/g, '-')
          .replace(/[^0-9a-zA-Z_-]/gi, '')
          .toLowerCase(),
        physicalAddress: { ...restOfValues.address },
        serviceArea: {
          postalCodes: cleanPostalCodes,
          locations: fullLocations,
          shipping: restOfValues.shipping,
        },
        seoMetadata: {
          ...shop?.seoMetadata,
          ...(seoMetadataManuallyUpdated && { manuallyUpdated: true }),
          address: {
            addressCountry: restOfValues.address.country,
            addressLocality: restOfValues.address.city,
            addressRegion: restOfValues.address.region,
            postalCode: restOfValues.address.postalCode,
          },
        },
        tags: updatedTags,
      };

      const newCompletions = [];

      const updateShopResponse = await dispatch(updateShopAction({ shop: newShop, newCompletions }));

      await reindexProductsService({});

      if (values.phoneNumber) {
        await accountRepository.updateProps(account.id, {
          phoneNumber: values.phoneNumber,
          'config.messagingPreferences.sms.smsPhoneNumber': formatPhoneNumber(values.phoneNumber),
        });
      }

      if (updateShopResponse.meta.requestStatus === 'fulfilled') {
        await shop.addToChecklist(ChecklistValues.Customize);
        await dispatch(getShopAction(shop.id));
        dispatch(
          openModal({
            modalType: 'SIMPLE_ALERT',
            modalProps: {
              show: true,
              celebrate: true,
              content: 'Changes have been saved!',
            },
          }),
        );
      }

      if (initialValues.websiteUrl !== newShop.websiteUrl) {
        trackEvent('Shop url edited', {
          shop: newShop.id,
          shopName: newShop.businessName,
          shopUrl: newShop.websiteUrl,
          shopOldUrl: shop.websiteUrl,
        });
      }

      if (initialValues.businessName !== newShop.businessName) {
        trackEvent('Shop name edited', {
          shop: newShop.id,
          shopName: newShop.businessName,
          shopOldName: shop.businessName,
          shopUrl: newShop.websiteUrl,
        });
      }

      if (!_.isEqual(shop?.serviceArea, newShop?.serviceArea)) {
        trackEvent('Shop service areas updated', {
          shopOldServiceAreas: shop?.serviceArea,
          shopNewServiceAreas: newShop?.serviceArea,
        });
      }

      setSubmitting(false);
      formikProps.resetForm();
    } catch (err) {
      setSubmitting(false);
      console.error('Error Submitting Shop Form: ', err);
    }
  };

  const requiredAsterisk = <span style={{ color: theme.branding.v2.red[500] }}>*</span>;

  const shippingOptions = [
    {
      label: 'Nationwide Shipping (United States)',
      value: 'nationwide',
    },
    {
      label: `Statewide Shipping Only${` (${shop?.physicalAddress?.regionName})` ||
        ` (${shop?.physicalAddress?.region})` ||
        ''}`,
      value: 'statewide',
    },
    {
      label: 'No Shipping',
      value: 'none',
    },
  ];

  return (
    <Grid container>
      <Helmet>
        <title>Business Details | Shop | Castiron</title>
      </Helmet>
      <Grid item xs={12} className={classes.formContainer}>
        <Formik
          initialValues={initialValues}
          validationSchema={storeSchema}
          onSubmit={onSubmit}
          innerRef={formikRef}
          enableReinitialize
        >
          {({ setFieldValue, values, isSubmitting, errors, submitCount, dirty, touched }) => (
            <AdminForm>
              {!!submitCount && !!errors && !isSubmitting && (
                <Box className={classes.errorContainer}>
                  <FormErrorList errors={errors} />
                </Box>
              )}
              <ScrollToError />
              <Grid spacing={!onMediumScreen ? 6 : undefined} container>
                <Grid className={classes.section} item xs={12} lg={8}>
                  <Grid container item direction="column" className={classes.subsectionContainer}>
                    <Typography variant="h3">Shop Details</Typography>
                    <Grid container item direction="column" wrap="nowrap" className={classes.infoBox}>
                      <Grid container item direction="column" wrap="nowrap" style={{ gap: '8px' }}>
                        <TextInput
                          label={
                            <Grid
                              container
                              item
                              direction="row"
                              justify="space-between"
                              alignItems="center"
                              wrap="nowrap"
                            >
                              <Typography variant="subtitle2">Shop URL {requiredAsterisk}</Typography>
                              <SvgIcon className={classes.lock}>
                                {disabled ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />}
                              </SvgIcon>
                            </Grid>
                          }
                          error={touched.websiteUrl && errors.websiteUrl}
                          name="websiteUrl"
                          disabled={disabled}
                          className={classes.websiteInput}
                          startAdornment={
                            <Box className={classes.inputURL}>
                              <Typography variant="body4">{process.env.REACT_APP_SHOP_URL}</Typography>
                            </Box>
                          }
                          endAdornment={
                            disabled && (
                              <ButtonBase onClick={(): void => setDisabled(false)}>
                                <Typography variant="body4" className={classes.editButton}>
                                  Edit
                                </Typography>
                              </ButtonBase>
                            )
                          }
                          onBlur={(): void => setDisabled(true)}
                          onChange={e => onWebsiteUrlChange(e, setFieldValue)}
                        />
                        <Link
                          href={`${process.env.REACT_APP_SHOP_URL}${shop?.websiteUrl}`}
                          target="_blank"
                          className={classes.previewShopContainer}
                        >
                          <Typography variant="button">View Your Shop</Typography>
                          <OpenInNewIcon className={classes.openNew} />
                        </Link>
                      </Grid>
                      <Grid container item direction="column" wrap="nowrap">
                        <TextInput
                          label={<Typography variant="subtitle2">Shop Name {requiredAsterisk}</Typography>}
                          error={touched.businessName && errors.businessName}
                          name="businessName"
                        />
                        <Typography className={classes.captionText} variant="caption">
                          Your shop name will appear throughout your site to your customers
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item direction="column" className={classes.subsectionContainer}>
                    <Typography variant="h3">Service Area</Typography>
                    <Grid
                      container
                      item
                      direction="column"
                      wrap="nowrap"
                      className={classes.infoBox}
                      style={{ gap: '32px' }}
                    >
                      <Grid container item direction="column" style={{ gap: '16px' }}>
                        <Grid container item direction="column" style={{ gap: '4px' }}>
                          <Typography variant="subtitle1">Delivery and Pickup</Typography>
                          <Typography variant="body4">
                            Let customers know where your business provides local deliveries and other services. Manage
                            your complete pickup, delivery, and shipping details in{' '}
                            <span className={classes.textLink} onClick={() => history.push('/store/fulfillment')}>
                              Fulfillments
                            </span>
                            .
                          </Typography>
                        </Grid>
                        <Grid container item direction="column" style={{ gap: '4px' }}>
                          <Typography variant="subtitle2">Selected Delivery Service Areas</Typography>
                          <Grid container item direction="row" className={classes.displayChips}>
                            {displayValues.map(location => (
                              <Chip
                                label={<Typography variant="button2">{location}</Typography>}
                                onDelete={() => handleLocationDeletion(location)}
                                deleteIcon={<ClearOutlinedIcon className={classes.chipDeleteIcon} />}
                                className={classes.locationChip}
                              />
                            ))}
                          </Grid>
                        </Grid>
                        <SimpleAddressInput
                          id="service-area-autocomplete"
                          onAddressChange={onAddressChange}
                          error={error}
                          label="Search for a Delivery Service Area"
                        />
                      </Grid>
                      <Grid container item direction="column" style={{ gap: '16px' }}>
                        <Grid container item direction="column" style={{ gap: '4px' }}>
                          <Typography variant="subtitle1">Shipping</Typography>
                          <Typography variant="body4">
                            Let customers know where you ship. Product specific fulfillment can be managed in{' '}
                            <span className={classes.textLink} onClick={() => history.push('/products')}>
                              Products
                            </span>
                            .
                          </Typography>
                        </Grid>
                        <Grid container item direction="column" wrap="nowrap" style={{ gap: '8px' }}>
                          <Typography variant="subtitle2">Shipping Availability</Typography>
                          <RadioGroup
                            name="shipping"
                            value={values.shipping}
                            onChange={e => setFieldValue('shipping', e.target.value)}
                            style={{ gap: '8px' }}
                          >
                            {shippingOptions.map((opt, index) => (
                              <FormControlLabel
                                value={opt.value}
                                label={<Typography variant="body2">{opt.label}</Typography>}
                                control={<Radio />}
                                key={`shipping-radio-${index}`}
                                className={classes.radioLabel}
                              />
                            ))}
                          </RadioGroup>
                        </Grid>
                        <Banner variant="info-white" noIcon>
                          <Grid container item direction="column" wrap="nowrap" style={{ gap: '4px' }}>
                            <Typography variant="h5">📦 Not sure how to ship?</Typography>
                            <Typography variant="body4">
                              Check out our{' '}
                              <a
                                href="https://www.castiron.me/seller/blog/shipping-cottage-food-resources-and-tips-you-can-use"
                                target="_blank"
                                className={classes.textLink}
                                style={{ fontWeight: 700 }}
                              >
                                Shipping Guide
                              </a>{' '}
                              to learn everything you need to know for a successful delivery.
                            </Typography>
                          </Grid>
                        </Banner>
                        <Typography variant="caption" className={classes.captionText}>
                          Keep in mind shipping guidelines for cottage food and commercial vendors vary by location.
                          Find specific guidelines for your business through your local government.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item direction="column" className={classes.subsectionContainer}>
                    <Typography variant="h3">Contact Info</Typography>
                    <Grid container item direction="column" wrap="nowrap" className={classes.infoBox}>
                      <Grid>
                        <TextInput
                          label={<Typography variant="subtitle2">Business Email {requiredAsterisk}</Typography>}
                          error={touched.email && errors.email}
                          placeholder="example@email.com"
                          name="email"
                        />
                        <Typography className={classes.captionText} variant="caption">
                          This email address will be used for sending and receiving emails from customers.
                        </Typography>
                      </Grid>
                      <Grid>
                        <PhoneInput
                          label={<Typography variant="subtitle2">Business Phone Number</Typography>}
                          name="phoneNumber"
                          error={errors.phoneNumber}
                        />
                        <Typography className={classes.captionText} variant="caption">
                          This phone number will be used for order notifications and more.
                        </Typography>
                      </Grid>
                      <Grid>
                        <BusinessAddressInput />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={classes.section} style={onMediumScreen ? { marginTop: 24 } : {}} item xs={12} lg={4}>
                  <Grid
                    container
                    item
                    direction="column"
                    id="social-media"
                    ref={socialRef}
                    className={classes.subsectionContainer}
                  >
                    <Typography variant="h3">Social Links</Typography>
                    <Grid container item direction="column" style={{ gap: '8px' }}>
                      <TextInput
                        className={classes.socialIcon}
                        startAdornment={<FacebookIcon />}
                        name="facebook"
                        placeholder="facebook.com/"
                        error={touched.facebook && errors.facebook}
                      />
                      <TextInput
                        className={classes.socialIcon}
                        startAdornment={<InstagramIcon />}
                        name="instagram"
                        placeholder="instagram.com/"
                        error={touched.instagram && errors.instagram}
                      />
                      <TextInput
                        className={clsx([classes.socialIcon, classes.tiktokIcon])}
                        startAdornment={<TikTokIcon />}
                        name="tiktok"
                        placeholder="tiktok.com/@"
                        error={touched.tiktok && errors.tiktok}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item direction="column" id="allergens" ref={allergenRef} style={{ gap: '6px' }}>
                    <Grid container direction="column" style={{ gap: '4px' }}>
                      <Typography variant="h3">Allergen Information</Typography>
                      <Typography variant="body4">
                        Let your customers know if your products were made on equipment that also produce products that
                        contain any of the following ingredients:
                      </Typography>
                    </Grid>
                    <CheckboxSelector name="allergens" columns={2} options={allergenOptions} />
                  </Grid>
                </Grid>
                <UnsavedChangesPrompt when={dirty} />
              </Grid>
            </AdminForm>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default BusinessDetails;
