import _ from 'lodash';
import moment from 'moment';
import { Grid, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { calculateTotals, Transaction, TransactionTotals } from '@castiron/domain';
import Dinero from 'dinero.js';
import { Chip, CollapsableCard } from '@castiron/components';
import { defaultTimeZone } from '@castiron/utils';

type Props = {
  transaction: Transaction;
  subTransaction: Transaction;
  defaultExpanded?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '24px',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 24px',
    },
  },
  section: { padding: 5 },
}));

const DepositPayment: React.FC<Props> = (props: Props) => {
  const { transaction, subTransaction, defaultExpanded = false } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);
  const [depositTotals, setDepositTotals] = useState<TransactionTotals>();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const timeZone = shop.config?.timeZone || defaultTimeZone;

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (transaction && subTransaction) {
      const depositTotals = calculateTotals({
        order: transaction.order,
        paymentSettings: shop.paymentSettings,
        subTransactionTotal: subTransaction ? subTransaction?.totals?.subtotal : 0,
        isSubTransactionPaid: true,
      });
      setDepositTotals(depositTotals);
    }
  }, [transaction, subTransaction]);

  const dueDateText = () => {
    if (transaction?.order?.paymentSentDuePeriod == '7days') {
      return `1 week prior to ${transaction?.order?.fulfillmentOption?.type}${
        transaction?.order?.fulfillmentOption?.date
          ? `- ${moment
              .unix(transaction?.order?.fulfillmentOption?.date)
              .tz(timeZone)
              .subtract(1, 'weeks')
              .format('M/D/YYYY')}`
          : ``
      }`;
    } else if (transaction?.order?.paymentSentDuePeriod == '14days') {
      return `2 weeks prior to ${transaction?.order?.fulfillmentOption?.type}${
        transaction?.order?.fulfillmentOption?.date
          ? `- ${moment
              .unix(transaction?.order?.fulfillmentOption?.date)
              .tz(timeZone)
              .subtract(2, 'weeks')
              .format('M/D/YYYY')}`
          : ``
      }`;
    } else if (transaction?.order?.paymentSentDuePeriod == '0day') {
      return `On date of ${transaction?.order?.fulfillmentOption?.type}${
        transaction?.order?.fulfillmentOption?.date
          ? `- ${moment
              .unix(transaction?.order?.fulfillmentOption?.date)
              .tz(timeZone)
              .format('M/D/YYYY')}`
          : ``
      }`;
    } else {
      return `Payment is due on date ${moment
        .unix(transaction?.order?.paymentDueDate)
        .tz(timeZone)
        .format('M/D/YYYY')}`;
    }
  };

  let paymentMethod;
  if (transaction?.transactionStatus === 'succeeded') {
    const paymentDate = transaction?.order?.payments?.[0]?.paidAt
      ? moment
          .unix(transaction?.order?.payments?.[0]?.paidAt)
          .tz(timeZone)
          .format('M/D/YYYY')
      : '';
    paymentMethod =
      transaction?.order?.initiator === 'owner'
        ? `Paid Manually on ${paymentDate}`
        : transaction?.processor?.name === 'stripe'
        ? `Paid ${paymentDate} via Castiron Payments`
        : transaction?.order?.payments
        ? `Paid ${paymentDate} via ${
            transaction?.order?.payments?.[0]?.type === 'credit-card'
              ? 'Credit Card'
              : _.capitalize(transaction?.order?.payments?.[0]?.type)
          }`
        : `Paid ${paymentDate} via Castiron Payments`;
  }

  return (
    <CollapsableCard title="Payment" handleExpand={toggleExpand} expanded={expanded} noScroll removeInnerPadding={true}>
      {subTransaction && (
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.section}>
            <Typography variant="h4">Deposit</Typography>
          </Grid>
          <Grid item xs={12} className={classes.section}>
            <Typography variant="body1">Amount</Typography>
          </Grid>
          <Grid container justify="flex-start" alignItems="center" className={classes.section}>
            <Typography>{Dinero({ amount: subTransaction?.totals?.total || 0 }).toFormat('$0.00')}</Typography>
            <Chip colorScheme="success" style={{ marginLeft: 5 }}>
              Paid
            </Chip>
          </Grid>
          <Grid item xs={12} className={classes.section}>
            <Typography variant="subtitle2">Payment Details</Typography>
          </Grid>
          <Grid item xs={12} className={classes.section}>
            <Typography variant="body1">{`Paid ${moment
              .unix(subTransaction?.order?.payments[0]?.paidAt)
              .tz(timeZone)
              .format('M/D/YYYY')} via Castiron Payments`}</Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        className={classes.container}
        style={subTransaction ? { borderTop: `1px solid ${theme.branding.v2.gray[200]}` } : {}}
      >
        {subTransaction && (
          <>
            <Grid item xs={12} className={classes.section}>
              <Typography variant="h4">Balance</Typography>
            </Grid>
            <Grid item xs={12} className={classes.section}>
              <Typography variant="body1">Amount</Typography>
            </Grid>
          </>
        )}
        {transaction?.transactionStatus === 'partially-paid' ? (
          <>
            <Grid container justify="flex-start" alignItems="center" className={classes.section}>
              <Typography>{Dinero({ amount: depositTotals?.total || 0 }).toFormat('$0.00')}</Typography>
              <Chip colorScheme="error" style={{ marginLeft: 5 }}>
                Unpaid
              </Chip>
            </Grid>
            <Grid item xs={12} className={classes.section}>
              <Typography variant="subtitle2">Due Date</Typography>
            </Grid>
            <Grid item xs={12} className={classes.section}>
              <Typography variant="body1">{dueDateText()}</Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid container justify="flex-start" alignItems="center" className={classes.section}>
              <Typography>{Dinero({ amount: transaction?.totals?.total || 0 }).toFormat('$0.00')}</Typography>
              <Chip colorScheme="success" style={{ marginLeft: 5 }}>
                Paid
              </Chip>
            </Grid>
            <Grid item xs={12} className={classes.section}>
              <Typography variant="subtitle2">Payment Details</Typography>
            </Grid>
            <Grid item xs={12} className={classes.section}>
              <Typography variant="body1">{paymentMethod}</Typography>
            </Grid>
          </>
        )}
      </Grid>
    </CollapsableCard>
  );
};

export default DepositPayment;
