import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { openModal } from '../../store/reducers/modalConductor';

interface Props {}

const useStyles = makeStyles((theme: Theme) => ({
  noClick: {
    cursor: 'pointer',
    '& > *': {
      pointerEvents: 'none',
    },
  },
}));

const RequireStripe: React.FC<Props> = props => {
  const { children } = props;

  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [isStripeSetup, setIsStripeSetup] = useState<boolean>(false);

  const { account } = useAppSelector(state => ({
    account: state.shops.account,
  }));

  const handleStripeSetup = () => {
    dispatch(
      openModal({
        modalType: 'PROMPT_STRIPE_SETUP_MODAL',
        modalProps: {
          show: true,
        },
      }),
    );
  };

  useEffect(() => {
    if (account && account.isReady) {
      setIsStripeSetup(true);
    }
  }, [account]);

  return isStripeSetup ? (
    <>{children}</>
  ) : (
    <div onClick={handleStripeSetup} className={classes.noClick}>
      {children}
    </div>
  );
};

export default RequireStripe;
