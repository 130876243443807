import React, { ReactNode } from 'react';
import { FormHelperText, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormikErrors } from 'formik';
import InputLabel from './InputLabel';

export interface BaseInputProps {
  label?: ReactNode;
  secondaryLabel?: ReactNode;
  helpText?: ReactNode;
  required?: boolean;
  error?: string | FormikErrors<any> | (string | FormikErrors<any>)[];
};

interface Props extends BaseInputProps {
  variant?: 'label-top' | 'label-left' | 'label-right';
  onLabelClick?: () => void;
  children: ReactNode;
  className?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  helpText: {
    color: theme.branding.v2.gray[500],
  },
}));

const InputWrapper: React.FC<Props> = (props: Props) => {
  const { className, variant = 'label-top', onLabelClick, label, secondaryLabel, helpText, required, error, children } = props;
  const classes = useStyles();

  /* mui 4 doesn't support 0.5 increment spacing, so manually put in 4px margins */
  return variant === 'label-top' ? (
    <Grid container direction='column' className={className}>
      {
        label &&
        <Grid item style={{ marginBottom: '4px' }}>
          <InputLabel label={label} secondaryLabel={secondaryLabel} required={required} onClick={onLabelClick} />
        </Grid>
      }
      <Grid item style={{ display: 'flex', flexDirection: 'column' }}>
        {children}
      </Grid>
      {
        error &&
        <Grid item style={{ marginTop: '4px' }}>
          <FormHelperText error>{error}</FormHelperText>
        </Grid>
      }
      {
        !error && helpText &&
        <Grid item style={{ marginTop: '4px' }}>
          <FormHelperText className={classes.helpText}>{helpText}</FormHelperText>
        </Grid>
      }
    </Grid>
  ) : variant === 'label-right' ? (
    <Grid container direction='column' className={className}>
        <Grid item>
          <Grid container wrap='nowrap'>
            <Grid item style={{ display: 'flex', flexDirection: 'column' }}>
              {children}
            </Grid>
            {
              label &&
              <Grid item>
                <InputLabel label={label} secondaryLabel={secondaryLabel} required={required} onClick={onLabelClick} />
              </Grid>
            }
          </Grid>
      </Grid>
      {
        error &&
        <Grid item style={{ marginTop: '4px' }}>
          <FormHelperText error>{error}</FormHelperText>
        </Grid>
      }
    </Grid>
  ) : (
    <Grid container direction='column' className={className}>
        <Grid item>
          <Grid container>
            {
              label &&
              <Grid item style={{ marginBottom: '4px' }}>
                <InputLabel label={label} secondaryLabel={secondaryLabel} required={required} onClick={onLabelClick} />
              </Grid>
            }
            <Grid item style={{ display: 'flex', flexDirection: 'column' }}>
              {children}
            </Grid>
          </Grid>
      </Grid>
      {
        error &&
        <Grid item style={{ marginTop: '4px' }}>
          <FormHelperText error>{error}</FormHelperText>
        </Grid>
      }
    </Grid>
  );
};

export default InputWrapper;