import React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Moment } from "moment";
import MomentUtils from '@date-io/moment';
import InputWrapper, { BaseInputProps } from '../InputWrapper';
import { useMediaQuery } from '@material-ui/core';

interface Props extends BaseInputProps {
  selectedDate?: Moment;
  allowKeyboardInput?: boolean;
  disablePast?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  onChange: (date: Moment, value?: string) => void;
  shouldDisableDate?: (date: Moment) => boolean;
  addErrorStyling?: boolean;
  disablePastDateError?: boolean;
  removeIcon?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  default: {
    /* make it play nice with the custom input labels */
    marginTop: 0,
  },
  /* bit of a hack, but it works */
  error: {
    '& div': {
      '& fieldset': {
        borderColor: theme.palette.error.main,
      }
    },
  },
  fullWidth: {
    width: '100%' 
  },
  noError: {
    '& div': {
      '& fieldset': {
        borderColor: theme.branding.gray[400],
      }
    },
  },
  noMinWidth: {
    '& .MuiPickersBasePicker-pickerView': {
      minWidth: 0,
    },
  },
}));

const DateInput: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXsMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const {
    label,
    secondaryLabel,
    required,
    selectedDate,
    allowKeyboardInput = true,
    disablePast = true,
    error,
    fullWidth,
    placeholder,
    onChange,
    shouldDisableDate,
    addErrorStyling = false,
    disablePastDateError = false,
    removeIcon = false,
  } = props;

  return (
    <InputWrapper label={label} secondaryLabel={secondaryLabel} required={required} error={error}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {
          !!allowKeyboardInput &&
          <KeyboardDatePicker
            autoOk
            disablePast={disablePast}
            disableToolbar
            variant="inline"
            format="MM/DD/yyyy"
            placeholder={placeholder || 'MM/DD/YYYY'}
            margin="normal"
            id="date-picker-inline"
            inputVariant="outlined"
            value={selectedDate}
            onChange={onChange}
            className={[classes.default, (error || addErrorStyling) && classes.error, fullWidth && classes.fullWidth, disablePastDateError && classes.noError].filter(x => !!x).join(' ')}
            shouldDisableDate={shouldDisableDate}
            minDateMessage={disablePastDateError ? null : 'Date should not be before minimal date'}
            PopoverProps={isXsMobile ? {PaperProps: {className: classes.noMinWidth}} : {}}
            KeyboardButtonProps={removeIcon ? { style: { display: 'none' } } : undefined}
          />
        }
        {
          !allowKeyboardInput &&
          <DatePicker
            autoOk
            disablePast={disablePast}
            disableToolbar
            variant="inline"
            format="MM/DD/yyyy"
            placeholder={placeholder || 'Select a date'}
            margin="normal"
            id="date-picker-inline"
            inputVariant="outlined"
            value={selectedDate}
            onChange={onChange}
            className={[classes.default, (error || addErrorStyling) && classes.error, fullWidth && classes.fullWidth, disablePastDateError && classes.noError].filter(x => !!x).join(' ')}
            shouldDisableDate={shouldDisableDate}
            minDateMessage={disablePastDateError ? null : 'Date should not be before minimal date'}
            PopoverProps={isXsMobile ? {PaperProps: {className: classes.noMinWidth}} : {}}
          />
        }
      </MuiPickersUtilsProvider>
    </InputWrapper>
  );
}

export default DateInput;