import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponentLike } from 'prop-types';
import { useConfig } from './useConfig';

/* can support no feature flag name passed in, which result in this just rendering children */
interface Props {
  name?: string;
  offState?: ReactComponentLike;
  children: ReactNode;
}

const FeatureFlag: React.FC<Props> = (props: Props) => {
  const { offState: OffState, name, children } = props;
  const { shop } = useSelector(state => ({
    // @ts-ignore
    shop: state.shops.shop,
  }));
  const config = useConfig();
  const flag = !name || config.featureFlag(name, shop);

  return <>{flag ? children : !!OffState && <OffState />}</>;
};

export default FeatureFlag;
