import React, {useCallback} from 'react';
import {FormikContextType, useFormikContext} from 'formik';
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {openModal} from "../../../store/reducers/modalConductor";
import * as Sentry from "@sentry/react";
import {transactionRepository} from "../../../domain";
import {prepareQuoteSegmentData, validateQuote} from "../QuoteUtils";
import {useHistory} from "react-router-dom";
import {Transaction} from "@castiron/domain";
import {useTracking} from "@castiron/utils";
import {Button} from "@castiron/components";
import {makeStyles, Theme} from "@material-ui/core";

type Props = {
  transaction: Transaction;
  disabled?: boolean;
  editing: boolean;
  formikContext?: FormikContextType<any>;
};

const useStyles = makeStyles((theme: Theme) => ({
  sendButton: {
    marginLeft: '8px'
  }
}));


const SendButton: React.FC<Props> = (props: Props) => {
  const {disabled, editing, transaction, formikContext} = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {trackEvent} = useTracking();
  const classes = useStyles();
  const formik = formikContext || useFormikContext();

  const {shop} = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const trackAction = (action: string): void => {
    trackEvent(
      'Quote Action Clicked',
      {
        action,
        url: window.location.href,
        ...prepareQuoteSegmentData(transaction)
      }
    );
  }

  const sendQuote = useCallback(async (event) => {
    event.stopPropagation();

    const sendQuoteTx = Sentry.startTransaction({
      op: 'submit',
      name: 'Send Quote',
      tags: {
        transactionType: 'business'
      }
    });
    Sentry.getCurrentHub().configureScope(scope => {
      scope.setSpan(sendQuoteTx);
      scope.setUser({
        id: shop.id,
        email: shop.email,
        username: shop.websiteUrl
      })
    });

    if (editing && formik) {
      const span = sendQuoteTx.startChild({
        op: 'submit',
        description: 'Submit Form'
      });
      await formik.submitForm();
      span.finish();
    }

    /* I hate that I'm doing this but there is no guarantee the transaction in the props is up to date enough for our purposes here
     * get the most recent from the source of truth after the form is submitted
     */
    const upToDateTransaction = await transactionRepository.get(transaction.id);

    trackAction('sendQuote');
    if (validateQuote(upToDateTransaction).length > 0) {
      history.push(`/quotes/edit/${upToDateTransaction.id}?error=missingRequired`);
      window.scrollTo(0, 0);
      return;
    }
    const subtotal = upToDateTransaction.totals?.subtotal || 0;
    if (subtotal < 50) {
      history.push(`/quotes/edit/${upToDateTransaction.id}?error=illegalTotal`);
      window.scrollTo(0, 0);
      return;
    }

    dispatch(
      openModal({
        modalType: 'QUOTE_SEND_MODAL',
        modalProps: {
          show: true,
          transaction: upToDateTransaction,
        },
      }),
    );
  }, [transaction]);


  return (
    <Button
      variant='contained'
      type='submit'
      color='primary'
      disabled={!!disabled}
      onClick={sendQuote}
      className={classes.sendButton}
    >
      Send
    </Button>
  );
};

export default SendButton;
