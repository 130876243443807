import React, {useState, useEffect, ReactNode} from 'react';
import Image from 'next/image';
import { ButtonBase, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles, TextField, Theme, CircularProgress } from '@material-ui/core';
import { Button } from '../Button';
import Popover from '../Popover/Popover';
import Typography from '../Typography';
import { InputField, SelectedAsset } from '@castiron/domain';
import MoreIcon from '../assets/more-icon.png';
import { upload } from '@castiron/castiron-firebase';

type Props = {
    image: SelectedAsset;
    handleNotesChange: (updatedImage: SelectedAsset, variation: InputField) => void;
    variation: InputField;
    imageArr: SelectedAsset[];
    index: number;
    handleImageArrChange: (arr: SelectedAsset[]) => void;
    uploading: { isUploading: boolean, id: string };
    uploadTimeout: { isFailed: boolean, id: string };
    imageFile: { imgFile: Blob, id: string };
    uploadData: { callbacks: { success: () => void, error: () => void }, options: { folder: string }, context: {}, id: string }
  children?: ReactNode;
};

const useStyles = makeStyles((theme: Theme) => ({
    ast: {
        color: theme.branding.red.primary,
    },
    buttonBase: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '4px 32px 4px 8px',
        '&:hover': {
            backgroundColor: `${theme.branding.gray[200]}4D`,
            borderRadius: 4,
        },
    },
    circularProgress: {
        '& svg': {
            width: '25px',
            height: '25px',
        },
        position: 'absolute',
        width: '120px',
        height: '120px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    fade: {
        opacity: 0.25,
        transition: 'opacity 1s ease'
    },
    fadeOut: {
        width: '120px',
        height: '120px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        zIndex: 1
    },
    failItem: {
        color: theme.branding.red.primary,
        cursor: 'pointer'
    },
    failedColumn: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
    },
    imagePreview: {
        width: 120,
        height: 120,
        aspectRatio: '1/1',
        borderRadius: 8,
        objectFit: 'cover',
        position: 'absolute',
    },
    imageTitle: {
        display: 'flex',
    },
    iconMenu: {
        paddingBottom: '5px'
    },
    input: {
        width: '100%',
        padding: '0 16px 0 0',
    },
    inputInner: {
        width: '100%',
        fontSize: 14,
    },
    notesPreviewContainer: {
        display: 'flex',
        borderRadius: 8,
        padding: 16,
        border: `1px solid ${theme.branding.gray[400]}`,
        minHeight: '152px',
        maxWidth: '360px',
    },
    notesRequired: {
        color: theme.branding.gray[700],
    },
    photoContainer: {
        marginTop: 24,
    },
    removeImage: {
        color: theme.branding.red.primary,
        '&:hover': {
            backgroundColor: `${theme.branding.gray[200]}4D`,
        },
    },
    removeImageDialogCancelButton: {
        color: theme.branding.gray[700],
    },
    removeImageDialogConfirmButton: {
        backgroundColor: theme.branding.red.primary,
    },
    tryAgain: {
        color: theme.branding.blue.primary,
    },

}));

const InspoAssetDisplay: React.FC<Props> = (props: Props) => {
    const { image, handleNotesChange, variation, imageArr, index, handleImageArrChange, uploading, uploadTimeout, imageFile, uploadData } = props;
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [notesRequiredError, setNotesRequiredError] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [failed, setFailed] = useState(false);
    const [working, setWorking] = useState(false);

    const classes = useStyles();


    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleDeleteAsset = () => {
        let newAssetArr = imageArr.filter(img => img.id !== image.id);
        handleImageArrChange(newAssetArr);
        handleClose();
        setShowDeleteDialog(false);
    };

    const tryAgain = () => {
        uploadData.id === image.id ?
            upload(imageFile, image.asset.metadata, uploadData.options, uploadData.callbacks, uploadData.context)
            : null;
    }

    const handleUploadingText = () => {
        if (working) {
            return (
                <div className={classes.fadeOut}>
                    <CircularProgress size={25} className={classes.circularProgress} thickness={6} />
                </div>
            )
        } else if (failed) {
            return (
                <div className={classes.fadeOut}>
                    <Grid direction='column' className={classes.failedColumn}>
                        <ButtonBase onClick={tryAgain}>
                            <Typography variant='button2' className={classes.tryAgain}>Try again</Typography>
                        </ButtonBase>
                        <Typography variant='button2'>or</Typography>
                        <ButtonBase onClick={() => setShowDeleteDialog(true)}>
                            <Typography variant='button2' className={classes.failItem}>Delete</Typography>
                        </ButtonBase>
                    </Grid>
                </div>
            )
        } else {
            return <></>
        }
    }

    useEffect(() => {
        image.id == uploadTimeout.id && uploadTimeout.isFailed ? setFailed(true) : null
    }, [uploadTimeout]);

    useEffect(() => {
        image.id == uploading.id && setWorking(!working)
    }, [uploading]);

    const notesRequiredText = () => {
        if (variation.notesRequired && !notesRequiredError) {
            return (<>
                <Typography variant='caption' className={classes.notesRequired}><span className={classes.ast}>*</span>Required</Typography>
            </>)
        } else if (variation.notesRequired && notesRequiredError) {
            return (<>
                <Typography variant='caption' className={classes.notesRequired}><span className={classes.ast}>Notes are required</span></Typography>
            </>)
        } else {
            return <></>
        }
    }


    return (
        <>
            <Dialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)} fullWidth>
                <DialogTitle>
                    <Typography variant='subtitle1'>Remove Photo #{index + 1}?</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography variant='body1'>This will remove the photo and any notes you have added for that photo.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='outlined'
                        className={classes.removeImageDialogCancelButton}
                        onClick={() => setShowDeleteDialog(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant='contained'
                        className={classes.removeImageDialogConfirmButton}
                        onClick={handleDeleteAsset}
                    >
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid  container key={index} className={classes.photoContainer}>
                <Grid item container className={classes.imageTitle}>
                    <Grid item>
                        <Typography variant='subtitle2'>Photo #{index + 1}</Typography>
                    </Grid>
                    <Grid item>
                        <ButtonBase className={classes.iconMenu} onClick={handleClick}>
                            <Image src={MoreIcon} width={24} height={24} />
                        </ButtonBase>
                        <Popover
                            onClose={handleClose}
                            anchorEl={anchorEl}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        >
                            <ButtonBase onClick={() => setShowDeleteDialog(true)} className={classes.buttonBase}>
                                <Typography variant='subtitle4' className={classes.removeImage}>Remove image</Typography>
                            </ButtonBase>
                        </Popover>
                    </Grid>
                </Grid>
                <Grid item container  direction='row' className={classes.notesPreviewContainer}>
                    <Grid xs={6} md={7} item  direction='column'>
                        <TextField
                            key={index}
                            error={notesRequiredError}
                            variant="outlined"
                            className={classes.input}
                            placeholder='Add a note about this photo...'
                            multiline
                            InputProps={{ classes: { input: classes.inputInner }, style: { padding: '8px 12px 16px 12px' } }}
                            inputProps={{ maxLength: 500 }}
                            rows={1}
                            rowsMax={12}
                            value={image.notes}
                            onChange={(e) => {
                                const updatedImage = { ...image, notes: e.target.value };
                                handleNotesChange(updatedImage, variation);
                                if (variation.notesRequired) { e.target.value === '' ? setNotesRequiredError(true) : null }
                            }}
                        />
                        <span>{notesRequiredText()}</span>
                    </Grid>
                    <Grid xs={3} item >
                        <img src={image.asset.downloadUrl} className={`${classes.imagePreview} ${working || failed ? classes.fade : ''}`} />
                        {handleUploadingText()}
                    </Grid>
                </Grid>
            </Grid>
            {failed && <Typography variant='caption4' className={classes.failItem}>Unable to upload photo #{index + 1}</Typography>}
        </>
    );
};

export default InspoAssetDisplay;
