import React, { ReactNode } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Button, Typography } from '@castiron/components';
import { useAppDispatch } from "../../../hooks";
import { closeModal } from "../../../store/reducers/modalConductor";

type Props = {
  message: ReactNode;
  onSuccess?: () => void;
  successCtaText?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  action: {
    borderTop: `1px solid ${theme.branding.v2.gray[200]}`,
    marginTop: 'auto',
    padding: 16,
  },
  message: {
    height: '100%',
    marginBottom: 32,
    padding: '32px 24px 40px',
    [theme.breakpoints.up('md')]: {
      maxWidth: 504,
    },
  },
  wrapper: {
    height: '100%',
  }
}));

const PaymentSuccess: React.FC<Props> = (props: Props) => {
  const { message, onSuccess, successCtaText } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    dispatch(closeModal());
    onSuccess && onSuccess();
  };

  return (
    <Grid container direction='column' className={classes.wrapper} wrap="nowrap">
      <Grid container item justify='center' alignContent='center' className={classes.message}>
        {typeof message === 'string' ?
          <Typography variant='body1'>{message}</Typography>
        :
          message
        }
      </Grid>
      <Grid container item justify="flex-end" className={classes.action}>
        <Button fullWidth={isMobile} variant='contained' onClick={() => handleClose()} style={{ padding: 16 }}>{successCtaText || 'Close'}</Button>
      </Grid>
    </Grid>
  );
};

export default PaymentSuccess;
