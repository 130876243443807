import React, {ReactNode, useEffect, useRef} from 'react';
import { useTracking } from '@castiron/utils';
import { useTheme } from '@material-ui/core';

type Props = {
  // eslint-disable-next-line
  msg: any;
  scrollToView?: boolean;
  children?: ReactNode;
  marketplace?: 'nourysh';
};

const SubmissionError: React.FC<Props> = (props: Props) => {
  const { scrollToView, msg, marketplace } = props;

  const ref = useRef<HTMLDivElement>();
  const theme = useTheme();

  useEffect(() => {
    if (ref.current && scrollToView) {
      ref.current.scrollIntoView({
        block: 'start',
        inline: 'start',
      });
    }
  });

  return (
    <div ref={ref}>
      <div className="text-danger submission-error">{msg}</div>
      <p>
        Having issues? Contact{' '}
        {marketplace === 'nourysh' ? (
          <a href="mailto:support@nourysh.com" style={{ color: theme.branding.v2.plum[500] }}>support@nourysh.com</a>
        ) : (
          <a href="mailto:help@castiron.me">help@castiron.me</a>
        )}{' '}
        to get help from our support team.
      </p>
    </div>
  );
};

export default SubmissionError;
