import React, { useEffect, useState } from 'react';
import { Grid, FormControlLabel, useTheme, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SvgIcon, CustomSwitch, RequestQuoteOutlined } from '@castiron/components';
import { GalleryPhoto, LayoutOption, Presale } from '@castiron/domain';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import DragIndicator from '@material-ui/icons/DragIndicator';
import ShoppingCartOutlined from '@material-ui/icons/ShoppingCartOutlined';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import PhotoLibraryOutlined from '@material-ui/icons/PhotoLibraryOutlined';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getProductsAction } from '../../../store/reducers/products';
import { getService } from '../../../firebase';
import Spinner from '../../Spinner';

type Props = {
  layoutOption: LayoutOption;
  index: number;
};

interface OptionData {
  displayText: string;
  icon: React.ReactElement;
  disabled: boolean;
  hideToggle?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: 16,
    [theme.breakpoints.down('xs')]: {
      padding: '16px 0px 16px 4px',
    },
  },
  containerBorder: {
    padding: 16,
    borderTop: `1px solid ${theme.branding.v2.gray[200]}`,
    [theme.breakpoints.down('xs')]: {
      padding: '16px 0px 16px 4px',
    },
  },
  dragDots: {
    '& svg > path': {
      fill: theme.branding.v2.gray[400],
    },
  },
  icon: {
    borderRadius: 12,
    width: 48,
    height: 48,
    padding: 12,
    margin: '0px 16px',
    color: theme.branding.blue.primary,
    border: `1px solid ${theme.branding.v2.gray[200]}`,
  },
}));

const HomePageLayoutOption: React.FC<Props> = (props: Props) => {
  const { index, layoutOption } = props;
  const { setFieldValue }: any = useFormikContext();
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [galleryPhotos, setGalleryPhotos] = useState<GalleryPhoto[]>([]);
  const [presales, setPresales] = useState<Presale[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { products, shop } = useAppSelector(state => ({
    products: state.products.products,
    shop: state.shops.shop,
  }));

  const standard = products.filter(p => p.type == 'standard');
  const custom = products.filter(p => p.type == 'custom');
  const events = products.filter(p => p.type == 'event');
  const getAllPresalesService = getService('presales', 'getallpresalesv2');

  const getGalleryPhotos = async () => {
    const photos = await shop.getGalleryPhotos();
    setGalleryPhotos(photos);
    return photos;
  };

  const getPresales = async () => {
    const presales = await getAllPresalesService({});
    setPresales(presales);
    return presales;
  };

  useEffect(() => {
    getGalleryPhotos();
    getPresales();
    const getProducts = async id => {
      await dispatch(getProductsAction(id));
      setIsLoading(false);
    };

    if (shop?.id) {
      getProducts(shop.id);
    }
  }, [shop]);

  const getOptionData = {
    standard: {
      displayText: 'Shop',
      icon: <ShoppingCartOutlined />,
      disabled: standard.length == 0,
    },
    custom: {
      displayText: shop.shopSubpageData?.quotes?.headline || 'Custom Orders',
      icon: <RequestQuoteOutlined />,
      disabled: custom.length == 0,
    },
    presales: {
      displayText: 'Presales',
      icon: <StorefrontOutlinedIcon />,
      disabled: presales.length == 0,
    },
    events: {
      displayText: 'Events',
      icon: <ConfirmationNumberOutlinedIcon />,
      disabled: events.length == 0,
    },
    about: {
      displayText: 'About',
      icon: <ChatBubbleOutlineIcon />,
      disabled: !shop?.shopSubpageData?.isAboutPageEnabled,
    },
    gallery: {
      displayText: 'Gallery',
      icon: <PhotoLibraryOutlined />,
      disabled: galleryPhotos.length == 0,
    },
    contact: {
      displayText: 'Contact',
      icon: <SendOutlinedIcon />,
      disabled: !shop?.shopSubpageData?.isContactPageEnabled,
    },
    email: {
      displayText: 'Email/SMS Capture',
      icon: <MailOutlineRoundedIcon />,
      disabled: true,
      hideToggle: true,
    },
  };

  const selectedOptionData = getOptionData[layoutOption.name] as OptionData;

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={index === 0 ? classes.container : classes.containerBorder}
    >
      <Spinner size="fullscreen" show={isLoading} />
      <Grid item>
        <SvgIcon className={classes.dragDots}>
          <DragIndicator />
        </SvgIcon>
        <SvgIcon className={classes.icon}>{selectedOptionData.icon}</SvgIcon>
        {selectedOptionData.displayText}
      </Grid>
      <Grid item>
        {!selectedOptionData.hideToggle && (
          <FormControlLabel
            disabled={selectedOptionData.disabled}
            control={
              <CustomSwitch
                defaultChecked={layoutOption.checked}
                disabled={selectedOptionData.disabled}
                onChange={e => setFieldValue(`homePageLayout[${index}].checked`, e.target.checked)}
              />
            }
            name={`homePageLayout[${index}]`}
            label=""
          />
        )}
      </Grid>
    </Grid>
  );
};

export default HomePageLayoutOption;
