import { Grid, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import {useAppSelector} from "../../../../hooks";
import {FeatureGate} from "@castiron/components";

const UpdatePlan: React.FC = () => {
  const { values }: any = useFormikContext();

  const { account } = useAppSelector(state => ({
    account: state.shops.account
  }))

  return (
    <Grid
      alignItems="center"
      justify="center"
      container
      direction="column"
      style={{ height: '100%', textAlign: 'center' }}
    >
      <Typography style={{ fontSize: 32, marginBottom: 16 }} variant="h2">
        👩‍💻
      </Typography>
      <Typography style={{ wordBreak: 'break-word' }} variant="h2">
        Ready to make {values.domain} yours?
      </Typography>
      <FeatureGate  name="shop.freeDomain">
        <Typography variant="body1">Switch to our annual subscription plan to claim your free domain</Typography>
      </FeatureGate>
    </Grid>
  );
};

export default UpdatePlan;
