import React from 'react';
import { TextField } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import InputWrapper, { BaseInputProps } from '../InputWrapper';

interface Props extends BaseInputProps {
  name: string;
  options: string[];
  autofocus?: boolean;
  onOptionSelected?: (newValue: string) => void
};

const useStyles = makeStyles((theme: Theme) => ({
  inputError: {
    '& div': {
      '& fieldset': {
        borderColor: theme.palette.error.main,
      },
    },
  },
}));

const AutocompleteInput: React.FC<Props> = (props: Props) => {
  const { name, error, options, autofocus, onOptionSelected } = props;
  const classes = useStyles();

  const { values, handleChange, setFieldValue } = useFormikContext();

  const onInputChange = (event, value, reason) => {
    if (reason === 'reset') {
      onOptionSelected && onOptionSelected(value);
      setFieldValue(name, value);
    }
    if (reason === 'clear') {
      setFieldValue(name, '');
    }
  };

  return (
    <InputWrapper {...props}>
      <Autocomplete
        options={options}
        autoSelect
        freeSolo
        onInputChange={onInputChange}
        inputValue={_.get(values, name)}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus={autofocus}
            variant='outlined'
            className={clsx(error && classes.inputError)}
            style={{ margin: '0px' }}
            name={name}
            onChange={handleChange}
          />
        )}
      />
    </InputWrapper>
  );
};

export default AutocompleteInput;