import React, { useEffect } from 'react';
import { useConfig } from '@castiron/castiron-firebase';
import { LayoutPageProps } from '../Layout';
import Signup from './Signup';
import { useTracking } from '@castiron/utils';
import { setIsOnboarding } from '../../store/reducers/shops';
import { useAppDispatch } from '../../hooks';

const Onboarding: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  //this intermediary is kind of a moot point now, but keeping it in case we do ever use this structure again
  // const config = useConfig();
  // const onboardingVersion = config && config.config().getNumber('feature_onboarding_version');

  return <Signup />;
};

export default Onboarding;
