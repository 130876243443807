import {
  Banner,
  Button,
  DiscardButton,
  IosShareIcon,
  ProductCard,
  SaveButton,
  TextInput,
  ToggleButton,
  ToggleButtonOption,
  Typography,
} from '@castiron/components';
import { getProductStatus, Shop } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Formik, FormikProps } from 'formik';
import _ from 'lodash';
import React, { Fragment, ReactElement, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { openModal } from '../../../store/reducers/modalConductor';
import { updateShopAction } from '../../../store/reducers/shops';
import AdminForm from '../../AdminForm';
import { LayoutPageProps } from '../../Layout';
import ViewShopButton from '../../Layout/Header/ViewShopButton';
import UnsavedChangesPrompt from '../../UnsavedChangesPrompt.tsx';

interface Props extends LayoutPageProps {}

interface FormValues {
  enabled: boolean;
  headline: string;
  description: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  bannerLink: {
    textDecoration: 'underline',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  container: {
    '& > form': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 24,
    },
  },
  errorBanner: {
    marginBottom: 24,
  },
  eventCard: {
    opacity: '64%',
    cursor: 'auto',
  },
  eventsContainer: {
    gap: 24,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(192px, 1fr))',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(172px, 1fr))',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
    },
  },
  inactiveErrorBanner: {
    marginTop: 24,
    [theme.breakpoints.down('sm')]: {
      margin: '0 0 24px 0',
    },
  },
  mobileShareIcon: {
    color: theme.branding.v2.gray[400],
  },
  toggleButton: {
    gap: 8,
    fontWeight: 600,
    lineHeight: '24px',
    '& button': {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
      width: '50%',
    },
  },
  toggleCaption: {
    fontWeight: 400,
    color: theme.branding.v2.gray[700],
  },
}));

const TicketedEventsPage: React.FC<LayoutPageProps> = (props: Props) => {
  const { setPageTitle, setPageIsProFeature, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const history = useHistory();
  const formRef = useRef<FormikProps<FormValues>>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { products, shop } = useAppSelector(state => ({
    products: state.products.products,
    shop: state.shops.shop,
  }));

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showErrors, setShowErrors] = useState<boolean>(false);

  const eventProducts = products.filter(
    p => p.type === 'event' && getProductStatus(p, shop?.config?.timeZone) === 'active',
  );
  const hasEventProducts = !_.isEmpty(eventProducts);

  const handleShareClick = () => {
    dispatch(
      openModal({
        modalType: 'SHARE_LINKS_MODAL',
        modalProps: {
          show: true,
          subdirectory: 'events',
        },
      }),
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    setPageTitle('Events');
    setPageIsProFeature(false);
    setBackLocation(true);
    setHeaderCTAs([
      isMobile ? (
        <IosShareIcon className={classes.mobileShareIcon} onClick={handleShareClick} />
      ) : (
        <Button variant="outlined" onClick={handleShareClick}>
          <IosShareIcon />
          &nbsp;&nbsp; Share
        </Button>
      ),
      <ViewShopButton subdirectory="events" />,
    ]);

    return () => {
      setBackLocation(false);
      setPageTitle('');
      setPageIsProFeature(false);
      setHeaderCTAs([]);
    };
  }, [isMobile]);

  useEffect(() => {
    setFooterCTAs([
      <DiscardButton isSubmitting={isSubmitting} backLocation="/store/pages" />,
      <SaveButton
        formikState={formRef.current}
        isSubmitting={isSubmitting}
        onClick={() => {
          setShowErrors(true);
          window.scrollTo(0, 0);
        }}
      />,
    ]);

    return () => {
      setFooterCTAs([]);
    };
  }, [isSubmitting]);

  const toggleButtonOptions: ToggleButtonOption[] = [
    {
      value: true,
      label: 'Active',
    },
    {
      value: false,
      label: 'Inactive',
    },
  ];

  const getInactiveBannerBlock = () =>
    !hasEventProducts && (
      <Banner variant="error" className={classes.inactiveErrorBanner}>
        <Typography variant="body4" style={{ color: theme.branding.v2.red[900] }}>
          Please add at least one{' '}
          <Link to="/events" className={classes.bannerLink}>
            event
          </Link>{' '}
          to change the status to active.
        </Typography>
      </Banner>
    );

  const getActiveBannerBlock = () => (
    <Banner variant="info-white">
      <Typography variant="body4">
        <span style={{ fontWeight: 700 }}>Note:</span> This page is populated by your events. To make edits to the items
        on this page, visit and edit your{' '}
        <Link to="/events" style={{ color: theme.branding.v2.gray[600], textDecoration: 'underline' }}>
          events
        </Link>
        .
      </Typography>
    </Banner>
  );

  const getToggleButtonBlock = (setFieldValue, values: FormValues) => (
    <Grid container item direction="column" className={classes.toggleButton}>
      <Typography variant="subtitle2">Status</Typography>
      <ToggleButton
        value={values.enabled}
        exclusive
        onChange={(e: React.MouseEvent<HTMLElement>, value): void => {
          setFieldValue('enabled', value);
        }}
        aria-label="page visibility"
        buttonOptions={toggleButtonOptions}
        disabled={!hasEventProducts}
      />
      <Typography variant="caption" className={classes.toggleCaption}>
        Status for this page is automatically set to active when you have at least one active event.
      </Typography>
    </Grid>
  );

  const onSubmit = async (values: FormValues, formikProps: FormikProps<FormValues>) => {
    setIsSubmitting(true);

    try {
      const { enabled, description, headline } = values;

      const newShop = {
        ...shop,
        shopSubpageData: {
          ...shop.shopSubpageData,
          ticketedEvents: {
            ...shop.shopSubpageData?.ticketedEvents,
            enabled,
            description,
            headline,
          },
        },
      } as Shop;

      await dispatch(updateShopAction({ shop: newShop }));

      trackEvent('Shop Events Page Updated');
      {
        headline !== shop.shopSubpageData?.ticketedEvents?.headline &&
          trackEvent('Shop Events Headline Changed', {
            headline,
          });
      }

      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT',
          modalProps: {
            show: true,
            celebrate: true,
            content: (
              <>
                <Typography variant="h4">Events Page Updated!</Typography>
              </>
            ),
          },
        }),
      );

      setIsSubmitting(false);
      resetForm(formikProps);
      history.push('/store/pages');
    } catch (error) {
      setIsSubmitting(false);
      console.error('Error Submitting Shop Events Page Form: ', error);
    }
  };

  const initialValues: FormValues = {
    enabled: (shop?.shopSubpageData?.ticketedEvents?.enabled && hasEventProducts) || false,
    headline: shop?.shopSubpageData?.ticketedEvents?.headline || 'Events',
    description: shop?.shopSubpageData?.ticketedEvents?.description || '',
  };

  const eventsSchema = yup.object().shape({
    enabled: yup.boolean(),
    headline: yup.string().required('Headline is required'),
    description: yup.string().nullable(),
  });

  const resetForm = (formikProps: FormikProps<FormValues>) => {
    formikProps.setSubmitting(false);
    formikProps.resetForm();
    history.push('/store/pages');
  };

  return (
    <Grid container justify="center" className={classes.container}>
      <Helmet>
        <title>Events | Castiron</title>
      </Helmet>
      <Formik
        initialValues={initialValues}
        validationSchema={eventsSchema}
        onSubmit={onSubmit}
        innerRef={formRef}
        enableReinitialize
      >
        {({ dirty, errors, setFieldValue, touched, values }): ReactElement => (
          <AdminForm>
            <div id="error">
              {showErrors && formRef.current.errors.headline && (
                <Banner className={classes.errorBanner} variant="error">
                  <Typography style={{ color: 'inherit' }} variant="body2">
                    Please fill out all required information: headline
                  </Typography>
                </Banner>
              )}
            </div>
            <Grid
              container
              item
              direction={isMobile ? 'column' : 'row-reverse'}
              wrap={isMobile ? 'wrap' : 'nowrap'}
              style={{ gap: 24 }}
            >
              <Grid container item xs={12} md={4}>
                {getToggleButtonBlock(setFieldValue, values)}
              </Grid>
              <Grid container item xs={12} md={8} direction="column" style={{ gap: 24 }}>
                {hasEventProducts ? getActiveBannerBlock() : getInactiveBannerBlock()}
                <TextInput
                  error={touched.headline && errors.headline}
                  helpText="This will be used in your website navigation too so keep it short!"
                  label="Headline"
                  maxLength={15}
                  name="headline"
                  required
                />
                <TextInput
                  error={touched.description && errors.description}
                  label="Description"
                  maxLength={120}
                  multiline
                  name="description"
                  rows={3}
                />
                {hasEventProducts && (
                  <Grid container item direction="column">
                    <Typography variant="subtitle2" style={{ marginBottom: 8 }}>
                      Active Events
                    </Typography>
                    <Grid className={classes.eventsContainer} container item>
                      {eventProducts.map(event => (
                        <Fragment key={event.id}>
                          <ProductCard
                            disabled
                            product={event}
                            onClick={() => {}}
                            type="product"
                            timeZone={shop?.config?.timeZone}
                          />
                        </Fragment>
                      ))}
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {!isSubmitting && <UnsavedChangesPrompt when={dirty} />}
            </Grid>
          </AdminForm>
        )}
      </Formik>
    </Grid>
  );
};

export default TicketedEventsPage;
