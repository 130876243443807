import * as React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const PendingOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_462_18939)">
      <path
        d="M7.99967 1.3335C4.31967 1.3335 1.33301 4.32016 1.33301 8.00016C1.33301 11.6802 4.31967 14.6668 7.99967 14.6668C11.6797 14.6668 14.6663 11.6802 14.6663 8.00016C14.6663 4.32016 11.6797 1.3335 7.99967 1.3335ZM7.99967 13.3335C5.05301 13.3335 2.66634 10.9468 2.66634 8.00016C2.66634 5.0535 5.05301 2.66683 7.99967 2.66683C10.9463 2.66683 13.333 5.0535 13.333 8.00016C13.333 10.9468 10.9463 13.3335 7.99967 13.3335Z"
        fill="#F57F17"
      />
      <path
        d="M4.66602 9C5.2183 9 5.66602 8.55228 5.66602 8C5.66602 7.44772 5.2183 7 4.66602 7C4.11373 7 3.66602 7.44772 3.66602 8C3.66602 8.55228 4.11373 9 4.66602 9Z"
        fill="#F57F17"
      />
      <path
        d="M8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9Z"
        fill="#F57F17"
      />
      <path
        d="M11.333 9C11.8853 9 12.333 8.55228 12.333 8C12.333 7.44772 11.8853 7 11.333 7C10.7807 7 10.333 7.44772 10.333 8C10.333 8.55228 10.7807 9 11.333 9Z"
        fill="#F57F17"
      />
    </g>
    <defs>
      <clipPath id="clip0_462_18939">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
