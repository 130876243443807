import React from 'react';
import { Grid, TextField, Theme, Typography, makeStyles } from '@material-ui/core';
import { FormikErrors, getIn, useFormikContext } from 'formik';
import InputWrapper, { BaseInputProps } from '../InputWrapper';

interface Props extends BaseInputProps {
  name: string;
  rowCount: number;
  value?: string;
  disabled?: boolean;
  className?: string;
  maxLength?: number;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  hasInputBackground?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  inputBackground: {
    backgroundColor: theme.branding.v2.gray[0],
    paddingTop: 4,
    height: 135,
    borderRadius: 4,
  },
  maxLength: {
    bottom: 8,
    position: 'absolute',
    right: 8,
    textAlign: 'right',
  },
}));

const TextAreaInput: React.FC<Props> = (props: Props) => {
  const {
    label,
    secondaryLabel,
    name,
    rowCount,
    required,
    error,
    value,
    disabled = false,
    maxLength,
    className,
    hasInputBackground,
    onChange,
  } = props;

  const formik = useFormikContext();
  const classes = useStyles();

  return (
    <InputWrapper label={label} secondaryLabel={secondaryLabel} required={required} error={error} className={className}>
      <Grid container direction="column" style={{ position: 'relative' }}>
        <TextField
          style={{ margin: '0px' }}
          variant="outlined"
          fullWidth
          name={name}
          multiline
          rows={rowCount}
          value={value ? value : formik.values[name]}
          onChange={onChange ? onChange : formik.handleChange}
          error={!!error}
          disabled={disabled}
          className={`${hasInputBackground && classes.inputBackground}`}
          inputProps={{
            maxLength,
          }}
        />
        {maxLength && (
          <Typography className={classes.maxLength} variant="caption">
            {(value ? value : getIn(formik.values, name)).length}/{maxLength}
          </Typography>
        )}
      </Grid>
    </InputWrapper>
  );
};

export default TextAreaInput;
