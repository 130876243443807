import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const RequestQuoteIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <g clipPath="url(#clip0_53_8990)">
      <path d="M18.6668 2.66663H8.00016C6.5335 2.66663 5.3335 3.86663 5.3335 5.33329V26.6666C5.3335 28.1333 6.5335 29.3333 8.00016 29.3333H24.0002C25.4668 29.3333 26.6668 28.1333 26.6668 26.6666V10.6666L18.6668 2.66663ZM8.00016 26.6666V5.33329H17.3335V10.6666H24.0002V26.6666H8.00016ZM14.6668 25.3333H17.3335V24H18.6668C19.4002 24 20.0002 23.4 20.0002 22.6666V18.6666C20.0002 17.9333 19.4002 17.3333 18.6668 17.3333H14.6668V16H20.0002V13.3333H17.3335V12H14.6668V13.3333H13.3335C12.6002 13.3333 12.0002 13.9333 12.0002 14.6666V18.6666C12.0002 19.4 12.6002 20 13.3335 20H17.3335V21.3333H12.0002V24H14.6668V25.3333Z" />
    </g>
  </SvgIcon>
);
