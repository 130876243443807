import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Grid, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  SaveButton,
  DiscardButton,
  TextInput,
  ToggleButton,
  ToggleButtonOption,
  Typography,
} from '@castiron/components';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Shop } from '@castiron/domain';
import { LayoutPageProps } from '../../Layout';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import AdminForm from '../../AdminForm';
import { useTracking } from '@castiron/utils';
import { shopToEventModel } from '@castiron/domain';
import { useHistory } from 'react-router-dom';
import ViewShopButton from '../../Layout/Header/ViewShopButton';
import UnsavedChangesPrompt from '../../UnsavedChangesPrompt.tsx';
import RichTextInput from '../../RichTextEditor';
import { openModal } from '../../../store/reducers/modalConductor';
import { updateShopAction } from '../../../store/reducers/shops';
import { Helmet } from 'react-helmet';

interface Props extends LayoutPageProps { }

interface FormValues {
  contactPageTitle: string;
  contactPageDescription: string;
  isContactPageEnabled: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  buttonsContainer: {
    paddingTop: 50,
    paddingBottom: 30,
    gap: 8,
    width: '100%',
  },
  footerButton: {
    margin: '0px 4px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  container: {
    '& > form': {
      width: '100%',
    },

    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
  },
  descriptionField: {
    paddingTop: '16px',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '12px 12px 0px 0px',
    maxWidth: '100%',
    '& div': {
      border: 'none',
    },
    '& div.ql-toolbar': {
      borderRadius: '0px 0px 12px 12px',
    },
  },
  toggleWrapper: {
    marginTop: 24,
    fontWeight: 600,
    lineHeight: '24px',
    '& button': {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
      width: '50%',
    },
    '& .MuiToggleButtonGroup-root': {
      color: theme.branding.blue.primary,
      marginBottom: 8,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 24,
    },
  },
  statusText: {
    color: theme.palette.grey[800],
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '21px',
  },
}));

const contactSchema = () =>
  yup.object().shape({
    isContactPageEnabled: yup.boolean(),
    contactPageDescription: yup.string().when('isContactPageEnabled', {
      is: true,
      then: yup.string().required('Description is required if Contact page is enabled'),
    }),
    contactPageTitle: yup.string().when('isContactPageEnabled', {
      is: true,
      then: yup.string().required('Headline is required if Contact page is enabled'),
    }),
  });

const ContactPage: React.FC<LayoutPageProps> = (props: Props) => {
  const { setPageTitle, setPageIsProFeature, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const history = useHistory();
  const formRef = useRef<FormikProps<FormValues>>();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  useEffect(() => {
    setPageTitle('Contact');
    setPageIsProFeature(false);
    setBackLocation(true);
    setHeaderCTAs([<ViewShopButton subdirectory='contact' />]);

    return () => {
      setBackLocation(false);
      setPageTitle('');
      setPageIsProFeature(false);
    };
  }, []);

  useEffect(() => {
    setFooterCTAs([
      <DiscardButton isSubmitting={isSubmitting} backLocation="/store/pages" />,
      <SaveButton formikState={formRef.current} isSubmitting={isSubmitting} />,
    ]);

    return () => {
      setFooterCTAs([]);
    };
  }, [isSubmitting]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const initialValues: FormValues = {
    contactPageTitle: shop.shopSubpageData?.contactPageTitle || 'Get in Touch.',
    contactPageDescription:
      shop.shopSubpageData?.contactPageDescription ||
      "Please provide your details and message below and I'll be in touch soon.",
    isContactPageEnabled: shop?.shopSubpageData?.isContactPageEnabled
      ? shop.shopSubpageData.isContactPageEnabled
      : false,
  };

  const resetForm = (formikProps: FormikProps<FormValues>) => {
    formikProps.setSubmitting(false);
    formikProps.resetForm();
    history.push(`/store/pages`);
  };

  const onSubmit = async (values: FormValues, formikProps: FormikProps<FormValues>) => {
    if (values.contactPageDescription === '<p><br></p>') {
      // This is a hack to get around the fact that the rich text editor is not setting the value to an empty string
      formikProps.setFieldError('contactPageDescription', 'Description is required if Contact page is enabled');
      return;
    } else {
      try {
        setIsSubmitting(true);
        const { contactPageDescription, contactPageTitle, isContactPageEnabled } = values;

        if (isContactPageEnabled !== shop.shopSubpageData?.isContactPageEnabled) {
          trackEvent('Shop Page Visibility Set', {
            page: 'contact',
            enabled: isContactPageEnabled,
            shop: shopToEventModel(shop),
          });
        };


        const newShop = {
          ...shop,
          shopSubpageData: {
            ...shop.shopSubpageData,
            contactPageDescription,
            contactPageTitle,
            isContactPageEnabled: isContactPageEnabled,
          },
        } as Shop;

        await dispatch(updateShopAction({ shop: newShop }));

        trackEvent('Shop Contact Page Updated', {
          shop: shopToEventModel(shop),
        });

        dispatch(
          openModal({
            modalType: 'SIMPLE_ALERT',
            modalProps: {
              show: true,
              celebrate: true,
              content: (
                <>
                  <Typography variant="h4">Contact Page Updated</Typography>
                </>
              ),
            },
          }),
        );
        setIsSubmitting(false);
        resetForm(formikProps);
        history.push('/store/pages');
      } catch (error) {
        setIsSubmitting(false);
        console.error('Error Submitting Shop Contact Page Form: ', error);
      }
    }
  };

  const toggleButtonOptions: ToggleButtonOption[] = [
    {
      value: true,
      label: 'Active',
    },
    {
      value: false,
      label: 'Inactive',
    },
  ];

  return (
    <Grid container justify="center" className={classes.container}>
      <Helmet>
        <title>Contact | Castiron</title>
      </Helmet>
      <Formik initialValues={initialValues} validationSchema={contactSchema} onSubmit={onSubmit} innerRef={formRef}>
        {({ dirty, errors, setFieldValue, touched, isSubmitting, values }): ReactElement => (
          <AdminForm>
            <Grid
              container
              item
              xs={12}
              direction={isMobile ? 'column-reverse' : 'row'}
              spacing={!isMobile && 6}
              wrap={isMobile ? 'wrap' : 'nowrap'}
            >
              <Grid container item xs={12} md={8} direction="column">
                <TextInput
                  label={values.isContactPageEnabled ? 'Headline *' : 'Headline'}
                  name="contactPageTitle"
                  placeholder="About my site"
                  error={touched.contactPageTitle && errors.contactPageTitle}
                />
                <Grid container direction="column" item style={{ marginTop: 24 }}>
                  <Typography variant="subtitle2">
                    Description {values.isContactPageEnabled && <span>*</span>}
                  </Typography>
                  <RichTextInput
                    name="contactPageDescription"
                    placeholder="The contact description will appear on your site’s contact page."
                    className={classes.descriptionField}
                    initialValue={shop.description}
                  />
                  {touched.contactPageDescription && errors.contactPageDescription && (
                    <Typography variant="caption" color="error">
                      {errors.contactPageDescription}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container item xs={12} md={4} direction="column" className={classes.toggleWrapper}>
                <Typography variant="subtitle1" className={classes.statusText}>
                  Status
                </Typography>
                <ToggleButton
                  value={values.isContactPageEnabled}
                  exclusive
                  onChange={(e: React.MouseEvent<HTMLElement>, value): void => {
                    setFieldValue('isContactPageEnabled', value);
                  }}
                  aria-label="page visibility"
                  buttonOptions={toggleButtonOptions}
                />
                <Typography variant="caption">
                  Setting this page to active makes it immediately visible on your website.
                </Typography>
              </Grid>
              {!isSubmitting && <UnsavedChangesPrompt when={dirty} />}
            </Grid>
          </AdminForm>
        )}
      </Formik>
    </Grid>
  );
};

export default ContactPage;
