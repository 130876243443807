import React, { useState } from 'react';
import moment from 'moment-timezone';
import { Box, Container, Grid } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Card, Chip, Pill, Typography } from '@castiron/components';
import Dinero from 'dinero.js';
import CustomerNotes from './CustomerNotes';
import { calculateTotalsForDepositOrder, LineItem, Transaction, TransactionTotals } from '@castiron/domain';
import Price from '../Price';
import Tooltip from '../Tooltip';
import { useAppSelector } from '../../hooks';
import { FeatureFlag } from '@castiron/castiron-firebase';
import _ from 'lodash';
import { Moment } from 'moment';
import momentTimezone from 'moment-timezone';

type Props = {
  overrideTitle?: string;
  transaction?: Transaction;
  subTransaction?: Transaction;
  removePaymentDetails?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  amountsSection: {
    padding: 24,
    width: '100%',
  },
  customOrderDetailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    marginTop: '16px',
    width: '100%',
  },
  customOrderDetails: {
    marginTop: '4px',
    minWidth: '100px',
  },
  customOrderDetailsValues: {
    marginTop: '4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  customDetailsItemContainer: {
    display: 'flex',
    gap: 12,
    padding: '0 8px',
  },
  hasTip: {
    width: '100%',
    padding: 16,
    borderRadius: 8,
    backgroundColor: theme.branding.blue.light,
    [theme.breakpoints.down('sm')]: {
      padding: '12px 12px 8px',
    },
  },
  itemBox: {
    padding: '24px',
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
    alignItems: 'center',
  },
  itemContainer: {
    display: 'flex',
    gap: 12,
    paddingLeft: 10,
  },
  itemDetails: {
    display: 'flex',
    flex: '2',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  itemPrice: {
    alignSelf: 'flex-start',
    fontSize: 14,
    fontWeight: 700,
  },
  notesSection: {
    padding: 24,
    borderTop: `1px solid ${theme.branding.gray[400]}`,
  },
  notesTitle: {
    color: theme.branding.gray[700],
  },
  priceSection: {
    paddingTop: 8,
    paddingBottom: 8,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  subtitle: {
    color: theme.branding.gray[800],
  },
  totalSection: {
    paddingTop: 12,
  },
  variationItem: {
    color: theme.branding.gray[800],
  },
  variationLabel: {
    color: theme.branding.gray[700],
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  selectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    width: '100%',
    marginTop: 16,
  },
  selection: {
    marginTop: '4px',
    minWidth: '100px',
  },
  selectionValues: {
    marginTop: '4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
}));

const DetailsBox: React.FC<Props> = (props: Props) => {
  const { overrideTitle, transaction, subTransaction, removePaymentDetails = false } = props;
  const classes = useStyles();
  const theme = useTheme();

  const capitalize = (val: string) => {
    if (val) return val[0].toUpperCase() + val.slice(1); // to sentence case the fulfillment option type
  };

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const [hasCopied, setHasCopied] = useState<boolean>(false);

  const totals =
    subTransaction && transaction
      ? calculateTotalsForDepositOrder({
          order: transaction.order,
          paymentSettings: shop.paymentSettings,
          subTransactionTotal: subTransaction?.totals?.subtotal,
          tip: transaction?.totals?.tip || 0,
          tippingPresetPercentage: transaction?.totals?.tippingPresetPercentage,
        })
      : transaction?.totals;
  const tip = totals?.tip || 0;
  const couponTotal = totals?.coupon || 0;
  const couponCode = transaction?.coupon?.code || '';
  const couponDiscount = transaction?.coupon?.discount || null;
  const fulfillmentFee = totals?.fulfillmentFee || 0;
  const fulfillmentType = capitalize(transaction?.order?.fulfillmentOption?.type) || '';
  const isFulfillmentInPerson = transaction?.order?.fulfillmentOption?.type === 'inperson';
  const stripeFees = totals?.stripeFees || 0;
  const castIronFees = totals?.castIronFees || 0;
  const customerCastironFeePercent = transaction?.tier.castironTakeRate
    ? totals?.customerFeePercent / transaction?.tier.castironTakeRate
    : 0;
  const customerCastironFeeAmount = Math.round(castIronFees * customerCastironFeePercent);
  const artisanCastironFeePercent = transaction?.tier.castironTakeRate
    ? totals?.artisanFeePercent / transaction?.tier.castironTakeRate
    : 0;
  const artisanCastironFeeAmount = Math.round(castIronFees * artisanCastironFeePercent);
  const customerPaidStripeFees = totals?.customerPaidStripeFees;
  const salesTax = totals?.taxes || 0;
  const total = totals?.total || 0; //subtotal + delivery + fee + tip + salesTax;
  const orderedItems: LineItem[] = transaction?.order?.items
    ? transaction?.order.items
    : transaction?.products.map(p => ({
        id: p.product.id,
        title: p.product.title,
        description: p.product.description,
        category: p.product.category,
        price: p.product.price,
        quantity: p.quantity,
        selections: p.selectedVariationValues,
        type: 'standard',
      }));

  const getPrice = (productPrice, productQuantity) => {
    const curPrice = productPrice * productQuantity;
    return curPrice;
  };

  if (!transaction) return <></>;

  const formatDate = (date: Moment, dateFormat) => {
    return date.tz(shop?.config?.timeZone).format(dateFormat);
  };

  const getEventDetails = (item: LineItem) => {
    const address = item?.eventDetails?.location?.address;
    const inPerson = !_.isEmpty(address);
    const addressText = address?.addressLine1
      ? `${address?.addressLine1}${address?.addressLine2 ? ` ${address?.addressLine2}` : ''}, ${address?.city}, ${
          address?.region
        } ${address?.postalCode}`
      : address?.fullAddress || '';
    const dateFormat = 'MM/DD/YYYY';
    const timeFormat = 'h:mma';
    const startDateMoment = momentTimezone.unix(item?.eventDetails?.date?.startTime);
    const endDateMoment = momentTimezone.unix(item?.eventDetails?.date?.endTime);
    const dateText = `${formatDate(startDateMoment, dateFormat)} ${formatDate(
      startDateMoment,
      timeFormat,
    )} - ${formatDate(endDateMoment, timeFormat)}`;
    return (
      <Grid container item direction="column" style={{ marginTop: '16px', gap: '16px' }}>
        <Pill variant="blue" content={dateText} />
        <Grid container item direction="column">
          <Typography variant="subtitle2" style={{ marginBottom: 8, color: theme.branding.v2.gray[500] }}>
            Location
          </Typography>
          {address?.addressLine1 ? (
            <>
              {item?.eventDetails?.location?.name && (
                <Typography variant="body1">{item?.eventDetails?.location?.name}</Typography>
              )}
              <Typography variant="body1">{address?.addressLine1}</Typography>
              {address?.addressLine2 && <Typography variant="body1">{address?.addressLine2}</Typography>}
              <Typography variant="body1">
                {address?.city}, {address?.region} {address?.postalCode}
              </Typography>
            </>
          ) : address?.fullAddress ? (
            <Typography variant="body1">{address?.fullAddress}</Typography>
          ) : (
            <Typography variant="body1">Online</Typography>
          )}
          <Typography
            variant="body1"
            style={{ cursor: 'pointer', fontWeight: 600, color: theme.branding.v2.blue[500] }}
            onClick={() => {
              if (inPerson) {
                navigator.clipboard
                  .writeText(addressText)
                  .then(() => setHasCopied(true))
                  .catch(error => console.error('Error copying address:', error));
              } else {
                const meetingUrl = item?.eventDetails?.location?.meetingUrl?.startsWith('https')
                  ? item?.eventDetails?.location?.meetingUrl
                  : item?.eventDetails?.location?.meetingUrl?.startsWith('http')
                  ? item?.eventDetails?.location?.meetingUrl?.replace('http', 'https')
                  : `https://${item?.eventDetails?.location?.meetingUrl}`;
                window.open(meetingUrl, '_blank');
              }
            }}
          >
            {inPerson ? (hasCopied ? 'Copied!' : 'Copy Address') : item?.eventDetails?.location?.meetingUrl}
          </Typography>
        </Grid>
        <Grid container item direction="column">
          <Typography variant="subtitle2" style={{ marginBottom: 8, color: theme.branding.v2.gray[500] }}>
            Attendee
          </Typography>
          <Typography variant="body1">{item?.attendees?.[0]?.name}</Typography>
          {item?.attendees?.[0]?.email && <Typography variant="body1">{item?.attendees?.[0]?.email}</Typography>}
          {item?.attendees?.[0]?.mobileNumber && (
            <Typography variant="body1">{item?.attendees?.[0]?.mobileNumber}</Typography>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Card title={overrideTitle || 'Details'} removeChildPadding>
        {orderedItems.length > 0 &&
          orderedItems.map((item, index) => (
            <Box key={item.id}>
              {['standard', 'event'].includes(item.type) ? (
                <Container
                  className={classes.itemBox}
                  style={index === orderedItems.length - 1 ? { borderBottom: 'none' } : {}}
                >
                  <Container className={classes.itemContainer}>
                    <div className={classes.itemDetails}>
                      <Grid container justify="space-between" wrap="nowrap">
                        <Typography variant="body1">{item.title}</Typography>
                        {item?.price && (
                          <Typography className={classes.itemPrice}>
                            {Dinero({ amount: getPrice(item.price, item.quantity) }).toFormat('$0.00')}
                          </Typography>
                        )}
                      </Grid>
                      <Typography variant="body2">
                        Qty: <span style={{ fontWeight: 700 }}>{item.quantity || 0}</span>
                      </Typography>
                      {item.type === 'event' && getEventDetails(item)}
                      {item.notes ? (
                        <Box className={classes.customOrderDetailsContainer}>
                          <Box className={classes.customOrderDetailsValues}>
                            <Typography variant="body2" className={classes.notesTitle}>
                              Notes
                            </Typography>
                            <Typography variant="body1">{item.notes}</Typography>
                          </Box>
                        </Box>
                      ) : (
                        <>
                          {transaction.artisanNotes && (
                            <Box className={classes.customOrderDetailsContainer}>
                              <Box className={classes.customOrderDetailsValues}>
                                <Typography variant="body2" className={classes.notesTitle}>
                                  Notes
                                </Typography>
                                <Typography>{transaction.artisanNotes}</Typography>
                              </Box>
                            </Box>
                          )}
                        </>
                      )}
                      {!transaction.artisanNotes &&
                        item.selections?.map(s => (
                          <Box key={s.inputFieldId} className={classes.selectionContainer}>
                            <Box className={classes.selection}>
                              <Typography className={classes.variationLabel} variant="body2">
                                {s.inputFieldName}
                              </Typography>
                            </Box>
                            <Box className={classes.selectionValues}>
                              {s.selectedValues &&
                                s.selectedValues.map(v => (
                                  <Box key={v.id} className={classes.innerContainer}>
                                    <Typography className={classes.variationItem} variant="body1">
                                      {v.name}
                                    </Typography>
                                    <Price className={classes.itemPrice} amount={v.cost * item.quantity} />
                                  </Box>
                                ))}
                            </Box>
                          </Box>
                        ))}
                    </div>
                  </Container>
                </Container>
              ) : (
                <>
                  {item.type !== 'invoice' && (
                    <Container
                      key={item.id}
                      className={classes.itemBox}
                      style={index === orderedItems.length - 1 ? { borderBottom: 'none' } : {}}
                    >
                      <Container className={classes.itemContainer}>
                        <div className={classes.itemDetails}>
                          <Grid container justify="space-between" wrap="nowrap">
                            <Typography variant="body1">{item.title}</Typography>
                            {item?.price && (
                              <Typography className={classes.itemPrice}>
                                {Dinero({ amount: getPrice(item.price, item.quantity) }).toFormat('$0.00')}
                              </Typography>
                            )}
                          </Grid>
                          <Typography variant="body2" className={classes.subtitle}>
                            Qty: <span style={{ fontWeight: 700 }}>{item.quantity || 0}</span>
                          </Typography>
                          {item.notes ? (
                            <Box className={classes.customOrderDetailsContainer}>
                              <Box className={classes.customOrderDetailsValues}>
                                <Typography variant="body2" className={classes.notesTitle}>
                                  Notes
                                </Typography>
                                <Typography variant="body1">{item.notes}</Typography>
                              </Box>
                            </Box>
                          ) : (
                            <>
                              {transaction.artisanNotes && (
                                <Box className={classes.customOrderDetailsContainer}>
                                  <Box className={classes.customOrderDetailsValues}>
                                    <Typography variant="body2" className={classes.notesTitle}>
                                      Notes
                                    </Typography>
                                    <Typography>{transaction.artisanNotes}</Typography>
                                  </Box>
                                </Box>
                              )}
                            </>
                          )}
                        </div>
                      </Container>
                    </Container>
                  )}
                  {item.subLineItems?.map(subItem => (
                    <Container
                      key={subItem.id}
                      className={classes.itemBox}
                      style={index === orderedItems.length - 1 ? { borderBottom: 'none' } : {}}
                    >
                      <Container className={classes.itemContainer}>
                        <div className={classes.itemDetails}>
                          <Grid container justify="space-between" wrap="nowrap">
                            <Typography variant="body1">{subItem.title}</Typography>
                            {subItem?.price && (
                              <Typography className={classes.itemPrice}>
                                {Dinero({ amount: getPrice(subItem.price, subItem.quantity) }).toFormat('$0.00')}
                              </Typography>
                            )}
                          </Grid>
                          <Typography variant="body2" className={classes.subtitle}>
                            Qty: <span style={{ fontWeight: 700 }}>{subItem.quantity || 0}</span>
                          </Typography>
                          {subItem.notes && (
                            <Box className={classes.customOrderDetailsContainer}>
                              <Box className={classes.customOrderDetailsValues}>
                                <Typography variant="body2" className={classes.notesTitle}>
                                  Notes
                                </Typography>
                                <Typography variant="body1">{subItem.notes}</Typography>
                              </Box>
                            </Box>
                          )}
                        </div>
                      </Container>
                    </Container>
                  ))}
                </>
              )}
            </Box>
          ))}

        {transaction?.notes && (
          <Box className={classes.notesSection}>
            <CustomerNotes notes={transaction.notes} />
          </Box>
        )}
        {isFulfillmentInPerson && transaction?.order?.items[0]?.title && (
          <Box className={classes.notesSection}>
            <Grid container xs={12} justify="space-between">
              <Typography variant="body2" style={{ fontWeight: 600 }}>
                Description
              </Typography>
              <Grid container xs={12} style={{ padding: '6px 0px' }}>
                <Typography variant="body2">{transaction?.order?.items[0]?.title}</Typography>
              </Grid>
            </Grid>
          </Box>
        )}

        {isFulfillmentInPerson && (
          <Box className={classes.notesSection}>
            <Grid container xs={12} justify="space-between">
              <Typography variant="body2">Order Date & Time</Typography>
              <Typography variant="body2">
                {moment
                  .unix(transaction?.createdAt)
                  .tz(shop?.config?.timeZone)
                  .format('MMMM Do YYYY, h:mm:ss A')}
              </Typography>
            </Grid>
          </Box>
        )}

        {/* Fees and Totals Section */}
        {!removePaymentDetails && (
          <Box
            className={classes.amountsSection}
            style={transaction?.notes ? { paddingTop: 0 } : { borderTop: `1px solid ${theme.branding.gray[400]}` }}
          >
            <div className={classes.priceSection}>
              <Typography variant="body2">Subtotal</Typography>
              {transaction?.totals?.subtotal !== undefined && (
                <Typography variant="body2">{Dinero({ amount: totals?.subtotal }).toFormat('$0.00')}</Typography>
              )}
            </div>
            {couponTotal != 0 && (
              <div className={classes.priceSection}>
                <Typography variant="body2">Coupon ({couponCode})</Typography>
                <Typography variant="body2">
                  -{Dinero({ amount: couponTotal || 0 }).toFormat('$0.00')}{' '}
                  {couponDiscount && couponDiscount.type === 'percent' ? `(${couponDiscount.value}%)` : ''}
                </Typography>
              </div>
            )}
            <FeatureFlag name="feature_tipping">
              {tip > 0 && (
                <>
                  <div className={classes.priceSection}>
                    <Typography variant="body2">Tip</Typography>
                    <Typography variant="body2">{Dinero({ amount: tip }).toFormat('$0.00')}</Typography>
                  </div>
                  <div className={classes.hasTip}>
                    <Typography variant="body2">
                      <span>&#128151;</span> You received a tip on this order! Keep up the great work.
                    </Typography>
                  </div>
                </>
              )}
            </FeatureFlag>
            {fulfillmentFee != 0 && (
              <div className={classes.priceSection}>
                <Typography variant="body2">{fulfillmentType}</Typography>
                <Typography variant="body2">{Dinero({ amount: fulfillmentFee || 0 }).toFormat('$0.00')}</Typography>
              </div>
            )}
            {!!customerCastironFeePercent && (
              <div className={classes.priceSection}>
                <Typography variant="body2">
                  Castiron fees ({customerCastironFeePercent * 100}% paid by customer)
                </Typography>
                <Typography variant="body2">
                  {Dinero({ amount: customerCastironFeeAmount }).toFormat('$0.00')}
                </Typography>
              </div>
            )}
            {customerPaidStripeFees && (
              <div className={classes.priceSection}>
                <Typography variant="body2">Credit card processing fees (paid by customer)</Typography>
                <Typography variant="body2">{Dinero({ amount: stripeFees }).toFormat('$0.00')}</Typography>
              </div>
            )}
            {salesTax > 0 && (
              <div className={classes.priceSection}>
                <Typography variant="body2">Sales tax</Typography>
                <Typography variant="body2">{Dinero({ amount: salesTax || 0 }).toFormat('$0.00')}</Typography>
              </div>
            )}
            <div className={`${classes.priceSection} ${classes.totalSection}`}>
              <Typography variant="subtitle3">Total</Typography>
              <Typography variant="subtitle3">{Dinero({ amount: total || 0 }).toFormat('$0.00')}</Typography>
            </div>
          </Box>
        )}
      </Card>
      {!!artisanCastironFeePercent && (
        <Card
          title={
            <div>
              Fees remaining on this order
              <Tooltip title="You don’t need to take any action to pay these fees. If they are still owed, they will be automatically deducted from your next payout" />
            </div>
          }
          removeChildPadding
        >
          <Box className={classes.amountsSection}>
            <div className={classes.priceSection}>
              <Typography variant="body4">Castiron fees ({artisanCastironFeePercent * 100}% paid by you)</Typography>
              <Typography variant="body4">
                {Dinero({
                  amount: artisanCastironFeeAmount,
                }).toFormat('$0.00')}
              </Typography>
            </div>
            {!customerPaidStripeFees && (
              <div className={classes.priceSection}>
                <Typography variant="body4">Credit card processing fees (paid by you)</Typography>
                <Typography variant="body4">{Dinero({ amount: stripeFees }).toFormat('$0.00')}</Typography>
              </div>
            )}
            <div className={`${classes.priceSection} ${classes.totalSection}`}>
              <Typography variant="subtitle3">Total</Typography>
              <Typography variant="subtitle3">
                {Dinero({ amount: artisanCastironFeeAmount + (!customerPaidStripeFees ? stripeFees : 0) }).toFormat(
                  '$0.00',
                )}
              </Typography>
            </div>
          </Box>
        </Card>
      )}
    </>
  );
};

export default DetailsBox;
