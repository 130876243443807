import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const BoxIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1 2.76667V11H11V2.76667H8.66667V8.46667L6 7.13333L3.33333 8.46667V2.76667H1ZM1 12C0.725 12 0.489583 11.9021 0.29375 11.7063C0.0979167 11.5104 0 11.275 0 11V2.18333C0 2.07353 0.0166667 1.96765 0.05 1.86568C0.0833333 1.76373 0.133333 1.66961 0.2 1.58333L1.06667 0.4C1.15556 0.277778 1.27189 0.180556 1.41568 0.108333C1.55947 0.0361111 1.7098 0 1.86667 0H10.1167C10.2735 0 10.4239 0.0361111 10.5677 0.108333C10.7114 0.180556 10.8278 0.277778 10.9167 0.4L11.8 1.58333C11.8667 1.66961 11.9167 1.76373 11.95 1.86568C11.9833 1.96765 12 2.07353 12 2.18333V11C12 11.275 11.9021 11.5104 11.7063 11.7063C11.5104 11.9021 11.275 12 11 12H1ZM1.28333 1.76667H10.7L10.0932 1H1.88333L1.28333 1.76667ZM4.33333 2.76667V6.85L6 6.01667L7.66667 6.85V2.76667H4.33333Z"
      fill="#18181B"
    />
  </SvgIcon>
);
