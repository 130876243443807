import * as yup from 'yup';
import { addressSchema } from '../address';

export interface ProductSeoMetadata {
  manuallyUpdated?: boolean;
  title: string;
  description: string;
}

export const eventDetailsSchema = yup
  .object()
  .shape({
    date: yup
      .object()
      .shape({
        startTime: yup.number().required(),
        endTime: yup.number().required(),
      })
      .required(),
    location: yup.object().shape({
      address: addressSchema(false),
      name: yup.string().nullable(),
      notes: yup.string().nullable(),
      meetingUrl: yup.string().nullable(),
    }),
    sendReminderEmail: yup.boolean().nullable(),
    reminderEmailSent: yup.boolean().nullable(),
  })
  .default(undefined);
