import { DuplicateIcon, RubbishBinIcon, SvgIcon, Typography } from '@castiron/components';
import { Presale } from '@castiron/domain';
import { defaultTimeZone, download, kebabCase, removeEmpty, useTracking } from '@castiron/utils';
import { ButtonBase } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { ArchiveOutlined, Link, UnarchiveOutlined } from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PencilIcon from '../../assets/img/pencil.svg';
import { presaleRepository } from '../../domain';
import { getService } from '../../firebase';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getShopLink } from '../../lib/domainUtils';
import { closeModal, openModal } from '../../store/reducers/modalConductor';
import Spinner from '../Spinner';
import DeleteButton from '../Store/Fulfillment/DeleteButton';

const exportOrdersByProductService = getService('orders', 'exportordersbyproduct', { type: 'request' });

type Props = {
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
  onRefresh?: () => void;
  presale: Presale;
  variant: 'deleteButton' | 'actionsMenu';
};

const useStyles = makeStyles((theme: Theme) => ({
  buttonBase: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingRight: 8,
    '&:hover': {
      backgroundColor: `${theme.branding.gray[600]}4D`,
    },
  },
  copiedSuccess: {
    width: '100%',
    height: 36,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: 14,
    color: theme.branding.gray[700],
    padding: '5px 10px',
  },
  delete: {
    color: theme.palette.error.main,
  },
  icon: {
    height: '24px',
    width: '24px',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '36px',
    width: '36px',
  },
  menuLabel: {
    marginLeft: 8,
    fontWeight: 600,
    fontSize: 14,
  },
  rubbishBinIcon: {
    height: '36px',
    width: 'auto',
    color: theme.palette.error.main,
    paddingLeft: '8px',
  },
}));

const PresaleActionsMenu: React.FC<Props> = (props: Props) => {
  const { onClose, onRefresh, presale, variant } = props;
  const classes = useStyles();
  const { trackEvent } = useTracking();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const presaleId = presale?.id;

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const [isDownloadingReport, setIsDownloadingReport] = useState<boolean>(false);
  const [copySuccess, setCopySuccess] = useState<boolean>(false);
  const shopLink = getShopLink(shop);

  const onEditClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    history.push(`/presales/edit/${presaleId}`);
    onClose && onClose(event);
  }, []);

  const handleDuplicate = useCallback(async e => {
    e.stopPropagation();
    const newPresale = await presaleRepository.create(
      removeEmpty({
        title: `Copy of ${presale?.title || ''}`,
        description: presale?.description,
        status: presale?.status,
        fulfillmentIds: presale?.fulfillmentIds,
        productIds: presale?.productIds,
        images: presale?.images,
        schedule: presale?.schedule,
        isFeatured: presale?.isFeatured,
        shopId: shop.id,
      }) as Presale,
    );
    trackEvent('Presale Duplicated', { presale: { id: presaleId } });
    history.push(`/presales/edit/${newPresale.id}`);
  }, []);

  const handleCopyLink = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setCopySuccess(true);
    navigator.clipboard.writeText(`${shopLink}/presale/${kebabCase(presale.title)}/${presaleId}`);
    trackEvent('Presale Link Copied', { presale: { id: presaleId } });
    setTimeout(() => {
      onClose && onClose(event);
    }, 1200);

    setTimeout(() => {
      setCopySuccess(false);
    }, 1500);
  };

  const downloadReport = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setIsDownloadingReport(true);

    const csv = await exportOrdersByProductService({
      context: 'presale',
      id: presale.id,
      timeZone: shop?.config?.timeZone || defaultTimeZone,
    });

    const date = moment().format('MMDDYYYY');
    const filename = `${shop.websiteUrl}-orders-by-product-${date}.csv`;

    download(filename, 'text/csv', csv);

    trackEvent('Orders by Product Report Downloaded');

    setIsDownloadingReport(false);
  };

  const changeStatus = async (event: React.MouseEvent<HTMLElement>, newStatus: 'archived' | 'active') => {
    event.stopPropagation();
    await presaleRepository.updateProps(presale.id, { status: newStatus, schedule: {} });
    onRefresh && onRefresh();
    onClose && onClose(event);
  };
  const onArchiveClick = async (event: React.MouseEvent<HTMLElement>) => await changeStatus(event, 'archived');

  const onUnarchiveClick = async (event: React.MouseEvent<HTMLElement>) => await changeStatus(event, 'active');

  const onDelete = async () => {
    await presaleRepository.delete(presaleId);
    trackEvent('Presale Deleted', { presale: { id: presaleId } });
    dispatch(closeModal());
    if (variant === 'deleteButton') {
      history.push('/presales');
    } else if (variant === 'actionsMenu') {
      onRefresh && onRefresh();
    }
  };

  const openDeleteModal = () => {
    dispatch(
      openModal({
        modalType: 'DELETE_MODAL',
        modalProps: {
          show: true,
          onDelete,
        },
      }),
    );
  };

  const onDeleteClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    //technically not deleted unless they respond to modal
    openDeleteModal();
    onClose && onClose(event);
  }, []);

  const deleteButton = <DeleteButton variant="footer" onDelete={openDeleteModal} />;

  const actionsMenu = (
    <>
      <Spinner show={isDownloadingReport} size={'fullscreen'} label="Gathering your data…" />
      <ButtonBase disabled={copySuccess} onClick={onEditClick} focusRipple className={classes.buttonBase}>
        <SvgIcon className={classes.iconContainer}>
          <PencilIcon />
        </SvgIcon>
        <Typography className={classes.menuLabel}>Edit</Typography>
      </ButtonBase>
      <ButtonBase disabled={copySuccess} focusRipple className={classes.buttonBase} onClick={handleDuplicate}>
        <div className={classes.iconContainer}>
          <DuplicateIcon className={classes.icon} />
        </div>
        <Typography className={classes.menuLabel}>Duplicate</Typography>
      </ButtonBase>
      {copySuccess ? (
        <div className={classes.copiedSuccess}>Link copied!</div>
      ) : (
        <ButtonBase focusRipple className={classes.buttonBase} onClick={handleCopyLink}>
          <div className={classes.iconContainer}>
            <Link className={classes.icon} />
          </div>
          <Typography className={classes.menuLabel}>Copy link</Typography>
        </ButtonBase>
      )}
      <ButtonBase focusRipple className={classes.buttonBase} onClick={downloadReport}>
        <div className={classes.iconContainer}>
          <GetAppIcon className={classes.icon} />
        </div>
        <Typography className={classes.menuLabel}>Download Report</Typography>
      </ButtonBase>
      {presale?.status === 'archived' ? (
        <ButtonBase disabled={copySuccess} focusRipple onClick={onUnarchiveClick} className={classes.buttonBase}>
          <div className={classes.iconContainer}>
            <UnarchiveOutlined className={classes.icon} />
          </div>
          <Typography className={classes.menuLabel}>Unarchive</Typography>
        </ButtonBase>
      ) : (
        <ButtonBase disabled={copySuccess} focusRipple onClick={onArchiveClick} className={classes.buttonBase}>
          <div className={classes.iconContainer}>
            <ArchiveOutlined className={classes.icon} />
          </div>
          <Typography className={classes.menuLabel}>Archive</Typography>
        </ButtonBase>
      )}
      <ButtonBase disabled={copySuccess} focusRipple onClick={onDeleteClick} className={classes.buttonBase}>
        <div className={classes.iconContainer}>
          <RubbishBinIcon className={classes.rubbishBinIcon} />
        </div>
        <Typography className={`${classes.menuLabel} ${classes.delete}`}>Delete</Typography>
      </ButtonBase>
    </>
  );

  const actions = {
    deleteButton,
    actionsMenu,
  };

  return actions[variant];
};

export default PresaleActionsMenu;
