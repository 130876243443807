import { Shop } from '@castiron/domain';

export const getShopLink = (shop: Shop): string => {
  const isCustomDomain = shop?.config?.domainStatus === 'verified';
  const customUrl = shop?.config?.domains
    ? shop?.config?.domains.find(d => d.startsWith('www')) || shop?.config?.domains[0]
    : undefined;
  const shopLink = isCustomDomain && customUrl ? customUrl : `${process.env.REACT_APP_SHOP_URL}${shop?.websiteUrl}`;
  return shopLink;
};
