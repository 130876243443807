import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const EmptyProductsIcon = (props: SvgIconProps) => (
  <SvgIcon width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="60" cy="60" r="60" fill="#E0ECFF" fillOpacity="0.48" />
    <g clipPath="url(#clip0_941_39132)">
      <path
        d="M60.9016 8.22686L57.1593 9.19919L11.0537 21.1785L7.3114 22.1508C5.49494 22.625 3.93933 23.8057 2.98586 25.434C2.03238 27.0623 1.75891 29.0052 2.22543 30.8364L18.4528 94.2365C18.9234 96.0667 20.0953 97.634 21.7114 98.5947C23.3276 99.5553 25.2559 99.8309 27.0734 99.3608L27.0831 99.3583L80.654 85.4394L80.6636 85.4369C82.4801 84.9627 84.0357 83.782 84.9892 82.1536C85.9427 80.5253 86.2161 78.5825 85.7496 76.7513L69.5223 13.3512C69.0517 11.521 67.8797 9.9537 66.2636 8.99303C64.6475 8.03237 62.7191 7.75683 60.9016 8.22686Z"
        fill="#E0ECFF"
      />
      <path
        d="M61.4096 10.2146L56.9772 11.3662L12.2518 22.9869L7.81937 24.1385C6.52582 24.4762 5.41803 25.317 4.73903 26.4766C4.06004 27.6361 3.86529 29.0197 4.19751 30.3238L20.4249 93.7238C20.76 95.0271 21.5946 96.1433 22.7454 96.8274C23.8963 97.5115 25.2695 97.7077 26.5638 97.373L26.5735 97.3704L80.1444 83.4515L80.1541 83.449C81.4476 83.1113 82.5554 82.2705 83.2344 81.1109C83.9134 79.9514 84.1081 78.5678 83.7759 77.2638L67.5485 13.8637C67.2134 12.5604 66.3789 11.4443 65.228 10.7602C64.0771 10.0761 62.7039 9.87985 61.4096 10.2146Z"
        fill="white"
      />
      <path
        d="M58.0633 30.745L30.9631 37.7863C30.6605 37.8649 30.3392 37.8192 30.0699 37.6592C29.8007 37.4991 29.6056 37.2379 29.5276 36.933C29.4495 36.628 29.4949 36.3043 29.6537 36.033C29.8126 35.7618 30.0718 35.5652 30.3745 35.4866L57.4747 28.4453C57.7773 28.367 58.0984 28.4129 58.3674 28.573C58.6364 28.733 58.8313 28.9941 58.9093 29.2989C58.9873 29.6037 58.942 29.9272 58.7834 30.1984C58.6248 30.4696 58.3658 30.6662 58.0633 30.745Z"
        fill="#E0ECFF"
      />
      <path
        d="M63.7642 33.4028L31.9563 41.6672C31.6536 41.7458 31.3323 41.7001 31.0631 41.5401C30.7939 41.38 30.5988 41.1188 30.5207 40.8139C30.4427 40.5089 30.4881 40.1852 30.6469 39.9139C30.8057 39.6427 31.065 39.4461 31.3677 39.3675L63.1756 31.1031C63.4783 31.0244 63.7996 31.0701 64.0688 31.2302C64.338 31.3902 64.5331 31.6514 64.6112 31.9564C64.6892 32.2613 64.6439 32.5851 64.485 32.8563C64.3262 33.1276 64.0669 33.3241 63.7642 33.4028Z"
        fill="#E0ECFF"
      />
      <path
        d="M62.5145 48.1367L35.4143 55.1779C35.2644 55.2169 35.1084 55.2257 34.9552 55.2039C34.8019 55.182 34.6544 55.13 34.5211 55.0508C34.3878 54.9716 34.2713 54.8666 34.1782 54.742C34.0852 54.6174 34.0174 54.4756 33.9787 54.3246C33.9401 54.1736 33.9313 54.0164 33.953 53.862C33.9746 53.7076 34.0263 53.559 34.1049 53.4246C34.1835 53.2903 34.2877 53.173 34.4114 53.0792C34.535 52.9854 34.6758 52.9171 34.8257 52.8782L61.9259 45.8369C62.0758 45.798 62.2318 45.7892 62.385 45.811C62.5383 45.8328 62.6858 45.8848 62.8191 45.964C62.9524 46.0433 63.0689 46.1482 63.162 46.2728C63.255 46.3974 63.3228 46.5393 63.3615 46.6903C63.4001 46.8413 63.4089 46.9985 63.3872 47.1529C63.3656 47.3073 63.314 47.4559 63.2353 47.5902C63.1567 47.7245 63.0525 47.8419 62.9289 47.9357C62.8052 48.0294 62.6644 48.0977 62.5145 48.1367Z"
        fill="#E0ECFF"
      />
      <path
        d="M68.2154 50.7944L36.4075 59.0588C36.2576 59.0977 36.1016 59.1066 35.9483 59.0847C35.7951 59.0629 35.6476 59.0109 35.5143 58.9317C35.381 58.8524 35.2645 58.7475 35.1714 58.6229C35.0783 58.4983 35.0106 58.3565 34.9719 58.2055C34.9333 58.0545 34.9245 57.8973 34.9462 57.7429C34.9678 57.5884 35.0194 57.4398 35.0981 57.3055C35.1767 57.1712 35.2808 57.0538 35.4045 56.9601C35.5282 56.8663 35.669 56.798 35.8189 56.7591L67.6268 48.4947C67.9295 48.416 68.2507 48.4617 68.52 48.6218C68.7892 48.7818 68.9843 49.043 69.0623 49.348C69.1404 49.653 69.095 49.9767 68.9362 50.2479C68.7774 50.5192 68.5181 50.7157 68.2154 50.7944Z"
        fill="#E0ECFF"
      />
      <path
        d="M66.9659 65.5285L39.8657 72.5698C39.5631 72.6482 39.242 72.6023 38.9729 72.4422C38.7039 72.2822 38.5089 72.021 38.4309 71.7162C38.3529 71.4114 38.3982 71.0878 38.5568 70.8166C38.7155 70.5455 38.9746 70.3489 39.2771 70.27L66.3773 63.2288C66.6799 63.1501 67.0012 63.1959 67.2705 63.3559C67.5397 63.5159 67.7348 63.7772 67.8128 64.0821C67.8909 64.3871 67.8455 64.7108 67.6867 64.982C67.5278 65.2533 67.2686 65.4499 66.9659 65.5285Z"
        fill="#E0ECFF"
      />
      <path
        d="M72.6666 68.186L40.8587 76.4504C40.7088 76.4895 40.5527 76.4984 40.3993 76.4767C40.2459 76.455 40.0983 76.403 39.9649 76.3238C39.8315 76.2446 39.7149 76.1397 39.6218 76.015C39.5286 75.8903 39.4608 75.7484 39.4221 75.5973C39.3834 75.4462 39.3747 75.289 39.3964 75.1345C39.4181 74.98 39.4698 74.8313 39.5485 74.6969C39.6273 74.5626 39.7315 74.4452 39.8553 74.3515C39.9792 74.2577 40.1201 74.1895 40.2701 74.1507L72.078 65.8863C72.3807 65.8076 72.702 65.8533 72.9712 66.0134C73.2404 66.1734 73.4355 66.4346 73.5136 66.7396C73.5916 67.0446 73.5462 67.3683 73.3874 67.6395C73.2286 67.9108 72.9693 68.1073 72.6666 68.186Z"
        fill="#E0ECFF"
      />
      <path
        d="M26.337 45.0062L16.7465 47.4981C16.6014 47.5356 16.4474 47.5136 16.3183 47.4369C16.1893 47.3602 16.0957 47.235 16.0581 47.0889L13.8566 38.4877C13.8194 38.3415 13.8412 38.1863 13.9174 38.0563C13.9935 37.9263 14.1177 37.832 14.2628 37.7942L23.8533 35.3023C23.9984 35.2648 24.1524 35.2868 24.2814 35.3635C24.4105 35.4402 24.504 35.5654 24.5416 35.7115L26.7431 44.3127C26.7804 44.4589 26.7585 44.6141 26.6824 44.7441C26.6063 44.8741 26.482 44.9684 26.337 45.0062Z"
        fill="#E0ECFF"
      />
      <path
        d="M30.7882 62.3978L21.1977 64.8897C21.0525 64.9272 20.8985 64.9052 20.7695 64.8285C20.6404 64.7518 20.5469 64.6266 20.5093 64.4805L18.3078 55.8793C18.2706 55.7331 18.2924 55.5779 18.3685 55.4479C18.4447 55.3179 18.5689 55.2236 18.7139 55.1858L28.3044 52.6939C28.4496 52.6564 28.6035 52.6784 28.7326 52.7551C28.8616 52.8318 28.9552 52.957 28.9928 53.1031L31.1943 61.7043C31.2315 61.8505 31.2097 62.0057 31.1336 62.1357C31.0574 62.2657 30.9332 62.36 30.7882 62.3978Z"
        fill="#E0ECFF"
      />
      <path
        d="M35.2393 79.7894L25.6488 82.2813C25.5037 82.3188 25.3497 82.2968 25.2207 82.2201C25.0916 82.1434 24.998 82.0183 24.9605 81.8721L22.759 73.2709C22.7217 73.1247 22.7436 72.9695 22.8197 72.8395C22.8958 72.7095 23.02 72.6152 23.1651 72.5774L32.7556 70.0855C32.9007 70.048 33.0547 70.07 33.1838 70.1467C33.3128 70.2234 33.4064 70.3486 33.444 70.4947L35.6454 79.0959C35.6827 79.2421 35.6609 79.3973 35.5847 79.5273C35.5086 79.6573 35.3844 79.7516 35.2393 79.7894Z"
        fill="#E0ECFF"
      />
      <path
        d="M98.2774 24.2979H42.934C41.0575 24.3 39.2586 25.052 37.9317 26.3888C36.6049 27.7256 35.8586 29.5382 35.8564 31.4287V96.903C35.8586 98.7936 36.6049 100.606 37.9317 101.943C39.2586 103.28 41.0575 104.032 42.934 104.034H98.2774C100.154 104.032 101.953 103.28 103.28 101.943C104.606 100.606 105.353 98.7936 105.355 96.903V31.4287C105.353 29.5382 104.606 27.7256 103.28 26.3888C101.953 25.052 100.154 24.3 98.2774 24.2979Z"
        fill="#E0ECFF"
      />
      <path
        d="M98.2781 26.3506H42.9346C41.5984 26.3521 40.3173 26.8876 39.3724 27.8396C38.4275 28.7916 37.896 30.0823 37.8945 31.4286V96.9029C37.896 98.2492 38.4275 99.54 39.3724 100.492C40.3173 101.444 41.5984 101.979 42.9346 101.981H98.2781C99.6144 101.979 100.895 101.444 101.84 100.492C102.785 99.5399 103.317 98.2492 103.318 96.9029V31.4286C103.317 30.0823 102.785 28.7916 101.84 27.8396C100.895 26.8876 99.6144 26.3521 98.2781 26.3506Z"
        fill="white"
      />
      <path
        d="M104.092 112C111.227 112 117.011 106.172 117.011 98.9829C117.011 91.7938 111.227 85.9658 104.092 85.9658C96.9563 85.9658 91.1719 91.7938 91.1719 98.9829C91.1719 106.172 96.9563 112 104.092 112Z"
        fill="#005FFF"
      />
      <path
        d="M110.271 97.2851H105.777V92.7575C105.777 92.3071 105.599 91.8753 105.283 91.5569C104.967 91.2385 104.539 91.0596 104.092 91.0596C103.645 91.0596 103.216 91.2385 102.9 91.5569C102.584 91.8753 102.407 92.3071 102.407 92.7575V97.2851H97.9127C97.4658 97.2851 97.0372 97.464 96.7211 97.7824C96.4051 98.1008 96.2275 98.5327 96.2275 98.983C96.2275 99.4333 96.4051 99.8652 96.7211 100.184C97.0372 100.502 97.4658 100.681 97.9127 100.681H102.407V105.209C102.407 105.659 102.584 106.091 102.9 106.409C103.216 106.728 103.645 106.906 104.092 106.906C104.539 106.906 104.967 106.728 105.283 106.409C105.599 106.091 105.777 105.659 105.777 105.209V100.681H110.271C110.718 100.681 111.146 100.502 111.462 100.184C111.778 99.8652 111.956 99.4333 111.956 98.983C111.956 98.5327 111.778 98.1008 111.462 97.7824C111.146 97.464 110.718 97.2851 110.271 97.2851Z"
        fill="white"
      />
      <path
        d="M89.949 62.3286H61.9621C61.8073 62.3287 61.6539 62.2982 61.5107 62.2386C61.3676 62.179 61.2375 62.0915 61.1279 61.9812C61.0183 61.871 60.9314 61.74 60.8721 61.5958C60.8128 61.4517 60.7822 61.2971 60.7822 61.1411C60.7822 60.985 60.8128 60.8305 60.8721 60.6864C60.9314 60.5422 61.0183 60.4112 61.1279 60.3009C61.2375 60.1907 61.3676 60.1032 61.5107 60.0436C61.6539 59.984 61.8073 59.9534 61.9621 59.9536H89.949C90.2613 59.954 90.5608 60.0792 90.7815 60.3019C91.0023 60.5245 91.1262 60.8264 91.1262 61.1411C91.1262 61.4558 91.0023 61.7576 90.7815 61.9803C90.5608 62.203 90.2613 62.3282 89.949 62.3286Z"
        fill="#E0ECFF"
      />
      <path
        d="M94.8107 66.3364H61.9621C61.8073 66.3366 61.6539 66.306 61.5107 66.2464C61.3676 66.1868 61.2375 66.0993 61.1279 65.989C61.0183 65.8788 60.9314 65.7478 60.8721 65.6036C60.8128 65.4595 60.7822 65.305 60.7822 65.1489C60.7822 64.9928 60.8128 64.8383 60.8721 64.6942C60.9314 64.55 61.0183 64.419 61.1279 64.3088C61.2375 64.1985 61.3676 64.111 61.5107 64.0514C61.6539 63.9918 61.8073 63.9613 61.9621 63.9614H94.8107C95.1233 63.9614 95.423 64.0865 95.6441 64.3092C95.8651 64.5319 95.9893 64.834 95.9893 65.1489C95.9893 65.4638 95.8651 65.7659 95.6441 65.9886C95.423 66.2113 95.1233 66.3364 94.8107 66.3364Z"
        fill="#E0ECFF"
      />
      <path
        d="M89.949 80.2893H61.9621C61.8073 80.2894 61.6539 80.2589 61.5107 80.1993C61.3676 80.1397 61.2375 80.0522 61.1279 79.9419C61.0183 79.8316 60.9314 79.7007 60.8721 79.5565C60.8128 79.4124 60.7822 79.2578 60.7822 79.1018C60.7822 78.9457 60.8128 78.7912 60.8721 78.6471C60.9314 78.5029 61.0183 78.3719 61.1279 78.2616C61.2375 78.1514 61.3676 78.0639 61.5107 78.0043C61.6539 77.9447 61.8073 77.9141 61.9621 77.9143H89.949C90.2615 77.9143 90.5613 78.0394 90.7824 78.2621C91.0034 78.4848 91.1276 78.7868 91.1276 79.1018C91.1276 79.4167 91.0034 79.7188 90.7824 79.9415C90.5613 80.1642 90.2615 80.2893 89.949 80.2893Z"
        fill="#E0ECFF"
      />
      <path
        d="M94.8107 84.2971H61.9621C61.8073 84.2973 61.6539 84.2667 61.5107 84.2071C61.3676 84.1475 61.2375 84.06 61.1279 83.9497C61.0183 83.8395 60.9314 83.7085 60.8721 83.5643C60.8128 83.4202 60.7822 83.2657 60.7822 83.1096C60.7822 82.9536 60.8128 82.799 60.8721 82.6549C60.9314 82.5107 61.0183 82.3797 61.1279 82.2695C61.2375 82.1592 61.3676 82.0717 61.5107 82.0121C61.6539 81.9525 61.8073 81.9219 61.9621 81.9221H94.8107C94.9656 81.9219 95.119 81.9525 95.2621 82.0121C95.4053 82.0717 95.5354 82.1592 95.6449 82.2695C95.7545 82.3797 95.8414 82.5107 95.9008 82.6549C95.9601 82.799 95.9906 82.9536 95.9906 83.1096C95.9906 83.2657 95.9601 83.4202 95.9008 83.5643C95.8414 83.7085 95.7545 83.8395 95.6449 83.9497C95.5354 84.06 95.4053 84.1475 95.2621 84.2071C95.119 84.2667 94.9656 84.2973 94.8107 84.2971Z"
        fill="#E0ECFF"
      />
      <path
        d="M55.6921 68.1557H45.7878C45.638 68.1555 45.4943 68.0955 45.3884 67.9887C45.2824 67.882 45.2228 67.7372 45.2227 67.5863V58.7037C45.2228 58.5527 45.2824 58.408 45.3884 58.3012C45.4943 58.1945 45.638 58.1344 45.7878 58.1343H55.6921C55.8419 58.1344 55.9855 58.1945 56.0915 58.3012C56.1974 58.408 56.257 58.5527 56.2572 58.7037V67.5863C56.257 67.7372 56.1974 67.882 56.0915 67.9887C55.9855 68.0955 55.8419 68.1555 55.6921 68.1557Z"
        fill="#E0ECFF"
      />
      <path
        d="M55.6921 86.1164H45.7878C45.638 86.1162 45.4943 86.0562 45.3884 85.9494C45.2824 85.8427 45.2228 85.6979 45.2227 85.547V76.6644C45.2228 76.5134 45.2824 76.3687 45.3884 76.2619C45.4943 76.1552 45.638 76.0951 45.7878 76.095H55.6921C55.8419 76.0951 55.9855 76.1552 56.0915 76.2619C56.1974 76.3687 56.257 76.5134 56.2572 76.6644V85.547C56.257 85.6979 56.1974 85.8427 56.0915 85.9494C55.9855 86.0562 55.8419 86.1162 55.6921 86.1164Z"
        fill="#E0ECFF"
      />
      <path
        d="M89.9791 42.3349H69.3456C69.033 42.3349 68.7332 42.2098 68.5122 41.9871C68.2912 41.7644 68.167 41.4624 68.167 41.1474C68.167 40.8325 68.2912 40.5305 68.5122 40.3078C68.7332 40.0851 69.033 39.96 69.3456 39.96H89.9791C90.2917 39.96 90.5915 40.0851 90.8125 40.3078C91.0336 40.5305 91.1577 40.8325 91.1577 41.1474C91.1577 41.4624 91.0336 41.7644 90.8125 41.9871C90.5915 42.2098 90.2917 42.3349 89.9791 42.3349Z"
        fill="#005FFF"
      />
      <path
        d="M94.8409 46.3427H69.3456C69.1908 46.3427 69.0376 46.312 68.8946 46.2523C68.7516 46.1927 68.6216 46.1052 68.5122 45.9949C68.4028 45.8847 68.3159 45.7537 68.2567 45.6097C68.1975 45.4656 68.167 45.3112 68.167 45.1553C68.167 44.9993 68.1975 44.8449 68.2567 44.7008C68.3159 44.5568 68.4028 44.4258 68.5122 44.3156C68.6216 44.2053 68.7516 44.1178 68.8946 44.0582C69.0376 43.9985 69.1908 43.9678 69.3456 43.9678H94.8409C95.1534 43.9678 95.4532 44.0929 95.6742 44.3156C95.8953 44.5383 96.0195 44.8403 96.0195 45.1553C96.0195 45.4702 95.8953 45.7722 95.6742 45.9949C95.4532 46.2176 95.1534 46.3427 94.8409 46.3427Z"
        fill="#005FFF"
      />
      <path
        d="M64.7799 51.1995H45.7575C45.6077 51.1994 45.464 51.1393 45.3581 51.0326C45.2521 50.9258 45.1925 50.7811 45.1924 50.6301V35.6724C45.1925 35.5215 45.2521 35.3767 45.3581 35.27C45.464 35.1632 45.6077 35.1032 45.7575 35.103H64.7799C64.9297 35.1032 65.0733 35.1632 65.1793 35.27C65.2852 35.3767 65.3448 35.5215 65.345 35.6724V50.6301C65.3448 50.7811 65.2852 50.9258 65.1793 51.0326C65.0733 51.1393 64.9297 51.1994 64.7799 51.1995Z"
        fill="#005FFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_941_39132">
        <rect width="115.012" height="104" fill="white" transform="translate(2 8)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
