import React, {useContext, useEffect} from "react";
import {Attribution, Session} from "@castiron/domain";
import {AttributionContext, AttributionData} from "@castiron/utils";
import {useState} from "react";
import moment from "moment";
import _ from "lodash";

interface SessionContextData {
  session?: Session;
  updateSession?: (s: Session) => Promise<void>;
}

export interface SessionManager {
  get: (id: string) => Promise<Session>;
  create: (session: Session) => Promise<Session>;
  update: (session: Session) => Promise<Session>;
}
export const SessionContext = React.createContext<SessionContextData>(undefined);

interface Props {
  sessionManager: SessionManager;
  sid?: string;
}

const SID_NAME = 'castiron-sid';

export const SessionConfiguration: React.FC<Props> = (props) => {
  const { children, sessionManager, sid } = props;

  const [session, setSession] = useState<Session>();

  const {attribution, updateAttribution} = useContext<AttributionData>(AttributionContext);

  useEffect(() => {
    const storedId = sessionStorage.getItem(SID_NAME);

    if ((sid || storedId) && !session) {
      const sessionId = sid || storedId;
      console.debug(`Finding existing session with id [${sessionId}]`);
      sessionManager.get(sessionId).then(s => {
        if (s) {
          console.debug(`Found existing session [${s.id}]`);
          setSession(s);
          updateAttribution(s.attribution)
        } else {
          sessionManager.create({
            attribution,
            ttl: moment().add(2, "days").unix()
          }).then((createdSession) => {
            setSession(createdSession);
            sessionStorage.setItem(SID_NAME, createdSession.id);
          });
        }
      })
    } else if (attribution && !session) {
      console.debug(`Creating new Session`);
      sessionManager.create({
        attribution,
        ttl: moment().add(2, "days").unix()
      }).then((createdSession) => {
        setSession(createdSession);
        sessionStorage.setItem(SID_NAME, createdSession.id);
      });
    } else if (session) {
      console.debug('Updating Session Attribution')
      if (!_.isEqual(attribution, session.attribution)) {
        setSession(s => {
          const newSession = {
            ...s,
            attribution
          };
          sessionManager.update(newSession);
          return newSession;
        });
      }
    }
  }, [attribution]);

  return <SessionContext.Provider value={{
    session,
    updateSession: (s: Session) => {
      if (!session) {
        console.warn('No session started, cannot update');
        return;
      }
      return sessionManager.update(s).then((updatedSession) => {
        console.debug('Updating Session');
        setSession(updatedSession);
      });
    }
  }}>{children}</SessionContext.Provider>;
}
