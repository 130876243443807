import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { CollapsableCard, QualifiedNumberInput, TextAreaInput } from '@castiron/components';
import { FulfillmentOption } from '@castiron/domain';

const ShippingDetails: React.FC = () => {
  const formik = useFormikContext<FulfillmentOption>();

  const [expanded, setExpanded] = useState<boolean>(true);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid style={{ marginTop: 24 }}>
      <CollapsableCard title="Shipping Details" handleExpand={toggleExpand} expanded={expanded} noScroll>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <QualifiedNumberInput
              label="Standard Processing Time (Optional)"
              fieldMappings={{
                text: 'processingTime.increments',
                qualifier: 'processingTime.incrementType',
              }}
              qualifierOptions={[
                { display: 'Days', value: 'day' },
                { display: 'Weeks', value: 'week' },
              ]}
              error={formik.touched.processingTime?.increments && formik.errors.processingTime?.increments}
            />
          </Grid>
          <Grid item>
            <TextAreaInput
              label="Details"
              secondaryLabel="Provide your customers with the info they need to make an informed selection at checkout."
              required
              name="description"
              rowCount={5}
              error={formik.touched.description && formik.errors.description}
            />
          </Grid>
        </Grid>
      </CollapsableCard>
    </Grid>
  );
};

export default ShippingDetails;
