import React from "react";
import { useHistory } from "react-router-dom";
import { Box, DialogContent, Grid, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Banner, ButtonV2, Card, CollapsableCard, OrderStatusPill, RichText, Typography } from "@castiron/components";
import { Asset, LineItem, Transaction, transactionToEventModel } from "@castiron/domain";
import { stripHtml, useTracking } from '@castiron/utils';
import ModalWrapper from "../../RootModal/ModalWrapper";
import { useAppDispatch } from "../../../hooks";
import { closeModal } from "../../../store/reducers/modalConductor";
import FulfillmentDetailsBox from "../../FulfillmentDetailsBox";
import GiftDetailsBox from "../../GiftDetailsBox";
import DetailsBox from "../../Orders/DetailsBox";
import QuoteRequestDetailsBox from "../../Orders/QuoteRequestDetailsBox";
import ModalHeader from "../../RootModal/ModalHeader";
import ModalActions from "../../RootModal/ModalActions";
import HeaderTabs, { tabProps } from "../../Layout/Header/HeaderTabs";

export type Props = {
  transaction: Transaction;
};

const useStyles = makeStyles((theme: Theme) => ({
  customerDetails: {
    color: '#71717A',
  },
  emptyContent: {
    backgroundColor: '#F5F9FF',
    borderRadius: 12,
    padding: '24px 16px',
    textAlign: 'center',
  },
  image: {
    aspectRatio: '1/1',
    height: '100%',
    objectFit: 'cover',
    width: '100%',
  },
  imageContainer: {
    backgroundColor: theme.branding.v2.gray[100],
    borderRadius: 12,
    overflow: 'hidden',
    position: 'relative',
  },
  imagesContainer: {
    gap: 16,
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  modalWrapper: {
    [theme.breakpoints.up('md')]: {
      height: 656,
      width: 571,
    },
  },
  notesContainer: {
    margin: '16px 0px 12px 0px',
  },
  tabNav: {
    '& .MuiTabs-flexContainer': {
      borderBottom: `1px solid ${theme.branding.gray[300]}`,
    },
    backgroundColor: theme.branding.gray[100],
    position: 'sticky',
    top: 0,
    zIndex: 50,
  },
}));

const IndividualListingModal: React.FC<Props> = (props: Props) => {
  const { transaction } = props;

  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const { trackEvent } = useTracking();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isQuote = transaction?.order?.type === 'custom';
  const customer = transaction?.customerObj;

  const isTextEmpty = (text: string) => stripHtml(text).length === 0;

  const imageUrl = (asset: Asset) => asset.mediumVersion?.downloadUrl || asset.downloadUrl;

  let headerText;
  let ctaText;
  let context: 'quote' | 'order';

  if (isQuote) {
      if (transaction.frontendState('quote') === 'paid') {
        headerText = `Custom Order #${transaction?.order?.orderNumber}`;
        ctaText = `View Order`;
        context = 'order';
      } else {
        headerText = `Quote #${transaction?.order?.orderNumber}`;
        ctaText = `View Quote`;
        context = 'quote';
      }
    } else {
      headerText = `Instant Checkout Order #${transaction?.order?.orderNumber}`;
      ctaText = `View Order`;
      context = 'order';
    }

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const handleIconClose = () => {
    trackEvent('Calendar Modal View Closed', {
      action: 'Icon Clicked',
      type: context,
      id: transaction.id,
      transaction: transactionToEventModel(transaction),
    });
    handleClose();
  };

  const handleBackgroundClose = () => {
    trackEvent('Calendar Modal View Closed', {
      action: 'Background Clicked',
      type: context,
      id: transaction.id,
      transaction: transactionToEventModel(transaction),
    });
    handleClose();
  };

  const updateCalendar =  () => {
    /* TODO: refresh calendar once we allow artisans to edit fulfillment dates and times */
  };

  const orderedItems: LineItem[] = transaction?.order?.items
    ? transaction?.order.items
    : transaction?.products.map(p => ({
      id: p.product.id,
      title: p.product.title,
      description: p.product.description,
      category: p.product.category,
      price: p.product.price,
      quantity: p.quantity,
      selections: p.selectedVariationValues,
      type: transaction?.order?.type,
    }));

  const emptyContent = {
    customer: {
      header: 'No Customer Added',
      body: `View ${context} to add a new or existing customer to the ${context}.`,
    },
    fulfillment: {
      header: 'No Fulfillment Added',
      body: `View ${context} to add fulfillment details including method, location, and, due date and time.`,
    },
    privateImages: {
      header: 'No Private Images Added',
      body: `View ${context} to add private images.`,
    },
    privateNotes: {
      header: 'No Private Notes Added',
      body: `View ${context} to add private notes.`,
    },
    products: {
      header: 'No Products Added',
      body: `View ${context} to add products and quickly calculate ${isQuote ? 'a quote' : 'an order'} for your customer.`,
    },
  };

  const getEmptyContent = (contentType: 'customer' | 'fulfillment' | 'privateImages' | 'privateNotes' | 'products') => {
    return (
      <Grid container direction="column" justify="center" alignItems="center" className={classes.emptyContent}>
        <Typography variant="subtitle1">{emptyContent[contentType].header}</Typography>
        <Typography variant="body1">{emptyContent[contentType].body}</Typography>
      </Grid>
    );
  };

  const customerDetails = (
    <Card title="Customer">
      {transaction?.customerObj
        ?
        <Grid container direction="column">
          {(customer?.firstName || customer?.lastName) && <Typography variant="body1">{`${customer?.firstName || ''} ${customer?.lastName || ''}`}</Typography>}
          {customer?.email && <Typography variant="body2" className={classes.customerDetails}>{customer?.email}</Typography>}
          {customer?.mobileNumber && <Typography variant="body2" className={classes.customerDetails}>{customer?.mobileNumber}</Typography>}
        </Grid>
        : getEmptyContent('customer')
      }
    </Card>
  );

  const fulfillmentDetails = transaction?.order?.fulfillmentOption
    ? <FulfillmentDetailsBox customer={customer} transaction={transaction} onUpdate={updateCalendar} readonly/>
    : <Card title="Fulfillment">{getEmptyContent('fulfillment')}</Card>;

  const productDetails = orderedItems?.length > 0 && orderedItems[0]?.id
    ?
    <>
      <DetailsBox overrideTitle="Products" transaction={transaction} removePaymentDetails />
      {isQuote && transaction?.order?.items[0]?.selections?.length > 0 && (
        <QuoteRequestDetailsBox orderedItems={orderedItems} />
      )}
    </>
    : <Card title="Products">{getEmptyContent('products')}</Card>;

  const privateNotes = (
    <CollapsableCard className={classes.notesContainer} defaultExpanded noScroll title="Private Notes">
      {isTextEmpty(transaction?.order?.notes?.text) ? (
        getEmptyContent('privateNotes')
      ) : (
        <RichText content={transaction?.order?.notes?.text} />
      )}
    </CollapsableCard>
  );

  const privateImages = (
    <CollapsableCard defaultExpanded noScroll title="Private Images">
      {!!transaction?.order?.notes?.images.length ? (
        <Grid className={classes.imagesContainer}>
          {transaction?.order?.notes?.images.map((image, index) => (
            <Box className={classes.imageContainer} key={`imageCard${index}-${image?.id}-`}>
              <img src={imageUrl(image)} className={classes.image} />
            </Box>
          ))}
        </Grid>
      ) : (
        getEmptyContent('privateImages')
      )}
    </CollapsableCard>
  );

  const goToItemDetails = () => {
    trackEvent('Calendar View Item Clicked', {
      type: context,
      id: transaction.id,
      transaction: transactionToEventModel(transaction),
    });
    handleClose();
    history.push(`/${context}s/edit/${transaction?.id}`);
  };

  const detailsContent = (
    <Grid container style={{ padding: 24 }}>
      {customerDetails}
      {fulfillmentDetails}
      {productDetails}
      <GiftDetailsBox order={transaction} />
    </Grid>
  );

  const tabs: tabProps[] = [
    {
      value: 'Details',
      content: detailsContent,
    },
    {
      value: 'My Notes',
      content: (
        <Grid container style={{ padding: 24 }}>
          <Banner variant="eye-blue">
            <Typography variant="body2">Only you can see these.</Typography>
          </Banner>
          {privateNotes}
          {privateImages}
        </Grid>
      ),
    },
  ];

  return (
    <ModalWrapper
      fullScreen={isMobile}
      size="lg"
      show={true}
      paperClass={classes.modalWrapper}
      onClose={handleBackgroundClose}
    >
      <ModalHeader
        title={
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <Grid item style={{ marginRight: 8 }}>
              <Typography variant="h3">{headerText}</Typography>
            </Grid>
            <Grid item>
              <OrderStatusPill transaction={transaction} context={context} />
            </Grid>
          </Grid>
        }
        handleClose={handleIconClose}
      />
      <DialogContent style={{ padding: 0 }}>
        {isQuote ? (
          <HeaderTabs initialTabValue="Details" tabs={tabs} tabNavClassName={classes.tabNav} centerTabs />
        ) : (
          detailsContent
        )}
      </DialogContent>
      <ModalActions>
        <ButtonV2 onClick={goToItemDetails} variant="contained">
          {ctaText}
        </ButtonV2>
      </ModalActions>
    </ModalWrapper>
  );
};

export default IndividualListingModal;
