import React, { useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { Chip, Grid, MenuItem, Select } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import _ from 'lodash';
import InputWrapper, { BaseInputProps } from '../InputWrapper';
import Typography from '../../Typography';

interface Props extends BaseInputProps {
  name: string;
  options: string[];
  value?: string[];
  autoWidth?: boolean;
  displayEmpty?: boolean;
  disabled?: boolean;
  rootClass?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  disabled: {
    textAlign: 'left',
    backgroundColor: theme.branding.v2.gray[50],
    borderRadius: 12,
  },
  input: {
    padding: '12px',
    cursor: 'pointer',
    backgroundColor: `${theme.branding.v2.gray[0]} !important`,
    '&::placeholder': {
      ...theme.typography.button,
    },
    '& .MuiFormControl-root': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  chip: {
    padding: '4px 12px',
    height: 'auto',
    borderRadius: '100px',
    backgroundColor: theme.branding.v2.gray[100],
    '& .MuiChip-label': {
      paddingLeft: 0,
      paddingRight: 8,
    },
    '& .MuiChip-deleteIcon': {
      margin: 0,
      color: theme.branding.v2.gray[500],
      height: 20,
      width: 20,
    },
  },
  menuItem: {
    '&:hover': {
      backgroundColor: `${theme.branding.v2.blue[50]} !important`,
    },
  },
  menuItemSelected: {
    backgroundColor: `${theme.branding.v2.blue[100]} !important`,
    fontWeight: 600,
  },
}));

const ChipSelectInput: React.FC<Props> = (props: Props) => {
  const { name, options, value, autoWidth, displayEmpty, disabled, rootClass } = props;
  const classes = useStyles();

  const { setFieldValue } = useFormikContext();

  const [selectedValues, setSelectedValues] = useState<any[]>(value);

  const handleDelete = (event, value) => {
    event.preventDefault();

    const newValues = _.without(selectedValues, value);
    setSelectedValues(newValues);
    setFieldValue(name, newValues);
  };

  const onChangeHandler = event => {
    setSelectedValues(event.target.value);
    setFieldValue(name, event.target.value);
  };

  return (
    <InputWrapper {...props}>
      <Select
        multiple
        name={name}
        variant="outlined"
        autoWidth={autoWidth}
        displayEmpty={displayEmpty}
        onChange={onChangeHandler}
        classes={{ root: `${classes.input} ${rootClass}` }}
        value={!selectedValues && value ? value : selectedValues}
        disabled={disabled}
        inputProps={{ classes: { disabled: classes.disabled } }}
        MenuProps={{ variant: 'menu' }}
        renderValue={(selected: any[]) => (
          <Grid container direction="row" style={{ gap: '8px' }}>
            {selected.map((value, index) => (
              <div key={`selected-special-${value}-${index}`}>
                <Chip
                  key={value}
                  label={<Typography variant="body1">{value}</Typography>}
                  className={classes.chip}
                  onDelete={e => handleDelete(e, value)}
                  deleteIcon={<CloseOutlinedIcon onMouseDown={event => event.stopPropagation()} />}
                />
              </div>
            ))}
          </Grid>
        )}
      >
        {options.map((option, index) => (
          <MenuItem
            value={option}
            key={`selectOption${index}`}
            classes={{ root: classes.menuItem, selected: classes.menuItemSelected }}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </InputWrapper>
  );
};

export default ChipSelectInput;
