import React, { ReactNode } from 'react';
import { Field, FormikErrors, FormikTouched } from 'formik';
import Label from '../Label';
import ValidationError from '../Errors/ValidationError';
import { useTheme } from '@material-ui/core/styles';

type Props = {
  name: string;
  label?: string;
  type: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[];
  className?: string;
  disabled?: any;
  children?: ReactNode;
  marketplace?: 'castiron' | 'nourysh';
};

function Input(props: Props) {
  //console.log('props.error: ', props.error);
  const theme = useTheme();
  //console.log('theme in input: ', theme);
  return (
    <div className={`form-group ${props.error && 'field-error'}`}>
      <Label name={props.name} label={props.label || ''} />
      <div className="input-group input-wrapper">
        <Field
          style={{
            border: '1px solid #EDEDED',
            height: 48,
            color:
              props.marketplace === 'nourysh' ? `${theme.branding.v2.plum[500]}` : `${theme.branding.v2.blue[500]}`,
          }}
          className="form-control"
          type={props.type}
          name={props.name}
          disabled={props.disabled}
        />
      </div>
      {props.error && <ValidationError name={props.name} msg={props.error} />}
    </div>
  );
}

export default Input;
