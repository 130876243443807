import React, { useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { FieldArray, useFormikContext } from 'formik';
import { ButtonV2 as Button, Typography } from '@castiron/components';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getProductsAction } from '../../../store/reducers/products';
import LineItemComponent from './LineItemComponent';
import { FormValues } from '.';

const useStyles = makeStyles((theme: Theme) => ({
  addButtonContainer: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: '12px',
    padding: '16px',
  },
  indent: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '16px',
    }
  },
}));

const emptyLineItem = {
  title: '',
  price: 0,
  quantity: 0,
}

const LineItems: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const { values } = useFormikContext<FormValues>();

  useEffect(() => {
    dispatch(getProductsAction(shop.id));
  }, [shop]);

  return (
    <Grid container direction='column' spacing={1}>
      <Grid item>
        <Typography variant="subtitle1">Items</Typography>
      </Grid>
      <Grid item>
        <Box className={classes.indent}>
          <FieldArray name='lineItems' render={arrayHelpers =>
            <Grid container direction='column'>
              {values.lineItems.map((lineItem, index) =>
                <Grid item key={`lineItem${index}`}>
                  <LineItemComponent arrayHelpers={arrayHelpers} index={index} />
                </Grid>
              )}
              <Grid item xs={12} md={3}>
                <Box className={classes.addButtonContainer}>
                  <Grid container justify='center'>
                    <Grid item>
                      <Button variant='text' onClick={() => arrayHelpers.push(emptyLineItem)}>
                        + Add Item
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          } />
        </Box>
      </Grid>
    </Grid>
  );
};

export default LineItems;