import React, { useEffect, useRef, useState } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import { Button, TextInput, Typography } from '@castiron/components';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import ModalWrapper from '../../RootModal/ModalWrapper';
import { closeModal, openModal } from '../../../store/reducers/modalConductor';
import { getService } from '../../../firebase';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import AdminForm from '../../AdminForm';
import { setDiscountAction } from '../../../store/reducers/shops';

const validatePromoCodeService = getService('subscriptions', 'validatepromocode', { version: 2 });

export type Props = {
  open: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    borderTop: `1px solid ${theme.branding.gray[400]}`,
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
  },
  content: {
    flex: 'auto',
    padding: 24,
    [theme.breakpoints.up(510)]: {
      width: '100%',
      height: '100%',
    },
  },
  discountCodeError: {
    color: theme.branding.v2.red[500],
    marginTop: 4,
  },
  header: {
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
    padding: '22px 24px',
    '& h6': {
      color: theme.branding.gray[800],
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '28px',
    },
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '24px',
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '28px',
  },
  wrapper: {
    '& .MuiDialog-paper': {
      [theme.breakpoints.up('sm')]: {
        height: 500,
        width: 500,
      },
    },
  },
}));

const RedeemCouponModal: React.FC<Props> = (props: Props) => {
  const { open } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [couponCode, setCouponCode] = useState('');
  const [discountCodeError, setDiscountCodeError] = useState<string | undefined>(undefined);
  const [isValidatingCode, setIsValidatingCode] = useState(false);
  const formikRef = useRef<any>();

  const { account, discount, shop, subscription } = useAppSelector(state => ({
    account: state.shops.account,
    discount: state.shops.discount,
    shop: state.shops.shop,
    subscription: state.shops.account.subscription,
  }));

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  useEffect(() => {
    if (discount) {
      setCouponCode(discount.code);
    }
  }, [discount]);

  const handleInputChange = (value: string): void => {
    setCouponCode(value);
    if (formikRef.current) {
      formikRef.current.setFieldValue('couponCode', value);
    }
  };

  const applyCoupon = async () => {
    if (couponCode) {
      setIsValidatingCode(true);
      try {
        const validateCodeResp = await validatePromoCodeService({
          code: couponCode,
        });
        setIsValidatingCode(false);

        if (validateCodeResp.couponValid === false) {
          setDiscountCodeError('Not a valid coupon code.');
        }

        if (validateCodeResp.appliesToPlans && !validateCodeResp.appliesToPlans.includes(subscription.plan.id)) {
          setDiscountCodeError('Sorry, this coupon can only be used on a Pro Subscription.');
        }

        if (!validateCodeResp.appliesToPlans || validateCodeResp.appliesToPlans.includes(subscription.plan.id)) {
          dispatch(
            openModal({
              modalType: 'CHANGE_PLANS_MODAL',
              modalProps: {
                open: true,
                couponCode: couponCode,
                step: 0,
              },
            }),
          );
        }
      } catch (err) {
        setIsValidatingCode(false);
        console.error('Error applying discount code', err);
        if (err.message.startsWith('No such coupon')) {
          setDiscountCodeError('Not a valid coupon code.');
          dispatch(setDiscountAction(undefined));
        } else {
          setDiscountCodeError('Sorry, there was an error applying this coupon.');
          dispatch(setDiscountAction(undefined));
        }
      }
    } else {
      setDiscountCodeError('No coupon code entered.');
      setIsValidatingCode(false);
    }
  };

  return (
    <ModalWrapper fullScreen={isMobile} size="md" show={open} className={classes.wrapper}>
      <DialogTitle className={classes.header}>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item>
            <Typography variant="h6" className={classes.title}>
              Redeem a Coupon
            </Typography>
          </Grid>
        </Grid>
        <IconButton className={classes.closeIcon} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Formik
          initialValues={{ couponCode: couponCode || '' }}
          validationSchema={yup.object().shape({
            couponCode: yup.string().required('Coupon code is required'),
          })}
          onSubmit={applyCoupon}
          innerRef={formikRef}
        >
          {(
            formikProps: FormikProps<{
              couponCode: string;
            }>,
          ) => (
            <AdminForm>
              <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                <Grid item>
                  <Typography variant="subtitle2" className={classes.subtitle}>
                    Coupon Code
                  </Typography>
                </Grid>
                <Grid item style={{ marginTop: 4 }}>
                  <TextInput
                    name="couponCode"
                    onChange={e => {
                      handleInputChange(e.target.value);
                    }}
                  />
                </Grid>
                <Grid container item justify="flex-start" alignItems="center">
                  {discountCodeError && (
                    <Typography variant="body1" className={classes.discountCodeError}>
                      {discountCodeError}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </AdminForm>
          )}
        </Formik>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="outlined" color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={applyCoupon} loading={isValidatingCode}>
          Redeem
        </Button>
      </DialogActions>
    </ModalWrapper>
  );
};

export default RedeemCouponModal;
