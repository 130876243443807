import React, { useCallback, useState } from 'react';
import ModalWrapper from '../RootModal/ModalWrapper';
import { useAppDispatch } from '../../hooks';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slider,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import { Asset } from '@castiron/domain';
import { Typography } from '@castiron/components';
import Cropper from 'react-easy-crop';

type Props = {
  show: boolean;
  imageObj: Asset;
  imageLocation?: 'gallery' | 'profile' | 'cover' | 'logo' | 'product' | 'home';
  cropShape?: 'rect' | 'round';
  aspectRatio?: number;
  onClose?: (croppedImage: File) => void;
  onCancel?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    padding: 16,
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
  },
  controls: {
    height: 64,
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
  },
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 400,
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },

    '& .reactEasyCrop_CropArea': {
      color: `${theme.branding.gray[800]}`,
    },
  },
  container: {
    '& .MuiPaper-root': {
      width: '100%',
      height: 650,
      [theme.breakpoints.down('sm')]: {
        height: '100%',
      },
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      height: '100%',
    },
  },
  header: {
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
    height: '80px',
    backgroundColor: theme.branding.gray[100],
    display: 'flex',
    alignItems: 'center',
  },
  zoomRange: {
    maxWidth: '80%',

    '& .MuiSlider-thumb, .MuiSlider-track': {
      color: `${theme.branding.blue.primary}`,
    },
  },
}));

const EditPhotoModal: React.FC<Props> = (props: Props) => {
  const { show, imageObj, imageLocation, onClose, cropShape, onCancel, aspectRatio } = props;
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [crop, setCrop] = useState({ x: 900, y: 900 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const createImage = url =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', error => reject(error));
      image.crossOrigin = 'anonymous';
      image.src = url;
    });

  const getCroppedImg = useCallback(async (imageSrc, pixelCrop) => {
    console.debug('imageSrc', imageSrc);
    const image: any = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return null;
    }

    // calculate bounding box of the image
    const boundingBox = {
      width: image.width,
      height: image.height,
    };

    // set canvas size to match the bounding box
    canvas.width = boundingBox.width;
    canvas.height = boundingBox.height;

    // draw image on canvas
    ctx.drawImage(image, 0, 0);

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const croppedImage = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated crop into the new canvas
    ctx.putImageData(croppedImage, 0, 0);

    return new Promise(resolve => {
      canvas.toBlob(file => {
        //turn blob into a file
        const imageFile = new File([file], imageObj.metadata?.originalFilename, {
          type: imageObj.metadata?.contentType || 'image/jpeg',
        });
        resolve(imageFile);
      }, imageObj.metadata?.contentType || 'image/jpeg');
    });
  }, []);

  const handleCrop = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageObj.downloadUrl, croppedAreaPixels);
      onClose(croppedImage as File);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  return (
    <ModalWrapper size="sm" show={show} fullScreen={isMobile} className={classes.container}>
      <DialogTitle className={classes.header}>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h3">Edit Photo</Typography>
          <IconButton className={classes.closeIcon} onClick={onCancel}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container className={classes.cropContainer} wrap="nowrap">
          <Cropper
            image={imageObj.downloadUrl}
            crop={crop}
            zoom={zoom}
            aspect={aspectRatio || 1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            objectFit="contain"
            cropShape={cropShape ? cropShape : 'rect'}
          />
        </Grid>
        <Grid container className={classes.controls} alignItems="center" wrap="nowrap" justify="center">
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => setZoom(Number(zoom))}
            className={classes.zoomRange}
          />
        </Grid>
        <DialogActions className={classes.actions}>
          <Button variant="outlined" color="primary" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleCrop}>
            Save
          </Button>
        </DialogActions>
      </DialogContent>
    </ModalWrapper>
  );
};

export default EditPhotoModal;
