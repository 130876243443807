import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { Banner, ButtonV2 as Button, TextInput, ToggleInput, Typography } from "@castiron/components";
import { Shop, shopToEventModel } from "@castiron/domain";
import { useTracking } from "@castiron/utils";
import { Form, Formik, FormikProps } from "formik";
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { updateShopAction } from "../../../store/reducers/shops";
import AdminPageContainer from "../../AdminPageContainer";
import { LayoutPageProps } from "../../Layout";
import ViewShopButton from "../../Layout/Header/ViewShopButton";
import RichTextInput from "../../RichTextEditor/v2";
import RouterLink from "../../RouterLink";
import { openModal } from '../../../store/reducers/modalConductor';

interface FormValues {
  enabled: boolean;
  headline: string;
  description: string;
};
const schema = yup.object({
  enabled: yup.boolean(),
  headline: yup.string(),
  description: yup.string(),
});

const DEFAULT_HEADLINE = 'Availability';
const DEFAULT_DESCRIPTION =
  'The calendar below outlines my general availability and is reference only. More accurate availability is provided when submitting a request or at checkout.';

const stringTruthinessMinusEmpty = (str: string): boolean => str === '' || !!str;

const AvailabilityPage: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();

  const formRef = useRef<FormikProps<FormValues>>();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const availabilityConfig = shop?.shopSubpageData?.availability;
  const initialState = {
    enabled: availabilityConfig?.enabled || false,
    headline: stringTruthinessMinusEmpty(availabilityConfig?.headline) ? availabilityConfig.headline : DEFAULT_HEADLINE,
    description: stringTruthinessMinusEmpty(availabilityConfig?.description) ? availabilityConfig.description : DEFAULT_DESCRIPTION,
  };

  const submit = async (values: FormValues) => {
    if (values.enabled !== shop.shopSubpageData?.availability?.enabled) {
      trackEvent('Shop Page Visibility Set', {
        page: 'availability',
        enabled: values.enabled,
        shop: shopToEventModel(shop)
      });
    };


    const newShop = {
      ...shop,
      shopSubpageData: {
        ...shop.shopSubpageData,
        availability: values,
      },
    } as Shop;

    await dispatch(updateShopAction({ shop: newShop }));

    trackEvent('Shop Availability Page Updated', {
      shop: shopToEventModel(shop),
    });

    dispatch(
      openModal({
        modalType: 'SIMPLE_ALERT',
        modalProps: {
          show: true,
          celebrate: true,
          content: (
            <>
              <Typography variant="h4">Availability Page Updated!</Typography>
            </>
          ),
        },
      }),
    );

    history.push('/store/pages');
  };

  useEffect(() => {
    setPageTitle('Availability');
    setBackLocation('/store/pages');
    setHeaderCTAs([<ViewShopButton subdirectory='availability' />]);
    setFooterCTAs([
      <Button variant="outlined" onClick={() => history.goBack()}>Discard</Button>,
      <Button variant="contained" onClick={formRef.current.submitForm}>Save</Button>,
    ]);

    return () => {
      setPageTitle('');
      setBackLocation(false);
      setFooterCTAs([]);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AdminPageContainer helmetTitle="Availability">
      <Formik
        validationSchema={schema}
        initialValues={initialState}
        innerRef={formRef}
        onSubmit={submit}
      >
        {({ touched, errors }) =>
          <Form>
            <Grid container direction="row-reverse" spacing={3}>
              <Grid item xs={12} sm={4}>
                <ToggleInput
                  label='Status'
                  name='enabled'
                  helpText='Setting this page to active makes it immediately visible on your website.'
                />
              </Grid>
              <Grid item xs>
                <Grid container direction="column" spacing={3}>
                  <Grid item>
                    <Banner variant='info-white'>
                      <Typography variant="body2" style={{ color: 'inherit' }}>
                        <span style={{ fontWeight: 700 }}>Note:</span> Your availability is based on your&nbsp;
                        <RouterLink to='/calendar' underline color='inherit'>Calendar settings.</RouterLink>
                      </Typography>
                    </Banner>
                  </Grid>
                  <Grid item>
                    <TextInput label='Headline' name='headline' error={touched.headline && errors.headline} />
                  </Grid>
                  <Grid item>
                    <RichTextInput label='Description' name='description' height={300} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        }
      </Formik>
    </AdminPageContainer>
  );
};

export default AvailabilityPage;