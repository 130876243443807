import { Banner } from '@castiron/components';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';

const Success: React.FC = () => {
  return (
    <Grid alignItems="center" container direction="column">
      <Typography style={{ fontSize: 32, marginBottom: 16 }} variant="h2">
        👏
      </Typography>
      <Typography variant="h2">Congrats!</Typography>
      <Typography style={{ marginBottom: 16 }} variant="body1">
        Your custom domain has been secured.
      </Typography>
      <Banner variant="important-red">
        <Typography variant="body2">
          <b>Important!</b> You will receive an email from our domain provider, OpenSRS. You <b>MUST</b> complete the
          instructions in that email to finish activating your new domain.
        </Typography>
      </Banner>
    </Grid>
  );
};

export default Success;
