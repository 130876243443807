import { Banner, Chip, Typography } from '@castiron/components';
import { Subscription } from '@castiron/domain';
import { DialogContent, Grid, useMediaQuery } from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
import React, { ReactElement, ReactNode } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { closeModal, openModal } from '../../../../store/reducers/modalConductor';
import ModalChoice from '../../../RootModal/ModalChoice';
import ModalHeader from '../../../RootModal/ModalHeader';
import ModalWrapper from '../../../RootModal/ModalWrapper';

/* 09/11/2023 at midnight EST */
const FEATURE_RELEASE_DATE = 1694404800;

export type Props = {
  show?: boolean;
};

export interface DomainChoices {
  action: () => void;
  banner?: ReactNode;
  description: string;
  disabled?: boolean;
  icon: ReactElement;
  pill?: ReactNode;
  title: string;
}

export const eligibleForFreeCustomDomain = (subscription?: Subscription): boolean => {
  const isAnnualPlan = subscription?.price?.frequency === 'yearly';
  /* Allow for annual plan subscribers or annual plan trialers who have entered a credit card and have subscribed after the release date */
  return (
    subscription?.startDate >= FEATURE_RELEASE_DATE &&
    (subscription?.status === 'active' || subscription?.status === 'trial') &&
    subscription?.paymentMethod &&
    isAnnualPlan
  );
};

export const existingAnnualUser = (subscription?: Subscription): boolean => {
  const isAnnualPlan = subscription?.price?.frequency === 'yearly';
  return subscription?.status === 'active' && isAnnualPlan && subscription?.startDate < FEATURE_RELEASE_DATE;
};

const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    marginTop: 16,
  },
  modalChoice: {
    padding: 16,
  },
  modalContent: {
    padding: 24,
  },
  modalWrapper: {
    [theme.breakpoints.up('sm')]: {
      width: 504,
    },
  },
}));

const StartDomainConnectModal: React.FC<Props> = (props: Props) => {
  const { show } = props;

  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useAppDispatch();

  const { account, shop } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
  }));

  const hasFreeCustomDomain = !!shop?.config?.freeCustomDomain;
  const isAnnualPlan = account?.subscription?.price?.frequency === 'yearly';

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const openCustomDomainModal = () => {
    dispatch(
      openModal({
        modalType: 'FREE_CUSTOM_DOMAIN_MODAL',
        modalProps: {
          show: true,
        },
      }),
    );
  };

  const openAddDomainModal = () => {
    dispatch(
      openModal({
        modalType: 'ADD_DOMAIN_MODAL',
        modalProps: {
          show: true,
        },
      }),
    );
  };

  const choices: DomainChoices[] = [
    {
      action: openAddDomainModal,
      description: 'Follow a few short steps to connect a domain you already own for your Castiron website.',
      icon: <LanguageOutlinedIcon />,
      title: 'Connect a domain I already own',
    },
  ];

  const customDomainChoice = {
    action: openCustomDomainModal,
    banner: hasFreeCustomDomain && (
      <Banner className={classes.banner} noIcon variant="info-white">
        <Typography variant="body2">You’ve already connected your custom domain.</Typography>
      </Banner>
    ),
    description: 'Easily select and connect a new custom domain for your Castiron website.',
    disabled: hasFreeCustomDomain,
    icon: <ShoppingBasketOutlinedIcon />,
    title: 'Get a domain',
    pill: !hasFreeCustomDomain && (
      <Chip colorScheme="blue" style={{ marginTop: 8 }}>
        {!isAnnualPlan
          ? 'Annual Plan Required'
          : account?.subscription?.startDate >= FEATURE_RELEASE_DATE
          ? 'Claim Your FREE Domain'
          : 'Renewal Required'}
      </Chip>
    ),
  };

  if (hasFreeCustomDomain) {
    choices.push(customDomainChoice);
  } else {
    choices.unshift(customDomainChoice);
  }

  return (
    <ModalWrapper fullScreen={isMobile} show={show} onClose={handleClose} paperClass={classes.modalWrapper}>
      <ModalHeader title="Add a Custom Domain" handleClose={handleClose} />
      <DialogContent className={classes.modalContent}>
        <Grid container direction="column" style={{ gap: 16 }}>
          {choices.map((choice, index) => (
            <ModalChoice
              action={choice.action}
              className={classes.modalChoice}
              description={choice.description}
              icon={choice.icon}
              key={`domain-choice-${index}`}
              pill={choice.pill}
              banner={choice.banner}
              disabled={choice.disabled}
              title={choice.title}
            />
          ))}
        </Grid>
      </DialogContent>
    </ModalWrapper>
  );
};

export default StartDomainConnectModal;
