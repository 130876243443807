import React, {useEffect, useRef, useState} from 'react';
import * as yup from 'yup';
import {Asserts} from 'yup';
import {DialogActions, DialogContent, DialogTitle, Grid, IconButton, useMediaQuery} from '@material-ui/core';
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import {Close} from '@material-ui/icons';
import {Button, Checkbox, Input, Typography} from '@castiron/components';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {closeModal, openModal} from '../../store/reducers/modalConductor';
import ModalWrapper from '../RootModal/ModalWrapper';
import {Formik, FormikProps} from "formik";
import {getService} from "../../firebase";
import { accountRepository } from '../../domain';

export const SEEN_SMS_OPTIN = 'seen-sms-notification-optin-modal';

export interface Props {
  context?: 'marketing';
  show: boolean;
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  closeIcon: {
    height: '24px',
    width: '24px',
  },
  content: {
    padding: '24px',
    [theme.breakpoints.up(510)]: {
      width: '450px',
      height: 'fit-content',
    },
  },
  header: {
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
    padding: '22px 24px',
    '& h6': {
      color: theme.branding.gray[800],
      fontSize: 18,
      fontWeight: 700,
      lineHeight: '28px',
    },
  },
}));

const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?([\s.-])?\d{3}([\s.-])?\d{4}$/;
const smsOptInSchema = yup.object().shape({
  smsOptIn: yup.boolean().oneOf([true]),
  smsMobileNumber: yup
    .string()
    .matches(phoneRegExp, 'Please enter a valid 10-digit phone number')
    .when('smsOptIn', {
      is: true,
      then: yup.string().required('Please enter your mobile number.'),
      otherwise: yup.string().nullable()
    })
});

type NotificationValues = Asserts<typeof smsOptInSchema>;

const sendSmsVerificationService = getService('messaging', 'sendsmsverification');

const SMSNotificationModal: React.FC<Props> = (props: Props) => {
  const {context, show} = props;
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const formRef = useRef<FormikProps<NotificationValues>>();
  const [submitting, setSubmitting] = useState<boolean>();

  /* modal is 450, but enforces padding in view port, so acts funny a bit larger */
  const isMobile = useMediaQuery(theme.breakpoints.down(510));

  const classes = useStyles();

  const {account} = useAppSelector(state => ({
    account: state.shops.account,
  }));

  const initialValues = {
    smsOptIn: true,
    smsMobileNumber: account?.phoneNumber || '',
  };

  useEffect(() => {
    if (show) {
      localStorage.setItem(SEEN_SMS_OPTIN, 'true');
    }
  }, [show]);

  const handleClose = () => dispatch(closeModal());

  const handleSubmit = async (values) => {
    setSubmitting(true);
    const formattedNumber = values.smsMobileNumber.replace(/[()\- ]/gi, '');

    if (account?.phoneNumber !== values.smsMobileNumber) {
      await accountRepository.updateProps(account.id, {
        phoneNumber: values.smsMobileNumber,
      });
    }

    sendSmsVerificationService({
      mobilePhoneNumber: `+1${formattedNumber}`
    }).then(() => {
      dispatch(openModal({
        modalType: 'SIMPLE_ALERT',
        modalProps: {
          show: true,
          content: <Typography variant="body1">A verification message has been sent to {values.smsMobileNumber}. Please follow its instructions to complete setup!</Typography>
        }
      }));
      setSubmitting(false);
    }).catch(err => {
      setSubmitting(false);
      console.error('Error', err);
    });
  }

  return (
    <ModalWrapper fullScreen={isMobile} size='sm' show={show}>
      <Formik
        validationSchema={smsOptInSchema}
        initialValues={initialValues}
        innerRef={formRef}
        onSubmit={handleSubmit}
      >
        {({initialValues, values, setFieldValue, submitForm, errors, isValid, dirty}) => (
          <>
            <DialogTitle className={classes.header}>
              <Grid container justify='space-between' alignItems='center'>
                <Grid item>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item>
                      <Typography variant='h3'>{context === 'marketing' ? "Notification Opt In" : "Sign up for SMS alerts"}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <IconButton>
                    <Close className={classes.closeIcon} onClick={handleClose}/>
                  </IconButton>
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent className={classes.content}>
              <Grid container direction="column">
                <Typography variant="body1" style={{marginBottom: '16px'}}>
                  {context === 'marketing' ? "Please add your business phone number and opt in to business notifications so we can send you the test for this SMS marketing campaign." : "Get notified of new quote requests and orders ASAP! Sign up for Castiron text notifications!"}
                </Typography>
                <Grid item container direction="row">
                  <Grid item xs={12}>
                    <Checkbox
                      name="smsOptIn"
                      label={context === 'marketing' ? "Please send me text notifications." : "Yes! Please send me text notifications!"}
                      onChange={e => setFieldValue('smsOptIn', e.target.checked)}
                      checked={values.smsOptIn}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <Input
                    name="smsMobileNumber"
                    label={context === 'marketing' ? "Business Phone Number" : "Mobile Number"}
                    type="text"
                    phoneMask={true}
                    placeholder="(555) 555-5555"
                    variant="outlined"
                    disabled={!values.smsOptIn}
                    error={errors.smsMobileNumber}
                    errorText={errors.smsMobileNumber as string}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={handleClose}>Cancel</Button>
              <Button variant="contained" onClick={submitForm} disabled={((!dirty && !initialValues.smsMobileNumber) || !isValid) && !submitting}>Opt In</Button>
            </DialogActions>
          </>
        )}
      </Formik>
    </ModalWrapper>
  );
};

export default SMSNotificationModal;
