// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const config = {
  apiKey: process?.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process?.env.REACT_APP_FIREBASE_PROJECT_ID,
  authDomain: process?.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  storageBucket: process?.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process?.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process?.env.REACT_APP_FIREBASE_APP_ID,
};

console.log('COMF', config)

let FirebaseApp;
if (!firebase.apps.length) {
  FirebaseApp = firebase.initializeApp(config);
} else {
  console.debug('Using existing Firebase app');
  FirebaseApp = firebase.app();
}

export default FirebaseApp;
