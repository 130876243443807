import React, {useEffect} from "react";
import { Grid, IconButton, makeStyles, Theme, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Transaction } from "@castiron/domain";
import { Button } from "@castiron/components";
import { useAppDispatch } from "../../../hooks";
import { closeModal } from "../../../store/reducers/modalConductor";
import ModalWrapper from "../../RootModal/ModalWrapper";
import {useTracking} from "@castiron/utils";

export interface Props {
  show: boolean,
  transaction: Transaction,
}

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer: {
    padding: '32px 24px',
  },
  textContainer: {
    paddingRight: '24px',
  },
  buttonContainer: {
    marginTop: '24px',
  },
  closeIcon: {
    position: 'absolute',
    top: '16px',
    right: '24px',
    cursor: 'pointer',
    zIndex: 10,
  },
}));

const QuoteSentModal: React.FC<Props> = (props: Props) => {
  const { show, transaction } = props;
  const customerName = [transaction.customerObj?.firstName, transaction.customerObj?.lastName].filter(item => !!item).join(' ');

  const dispatch = useAppDispatch();
  const classes = useStyles();

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  return (
    <ModalWrapper size="xs" show={show}>
      <IconButton className={classes.closeIcon} onClick={handleClose}>
        <Close />
      </IconButton>
      <Grid container className={classes.modalContainer}>
        <Grid item xs={12} className={classes.textContainer}>
          <Typography>
            Quote sent to {customerName} ({transaction.customerObj?.email})
          </Typography>
        </Grid>
        <Grid container item xs={12} justify="flex-end" className={classes.buttonContainer}>
          <Grid item>
            <Button variant="contained" onClick={handleClose}>OK</Button>
          </Grid>
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};

export default QuoteSentModal;
