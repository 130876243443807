import {Hidden} from '@material-ui/core';
import React, {useEffect} from 'react';
import FooterDesktop from './FooterDesktop';
import FooterMobile from './FooterMobile';
import {useAppSelector} from "../../../hooks";
import {useFeatures} from "@castiron/components";
import {useLocation} from "react-router-dom";
import {enableBeacon, hideBeacon} from "../../../lib/helpscount";

type Props = {
  isAuth?: boolean;
  stickyFooterClass?: string;
};

declare global {
  interface Window {
    Beacon(method: string, id?: any): void;
  }
}

let authState = 'initialState';

const Footer: React.FC<Props> = (props: Props) => {
  const features = useFeatures();

  const { fromOnboarding } = useAppSelector(state => ({
    fromOnboarding: state.shops.fromOnboarding,
  }));

  const location = useLocation();

  const { isAuth, stickyFooterClass } = props;
  const initBeacon = (isAuth): void => {
    if (authState !== isAuth) {
      authState = isAuth;
      if (!isAuth) {
        enableBeacon();
      } else {
        hideBeacon();
      }
    }
  };

  useEffect(() => {
    if (features !== undefined && features.includes('admin.support')) {
      initBeacon(props.isAuth);
    }
  }, [props.isAuth, features, location]);

  return (
    <footer>
      <Hidden mdUp>
        <FooterMobile stickyFooterClass={stickyFooterClass} />
      </Hidden>
      <Hidden smDown>
        <FooterDesktop isAuth={isAuth} stickyFooterClass={stickyFooterClass} />
      </Hidden>
    </footer>
  );
};

export default Footer;
