type RichTextNode = {
  type: string;
  children: Array<{
    text: string;
    bold?: boolean;
    italic?: boolean;
    underline?: boolean;
    [key: string]: any;
  }>;
};

// Utility function to convert Strapi Rich Text to HTML
export const convertStrapiRichTextToHTML = (richTextData: RichTextNode[]) => {
  return richTextData
    .map(block => {
      if (block.type === 'paragraph') {
        const content = block.children.map(child => convertChildToHTML(child)).join('');
        return content.trim() === '' ? `<br />` : `<p>${content}</p>`;
      }

      switch (block.type) {
        case 'bold':
          return `<strong>${block.children.map(child => child.text).join('')}</strong>`;
        case 'italic':
          return `<em>${block.children.map(child => child.text).join('')}</em>`;
        case 'underline':
          return `<u>${block.children.map(child => child.text).join('')}</u>`;
        default:
          return block.children.map(child => convertChildToHTML(child)).join('');
      }
    })
    .join('');
};

const convertChildToHTML = (child: { text: string; bold?: boolean; italic?: boolean; underline?: boolean }) => {
  let html = child.text;

  if (child.bold) {
    html = `<strong>${html}</strong>`;
  }
  if (child.italic) {
    html = `<em>${html}</em>`;
  }
  if (child.underline) {
    html = `<u>${html}</u>`;
  }

  return html;
};
