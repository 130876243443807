import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Input, Theme } from "@material-ui/core";
import { Diners, Typography } from "@castiron/components";
import { getService } from "../../firebase";
import Dinero from "dinero.js";
import Spinner from "../Spinner";

interface Props {

}

interface AiProductSuggestion {
  title: string;
  description: string;
  price?: number;
  type: 'standard' | 'custom',
  category: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    border: 'solid grey 1px',
    width: '600px'
  },
  product: {
  }
}));

const generateProductService = getService('products', 'generateproducts');

const GenerateMenuTest: React.FC<Props> = (props: Props) => {
  const {} = props;
  const classes = useStyles();
  const [productText, setProductText] = useState<string>();
  const [suggestions, setSuggestions] = useState<AiProductSuggestion[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const onClick = () => {
    const getProducts = async (products: string[]) => {
      const resp = await generateProductService({
        products
      });

      setSuggestions(resp.products);
      setLoading(false);
    }

    if (productText) {
      setLoading(true);
      getProducts(productText.split(/\n/));
    }
  }

  return <>
    <Spinner show={loading}/>
    <Grid container direction="column">
    <Grid item>
      <Typography variant="body1">List the products you would like to generate, one title per line:</Typography>
    </Grid>
    <Grid item>
      <Input
        className={classes.input}
        multiline
        rows={10}
        onChange={e => setProductText(e.target.value)}
      />
    </Grid>
    <Grid item>
      <Button onClick={onClick}>Generate!</Button>
    </Grid>
    {suggestions && <Grid item container direction="column" spacing={3}>
      {suggestions.map(s => <Grid item container direction="column" key={s.title} className={classes.product}>
        <Grid item><strong>Title:</strong> {s.title}</Grid>
        <Grid item><strong>Type:</strong> {s.type}</Grid>
        <Grid item><strong>Category:</strong> {s.category}</Grid>
        <Grid item><strong>Price:</strong> {s.price && Dinero({amount: s.price}).toFormat('$0.00')}</Grid>
        <Grid item><strong>Description:</strong> {s.description}</Grid>
      </Grid>)}
    </Grid>}
  </Grid>
  </>;
}

export default GenerateMenuTest;
