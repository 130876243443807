import * as React from 'react';
import { SvgIconProps } from '../SvgIcon';

export const BoltIconOutlined = (props: SvgIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M8.66667 1.33337L2 9.33337H8L7.33333 14.6667L14 6.66671H8L8.66667 1.33337Z"
      stroke="#A1A1AA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
