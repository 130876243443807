import { Presale, ShopTheme, getImageUrl, getPresaleImageUrl, hasSchedule } from '@castiron/domain';
import { getProductStatus } from '@castiron/utils';
import { Grid, useMediaQuery } from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import _ from 'lodash';
import { Moment } from 'moment';
import momentTimezone from 'moment-timezone';
import React, { ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { ButtonV2 } from '../Button';
import { CalendarIcon, NoPhotoIcon } from '../Icons';
import Pill from '../Pill';
import Typography from '../Typography';

interface Props {
  onClick: () => void;
  presale: Presale;
  context: 'admin' | 'homePage' | 'presalePage' | 'template';
  actionMenu?: ReactNode;
  disabled?: boolean;
  themeProps?: ShopTheme;
  timeZone: string;
}

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  container: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  disabled: {
    opacity: '64%',
    cursor: 'auto',
  },
  featuredPill: {
    backgroundColor: theme.branding.v2.yellow[50],
    top: 8,
    borderRadius: 15,
    height: 28,
    padding: '4px 8px',
    position: 'absolute',
    left: 8,
    width: 'fit-content',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  },
  image: {
    aspectRatio: '1/1',
    height: '100%',
    objectFit: 'cover',
    width: '100%',
  },
  imageContainer: {
    borderRadius: props => (props?.themeProps?.shopButtonRoundness == '0px' ? 0 : 12),
    overflow: 'hidden',
    position: 'relative',
    marginBottom: 16,
    border: `1px solid ${theme.branding.v2.gray[100]}`,
    backgroundColor: theme.branding.v2.gray[0],
  },
  livePill: {
    bottom: 8,
    borderRadius: 15,
    height: 28,
    padding: '4px 8px',
    position: 'absolute',
    right: 8,
    width: 'fit-content',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    '&:hover': {
      backgroundColor: props => `${theme.branding.v2[props?.themeProps?.shopButtonColor || 'blue'][500]} !important`,
    },
  },
  noPhotoIcon: {
    color: theme.branding.v2.gray[400],
    height: 42,
    width: 42,
  },
  noPhotoText: {
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 4,
    WebkitBoxOrient: 'vertical',
  },
  presaleTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  statusPill: {
    backgroundColor: theme.branding.v2.gray[0],
    bottom: 8,
    borderRadius: 15,
    height: 28,
    padding: '4px 8px',
    position: 'absolute',
    right: 8,
    width: 'fit-content',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  },
  statusPillDecorator: {
    fontSize: 16,
    marginRight: 4,
    color: theme.branding.v2.green[500],
  },
  text: {
    color: theme.branding.v2.gray[500],
  },
}));

const PresaleCard: React.FC<Props> = (props: Props) => {
  const { onClick, presale, context, actionMenu, disabled, themeProps, timeZone } = props;

  const classes = useStyles(props);
  const theme = useTheme();
  const isXsMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [isXsProductCard, setIsXsProductCard] = useState(isXsMobile);
  const ref = useRef(null);
  const isShop = context === 'homePage' || context === 'presalePage';
  const numOfPresaleProducts = presale?.products?.length;
  const numOfPresaleFulfillments = presale?.fulfillments?.length;

  let presaleImageUrl = getPresaleImageUrl(presale, 'mediumVersion');

  useLayoutEffect(() => {
    if (!isShop && ref?.current) {
      setIsXsProductCard(ref?.current?.offsetWidth < 150);

      // if window is resized, update
      const handleResize = () => {
        if (!isShop && ref?.current) {
          setIsXsProductCard(ref?.current?.offsetWidth < 150);
        }
      };

      if (!isShop) {
        window.addEventListener('resize', handleResize);
      }
    }
  }, [ref]);

  const image = presaleImageUrl ? (
    <img src={presaleImageUrl} className={classes.image} alt={presale?.title} />
  ) : (
    <Grid
      className={classes.image}
      container
      justify="center"
      alignItems="center"
      style={
        isShop
          ? {
              padding: 8,
              backgroundColor: themeProps?.themeColors?.accent?.secondary || theme.branding.v2.gray[200],
            }
          : {}
      }
    >
      {isShop ? (
        <Typography className={classes.noPhotoText} variant="body2">
          {presale?.title}
        </Typography>
      ) : (
        <NoPhotoIcon className={classes.noPhotoIcon} />
      )}
    </Grid>
  );

  const statusPill = () => {
    const status = getProductStatus(presale, timeZone);
    return (
      <Grid container className={classes.statusPill} justify="center" alignItems="center">
        <Typography variant="caption">
          <span
            className={classes.statusPillDecorator}
            style={
              status === 'inactive'
                ? { color: theme.branding.v2.red[500] }
                : status === 'archived'
                ? { color: theme.branding.v2.gray[500] }
                : {}
            }
          >
            {status === 'scheduled' ? '◦' : '•'}
          </span>
          {_.capitalize(status)}
        </Typography>
      </Grid>
    );
  };

  const featuredPill = !isShop && presale?.isFeatured && (
    <Grid
      container
      className={classes.featuredPill}
      justify="center"
      alignItems="center"
      style={isXsProductCard ? { height: 28, width: 28 } : {}}
    >
      <Typography variant="caption">⭐️{isXsProductCard ? '' : ' Featured'}</Typography>
    </Grid>
  );

  const livePill = (
    <ButtonV2 className={classes.livePill} disableRipple variant="contained">
      Live
    </ButtonV2>
  );

  const daysLeftPill = () => {
    const todaysDate = momentTimezone().tz(timeZone);
    const endDate = momentTimezone.unix(presale.schedule.endTime).tz(timeZone);
    const daysLeft = endDate.diff(todaysDate, 'days');
    const hoursLeft = endDate.diff(todaysDate, 'hours');
    const minsLeft = endDate.diff(todaysDate, 'minutes');
    const text = () => {
      if (daysLeft > 0) {
        return `${daysLeft} day${daysLeft === 1 ? '' : 's'} left${context === 'presalePage' ? ' to shop' : ''}`;
      } else {
        if (hoursLeft > 0) {
          return `${hoursLeft} hour${hoursLeft === 1 ? '' : 's'} left${context === 'presalePage' ? ' to shop' : ''}`;
        } else {
          return `${minsLeft} minute${minsLeft === 1 ? '' : 's'} left${context === 'presalePage' ? ' to shop' : ''}`;
        }
      }
    };
    return <Pill content={text()} themeProps={themeProps} />;
  };

  const formatDate = (date: Moment, dateFormat) => {
    return date.tz(timeZone).format(dateFormat);
  };
  const getScheduleText = () => {
    const adminScheduleFormat = 'M/D/YY h:mmA';
    const homePageStartTimeFormat = 'MMM. D';
    const homePageStartTimeFormatYear = 'MMM. D, YYYY';
    const shopPageEndTimeFormat = 'MMM. D, YYYY [at] h:mmA';
    const startDateMoment = momentTimezone.unix(presale?.schedule?.startTime);
    const endDateMoment = momentTimezone.unix(presale?.schedule?.endTime);
    if (context === 'admin') {
      return `${formatDate(startDateMoment, adminScheduleFormat)} - ${formatDate(endDateMoment, adminScheduleFormat)}`;
    } else if (context === 'homePage') {
      return `${formatDate(
        startDateMoment,
        startDateMoment.isSame(endDateMoment, 'year') ? homePageStartTimeFormat : homePageStartTimeFormatYear,
      )} - ${formatDate(endDateMoment, shopPageEndTimeFormat)}`;
    } else if (context === 'presalePage') {
      return `Sales end ${formatDate(endDateMoment, shopPageEndTimeFormat)}`;
    }
  };

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={`${disabled ? classes.disabled : classes.container}`}
      onClick={onClick}
      ref={ref}
    >
      <Grid
        container
        item
        justify="center"
        alignItems="center"
        className={classes.imageContainer}
        style={!presaleImageUrl ? { backgroundColor: theme.branding.v2.gray[100] } : {}}
      >
        {featuredPill}
        {actionMenu}
        {image}
        {context === 'admin' && statusPill()}
        {isShop && livePill}
      </Grid>
      <Grid item style={{ marginBottom: 4 }}>
        <Typography variant="subtitle1" className={classes.presaleTitle}>
          {presale?.title}
        </Typography>
      </Grid>
      <Grid container item direction="column" wrap="nowrap" style={{ gap: 8 }}>
        {hasSchedule(presale) && (
          <>
            <Grid container item wrap="nowrap">
              {context === 'admin' && <CalendarIcon style={{ height: 20, width: 20 }} />}
              <Typography className={classes.text} variant="body2">
                {getScheduleText()}
              </Typography>
            </Grid>
            {isShop && daysLeftPill()}
          </>
        )}
        {isShop && presale?.description && <Typography variant="body1">{presale.description}</Typography>}
        {!isShop && (
          <Grid alignItems="flex-start" container item wrap="nowrap">
            <Typography className={classes.text} variant="body2">
              {`${numOfPresaleProducts} product${
                numOfPresaleProducts === 1 ? '' : 's'
              } • ${numOfPresaleFulfillments} fulfillment${numOfPresaleFulfillments === 1 ? '' : 's'}`}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default PresaleCard;
