import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { useAppDispatch } from '../../hooks';
import { openModal } from '../../store/reducers/modalConductor';
import obModalDesktopScreen1 from '../../assets/img/onboardingModals/productModal/ProductDesktopScreen1.png';
import obModalDesktopScreen2 from '../../assets/img/onboardingModals/productModal/ProductDesktopScreen2.png';
import obModalDesktopScreen3 from '../../assets/img/onboardingModals/productModal/ProductDesktopScreen3.png';
import obModalDesktopScreen4 from '../../assets/img/onboardingModals/productModal/ProductDesktopScreen4.png';
import obModalDesktopScreen5 from '../../assets/img/onboardingModals/productModal/ProductDesktopScreen5.png';
import obModalMobileScreen1 from '../../assets/img/onboardingModals/productModal/ProductMobileScreen1.png';
import obModalMobileScreen2 from '../../assets/img/onboardingModals/productModal/ProductMobileScreen2.png';
import obModalMobileScreen3 from '../../assets/img/onboardingModals/productModal/ProductMobileScreen3.png';
import obModalMobileScreen4 from '../../assets/img/onboardingModals/productModal/ProductMobileScreen4.png';
import obModalMobileScreen5 from '../../assets/img/onboardingModals/productModal/ProductMobileScreen5.png';
import { EmptyProductsIcon } from '@castiron/components/src/Icons';
import EmptyPage, { TipsForYou } from '../EmptyPage';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    height: 120,
    width: 120,
  },
  productModalMediaAdjustments: {
    '& img': {
      height: '100%',
      width: '100%',
    },
  },
  productModalContentAdjustments: {
    maxWidth: '100% !important',
  },
  resourceGuide: {
    [theme.breakpoints.down('sm')]: {
      margin: '0px 16px',
    },
  },
}));

const EmptyProduct: React.FC = props => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const isMobileXs = useMediaQuery(theme.breakpoints.down('xs'));

  const productModalContent = [
    {
      header: 'Let Your Customers Know What You Offer!',
      body: 'Select from our two different product types.',
      media: isMobileXs ? (
        <img src={obModalMobileScreen1} alt="Welcome Product Screen 1" />
      ) : (
        <img src={obModalDesktopScreen1} alt="Welcome Product Screen 1" />
      ),
      mediaClassName: classes.productModalMediaAdjustments,
      contentClassName: classes.productModalContentAdjustments,
    },
    {
      header: 'Product Type #1: Instant Checkout Products',
      body:
        "This type is perfect for products that have standard pricing, are mostly the same for all customers, and don't require your review and approval before purchase.",
      media: isMobileXs ? (
        <img src={obModalMobileScreen2} alt="Welcome Product Screen 1" />
      ) : (
        <img src={obModalDesktopScreen2} alt="Welcome Product Screen 1" />
      ),
      mediaClassName: classes.productModalMediaAdjustments,
      contentClassName: classes.productModalContentAdjustments,
    },
    {
      header: 'Product Type #2: Custom Order Forms',
      body:
        'This type is perfect for products that require an order form to add customization, variable pricing and invoicing, and owner approval and review prior to purchase.',
      media: isMobileXs ? (
        <img src={obModalMobileScreen3} alt="Welcome Product Screen 3" />
      ) : (
        <img src={obModalDesktopScreen3} alt="Welcome Product Screen 3" />
      ),
      mediaClassName: classes.productModalMediaAdjustments,
      contentClassName: classes.productModalContentAdjustments,
    },
    {
      header: 'Offer Products That Fit Each Type',
      body:
        'Add as many products or order forms as you need and choose when they appear in your shop by marking them as either “active” or “inactive”.',
      media: isMobileXs ? (
        <img src={obModalMobileScreen4} alt="Welcome Product Screen 4" />
      ) : (
        <img src={obModalDesktopScreen4} alt="Welcome Product Screen 4" />
      ),
      mediaClassName: classes.productModalMediaAdjustments,
      contentClassName: classes.productModalContentAdjustments,
    },
    {
      header: "See Your Products Through Your Customers' Eyes!",
      body: (
        <>
          After editing, click <b>View My Shop</b> located on your home tab. Click on any product or order form to view
          exactly what your customers will see.
        </>
      ),
      media: isMobileXs ? (
        <img src={obModalMobileScreen5} alt="Welcome Product Screen 5" />
      ) : (
        <img src={obModalDesktopScreen5} alt="Welcome Product Screen 5" />
      ),
      mediaClassName: classes.productModalMediaAdjustments,
      contentClassName: classes.productModalContentAdjustments,
    },
  ];

  const openWelcomeProductModal = () => {
    dispatch(
      openModal({
        modalType: 'ONBOARDING_MODAL',
        modalProps: {
          show: true,
          stepContent: productModalContent,
          onboardingModalType: 'productModal',
        },
      }),
    );
  };

  const openAddProductModal = () => {
    dispatch(
      openModal({
        modalType: 'ADD_PRODUCT_MODAL',
        modalProps: {
          show: true,
        },
      }),
    );
  };

  const tipsForYou: TipsForYou[] = [
    {
      icon: 'preview',
      title: 'View an Example',
      description: 'Get an idea of what you and your customers will see.',
      ctaAction: () => openWelcomeProductModal(),
    },
    {
      icon: 'article',
      title: 'Read Our Product Guide',
      description: 'Learn how to make product listings that sell.',
      ctaAction: () =>
        window
          .open(
            'https://castiron.helpscoutdocs.com/article/95-how-to-create-the-perfect-products-in-castiron',
            '_blank',
          )
          .focus(),
    },
  ];

  return (
    <Grid container item xs={12} className={classes.resourceGuide}>
      <EmptyPage
        icon={<EmptyProductsIcon className={classes.icon} />}
        title="Add a Product"
        description="No products to display. Add your first product or order form to display in your shop."
        ctaAction={() => openAddProductModal()}
        ctaText="Add a Product"
        tipsForYou={tipsForYou}
        page="Products"
      />
    </Grid>
  );
};

export default EmptyProduct;
