import { ButtonV2, Dropdown, Pill, Typography } from '@castiron/components';
import { BaseProduct, EventPage, getProductStatus, Special } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { Grid, Paper, Theme, makeStyles, useTheme } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { shopRepository } from '../../../domain';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeModal, openModal } from '../../../store/reducers/modalConductor';
import { getShopAction } from '../../../store/reducers/shops';
import ViewShopButton from '../../Layout/Header/ViewShopButton';
import { PopupContent } from '.';

interface Props {
  activeShopEvents: Special[];
  content: PopupContent;
  products: BaseProduct[];
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: `1px solid ${theme.branding.v2.gray[300]}`,
    borderRadius: 12,
    overflow: 'hidden',
    display: 'grid',
    gridTemplateRows: 'auto max-content',
    gridTemplateColumns: '1fr',
  },
  description: {
    padding: '24px 16px',
  },
  dropdownContainer: {
    color: theme.branding.v2.gray[500],
    '&:hover': {
      color: theme.branding.v2.gray[800],
    },
    position: 'absolute',
    top: '8px',
    right: '8px',
    height: '40px',
    width: '40px',
  },
  image: {
    height: '100%',
    width: '100%',
  },
  paperClass: {
    [theme.breakpoints.up('sm')]: {
      height: 'fit-content !important',
      maxHeight: 624,
    },
  },
  statusDecorator: {
    color: theme.branding.v2.green[500],
    marginRight: 8,
    fontSize: 16,
  },
  statusPill: {
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    position: 'absolute',
    left: 8,
    bottom: 8,
  },
}));

const SinglePopup: React.FC<Props> = (props: Props) => {
  const { activeShopEvents, content, products } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { trackEvent } = useTracking();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [eventPage, setEventPage] = useState<EventPage>(null);

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const eventProducts = products.filter(
    p => p?.eventTags?.includes(content.tag) && getProductStatus(p, shop?.config?.timeZone) === 'active',
  );
  const hasEventProducts = !_.isEmpty(eventProducts);

  useEffect(() => {
    if (content.isEvent) {
      const eventPage = shop?.shopSubpageData?.events?.find(event => event.tag === content.tag);
      if (eventPage) {
        setShowPopup(eventPage?.showPopup && hasEventProducts);
        setEventPage(eventPage);
      }
    } else if (content.tag === 'subscribe') {
      // only set to false if it's been set to false (i.e. not undefined), otherwise set to true
      setShowPopup(shop?.config?.popups?.email?.showPopup !== false && shop?.config?.popups?.sms?.showPopup !== false);
    } else if (content.tag === 'holidays') {
      const activeHolidayTags = activeShopEvents?.map(e => e.tag) || [];
      const shopHasActiveHolidayPages = activeHolidayTags?.some(tag => {
        const eventProducts = products.filter(
          p => p?.eventTags?.includes(tag) && getProductStatus(p, shop?.config?.timeZone) === 'active',
        );
        const isEventPageEnabled = shop?.shopSubpageData?.events?.find(e => e.tag == tag)?.enabled !== false
        return shop?.config?.popups?.holidays?.showPopup && !_.isEmpty(eventProducts) && isEventPageEnabled;
      });
      setShowPopup(shopHasActiveHolidayPages);
    }
  }, [shop, products]);

  const toggleShowPopup = async () => {
    if (content?.isEvent) {
      trackEvent(`Popup Turned ${!showPopup ? 'On' : 'Off'}`, { type: content.tag });
      let pageTurnedOn = false;
      const newEventPage = {
        tag: content.tag,
        enabled: !!eventPage?.enabled,
        headline: eventPage?.headline || content?.eventDefaults?.headline,
        description: eventPage?.description || content?.eventDefaults?.description,
        showPopup: !showPopup,
      };
      const isEventEnabled = eventPage?.enabled !== false && hasEventProducts;
      if (!isEventEnabled && !showPopup) {
        pageTurnedOn = true;
        newEventPage.enabled = true;
      }
      let newEvents = shop?.shopSubpageData?.events ? _.cloneDeep(shop?.shopSubpageData?.events) : [];
      if (eventPage) {
        newEvents = newEvents?.map(event => (event?.tag === content.tag ? newEventPage : event));
      } else {
        newEvents.push(newEventPage);
      }
      await shopRepository.updateProps(shop.id, {
        'shopSubpageData.events': newEvents,
      });
      await dispatch(getShopAction(shop.id));
      await dispatch(
        openModal({
          modalType: pageTurnedOn ? 'GENERAL_MODAL' : 'SIMPLE_ALERT',
          modalProps: pageTurnedOn
            ? {
                show: true,
                paperClass: classes.paperClass,
                icon: content.icon,
                title: `${content?.name} Popup and Page Turned On`,
                content: (
                  <Typography variant="body1">
                    The {content?.name} popup will direct shoppers to your new and active <b>{content?.name} page</b> -
                    customize this in <b>Pages</b>.
                  </Typography>
                ),
                actions: [
                  <ButtonV2 onClick={() => dispatch(closeModal())} variant="outlined">
                    Done
                  </ButtonV2>,
                  <ButtonV2
                    onClick={() => {
                      dispatch(closeModal());
                      history.push(`/store/event/${content?.slug}`);
                    }}
                    variant="contained"
                  >
                    Go to Pages
                  </ButtonV2>,
                ],
              }
            : {
                show: true,
                content: (
                  <>
                    {content?.popup?.title} is now {showPopup ? 'inactive' : 'active'}.
                  </>
                ),
              },
        }),
      );
    } else if (content.tag === 'subscribe') {
      trackEvent(`Popup Turned ${!showPopup ? 'On' : 'Off'}`, { type: content.tag });
      await shopRepository.updateProps(shop.id, {
        'config.popups.email.showPopup': !showPopup,
        'config.popups.sms.showPopup': !showPopup,
      });
      await dispatch(getShopAction(shop.id));
      await dispatch(
        openModal({
          modalType: showPopup ? 'SIMPLE_ALERT' : 'GENERAL_MODAL',
          modalProps: showPopup
            ? {
                show: true,
                content: <>Your Popup is Inactive.</>,
              }
            : {
                show: true,
                paperClass: classes.paperClass,
                icon: '🎊',
                content: (
                  <Grid container item style={{ gap: '16px' }} direction="column" wrap="nowrap">
                    <Typography variant="h2">Your Popup is Live</Typography>
                    <Typography variant="body1">Preview your site to see it in action.</Typography>
                    <Grid container item style={{ gap: '16px' }} direction="column" wrap="nowrap">
                      <ViewShopButton
                        addQueryParams={{ openModalNumber: 1 }}
                        onClick={() => {
                          dispatch(closeModal());
                        }}
                        text="Preview"
                        variant="contained"
                      />
                      <ButtonV2 onClick={() => dispatch(closeModal())} variant="outlined">
                        Done
                      </ButtonV2>
                    </Grid>
                  </Grid>
                ),
              },
        }),
      );
    } else if (content.tag === 'holidays') {
      trackEvent(`Popup Turned ${!showPopup ? 'On' : 'Off'}`, { type: content.tag });
      await shopRepository.updateProps(shop.id, {
        'config.popups.holidays.showPopup': !showPopup,
      });
      await dispatch(getShopAction(shop.id));
      await dispatch(
        openModal({
          modalType: showPopup ? 'SIMPLE_ALERT' : 'GENERAL_MODAL',
          modalProps: showPopup
            ? {
                show: true,
                content: <>Your Popup is Inactive.</>,
              }
            : {
                show: true,
                paperClass: classes.paperClass,
                icon: '🎊',
                content: (
                  <Grid container item style={{ gap: '16px' }} direction="column" wrap="nowrap">
                    <Typography variant="h2">Your Popup is Live</Typography>
                    <Typography variant="body1">Preview your site to see it in action.</Typography>
                    <Grid container item style={{ gap: '16px' }} direction="column" wrap="nowrap">
                      <ViewShopButton
                        addQueryParams={{ openModalNumber: 2 }}
                        onClick={() => {
                          dispatch(closeModal());
                        }}
                        text="Preview"
                        variant="contained"
                      />
                      <ButtonV2 onClick={() => dispatch(closeModal())} variant="outlined">
                        Done
                      </ButtonV2>
                    </Grid>
                  </Grid>
                ),
              },
        }),
      );
    }
  };

  return (
    <Grid container item className={classes.container}>
      <Grid container item style={{ position: 'relative' }}>
        <img src={content?.popup?.imageUrl} className={classes.image} alt="Image of Popup" />
        <Pill
          className={classes.statusPill}
          content={
            showPopup ? (
              <>
                <span className={classes.statusDecorator}>•</span>Active
              </>
            ) : (
              <>
                <span className={classes.statusDecorator} style={{ color: theme.branding.v2.red[500] }}>
                  •
                </span>
                Inactive
              </>
            )
          }
          variant="white"
        />
        <Paper className={classes.dropdownContainer}>
          <Grid container style={{ height: '100%' }} justify="center" alignItems="center">
            <Grid item>
              <Dropdown
                variant="ellipsis"
                options={[
                  {
                    label: showPopup ? 'Turn Off' : 'Turn On',
                    onClick: toggleShowPopup,
                    ...(showPopup ? { color: 'error' } : {}),
                  },
                ]}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid container item className={classes.description} direction="column" wrap="nowrap">
        <Typography variant="subtitle1">{content?.popup?.title}</Typography>
        <Typography variant="body1">{content?.popup?.description}</Typography>
      </Grid>
    </Grid>
  );
};

export default SinglePopup;
