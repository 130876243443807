import { Typography } from '@castiron/components';
import { Address } from '@castiron/domain';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import React from 'react';
import { useAppDispatch } from '../../hooks';
import { openModal } from '../../store/reducers/modalConductor';

interface Props {
  address: Address;
  context?: 'fulfillment' | 'quote';
  onSuccess?: (address: Address) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  addAddressButton: {
    color: theme.branding.v2.blue[500],
    '&:hover': {
      cursor: 'pointer',
    },
  },
  container: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 12,
    gap: 8,
    padding: 12,
    wordBreak: 'break-word',
  },
}));

const AddressAddAndDisplay: React.FC<Props> = (props: Props) => {
  const { address, context, onSuccess } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const displaySeoLocationModal = () => {
    dispatch(
      openModal({
        modalType: 'SEO_LOCATION_MODAL',
        modalProps: {
          context: context,
          onSuccess: onSuccess,
          show: true,
        },
      }),
    );
  };

  return (
    <Grid className={classes.container} container wrap="nowrap" justify="flex-start">
      <Grid item>
        <LocationOnOutlinedIcon />
      </Grid>
      <Grid container direction="column" item wrap="nowrap">
        {address?.addressLine1 ? (
          <>
            <Typography variant="body2">{address?.addressLine1}</Typography>
            {address?.addressLine2 && <Typography variant="body2">{address?.addressLine2}</Typography>}
            <Typography variant="body2">
              {address?.city}, {address?.region} {address?.postalCode}
            </Typography>
          </>
        ) : address?.fullAddress ? (
          <Typography variant="body2">{address?.fullAddress}</Typography>
        ) : (
          <>
            <Typography variant="body2">None</Typography>
            <Typography variant="subtitle2" className={classes.addAddressButton} onClick={displaySeoLocationModal}>
              + Add Address
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default AddressAddAndDisplay;
