import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Box, DialogActions, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ButtonV2, ChipSelectInput, Typography } from '@castiron/components';
import { ProductType, holidays, occasions } from '@castiron/domain';
import { useFormikContext } from 'formik';
import { ProductModalProps } from './AddProductModal';

interface Props extends ProductModalProps {
  productType: ProductType;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '350px',
    },
  },
  footer: {
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    padding: '12px 24px',
  },
  aiIcon: {
    height: 16,
    width: 16,
    color: theme.branding.blue.primary,
  },
}));

interface FormValues {
  holidays: string[];
  occasions: string[];
}

const TagProduct: React.FC<Props> = (props: Props) => {
  const { context, setTitle, setFooter, productType, setStep, setStepNumber } = props;
  const { values, isSubmitting } = useFormikContext<FormValues>();
  const classes = useStyles();

  const submit = async (values: FormValues) => {
    setStep('image');
  };

  useEffect(() => {
    setTitle(context === 'order-forms' || productType === 'custom' ? 'Tag Your Order Form' : context === 'products' || productType === 'standard' ? 'Tag Your Product' : 'New Event');
    setStepNumber(2);
  }, []);

  useEffect(() => {
    setFooter(
      <DialogActions className={classes.footer}>
        <Grid container justify="flex-end" spacing={1}>
          <Grid item>
            <ButtonV2 variant="outlined" onClick={() => setStep('nameAndPrice')}>
              Back
            </ButtonV2>
          </Grid>
          <Grid item>
            <ButtonV2 variant="contained" onClick={() => submit(values)} loading={isSubmitting}>
              Continue
            </ButtonV2>
          </Grid>
        </Grid>
      </DialogActions>,
    );

    return () => {
      setFooter(null);
    };
  }, []);

  return (
    <>
      <Box className={classes.container}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="body1">
              🪄 Select relevant holidays and occasions to maximize sales with our <b>automated marketing tools</b> and
              more.
            </Typography>
          </Grid>
          <Grid item>
            <ChipSelectInput
              label="Holidays"
              secondaryLabel={`Select all major holidays this ${
                productType === 'event' ? 'event' : 'product'
              } caters to, such as Halloween.`}
              name="holidays"
              options={holidays}
              value={values.holidays}
            />
          </Grid>
          <Grid item>
            <ChipSelectInput
              label="Occasions"
              secondaryLabel={`Select all ongoing occasions this ${
                productType === 'event' ? 'event' : 'product'
              } caters to, such as Weddings and Birthdays.`}
              name="occasions"
              options={occasions}
              value={values.occasions}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TagProduct;
