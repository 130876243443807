import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

interface Props {
  to: string;
  children: ReactNode;
  underline?: boolean;
  color?: 'inherit';
};

const RouterLink: React.FC<Props> = (props: Props) => {
  const { to, children, underline, color } = props;

  const style = {
    ...(underline && { textDecoration: 'underline' }),
    ...(color && { color })
  };

  return (
    <Link to={to} style={style}>
      {children}
    </Link>
  );
};

export default RouterLink;