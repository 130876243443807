import React, { ElementType } from 'react';
import { IconButton, IconButtonProps, SvgIcon, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface Props extends IconButtonProps {
  ariaLabel?: string;
  disabled?: any;
  icon: ElementType;
  iconClassName?: any;
  isInModal?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  amountBtn: {
    width: 25,
    height: 25,
    border: `1px solid ${theme.branding.v2.gray[200]}`,
  },
  amountBtnModal: {
    width: 56,
    height: 56,
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    margin: '0px 24px',
  },
  btnIcon: {
    fontSize: 17,
  },
}));

const CircleButton: React.FC<Props> = (props: Props) => {
  const {
    ariaLabel,
    disabled,
    icon,
    iconClassName,
    isInModal = false,
    onClick,
    style = {},
    
  } = props;
  const classes = useStyles();

  return (
    <IconButton
      aria-label={ariaLabel}
      onFocus={(e): void => e.stopPropagation()}
      onClick={onClick}
      className={isInModal ? classes.amountBtnModal : classes.amountBtn}
      disabled={disabled}
      style={style}
    >
      <SvgIcon component={icon} className={`${classes.btnIcon} ${iconClassName}`} />
    </IconButton>
  );
};

export default CircleButton;
