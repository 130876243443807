import { Button, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloudUpload from '@material-ui/icons/CloudUpload';
import React, {ReactNode} from 'react';
import { useDropzone } from 'react-dropzone';

type Props = {
  onFileDrop: (file) => void;
  fileType?: string;
  containerClass?: string;
  dropzoneClass?: string;
  dropzoneText?: string;
  browseButtonRef?: React.MutableRefObject<any>;
  imageInProduct?: boolean;
  maxFiles?: number;
  maxFilesError?: boolean;
  closeToMax?: boolean;
  children?: ReactNode;
  customColorName?: string;
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  outerContainer: {
    width: '100%',
    position: 'relative',
    paddingBottom: '100%',
  },
  dropzone: {
    border: '1px dashed #AEB4B9',
    borderRadius: 4,
    backgroundColor: '#E6E6EA3D',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '68px 15px',
    maxHeight: 300,
  },
  text: {
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'center'
  },
  icon: {
    fontSize: 24,
  },
  productDropzone: {
    padding: '16px 15px',
    maxHeight: '128px',
  },
  subtext: {
    display: 'flex',
    gap: 4,
    fontSize: 14,
    color: theme.branding.iron,
    fontWeight: 700,
    lineHeight: '16px',
  },
  browseButton: {
    fontSize: 14,
    lineHeight: '16px',
    color: props => props.customColorName ? theme.branding.v2[props.customColorName][500] : theme.branding.blueberry,
    fontWeight: 700,
    padding: 0,
  },
  dragging: {
    backgroundColor: props => props.customColorName ? theme.branding.v2[props.customColorName][100] : theme.branding.blue.light
  }
}));

const FileUploader: React.FC<Props> = (props: Props) => {
  const { onFileDrop, fileType, containerClass, dropzoneClass, dropzoneText, browseButtonRef, imageInProduct, maxFiles, maxFilesError, closeToMax } = props;
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({ accept: fileType, onDrop: onFileDrop, maxFiles: maxFiles || 1, noDrag: maxFilesError, noClick: maxFilesError, disabled: maxFilesError, multiple: !closeToMax });
  const classes = useStyles(props);

  return (
    <div className={containerClass}>
      <div {...getRootProps({ className: `dropzone ${classes.dropzone} ${dropzoneClass} ${imageInProduct && classes.productDropzone} ${isDragActive ? classes.dragging : ''}` })}>
        <input {...getInputProps()} />
        <CloudUpload className={classes.icon} />
        <Typography className={classes.text}>{dropzoneText || 'DROP FILE HERE'}</Typography>
        {!maxFilesError && (
        <Typography className={classes.subtext}>
          {imageInProduct ? 'or' : 'OR '}
          <Button className={classes.browseButton} component="button" ref={browseButtonRef}>
            {imageInProduct ? 'browse' : 'BROWSE'}
          </Button>
        </Typography>
        )}
      </div>
    </div>
  );
};

export default FileUploader;
