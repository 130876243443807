import _ from 'lodash';
import { taxonomy } from './data';

const getKeys = (taxonomy: {}, parentCategory?: string): string[] => {
  //gets "mid" level categories (ie: Cookies, Cakes, Desserts, etc.) by default
  let categories = [];
  Object.keys(taxonomy).forEach(key => {
    if (Array.isArray(taxonomy[key])) {
      if (key.toLowerCase() === 'other') {
        categories.push(`${key}${parentCategory ? ` ${parentCategory}` : ''}`);
      } else {
        categories.push(key);
      }
    } else {
      categories = categories.concat(getKeys(taxonomy[key], key));
    }
  });
  return categories;
};

export const taxonomyToStrings = (withValue?: boolean, custSeparator?: string) => {
  const separator = custSeparator ? custSeparator : ' > ';

  let mappedCats = [];

  _.forIn(taxonomy, (midValues, key) => {
    const top = key;
    const midsArr = getKeys(midValues);

    midsArr.forEach((mid, index, arr) => {
      const leafs = _.get(midValues, mid);
      leafs.forEach(leaf => {
        const catString = top + separator + mid + separator + leaf;

        withValue
          ? mappedCats.push({
              text: catString,
              value: { top, mid, leaf },
            })
          : mappedCats.push(catString);
      });
    });
  });

  return mappedCats;
};

export const getTaxonomyCategories = (): string[] => {
  return getKeys(taxonomy);
};

export const categoryMappings = {
  baking: {
    category: 'Baking',
    displayText: 'Baking',
  },
  cookies: {
    category: 'Cookies',
    displayText: 'Cookies',
  },
  'custom-decorated-cookies': {
    category: 'Custom Decorated Cookies',
    displayText: 'Custom Decorated Cookies',
  },
  'custom-printed-cookies': {
    category: 'Custom Printed Cookies',
    displayText: 'Custom Printed Cookies',
  },
  'cookie-decorating-kits': {
    category: 'Cookie Decorating Kits',
    displayText: 'Cookie Decorating Kits',
  },
  macarons: {
    category: 'Macarons',
    displayText: 'Macarons',
  },
  'sandwich-cookies': {
    category: 'Sandwich cookies',
    displayText: 'Sandwich Cookies',
  },
  macaroons: {
    category: 'Macaroons',
    displayText: 'Macaroons',
  },
  'cookie-treats': {
    category: 'Cookie Treats',
    displayText: 'Cookie Treats',
  },
  'stuffed-cookies': {
    category: 'Stuffed Cookies',
    displayText: 'Stuffed Cookies',
  },
  'cookie-bars': {
    category: 'Cookie Bars',
    displayText: 'Cookie Bars',
  },
  'cookie-cake': {
    category: 'Cookie Cake',
    displayText: 'Cookie Cake',
  },
  biscotti: {
    category: 'Biscotti',
    displayText: 'Biscotti',
  },
  'whoopie-pies': {
    category: 'Whoopie Pies',
    displayText: 'Whoopie Pies',
  },
  'other-cookies': {
    category: 'Other Cookies',
    displayText: 'Other Cookies',
  },
  cakes: {
    category: 'Cakes',
    displayText: 'Cakes',
  },
  'wedding-cake': {
    category: 'Wedding Cake',
    displayText: 'Wedding Cake',
  },
  'birthday-cake': {
    category: 'Birthday Cake',
    displayText: 'Birthday Cake',
  },
  'custom-cake': {
    category: 'Custom Cake',
    displayText: 'Custom Cake',
  },
  'sheet-cakes': {
    category: 'Sheet Cakes',
    displayText: 'Sheet Cakes',
  },
  'small-cake': {
    category: 'Small Cake',
    displayText: 'Small Cake',
  },
  'large-cake': {
    category: 'Large Cake',
    displayText: 'Large Cake',
  },
  'single-serve-cake': {
    category: 'Single Serve Cake',
    displayText: 'Single Serve Cake',
  },
  cupcakes: {
    category: 'Cupcakes',
    displayText: 'Cupcakes',
  },
  'smash-cakes': {
    category: 'Smash Cakes',
    displayText: 'Smash Cakes',
  },
  'ice-cream-cakes': {
    category: 'Ice Cream Cakes',
    displayText: 'Ice Cream Cakes',
  },
  'pound-cakes': {
    category: 'Pound Cakes',
    displayText: 'Pound Cakes',
  },
  'wedding-cake-tasting-box': {
    category: 'Wedding Cake Tasting Box',
    displayText: 'Wedding Cake Tasting Box',
  },
  'cake-pops': {
    category: 'Cake Pops',
    displayText: 'Cake Pops',
  },
  'fruit-cakes': {
    category: 'Fruit Cakes',
    displayText: 'Fruit Cakes',
  },
  'swiss-roll-cakes': {
    category: 'Swiss Roll Cakes',
    displayText: 'Swiss Roll Cakes',
  },
  'other-cakes': {
    category: 'Other Cakes',
    displayText: 'Other Cakes',
  },
  desserts: {
    category: 'Desserts',
    displayText: 'Desserts',
  },
  'ice-cream': {
    category: 'Ice Cream',
    displayText: 'Ice Cream',
  },
  'frozen-yogurt': {
    category: 'Frozen yogurt',
    displayText: 'Frozen Yogurt',
  },
  puddings: {
    category: 'Puddings',
    displayText: 'Puddings',
  },
  mousse: {
    category: 'Mousse',
    displayText: 'Mousse',
  },
  pies: {
    category: 'Pies',
    displayText: 'Pies',
  },
  tarts: {
    category: 'Tarts',
    displayText: 'Tarts',
  },
  'crumbles-and-cobblers': {
    category: 'Crumbles and Cobblers',
    displayText: 'Crumbles and Cobblers',
  },
  'cream-puffs': {
    category: 'Cream Puffs',
    displayText: 'Cream Puffs',
  },
  'dessert-bars': {
    category: 'Dessert Bars',
    displayText: 'Dessert Bars',
  },
  'churros-and-funnel-cakes': {
    category: 'Churros and Funnel Cakes',
    displayText: 'Churros and Funnel Cakes',
  },
  meringues: {
    category: 'Meringues',
    displayText: 'Meringues',
  },
  pavlovas: {
    category: 'Pavlovas',
    displayText: 'Pavlovas',
  },
  caramels: {
    category: 'Caramels',
    displayText: 'Caramels',
  },
  brittles: {
    category: 'Brittles',
    displayText: 'Brittles',
  },
  marshmallows: {
    category: 'Marshmallows',
    displayText: 'Marshmallows',
  },
  candy: {
    category: 'Candy',
    displayText: 'Candy',
  },
  'other-desserts': {
    category: 'Other Desserts',
    displayText: 'Other Desserts',
  },
  chocolates: {
    category: 'Chocolates',
    displayText: 'Chocolates',
  },
  'chocolate-treats': {
    category: 'Chocolate Treats',
    displayText: 'Chocolate Treats',
  },
  truffles: {
    category: 'Truffles',
    displayText: 'Truffles',
  },
  fudge: {
    category: 'Fudge',
    displayText: 'Fudge',
  },
  brownies: {
    category: 'Brownies',
    displayText: 'Brownies',
  },
  'chocolate-covered-pretzels': {
    category: 'Chocolate Covered Pretzels',
    displayText: 'Chocolate Covered Pretzels',
  },
  'hot-cocoa': {
    category: 'Hot Cocoa',
    displayText: 'Hot Cocoa',
  },
  barks: {
    category: 'Barks',
    displayText: 'Barks',
  },
  'baked-goods': {
    category: 'Baked Goods',
    displayText: 'Baked Goods',
  },
  'cinnamon-rolls': {
    category: 'Cinnamon Rolls',
    displayText: 'Cinnamon Rolls',
  },
  cheesecakes: {
    category: 'Cheesecakes',
    displayText: 'Cheesecakes',
  },
  donuts: {
    category: 'Donuts',
    displayText: 'Donuts',
  },
  pastries: {
    category: 'Pastries',
    displayText: 'Pastries',
  },
  granola: {
    category: 'Granola',
    displayText: 'Granola',
  },
  'energy-bites': {
    category: 'Energy Bites',
    displayText: 'Energy Bites',
  },
  baklava: {
    category: 'Baklava',
    displayText: 'Baklava',
  },
  pretzels: {
    category: 'Pretzels',
    displayText: 'Pretzels',
  },
  muffins: {
    category: 'Muffins',
    displayText: 'Muffins',
  },
  'other-baked-goods': {
    category: 'Other Baked Goods',
    displayText: 'Other Baked Goods',
  },
  breads: {
    category: 'Breads',
    displayText: 'Breads',
  },
  bagels: {
    category: 'Bagels',
    displayText: 'Bagels',
  },
  'artisanal-bread': {
    category: 'Artisanal Bread',
    displayText: 'Artisanal Bread',
  },
  biscuits: {
    category: 'Biscuits',
    displayText: 'Biscuits',
  },
  flatbreads: {
    category: 'Flatbreads',
    displayText: 'Flatbreads',
  },
  tortillas: {
    category: 'Tortillas',
    displayText: 'Tortillas',
  },
  'dinner-rolls': {
    category: 'Dinner Rolls',
    displayText: 'Dinner Rolls',
  },
  buns: {
    category: 'Buns',
    displayText: 'Buns',
  },
  'other-breads': {
    category: 'Other Breads',
    displayText: 'Other Breads',
  },
  'prepared-foods': {
    category: 'Prepared Foods',
    displayText: 'Prepared Foods',
  },
  charcuterie: {
    category: 'Charcuterie',
    displayText: 'Charcuterie',
  },
  'charcuterie-board': {
    category: 'Charcuterie Board',
    displayText: 'Charcuterie Board',
  },
  'charcuterie-cups': {
    category: 'Charcuterie Cups',
    displayText: 'Charcuterie Cups',
  },
  'charcuterie-box': {
    category: 'Charcuterie Box',
    displayText: 'Charcuterie Box',
  },
  'grazing-table': {
    category: 'Grazing Table',
    displayText: 'Grazing Table',
  },
  'other-charcuterie': {
    category: 'Other Charcuterie',
    displayText: 'Other Charcuterie',
  },
  'gift-baskets': {
    category: 'Gift Baskets',
    displayText: 'Gift Baskets',
  },
  'muffin-basket': {
    category: 'Muffin Basket',
    displayText: 'Muffin Basket',
  },
  'cookie-basket': {
    category: 'Cookie Basket',
    displayText: 'Cookie Basket',
  },
  'cupcake-basket': {
    category: 'Cupcake Basket',
    displayText: 'Cupcake Basket',
  },
  'fruit-basket': {
    category: 'Fruit Basket',
    displayText: 'Fruit Basket',
  },
  'nut-basket': {
    category: 'Nut Basket',
    displayText: 'Nut Basket',
  },
  'meat-and-cheese-basket': {
    category: 'Meat and Cheese Basket',
    displayText: 'Meat and Cheese Basket',
  },
  'other-gift-baskets': {
    category: 'Other Gift Baskets',
    displayText: 'Other Gift Baskets',
  },
  'meal-prep': {
    category: 'Meal Prep',
    displayText: 'Meal Prep',
  },
  'prepared-meals-individual': {
    category: 'Prepared Meals - Individual',
    displayText: 'Prepared Meals - Individual',
  },
  'prepared-meals-subscription': {
    category: 'Prepared Meals - Subscription',
    displayText: 'Prepared Meals - Subscription',
  },
  'prepared-lunch-boxes': {
    category: 'Prepared Lunch Boxes',
    displayText: 'Prepared Lunch Boxes',
  },
  'meal-kits': {
    category: 'Meal Kits',
    displayText: 'Meal Kits',
  },
  'other-meal-prep': {
    category: 'Other Meal Prep',
    displayText: 'Other Meal Prep',
  },
  pasta: {
    category: 'Pasta',
    displayText: 'Pasta',
  },
  'pasta-fresh': {
    category: 'Pasta - Fresh',
    displayText: 'Pasta - Fresh',
  },
  'pasta-dried': {
    category: 'Pasta - Dried',
    displayText: 'Pasta - Dried',
  },
  'orzo-and-couscous': {
    category: 'Orzo and Couscous',
    displayText: 'Orzo and Couscous',
  },
  'other-pasta': {
    category: 'Other Pasta',
    displayText: 'Other Pasta',
  },
  'events-and-services': {
    category: 'Events and Services',
    displayText: 'Events and Services',
  },
  classes: {
    category: 'Classes',
    displayText: 'Classes',
  },
  'baking-class-general': {
    category: 'Baking Class - General',
    displayText: 'Baking Class General',
  },
  'cookie-decorating-class': {
    category: 'Cookie Decorating Class',
    displayText: 'Cookie Decorating Class',
  },
  'macaron-class': {
    category: 'Macaron Class',
    displayText: 'Macaron Class',
  },
  'cake-decorating-class': {
    category: 'Cake Decorating Class',
    displayText: 'Cake Decorating Class',
  },
  'cooking-class-general': {
    category: 'Cooking Class - General',
    displayText: 'Cooking Class General',
  },
  'charcuterie-class': {
    category: 'Charcuterie Class',
    displayText: 'Charcuterie Class',
  },
  'other-classes': {
    category: 'Other Classes',
    displayText: 'Other Classes',
  },
  'catering-and-private-chef': {
    category: 'Catering and Private Chef',
    displayText: 'Catering and Private Chef',
  },
  'wedding-catering-inquiry': {
    category: 'Wedding Catering Inquiry',
    displayText: 'Wedding Catering Inquiry',
  },
  'business-event-catering-inquiry': {
    category: 'Business Event Catering Inquiry',
    displayText: 'Business Event Catering Inquiry',
  },
  'special-event-catering-inquiry': {
    category: 'Special Event Catering Inquiry',
    displayText: 'Special Event Catering Inquiry',
  },
  'private-chef-inquiry': {
    category: 'Private Chef Inquiry',
    displayText: 'Private Chef Inquiry',
  },
  'custom-meal-plan-inquiry': {
    category: 'Custom Meal Plan Inquiry',
    displayText: 'Custom Meal Plan Inquiry',
  },
  beverages: {
    category: 'Beverages',
    displayText: 'Beverages',
  },
  coffee: {
    category: 'Coffee',
    displayText: 'Coffee',
  },
  'coffee-beans': {
    category: 'Coffee Beans',
    displayText: 'Coffee Beans',
  },
  'coffee-cold-brew': {
    category: 'Coffee Cold Brew',
    displayText: 'Coffee Cold Brew',
  },
  tea: {
    category: 'Tea',
    displayText: 'Tea',
  },
  'loose-leaf-tea': {
    category: 'Loose Leaf Tea',
    displayText: 'Loose Leaf Tea',
  },
  'tea-bags': {
    category: 'Tea Bags',
    displayText: 'Tea Bags',
  },
  juices: {
    category: 'Juices',
    displayText: 'Juices',
  },
  kombucha: {
    category: 'Kombucha',
    displayText: 'Kombucha',
  },
  'cocktail-mixers': {
    category: 'Cocktail Mixers',
    displayText: 'Cocktail Mixers',
  },
  'other-beverages': {
    category: 'Other Beverages',
    displayText: 'Other Beverages',
  },
  grocery: {
    category: 'Grocery',
    displayText: 'Grocery',
  },
  'condiments-and-sauces': {
    category: 'Condiments and Sauces',
    displayText: 'Condiments and Sauces',
  },
  'bbq-sauce': {
    category: 'BBQ sauce',
    displayText: 'BBQ sauce',
  },
  'hot-sauce': {
    category: 'Hot Sauce',
    displayText: 'Hot Sauce',
  },
  ketchups: {
    category: 'Ketchups',
    displayText: 'Ketchups',
  },
  mustards: {
    category: 'Mustards',
    displayText: 'Mustards',
  },
  'salad-dressings': {
    category: 'Salad Dressings',
    displayText: 'Salad Dressings',
  },
  'caramel-sauce': {
    category: 'Caramel Sauce',
    displayText: 'Caramel Sauce',
  },
  'chocolate-sauce': {
    category: 'Chocolate Sauce',
    displayText: 'Chocolate Sauce',
  },
  'other-condiments': {
    category: 'Other Condiments',
    displayText: 'Other Condiments',
  },
  'spreads-and-dips': {
    category: 'Spreads and Dips',
    displayText: 'Spreads and Dips',
  },
  hummus: {
    category: 'Hummus',
    displayText: 'Hummus',
  },
  'artisanal-spreads-and-dips': {
    category: 'Artisanal Spreads and Dips',
    displayText: 'Artisanal Spreads and Dips',
  },
  pantry: {
    category: 'Pantry',
    displayText: 'Pantry',
  },
  honey: {
    category: 'Honey',
    displayText: 'Honey',
  },
  'lentils-and-legumes': {
    category: 'Lentils and Legumes',
    displayText: 'Lentils and Legumes',
  },
  'grains-general': {
    category: 'Grains - General',
    displayText: 'Grains General',
  },
  'grains-ancient-grains': {
    category: 'Grains - Ancient Grains',
    displayText: 'Grains Ancient Grains',
  },
  flour: {
    category: 'Flour',
    displayText: 'Flour',
  },
  jam: {
    category: 'Jam',
    displayText: 'Jam',
  },
  jelly: {
    category: 'Jelly',
    displayText: 'Jelly',
  },
  pickles: {
    category: 'Pickles',
    displayText: 'Pickles',
  },
  snacks: {
    category: 'Snacks',
    displayText: 'Snacks',
  },
  popcorn: {
    category: 'Popcorn',
    displayText: 'Popcorn',
  },
  crackers: {
    category: 'Crackers',
    displayText: 'Crackers',
  },
  jerky: {
    category: 'Jerky',
    displayText: 'Jerky',
  },
  herbs: {
    category: 'Herbs',
    displayText: 'Herbs',
  },
  spices: {
    category: 'Spices',
    displayText: 'Spices',
  },
  seasoning: {
    category: 'Seasoning',
    displayText: 'Seasoning',
  },
  'soup-mix': {
    category: 'Soup Mix',
    displayText: 'Soup Mix',
  },
  'baking-mix': {
    category: 'Baking Mix',
    displayText: 'Baking Mix',
  },
  'cake-mix': {
    category: 'Cake Mix',
    displayText: 'Cake Mix',
  },
  'canned-goods': {
    category: 'Canned Goods',
    displayText: 'Canned Goods',
  },
  'other-pantry': {
    category: 'Other Pantry',
    displayText: 'Other Pantry',
  },
  dairy: {
    category: 'Dairy',
    displayText: 'Dairy',
  },
  butter: {
    category: 'Butter',
    displayText: 'Butter',
  },
  yogurt: {
    category: 'Yogurt',
    displayText: 'Yogurt',
  },
  'cream-cheese': {
    category: 'Cream Cheese',
    displayText: 'Cream Cheese',
  },
  'soft-cheeses': {
    category: 'Soft Cheeses',
    displayText: 'Soft Cheeses',
  },
  'hard-cheeses': {
    category: 'Hard Cheeses',
    displayText: 'Hard Cheeses',
  },
  'other-dairy': {
    category: 'Other Dairy',
    displayText: 'Other Dairy',
  },
  'other-grocery': {
    category: 'Other Grocery',
    displayText: 'Other Grocery',
  },
  'sourdough-starter': {
    category: 'Sourdough Starter',
    displayText: 'Sourdough Starter',
  },
  marinades: {
    category: 'Marinades',
    displayText: 'Marinades',
  },
};

export const categoryToSlugMappings = {
  baking: 'baking',
  cookies: 'cookies',
  'custom decorated cookies': 'custom-decorated-cookies',
  'custom printed cookies': 'custom-printed-cookies',
  'cookie decorating kits': 'cookie-decorating-kits',
  macarons: 'macarons',
  'sandwich cookies': 'sandwich-cookies',
  macaroons: 'macaroons',
  'cookie treats': 'cookie-treats',
  'stuffed cookies': 'stuffed-cookies',
  'cookie bars': 'cookie-bars',
  'cookie cake': 'cookie-cake',
  biscotti: 'biscotti',
  'whoopie pies': 'whoopie-pies',
  'other cookies': 'other-cookies',
  cakes: 'cakes',
  'wedding cake': 'wedding-cake',
  'birthday cake': 'birthday-cake',
  'custom cake': 'custom-cake',
  'sheet cakes': 'sheet-cakes',
  'small cake': 'small cake',
  'large cake': 'large-cake',
  'single serve cake': 'single-serve-cake',
  cupcakes: 'cupcakes',
  'smash cakes': 'smash-cakes',
  'ice cream cakes': 'ice-cream-cakes',
  'pound cakes': 'pound-cakes',
  'wedding cake tasting box': 'wedding-cake-tasting-box',
  'cake pops': 'cake-pops',
  'fruit cakes': 'fruit-cakes',
  'swiss roll cakes': 'swiss-roll-cakes',
  'other cakes': 'other-cakes',
  desserts: 'desserts',
  'ice cream': 'ice-cream',
  'frozen yogurt': 'frozen-yogurt',
  puddings: 'puddings',
  mousse: 'mousse',
  pies: 'pies',
  tarts: 'tarts',
  'crumbles and cobblers': 'crumbles-and-cobblers',
  'cream puffs': 'cream-puffs',
  'dessert bars': 'dessert-bars',
  'churros and funnel cakes': 'churros-and-funnel-cakes',
  meringues: 'meringues',
  pavlovas: 'pavlovas',
  caramels: 'caramels',
  brittles: 'brittles',
  marshmallows: 'marshmallows',
  candy: 'candy',
  'other desserts': 'other-desserts',
  chocolates: 'chocolates',
  'chocolate treats': 'chocolate-treats',
  truffles: 'truffles',
  fudge: 'fudge',
  brownies: 'brownies',
  'chocolate covered pretzels': 'chocolate-covered-pretzels',
  'hot cocoa': 'hot-cocoa',
  barks: 'barks',
  'baked goods': 'baked-goods',
  'cinnamon rolls': 'cinnamon-rolls',
  cheesecakes: 'cheesecakes',
  donuts: 'donuts',
  pastries: 'pastries',
  granola: 'granola',
  'energy bites': 'energy-bites',
  baklava: 'baklava',
  pretzels: 'pretzels',
  muffins: 'muffins',
  'other baked goods': 'other-baked-goods',
  breads: 'breads',
  bagels: 'bagels',
  'artisanal bread': 'artisanal-bread',
  biscuits: 'biscuits',
  flatbreads: 'flatbreads',
  tortillas: 'tortillas',
  'dinner rolls': 'dinner-rolls',
  buns: 'buns',
  'other breads': 'other-breads',
  'prepared foods': 'prepared-foods',
  charcuterie: 'charcuterie',
  'charcuterie board': 'charcuterie-board',
  'charcuterie cups': 'charcuterie-cups',
  'charcuterie box': 'charcuterie-box',
  'grazing table': 'grazing-table',
  'other charcuterie': 'other-charcuterie',
  'gift baskets': 'gift-baskets',
  'muffin basket': 'muffin-basket',
  'cookie basket': 'cookie-basket',
  'cupcake basket': 'cupcake-basket',
  'fruit basket': 'fruit-basket',
  'nut basket': 'nut-basket',
  'meat and cheese basket': 'meat-and-cheese-basket',
  'other gift baskets': 'other-gift-baskets',
  'meal prep': 'meal-prep',
  'prepared meals - individual': 'prepared-meals-individual',
  'prepared meals - subscription': 'prepared-meals-subscription',
  'prepared lunch boxes': 'prepared-lunch-boxes',
  'meal kits': 'meal-kits',
  'other meal prep': 'other-meal-prep',
  pasta: 'pasta',
  'pasta - fresh': 'pasta-fresh',
  'pasta - dried': 'pasta-dried',
  'orzo and couscous': 'orzo-and-couscous',
  'other pasta': 'other-pasta',
  'events and services': 'events-and-services',
  classes: 'classes',
  'baking class - general': 'baking-class-general',
  'cookie decorating class': 'cookie-decorating-class',
  'macaron class': 'macaron-class',
  'cake decorating class': 'cake-decorating-class',
  'cooking class - general': 'cooking-class-general',
  'charcuterie class': 'charcuterie-class',
  'other classes': 'other-classes',
  'catering and private chef': 'catering-and-private-chef',
  'wedding catering inquiry': 'wedding-catering-inquiry',
  'business event catering inquiry': 'business-event-catering-inquiry',
  'special event catering inquiry': 'special-event-catering-inquiry',
  'private chef inquiry': 'private-chef-inquiry',
  'custom meal plan inquiry': 'custom-meal-plan-inquiry',
  beverages: 'beverages',
  coffee: 'coffee',
  'coffee beans': 'coffee-beans',
  'coffee cold brew': 'coffee-cold-brew',
  tea: 'tea',
  'loose leaf tea': 'loose-leaf-tea',
  'tea bags': 'tea-bags',
  juices: 'juices',
  kombucha: 'kombucha',
  'cocktail mixers': 'cocktail-mixers',
  'other beverages': 'other-beverages',
  grocery: 'grocery',
  'condiments and sauces': 'condiments-and-sauces',
  'bbq sauce': 'bbq-sauce',
  'hot sauce': 'hot-sauce',
  ketchups: 'ketchups',
  mustards: 'mustards',
  'salad dressings': 'salad-dressings',
  'caramel sauce': 'caramel-sauce',
  'chocolate sauce': 'chocolate-sauce',
  'other condiments': 'other-condiments',
  'spreads and dips': 'spreads-and-dips',
  hummus: 'hummus',
  'artisanal spreads and dips': 'artisanal-spreads-and-dips',
  pantry: 'pantry',
  honey: 'honey',
  'lentils and legumes': 'lentils-and-legumes',
  'grains - general': 'grains-general',
  'grains - ancient grains': 'grains-ancient-grains',
  flour: 'flour',
  jam: 'jam',
  jelly: 'jelly',
  pickles: 'pickles',
  snacks: 'snacks',
  popcorn: 'popcorn',
  crackers: 'crackers',
  jerky: 'jerky',
  herbs: 'herbs',
  spices: 'spices',
  seasoning: 'seasoning',
  'soup mix': 'soup-mix',
  'baking mix': 'baking-mix',
  'cake mix': 'cake-mix',
  'canned goods': 'canned-goods',
  'other pantry': 'other-pantry',
  dairy: 'dairy',
  butter: 'butter',
  yogurt: 'yogurt',
  'cream cheese': 'cream-cheese',
  'soft cheeses': 'soft-cheeses',
  'hard cheeses': 'hard-cheeses',
  'other dairy': 'other-dairy',
  'other grocery': 'other-grocery',
  'sourdough starter': 'sourdough-starter',
  marinades: 'marinades',
  // these are legacy category names and used as redirects
  'custom sugar cookies - iced decoration': 'custom-decorated-cookies',
  'custom sugar cookies - printed decoration': 'custom-printed-cookies',
  'themed cookie set presales': 'cookie-treats',
  'drop cookies': 'cookie-treats',
  'no bake cookies': 'cookie-treats',
  'custom cake - wedding': 'wedding-cake',
  'custom cake - birthday': 'birthday-cake',
  'custom cake - other': 'custom-cake',
  'single-tier cakes': 'small-cake',
  'tiered cakes': 'large-cake',
  'cake tasting': 'wedding-cake-tasting-box',
  bars: 'dessert-bars',
  'candy - general': 'candy',
  'chocolate bars': 'chocolate-treats',
  'truffles - bonbon': 'truffles',
  'truffles - ganache': 'truffles',
  'other chocolates': 'chocolate-treats',
  'pastries - general': 'pastries',
  'pastries - croissants': 'pastries',
  'pastries - handheld': 'pastries',
  'granola products': 'granola',
  danishes: 'pastries',
  'artisanal breads - white': 'artisanal-bread',
  'artisanal breads - wheat': 'artisanal-bread',
  'artisanal breads - sourdough': 'artisanal-bread',
  'brioche and challah': 'artisanal-bread',
  'sandwich loaves': 'artisanal-bread',
  'quick breads': 'artisanal-bread',
  cornbread: 'artisanal-bread',
  'lunch boxes': 'prepared-lunch-boxes',
  'other event catering inquiry': 'special-event-catering-inquiry',
  'other spreads + dips': 'artisanal-spreads-and-dips',
  'preserves - jam': 'jam',
  'preserves - jelly': 'jelly',
  'preserves - pickles': 'pickles',
  'snacks - general': 'snacks',
  'snacks - popcorn': 'popcorn',
  'snacks - crackers': 'crackers',
  'snacks - jerkies': 'jerky',
  'seasonings - herbs': 'herbs',
  'seasonings - spices': 'spices',
  'seasonings - blends': 'seasoning',
  'mixes - soups': 'soup-mix',
  'mixes - brownies + bars': 'baking-mix',
  'mixes - cakes': 'cake-mix',
  'crumbles + cobblers': 'crumbles-and-cobblers',
  'churros + funnel cakes': 'churros-and-funnel-cakes',
  'meat + cheese basket': 'meat-and-cheese-basket',
  'orzo + couscous': 'orzo-and-couscous',
  'artisanal spreads + dips': 'artisanal-spreads-and-dips',
  'lentils + legumes': 'lentils-and-legumes',
  'brioche + challah': 'artisanal-bread',
  'catering & private chef': 'catering-and-private-chef',
  'condiments & sauces': 'condiments-and-sauces',
  'spreads & dips': 'spreads-and-dips',
  'custom cake other': 'custom-cake',
  'custom sugar cookies iced decoration': 'custom-decorated-cookies',
  'artisanal breads sourdough': 'artisanal-bread',
  'custom cake birthday': 'birthday-cake',
  'custom cake wedding': 'wedding-cake',
  'custom sugar cookies printed decoration': 'custom-printed-cookies',
  'seasonings spices': 'seasoning',
};
