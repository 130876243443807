import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { ButtonBase, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ProductPageContext } from '@castiron/domain';
import { useAppDispatch } from '../../hooks';
import { openModal } from '../../store/reducers/modalConductor';

type Props = {
  categoryName: string;
  context?: ProductPageContext;
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  buttonBase: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingRight: 8,
    '&:hover': {
      backgroundColor: `${theme.branding.gray[600]}4D`,
    },
  },
  delete: {
    color: theme.palette.error.main,
  },
  menuLabel: {
    marginLeft: 8,
    fontWeight: 600,
    fontSize: 14,
  },
}));

const OrganizeCategoriesActionsMenu: React.FC<Props> = (props: Props) => {
  const { onClose, categoryName, context } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const renameCategory = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    dispatch(
      openModal({
        modalType: 'RENAME_CATEGORY_MODAL',
        modalProps: {
          show: true,
          categoryName: categoryName,
        },
      }),
    );
    onClose(event);
  }, []);

  const deleteCategory = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    dispatch(
      openModal({
        modalType: 'DELETE_CATEGORY_MODAL',
        modalProps: {
          show: true,
          categoryName: categoryName,
        },
      }),
    );
    onClose(event);
  }, []);

  return (
    <>
      <ButtonBase
        className={classes.buttonBase}
        onClick={() => history.push(`/${context || 'products'}/organize/${categoryName}`)}
      >
        <Typography className={classes.menuLabel}>Manage Products</Typography>
      </ButtonBase>
      {categoryName !== 'Other' && (
        <ButtonBase className={classes.buttonBase} onClick={renameCategory}>
          <Typography className={classes.menuLabel}>Rename</Typography>
        </ButtonBase>
      )}
      {categoryName !== 'Other' && (
        <ButtonBase className={classes.buttonBase} onClick={deleteCategory}>
          <Typography className={`${classes.menuLabel} ${classes.delete}`}>Delete</Typography>
        </ButtonBase>
      )}
    </>
  );
};

export default OrganizeCategoriesActionsMenu;
