import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, GridSize, Theme, SvgIcon, useTheme, useMediaQuery } from "@material-ui/core";
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Remove from '@material-ui/icons/Remove';
import Tooltip from "../Tooltip";


export interface Stats {
  number: number;
  change: number;
}

interface Props {
  title: string;
  helpText: string;
  stats: Stats;
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderRadius: '4px',
    border: `solid ${theme.branding.gray[200]} 1px`,
    padding: '8px',
    fontSize: '14px'
  },
  total: {
    fontWeight: 'bold',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'end'
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'start'
    },
  },
  title: {

  },
  change: {
    fontSize: '12px',
    margin: 'auto'
  },
  noChangeIcon: {
    color: theme.branding.gray[600],
    width: '14px',
    height: '14px',
    marginBottom: '2px'
  },
  improveIcon: {
    color: theme.branding.green.primary,
    width: '14px',
    height: '14px',
    marginBottom: '2px',
    backgroundColor: '#E0F7F0',
    borderRadius: '4px',
    padding: '1px'
  },
  declineIcon: {
    color: theme.branding.error,
    width: '14px',
    height: '14px',
    marginBottom: '2px',
    backgroundColor: '#FFECE8',
    borderRadius: '4px',
    padding: '1px'
  },
  tooltipIcon: {
    width: '14px',
    height: '14px',
    color: theme.branding.gray[600]
  },
  tooltip: {
    width: '14px',
    height: '14px',
    padding: 0,
    marginBottom: '2px'
  }
}));

const StatsBox: React.FC<Props> = (props: Props) => {
  const { title, helpText, stats, xs, sm, md } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const showText = !useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const improveIcon = (<SvgIcon className={classes.improveIcon}>
    <ArrowUpward />
  </SvgIcon>);

  const declineIcon = (<SvgIcon className={classes.declineIcon}>
    <ArrowDownward />
  </SvgIcon>);

  const change = stats?.change === 0 ? '+0' : stats?.change > 0 ? `+${stats?.change}` : `${stats?.change}`;

  const icon = (stats?.change === 0) ? <></> : (stats?.change > 0) ? improveIcon : declineIcon;

  return <Grid item direction="row" xs={xs} sm={sm} md={md}>
    <Grid container direction="row" className={classes.container} spacing={1}>
      <Grid item xs={6} sm={2} md={2} lg={2} className={classes.total}>
        {stats?.number}
      </Grid>
      {(helpText && !isDesktop) && (<Grid xs={6} item><Tooltip iconClass={classes.tooltipIcon}
        tooltipClass={classes.tooltip}
        title={helpText} /></Grid>)}
      <Grid item xs={12} sm={6} md={7} lg={5} className={classes.title}>
        {title}
        {(helpText && isDesktop) && (<Tooltip iconClass={classes.tooltipIcon}
          tooltipClass={classes.tooltip}
          title={helpText} />)}
      </Grid>
      {/* <Grid item xs={12} sm={4} md={3} lg={5} className={classes.change}>
        {icon} {change} {showText && <>last 7 days</>}
      </Grid> */}
    </Grid>
  </Grid>;
}

export default StatsBox;
