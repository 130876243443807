import React from 'react';
import { Checkbox, CheckboxProps } from '@material-ui/core';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import InputWrapper, { BaseInputProps } from '../InputWrapper';

interface Props extends BaseInputProps, CheckboxProps {
  className?: string;
  handleChange?: (e?: any) => void;
  name: string;
  variant?: 'label-left' | 'label-right';
}

const CheckboxInput: React.FC<Props> = (props: Props) => {
  const { name, variant = 'label-left', className, handleChange, disabled } = props;

  const { values, setFieldValue } = useFormikContext();

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange && handleChange(event);
    setFieldValue(name, event.target.checked);
  };

  const handleLabelToggle = () => {
    handleChange && handleChange();
    setFieldValue(name, !_.get(values, name));
  };

  return (
    <InputWrapper {...props} variant={variant} onLabelClick={handleLabelToggle}>
      <Checkbox
        className={className}
        checked={!!_.get(values, name)}
        color="primary"
        onChange={handleToggle}
        disabled={disabled}
      />
    </InputWrapper>
  );
};

export default CheckboxInput;
