import React from 'react';
import { useAppDispatch } from '../../hooks';
import { openModal } from '../../store/reducers/modalConductor';
import { Button } from '@castiron/components';
import { ButtonProps } from '@castiron/components';
import { ProductPageContext } from '@castiron/domain';

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type AddProductButtonProps = Override<
  ButtonProps,
  {
    context?: ProductPageContext;
  }
>;

const AddProductButton: React.FC<AddProductButtonProps> = (props: AddProductButtonProps) => {
  const { context, ...restOfProps } = props;
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(
      openModal({
        modalType: 'ADD_PRODUCT_MODAL',
        modalProps: {
          show: true,
          context,
        },
      }),
    );
  };

  return (
    <Button onClick={handleClick} {...restOfProps}>
      New {context === 'order-forms' ? 'Order Form' : context === 'products' ? ' Product' : 'Event'}
    </Button>
  );
};

export default AddProductButton;
