import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';

interface Props {
  action: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '48px',
    width: '48px',
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: '12px',
    '& :hover': {
      cursor: 'pointer',
    },
  },
  icon: {
    height: '24px',
    width: '24px',
    position: 'relative',
    top: '12px',
    left: '12px',
  },
}));

const BackButton: React.FC<Props> = (props: Props) => {
  const { action } = props;
  const classes = useStyles();

  return (
    <Box className={classes.container} onClick={action}>
      <ArrowBack className={classes.icon} />
    </Box>
  );
};

export default BackButton;