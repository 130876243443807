import React, { useEffect, useState, ReactNode } from 'react';
import { Box, Grid, IconButton, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomerDetailsBox from '../CustomerDetailsBox';
import { useAppDispatch } from '../../hooks';
import { useHistory, useLocation } from 'react-router';
import PencilIcon from '../../assets/img/pencil-icon.png';
import { openModal } from '../../store/reducers/modalConductor';
import { Customer } from '@castiron/domain';
import Close from '@material-ui/icons/Close';
import { Field, useFormikContext } from 'formik';
import { Typography, Checkbox, Input } from '@castiron/components';
import Spinner from '../Spinner';

type Props = {
  customer: Customer;
  setCustomer?: (customer: Customer) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    minWidth: 80,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    padding: '16px 80px',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 20px',
    },
  },
  checkboxesContainer: {
    marginTop: 22,
    display: 'flex',
    flexDirection: 'column',
  },
  closeButton: {
    position: 'absolute',
    right: 3,
    top: 3,
    zIndex: 1,
  },
  container: {
    border: '1px solid #7777773D',
    borderRadius: 8,
    padding: 28,
  },
  deleteIcon: {
    cursor: 'pointer',
    color: theme.palette.error.main,
    position: 'absolute',
    top: 10,
    right: 50,
    zIndex: 1,
  },
  editImg: {
    width: 33,
    height: 33,
  },
  emailInputContainer: {
    flexGrow: 0.5,
  },
  formInner: {
    padding: '0px 16px'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  icon: {
    height: 36,
    width: 'auto',
  },
  itemBox: {
    borderRadius: 8,
    border: `1px solid ${theme.branding.gray[400]}`,
    marginBottom: 20,
    paddingBottom: 32,
    width: '100%',

    '& >form': {
      paddingLeft: 16,
      paddingRight: 16,
    }
  },
  itemHeader: {
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
    padding: '0px 16px',
  },
  menuIcon: {
    cursor: 'pointer',
    color: theme.palette.error.main,
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 1,
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    marginTop: 18,
    marginBottom: 13,
  },
}));

const OrderCustomer: React.FC<Props> = (props: Props) => {
  const { customer, setCustomer } = props;
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const startingMode = params.get('mode');
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { isSubmitting, setFieldValue, values, errors, touched }: any = useFormikContext();

  const [editMode, setEditMode] = useState(startingMode === 'edit');

  useEffect(() => {
    if (startingMode === 'message') {
      dispatch(
        openModal({
          modalType: 'CONTACT_MODAL',
          modalProps: {
            show: true,
            email: customer?.email,
          },
        }),
      );
    }
  }, [location, history, dispatch]);

  return (
    <Grid style={{ position: 'relative' }} item xs={12}>
      <Spinner show={isSubmitting} size={'fullscreen'} />
      <IconButton
        onClick={(): void => setEditMode(!editMode)}
        className={classes.closeButton}
      >
        {editMode ? <Close /> : <img className={classes.editImg} src={PencilIcon} />}
      </IconButton>
      {editMode ? (
        <Box className={classes.itemBox}>
          <Grid container className={classes.itemHeader} alignItems="center">
            <Typography className={classes.title}>Contact Details</Typography>
          </Grid>
          <Box className={classes.formInner}>
            <Box>
              <Box className={classes.emailInputContainer}>
                <Input
                  error={touched.email && errors.email}
                  fullWidth
                  label="Email"
                  name='customer.email'
                  placeholder="you@example.com"
                  required
                  value={values.customer.email}
                />
              </Box>
              <Input
                type="tel"
                error={touched.mobileNumber && errors.mobileNumber}
                fullWidth
                name='customer.mobileNumber'
                label="Mobile phone"
                placeholder="(555) 555-5555"
                phoneMask={true}
                value={values.customer.mobileNumber}
              />
            </Box>
            <Box>
              <Input fullWidth name='customer.firstName' label="First name" placeholder="First name" value={values.customer.firstName} />
              <Input fullWidth name='customer.lastName' label="Last name" placeholder="Last name" value={values.customer.lastName} />
            </Box>
            <Input
              rows={4}
              fullWidth
              multiline
              name='customer.notes'
              label="Contact notes"
              placeholder="Notes on preferences, allergies, dropoff information, etc"
              value={values.customer.notes}
            />
            <Box className={classes.checkboxesContainer}>
              <Field
                as={Checkbox}
                onChange={(e): void => setFieldValue('subscribed', e.target.checked)}
                name='customer.subscribed'
                checked={values.customer.subscribed}
                label="Yes, this contact has opted in to my marketing list."
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <CustomerDetailsBox {...{ customer }} />
      )}
    </Grid>
  );
};

export default OrderCustomer;
