import React, {ReactNode} from 'react';
import Label from '../Label';
import { DatePicker, DatePickerFormik } from './DatePicker';

type Props = {
  name: string;
  label?: string;
  type: string;
  defaultValue?: string;
  disableFormik?: boolean;
  onChange?: any;
  value?: any;
  center?: boolean;
  children?: ReactNode;
};

const Date = (props: Props): React.ReactElement => {
  return (
    <div className="form-group" style={props.center ? { textAlign: 'center' } : {}}>
      <Label name={props.name} label={props.label || ''} />
      <div
        className="input-group input-wrapper"
        style={props.center ? { display: 'flex', justifyContent: 'center' } : {}}
      >
        {props.disableFormik ? (
          <DatePicker
            defaultValue={props.defaultValue}
            onChange={props.onChange}
            value={props.value}
            name={props.name}
          />
        ) : (
          <DatePickerFormik
            defaultValue={props.defaultValue}
            onChange={props.onChange}
            value={props.value}
            name={props.name}
          />
        )}
      </div>
    </div>
  );
};

export default Date;
