import { CustomProduct, Customer, Shop, calculateTotals } from '@castiron/domain';
import { removeEmpty } from '@castiron/utils';
import moment from 'moment';
import { assetRepository, shopRepository, transactionRepository } from '../domain';
import { getService } from '../firebase';

const createCustomOrderService = getService('orders', 'createcustomorderv2');
const sendQuoteService = getService('orders', 'sendquoterequestv2');

const textVariationCopy =
  'This is an example request to help simulate the Custom Order Process on Castiron. Send yourself a quote to test what your customer will see.';

const createTestQuote = async (product: CustomProduct, shop: Shop, customer: Customer) => {
  const body = {
    id: shop.id,
    status: 'open',
    origination: 'test',
  };
  try {
    const createCustomOrderResponse = await createCustomOrderService(body);
    console.debug('Create custom product order response: ', createCustomOrderResponse);
    const { id, order } = createCustomOrderResponse;

    const testQuoteAsset = await assetRepository.findByProductType('testQuote');

    const updatedOrder = {
      ...order,
      items: [
        {
          id: product?.id,
          title: product?.title,
          description: product?.description,
          price: product?.startingPrice || 5000,
          quantity: 1,
          selections: product?.variations?.map(variation => ({
            inputFieldId: variation.id,
            inputFieldName: variation.name,
            selectedAssets:
              variation.type === 'photo' && testQuoteAsset
                ? [
                    {
                      id: testQuoteAsset?.shopId,
                      title: testQuoteAsset?.metadata?.productType,
                      asset: removeEmpty(testQuoteAsset),
                    },
                  ]
                : [],
            selectedValues:
              variation.type === 'photo'
                ? []
                : [
                    {
                      cost: 0,
                      id: variation.values[0].id,
                      name: variation.type === 'text' ? textVariationCopy : variation.values[0].name,
                      value: '',
                    },
                  ],
          })),
          subLineItems: [],
          subtotal: product?.startingPrice || 5000,
          subLineItemTotal: 0,
          total: product?.startingPrice || 5000,
          type: 'custom',
          policies: (product as CustomProduct)?.policies,
        },
      ],
      fulfillmentOption: {
        ...order?.fulfillmentOption,
        type: 'pickup',
        displayName: 'Pickup',
        description: 'Pickup',
        address: shop?.physicalAddress,
        date: moment()
          .add(7, 'days')
          .unix(),
        useBusinessAddress: true,
      },
      requestedFulfillment: {
        type: 'pickup',
        date: moment()
          .add(7, 'days')
          .unix(),
        address: shop?.physicalAddress,
      },
      paymentSentDuePeriod: '24hours',
    };

    await transactionRepository.updateProps(
      id,
      removeEmpty({
        customer: customer?.id,
        customerObj: customer,
        order: updatedOrder,
        shippingInfo: {
          address: shop?.physicalAddress,
        },
        totals: calculateTotals({
          order: updatedOrder,
          paymentSettings: shop.paymentSettings,
        }),
      }),
    );

    const { success } = await sendQuoteService({ transactionId: id, sendCopyToArtisan: false, isTest: true });

    if (success) {
      shopRepository.updateProps(shop.id, {
        'config.testQuoteSent': true,
      });
    }
    return { success };
  } catch (e) {
    console.error('Error creating custom product order: ', e);
    return { success: false };
  }
};

export default createTestQuote;
