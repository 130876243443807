import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { useAppSelector, useAppDispatch } from '../../../hooks';
import * as yup from 'yup';
import Container from '../Container';
import { Formik, FormikProps } from 'formik';
import { getProductsAction } from '../../../store/reducers/products';
import { NewsletterMarketingSend, Product } from '@castiron/domain/src';
import DesktopForm from './DesktopForm';
import MobileForm from './MobileForm';
import { marketingSendRepository } from '@castiron/client-admin/src/domain';
import Spinner from '../../Spinner';
import { MarketingSendFrequency } from '@castiron/domain/src/marketing';
import { useHistory } from 'react-router-dom';
import { removeEmpty, useTracking } from '@castiron/utils';
import { BaseProduct, ChecklistValues } from '@castiron/domain';
import { updateChecklistAction } from '@castiron/client-admin/src/store/reducers/shops';
import UnsavedChangesPrompt from '../../UnsavedChangesPrompt.tsx';
import { LayoutPageProps } from '../../Layout';
import { productHasImages } from '../../../lib/imageUtils';

export type FormValues = {
  status: string;
  frequency: MarketingSendFrequency;
  artisanMessage: string;
  includedProductIds: string[];
};

export const defaultMsg =
  'Your support means the world to me. I hope you enjoy my products as much as I enjoy making them for you. Let me know if you have any questions!';

const EditUpdateEmail: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { trackEvent } = useTracking();

  const { shop, products, sendGridConfig } = useAppSelector(state => ({
    shop: state.shops.shop,
    products: state.products.products,
    sendGridConfig: state.shops?.account?.sendGridConfig,
  }));

  const [category, setCategory] = useState<string>('all');
  const [productsWithImages, setProductsWithImages] = useState<BaseProduct[]>(
    products.filter((p: Product) => productHasImages(p)),
  );
  const [filteredProducts, setFilteredProducts] = useState<BaseProduct[]>(
    products.filter((p: Product) => productHasImages(p)),
  );
  const [newsletterMarketingSend, setNewsletterMarketingSend] = useState<NewsletterMarketingSend | null>(null);
  const [getMarketingSendLoading, setGetMarketingSendLoading] = useState<boolean>(true);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  useEffect(() => {
    setPageTitle('Edit Email');
    setBackLocation('/marketing/automations');
    setHeaderCTAs([]);
    setFooterCTAs([]);

    return () => {
      setPageTitle('');
      setBackLocation(false);
    };
  }, []);

  useEffect(() => {
    setProductsWithImages(products.filter((p: Product) => productHasImages(p)));
  }, [products]);

  useEffect(() => {
    const getProducts = async () => {
      dispatch(getProductsAction(shop.id));
    };

    const getNewsletterMarketingSend = async () => {
      setGetMarketingSendLoading(true);
      const marketingSendResponse = await marketingSendRepository.listByType(shop.id, 'newsletter');
      (await !!marketingSendResponse.length) &&
        setNewsletterMarketingSend(marketingSendResponse[0] as NewsletterMarketingSend);
      setGetMarketingSendLoading(false);
    };

    if (shop?.id) {
      getProducts();
      getNewsletterMarketingSend();
    }
  }, [shop]);

  useEffect(() => {
    category === 'all'
      ? setFilteredProducts(productsWithImages)
      : setFilteredProducts(productsWithImages.filter((product: Product) => product.category.name === category));
  }, [category, products]);

  const onSubmit = async (values: FormValues) => {
    setSubmitLoading(true);
    if (newsletterMarketingSend && newsletterMarketingSend.id) {
      const newMarketingSend = removeEmpty({
        id: newsletterMarketingSend.id,
        shopId: newsletterMarketingSend.shopId,
        type: newsletterMarketingSend.type,
        templateId: newsletterMarketingSend.templateId || 'bfihusWJ8zjV5rxbcQQB',
        lastSentDate: values.status === 'inactive' ? null : lastSendDate,
        ...(newsletterMarketingSend.contactListId && { contactListId: newsletterMarketingSend?.contactListId }),
        ...values,
      }) as NewsletterMarketingSend;
      await marketingSendRepository.update(newMarketingSend);
      trackEvent('Newsletter Created', { newsletterMarketingSend: newMarketingSend });
    } else {
      const newMarketingSend = removeEmpty({
        shopId: shop.id,
        type: 'newsletter',
        templateId: 'E0tFpPyxOU3Mq0kSPYYc',
        lastSentDate: values.status === 'inactive' ? null : lastSendDate,
        ...(sendGridConfig?.contactListId && { contactListId: sendGridConfig?.contactListId }),
        ...values,
      }) as NewsletterMarketingSend;
      await marketingSendRepository.create(newMarketingSend);
      if (!shop.checklistCompletions?.includes(ChecklistValues.EmailMarketing)) {
        dispatch(updateChecklistAction({ shop, items: [ChecklistValues.EmailMarketing] }));
      }
      trackEvent('Newsletter Updated', { newsletterMarketingSend: newMarketingSend });
    }
    setSubmitLoading(false);
    history.push('/marketing/automations');
  };

  const formSchema = yup.object().shape({
    status: yup.string().required('Active value required'),
    frequency: yup.string().required('Frequency is required'),
    artisanMessage: yup.string().required('Personal sign off is required'),
    includedProductIds: yup
      .array()
      .of(yup.string())
      .min(2, 'Must select at least two products')
      .required('Products are required'),
  });

  const lastSendDate = useMemo(() => {
    return newsletterMarketingSend?.lastSentDate;
  }, [newsletterMarketingSend]);

  return (
    <Container>
      {getMarketingSendLoading || submitLoading ? (
        <Grid justify="center" container>
          <Spinner show={true} size={'relative'} />
        </Grid>
      ) : (
        <Formik
          validateOnMount
          onSubmit={onSubmit}
          validationSchema={formSchema}
          initialValues={{
            status: newsletterMarketingSend?.status || 'inactive',
            frequency: newsletterMarketingSend?.frequency || 'weekly',
            artisanMessage: newsletterMarketingSend?.artisanMessage || defaultMsg,
            includedProductIds:
              newsletterMarketingSend?.includedProductIds?.filter(includedProductId =>
                productsWithImages?.map(p => p.id)?.includes(includedProductId),
              ) || productsWithImages?.map(p => p.id),
          }}
        >
          {(formikProps: FormikProps<FormValues>): ReactElement => {
            return (
              <>
                <UnsavedChangesPrompt when={formikProps.dirty} />
                {isMobile ? (
                  <MobileForm
                    {...{
                      formikProps,
                      categories: shop.categories,
                      products: productsWithImages,
                      filteredProducts,
                      setCategory,
                      category,
                      lastSendDate,
                      marketingSendId: newsletterMarketingSend?.id,
                    }}
                  />
                ) : (
                  <DesktopForm
                    {...{
                      formikProps,
                      categories: shop.categories,
                      products: productsWithImages,
                      filteredProducts,
                      setCategory,
                      category,
                      lastSendDate,
                      marketingSendId: newsletterMarketingSend?.id,
                    }}
                  />
                )}
              </>
            );
          }}
        </Formik>
      )}
    </Container>
  );
};

export default EditUpdateEmail;
