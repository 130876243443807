import React from "react";
import { Theme, makeStyles } from '@material-ui/core/styles';
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useFormikContext } from "formik";
import _ from 'lodash';
import InputWrapper, { BaseInputProps } from "../InputWrapper";

/* expects value in name to always be a boolean */
interface Props extends BaseInputProps {
  name: string;
  onDisplay?: string;
  offDisplay?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    border: 'none',
    flexBasis: '50%',
  },
  buttonGroup: {
    border: `1px solid ${theme.branding.gray[400]}`,
    padding: 4,
    borderRadius: 12,
    'border-top-left-radius': 12,
    'border-bottom-left-radius': 12,
    'border-top-right-radius': 12,
    'border-bottom-right-radius': 12,

    '& button.MuiToggleButton-root.Mui-selected': {
      //active button
      backgroundColor: theme.branding.blue.light,
      color: theme.branding.blue.dark,
      '&:hover': {
        backgroundColor: theme.branding.blue.light,
        color: theme.branding.blue.dark,
      },
  },
    '& button.MuiToggleButton-root': {
      //inactive button & button root
      backgroundColor: theme.branding.gray[100],
      color: theme.branding.gray[800],
      borderRadius: 12,
      'border-top-left-radius': 12,
      'border-bottom-left-radius': 12,
      'border-top-right-radius': 12,
      'border-bottom-right-radius': 12,
      padding: '12px 8px',

      '&:hover': {
        backgroundColor: theme.branding.gray[300],
        color: theme.branding.gray[800],
      },
    },
  },
}));

const ToggleInput: React.FC<Props> = (props: Props) => {
  const { name, onDisplay = 'Active', offDisplay = 'Inactive' } = props;
  const classes = useStyles();
  const { setFieldValue, values } = useFormikContext();

  const handleChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    setFieldValue(name, newValue);
  };

  return (
    <InputWrapper {...props}>
      <ToggleButtonGroup
        exclusive
        value={_.get(values, name)}
        onChange={handleChange}
        className={classes.buttonGroup}
      >
        <ToggleButton value={true} className={classes.button}>{onDisplay}</ToggleButton>
        <ToggleButton value={false} className={classes.button}>{offDisplay}</ToggleButton>
      </ToggleButtonGroup>
    </InputWrapper >
  );
};

export default ToggleInput;