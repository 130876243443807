import React from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';

interface Props {
  /* percentage is expected to be a number between 0 and 1 */
  percentage: number;
  fullwidth?: boolean;
  barHeight?: number;
  borderRadiusRight?: number;
  borderRadiusLeft?: number;
  customColor?: string;
}

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  fill: {
    height: '100%',
    backgroundColor: props => props.customColor || theme.branding.v2.blue[500],
    borderRadius: '100px',
  },
  /* we probably want some variant sizes here at some point
   * this is the only size I need right now, though
   */
  progressBar: {
    height: '12px',
    width: '112px',
    backgroundColor: theme.branding.v2.gray[50],
    borderRadius: '100px',
  },

  progressBarFullWidth: {
    height: '12px',
    width: '100%',
    backgroundColor: theme.branding.v2.gray[50],
    borderRadius: '100px',
  },
}));

const ProgressBar: React.FC<Props> = (props: Props) => {
  const { percentage, fullwidth, barHeight, borderRadiusLeft, borderRadiusRight, customColor } = props;
  const classes = useStyles(props);

  const styleOverrides = {
    ...(barHeight && { height: barHeight }),
    ...(borderRadiusRight && { borderTopRightRadius: borderRadiusRight }),
    ...(borderRadiusRight && { borderBottomRightRadius: borderRadiusRight }),
    ...(borderRadiusLeft && { borderTopLeftRadius: borderRadiusLeft }),
    ...(borderRadiusLeft && { borderBottomLeftRadius: borderRadiusLeft }),
  };

  return (
    <Box className={fullwidth ? classes.progressBarFullWidth : classes.progressBar} style={styleOverrides}>
      <Box className={classes.fill} style={{ width: `${percentage * 100}%`, ...styleOverrides }} />
    </Box>
  );
};

export default ProgressBar;
