import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import InputWrapper, { BaseInputProps } from '../InputWrapper';

interface Props extends BaseInputProps {
  name: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    margin: 0,
    '& > input::-webkit-outer-spin-button': {
      appearance: 'textfield',
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& > input::-webkit-inner-spin-button': {
      appearance: 'textfield',
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& > input[type="number"]': {
      appearance: 'textfield',
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      margin: 0,

      '&:hover::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
        appearance: 'none',
        margin: 0,
      },
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      margin: 0,
    },
    '&:hover::-webkitinner-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      margin: 0,
    },
    '&:hover::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      margin: 0,
    },
  },
}));

const NumberInput: React.FC<Props> = (props: Props) => {
  const { name } = props;
  const classes = useStyles();

  const { values, handleChange } = useFormikContext();

  return (
    <InputWrapper {...props}>
      <TextField
        name={name}
        value={_.get(values, name)}
        type="number"
        variant='outlined'
        onChange={handleChange}
        className={classes.text}
      />
    </InputWrapper>
  );
};

export default NumberInput;