import React, {ReactNode} from 'react';
import Label from '../Label';
import DateTimePicker from './DateTimePicker';

type Props = {
  name: string;
  label?: string;
  type: string;
  defaultValue?: string;
  children?: ReactNode;
};

const Date = (props: Props): React.ReactElement => {
  return (
    <div className="form-group">
      <Label name={props.name} label={props.label || ''} />
      <div className="input-group input-wrapper">
        <DateTimePicker defaultValue={props.defaultValue} name={props.name} />
      </div>
    </div>
  );
};

export default Date;
