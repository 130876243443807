import { DialogContent, Grid, IconButton, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import * as yup from 'yup';
import { AvailabilityStatusLabel, Banner, Button, ButtonV2, DateInput, Select, Typography } from '@castiron/components';
import { defaultTimeZone, useTracking } from '@castiron/utils';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { closeModal, openModal } from '../../store/reducers/modalConductor';
import { Formik, FormikProps } from 'formik';
import { AvailabilitySubtype } from '@castiron/domain';
import AdminForm from '../AdminForm';
import moment, { Moment } from 'moment';
import ModalWrapper from '../RootModal/ModalWrapper';
import Close from '@material-ui/icons/Close';
import { shopRepository } from '../../domain';
import { UpdatedTimeframe } from './index';
import ModalHeader from '../RootModal/ModalHeader';
import ModalActions from '../RootModal/ModalActions';
import ReferralBlock from '../ReferralPrompts/ReferralBlock';

export type Props = {
  show?: boolean;
  onSubmit?: (type: AvailabilitySubtype, updatedTimeframes: UpdatedTimeframe[]) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    cursor: 'pointer',
    margin: '32px 0px',
    width: 'fit-content',
    '& span': {
      color: theme.branding.v2.blue[500],
    },
  },
  button: {
    marginLeft: 8,
    padding: 16,
  },
  closeIcon: {
    cursor: 'pointer',
    fontSize: 24,
    margin: '48px 0px 0px 16px',
  },
  content: {
    margin: '32px 40px',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      margin: '32px 16px',
    },
  },
  paperClass: {
    borderRadius: 32,
    width: 800,
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      height: 632,
    },
  },
  scroll: {
    padding: 0,
    overflowY: 'scroll',
    'scrollbar-width': 'thin',
    'scrollbar-color': theme.branding.v2.gray[400],
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.branding.v2.gray[600],
      '-webkit-border-radius': '12px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.branding.v2.gray[0],
    },
    '&::-webkit-scrollbar-track-piece:start': {
      background: 'transparent',
    },
    '&-webkit-scrollbar-track-piece:end': {
      background: 'transparent',
    },
  },
  select: {
    margin: '4px 0',
    width: '100%',
  },
  to: {
    padding: '48px 16px 0 16px',
    [theme.breakpoints.down('sm')]: {
      padding: '48px 4px 0 4px',
    },
  }
}));

const availabilitySchema = yup.object().shape({
  status: yup.mixed<AvailabilitySubtype>().oneOf(['available', 'limited', 'unavailable']),
  timeframes: yup.array().of(
    yup.object().shape({
      startDate: yup.number().test({
        message: 'Please select a date after your start date.',
        test: (value, context) => value <= context.parent.endDate,
      }),
      endDate: yup.number().test({
        message: 'Please select a date after your start date.',
        test: (value, context) => value >= context.parent.startDate,
      }),
    }),
  ),
});

const AvailabilityModal: React.FC<Props> = (props: Props) => {
  const { show, onSubmit } = props;

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { trackEvent } = useTracking();
  const formRef = useRef<FormikProps<any>>();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { shop, tier } = useAppSelector(state => ({
    shop: state.shops.shop,
    tier: state.shops.account.tier,
  }));

  const timeZone = shop?.config?.timeZone || defaultTimeZone;

  const initialValues = {
    status: 'unavailable',
    timeframes: [{
      startDate: null,
      endDate: null,
    }],
  };

  const displayContent = {
    available: {
      banner:
        <Banner variant='info-white' noIcon={true}>
          <Typography variant='body2'>I currently have availability for your selected date but my calendar fills up fast! Once submitted, I’ll get back to you as soon as possible on your request.</Typography>
        </Banner>
      ,
      displayText: 'Available',
      helperText: <Typography variant='caption'>Customers <b>can</b> choose a date in this timeframe for their custom order request and will see this message:</Typography>,
      label: <AvailabilityStatusLabel status='available' />,
    },
    limited: {
      banner:
        <Banner variant='info-yellow' noIcon={true}>
          <Typography variant='body2'>I have limited availability for your selected date but my calendar fills up fast! Once submitted, I’ll get back to you as soon as possible on your request.</Typography>
        </Banner>
      ,
      displayText: 'Limited Availability',
      helperText: <Typography variant='caption'>Customers <b>can</b> choose a date in this timeframe for their custom order request and will see this message:</Typography>,
      label: <AvailabilityStatusLabel status='limited' />,
    },
    unavailable: {
      displayText: 'Not Available',
      helperText: <Typography variant='caption'>Customers <b>cannot</b> choose a date in this timeframe for their custom order request.</Typography>,
      label: <AvailabilityStatusLabel status='unavailable' />,
    },
  };

  const onStatusChange = async (e) => {
    formRef.current.setFieldValue('status', e.target.value);
  };

  const onStartDateChange = async (startDate: Moment, index: number) => {
    /* if it's the first time entering a value, set both start and end to the same date */
    if (formRef.current.values.timeframes[index].endDate === null && startDate.isValid()) {
      /* await here to ensure downstream validation happens with new values */
      await formRef.current.setFieldValue(`timeframes[${index}].endDate`, moment(startDate).startOf('day').unix());
    }
    await formRef.current.setFieldValue(`timeframes[${index}].startDate`, moment(startDate).startOf('day').unix());
    await formRef.current.setFieldTouched(`timeframes[${index}].startDate`, (startDate !== null && startDate?.isValid()));
  };

  const onEndDateChange = async (endDate: Moment, index: number) => {
    /* if it's the first time entering a value, set both start and end to the same date */
    if (formRef.current.values.timeframes[index].startDate === null && endDate.isValid()) {
      /* await here to ensure downstream validation happens with new values */
      await formRef.current.setFieldValue(`timeframes[${index}].startDate`, moment(endDate).startOf('day').unix());
    }
    await formRef.current.setFieldValue(`timeframes[${index}].endDate`, moment(endDate).startOf('day').unix());
    await formRef.current.setFieldTouched(`timeframes[${index}].endDate`, (endDate !== null && endDate?.isValid()));
  };

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const handleSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      const {
        status,
        timeframes,
      } = values;
      const updatedTimeframes: UpdatedTimeframe[] = [];
      timeframes.forEach(async timeframe => {
        const startDateMoment = moment.unix(timeframe.startDate).tz(timeZone, true);
        const updatedStartDate = startDateMoment.unix();
        const endDateMoment = moment.unix(timeframe.endDate).tz(timeZone, true);
        const updatedEndDate = endDateMoment.unix();

        updatedTimeframes.push({updatedStartDate, updatedEndDate});

        await shop.addCalendarAvailability(status, updatedStartDate, updatedEndDate);
      });

      /* sets availability on calendar */
      onSubmit && onSubmit(status, updatedTimeframes);
      
      /* we are saving dates, let's check and see if a timezone is set in the config */
      if (!shop.config?.timeZone) {
        /* if not, set one */
        await shopRepository.updateProps(shop.id, {
          config: {
            ...shop.config,
            timeZone: defaultTimeZone,
          },
        });
      }

      trackEvent('Calendar Availability Set', {
        status,
        updatedTimeframes,
        tier,
      });

      setIsSubmitting(false);

      dispatch(
        openModal({
          modalType: 'GENERAL_MODAL',
          modalProps: {
            content: (
              <Grid container direction="column" wrap="nowrap" justify="center">
                <Grid item>
                  <Typography variant="body1">{`Date${
                    updatedTimeframes.length === 1 &&
                    updatedTimeframes[0].updatedStartDate === updatedTimeframes[0].updatedEndDate
                      ? ''
                      : 's'
                  } updated to ${displayContent[status].displayText}.`}</Typography>
                </Grid>
                <Grid item style={{ marginTop: 16 }}>
                  <ReferralBlock location="availability-modal" />
                </Grid>
              </Grid>
            ),
            actions: [
              <ButtonV2 onClick={() => dispatch(closeModal())} variant="contained">
                Close
              </ButtonV2>,
            ],
            onClose: () => dispatch(closeModal()),
            show: true,
          },
        }),
      );
    } catch (err) {
      console.debug(err);
      setIsSubmitting(false);
    }
  };

  const addTimeframe = async () => {
    await formRef.current.setFieldValue('timeframes', [
      ...formRef.current.values.timeframes,
      { startDate: null, endDate: null },
    ]);
  };

  const deleteTimeframe = async (index) => {
    await formRef.current.setFieldTouched(`timeframes[${index}].startDate`, false);
    await formRef.current.setFieldTouched(`timeframes[${index}].endDate`, false);
    await formRef.current.setFieldValue('timeframes', formRef.current.values.timeframes.toSpliced(index, 1));
  };

  return (
    <Formik
      validationSchema={availabilitySchema}
      initialValues={initialValues}
      innerRef={formRef}
      onSubmit={handleSubmit}
    >
      {({ dirty, errors, isValid, touched, values }) => (
        <AdminForm style={{ width: '100%' }}>
          <ModalWrapper
            paperClass={!isMobile && classes.paperClass}
            fullScreen={isMobile}
            show={show}
            onClose={handleClose}
          >
            <ModalHeader title="Update Availability" handleClose={handleClose} />
            <DialogContent className={classes.scroll}>
              <Grid container item className={classes.content} wrap="nowrap" direction="column">
                <Grid item>
                  <Typography variant="subtitle2">Show Dates As:</Typography>
                </Grid>
                <Grid item>
                  <Select
                    options={[
                      { label: displayContent.available.label, value: 'available' },
                      { label: displayContent.limited.label, value: 'limited' },
                      { label: displayContent.unavailable.label, value: 'unavailable' },
                    ]}
                    value={values.status}
                    onChange={onStatusChange}
                    selectClass={classes.select}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="caption">{displayContent[values.status].helperText}</Typography>
                </Grid>
                {displayContent[values.status].banner && (
                  <Grid item style={{ marginTop: 8 }}>
                    {displayContent[values.status].banner}
                  </Grid>
                )}
                <Grid item style={{ marginTop: 32 }}>
                  <Typography variant="subtitle1">Timeframes</Typography>
                </Grid>
                {values.timeframes.map((timeframe, index) => (
                  <Grid
                    key={`timeframe-${index}`}
                    container
                    item
                    wrap="nowrap"
                    alignItems="flex-start"
                    style={{ marginTop: 16 }}
                  >
                    <DateInput
                      addErrorStyling={
                        touched.timeframes &&
                        errors.timeframes &&
                        ((touched.timeframes[index]?.startDate && errors.timeframes[index]?.startDate) ||
                          (touched.timeframes[index]?.endDate && errors.timeframes[index]?.endDate))
                      }
                      label="Start Date"
                      onChange={(startDate: Moment) => onStartDateChange(startDate, index)}
                      placeholder="Select Start Date"
                      selectedDate={timeframe.startDate ? moment.unix(timeframe.startDate) : null}
                      removeIcon={isMobile}
                      allowKeyboardInput={!isMobile}
                    />
                    <Typography className={classes.to} variant="body1">
                      to
                    </Typography>
                    <DateInput
                      error={
                        touched.timeframes &&
                        errors.timeframes &&
                        ((touched.timeframes[index]?.startDate && errors.timeframes[index]?.startDate) ||
                          (touched.timeframes[index]?.endDate && errors.timeframes[index]?.endDate))
                      }
                      label="End Date"
                      onChange={(endDate: Moment) => onEndDateChange(endDate, index)}
                      placeholder="Select End Date"
                      selectedDate={timeframe.endDate ? moment.unix(timeframe.endDate) : null}
                      removeIcon={isMobile}
                      allowKeyboardInput={!isMobile}
                    />
                    {values.timeframes.length > 1 && (
                      <Close className={classes.closeIcon} onClick={() => deleteTimeframe(index)} />
                    )}
                  </Grid>
                ))}
                <Grid
                  item
                  className={classes.addButton}
                  onClick={addTimeframe}
                >
                  <Typography variant="button">+ Add Another Timeframe</Typography>
                </Grid>
                <Grid item>
                  <Banner variant="info-blue">
                    <Typography variant="body2" style={{ color: theme.branding.blue.primary }}>
                      Please note: Availability only impacts custom orders.
                    </Typography>
                  </Banner>
                </Grid>
              </Grid>
            </DialogContent>
            <ModalActions>
              <Button className={classes.button} onClick={handleClose} variant="outlined">
                Cancel
              </Button>
              <Button
                className={classes.button}
                disabled={!dirty || !isValid || isSubmitting}
                onClick={() => handleSubmit(values)}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </ModalActions>
          </ModalWrapper>
        </AdminForm>
      )}
    </Formik>
  );
};

export default AvailabilityModal;