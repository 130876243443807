import {
  CheckboxInput,
  CollapsableCard,
  ProFeatureChip,
  TextAreaInput,
  TextInput,
  Typography,
  useFeatures,
} from '@castiron/components';
import { kebabCase, stripHtml, useTracking } from '@castiron/utils';
import { Grid } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useState } from 'react';
import { useFormikContext } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import Tooltip from '../../../Tooltip';
import { openModal } from '../../../../store/reducers/modalConductor';
import { BaseProduct } from '@castiron/domain';
import { getShopLink } from '../../../../lib/domainUtils';

type Props = {
  product?: BaseProduct;
};

const useStyles = makeStyles((theme: Theme) => ({
  checkbox: {
    padding: '0px 11px 0px 0px',
  },
  checkboxContainer: {
    marginTop: 4,
  },
  preview: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 12,
    padding: 16,
    wordBreak: 'break-all',
  },
}));

const SEO: React.FC<Props> = (props: Props) => {
  const { product } = props;
  const classes = useStyles();
  const { setFieldValue, values }: any = useFormikContext();
  const features = useFeatures();
  const { trackEvent } = useTracking();
  const dispatch = useAppDispatch();

  const isSeoEnabled = features.includes('admin.seo');
  const [expanded, setExpanded] = useState(isSeoEnabled);

  const { account, shop } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
  }));

  const shopLink = getShopLink(shop);

  const openUpgradeModal = async () => {
    trackEvent('Gated Feature Upgrade Clicked', { feature: 'seo' });
    dispatch(
      openModal({
        modalType: 'CHANGE_PLANS_MODAL',
        modalProps: {
          open: true,
        },
      }),
    );
  };

  const seoTitleContent = useCallback(
    () => (
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <Typography variant="subtitle1">SEO</Typography>
        </Grid>
        {!account.isCastironPlus() && (!isSeoEnabled || account.isInTrial()) && (
          <Grid item>
            <ProFeatureChip />
          </Grid>
        )}
      </Grid>
    ),
    [account, isSeoEnabled],
  );

  return (
    <CollapsableCard
      expanded={expanded}
      handleExpand={() => {
        if (isSeoEnabled) {
          setExpanded(!expanded);
        } else {
          openUpgradeModal();
        }
      }}
      noScroll
      title={
        isSeoEnabled ? (
          seoTitleContent()
        ) : (
          <Tooltip arrow title="Upgrade to Pro to unlock SEO and many other features.">
            {seoTitleContent()}
          </Tooltip>
        )
      }
    >
      <Grid container direction="column">
        <Typography variant="subtitle2" style={{ marginBottom: 4 }}>
          Google Preview
        </Typography>
        <Grid className={classes.preview}>
          <Typography variant="caption">
            {`${shopLink}/p/${kebabCase(values?.title)}${product ? `/${product?.id}` : ''}`}
          </Typography>
          <Typography variant="subtitle1" style={{ color: '#1717A4' }}>
            {values?.seo?.title}
          </Typography>
          <Typography variant="caption">{values?.seo?.description}</Typography>
        </Grid>
      </Grid>
      <Grid container style={{ margin: '16px 0px' }} direction="column">
        <Grid item>
          <TextInput
            disabled={values.seo.useProductTitle}
            label={
              <Grid container alignItems="center">
                <Typography variant="subtitle2">SEO Title</Typography>
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <span>
                      The header and most prominent text. We recommend the following format:{' '}
                      <b>Your Product | Your Business Name</b> (i.e. Chocolate Chip Cookies | The Cookie Project)
                    </span>
                  }
                />
              </Grid>
            }
            maxLength={80}
            name="seo.title"
          />
        </Grid>
        <Grid item className={classes.checkboxContainer}>
          <CheckboxInput
            className={classes.checkbox}
            handleChange={e => {
              if (e.target.checked) {
                setFieldValue('seo.title', (values?.title).slice(0, 80));
              }
            }}
            label={<Typography variant="body2">Same as {product?.type === 'event' ? 'Event' : 'Product'} Title</Typography>}
            name="seo.useProductTitle"
            variant="label-right"
          />
        </Grid>
      </Grid>
      <Grid container direction="column">
        <Grid item>
          <TextAreaInput
            disabled={values.seo.useProductDescription}
            label={
              <Grid container alignItems="center">
                <Typography variant="subtitle2">SEO Description</Typography>
                <Tooltip
                  arrow
                  placement="top"
                  title="Write a brief description about your product, focusing on the needs of your potential customers and what makes this product unique!"
                />
              </Grid>
            }
            name="seo.description"
            maxLength={300}
            rowCount={4}
            value={values?.seo?.description}
          />
        </Grid>
        <Grid item className={classes.checkboxContainer}>
          <CheckboxInput
            className={classes.checkbox}
            handleChange={e => {
              if (e.target.checked) {
                setFieldValue('seo.description', stripHtml(values?.description).slice(0, 300));
              }
            }}
            label={<Typography variant="body2">Same as {product?.type === 'event' ? 'Event' : 'Product'} Description</Typography>}
            name="seo.useProductDescription"
            variant="label-right"
          />
        </Grid>
      </Grid>
    </CollapsableCard>
  );
};

export default SEO;
