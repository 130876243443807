import React, {ReactNode} from 'react';
import { ErrorMessage, FormikErrors } from 'formik';
import { capitalize } from '@castiron/utils';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  name: string;
  msg: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  children?: ReactNode;
};

const useStyles = makeStyles(theme => ({
  root: {
    color: '#ff2b26',
    marginTop: '10px;',
  },
}));

function ValidationError(props: Props) {
  const classes = useStyles();
  return (
    <ErrorMessage
      className={classes.root}
      name={props.name}
      render={msg => <div className={`${classes.root} text-danger validation-error}`}>{capitalize(msg)}</div>}
    />
  );
}

export default ValidationError;
