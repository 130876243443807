import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import {
  SaveButton,
  DiscardButton,
  ToggleButton,
  ToggleButtonOption,
  Typography,
  Banner,
  Button,
  IosShareIcon,
  ProductCard,
  SelectOption,
  SelectInput,
} from '@castiron/components';
import { Shop } from '@castiron/domain';
import { getProductStatus, useTracking } from '@castiron/utils';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { openModal } from '../../../store/reducers/modalConductor';
import { updateShopAction } from '../../../store/reducers/shops';
import { getProductsAction } from '../../../store/reducers/products';
import { LayoutPageProps } from '../../Layout';
import AdminForm from '../../AdminForm';
import ViewShopButton from '../../Layout/Header/ViewShopButton';
import UnsavedChangesPrompt from '../../UnsavedChangesPrompt.tsx';
import RichTextInput from '../../RichTextEditor/v2';

interface Props extends LayoutPageProps {}

interface FormValues {
  isActive: boolean;
  quotesPageHeadline: string;
  quotesPageDescription: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  bannerLink: {
    textDecoration: 'underline',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  container: {
    '& > form': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
  },
  errorBanner: {
    marginTop: 24,
    [theme.breakpoints.down('sm')]: {
      margin: '0 0 24px 0',
    },
  },
  infoBanner: {
    marginBottom: 24,
  },
  mobileShareIcon: {
    color: theme.branding.v2.gray[400],
  },
  productCard: {
    opacity: '64%',
    cursor: 'auto',
  },
  productsHeader: {
    margin: '24px 0 8px',
  },
  rightColumn: {
    fontWeight: 600,
    lineHeight: '24px',
  },
  toggleButton: {
    fontWeight: 600,
    lineHeight: '24px',
    '& button': {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '16px 0px',
    },
  },
  toggleCaption: {
    fontWeight: 400,
    color: theme.branding.v2.gray[700],
  },
}));

const QuotesPage: React.FC<LayoutPageProps> = (props: Props) => {
  const { setPageTitle, setPageIsProFeature, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const history = useHistory();
  const formRef = useRef<FormikProps<FormValues>>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { shop, products } = useAppSelector(state => ({
    shop: state.shops.shop,
    products: state.products.products,
  }));

  const [isSubmitting, setIsSubmitting] = useState(false);

  const quoteProducts = products.filter(
    p => p.type === 'custom' && getProductStatus(p, shop?.config?.timeZone) === 'active',
  );
  const hasQuoteProducts = !_.isEmpty(quoteProducts);

  const handleShareClick = () => {
    dispatch(
      openModal({
        modalType: 'SHARE_LINKS_MODAL',
        modalProps: {
          show: true,
          subdirectory: 'order-forms',
        },
      }),
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    setPageTitle('Order Forms');
    setPageIsProFeature(false);
    setBackLocation(true);
    setHeaderCTAs([
      isMobile ? (
        <IosShareIcon className={classes.mobileShareIcon} onClick={handleShareClick} />
      ) : (
        <Button variant="outlined" onClick={handleShareClick}>
          <IosShareIcon />
          &nbsp;&nbsp; Share
        </Button>
      ),
      <ViewShopButton subdirectory="order-forms" />,
    ]);

    return () => {
      setBackLocation(false);
      setPageTitle('');
      setPageIsProFeature(false);
      setHeaderCTAs([]);
    };
  }, [isMobile]);

  useEffect(() => {
    setFooterCTAs([
      <DiscardButton isSubmitting={isSubmitting} backLocation="/store/pages" />,
      <SaveButton formikState={formRef.current} isSubmitting={isSubmitting} />,
    ]);

    return () => {
      setFooterCTAs([]);
    };
  }, [isSubmitting]);

  useEffect(() => {
    const getProducts = async () => {
      dispatch(getProductsAction(shop.id));
    };

    if (shop) getProducts();
  }, [shop]);

  const toggleButtonOptions: ToggleButtonOption[] = [
    {
      value: true,
      label: 'Active',
    },
    {
      value: false,
      label: 'Inactive',
    },
  ];

  const getInactiveBannerBlock = () =>
    !hasQuoteProducts && (
      <Banner variant="error" className={classes.errorBanner}>
        <Typography variant="body4" style={{ color: theme.branding.v2.red[900] }}>
          Please add at least one{' '}
          <Link to="/order-forms" className={classes.bannerLink}>
            order form
          </Link>{' '}
          to change the status to active.
        </Typography>
      </Banner>
    );

  const getActiveBannerBlock = () => (
    <Banner variant="info-white" className={classes.infoBanner}>
      <Typography variant="body4">
        <span style={{ fontWeight: 700 }}>Note:</span> This page is populated by your order forms. To make edits to the
        page, visit and edit your{' '}
        <Link to="/order-forms" style={{ color: theme.branding.v2.gray[600], textDecoration: 'underline' }}>
          order forms
        </Link>
        .
      </Typography>
    </Banner>
  );

  const getToggleButtonBlock = (setFieldValue, values: FormValues) => (
    <Grid container item direction="column" className={classes.toggleButton}>
      <Typography variant="subtitle2">Status</Typography>
      <ToggleButton
        value={values.isActive}
        exclusive
        onChange={(e: React.MouseEvent<HTMLElement>, value): void => {
          setFieldValue('isActive', value);
        }}
        aria-label="page visibility"
        buttonOptions={toggleButtonOptions}
        disabled={!hasQuoteProducts}
      />
      <Typography variant="caption" className={classes.toggleCaption}>
        Status for this page is automatically set to active when you have at least one active custom product.
      </Typography>
    </Grid>
  );

  const onSubmit = async (values: FormValues, formikProps: FormikProps<FormValues>) => {
    setIsSubmitting(true);

    try {
      const { isActive, quotesPageDescription, quotesPageHeadline } = values;

      const newShop = {
        ...shop,
        shopSubpageData: {
          ...shop.shopSubpageData,
          quotes: {
            ...shop.shopSubpageData?.quotes,
            enabled: isActive,
            description: quotesPageDescription,
            headline: quotesPageHeadline,
          },
        },
      } as Shop;

      await dispatch(updateShopAction({ shop: newShop }));

      trackEvent('Shop Get A Quote Page Updated');
      {
        quotesPageHeadline !== shop.shopSubpageData?.quotes?.headline &&
          trackEvent('Shop Get a Quote Page Headline Changed', {
            headline: quotesPageHeadline,
          });
      }

      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT',
          modalProps: {
            show: true,
            celebrate: true,
            content: (
              <>
                <Typography variant="h4">Order Forms Page Updated!</Typography>
              </>
            ),
          },
        }),
      );

      setIsSubmitting(false);
      resetForm(formikProps);
      history.push('/store/pages');
    } catch (error) {
      setIsSubmitting(false);
      console.error('Error Submitting Shop Quotes Page Form: ', error);
    }
  };

  const initialValues: FormValues = {
    isActive: (shop?.shopSubpageData?.quotes?.enabled && hasQuoteProducts) || false,
    quotesPageHeadline: shop?.shopSubpageData?.quotes?.headline || 'Custom Orders',
    quotesPageDescription:
      shop?.shopSubpageData?.quotes?.description ||
      'Please select an item below to view options, availability, and to begin a quote.',
  };

  const quotesSchema = yup.object().shape({
    isActive: yup.boolean(),
    quotesPageHeadline: yup.string(),
    quotesPageDescription: yup.string().nullable(),
  });

  const resetForm = (formikProps: FormikProps<FormValues>) => {
    formikProps.setSubmitting(false);
    formikProps.resetForm();
    history.push(`/store/pages`);
  };

  const headlineOptions: SelectOption[] = [
    {
      display: 'Custom Orders',
      value: 'Custom Orders',
    },
    {
      display: 'Order Forms',
      value: 'Order Forms',
    },
    {
      display: 'Get a Quote',
      value: 'Get a Quote',
    },
  ];

  return (
    <Grid container justify="center" className={classes.container}>
      <Helmet>
        <title>Order Forms | Castiron</title>
      </Helmet>
      <Formik
        initialValues={initialValues}
        validationSchema={quotesSchema}
        onSubmit={onSubmit}
        innerRef={formRef}
        enableReinitialize
      >
        {({ dirty, errors, setFieldValue, touched, values }): ReactElement => (
          <AdminForm>
            <Grid
              container
              item
              xs={12}
              direction={isMobile ? 'column' : 'row'}
              spacing={!isMobile && 6}
              wrap={isMobile ? 'wrap' : 'nowrap'}
            >
              {isMobile && (
                <>
                  {getToggleButtonBlock(setFieldValue, values)}
                  {hasQuoteProducts ? getActiveBannerBlock() : getInactiveBannerBlock()}
                </>
              )}

              <Grid container item xs={12} md={8} direction="column">
                {!isMobile && hasQuoteProducts && getActiveBannerBlock()}
                <SelectInput options={headlineOptions} label="Headline" name="quotesPageHeadline" />
                <Grid container direction="column" item style={{ marginTop: 24 }}>
                  <Typography variant="subtitle2">Description</Typography>
                  <RichTextInput name="quotesPageDescription" />
                  {touched.quotesPageDescription && errors.quotesPageDescription && (
                    <Typography variant="caption" color="error">
                      {errors.quotesPageDescription}
                    </Typography>
                  )}
                </Grid>
                {hasQuoteProducts && (
                  <Grid container item direction="column" style={{ marginBottom: 40 }}>
                    <Typography variant="subtitle2" className={classes.productsHeader}>
                      Active Order Forms
                    </Typography>
                    <Grid container item spacing={2}>
                      {_.sortBy(quoteProducts, [
                        product => getProductStatus(product, shop?.config?.timeZone) !== 'active',
                        product => product.title.toLowerCase(),
                      ]).map(product => (
                        <Grid className={classes.productCard} key={product.id} item xs={6} sm={4}>
                          <ProductCard
                            disabled
                            product={product}
                            onClick={() => {}}
                            type="product"
                            timeZone={shop?.config?.timeZone}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <Grid container item xs={12} md={4} direction="column" className={classes.rightColumn}>
                {!isMobile && (
                  <>
                    {getToggleButtonBlock(setFieldValue, values)}
                    {!hasQuoteProducts && getInactiveBannerBlock()}
                  </>
                )}
              </Grid>
              {!isSubmitting && <UnsavedChangesPrompt when={dirty} />}
            </Grid>
          </AdminForm>
        )}
      </Formik>
    </Grid>
  );
};

export default QuotesPage;
