import * as yup from 'yup';

/* contains common yup schema for reusability */

const idLength = 36;
export const idField = yup.string().max(idLength);

export const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?([\s.-])?\d{3}([\s.-])?\d{4}$/;

export const formatPhoneNumber = (phoneNumber: string) => {
  const formattedPhoneNumber = phoneNumber.replace(/[()\- ]/gi, '');
  return phoneNumber ? `+1${formattedPhoneNumber}` : '';
};