import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const EmptyPresalesIcon = (props: SvgIconProps) => (
  <SvgIcon width="120" height="101" viewBox="0 0 120 101" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_55_1638)">
      <path
        d="M5.57596 95.456C7.24048 98.5429 10.7732 99.8681 10.7732 99.8681C10.7732 99.8681 11.6072 96.1883 9.94266 93.1014C8.27814 90.0145 4.74543 88.6893 4.74543 88.6893C4.74543 88.6893 3.91144 92.3691 5.57596 95.456Z"
        fill="#003080"
      />
      <path
        d="M6.72007 94.4404C9.72521 96.2484 10.8826 99.8396 10.8826 99.8396C10.8826 99.8396 7.16766 100.499 4.16252 98.6914C1.15738 96.8834 0 93.2923 0 93.2923C0 93.2923 3.71493 92.6324 6.72007 94.4404Z"
        fill="#005FFF"
      />
      <path d="M74.0958 4.80908H55.3458V5.07694H74.0958V4.80908Z" fill="#F5F9FF" />
      <path d="M69.0065 5.01001H68.7386V7.48769H69.0065V5.01001Z" fill="#F5F9FF" />
      <path d="M60.8368 5.01001H60.569V7.48769H60.8368V5.01001Z" fill="#F5F9FF" />
      <path d="M83.6047 17.6663H64.8547V17.9341H83.6047V17.6663Z" fill="#F5F9FF" />
      <path d="M70.2118 15.2555H69.944V17.7332H70.2118V15.2555Z" fill="#F5F9FF" />
      <path d="M78.3815 15.2555H78.1136V17.7332H78.3815V15.2555Z" fill="#F5F9FF" />
      <path d="M108.515 23.693H89.7654V23.9609H108.515V23.693Z" fill="#F5F9FF" />
      <path d="M95.1226 21.2823H94.8547V23.76H95.1226V21.2823Z" fill="#F5F9FF" />
      <path d="M103.292 21.2823H103.024V23.76H103.292V21.2823Z" fill="#F5F9FF" />
      <path d="M106.64 85.8358H87.8904V86.1037H106.64V85.8358Z" fill="#F5F9FF" />
      <path d="M93.2476 83.4252H92.9797V85.9028H93.2476V83.4252Z" fill="#F5F9FF" />
      <path d="M101.417 83.4252H101.149V85.9028H101.417V83.4252Z" fill="#F5F9FF" />
      <path d="M74.6315 42.8448H55.8815V43.1127H74.6315V42.8448Z" fill="#F5F9FF" />
      <path d="M61.2387 40.4341H60.9708V42.9118H61.2387V40.4341Z" fill="#F5F9FF" />
      <path d="M69.4082 40.4341H69.1404V42.9118H69.4082V40.4341Z" fill="#F5F9FF" />
      <path d="M80.5244 75.1216H61.7744V75.3894H80.5244V75.1216Z" fill="#F5F9FF" />
      <path d="M67.1315 72.7108H66.8636V75.1885H67.1315V72.7108Z" fill="#F5F9FF" />
      <path d="M75.3012 72.7108H75.0333V75.1885H75.3012V72.7108Z" fill="#F5F9FF" />
      <path d="M110.524 65.3447H91.7744V65.6126H110.524V65.3447Z" fill="#F5F9FF" />
      <path d="M97.1315 62.9341H96.8636V65.4118H97.1315V62.9341Z" fill="#F5F9FF" />
      <path d="M105.301 62.9341H105.033V65.4118H105.301V62.9341Z" fill="#F5F9FF" />
      <path
        d="M48.4902 94.0425H16.7778V93.8145H48.262V82.7493H19.4438L18.0438 79.8327L18.2495 79.734L19.5873 82.5211H48.4902V94.0425Z"
        fill="#003080"
      />
      <path
        d="M20.9985 99.6321C22.3215 99.6321 23.394 98.5596 23.394 97.2366C23.394 95.9136 22.3215 94.8411 20.9985 94.8411C19.6754 94.8411 18.6029 95.9136 18.6029 97.2366C18.6029 98.5596 19.6754 99.6321 20.9985 99.6321Z"
        fill="#003080"
      />
      <path
        d="M44.6117 99.6321C45.9347 99.6321 47.0072 98.5596 47.0072 97.2366C47.0072 95.9136 45.9347 94.8411 44.6117 94.8411C43.2886 94.8411 42.2161 95.9136 42.2161 97.2366C42.2161 98.5596 43.2886 99.6321 44.6117 99.6321Z"
        fill="#003080"
      />
      <path
        d="M72.4456 47.8427C73.2646 47.8427 73.9286 47.1788 73.9286 46.3598C73.9286 45.5408 73.2646 44.8768 72.4456 44.8768C71.6266 44.8768 70.9626 45.5408 70.9626 46.3598C70.9626 47.1788 71.6266 47.8427 72.4456 47.8427Z"
        fill="#003080"
      />
      <path
        d="M51.882 78.9848H16.2371L8.52435 53.7745H59.8249L59.7782 53.9229L51.882 78.9848ZM16.4058 78.7566H51.7146L59.5139 54.0027H8.83271L16.4058 78.7566Z"
        fill="#003080"
      />
      <path d="M49.0996 77.6729H17.7913L11.017 55.3145H56.076L56.035 55.4461L49.0996 77.6729Z" fill="#F5F9FF" />
      <path
        d="M60.4641 51.5224L60.2435 51.4638L61.749 45.7894H70.5065V46.0176H61.9246L60.4641 51.5224Z"
        fill="#003080"
      />
      <path d="M57.2613 61.4175H11.0167V61.6456H57.2613V61.4175Z" fill="#003080" />
      <path d="M54.6131 69.8229H13.5883V70.051H54.6131V69.8229Z" fill="#003080" />
      <path d="M34.288 53.8887H34.0599V78.8708H34.288V53.8887Z" fill="#003080" />
      <path d="M44.5026 53.8814L42.8649 78.8629L43.0926 78.8778L44.7303 53.8964L44.5026 53.8814Z" fill="#003080" />
      <path d="M23.8501 53.8812L23.6224 53.896L25.2506 78.8781L25.4783 78.8633L23.8501 53.8812Z" fill="#003080" />
      <path d="M120 99.7769H0V100.045H120V99.7769Z" fill="#003080" />
      <path
        d="M79.7426 8.28702C79.7426 8.28702 81.7004 13.8593 80.4956 14.7166C79.2907 15.5738 84.5618 22.5749 84.5618 22.5749L90.8871 20.8603L87.4232 15.0023C87.4232 15.0023 86.9714 9.14429 86.9714 8.28701C86.9714 7.42974 79.7426 8.28702 79.7426 8.28702Z"
        fill="#A0616A"
      />
      <path
        opacity="0.1"
        d="M79.7426 8.28702C79.7426 8.28702 81.7004 13.8593 80.4956 14.7166C79.2907 15.5738 84.5618 22.5749 84.5618 22.5749L90.8871 20.8603L87.4232 15.0023C87.4232 15.0023 86.9714 9.14429 86.9714 8.28701C86.9714 7.42974 79.7426 8.28702 79.7426 8.28702Z"
        fill="#003080"
      />
      <path
        d="M76.4563 48.0073C76.4563 48.0073 75.8848 55.1512 76.4563 58.866C77.0278 62.5809 77.8851 72.8681 77.8851 72.8681C77.8851 72.8681 77.8851 92.2996 81.0284 92.5854C84.1717 92.8712 86.4578 93.1569 86.7435 92.0139C87.0293 90.8709 85.3148 90.2993 86.172 89.7278C87.0293 89.1563 87.3151 87.156 86.172 84.87C85.029 82.5839 86.172 59.4375 86.172 59.4375L91.6014 74.0112C91.6014 74.0112 92.1729 86.013 92.7444 87.7275C93.316 89.4421 92.1729 92.5854 94.1732 92.8712C96.1735 93.1569 98.4596 91.4424 99.6026 90.8709C100.746 90.2993 97.8881 90.2993 98.4596 90.0136C99.0311 89.7278 100.746 88.8705 100.174 88.5848C99.6026 88.299 99.0311 74.5827 99.0311 74.5827C99.0311 74.5827 97.4594 45.2926 95.4591 44.1495C93.4588 43.0065 92.1729 44.9753 92.1729 44.9753L76.4563 48.0073Z"
        fill="#003080"
      />
      <path
        d="M81.5999 91.4424V93.7284C81.5999 93.7284 79.0281 99.9427 81.5999 99.9427C84.1718 99.9427 86.1721 100.587 86.1721 99.7293V92.0139L81.5999 91.4424Z"
        fill="#003080"
      />
      <path
        d="M98.4596 91.4384V93.7244C98.4596 93.7244 101.031 99.9386 98.4596 99.9386C95.8878 99.9386 93.8875 100.583 93.8875 99.7253V92.0099L98.4596 91.4384Z"
        fill="#003080"
      />
      <path
        d="M83.7431 12.4305C86.5839 12.4305 88.8868 10.1276 88.8868 7.28682C88.8868 4.44607 86.5839 2.14319 83.7431 2.14319C80.9024 2.14319 78.5995 4.44607 78.5995 7.28682C78.5995 10.1276 80.9024 12.4305 83.7431 12.4305Z"
        fill="#A0616A"
      />
      <path
        d="M82.1715 16.8597C82.1715 16.8597 83.6003 21.1461 85.8863 20.2888L88.1724 19.4316L92.173 46.8643C92.173 46.8643 89.0297 51.4364 84.4575 48.5788C79.8854 45.7212 82.1715 16.8597 82.1715 16.8597Z"
        fill="#005FFF"
      />
      <path
        d="M86.172 16.0024L87.4579 13.2877C87.4579 13.2877 95.0305 16.8597 96.1735 18.0027C97.3165 19.1457 97.3165 20.8603 97.3165 20.8603L95.3162 28.0042C95.3162 28.0042 95.8877 43.7209 95.8877 44.2924C95.8877 44.8639 97.8881 48.0072 96.4593 46.8642C95.0305 45.7212 94.7447 44.5782 93.6017 46.2927C92.4587 48.0072 89.8868 50.0075 89.8868 50.0075L86.172 16.0024Z"
        fill="#003080"
      />
      <path
        d="M96.1735 36.5769L95.3162 44.5781C95.3162 44.5781 90.1726 49.1502 91.3156 49.436C92.4587 49.7217 93.0302 48.5787 93.0302 48.5787C93.0302 48.5787 95.0305 50.579 96.1735 49.436C97.3165 48.293 100.174 37.4342 100.174 37.4342L96.1735 36.5769Z"
        fill="#A0616A"
      />
      <path
        d="M83.8527 0.0173934C82.7124 -0.0233704 81.4922 -0.0435348 80.5307 0.570948C80.0884 0.879331 79.7004 1.25913 79.3827 1.69481C78.4463 2.87854 77.6372 4.36793 77.985 5.8366L78.389 5.67905C78.389 6.07109 78.3018 6.45822 78.1339 6.81244C78.1907 6.64702 78.3813 6.91442 78.3302 7.08175L77.8853 8.54166C78.6168 8.27348 79.5268 8.81646 79.6381 9.5876C79.689 7.8919 79.8649 5.94747 81.2405 4.95457C81.9341 4.45385 82.8121 4.30099 83.6567 4.16526C84.436 4.04004 85.2529 3.92063 85.9993 4.17716C86.7456 4.43369 87.3812 5.19702 87.212 5.96791C87.5562 5.84936 87.9411 6.0892 88.1111 6.41102C88.2812 6.73284 88.2902 7.11247 88.2953 7.47643C88.6621 7.73569 89.0796 7.22087 89.2291 6.79726C89.58 5.80293 89.892 4.74449 89.7029 3.70713C89.5139 2.66977 88.6686 1.67841 87.6141 1.6749C87.7421 1.53432 87.8104 1.34946 87.8045 1.15944L86.9354 1.08601C87.1561 1.05476 87.359 0.947785 87.5094 0.783388C87.1605 1.16735 84.417 0.0375644 83.8527 0.0173934Z"
        fill="#003080"
      />
      <path
        d="M83.6002 15.1451C83.6002 15.1451 81.274 12.8655 80.4368 13.0052C79.5996 13.1448 78.4566 15.1451 78.4566 15.1451C78.4566 15.1451 71.5984 17.4312 71.8842 19.7172C72.1699 22.0033 75.3133 33.1478 75.3133 33.1478C75.3133 33.1478 77.8851 46.5784 75.599 48.0072C73.313 49.436 86.4578 53.1509 86.7436 51.4363C87.0293 49.7218 87.6008 32.5763 86.7436 30.0045C85.8863 27.4327 83.6002 15.1451 83.6002 15.1451Z"
        fill="#003080"
      />
      <path
        d="M93.6017 19.7173H97.1351C97.1351 19.7173 99.6026 30.5761 99.8883 31.7191C100.174 32.8621 100.746 38.2915 100.46 38.2915C100.174 38.2915 94.459 37.1485 94.459 38.0058L93.6017 19.7173Z"
        fill="#003080"
      />
      <path
        d="M93.4822 46.6071H79.9554C78.6354 40.5348 78.5251 34.6679 79.9554 29.0625H93.4822C91.2992 34.5671 91.1628 40.3938 93.4822 46.6071Z"
        fill="#F5F9FF"
      />
      <path
        d="M74.4559 39.1488L78.4565 40.8634C78.4565 40.8634 86.172 42.0064 86.172 38.8631C86.172 35.7197 78.4565 37.4343 78.4565 37.4343L75.8845 36.6119L74.4559 39.1488Z"
        fill="#A0616A"
      />
      <path
        d="M75.3132 18.0027L71.8841 18.86L69.3123 34.0051C69.3123 34.0051 68.455 38.0057 69.8838 38.2915C71.3126 38.5772 75.3132 40.8633 75.3132 40.8633C75.3132 40.8633 75.8847 36.5769 77.0278 36.5769L74.1702 34.2909L75.8847 24.2894L75.3132 18.0027Z"
        fill="#003080"
      />
      <path d="M26.819 47.2644H8.06897V47.5323H26.819V47.2644Z" fill="#F5F9FF" />
      <path d="M13.4261 44.8538H13.1583V47.3314H13.4261V44.8538Z" fill="#F5F9FF" />
      <path d="M21.5958 44.8538H21.3279V47.3314H21.5958V44.8538Z" fill="#F5F9FF" />
      <path d="M33.3815 7.62158H14.6315V7.88944H33.3815V7.62158Z" fill="#F5F9FF" />
      <path d="M28.2922 7.82251H28.0244V10.3002H28.2922V7.82251Z" fill="#F5F9FF" />
      <path d="M20.1226 7.82251H19.8547V10.3002H20.1226V7.82251Z" fill="#F5F9FF" />
      <path d="M52.2654 34.0055H33.5154V34.2734H52.2654V34.0055Z" fill="#F5F9FF" />
      <path d="M47.1762 34.2064H46.9083V36.6841H47.1762V34.2064Z" fill="#F5F9FF" />
      <path d="M39.0065 34.2064H38.7386V36.6841H39.0065V34.2064Z" fill="#F5F9FF" />
      <path d="M20.3904 33.8716H1.64044V34.1394H20.3904V33.8716Z" fill="#F5F9FF" />
      <path d="M15.3011 34.0725H15.0333V36.5502H15.3011V34.0725Z" fill="#F5F9FF" />
      <path d="M7.1315 34.0725H6.86365V36.5502H7.1315V34.0725Z" fill="#F5F9FF" />
      <path d="M42.8904 20.4788H24.1404V20.7466H42.8904V20.4788Z" fill="#F5F9FF" />
      <path d="M29.4976 18.068H29.2297V20.5457H29.4976V18.068Z" fill="#F5F9FF" />
      <path d="M37.6672 18.068H37.3994V20.5457H37.6672V18.068Z" fill="#F5F9FF" />
    </g>
    <defs>
      <clipPath id="clip0_55_1638">
        <rect width="120" height="100.175" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
