import { DialogActions, DialogContent, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import React, { useEffect } from 'react';
import Confetti from '../Confetti/Confetti';
import { ButtonV2 } from '@castiron/components';
import ModalWrapper from '../RootModal/ModalWrapper';
import { useAppDispatch } from '../../hooks';
import { closeModal } from '../../store/reducers/modalConductor';

export type Props = {
  show: boolean;
  content: string;
  buttonText?: string;
  title?: string;
  handleAction?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
  },
  title: {
    fontSize: 21,
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: 0.15,
    marginBottom: 24,
  },
}));

const SimpleAlertV2: React.FC<Props> = (props: Props) => {
  const { show, content, buttonText, title, handleAction } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const handleClose = (): void => {
    if (handleAction) {
      handleAction();
    }
    dispatch(closeModal());
  };

  return (
    <ModalWrapper show={show} size="xs">
      <IconButton className={classes.closeIcon} onClick={handleClose}>
        <Close />
      </IconButton>
      <DialogContent>
        <Grid container justify="center" alignItems="center" style={{ padding: 12, marginTop: 36 }}>
          {title && (
            <Grid container item justify="center" alignItems="center" xs={12}>
              <Typography variant="h5" className={classes.title}>
                {title}
              </Typography>
            </Grid>
          )}
          <Grid container item justify="center" alignItems="center" xs={12}>
            <Typography variant="body1">{content}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', padding: '12px 36px 36px 36px' }}>
        <ButtonV2 fullWidth onClick={handleClose} variant="contained" style={{ padding: 16 }}>
          {buttonText ? buttonText : 'Done'}
        </ButtonV2>
      </DialogActions>
    </ModalWrapper>
  );
};

export default SimpleAlertV2;
