import React, { useEffect, useState, useRef } from 'react';
import { Grid, useMediaQuery, useTheme, Theme, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Typography, ButtonV2 } from '@castiron/components';
import { Asset, LayoutOption } from '@castiron/domain';
import { useFormikContext, FormikProps } from 'formik';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import HomePageLayoutOption from './HomePageLayoutOption';

type Props = {};

interface FormValues {
  isHomePageEnabled: boolean;
  homePageHeadline: string;
  homePageDescription: string;
  homePageBackground: Asset;
  homePageLayout: LayoutOption[];
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: 25,
  },
  homePageLayout: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 12,
  },
}));

const HomePageLayout: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { values, setFieldValue, errors, touched }: any = useFormikContext();

  // a little function to help us with reordering the result
  function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const sortedLayoutOptions = reorder<LayoutOption>(
      values.homePageLayout,
      result.source.index,
      result.destination.index,
    );

    setFieldValue(
      'homePageLayout',
      sortedLayoutOptions.map((v, index) => ({
        ...v,
        position: index,
      })),
    );
  };

  return (
    <Grid container justify="center" alignItems="center" className={classes.container}>
      <Grid container item direction="row" style={{ marginBottom: 8 }}>
        <Typography variant="subtitle1">Additional Sections</Typography>
        <Tooltip
          title="Provide a quick way for your customers to visit other sections of your website."
          style={{ marginLeft: 5, width: 20 }}
        >
          <InfoOutlinedIcon />
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        {values.homePageLayout && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="homePageLayout" type="LAYOUTOPTIONS">
              {(provided, snapshot) => (
                <Grid
                  item
                  container
                  xs={12}
                  alignItems="center"
                  direction="column"
                  wrap="nowrap"
                  ref={provided.innerRef}
                  className={classes.homePageLayout}
                >
                  {_.sortBy(values?.homePageLayout, p => p.position).map(
                    (layoutOption: LayoutOption, index: number) => (
                      <Draggable draggableId={layoutOption.name} index={index} key={layoutOption.name}>
                        {(provided, snapshot) => (
                          <Grid
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            container
                            item
                            key={layoutOption.name}
                            justify="space-between"
                            alignItems="center"
                            xs={12}
                          >
                            <Grid container item xs={12}>
                              <HomePageLayoutOption layoutOption={layoutOption} index={index} />
                            </Grid>
                          </Grid>
                        )}
                      </Draggable>
                    ),
                  )}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </Grid>
    </Grid>
  );
};

export default HomePageLayout;
