export const taxonomy = {
  Baking: {
    Cookies: [
      'Custom Decorated Cookies',
      'Custom Printed Cookies',
      'Cookie Decorating Kits',
      'Macarons',
      'Sandwich cookies',
      'Macaroons',
      'Cookie Treats',
      'Stuffed Cookies',
      'Cookie Bars',
      'Cookie Cake',
      'Biscotti',
      'Whoopie Pies',
      'Other Cookies',
    ],
    Cakes: [
      'Wedding Cake',
      'Birthday Cake',
      'Custom Cake',
      'Sheet Cakes',
      'Small Cake',
      'Large Cake',
      'Single Serve Cake',
      'Cupcakes',
      'Smash Cakes',
      'Ice Cream Cakes',
      'Pound Cakes',
      'Wedding Cake Tasting Box',
      'Cake Pops',
      'Fruit Cakes',
      'Swiss Roll Cakes',
      'Other Cakes',
    ],
    Desserts: [
      'Ice Cream',
      'Frozen yogurt',
      'Puddings',
      'Mousse',
      'Pies',
      'Tarts',
      'Crumbles and Cobblers',
      'Cream Puffs',
      'Dessert Bars',
      'Churros and Funnel Cakes',
      'Meringues',
      'Pavlovas',
      'Caramels',
      'Brittles',
      'Marshmallows',
      'Candy',
      'Other Desserts',
    ],
    Chocolates: [
      'Chocolate Treats',
      'Truffles',
      'Fudge',
      'Brownies',
      'Chocolate Covered Pretzels',
      'Hot Cocoa',
      'Barks',
    ],
    'Baked Goods': [
      'Cinnamon Rolls',
      'Cheesecakes',
      'Donuts',
      'Pastries',
      'Granola',
      'Energy Bites',
      'Baklava',
      'Pretzels',
      'Muffins',
      'Other Baked Goods',
    ],
    Breads: [
      'Bagels',
      'Artisanal Bread',
      'Biscuits',
      'Flatbreads',
      'Tortillas',
      'Dinner Rolls',
      'Buns',
      'Other Breads',
    ],
  },
  'Prepared Foods': {
    Charcuterie: ['Charcuterie Board', 'Charcuterie Cups', 'Charcuterie Box', 'Grazing Table', 'Other Charcuterie'],
    'Gift Baskets': [
      'Muffin Basket',
      'Cookie Basket',
      'Cupcake Basket',
      'Fruit Basket',
      'Nut Basket',
      'Meat and Cheese Basket',
      'Other Gift Baskets',
    ],
    'Meal Prep': [
      'Prepared Meals - Individual',
      'Prepared Meals - Subscription',
      'Prepared Lunch Boxes',
      'Meal Kits',
      'Other Meal Prep',
    ],
    Pasta: ['Pasta - Fresh', 'Pasta - Dried', 'Orzo and Couscous', 'Other Pasta'],
  },
  'Events and Services': {
    Classes: [
      'Baking Class - General',
      'Cookie Decorating Class',
      'Macaron Class',
      'Cake Decorating Class',
      'Cooking Class - General',
      'Charcuterie Class',
      'Other Classes',
    ],
    'Catering and Private Chef': [
      'Wedding Catering Inquiry',
      'Business Event Catering Inquiry',
      'Special Event Catering Inquiry',
      'Private Chef Inquiry',
      'Custom Meal Plan Inquiry',
    ],
  },
  Beverages: {
    Coffee: ['Coffee Beans', 'Coffee Cold Brew'],
    Tea: ['Loose Leaf Tea', 'Tea Bags'],
    'Other Beverages': ['Juices', 'Kombucha', 'Cocktail Mixers', 'Other Beverages'],
  },
  Grocery: {
    'Condiments and Sauces': [
      'BBQ sauce',
      'Hot Sauce',
      'Ketchups',
      'Mustards',
      'Salad Dressings',
      'Caramel Sauce',
      'Chocolate Sauce',
      'Other Condiments',
    ],
    'Spreads and Dips': ['Hummus', 'Artisanal Spreads and Dips'],
    Pantry: [
      'Honey',
      'Lentils and Legumes',
      'Grains - General',
      'Grains - Ancient Grains',
      'Flour',
      'Jam',
      'Jelly',
      'Pickles',
      'Snacks',
      'Popcorn',
      'Crackers',
      'Jerky',
      'Herbs',
      'Spices',
      'Seasoning',
      'Soup Mix',
      'Baking Mix',
      'Cake Mix',
      'Canned Goods',
      'Other Pantry',
    ],
    Dairy: ['Butter', 'Yogurt', 'Cream Cheese', 'Soft Cheeses', 'Hard Cheeses', 'Other Dairy'],
    'Other Grocery': ['Sourdough Starter', 'Marinades'],
  },
};

export const allTaxonomyText = [
  'Baking',
  'Cookies',
  'Custom Decorated Cookies',
  'Custom Printed Cookies',
  'Cookie Decorating Kits',
  'Macarons',
  'Sandwich cookies',
  'Macaroons',
  'Cookie Treats',
  'Stuffed Cookies',
  'Cookie Bars',
  'Cookie Cake',
  'Biscotti',
  'Whoopie Pies',
  'Other Cookies',
  'Cakes',
  'Wedding Cake',
  'Birthday Cake',
  'Custom Cake',
  'Sheet Cakes',
  'Small Cake',
  'Large Cake',
  'Single Serve Cake',
  'Cupcakes',
  'Smash Cakes',
  'Ice Cream Cakes',
  'Pound Cakes',
  'Wedding Cake Tasting Box',
  'Cake Pops',
  'Fruit Cakes',
  'Swiss Roll Cakes',
  'Other Cakes',
  'Desserts',
  'Ice Cream',
  'Frozen yogurt',
  'Puddings',
  'Mousse',
  'Pies',
  'Tarts',
  'Crumbles and Cobblers',
  'Cream Puffs',
  'Dessert Bars',
  'Churros and Funnel Cakes',
  'Meringues',
  'Pavlovas',
  'Caramels',
  'Brittles',
  'Marshmallows',
  'Candy',
  'Other Desserts',
  'Chocolates',
  'Chocolate Treats',
  'Truffles',
  'Fudge',
  'Brownies',
  'Chocolate Covered Pretzels',
  'Hot Cocoa',
  'Barks',
  'Baked Goods',
  'Cinnamon Rolls',
  'Cheesecakes',
  'Donuts',
  'Pastries',
  'Granola',
  'Energy Bites',
  'Baklava',
  'Pretzels',
  'Muffins',
  'Other Baked Goods',
  'Breads',
  'Bagels',
  'Artisanal Bread',
  'Biscuits',
  'Flatbreads',
  'Tortillas',
  'Dinner Rolls',
  'Buns',
  'Other Breads',
  'Prepared Foods',
  'Charcuterie',
  'Charcuterie Board',
  'Charcuterie Cups',
  'Charcuterie Box',
  'Grazing Table',
  'Other Charcuterie',
  'Gift Baskets',
  'Muffin Basket',
  'Cookie Basket',
  'Cupcake Basket',
  'Fruit Basket',
  'Nut Basket',
  'Meat and Cheese Basket',
  'Other Gift Baskets',
  'Meal Prep',
  'Prepared Meals - Individual',
  'Prepared Meals - Subscription',
  'Prepared Lunch Boxes',
  'Meal Kits',
  'Other Meal Prep',
  'Pasta',
  'Pasta - Fresh',
  'Pasta - Dried',
  'Orzo and Couscous',
  'Other Pasta',
  'Events and Services',
  'Classes',
  'Baking Class - General',
  'Cookie Decorating Class',
  'Macaron Class',
  'Cake Decorating Class',
  'Cooking Class - General',
  'Charcuterie Class',
  'Other Classes',
  'Catering and Private Chef',
  'Wedding Catering Inquiry',
  'Business Event Catering Inquiry',
  'Special Event Catering Inquiry',
  'Private Chef Inquiry',
  'Custom Meal Plan Inquiry',
  'Beverages',
  'Coffee',
  'Coffee Beans',
  'Coffee Cold Brew',
  'Tea',
  'Loose Leaf Tea',
  'Tea Bags',
  'Other Beverages', //this is a duplicate should be fine though
  'Juices',
  'Kombucha',
  'Cocktail Mixers',
  'Other Beverages',
  'Grocery',
  'Condiments and Sauces',
  'BBQ sauce',
  'Hot Sauce',
  'Ketchups',
  'Mustards',
  'Salad Dressings',
  'Caramel Sauce',
  'Chocolate Sauce',
  'Other Condiments',
  'Spreads and Dips',
  'Hummus',
  'Artisanal Spreads and Dips',
  'Pantry',
  'Honey',
  'Lentils and Legumes',
  'Grains - General',
  'Grains - Ancient Grains',
  'Flour',
  'Jam',
  'Jelly',
  'Pickles',
  'Snacks',
  'Popcorn',
  'Crackers',
  'Jerky',
  'Herbs',
  'Spices',
  'Seasoning',
  'Soup Mix',
  'Baking Mix',
  'Cake Mix',
  'Canned Goods',
  'Other Pantry',
  'Dairy',
  'Butter',
  'Yogurt',
  'Cream Cheese',
  'Soft Cheeses',
  'Hard Cheeses',
  'Other Dairy',
  'Other Grocery',
  'Sourdough Starter',
  'Marinades',
];
