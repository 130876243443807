import React, { ReactElement } from 'react';
import { IconButton, Tooltip as MUITooltip } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { SvgIcon } from '@castiron/components';
import clsx from 'clsx';

type Props = {
  arrow?: boolean;
  children?: React.ReactElement<any, any>;
  title?: string | ReactElement<any, any>;
  tooltipClass?: string;
  iconClass?: string;
  style?: React.CSSProperties;
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
  onClick?: (e?: any) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginLeft: 8,
  },
  infoIcon: {
    color: theme.branding.v2.gray[500],
    width: 16,
    height: 16,
    cursor: 'pointer',
  },
  tooltips: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '24px',
  },
  tooltipContainer: {
    borderRadius: 12,
    padding: 8,
  },
}));

const Tooltip: React.FC<Props> = (props: Props) => {
  const { arrow = false, children, title, tooltipClass, style, placement, iconClass, onClick } = props;
  const classes = useStyles();

  return (
    <MUITooltip
      arrow={arrow}
      style={style}
      classes={{
        tooltip: classes.tooltipContainer,
      }}
      className={`${classes.container} ${tooltipClass}`}
      title={<span className={classes.tooltips}>{title}</span>}
      placement={placement}
      PopperProps={{
        style: {
          marginTop: '-12px',
        },
      }}
      onClick={onClick}
    >
      {children ? (
        children
      ) : (
        <IconButton>
          <SvgIcon className={clsx([classes.infoIcon, iconClass])}>
            <InfoOutlinedIcon />
          </SvgIcon>
        </IconButton>
      )}
    </MUITooltip>
  );
};

export default Tooltip;
