import { Grid, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import React, { Fragment, ReactNode } from 'react';
import { Typography } from '@castiron/components';
import ModalWrapper from './ModalWrapper';
import { useAppDispatch } from '../../hooks';
import { closeModal } from '../../store/reducers/modalConductor';
import ModalActions from './ModalActions';
import ModalHeader from './ModalHeader';

export type Props = {
  icon?: ReactNode;
  title?: ReactNode;
  content?: ReactNode;
  actions?: ReactNode[];
  show?: boolean;
  containerClass?: any;
  header?: ReactNode;
  noContentMargin?: boolean;
  onClose?: () => void;
  paperClass?: string;
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  contentContainer: {
    marginBottom: props => (props.noContentMargin ? 0 : 24),
    padding: props => (props.noContentMargin ? '0px 24px' : `24px 24px 0px 24px`),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      height: '100%',
    },
    overflowY: 'scroll',
    'scrollbar-width': 'thin',
    'scrollbar-color': theme.branding.v2.gray[400],
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.branding.v2.gray[600],
      '-webkit-border-radius': '12px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.branding.v2.gray[0],
    },
    '&::-webkit-scrollbar-track-piece:start': {
      background: 'transparent',
    },
    '&-webkit-scrollbar-track-piece:end': {
      background: 'transparent',
    },
  },
  iconContainer: {
    fontSize: 63,
  },
  paperClass: {
    borderRadius: 16,
    [theme.breakpoints.up('sm')]: {
      height: 624,
      width: 504,
    },
  },
  titleContainer: {
    marginBottom: 8,
    padding: `0 24px`,
    textAlign: 'center',
  },
}));

const GeneralModal: React.FC<Props> = (props: Props) => {
  const { icon, title, content, actions, show, onClose, containerClass, header, paperClass } = props;
  const classes = useStyles(props);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isXsMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = (): void => {
    if (onClose) {
      onClose();
    }
    dispatch(closeModal());
  };

  return (
    <ModalWrapper
      paperClass={`${!isXsMobile ? classes.paperClass : ''} ${paperClass ? paperClass : ''}`}
      fullScreen={isXsMobile}
      show={show}
      onClose={handleClose}
    >
      <ModalHeader title={header} handleClose={handleClose} />
      {icon && (
        <Grid container justify="center" className={classes.iconContainer}>
          {icon}
        </Grid>
      )}
      {title && (
        <Grid container justify="center" className={classes.titleContainer}>
          {typeof title === 'string' ? <Typography variant="h2">{title}</Typography> : title}
        </Grid>
      )}
      {content && (
        <Grid container justify="center" className={classes.contentContainer}>
          {typeof content === 'string' ? <Typography variant="body1">{content}</Typography> : content}
        </Grid>
      )}
      {actions && (
        <ModalActions>
          {actions.map((action, index) => (
            <Fragment key={`action${index}`}>
              {action}
            </Fragment>
          ))}
        </ModalActions>
      )}
    </ModalWrapper>
  );
};

export default GeneralModal;
