import React, { ReactNode, useEffect, useState } from 'react';
import _ from 'lodash';
import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import ClassicCastironProduct from '../../assets/img/themes-castiron-product.png';
import ClassicCastironHome from '../../assets/img/themes-castiron-home.png';
import PinkLemonadeProduct from '../../assets/img/themes-lemon-product.png';
import PinkLemonadeHome from '../../assets/img/themes-lemon-home.png';
import TropicalMacaronProduct from '../../assets/img/themes-macaron-product.png';
import TropicalMacaronHome from '../../assets/img/themes-macaron-home.png';
import SugarCookieProduct from '../../assets/img/themes-cookie-product.png';
import SugarCookieHome from '../../assets/img/themes-cookie-home.png';
import StrawberrySorbetProduct from '../../assets/img/themes-strawberry-product.png';
import StrawberrySorbetHome from '../../assets/img/themes-strawberry-home.png';
import SweetPatisserieProduct from '../../assets/img/themes-patisserie-product.png';
import SweetPatisserieHome from '../../assets/img/themes-patisserie-home.png';
import LavenderLatteProduct from '../../assets/img/themes-latte-product.png';
import LavenderLatteHome from '../../assets/img/themes-latte-home.png';
import ParisCafeProduct from '../../assets/img/themes-cafe-product.png';
import ParisCafeHome from '../../assets/img/themes-cafe-home.png';
import BlueberryCrispProduct from '../../assets/img/themes-blueberry-product.png';
import BlueberryCrispHome from '../../assets/img/themes-blueberry-home.png';
import RoseBakeryProduct from '../../assets/img/themes-rose-product.png';
import RoseBakeryHome from '../../assets/img/themes-rose-home.png';

interface Props {
  themeName: string;
  children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    width: 176,
    [theme.breakpoints.down('xs')]: {
      width: 128,
    },
  },
  imagesContainer: {
    padding: '30px 0',
    width: '100%',
  },
  upperContainer: {
    position: 'relative',
    borderRadius: '11px 11px 0 0',
    backgroundColor: theme.branding.v2.gray[0],
  },
}));

const ThemePreview: React.FC<Props> = (props: Props) => {
  const { themeName, children } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [previewProps, setPreviewProps] = useState<{
    homeImg: string;
    productImg: string;
  }>({
    homeImg: '',
    productImg: '',
  });

  useEffect(() => {
    const props = findPreviewProps();
    setPreviewProps(props);
  }, []);

  const findPreviewProps = () => {
    switch (themeName) {
      case 'Classic Castiron':
        return {
          homeImg: ClassicCastironHome,
          productImg: ClassicCastironProduct,
        };
      case 'Pink Lemonade':
        return {
          homeImg: PinkLemonadeHome,
          productImg: PinkLemonadeProduct,
        };
      case 'Tropical Macaron':
        return {
          homeImg: TropicalMacaronHome,
          productImg: TropicalMacaronProduct,
        };
      case 'Sugar Cookie':
        return {
          homeImg: SugarCookieHome,
          productImg: SugarCookieProduct,
        };
      case 'Strawberry Sorbet':
        return {
          homeImg: StrawberrySorbetHome,
          productImg: StrawberrySorbetProduct,
        };
      case 'Sweet Patisserie':
        return {
          homeImg: SweetPatisserieHome,
          productImg: SweetPatisserieProduct,
        };
      case 'Lavender Latte':
        return {
          homeImg: LavenderLatteHome,
          productImg: LavenderLatteProduct,
        };
      case 'Rose Bakery':
        return {
          homeImg: RoseBakeryHome,
          productImg: RoseBakeryProduct,
        };
      case 'Paris Cafe':
        return {
          homeImg: ParisCafeHome,
          productImg: ParisCafeProduct,
        };
      case 'Blueberry Crisp':
        return {
          homeImg: BlueberryCrispHome,
          productImg: BlueberryCrispProduct,
        };
      default:
        return {
          homeImg: ClassicCastironHome,
          productImg: ClassicCastironProduct,
        };
    }
  };

  return (
    <Grid container item alignItems="center" justify="center" className={classes.upperContainer}>
      <Grid
        container
        item
        spacing={2}
        justify="center"
        alignItems="center"
        direction="row"
        wrap={isMobile ? 'nowrap' : 'wrap'}
        className={classes.imagesContainer}
      >
        <Grid item>
          <img src={previewProps.homeImg} className={classes.image} />
        </Grid>
        <Grid item>
          <img src={previewProps.productImg} className={classes.image} />
        </Grid>
      </Grid>
      {children}
    </Grid>
  );
};

export default ThemePreview;
