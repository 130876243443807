import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import firebase from 'firebase/compat/app';
import { Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BusinessAddressInput, Link, PhoneInput, TextInput } from '@castiron/components';
import { Formik, Form, FormikProps } from 'formik';
import { Address, Shop, addressSchema, formatPhoneNumber, phoneRegExp } from '@castiron/domain';
import { stateShippingList, useTracking } from '@castiron/utils';
import { accountRepository, shopRepository } from '../../../../domain';
import { useAppSelector, useAppDispatch } from '../../../../hooks';
import { getShopAction, updateShopAction } from '../../../../store/reducers/shops';
import { trackHubSpotContactPage } from '../../../../lib/trackHubSpotContactEvent';
import { StickyFooterProps } from '../OnboardingFooter';
import { AlternateEmail } from '@material-ui/icons';
import _ from 'lodash';
import { getFulfillmentTemplates } from './fulfillmentTemplates';

const getShopNameFromBusinessName = async (businessName: string): Promise<string> => {
  let proposedShopUrl = businessName
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^0-9a-zA-Z_-]/gi, '')
    .toLowerCase();
  const existingShops = await shopRepository.findWebsiteUrlsStartsWith(proposedShopUrl);
  const numExisting = existingShops.length;
  if (numExisting > 0) proposedShopUrl = `${proposedShopUrl}-${numExisting}`;
  return proposedShopUrl;
};

interface Props {
  step?: number;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddressUpdate: (internationa: boolean) => void;
  setHeader?: (header: string) => void;
  setSubHeader?: (subHeader: string) => void;
  setStickyFooterProps?: (props: StickyFooterProps) => void;
  nextStep?: () => void;
}

export interface FormProps {
  address: Address;
  businessName: string;
  instagramHandle?: string;
  phoneNumber?: string;
}

const businessInfoSchema = yup.object().shape({
  address: addressSchema(true),
  businessName: yup
    .string()
    .min(1)
    .required('Please enter your business name.'),
  instagramHandle: yup.string(),
  phoneNumber: yup.string().matches(phoneRegExp, 'Must be a valid 10-digit phone number format'),
});

const useStyles = makeStyles((theme: Theme) => ({
  alternateEmailIcon: {
    fontSize: 16,
  },
  caption: {
    color: theme.branding.gray[700],
  },
  container: {
    borderRadius: 12,
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    padding: 24,
  },
  inputContainer: {
    paddingBottom: 8,
  },
  subtitle: {
    margin: '24px 0px 8px 0px',
  },
}));

const BusinessInfo: React.FC<Props> = (props: Props) => {
  const { step, setLoading, setHeader, setSubHeader, setStickyFooterProps, nextStep, handleAddressUpdate } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const formRef = useRef<FormikProps<FormProps>>();
  const { trackEvent } = useTracking();

  const onNextClick = async () => {
    await formRef.current.submitForm();
  };

  useEffect(() => {
    setHeader(`👋 Welcome ${shop?.owner.firstName}!`);
    setSubHeader('');

    setStickyFooterProps({
      onNextClick,
    });

    return () => {
      setStickyFooterProps(undefined);
      setHeader('');
      setSubHeader('');
    };
  }, [formRef]);

  const { account, shop } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
  }));

  const shopAddress = shop?.physicalAddress;

  const initialValues = {
    address: {
      fullAddress: shopAddress?.fullAddress || '',
      addressLine1: shopAddress?.addressLine1 || '',
      addressLine2: shopAddress?.addressLine2 || '',
      city: shopAddress?.city || '',
      region: shopAddress?.region || '',
      regionName: shopAddress?.regionName || '',
      postalCode: shopAddress?.postalCode || '',
      country: shopAddress?.country || '',
    },
    businessName: shop?.businessName ? shop?.businessName : '',
    instagramHandle: account?.onboardingQuestions?.businessInfo?.instagramHandle || '',
    phoneNumber: account?.phoneNumber || '',
  };

  const updateServiceArea = async value => {
    value === 'nationwide'
      ? await Promise.resolve(
          shopRepository.updateProps(shop.id, {
            'serviceArea.shipping': value,
            tags: firebase.firestore.FieldValue.arrayUnion('National'),
          }),
        )
      : await Promise.resolve(
          shopRepository.updateProps(shop.id, {
            'serviceArea.shipping': value,
            tags: firebase.firestore.FieldValue.arrayRemove('National'),
          }),
        );
  };

  const submit = async (values, errors) => {
    try {
      setLoading(true);

      const newCompletions = [];
      const newDescription = `Welcome to ${values?.businessName}! I'm ${
        shop?.owner?.firstName ? shop?.owner?.firstName + ', ' : ''
      }proudly serving customers in the ${
        values?.address?.city ? values?.address?.city + ' ' : ''
      }area. Thanks for stopping by! Please don't hesitate to reach out if you have any questions and be sure to subscribe to my email list for discounts and shop updates. Thank you for supporting ${
        values?.businessName
      }! `;
      const instagramHandle = values.instagramHandle?.trim();

      let newShop: Shop = {
        ...shop,
        businessName: values.businessName,
        description: newDescription,
        physicalAddress: { ...values.address },
        seoMetadata: {
          ...shop?.seoMetadata,
          address: {
            addressCountry: values.address.country,
            addressLocality: values.address.city,
            addressRegion: values.address.region,
            postalCode: values.address.postalCode,
          },
        },
        shopSubpageData: {
          ...shop.shopSubpageData,
          home: {
            ...shop.shopSubpageData?.home,
            headline: values.businessName,
          },
        },
      };

      if (values.businessName !== initialValues.businessName) {
        newShop.websiteUrl = await getShopNameFromBusinessName(values.businessName);
      }

      const shopUpdate = await Promise.resolve(dispatch(updateShopAction({ shop: newShop, newCompletions })));

      await accountRepository.updateProps(account.id, {
        billingAddress: { ...values.address },
        'onboardingQuestions.businessInfo.instagramHandle': instagramHandle,
        ...(values.phoneNumber && { phoneNumber: values.phoneNumber }),
        ...(values.phoneNumber && {
          'config.messagingPreferences.sms.smsPhoneNumber': formatPhoneNumber(values.phoneNumber),
        }),
      });

      if (instagramHandle) {
        trackEvent('Instagram Handle Captured', {
          instagramHandle,
        });

        trackHubSpotContactPage(
          {
            email: shop?.email,
            instagram_account: instagramHandle,
          },
          '/signup/1',
        );
      }

      if (shopUpdate.meta.requestStatus === 'fulfilled') {
        trackEvent('Business Questions Captured', {
          ..._.omit(values, 'instagramHandle'),
        });

        trackHubSpotContactPage(
          {
            email: shop.email,
            company: values.businessName,
            zip: values.address.postalCode,
            city: values.address.city,
            state: values.address.regionName,
            ...(values.phoneNumber && { mobilephone: values.phoneNumber }),
          },
          `/signup/info/${step}`,
        );
      }

      if (newShop?.physicalAddress?.country !== 'US') {
        handleAddressUpdate(true);
      } else {
        handleAddressUpdate(false);

        const fulfillmentOptions = stateShippingList[newShop?.physicalAddress?.region];
        const fulfillmentsToCreate = getFulfillmentTemplates(newShop?.physicalAddress, fulfillmentOptions);

        if (!_.isEmpty(fulfillmentsToCreate)) {
          const existingOpts = await Promise.resolve(shop.getFulfillmentOptions());

          if (
            (!!shop?.physicalAddress?.region && newShop?.physicalAddress?.region !== shop?.physicalAddress?.region) ||
            _.isEmpty(existingOpts)
          ) {
            if (!_.isEmpty(existingOpts)) {
              //delete existing fulfillments if there are any and region has changed
              await Promise.all(existingOpts.map(async opt => await shop.deleteFulfillmentOption(opt.id)));
            }
            await Promise.all(
              fulfillmentsToCreate.map(async ff => {
                await shop.addFulfillmentOption(ff);

                trackEvent('Shop Fulfillment Action', {
                  action: 'created',
                  fulfillment: ff,
                });
              }),
            );
          }

          if (fulfillmentOptions.includes('nationwide-shipping')) {
            await updateServiceArea('nationwide');
          } else if (fulfillmentOptions.includes('local-shipping')) {
            await updateServiceArea('statewide');
          } else {
            await updateServiceArea('none');
          }

          trackHubSpotContactPage(
            {
              email: shop.email,
              fulfillment_options: fulfillmentOptions.join(', '),
            },
            `/signup/info/${step}`,
          );
        }

        await dispatch(getShopAction(shop.id));
        nextStep();
      }
    } catch (error) {
      console.error('Error Submitting Shop Form: ', error);
    }

    setLoading(false);
  };

  return (
    <Grid container style={{ marginBottom: '120px' }}>
      <Formik onSubmit={submit} initialValues={initialValues} validationSchema={businessInfoSchema} innerRef={formRef}>
        {({ errors, touched }: FormikProps<FormProps>) => (
          <Form noValidate>
            <Typography className={classes.subtitle} variant="subtitle1">
              Business Details
            </Typography>
            <Grid container className={classes.container}>
              <Grid item xs={12} className={classes.inputContainer}>
                <TextInput
                  label="Business Name"
                  placeholder="Enter Business Name"
                  error={touched.businessName && errors.businessName}
                  name="businessName"
                  required
                />
                {!(touched.businessName && errors.businessName) && (
                  <Typography variant="caption" className={classes.caption}>
                    We'll use this for your shop name. Don’t worry, you can always change this later.
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} className={classes.inputContainer}>
                <BusinessAddressInput />
              </Grid>
              <Grid item xs={12}>
                <PhoneInput error={touched.phoneNumber && errors.phoneNumber} label="Phone Number" name="phoneNumber" />
                <Typography variant="caption" className={classes.caption}>
                  By signing up via text, you agree to receive recurring automated promotional and personalized
                  marketing text messages (e.g. help setting up your store) from Castiron at the cell number used when
                  signing up. Reply HELP for help and STOP to cancel. Msg frequency varies. Msg and data rates may
                  apply.{' '}
                  <Link underline={false} href="https://www.castiron.me/terms-conditions" target="_blank">
                    View Terms & Privacy
                  </Link>
                  .
                </Typography>
              </Grid>
            </Grid>
            <Typography className={classes.subtitle} variant="subtitle1">
              Social Info
            </Typography>
            <Grid container className={classes.container}>
              <TextInput
                label="Instagram Handle"
                name="instagramHandle"
                placeholder="castironhq"
                startAdornment={<AlternateEmail className={classes.alternateEmailIcon} />}
              />
              <Typography variant="caption" className={classes.caption}>
                We want to follow along! 😊
              </Typography>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default BusinessInfo;
