import * as React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const AppearanceIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_89_350)">
      <path
        d="M19.2225 6.66419C19.58 6.30669 19.58 5.72919 19.2225 5.37169L16.6283 2.77752C16.2708 2.42002 15.6933 2.42002 15.3358 2.77752L11.22 6.89336L7.65417 3.32752C6.93917 2.61252 5.775 2.61252 5.06 3.32752L3.31833 5.06919C2.60333 5.78419 2.60333 6.94836 3.31833 7.66336L6.88417 11.2292L2.75 15.3634V19.25H6.63667L10.78 15.1067L14.3458 18.6725C15.2167 19.5434 16.39 19.2225 16.94 18.6725L18.6817 16.9309C19.3967 16.2159 19.3967 15.0517 18.6817 14.3367L15.1158 10.7709L19.2225 6.66419ZM4.62 6.36169L6.3525 4.62002L7.51667 5.78419L6.435 6.87502L7.7275 8.16752L8.81833 7.07669L9.91833 8.17669L8.17667 9.91836L4.62 6.36169ZM14.9142 13.1817L13.8233 14.2725L15.1158 15.565L16.2067 14.4742L17.3708 15.6384L15.6292 17.38L12.0633 13.8142L13.805 12.0725L14.9142 13.1817ZM5.87583 17.4167H4.58333V16.1242L13.3925 7.31502L14.5842 8.50669L14.685 8.60752L5.87583 17.4167ZM14.685 6.01336L15.9775 4.72086L17.27 6.01336L15.9775 7.30586L14.685 6.01336Z"
        fill="#27272A"
      />
    </g>
    <defs>
      <clipPath id="clip0_89_350">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
