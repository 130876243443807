import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const IosShareIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 17 22" {...props}>
    <g>
      <path d="M12.5 4L11.08 5.42L9.49 3.83V15H7.51V3.83L5.92 5.42L4.5 4L8.5 0L12.5 4ZM16.5 9V20C16.5 21.1 15.6 22 14.5 22H2.5C1.39 22 0.5 21.1 0.5 20V9C0.5 7.89 1.39 7 2.5 7H5.5V9H2.5V20H14.5V9H11.5V7H14.5C15.6 7 16.5 7.89 16.5 9Z" />
    </g>
  </SvgIcon>
);
