import React, { useCallback, useEffect, useRef, useState } from 'react';
import { nanoid } from '@reduxjs/toolkit';
import _ from 'lodash';
import * as yup from 'yup';
import { FieldArray, Formik, FormikProps, getIn } from 'formik';
import { ButtonBase, Grid, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import {
  BaseProduct,
  ChecklistValues,
  TaxonomyCategory,
  taxonomyCategorySchema,
  taxonomyToStrings,
} from '@castiron/domain';
import { removeEmpty, useTracking } from '@castiron/utils';
import { Banner, GroupedSelectInput, MoneyInput, TextInput, Typography } from '@castiron/components';
import { accountRepository } from '../../../../domain';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { trackHubSpotContactPage } from '../../../../lib/trackHubSpotContactEvent';
import { createProductAction, deleteProductAction, getProductsAction } from '../../../../store/reducers/products';
import { getShopAction, updateChecklistAction, updateShopAction } from '../../../../store/reducers/shops';
import AdminForm from '../../../AdminForm';
import { StickyFooterProps } from '../OnboardingFooter';

interface Props {
  step: number;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setHeader?: (header: string) => void;
  setSubHeader?: (subHeader: string) => void;
  setStickyFooterProps?: (props: StickyFooterProps) => void;
  nextStep?: () => void;
}

const formSchema = yup.object().shape({
  newProducts: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(),
        title: yup.string().required('Please enter a title.'),
        category: taxonomyCategorySchema.typeError('Please select an option from the dropdown.'),
        price: yup
          .number()
          .min(0.5, 'Price must be greater than $0.50.')
          .required('Please enter a price.'),
      }),
    )
    .test({
      name: 'at-least-one-product',
      test: (values, context) => {
        const isValid = values.length === 1 ? !!values[0].title || !!values[0].category || !!values[0].price : true;

        if (isValid) return true;
        return context.createError({
          path: 'genValid',
          message: 'Please create at least one product.',
        });
      },
    })
    .test({
      name: 'all-valid',
      test: (values, context) => {
        const isValid = values.every(v => !!v.title && !!v.category && !!v.price);

        if (isValid) return true;
        return context.createError({
          path: 'genValid',
          message: 'Please fill out all required information.',
        });
      },
    })
    .required(),
});

const useStyles = makeStyles((theme: Theme) => ({
  addAnother: { color: theme.branding.v2.blue[500] },
  newProducts: { gap: 16 },
  productBox: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: 12,
  },
  productBoxFooter: {
    borderTop: `1px solid ${theme.branding.v2.gray[200]}`,
    padding: '16px 24px',
  },
  productBoxUpper: {
    padding: 24,
    gap: 16,
  },
  productContainer: { gap: 8 },
}));

const AddAProduct: React.FC<Props> = (props: Props) => {
  const { step, setLoading, setHeader, setStickyFooterProps, setSubHeader, nextStep } = props;
  const dispatch = useAppDispatch();
  const formRef = useRef<FormikProps<any>>();
  const { trackEvent } = useTracking();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const { account, shop, products } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
    products: state.products.products,
  }));

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [catOptsLoading, setCatOptsLoading] = useState(true);
  const [initialProducts, setInitialProducts] = useState([
    {
      id: nanoid(),
      title: '',
      category: null,
      price: 0,
    },
  ]);
  const [showError, setShowError] = useState(false);

  const submit = async values => {
    setLoading(true);

    if (!_.isEmpty(values.newProducts)) {
      //since formatting is different, just throw values into sessionStorage in case people go back and forth
      sessionStorage.setItem(`onboarding-new-products-${shop.id}`, JSON.stringify(values.newProducts));

      const uniqueCategories = _.uniq(values.newProducts?.map(p => p?.category?.leaf)).map((cat: string) => ({
        id: nanoid(),
        name: cat,
      }));
      const existingCats = !_.isEmpty(shop.categories) ? shop.categories : [];
      const shopCats = _.uniq([...uniqueCategories, ...existingCats]);

      const shopTax: TaxonomyCategory[] = values.newProducts?.map(product => product?.category);
      // update shop subpage to enabled + add new taxonomy arr; doing first to avoid issues with calls that assume the taxonomy exists already
      const newShop = {
        ...shop,
        categories: shopCats,
        taxonomy: shopTax,
        shopSubpageData: {
          ...shop.shopSubpageData,
          isShopPageEnabled: true,
        },
      };
      await Promise.resolve(
        dispatch(
          updateShopAction({
            shop: newShop,
          }),
        ),
      );

      //only add product if there was a change in the values from existing products
      const uniqueIds = values.newProducts?.filter(newProduct => {
        const existingIds = products.map(p => p.id);
        return !existingIds.includes(newProduct.id);
      });

      if (!_.isEmpty(uniqueIds)) {
        const productsAddedP = await Promise.all(
          values.newProducts?.map(async (product, index) => {
            if (!!product) {
              try {
                const newProduct = {
                  id: product.id,
                  type: 'standard',
                  title: product.title,
                  status: 'active',
                  price: product.price,
                  taxonomy: [product.category],
                  category: uniqueCategories.find(cat => cat.name === product?.category?.leaf),
                  shopId: shop.id,
                  source: 'onboarding',
                  unlimitedInventory: true,
                };

                const addedProduct = await dispatch(createProductAction(newProduct));

                if (products.length === 0 && index === 0) {
                  trackEvent('First Product Created', {
                    product: removeEmpty(addedProduct.payload as BaseProduct),
                  });
                  await dispatch(updateChecklistAction({ shop, items: [ChecklistValues.ProductAdded] }));
                }

                trackEvent('Product Created', {
                  product: removeEmpty({
                    ...(addedProduct.payload as BaseProduct),
                    method: 'admin',
                  }),
                  addMethod: 'onboarding',
                });

                trackEvent('Standard Product Created', {
                  product: removeEmpty({
                    ...(addedProduct.payload as BaseProduct),
                    method: 'admin',
                  }),
                  addMethod: 'onboarding',
                });

                return addedProduct;
              } catch (err) {
                console.error('Error adding new product: ', err);
              }
            }
          }),
        );
        const productsAdded: string[] = productsAddedP.map(p => p?.payload?.id);

        await dispatch(getProductsAction(shop.id));
        trackEvent('Product Created in Onboarding', {
          products: productsAdded,
        });

        //adding new props to hs in accountRepository.updateProps
        trackHubSpotContactPage({ email: shop.email }, `/signup/info/${step}`);

        await accountRepository.updateProps(account.id, {
          'onboardingQuestions.newProducts': productsAdded,
          'onboardingQuestions.taxonomy': shopTax,
        });
      }

      //rerun getShop to ensure that shop subpage data is up to date
      await dispatch(getShopAction(shop.id));

      setLoading(false);
      nextStep();
    } else {
      setLoading(false);
      setShowError(true);
    }
  };

  const categorySelectInput = useCallback(
    (index, touched, errors) => (
      <GroupedSelectInput
        options={categoryOptions}
        groupBy={option => option.mid}
        label="Category"
        name={`newProducts[${index}].category`}
        disabled={catOptsLoading}
        error={showError && getIn(errors, `newProducts[${index}].category`)}
        required
        getSelectedDisplay={option => option.leaf}
        getListDisplay={option => option.leaf}
      />
    ),
    [categoryOptions, catOptsLoading, formRef, initialProducts, showError],
  );

  useEffect(() => {
    setHeader('🛒 Add products to your shop.');
    setSubHeader('Add up to three products. You can customize these products and many more later.');
    setStickyFooterProps({
      onNextClick: async () => {
        await formRef.current.submitForm();
        if (!formRef.current.isValid) {
          setShowError(true);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }
      },
      onBackClick: () =>
        sessionStorage.setItem(
          `onboarding-new-products-${shop.id}`,
          JSON.stringify(formRef.current.values.newProducts),
        ),
    });

    return () => {
      setStickyFooterProps(undefined);
      setHeader('');
      setSubHeader('');
    };
  }, [formRef]);

  useEffect(() => {
    const mappedCats = taxonomyToStrings(true).map(cat => cat.value);

    setCategoryOptions(mappedCats);
    setCatOptsLoading(false);

    //get stored form values
    if (sessionStorage[`onboarding-new-products-${shop.id}`]) {
      const retrievedProducts = sessionStorage.getItem(`onboarding-new-products-${shop.id}`);
      if (!!retrievedProducts) setInitialProducts(JSON.parse(retrievedProducts));
    }
  }, []);

  return (
    <Grid container direction="column" style={{ marginTop: '24px', marginBottom: '120px' }}>
      <Formik
        initialValues={{
          newProducts: initialProducts,
        }}
        validationSchema={formSchema}
        onSubmit={submit}
        innerRef={formRef}
        enableReinitialize
      >
        {({ values, errors, touched }) => (
          <AdminForm>
            <Grid container item direction="column" style={{ gap: 16 }}>
              {showError && !!errors.genValid && (
                <Banner variant="error">
                  <Typography variant="body2" style={{ color: 'inherit' }}>
                    {errors.genValid}
                  </Typography>
                </Banner>
              )}
              <FieldArray
                name="newProducts"
                render={arrayHelpers => (
                  <Grid container direction="column" className={classes.newProducts}>
                    {values.newProducts.map((product, index, arr) => (
                      <Grid container className={classes.productContainer} direction="column" key={index}>
                        <Grid container item xs={12} justify="flex-start">
                          <Typography variant="h5">Product #{index + 1}</Typography>
                        </Grid>
                        <Grid container item direction="column" className={classes.productBox}>
                          <Grid container item direction="column" className={classes.productBoxUpper}>
                            <Grid container item>
                              <TextInput
                                label="Title"
                                name={`newProducts[${index}].title`}
                                error={showError && getIn(errors, `newProducts[${index}].title`)}
                                required
                              />
                            </Grid>
                            <Grid
                              container
                              item
                              wrap="nowrap"
                              direction={isMobile ? 'column' : 'row'}
                              style={{ gap: '16px' }}
                            >
                              <Grid container item xs={12} sm={9}>
                                {categorySelectInput(index, touched, errors)}
                              </Grid>
                              <Grid container item xs={12} sm={3}>
                                <MoneyInput
                                  label="Price"
                                  name={`newProducts[${index}].price`}
                                  error={showError && getIn(errors, `newProducts[${index}].price`)}
                                  required
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid container item direction="row" justify="flex-end" className={classes.productBoxFooter}>
                            <IconButton
                              onClick={async () => {
                                setShowError(false);

                                if (products.find(p => p.id === product.id)) {
                                  await dispatch(deleteProductAction(product.id));
                                }
                                arrayHelpers.remove(index);
                              }}
                              disabled={arr.length < 2}
                            >
                              <DeleteOutlinedIcon fill={theme.branding.v2.gray[300]} />
                            </IconButton>
                          </Grid>
                        </Grid>

                        {/* limit to three products, only show on last entry */}
                        {arr.length < 3 && index === arr.length - 1 && (
                          <Grid container item xs={12} justify="flex-end">
                            <ButtonBase
                              disableRipple
                              onClick={() => {
                                setShowError(false);
                                arrayHelpers.push({ id: nanoid(), title: '', category: null, price: 0 });
                              }}
                            >
                              <Typography variant="button" className={classes.addAnother}>
                                + Add Another
                              </Typography>
                            </ButtonBase>
                          </Grid>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                )}
              />
            </Grid>
          </AdminForm>
        )}
      </Formik>
    </Grid>
  );
};

export default AddAProduct;
