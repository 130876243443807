import React, { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ListAlt } from '@material-ui/icons';
import { BoltIcon, Typography } from '@castiron/components';
import { ProductType } from '@castiron/domain';
import { useAppDispatch } from '../../../hooks';
import { ProductModalProps } from './AddProductModal';
import { ModalChoiceIconColor } from '../../RootModal/ModalChoiceIcon';
import ModalChoice from '../../RootModal/ModalChoice';

export interface Props extends ProductModalProps {
  setProductType: (productType: ProductType) => void;
}

interface ProductTypeInfo {
  type: ProductType;
  title: string;
  icon: ReactElement;
  iconColor: ModalChoiceIconColor;
  description: string;
  bullets: string[];
}

const useStyles = makeStyles((theme: Theme) => ({
  lightGray: {
    color: theme.branding.gray[600],
  },
}));

const ProductTypeSelection: React.FC<Props> = (props: Props) => {
  const { fromChecklist, setTitle, setProductType, setStep } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const handleClick = (type: ProductType) => {
    setProductType(type);
    setStep(fromChecklist ? 'start' : 'nameAndPrice');
  };

  const productTypeInfo: ProductTypeInfo[] = [
    {
      type: 'standard',
      title: 'Instant Checkout Item',
      icon: <BoltIcon />,
      iconColor: 'blue',
      description: 'Perfect for a product that:',
      bullets: ['Has standard pricing', 'Is mostly the same for all customers', 'Doesn’t require a manual review'],
    },
    {
      type: 'custom',
      title: 'Order Form',
      icon: <ListAlt />,
      iconColor: 'green',
      description: 'Perfect for a product that requires:',
      bullets: ['An order form to customize', 'Detailed requests & invoicing', 'Significant customization'],
    },
  ];

  const description = (info: ProductTypeInfo) => (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="body2" className={classes.lightGray}>
          {info.description}
        </Typography>
      </Grid>
      <Grid item>
        {info.bullets.map((bullet, index) => (
          <Typography variant="body2" key={`${info}Bullet-${index}`} className={classes.lightGray}>
            &bull; {bullet}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );

  useEffect(() => {
    setTitle('Select a Type');
  }, []);

  return (
    <Grid container direction="column" spacing={2}>
      {productTypeInfo.map(info => (
        <Grid item key={`${info.type}ModalChoice`}>
          <ModalChoice
            icon={info.icon}
            iconColor={info.iconColor}
            title={info.title}
            description={description(info)}
            action={() => handleClick(info.type)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductTypeSelection;
