import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const CottageIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 30 27" {...props}>
    <g>
      <path d="M14.9997 2.99992L6.99967 9.10658V6.99992H4.33301V11.1466L0.333008 14.1999L1.94634 16.3199L4.33301 14.4933V26.9999H25.6663V14.4933L28.053 16.3066L29.6663 14.1999L14.9997 2.99992ZM22.9997 24.3333H16.333V18.9999H13.6663V24.3333H6.99967V12.4666L14.9997 6.35992L22.9997 12.4666V24.3333ZM12.333 0.333252C12.333 2.54659 10.5463 4.33325 8.33301 4.33325C7.59967 4.33325 6.99967 4.93325 6.99967 5.66658H4.33301C4.33301 3.45325 6.11967 1.66659 8.33301 1.66659C9.06634 1.66659 9.66634 1.06659 9.66634 0.333252H12.333Z" />
    </g>
  </SvgIcon>
);
