export type TemplateDisplay = {
  icon: string;
  iconBackgroundColor: string;
  name: string;
  subheader: string;
  route: string;
  category: string;
  event?: string;
  eventTag?: string;
  pillText?: string;
};

export const getActiveCategories = (templates: TemplateDisplay[], categoriesMap) => {
  const activeCategories = templates.map(t => t.category);

  const displayCategories = categoriesMap.filter(cat => activeCategories.includes(cat.name));

  return displayCategories;
};
