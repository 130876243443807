import React, { ReactNode } from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import InputWrapper, { BaseInputProps } from '../InputWrapper';

export type SelectOption = {
  display: string | ReactNode;
  value: any;
};

interface Props extends BaseInputProps {
  name: string;
  options: SelectOption[];
  autoWidth?: boolean;
  displayEmpty?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  disabled: {
    textAlign: 'left',
    backgroundColor: theme.branding.v2.gray[50],
    borderRadius: 12,
  },
}));

const SelectInput: React.FC<Props> = (props: Props) => {
  const { name, options, autoWidth, displayEmpty, disabled } = props;
  const { values, setFieldValue } = useFormikContext();
  const classes = useStyles();

  return (
    <InputWrapper {...props}>
      <Select
        name={name}
        variant="outlined"
        autoWidth={autoWidth}
        displayEmpty={displayEmpty}
        onChange={event => setFieldValue(name, event.target.value)}
        value={_.get(values, name)}
        disabled={disabled}
        inputProps={{ classes: { disabled: classes.disabled } }}
      >
        {options.map(option => (
          <MenuItem value={option.value} key={`selectOption${option.value}`}>
            {option.display}
          </MenuItem>
        ))}
      </Select>
    </InputWrapper>
  );
};

export default SelectInput;
