import React, { useState } from 'react';
import { Card, Checkbox, TextInput, EmailInput, PhoneInput } from '@castiron/components';
import { makeStyles } from '@material-ui/core/styles';
import { useAppSelector } from '../../../hooks';

const useStyles = makeStyles(() => ({
  checkboxContainer: {
    marginTop: 24,
  },
}));

const CustomerDetailsPreview: React.FC = () => {
  const classes = useStyles();

  const [isChecked, setIsChecked] = useState<boolean>(true);

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  return (
    <Card noMargin title="Your Details">
      <TextInput
        name="fullName"
        label="Full Name"
        required
      />
      <EmailInput 
        name="email"
        label="Email Address"
        required
      />
      <PhoneInput
        name="mobileNumber"
        label="Phone Number"
        required
      />
      <Checkbox
        checked={isChecked}
        checkboxClass={classes.checkboxContainer}
        label={
          <>
            Yes, please keep me posted on the latest product and store updates from <strong>{shop?.businessName}</strong>
          </>
        } 
        onChange={() => setIsChecked(!isChecked)}
      />
    </Card>
  );
};

export default CustomerDetailsPreview;