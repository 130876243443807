import { makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import React, {ReactNode, useEffect, useState} from 'react';

type Props = {
  className?: string;
  errors: any;
  children?: ReactNode;
};

const useStyles = makeStyles((theme: Theme) => ({
  errors: {
    color: theme.branding.error,
    marginTop: 8,
  },
  success: {
    color: theme.palette.success.dark,
    marginTop: 8,
  },
}));

const FormErrorList: React.FC<Props> = (props: Props) => {
  const { className, errors } = props;
  const [formErrors, setFormErrors] = useState('');
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    let tempStr = '';

    Object.keys(errors).forEach(err => {
      switch (err) {
        case 'businessName':
          tempStr += 'Store name, ';
          break;
        case 'websiteUrl':
          tempStr += 'Store URL, ';
          break;
        default:
          const modifiedErrorName = err.replace(/([A-Z])/g, ' $1').replace(/^./, function(str) {
            return str.toUpperCase();
          });
          tempStr += `${modifiedErrorName}, `;
          break;
      }
    });
    tempStr = tempStr.replace(/,\s*$/, '');
    setFormErrors(tempStr);
  }, [errors]);

  return (
    <>
      {formErrors ? (
        <Typography className={`${classes.errors} ${className && className}`}>
          Please fill out all required information: {formErrors}.
        </Typography>
      ) : (
        <Typography className={`${classes.success}  ${className && className}`}>
          Success, all required information is present.
        </Typography>
      )}
    </>
  );
};

export default FormErrorList;
