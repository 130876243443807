import React from 'react';
import { useHistory } from 'react-router';
import { ButtonBase, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ProductPageContext } from '@castiron/domain';

type Props = {
  productId: string;
  context: ProductPageContext;
};

const useStyles = makeStyles((theme: Theme) => ({
  buttonBase: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingRight: 8,
    '&:hover': {
      backgroundColor: `${theme.branding.gray[600]}4D`,
    },
  },
  menuLabel: {
    marginLeft: 8,
    fontWeight: 600,
    fontSize: 14,
  },
}));

const OrganizeProductsActionsMenu: React.FC<Props> = (props: Props) => {
  const { productId, context } = props;
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <ButtonBase
        className={classes.buttonBase}
        onClick={() => history.push(`/${context || 'products'}/edit/${productId}`)}
      >
        <Typography className={classes.menuLabel}>View Product Details</Typography>
      </ButtonBase>
    </>
  );
};

export default OrganizeProductsActionsMenu;
