import firebase from 'firebase/compat';
import _firestore from '@google-cloud/firestore';
import { BaseDocument, FieldFunctions, BaseRepository } from '../base/repository';
import { OrderType } from '../order';

export type FeatureName =
  | 'admin.analytics'
  | 'admin.calendar'
  | 'admin.calendar.availability'
  | 'admin.community' //legacy
  | 'admin.contacts'
  | 'admin.coupons'
  | 'admin.marketing'
  | 'admin.messaging.productAnnouncement'
  | 'admin.orders'
  | 'admin.orders.actions.multipleFulfillOrders'
  | 'admin.orders.actions.print'
  | 'admin.orders.reports.soldProducts'
  | 'admin.presales'
  | 'admin.products'
  | 'admin.products.schedule'
  | 'admin.products.variations.photo'
  | 'admin.productTemplates'
  | 'admin.quotes'
  | 'admin.reports'
  | 'admin.reports.tax'
  | 'admin.seo'
  | 'admin.settings'
  | 'admin.subscriptions'
  | 'admin.support'
  | 'shop.customDomain'
  | 'shop.freeDomain'
  | 'shop.ecommerce'
  | 'shop.gallery'
  | 'shop.irl.app'
  | 'shop.subpages'
  | 'shop.subpages.custom'
  | 'shop.themes';

export type TakeRateLevelType = OrderType | 'all';

export interface TakeRateLevel {
  type: TakeRateLevelType;
  orderTotalMinimum?: number;
  castironTakeRate: number;
}

export interface Tier extends BaseDocument<Tier> {
  name: string;
  castironTakeRate: number;
  takeRateLevels?: TakeRateLevel[];
  payoutFrequency: 'daily' | 'weekly' | 'monthly';
  features: FeatureName[];
  version: number;
}

export class TierRepository extends BaseRepository<Tier> {
  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, fieldFunctions?: FieldFunctions) {
    super(firestore, 'tiers', fieldFunctions);
  }
}
