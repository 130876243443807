import * as yup from 'yup';

export const couponSchema = yup
  .object()
  .shape({
    shopId: yup.string().required(),
    code: yup.string().required(),
    discount: yup
      .object({
        type: yup
          .string()
          .oneOf(['amount', 'percent'])
          .required(),
        value: yup.number().required(),
      })
      .required(),
    duration: yup
      .object({
        startDate: yup.number().required(),
        endDate: yup.number().nullable(),
      })
      .required(),
    metrics: yup
      .object({
        totalRevenue: yup.number().required(),
        totalUses: yup.number().required(),
      })
      .nullable(),
    minimum: yup
      .object({
        type: yup
          .string()
          .oneOf(['orderValue', 'orderQuantity'])
          .required(),
        value: yup.number().required(),
      })
      .nullable(),
    maximumPerCustomer: yup.number().nullable(),
  })
  .default(undefined);
