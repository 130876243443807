import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { ButtonV2 as Button, Typography } from '@castiron/components';
import { useFormikContext } from 'formik';
import { FormValues } from '.';
import { CloseOutlined, InfoOutlined } from '@material-ui/icons';

interface Props {
  open: boolean;
  close: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    padding: '24px',
  },
  image: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    borderRadius: '16px',
  },
  imageContainer: {
    height: '172px',
    width: '172px',
    borderRadius: '16px',
  },
}));

const RearrangeModal: React.FC<Props> = (props: Props) => {
  const { open, close } = props;
  const classes = useStyles();
  const theme = useTheme();

  const { values, setFieldValue } = useFormikContext<FormValues>();

  const [localOrdering, setLocalOrdering] = useState(values.images);

  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSave = () => {
    setFieldValue('images', localOrdering);
    close();
  };

  useEffect(() => {
    /* need to update this according when images are added deleted on hosting page */
    setLocalOrdering(values.images);
  }, [values]);

  return (
    <Dialog open={open} onClose={close} fullScreen={fullscreen} maxWidth="lg">
      <DialogTitle>
        <Grid container justify="space-between">
          <Grid item>
            <Grid container>
              <Grid item>
                <Typography variant="h3">Rearrange Photos</Typography>
              </Grid>
              <Grid item>
                <InfoOutlined />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton onClick={close}>
              <CloseOutlined />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.dialogContent}>
          <Grid container spacing={2} justify="space-evenly">
            {localOrdering.map(image => (
              <Grid item key={`rearrangeImage${image.id}`}>
                <Box className={classes.imageContainer}>
                  <img src={image.mediumVersion?.downloadUrl || image.downloadUrl} className={classes.image} />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={close}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RearrangeModal;
