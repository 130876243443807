import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import InputWrapper, { BaseInputProps } from '../InputWrapper';

interface Props extends BaseInputProps {
  name: string;
  positiveOnly?: boolean;
};

const allPattern = /^-?[0-9]+$/;
const positivePattern = /^[0-9]+$/;

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    margin: 0,
    '& > input::-webkit-outer-spin-button': {
      appearance: 'textfield',
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& > input::-webkit-inner-spin-button': {
      appearance: 'textfield',
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& > input[type="number"]': {
      appearance: 'textfield',
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      margin: 0,

      '&:hover::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
        appearance: 'none',
        margin: 0,
      },
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      margin: 0,
    },
    '&:hover::-webkitinner-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      margin: 0,
    },
    '&:hover::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      margin: 0,
    },
  },
  inputError: {
    '& div': {
      '& fieldset': {
        borderColor: theme.palette.error.main,
      },
    },
  },
}));

const IntegerInput: React.FC<Props> = (props: Props) => {
  const { name, error, positiveOnly } = props;
  const classes = useStyles();

  const { values, setFieldValue } = useFormikContext();
  const fieldValue = _.get(values, name);

  const [localValue, setLocalValue] = useState(`${fieldValue}`);

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const inputValue = event.target.value;
    const pattern = positiveOnly ? positivePattern : allPattern;
    if (!positiveOnly && inputValue === '-') {
      setLocalValue(inputValue);
      setFieldValue(name, 0);
    } else if (pattern.test(inputValue)) {
      setLocalValue(inputValue);
      setFieldValue(name, parseInt(inputValue));
    } else if (inputValue === '') {
      setLocalValue(inputValue);
      setFieldValue(name, 0);
    }
  };

  const onFocus = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    event.target.select();
  };

  return (
    <InputWrapper {...props}>
      <TextField
        name={name}
        value={localValue}
        inputProps={{ inputMode: 'numeric', pattern: positiveOnly ? '[0-9]+' : '-[0-9]+' }}
        variant='outlined'
        onChange={onChange}
        onFocus={onFocus}
        className={clsx(classes.text, error && classes.inputError)}
      />
    </InputWrapper>
  );
};

export default IntegerInput;