import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles, Theme } from '@material-ui/core/styles';
import { InfoOutlined, ListAlt } from "@material-ui/icons";
import { ProductType } from "@castiron/domain";
import clsx from 'clsx';
import { BoltIcon } from '../Icons/BoltIcon';
import Tooltip from '../Tooltip';
import Typography from '../Typography';

interface Props {
  type: ProductType;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'inline-block',
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '100px',
    padding: '12px 8px',
  },
  customIcon: {
    color: theme.branding.green.primary,
  },
  gray: {
    color: theme.branding.gray[700],
  },
  icon: {
    height: '24px',
    width: '24px',
  },
  leftMargin: {
    marginLeft: '8px',
  },
  standardIcon: {
    color: theme.branding.blue.primary,
  },
}));

const ProductTypePill: React.FC<Props> = (props: Props) => {
  const { type } = props;
  const classes = useStyles();

  let content;
  let icon;
  let tooltip;
  switch (type) {
    case 'standard':
      content = 'Instant Checkout';
      icon = <BoltIcon className={clsx([classes.icon, classes.standardIcon])} />;
      tooltip = 'Instant Checkout templates are ideal for products that have standard pricing and are mostly the same for all customers.';
      break;
    case 'custom':
    default:
      content = 'Order Form';
      icon = <ListAlt className={clsx([classes.icon, classes.customIcon])} />;
      tooltip = 'Order Forms are perfect for products that require detailed requests, are often unique to a customer, and require invoicing.';
      break;
  }

  return (
    <Tooltip title={tooltip}>
      <Box className={classes.container}>
        {icon}
        <Typography component='span' variant="body2" className={clsx([classes.gray, classes.leftMargin])}>{content}</Typography>
        <InfoOutlined className={clsx([classes.icon, classes.gray, classes.leftMargin])} />
      </Box>
    </Tooltip>
  );
};

export default ProductTypePill;