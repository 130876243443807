import { Banner, ButtonV2, Typography } from '@castiron/components';
import { Presale, getProductStatus } from '@castiron/domain';
import { defaultTimeZone, hasSchedule, isset, removeEmpty } from '@castiron/utils';
import { FormHelperText, Grid, useMediaQuery } from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { Formik, FormikProps } from 'formik';
import _ from 'lodash';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { presaleRepository, shopRepository } from '../../../domain';
import { getService } from '../../../firebase';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { openModal } from '../../../store/reducers/modalConductor';
import { getProductsAction } from '../../../store/reducers/products';
import AdminForm from '../../AdminForm';
import { LayoutPageProps } from '../../Layout';
import HeaderTabs from '../../Layout/Header/HeaderTabs';
import Spinner from '../../Spinner';
import UnsavedChangesPrompt from '../../UnsavedChangesPrompt.tsx';
import PresaleActionsMenu from '../PresaleActionsMenu';
import PresaleFulfillments from './PresaleFulfillments';
import PresaleGeneral from './PresaleGeneral';
import PresaleProducts from './PresaleProducts';

const getPresaleHandler = getService('presales', 'getpresalev2');

interface Params {
  id?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: 24,
    [theme.breakpoints.down('sm')]: {
      padding: 24,
    },
    gap: 16,
  },
}));

const EditPresale: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;

  const { id: presaleId } = useParams<Params>();
  const isEditing = isset(presaleId);

  const classes = useStyles();
  const theme = useTheme();
  const formikRef = useRef<FormikProps<any>>();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { fulfillments, isProductsLoading, products, shop } = useAppSelector(state => ({
    fulfillments: state.shops.fulfillments,
    isProductsLoading: state.products.loading,
    products: state.products.products,
    shop: state.shops.shop,
  }));

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [presale, setPresale] = useState<Presale>(null);
  const validProducts = products?.filter(
    product =>
      ['active', 'inactive', 'scheduled'].includes(getProductStatus(product, shop?.config?.timeZone)) &&
      product.type === 'standard',
  );
  const validFulfillments = fulfillments?.filter(fulfillment => ['active', 'inactive'].includes(fulfillment?.status));

  const presalesSchema = yup.object().shape({
    title: yup.string().when('status', {
      is: val => val === 'inactive' && !hasSchedule(formikRef?.current?.values),
      then: schema => schema,
      otherwise: schema => schema.required('Please enter a title'),
    }),
    description: yup.string(),
    status: yup.string().oneOf(['active', 'inactive', 'scheduled', 'deleted', 'hidden', 'archived']),
    fulfillmentIds: yup
      .array()
      .of(yup.string())
      .when('status', {
        is: val => val === 'inactive' && !hasSchedule(formikRef?.current?.values),
        then: schema => schema,
        otherwise: schema =>
          schema
            .required('Add at least one fulfilment to this presale.')
            .min(1, 'Add at least one fulfillment to this presale.'),
      }),
    productIds: yup
      .array()
      .of(yup.string())
      .when('status', {
        is: val => val === 'inactive' && !hasSchedule(formikRef?.current?.values),
        then: schema => schema,
        otherwise: schema =>
          schema
            .required('Add at least one product to this presale.')
            .min(1, 'Add at least one product to this presale.'),
      }),
    images: yup.array().of(yup.object()),
    schedule: yup
      .object({
        startTime: yup.number().nullable(),
        endTime: yup
          .number()
          .nullable()
          .moreThan(yup.ref('startTime'), 'End time must be after start time'),
      })
      .nullable(),
    isFeatured: yup.boolean(),
    showLowInventoryCount: yup.boolean(),
  });

  const initalValues = {
    title: presale?.title || '',
    description: presale?.description || '',
    status: presale?.status || 'active',
    fulfillmentIds: presale?.fulfillmentIds || [],
    productIds: presale?.productIds || [],
    images: presale?.images || [],
    schedule: presale?.schedule || {},
    isFeatured: !!presale?.isFeatured,
    showLowInventoryCount: !!presale?.showLowInventoryCount,
  };

  useEffect(() => {
    const getProducts = async () => {
      dispatch(getProductsAction(shop.id));
    };

    if (shop?.id) getProducts();
  }, [shop]);

  const getPresale = async () => {
    if (isEditing) {
      const presale = await getPresaleHandler({ id: presaleId });
      if (presale?.status === 'deleted' || !presale) {
        setIsLoading(false);
        history.push('/presales/add');
      } else {
        setIsLoading(false);
        setPresale(presale);
      }
    } else {
      setIsLoading(false);
      history.push('/presales/add');
    }
  };

  useEffect(() => {
    getPresale();
  }, []);

  useEffect(() => {
    setPageTitle(`${isEditing ? 'Edit' : 'Create'} Presale`);
    setBackLocation(true);
    setHeaderCTAs([]);
    setFooterCTAs([
      isEditing && <PresaleActionsMenu presale={presale} variant="deleteButton" />,
      <ButtonV2
        variant="outlined"
        onClick={() => history.push('/presales')}
        style={{ marginRight: 8 }}
        disabled={formikRef?.current?.isSubmitting}
      >
        Discard
      </ButtonV2>,
      <ButtonV2
        disabled={formikRef?.current?.isSubmitting}
        variant="contained"
        onClick={async () => {
          if (!formikRef.current.isSubmitting) {
            await formikRef.current.validateForm();
            if (!formikRef.current.isValid) {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
              });
              console.log({ errors: formikRef.current.errors });
            }
            formikRef.current.submitForm();
          }
        }}
      >
        Save
      </ButtonV2>,
    ]);

    return () => {
      setBackLocation(false);
      setFooterCTAs([]);
      setPageTitle('');
    };
  }, [presale]);

  const getProductErrorBox = (errors, submitCount) => {
    if (_.isEmpty(errors) || submitCount === 0) return;
    const errorMap = {
      fulfillmentIds: 'at least one fulfillment',
      productIds: 'at least one product',
      schedule: 'end date, end time',
      title: 'title',
    };
    const errorText = `Please fill out all required information: ${Object.keys(errors)
      .map(error => errorMap[error])
      .join(', ')}`;
    return (
      <Banner variant="error">
        <Typography variant="body2" style={{ color: 'inherit' }}>
          {errorText}
        </Typography>
      </Banner>
    );
  };

  const scheduledPresaleInfoBanner = (
    <Banner variant="info-blue">
      <Typography variant="body2" style={{ color: 'inherit' }}>
        Selected products and fulfillments will be available during this schedule regardless of product and fulfillment
        status.
      </Typography>
    </Banner>
  );

  const handleSubmit = async values => {
    try {
      if (hasSchedule(values)) {
        /* we are saving dates, let's check and see if a timezone is set in the config */
        if (!shop.config?.timeZone) {
          /* if not, set one */
          await shopRepository.updateProps(shop.id, {
            config: {
              ...shop.config,
              timeZone: defaultTimeZone,
            },
          });
        }
      }

      const newPresale: Presale = removeEmpty({
        title: values?.title,
        description: values?.description,
        status: values?.status,
        fulfillmentIds: values?.fulfillmentIds,
        productIds: values?.productIds,
        isFeatured: values?.isFeatured,
        showLowInventoryCount: values?.showLowInventoryCount,
        shopId: shop.id,
      }) as Presale;
      // Want to pass a schedule if it's being removed, i.e. is {}
      newPresale.schedule = values?.schedule;

      if (isEditing) {
        await presaleRepository.updateProps(presaleId, newPresale);
      } else {
        await presaleRepository.create(newPresale);
      }

      if (!shop?.shopSubpageData?.presales?.enabled) {
        await shopRepository.updateProps(shop.id, {
          'shopSubpageData.presales.enabled': true,
          'shopSubpageData.presales.headline': shop?.shopSubpageData?.presales?.headline || 'Presales',
          'shopSubpageData.presales.description': shop?.shopSubpageData?.presales?.description || '',
        });
      }

      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT',
          modalProps: {
            show: true,
            celebrate: true,
            content: (
              <>
                Presale <strong>{presale?.title}</strong> was {isEditing ? 'updated' : 'created'}
              </>
            ),
          },
        }),
      );
      history.push('/presales');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Helmet>
        <title>{isEditing ? 'Edit' : 'Add'} Presale | Castiron</title>
      </Helmet>
      {(isEditing && !presale) || isLoading || isProductsLoading ? (
        <Spinner size="fullscreen" show={true} />
      ) : (
        <Formik
          enableReinitialize
          validateOnMount
          validateOnBlur
          validateOnChange
          onSubmit={handleSubmit}
          validationSchema={presalesSchema}
          initialValues={initalValues}
          innerRef={formikRef}
        >
          {({ dirty, errors, isSubmitting, submitCount, values }): ReactElement => {
            return (
              <AdminForm style={{ width: '100%' }}>
                {!isSubmitting && <UnsavedChangesPrompt when={dirty} />}
                <HeaderTabs
                  initialTabValue="General"
                  tabs={[
                    {
                      value: 'General',
                      content: (
                        <Grid container direction="column" wrap="nowrap" className={classes.container}>
                          {getProductErrorBox(errors, submitCount)}
                          <Grid
                            container
                            direction={isMobile ? 'column' : 'row'}
                            wrap="nowrap"
                            style={{ gap: 16 }}
                            alignItems="flex-start"
                          >
                            <PresaleGeneral presaleId={presaleId} />
                          </Grid>
                        </Grid>
                      ),
                    },
                    {
                      value: 'Products',
                      content: (
                        <Grid container direction="column" wrap="nowrap" className={classes.container}>
                          {getProductErrorBox(errors, submitCount)}
                          <PresaleProducts products={validProducts} />
                          {errors.productIds && submitCount > 0 && (
                            <FormHelperText error style={{ textAlign: 'center' }}>
                              {errors.productIds}
                            </FormHelperText>
                          )}
                          {hasSchedule(values) && scheduledPresaleInfoBanner}
                        </Grid>
                      ),
                    },
                    {
                      value: 'Fulfillments',
                      content: (
                        <Grid container direction="column" wrap="nowrap" className={classes.container}>
                          {getProductErrorBox(errors, submitCount)}
                          <PresaleFulfillments fulfillments={validFulfillments} />
                          {errors.fulfillmentIds && submitCount > 0 && (
                            <FormHelperText error style={{ textAlign: 'center' }}>
                              {errors.fulfillmentIds}
                            </FormHelperText>
                          )}
                          {hasSchedule(values) && scheduledPresaleInfoBanner}
                        </Grid>
                      ),
                    },
                  ]}
                />
              </AdminForm>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default EditPresale;
