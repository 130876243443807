import { ButtonV2 } from '@castiron/components';
import { useTracking } from '@castiron/utils';
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { scheduledMessagesRepository } from '../../../domain';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeModal, openModal } from '../../../store/reducers/modalConductor';
import GeneralModal from '../../RootModal/GeneralModal';

const useStyles = makeStyles((theme: Theme) => ({
  modalPaperClass: {
    [theme.breakpoints.up('sm')]: {
      height: 'fit-content',
    },
  },
  deleteButton: {
    backgroundColor: theme.branding.v2.red[500],
    border: `1px solid ${theme.branding.v2.red[500]}`,
    color: theme.branding.v2.gray[0],
    '&:hover': {
      backgroundColor: theme.branding.v2.red[400],
    },
  },
}));

export interface Props {
  context: 'email' | 'sms';
  id: string;
  status: 'sent' | 'scheduled';
}

const DeleteScheduledSendModal: React.FC<Props> = (props: Props) => {
  const { context, id, status } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const history = useHistory();

  const { account } = useAppSelector(state => ({
    account: state.shops.account,
  }));

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleClose = () => {
    dispatch(closeModal());
  };

  const handleDeleteClick = async () => {
    try {
      setIsSubmitting(true);
      if (status === 'sent') {
        await account.updateMessageHistory(id, {
          wasSent: false,
          status: 'deleted',
        });
        trackEvent('Sent Message Deleted');
      } else {
        await scheduledMessagesRepository.updateProps(id, {
          status: 'deleted',
        });
        trackEvent('Scheduled Send Deleted');
      }
      setIsSubmitting(false);
      handleClose();
      history.push(`/marketing${context === 'sms' ? '/sms' : '/email'}`);
      history.go(0);
    } catch (err) {
      console.log(err);
      setIsSubmitting(false);
      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT',
          modalProps: {
            show: true,
            content: `Error deleting ${context === 'email' ? 'email' : 'SMS'}.`,
          },
        }),
      );
    }
  };

  return (
    <GeneralModal
      paperClass={classes.modalPaperClass}
      show={true}
      content={
        <Grid container item direction="column" wrap="nowrap" style={{ gap: '24px', textAlign: 'center' }}>
          <Typography variant="body1" style={{ fontSize: '28px' }}>
            🗑️
          </Typography>
          <Typography variant="h2">Are you sure you want to delete this item?</Typography>
          <Typography variant="body1">
            {status === 'sent'
              ? 'The item will be permanently deleted and this cannot be undone.'
              : 'The item will be permanently deleted and won’t send at its scheduled date and time.'}
          </Typography>
          <ButtonV2
            fullWidth
            variant="outlined"
            className={classes.deleteButton}
            onClick={handleDeleteClick}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Delete
          </ButtonV2>
          <ButtonV2 variant="outlined" onClick={handleClose} fullWidth>
            Cancel
          </ButtonV2>
        </Grid>
      }
    />
  );
};

export default DeleteScheduledSendModal;
