import { Message, MessageHistory, MessageType } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { ButtonBase, Theme, Typography, makeStyles, useTheme } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import { getService } from '../../../firebase';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { openModal } from '../../../store/reducers/modalConductor';
import { MarketingStatsMessage } from '../MarketingStats';

const sendPresaleAnnouncementTestService = getService('presales', 'sendpresaleannouncementtestv2');
const sendProductAnnouncementTestService = getService('products', 'sendproductannouncementtestv2');
const sendEmailAnnouncementService = getService('messaging', 'sendemailannouncement');
const sendTestEmailService = getService('messaging', 'sendtestemail');
const sendSmsAnnouncementService = getService('messaging', 'sendsmsannouncement');
const sendTestSmsService = getService('messaging', 'sendtestsms');

type Props = {
  message: MarketingStatsMessage;
  onClose: (event: React.MouseEvent<HTMLElement>) => void;
  status: 'sent' | 'scheduled';
};

const useStyles = makeStyles((theme: Theme) => ({
  buttonBase: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingRight: 8,
    '&:hover': {
      backgroundColor: `${theme.branding.gray[600]}4D`,
    },
  },
  menuLabel: {
    marginLeft: 8,
    fontWeight: 600,
    fontSize: 14,
  },
}));

const MarketingStatsActionsMenu: React.FC<Props> = (props: Props) => {
  const { message, onClose, status } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();
  const { trackEvent } = useTracking();

  const { account, isCouponsLoading, shop, storeCoupons } = useAppSelector(state => ({
    account: state.shops.account,
    isCouponsLoading: state.coupons.loading,
    shop: state.shops.shop,
    storeCoupons: state.coupons.coupons,
  }));

  const smsMessageTypes: MessageType[] = ['sms_announcement'];
  const emailMessageTypes: MessageType[] = ['presale_announcement', 'product_announcement', 'email_announcement'];
  const scheduledMessage = status === 'scheduled' && (message as Message<any>);

  const handleSendTestClick = async (event: React.MouseEvent<HTMLElement>) => {
    onClose(event);
    if (smsMessageTypes.includes(message.type) && !account?.config?.messagingPreferences?.sms?.enabled) {
      dispatch(
        openModal({
          modalType: 'SMS_NOTIFICATION_OPTIN_MODAL',
          modalProps: {
            show: true,
            context: 'marketing',
          },
        }),
      );
      return;
    }
    try {
      if (emailMessageTypes.includes(message.type)) {
        if (status === 'sent') {
          await sendTestEmailService({
            subject: message.subject,
            body: (message as MessageHistory).body,
          });
          trackEvent('Test Marketing Email Sent', {
            emailType: 'Test',
            location: 'Actions Menu',
          });
        } else if (message.type === 'presale_announcement') {
          await sendPresaleAnnouncementTestService({
            presale: scheduledMessage?.content?.presale,
            subjectLine: scheduledMessage?.content?.subjectLine,
            bodyText: scheduledMessage?.content?.bodyText,
            buttonText: scheduledMessage?.content?.buttonText,
            isTest: true,
            utmCampaign: scheduledMessage?.content?.utmCampaign,
          });
          trackEvent('Test Marketing Email Sent', {
            emailType: 'presale_announcement',
            presale: scheduledMessage?.content?.presale,
            location: 'Actions Menu',
          });
        } else if (message.type === 'product_announcement') {
          await sendProductAnnouncementTestService({
            product: scheduledMessage?.content?.product,
            subjectLine: scheduledMessage?.content?.subjectLine,
            bodyText: scheduledMessage?.content?.bodyText,
            buttonText: scheduledMessage?.content?.buttonText,
            isTest: true,
            utmCampaign: scheduledMessage?.content?.utmCampaign,
          });
          trackEvent('Test Marketing Email Sent', {
            emailType: 'product_announcement',
            product: scheduledMessage?.content?.product,
            location: 'Actions Menu',
          });
        } else if (message.type === 'email_announcement') {
          await sendEmailAnnouncementService({
            customerIds: [],
            subjectLine: scheduledMessage?.content?.subjectLine,
            bodyText: scheduledMessage?.content?.bodyText,
            buttonText: scheduledMessage?.content?.buttonText,
            isTest: true,
            utmCampaign: scheduledMessage?.content?.utmCampaign,
          });
          trackEvent('Test Marketing Email Sent', {
            emailType: 'email_announcement',
            location: 'Actions Menu',
          });
        }
      } else {
        if (status === 'sent') {
          await sendTestSmsService({
            body: (message as MessageHistory).body,
          });
          trackEvent('Test Marketing SMS Sent', {
            smsType: 'Test',
            location: 'Actions Menu',
          });
        } else {
          await sendSmsAnnouncementService({
            customerIds: [],
            isTest: true,
            itemId: scheduledMessage?.content?.itemId,
            itemType: scheduledMessage?.content?.itemType,
            text: scheduledMessage?.content?.text,
            utmCampaign: scheduledMessage?.content?.utmCampaign,
          });
          trackEvent('Test Marketing SMS Sent', {
            smsType: 'sms_annoucement',
            itemId: scheduledMessage?.content?.itemId,
            itemType: scheduledMessage?.content?.itemType,
            location: 'Actions Menu',
          });
        }
      }
      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT',
          modalProps: {
            show: true,
            celebration: true,
            content: `Test ${emailMessageTypes.includes(message.type) ? 'email' : 'SMS'} sent!`,
          },
        }),
      );
    } catch (err) {
      console.error(`Error in Send Test ${emailMessageTypes.includes(message.type) ? 'Email' : 'Text'}: `, err);
      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT',
          modalProps: {
            show: true,
            content: `Error sending test ${emailMessageTypes.includes(message.type) ? 'email' : 'SMS'}.`,
          },
        }),
      );
    }
  };

  const handleEditClick = (event: React.MouseEvent<HTMLElement>) => {
    onClose(event);
    history.push(
      `/marketing/single-send-${emailMessageTypes.includes(message.type) ? 'email' : 'sms'}/scheduled/${message.id}${(message as Message<any>)?.content?.templateType ? `?type=${(message as Message<any>)?.content?.templateType}` : ''}`,
    );
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    onClose(event);
    dispatch(
      openModal({
        modalType: 'DELETE_MARKETING_MODAL',
        modalProps: {
          id: message.id,
          status,
          context: emailMessageTypes.includes(message.type) ? 'email' : 'sms',
        },
      }),
    );
  };

  return (
    <>
      <ButtonBase onClick={handleSendTestClick} focusRipple className={classes.buttonBase} style={{ marginBottom: 16 }}>
        <Typography className={classes.menuLabel}>
          Send Test {emailMessageTypes.includes(message.type) ? 'Email' : 'SMS'}
        </Typography>
      </ButtonBase>
      {status === 'scheduled' && (
        <ButtonBase onClick={handleEditClick} focusRipple className={classes.buttonBase} style={{ marginBottom: 16 }}>
          <Typography className={classes.menuLabel}>
            {status === 'scheduled' ? 'Edit' : 'View'} {emailMessageTypes.includes(message.type) ? 'Email' : 'SMS'}
          </Typography>
        </ButtonBase>
      )}
      <ButtonBase onClick={handleDeleteClick} focusRipple className={classes.buttonBase}>
        <Typography className={classes.menuLabel} style={{ color: theme.branding.v2.red[500] }}>
          Delete
        </Typography>
      </ButtonBase>
    </>
  );
};

export default MarketingStatsActionsMenu;
