import { useSelector } from 'react-redux';
import trackEvent, {EventConfig, EventProperties, trackPage, trackUser} from '../events/trackEvent';
import _ from 'lodash';
import { UnregisterCallback } from 'history';
import { Attribution, Customer, accountToEventModel, shopToEventModel } from '@castiron/domain';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import {AttributionContext, AttributionData} from '../attribution';
import { removeEmpty } from '../removeEmpty';
export interface UnregisterHolder {
  unreg: UnregisterCallback;
}

interface ShopProperties {
  id?: string;
  websiteUrl?: string;
  businessName?: string;
  email?: string;
  tags?: string[];
  isICP?: boolean;
}

interface UserProperties {
  id?: string;
  email?: string;
  name?: string;
}

interface CartProperties {
  numItems?: number;
  total?: number;
  totalWithoutFees?: number;
}

interface CustomerProperties {
  id: string;
  email: string;
  name?: string;
  subscribed: boolean;
  hasEverSubscribed: boolean;
}

export interface DefaultEventProperties {
  attribution?: Attribution;
  shop?: ShopProperties;
  user?: UserProperties;
  cart?: CartProperties;
  experiments?: Record<string, string>;
  customer?: CustomerProperties;
}

const createTrackEvent = (defaultProps: EventProperties = {}) => {
  return (eventName: string, props: EventProperties = {}) => {
    trackEvent(eventName, _.merge(defaultProps, props));
  };
};

const createTrackPage = (defaultProps: EventProperties = {}) => {
  return (name?: string, props: EventProperties = {}) => {
    trackPage(name, _.merge(defaultProps, props));
  };
};

const createTrackUser = (storedUserId?: string, defaultProps?: EventProperties, config?: EventConfig) => {
  return (userId?: string, customer?: Partial<Customer>, props: EventProperties = {}) => {
    let userTraits = {};
    if (customer) {
      userTraits = removeEmpty({
        name: `${customer?.firstName} ${customer?.lastName}`,
        firstName: customer?.firstName,
        lastName: customer?.lastName,
        phone: customer?.mobileNumber,
        address: {
          street: customer?.addressOne && `${customer?.addressOne} ${customer?.addressTwo}`,
          city: customer?.city,
          state: customer?.state,
          postalCode: customer?.postalCode,
        },
        email: customer?.email,
        createdAt: customer?.createdAt && moment.unix(customer.createdAt).toISOString(),
      });
    }

    trackUser(userId || storedUserId, _.merge(defaultProps, props, userTraits), {
      anonymousId: config?.anonymousId
    });
  };
};

export const useTracking = () => {
  const { shop, me, cart, experiments, customer, account } = useSelector(state => ({
    //@ts-ignore
    shop: state.shops.shop,
    //@ts-ignore
    me: state.users?.me,
    //@ts-ignore
    cart: state.cart,
    //@ts-ignore
    experiments: state.experiments?.active,
    //@ts-ignore
    customer: state.customers.customer,
    //@ts-ignore
    account: state.shops.account,
  }));
  const {attribution} = useContext<AttributionData>(AttributionContext);
  const [eventQueue, setEventQueue] = useState<any[]>([]);

  const defaultProps = {
    attribution,
    experiments: Object.fromEntries(experiments.map(ex => [ex.name, ex.activeVariant.name])),
    shop: shop ? shopToEventModel(shop) : null,
    account: account ? accountToEventModel(account) : null,
    user:
      me && me.uid
        ? {
            id: me.uid,
            email: me.email,
            name: me.displayName,
          }
        : null,
    cart: cart?.hasLoaded
      ? {
          numItems: cart.products?.map(p => p.quantity).reduce((total, quantity) => total + quantity, 0),
          total: cart.totals?.total,
          totalWithoutFees: cart.totals?.totalWithoutFees,
        }
      : null,
    customer: customer
      ? {
          id: customer.id,
          email: customer.email,
          name: customer.firstName ? `${customer.firstName} ${customer.lastName}` : undefined,
          subscribed: customer.subscribed,
          hasEverSubscribed: customer.hasEverSubscribed,
        }
      : null,
  };

  useEffect(() => {
    if (eventQueue.length > 0 && attribution) {
      console.debug('Flushing event queue', eventQueue);
      eventQueue.forEach(e => {
        switch (e.type) {
          case 'track':
            trackEvent(e.name, _.merge(defaultProps, e.props), {
              anonymousId: attribution?.anonymousId
            });
            break;
          case 'page':
            trackPage(e.name, _.merge(defaultProps, e.props), {
              anonymousId: attribution?.anonymousId
            });
            break;
        }
        setEventQueue([]);
      });
    }
  }, [attribution]);

  return {
    trackEvent: (name: string, props: EventProperties = {}) => {
      if (attribution) {
        trackEvent(name, _.merge(defaultProps, props), {
          anonymousId: attribution?.anonymousId
        });
      } else {
        setEventQueue(q => [...q, { name, props, type: 'track' }]);
      }
    },
    trackPage: (name?: string, props: EventProperties = {}) => {
      if (attribution) {
        trackPage(name, _.merge(defaultProps, props), {
          anonymousId: attribution?.anonymousId
        });
      } else {
        setEventQueue(q => [...q, { name, props, type: 'page' }]);
      }
    },
    trackUser: defaultProps.user
      ? createTrackUser(defaultProps.user.id, defaultProps.user)
      : createTrackUser(null, defaultProps),

    trackClick: (clickType?: string, props: EventProperties = {}) => {
      if (attribution) {
        trackEvent('User Click', {
          ..._.merge(defaultProps, props),
          type: clickType
        });
      } else {
        setEventQueue(q => [...q, { name: 'User Click', props: {
          ...props,
          type: clickType
        }, type: 'track' }]);
      }
    }
  };
};

export default useTracking;
