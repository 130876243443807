import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { ButtonBase, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ArchiveOutlined, Link, UnarchiveOutlined } from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DuplicateIcon, RubbishBinIcon, SvgIcon } from '@castiron/components';
import { BaseProduct, ProductPageContext, ProductStatus, TicketedEvent } from '@castiron/domain';
import { defaultTimeZone, download, useTracking } from '@castiron/utils';
import PencilIcon from '../../assets/img/pencil.svg';
import { AutoAwesome } from '@castiron/components/src/Icons/AutoAwesome';
import { productRepository } from '../../domain';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { openModal } from '../../store/reducers/modalConductor';
import { deleteProductAction, duplicateProductAction, getProductsAction } from '../../store/reducers/products';
import { getService } from '../../firebase';
import { getShopLink } from '../../lib/domainUtils';
import moment from 'moment';
import Spinner from '../Spinner';
import PeopleOutlineOutlined from '@material-ui/icons/PeopleOutlineOutlined';

const exportOrdersByProductService = getService('orders', 'exportordersbyproduct', { type: 'request' });
const exportAttendeeListService = getService('products', 'exportattendeelist', { type: 'request' });

type Props = {
  product: BaseProduct;
  context?: ProductPageContext;
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  buttonBase: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingRight: 8,
    '&:hover': {
      backgroundColor: `${theme.branding.gray[600]}4D`,
    },
  },
  buttonBaseNew: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: `${theme.branding.v2.yellow[600]}`,
    paddingRight: 8,
    '&:hover': {
      backgroundColor: `${theme.branding.gray[600]}4D`,
    },
  },
  copiedSuccess: {
    width: '100%',
    height: 36,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: 14,
    color: theme.branding.gray[700],
    padding: '5px 10px',
  },
  delete: {
    color: theme.palette.error.main,
  },
  icon: {
    height: '24px',
    width: '24px',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '36px',
    width: '36px',
  },
  menuLabel: {
    marginLeft: 8,
    fontWeight: 600,
    fontSize: 14,
  },
  rubbishBinIcon: {
    height: '36px',
    width: 'auto',
    color: theme.palette.error.main,
    paddingLeft: '8px',
  },
}));

const ProductActionsMenu: React.FC<Props> = (props: Props) => {
  const { onClose, product, context } = props;
  const { id, title } = product;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { trackEvent } = useTracking();

  const [copySuccess, setCopySuccess] = useState(false);
  const [isDownloadingReport, setIsDownloadingReport] = useState<boolean>(false);

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const shopLink = getShopLink(shop);

  const handleDuplicate = useCallback(e => {
    e.stopPropagation();
    dispatch(duplicateProductAction(id)).then(res => {
      trackEvent('Product Duplicated', { product: { id: id } });
      history.push(`/${context}/edit/${(res.payload as BaseProduct).id}`, {
        fromCreate: true,
      });
    });
  }, []);

  const onDelete = useCallback(
    id => async (): Promise<void> => {
      dispatch(deleteProductAction(id)).then(res => {
        dispatch(
          openModal({
            modalType: 'SIMPLE_ALERT',
            modalProps: {
              show: true,
              celebrate: false,
              content: (
                <>
                  {context === 'order-forms' ? 'Order Form' : context === 'products' ? ' Product' : 'Event'} <strong>{title}</strong> was deleted.
                </>
              ),
            },
          }),
        );

        trackEvent('Product Deleted', { product: { id: id } });
      });
    },
    [],
  );

  const downloadAttendeeList = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setIsDownloadingReport(true);
    const timeZone = shop?.config?.timeZone || defaultTimeZone;
    const csv = await exportAttendeeListService({
      eventId: id,
      timeZone,
    });

    const ticketedEvent = (await productRepository.get(id)) as TicketedEvent;

    const filename = `${ticketedEvent?.title} Attendees ${moment
      .unix(ticketedEvent?.eventDetails?.date?.startTime)
      .tz(timeZone)
      .format('M/D/YYYY')}.csv`;

    download(filename, 'text/csv', csv);

    trackEvent('Attendees List Downloaded');

    setIsDownloadingReport(false);
  };

  const downloadReport = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    setIsDownloadingReport(true);

    const csv = await exportOrdersByProductService({
      context: 'product',
      id,
      timeZone: shop?.config?.timeZone || defaultTimeZone,
    });

    const date = moment().format('MMDDYYYY');
    const filename = `${shop.websiteUrl}-orders-by-product-${date}.csv`;

    download(filename, 'text/csv', csv);

    trackEvent('Orders by Product Report Downloaded');

    setIsDownloadingReport(false);
  };

  const changeStatus = async (event: React.MouseEvent<HTMLElement>, newStatus: ProductStatus) => {
    event.stopPropagation();
    await productRepository.updateProps(product.id, { status: newStatus, schedule: {} });
    await dispatch(getProductsAction(shop.id));
    onClose(event);
  };
  const onArchiveClick = async (event: React.MouseEvent<HTMLElement>) => await changeStatus(event, 'archived');
  const onUnarchiveClick = async (event: React.MouseEvent<HTMLElement>) => await changeStatus(event, 'active');

  const onDeleteClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    //technically not deleted unless they respond to modal
    dispatch(
      openModal({
        modalType: 'DELETE_MODAL',
        modalProps: {
          show: true,
          onDelete: onDelete(id),
        },
      }),
    );
    onClose(event);
  }, []);

  const handleShare = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    dispatch(
      openModal({
        modalType: 'PRODUCT_SOCIAL_SHARE_MODAL',
        modalProps: {
          show: true,
          product: product,
        },
      }),
    );
    onClose(event);
  }, []);

  const onEditClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    history.push(`/${context}/edit/${id}`);
    onClose(event);
  }, []);

  const handleCopyLink = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setCopySuccess(true);
    navigator.clipboard.writeText(`${shopLink}/product/${id}`);
    trackEvent('Product Link Copied', { product: { id: id } });
    setTimeout(() => {
      onClose(event);
    }, 1200);

    setTimeout(() => {
      setCopySuccess(false);
    }, 1500);
  };

  return (
    <>
      <Spinner show={isDownloadingReport} size={'fullscreen'} label="Gathering your data…" />
      <ButtonBase disabled={copySuccess} onClick={onEditClick} focusRipple className={classes.buttonBase}>
        <SvgIcon className={classes.iconContainer}>
          <PencilIcon />
        </SvgIcon>
        <Typography className={classes.menuLabel}>Edit</Typography>
      </ButtonBase>
      <ButtonBase disabled={copySuccess} focusRipple className={classes.buttonBase} onClick={handleDuplicate}>
        <div className={classes.iconContainer}>
          <DuplicateIcon className={classes.icon} />
        </div>
        <Typography className={classes.menuLabel}>Duplicate</Typography>
      </ButtonBase>
      <ButtonBase disabled={copySuccess} focusRipple className={classes.buttonBaseNew} onClick={handleShare}>
        <div className={classes.iconContainer}>
          <AutoAwesome className={classes.icon} />
        </div>
        <Typography className={classes.menuLabel}>New AI Social Share</Typography>
      </ButtonBase>
      {copySuccess ? (
        <div className={classes.copiedSuccess}>Link copied!</div>
      ) : (
        <ButtonBase focusRipple className={classes.buttonBase} onClick={handleCopyLink}>
          <div className={classes.iconContainer}>
            <Link className={classes.icon} />
          </div>
          <Typography className={classes.menuLabel}>Copy link</Typography>
        </ButtonBase>
      )}
      {context === 'events' && (
        <ButtonBase focusRipple className={classes.buttonBase} onClick={downloadAttendeeList}>
        <div className={classes.iconContainer}>
          <PeopleOutlineOutlined className={classes.icon} />
        </div>
        <Typography className={classes.menuLabel}>Download Attendee List</Typography>
      </ButtonBase>
      )}
      <ButtonBase focusRipple className={classes.buttonBase} onClick={downloadReport}>
        <div className={classes.iconContainer}>
          <GetAppIcon className={classes.icon} />
        </div>
        <Typography className={classes.menuLabel}>Download Report</Typography>
      </ButtonBase>
      {product.status === 'archived' ? (
        <ButtonBase disabled={copySuccess} focusRipple onClick={onUnarchiveClick} className={classes.buttonBase}>
          <div className={classes.iconContainer}>
            <UnarchiveOutlined className={classes.icon} />
          </div>
          <Typography className={classes.menuLabel}>Unarchive</Typography>
        </ButtonBase>
      ) : (
        <ButtonBase disabled={copySuccess} focusRipple onClick={onArchiveClick} className={classes.buttonBase}>
          <div className={classes.iconContainer}>
            <ArchiveOutlined className={classes.icon} />
          </div>
          <Typography className={classes.menuLabel}>Archive</Typography>
        </ButtonBase>
      )}
      <ButtonBase disabled={copySuccess} focusRipple onClick={onDeleteClick} className={classes.buttonBase}>
        <div className={classes.iconContainer}>
          <RubbishBinIcon className={classes.rubbishBinIcon} />
        </div>
        <Typography className={`${classes.menuLabel} ${classes.delete}`}>Delete</Typography>
      </ButtonBase>
    </>
  );
};

export default ProductActionsMenu;
