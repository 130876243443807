import { nanoid } from 'nanoid';
import { Asset, BaseProduct, Shop } from '@castiron/domain';

export const convertToAsset = (objUrl, type: string, name: string, shop: Shop, contentType: string) => {
  const id = nanoid();
  const asset: Asset = {
    id,
    downloadUrl: objUrl,
    metadata: {
      id,
      assetType: type,
      originalFilename: name || id,
      contentType: contentType || 'image/jpeg',
    },
    options: {
      folder: `user/${shop.id}`,
    },
    shopId: shop.id,
  };
  return asset;
};

export const productHasImages = (product: BaseProduct): boolean => {
  return !!(product?.images?.length > 0 || product?.imageObj?.downloadUrl);
};
