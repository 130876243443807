import React from 'react';
import { ButtonV2 } from '@castiron/components';
import { CircularProgress, Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export interface StickyFooterProps {
  isFinalStep?: boolean;
  onNextClick?: () => void;
  onBackClick?: () => void;
  isUnpaidPage?: boolean;
}

interface Props extends StickyFooterProps {
  step: number;
  isLoading?: boolean;
  isInternational?: boolean;
  nextStep: () => void;
  prevStep: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: '16px 0px',
  },
  finalStepButton: {
    width: "100%",
    maxWidth: "600px",
  },
  leftButton: {
    marginRight: 4,
  },
  rightButton: {
    marginLeft: 4,
  },
  spinner: {
    color: theme.branding.v2.gray[0],
  },
}));

const OnboardingFooter: React.FC<Props> = (props: Props) => {
  const {
    step,
    isFinalStep,
    isLoading,
    onNextClick,
    nextStep,
    onBackClick,
    prevStep,
    isInternational,
    isUnpaidPage = false,
  } = props;
  const classes = useStyles();

  const handlePrevStep = () => {
    if (!!onBackClick) {
      onBackClick();
    }
    window.scrollTo(0, 0);
    prevStep();
  };

  const handleNextStep = () => {
    window.scrollTo(0, 0);
    onNextClick();
  };

  return (
    <Grid container justify="space-between" alignItems="center" wrap="nowrap" className={classes.container}>
      <Grid
        container
        item
        xs={12}
        justify={isFinalStep ? 'center' : isUnpaidPage ? 'space-between' : 'flex-end'}
        wrap="nowrap"
      >
        {isUnpaidPage && !isFinalStep ? (
          <ButtonV2 variant="outlined" disabled={isLoading} onClick={handlePrevStep}>
            Skip for Now
          </ButtonV2>
        ) : (
          step !== 1 &&
          !isFinalStep && (
            <ButtonV2 className={classes.leftButton} variant="outlined" onClick={handlePrevStep} disabled={isLoading}>
              Back
            </ButtonV2>
          )
        )}
        <ButtonV2
          type="submit"
          className={isFinalStep ? classes.finalStepButton : classes.rightButton}
          variant="contained"
          color="primary"
          onClick={handleNextStep}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress className={classes.spinner} size={24} />
          ) : isUnpaidPage && isFinalStep ? (
            'Confirm & Pay'
          ) : isUnpaidPage ? (
            'Subscribe & Go To Admin'
          ) : isFinalStep ? (
            'Start Trial'
          ) : isInternational ? (
            'Back to Castiron'
          ) : (
            'Continue'
          )}
        </ButtonV2>
      </Grid>
    </Grid>
  );
};

export default OnboardingFooter;
