import firebase from 'firebase/compat/app';
import _firestore from '@google-cloud/firestore';
import _ from 'lodash';
import moment from 'moment';
import { removeEmpty } from '../_lib/removeEmpty';
import { ProductTemplate, ProductTemplateMetrics } from './model';
import { BaseRepository, FieldFunctions } from '../base/repository';

export class ProductTemplateRepository extends BaseRepository<ProductTemplate> {
  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, fieldFunctions?: FieldFunctions) {
    super(firestore, 'product_templates', fieldFunctions);
  }

  public async findActiveByCategory(category: string): Promise<ProductTemplate[]> {
    const now = moment().unix();
    const results = await this.find({
      where: [
        { field: 'category', operator: '==', value: category },
        { field: 'activeTo', operator: '>=', value: now },
      ],
    });
    /* I don't love doing this all post query, but the result sets here should be pretty small */
    return _.sortBy(
      results.filter(template => template.activeFrom <= now),
      template => template.order,
    );
  }

  public async findByPersona(persona: string): Promise<ProductTemplate[]> {
    const now = moment().unix();
    const results = await this.find({
      where: [{ field: 'personas', operator: 'array-contains', value: persona }],
    });
    /* I don't love doing this all post query, but the result sets here should be pretty small */
    return _.sortBy(
      results.filter(template => template.activeFrom <= now),
      template => template.order,
    );
  }

  public async updateMetrics(id: string, metrics: ProductTemplateMetrics) {
    return this.updateProps(
      id,
      removeEmpty({
        'metrics.numTimesUsed': metrics.numTimesUsed && this.fieldFunctions.increment(metrics.numTimesUsed),
        'metrics.numSales': metrics.numSales && this.fieldFunctions.increment(metrics.numSales),
        'metrics.totalRevenue': metrics.totalRevenue && this.fieldFunctions.increment(metrics.totalRevenue),
      }),
    );
  }
}
