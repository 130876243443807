import * as yup from 'yup';
import { phoneRegExp } from '../base/model';
import { Customer } from './index';

export const customerDetailsSchema = yup.object().shape({
  fullName: yup.string().required('Please enter your full name'),
  email: yup
    .string()
    .email('Invalid email')
    .required('Please enter your email'),
  mobileNumber: yup
    .string()
    .matches(phoneRegExp, 'Must be a valid 10-digit phone number format')
    .required('Please enter your phone number'),
  smsNotificationOptIn: yup.boolean(),
  subscribed: yup.boolean(),
});

export const eventAttendeeSchema = yup
  .object()
  .shape({
    fullName: yup.string().required('Please enter attendee full name'),
    email: yup.string().when('mobileNumber', ([mobileNumber], schema) => {
      return mobileNumber?.length === 0
        ? schema.email('Must be a valid email').required('Please enter attendee email')
        : schema.email('Must be a valid email').nullable();
    }),
    mobileNumber: yup.string().matches(phoneRegExp, 'Must be a valid 10-digit phone number format'),
    smsNotificationOptIn: yup.boolean(),
    subscribed: yup.boolean(),
  })
  .default(undefined);

export const customerSchema = yup
  .object()
  .shape({
    shopId: yup.string().required(),
    email: yup.string().nullable(),
    firstName: yup.string().nullable(),
    lastName: yup.string().nullable(),
    address: yup.string().nullable(),
    addressOne: yup.string().nullable(),
    addressTwo: yup.string().nullable(),
    city: yup.string().nullable(),
    state: yup.string().nullable(),
    postalCode: yup.string().nullable(),
    country: yup.string().nullable(),
    instructions: yup.string().nullable(),
    subscribed: yup.boolean().nullable(),
    starred: yup.boolean().nullable(),
    notes: yup.string().nullable(),
    placeToReach: yup
      .string()
      .oneOf(['phone', 'email', 'no-preference'])
      .nullable(),
    hasEverSubscribed: yup.boolean().nullable(),
    subscriberOrigination: yup.string().nullable(),
    dateLastSubscribed: yup.number().nullable(),
    dateLastUnsubscribed: yup.number().nullable(),
    metrics: yup
      .object({
        totalOrders: yup.number().nullable(),
        totalRevenue: yup.number().nullable(),
        dateLastPurchased: yup.number().nullable(),
        totalTips: yup.number().nullable(),
      })
      .default(undefined)
      .nullable(),
    subscriptionInviteSent: yup.boolean().nullable(),
    messagingPreferences: yup
      .object({
        sms: yup
          .object({
            enabled: yup.boolean(),
            smsPhoneNumber: yup.string().nullable(),
            verificationCode: yup.string().nullable(),
          })
          .default(undefined)
          .nullable(),
      })
      .default(undefined)
      .nullable(),

    lastCommunications: yup
      .object({
        artisanUploadSubscriberConfirmation: yup.number().nullable(),
        directMessageArtisanToCustomer: yup.number().nullable(),
        directMessageCustomerToArtisan: yup.number().nullable(),
        paymentReminder: yup.number().nullable(),
        welcomeEmail: yup.number().nullable(),
      })
      .default(undefined)
      .nullable(),
    mobileNumber: yup.string().nullable(),
  })
  .default(undefined);
