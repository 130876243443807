import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  IconButton,
  makeStyles,
  Grid,
  useMediaQuery,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Theme, useTheme } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import { ButtonV2, Typography } from '@castiron/components';
import { Shop, ThemeNameOption, ChecklistValues } from '@castiron/domain';
import { useAppDispatch } from '../../../hooks';
import { closeModal, openModal } from '../../../store/reducers/modalConductor';
import { getShopAction, updateShopAction } from '../../../store/reducers/shops';
import ModalWrapper from '../../RootModal/ModalWrapper';
import { useTracking } from '@castiron/utils';
import { findThemeConfig } from '../../../lib/themeUtils';

export type Props = {
  show: boolean;
  selectedTheme: ThemeNameOption;
  shop: Shop;
};

const useStyles = makeStyles((theme: Theme) => ({
  actionsContainer: {
    marginTop: 24,
    padding: '12px 24px',
    borderTop: `1px solid ${theme.branding.v2.gray[200]}`,
  },
  closeIcon: {
    cursor: 'pointer',
    height: 24,
    width: 24,
  },
  content: {
    maxWidth: 328,
    alignSelf: 'center',
  },
  modalTitle: {
    padding: '26px 24px',
  },
}));

const ConfirmThemeModal: React.FC<Props> = (props: Props) => {
  const { show, selectedTheme, shop } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();
  const { trackEvent } = useTracking();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const handleApply = async () => {
    const themeConfig = findThemeConfig(selectedTheme, theme);

    const newShop: Shop = {
      ...shop,
      config: {
        ...shop?.config,
        shopTheme: themeConfig,
      },
    };

    const updateShopResponse = await dispatch(updateShopAction({ shop: newShop }));

    if (updateShopResponse.meta.requestStatus === 'fulfilled') {
      await shop.addToChecklist(ChecklistValues.Brand);
      await dispatch(getShopAction(shop.id));

      trackEvent('Shop Theme Preset Updated', {
        theme: selectedTheme,
      });

      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT',
          modalProps: {
            show: true,
            celebrate: true,
            content: 'Theme has been updated!',
          },
        }),
      );
      history.push('/store/appearance');
    }
  };

  return (
    <ModalWrapper size="xs" show={show} fullScreen={isMobile}>
      <DialogTitle style={{ padding: 0 }}>
        <Grid container direction="row" justify="flex-end" className={classes.modalTitle}>
          <IconButton className={classes.closeIcon} onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container direction="column" justify="center" alignItems="center" style={{ textAlign: 'center' }}>
          <Typography variant="h2">Are You Sure?</Typography>
          <Typography variant="placeholder1">Selecting a new theme will reset all customizations.</Typography>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: 0 }}>
        <Grid container item justify="flex-end" className={classes.actionsContainer}>
          <ButtonV2 variant="outlined" onClick={handleClose} style={{ marginRight: 8 }}>
            Cancel
          </ButtonV2>
          <ButtonV2 variant="contained" onClick={handleApply}>
            Apply New Theme
          </ButtonV2>
        </Grid>
      </DialogActions>
    </ModalWrapper>
  );
};

export default ConfirmThemeModal;
