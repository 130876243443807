import React, { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import _ from 'lodash';
import { useFormikContext } from 'formik';
import InputWrapper, { BaseInputProps } from '../InputWrapper';
import { Address } from '@castiron/domain';

interface Props extends BaseInputProps {
  addressFields: {
    address: string;
    addressLine1: string;
    city: string;
    region: string;
    regionName: string;
    postalCode: string;
    country: string;
  };
  onAddressChange?: (address: Address) => void;
}

const AddressInput: React.FC<Props> = (props: Props) => {
  const { label, secondaryLabel, required, error, addressFields, onAddressChange } = props;

  const { setFieldValue, values }: any = useFormikContext();

  const [localValue, setLocalValue] = useState(_.get(values, addressFields.address));

  useEffect(() => {
    setLocalValue(_.get(values, addressFields.address));
  }, [values]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(event.target.value);
  };

  // @ts-ignore
  let autocompleteObj;

  const enableEnterKey = input => {
    const _addEventListener = input.addEventListener;

    const addEventListenerWrapper = (type, listener) => {
      if (type === 'keydown') {
        const _listener = listener;
        listener = event => {
          const suggestionSelected = document.getElementsByClassName('pac-item-selected').length;
          if (event.key === 'Enter' || (event.key === 'Tab' && !suggestionSelected)) {
            const e = new KeyboardEvent('keydown', {
              key: 'ArrowDown',
              code: 'ArrowDown',
              keyCode: 40,
            });
            _listener.apply(input, [e]);
          }
          _listener.apply(input, [event]);
        };
      }
      _addEventListener.apply(input, [type, listener]);
    };

    input.addEventListener = addEventListenerWrapper;
  };

  const onPlaceChanged = (): void => {
    //if we need to break things up for the DB, if we're fine with it as is....
    //https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
    // @ts-ignore
    let place = autocompleteObj.getPlace();

    setFieldValue(fieldMappings.address, place.formatted_address);
    setLocalValue(place.formatted_address);

    let address = '';
    let city = '';
    let state = '';
    let stateName = '';
    let postalCode = '';
    let country = '';

    for (const component of place.address_components as typeof window.google.maps.GeocoderAddressComponent) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number': {
          address = `${component.long_name} ${address}`;
          break;
        }
        case 'route': {
          address += component.short_name;
          break;
        }
        // City can come through in a few different component types
        // Assigned in order of importance
        case 'locality':
        case 'sublocality':
        case 'sublocality_level_1':
        case 'administrative_area_level_3':
        case 'sublocality_level_2':
        case 'sublocality_level_3':
        case 'sublocality_level_4':
        case 'sublocality_level_5':
        case 'administrative_area_level_4':
        case 'administrative_area_level_5':
        case 'colloquial_area': {
          if (!city)
            // Only assign once
            city = component.long_name;
          break;
        }
        // if locality, okay to re-assign
        case 'locality': {
          city = component.long_name;
          break;
        }
        case 'administrative_area_level_1': {
          state = component.short_name;
          stateName = component.long_name;
          break;
        }
        case 'postal_code': {
          postalCode = `${component.long_name}`;
          break;
        }
        case 'country': {
          country = component.short_name;
          break;
        }
        // case 'postal_code_suffix': {
        //   postcode = `${postcode}-${component.long_name}`;
        //   break;
        // }
      }
    }

    setFieldValue(fieldMappings.addressLine1, address);
    setFieldValue(fieldMappings.city, city);
    setFieldValue(fieldMappings.region, state);
    setFieldValue(fieldMappings.regionName, stateName);
    setFieldValue(fieldMappings.postalCode, postalCode);
    setFieldValue(fieldMappings.country, country);

    onAddressChange &&
      onAddressChange({
        fullAddress: place.formatted_address,
        addressLine1: address,
        city: city,
        region: state,
        regionName: stateName,
        postalCode: postalCode,
        country: country,
      });
  };

  useEffect(() => {
    let autocompleteEl = document.getElementById('inputAutocomplete');
    if (autocompleteEl) {
      autocompleteObj = new window.google.maps.places.Autocomplete(autocompleteEl, {
        fields: ['address_components', 'geometry', 'formatted_address'],
        types: ['address'],
      });

      enableEnterKey(autocompleteEl);

      autocompleteObj.addListener('place_changed', onPlaceChanged);
      autocompleteEl.onkeydown = event => {
        if (event.code === 'Backspace' || event.code === 'Delete') {
          setFieldValue(fieldMappings.address, '');
          setFieldValue(fieldMappings.addressLine1, null);
          setFieldValue(fieldMappings.city, null);
          setFieldValue(fieldMappings.region, null);
          setFieldValue(fieldMappings.regionName, null);
          setFieldValue(fieldMappings.postalCode, null);
          setFieldValue(fieldMappings.country, null);
        }
      };
    }
  }, []);

  const fieldMappings = addressFields || {
    address: 'address',
    addressLine1: 'addressOne',
    city: 'city',
    region: 'state',
    regionName: 'stateName',
    postalCode: 'postalCode',
    country: 'country',
  };
  return (
    <InputWrapper label={label} secondaryLabel={secondaryLabel} required={required} error={error}>
      <TextField
        style={{ margin: '0px' }}
        id="inputAutocomplete"
        variant="outlined"
        name={fieldMappings.address}
        fullWidth
        value={localValue}
        onChange={handleChange}
        error={!!error}
      />
    </InputWrapper>
  );
};

export default AddressInput;
