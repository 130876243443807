import React, {ReactNode, useCallback} from 'react';
import { useHistory } from 'react-router-dom';
import { FormikProps } from 'formik';
import { SelectedCustomerProps } from '@castiron/domain';
import { Button } from '../';


type Props = {
  setSelectedCustomers?: (selectedCustomers: SelectedCustomerProps[]) => void;
  setSelectedOrders?: (selectedOrders: any[]) => void;
  backLocation?: string;
  children?: ReactNode;
};


const CancelButton: React.FC<Props> = (props: Props) => {
  const { backLocation, setSelectedCustomers, setSelectedOrders } = props;

  const history = useHistory();

  const resetCustomers = () => useCallback(() => {
    backLocation && history.push(backLocation)
    setSelectedCustomers && setSelectedCustomers([]);
    setSelectedOrders && setSelectedOrders([]);
  }, [])

  return (
    <Button
      variant='outlined'
      onClick={resetCustomers()}
    >
      Cancel
    </Button>
  );
};

export default CancelButton;
