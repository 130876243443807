import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Theme } from '@material-ui/core';
import { Banner, Button, Typography } from '@castiron/components';
import { ProductType } from '@castiron/domain';

interface Props {
  errors: any;
  className?: string;
  productType: ProductType;
  text: string;
  scrollToError: (id: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  errorHeading: {
    color: 'inherit',
    display: 'inline',
    marginRight: 4,
  },
  errorText: {
    color: 'inherit',
    display: 'inline',

    '& button': {
      color: 'inherit',
      textDecoration: 'none',
      display: 'inline',
      padding: 0,
      textAlign: 'left',

      [theme.breakpoints.down('sm')]: {
        textDecoration: 'underline',
      },

      '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
    },
  },
}));

const ProductErrorBox: React.FC<Props> = (props: Props) => {
  const { errors, text, className, productType, scrollToError } = props;
  const classes = useStyles();
  const [errorList, setErrorList] = React.useState<any>([]);
  const [varErrors, setVarErrors] = React.useState<any>([]);

  useEffect(() => {
    if (errors) {
      const errorArray = Object.keys(errors).map(key => {
        if (key === 'schedule') {
          return `${errors?.schedule?.startTime || ''}${errors?.schedule?.startTime ? ', ' : ''}${errors?.schedule
            ?.endTime || ''}`;
        } else if (key === 'eventDetails') {
          return [
            errors?.eventDetails?.date?.startTime,
            errors?.eventDetails?.date?.endTime,
            errors?.eventDetails?.location?.meetingUrl,
            errors?.eventDetails?.location?.address?.fullAddress,
          ]
            .filter(x => !!x)
            .join(', ');
        } else if (key !== 'variations') {
          return errors[key];
        }
      });
      setErrorList(errorArray);

      if (errors.variations) {
        let varErrorsCount = 0;

        const variationErrors = errors.variations.map((variation: any, index: number) => {
          if (variation) {
            if (variation.values)
              varErrorsCount = variation.values.filter((value: any) => {
                if (value) return value.name;
              }).length;
            variation.name && varErrorsCount++;
            return { [`${productType === 'custom' ? 'Question' : 'Variation'} ${index + 1}`]: varErrorsCount };
          }
        });

        setVarErrors(variationErrors);
      }
    }
  }, [errors]);

  return (
    <Banner className={className} iconAtStart variant="error">
      <Grid container>
        <Typography variant="body2" className={classes.errorHeading}>
          {text}
        </Typography>
        {errorList.length > 0 && (
          <Typography variant="body2" className={classes.errorText}>
            {errorList.join(', ')}
          </Typography>
        )}
        {varErrors.length > 0 &&
          varErrors.map((error: any, index: number) => {
            if (error) {
              const key = Object.keys(error)[0];
              return (
                <Typography variant="body2" className={classes.errorText}>
                  <Button onClick={() => scrollToError(`${key.split(' ').join('')}`)} variant="text">
                    {key}: {error[key]} errors{varErrors.length > 1 && index < varErrors.length - 1 && ','}
                  </Button>
                </Typography>
              );
            }
          })}
      </Grid>
    </Banner>
  );
};

export default ProductErrorBox;
