import { upload } from '@castiron/castiron-firebase';
import { Banner, ButtonV2, CollapsableCard, DropDownOption, Dropdown, Forms, RichText, Typography } from '@castiron/components';
import { Asset, Transaction } from '@castiron/domain';
import { stripHtml, useTracking } from '@castiron/utils';
import {
  Box,
  Grid,
  Paper,
  Theme,
  makeStyles
} from '@material-ui/core';
import { AddOutlined, DeleteOutlined, PhotoLibraryOutlined } from '@material-ui/icons';
import { nanoid } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { MAX_UPLOAD_FILE_SIZE } from '../../../constants';
import { transactionRepository } from '../../../domain';
import { useAppSelector } from '../../../hooks';
import RichTextInput from '../../RichTextEditor/v2';
import GeneralModal from '../../RootModal/GeneralModal';
import Spinner from '../../Spinner';

type Props = {
  transaction: Transaction;
};

const useStyles = makeStyles((theme: Theme) => ({
  addCursor: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  addIcon: {
    color: theme.branding.v2.blue[500],
  },
  bgColor: {
    /* hack to include alpha using theme color */
    backgroundColor: `${theme.branding.v2.blue[100]}7a !important`,
  },
  dragging: {
    backgroundColor: theme.branding.blue.light,
  },
  emptyContainer: {
    borderRadius: '16px',
    padding: '24px',
    [theme.breakpoints.up('md')]: {
      padding: '64px',
    },
  },
  emptyIcon: {
    color: theme.branding.v2.blue[200],
    height: '48px',
    width: '48px',
  },
  image: {
    aspectRatio: '1/1',
    height: '100%',
    objectFit: 'cover',
    width: '100%',
  },
  imageContainer: {
    backgroundColor: theme.branding.v2.gray[100],
    borderRadius: 12,
    overflow: 'hidden',
    position: 'relative',
  },
  imagesContainer: {
    gap: 16,
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  menuContainerNonPrimary: {
    color: theme.branding.v2.gray[500],
    '&:hover': {
      color: theme.branding.v2.gray[800],
    },
    position: 'absolute',
    top: '4px',
    right: '4px',
    height: '40px',
    width: '40px',
  },
  notesContainer: {
    margin: '16px 0px 12px 0px',
  },
  notesModal: {
    marginTop: 24,
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      width: 400,
    },
  },
}));

const QuoteNotes: React.FC<Props> = (props: Props) => {
  const { transaction } = props;

  const classes = useStyles();
  const { errors, initialValues, setFieldValue, values }: any = useFormikContext();
  const { trackEvent } = useTracking();

  const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [fileSizeError, setFileSizeError] = useState<boolean>(false);
  const [failedToUpload, setFailedToUpload] = useState<string[]>([]);
  const [notesText, setNotesText] = useState<string>(initialValues?.order?.notes?.text);

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const setImages = async (images) => {
    await setFieldValue('order.notes.images', images);
    /* There was a delay with the updated values being used in submit. Manually providing the updated values to submitNotes helps with that. */
    submitNotes(values?.order?.notes?.text, images);
    setUploading(false);
  }

  const submitNotes = async (text, images) => {
    setIsSubmitting(true);
    await transactionRepository.updateProps(transaction.id, {
      order: {
        ...transaction?.order,
        notes: {
          text,
          images,
        },
      }
    });
    setNotesText(text);
    trackEvent('Private Notes Saved');
    setShowNotesModal(false);
    setIsSubmitting(false);
  };

  const closeNotesModal = () => {
    setFieldValue('order.notes.text', notesText)
    setShowNotesModal(false);
  };

  const isTextEmpty = (text: string) => stripHtml(text).length === 0;

  const notesModal = (
    <GeneralModal
      header={isTextEmpty(notesText) ? "Add Private Notes" : "Edit Private Notes"}
      content={
        <Grid container className={classes.notesModal}>
          <RichTextInput
            height={190}
            helpText="Only you can see these."
            label={<Typography style={{ textAlign: 'left' }} variant="subtitle2">Private Notes</Typography>}
            name="order.notes.text"
            placeholder="Add your notes..."
          />
        </Grid>
      }
      actions={[
        <ButtonV2
          variant="outlined"
          onClick={closeNotesModal}
        >
          Cancel
        </ButtonV2>,
        <ButtonV2
          disabled={isSubmitting}
          variant="contained"
          onClick={() => { submitNotes(values?.order?.notes?.text, values?.order?.notes?.images) }}
        >
          {isTextEmpty(notesText) ? "Add" : "Update"}
        </ButtonV2>,
      ]}
      onClose={closeNotesModal}
      show={showNotesModal}
    />
  );

  const handleFile = async (file, handleFileUploadSuccess: (downloadUrl, metadata, options) => void) => {
    const id = nanoid();

    if (file) {
      const metadata = {
        assetType: 'product',
        id,
        shopId: shop.id,
        originalFilename: file.name,
        type: file.type,
      };
      const options = {
        folder: `user/${shop.id}`,
      };
      const callbacks = {
        success: handleFileUploadSuccess,
      };

      upload(file, metadata, options, callbacks, {});
    }
  };

  const handleFiles = (files: any[]) => {
    if (files.some(file => file.size > MAX_UPLOAD_FILE_SIZE)) {
      setFileSizeError(true);
      return;
    } else {
      setFileSizeError(false);
    }
    setUploading(true);
    /* only doing successes right now, will likely end with a never ending wait if something errors
     * maybe add a timeout later and message which images aren't in completed so the user can retry them?
     */
    setFailedToUpload([]);
    const completed = [];
    files.forEach(file =>
      handleFile(file, (downloadUrl, metadata, options) => {
        const image = { id: metadata.id, downloadUrl, metadata, options };
        completed.push(image);
      }),
    );

    const waitForImageUploadsAndSetValues = async (attempts: number = 0) =>
      setTimeout(() => {
        if (
          _.intersection(
            completed.map(file => file.metadata.originalFilename),
            files.map(file => file.name),
          ).length === files.length
        ) {
          setImages([...values?.order?.notes?.images, ...completed]);
          /* 10 seconds */
        } else if (attempts > 100) {
          setImages([...values?.order?.notes?.images, ...completed]);
          setFailedToUpload(
            _.difference(
              files.map(file => file.name),
              completed.map(file => file.metadata.originalFilename),
            ),
          );
        } else {
          waitForImageUploadsAndSetValues(attempts + 1);
        }
      }, 100);

    waitForImageUploadsAndSetValues();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: handleFiles,
  });

  const imageUrl = (asset: Asset) => asset.mediumVersion?.downloadUrl || asset.downloadUrl;

  const imageDropdown = (image: Asset) => {
    const menuOptions: DropDownOption[] = [
      {
        label: 'Delete',
        icon: <DeleteOutlined />,
        onClick: () => {
          setImages(values?.order?.notes?.images.filter(valueImage => image.id !== valueImage.id));
        },
        color: 'error',
      },
    ];
    return (
      <Paper className={classes.menuContainerNonPrimary}>
        <Grid container style={{ height: '100%' }} justify="center" alignItems="center">
          <Grid item>
            <Dropdown
              variant="ellipsis"
              options={menuOptions}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const displayImageCard = (image: Asset, index: number) => (
    <Box className={classes.imageContainer} key={`imageCard${index}-${image?.id}-`}>
      <img src={imageUrl(image)} className={classes.image} />
      {imageDropdown(image)}
    </Box>
  );

  const addImageCard = (
    <Box className={clsx([classes.imageContainer, classes.bgColor, classes.addCursor])}>
      <div
        {...getRootProps({ className: isDragActive ? classes.dragging : '' })}
        style={{ height: '100%' }}
      >
        <input {...getInputProps()} />
        {
          <Grid container justify="center" alignItems="center" className={classes.image}>
            <Grid item>
              <AddOutlined className={classes.addIcon} />
            </Grid>
          </Grid>
        }
      </div>
    </Box>
  );

  const emptyDropzone = (
    <div
      {...getRootProps({ className: `dropzone ${isDragActive ? classes.dragging : ''}` })}
      style={{ height: '100%' }}
    >
      <Box className={clsx([classes.emptyContainer, classes.bgColor])}>
        <input {...getInputProps()} />
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item>
            <PhotoLibraryOutlined className={classes.emptyIcon} />
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <Typography variant="button" style={{ textAlign: 'center' }}>
                  Drop Your Photos Here
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">JPEG, JPG, or PNG accepted</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="body2">OR</Typography>
          </Grid>
          <Grid item>
            <ButtonV2 variant="contained">Browse Photos</ButtonV2>
          </Grid>
        </Grid>
      </Box>
    </div>
  );

  return (
    <Grid container>
      <Spinner size="fullscreen" show={uploading} label="Uploading Image(s)" />
      <Banner variant="eye-blue">
        <Typography variant="body2">Only you can see these.</Typography>
      </Banner>
      <CollapsableCard
        className={classes.notesContainer}
        defaultExpanded
        noScroll
        title="Private Notes"
      >
        {!isTextEmpty(values?.order?.notes?.text) && <RichText content={values?.order?.notes?.text} />}
        <ButtonV2
          style={isTextEmpty(values?.order?.notes?.text) ? {} : {marginTop: 16}}
          onClick={() => { setShowNotesModal(true) }}
          variant="outlined"
        >
          {isTextEmpty(values?.order?.notes?.text) ? "Add Private Notes" : "Edit"}
        </ButtonV2>
      </CollapsableCard>
      <CollapsableCard
        defaultExpanded
        noScroll
        title="Private Images"
      >
        {!!values?.order?.notes?.images.length && (
          <Box className={classes.imagesContainer}>
            {values?.order?.notes?.images.map((image, index) => displayImageCard(image, index))}
            {addImageCard}
          </Box>
        )}
        {!values?.order?.notes?.images.length && emptyDropzone}
        {errors?.order?.notes?.images && <Forms.SubmissionError msg={errors?.order?.notes?.images} />}
        {fileSizeError && <Forms.SubmissionError msg="Images must be under 10MB" />}
        {failedToUpload.length > 0 && (
          <Forms.SubmissionError
            msg={`Failed to upload the following images: ${failedToUpload.join(',')}. Please try again.`}
          />
        )}
      </CollapsableCard>
      {notesModal}
    </Grid>
  );
};

export default QuoteNotes;
