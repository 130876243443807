import { EmptyEventsIcon } from '@castiron/components/src/Icons';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import EmptyPage, { TipsForYou } from '../EmptyPage';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    height: 120,
    width: 120,
  },
  resourceGuide: {
    [theme.breakpoints.down('sm')]: {
      margin: '0px 16px',
    },
  },
}));

const EmptyEvent: React.FC = props => {
  const classes = useStyles();

  const tipsForYou: TipsForYou[] = [
    {
      icon: 'briefcase',
      title: 'Read Our Events Blog',
      description: 'Learn how to plan and run a successful event from experts!',
      ctaAction: () =>
        window
          .open('https://shopcastiron.com/seller/blog/the-secret-to-hosting-a-successful-food-class', '_blank')
          .focus(),
    },
  ];

  return (
    <Grid container item xs={12} className={classes.resourceGuide}>
      <EmptyPage
        icon={<EmptyEventsIcon className={classes.icon} />}
        title="Create a Ticketed Event"
        description="Create your first event and sell tickets to experiences, classes, and other ticketed events."
        tipsForYou={tipsForYou}
        page="Events"
      />
    </Grid>
  );
};

export default EmptyEvent;
