import { DialogActions, DialogContent, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import React, { ReactNode, useEffect, useState } from 'react';
import { ButtonV2 } from '@castiron/components';
import ModalWrapper from '../RootModal/ModalWrapper';
import { useAppDispatch } from '../../hooks';
import { closeModal } from '../../store/reducers/modalConductor';

export type Props = {
  color: 'default' | 'red';
  show: boolean;
  content: ReactNode;
  buttonText: string;
  actionText?: string;
  title?: string;
  isRemoval?: boolean;
  handleAction: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
  },
  title: {
    fontSize: 21,
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: 0.15,
    marginBottom: 24,
  },
  redButton: {
    backgroundColor: theme.branding.v2.red[500],
    '&:hover': {
      backgroundColor: theme.branding.v2.red[500],
    },
  },
}));

const SimpleRemovalAlert: React.FC<Props> = (props: Props) => {
  const { show, content, buttonText, title, color, actionText, handleAction } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [getButtonText, setGetButtonText] = useState<string>(buttonText);

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const handleOnAction = async () => {
    setIsLoading(true);
    if (actionText) {
      setGetButtonText(actionText);
    }
    await handleAction();
    setIsLoading(false);
  };

  return (
    <ModalWrapper show={show} size="xs">
      <IconButton className={classes.closeIcon} onClick={handleClose}>
        <Close />
      </IconButton>
      <DialogContent>
        <Grid container justify="center" alignItems="center" style={{ padding: 12, marginTop: 36 }}>
          {title && (
            <Typography variant="h5" className={classes.title}>
              {title}
            </Typography>
          )}
          <Typography variant="body1">{content}</Typography>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', padding: '12px 36px 36px 36px' }}>
        <Grid container>
          <Grid item xs={12} style={{ marginBottom: 8 }}>
            <ButtonV2
              fullWidth
              onClick={handleOnAction}
              variant="contained"
              className={color === 'red' ? classes.redButton : ''}
              style={{ padding: 16 }}
              loading={isLoading}
              disabled={isLoading}
            >
              {isLoading && actionText ? actionText : buttonText}
            </ButtonV2>
          </Grid>
          <Grid item xs={12}>
            <ButtonV2 fullWidth onClick={handleClose} variant="outlined" style={{ padding: 16 }}>
              Cancel
            </ButtonV2>
          </Grid>
        </Grid>
      </DialogActions>
    </ModalWrapper>
  );
};

export default SimpleRemovalAlert;
