import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import _ from 'lodash';
import InputWrapper, { BaseInputProps } from '../InputWrapper';
import { Address } from '@castiron/domain';

interface Props extends BaseInputProps {
  id?: string;
  onAddressChange: (location: any) => void;
}

const SimpleAddressInput: React.FC<Props> = (props: Props) => {
  const { id, label, secondaryLabel, required, error, onAddressChange } = props;

  const [localValue, setLocalValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(event.target.value);
  };

  // @ts-ignore
  let autocompleteObj;

  const enableEnterKey = input => {
    const _addEventListener = input.addEventListener;

    const addEventListenerWrapper = (type, listener) => {
      if (type === 'keydown') {
        const _listener = listener;
        listener = event => {
          const suggestionSelected = document.getElementsByClassName('pac-item-selected').length;
          if (event.key === 'Enter' || (event.key === 'Tab' && !suggestionSelected)) {
            const e = new KeyboardEvent('keydown', {
              key: 'ArrowDown',
              code: 'ArrowDown',
              keyCode: 40,
            });
            _listener.apply(input, [e]);
          }
          _listener.apply(input, [event]);
        };
      }
      _addEventListener.apply(input, [type, listener]);
    };

    input.addEventListener = addEventListenerWrapper;
  };

  const onPlaceChanged = (): void => {
    // @ts-ignore
    let place = autocompleteObj.getPlace();
    setLocalValue(place.formatted_address);

    let location = {
      fullAddress: place.formatted_address,
      neighborhood: '',
      sublocality: '',
      city: '',
      postalCode: '',
      region: '',
      regionName: '',
    };

    for (const component of place.address_components as typeof window.google.maps.GeocoderAddressComponent) {
      const componentType = component.types[0];
      switch (componentType) {
        // City can come through in a few different component types
        // Assigned in order of importance
        case 'locality': {
          location.city = component.long_name;
          break;
        }
        case 'sublocality': {
          location.sublocality = component.long_name;
          if (!location.city) location.city = component.long_name;
          break;
        }
        case 'neighborhood': {
          location.neighborhood = component.long_name;
          if (!location.city) location.city = component.long_name;
          break;
        }
        case 'administrative_area_level_1': {
          location.region = component.short_name;
          location.regionName = component.long_name;
          break;
        }
        case 'postal_code': {
          location.postalCode = `${component.long_name}`;
          break;
        }
      }
    }

    onAddressChange(location);
  };

  useEffect(() => {
    let autocompleteEl = document.getElementById(id || 'inputAutocomplete');
    if (autocompleteEl) {
      autocompleteObj = new window.google.maps.places.Autocomplete(autocompleteEl, {
        fields: ['address_components', 'formatted_address'],
        types: ['locality', 'sublocality', 'neighborhood', 'administrative_area_level_1', 'postal_code'],
        componentRestrictions: { country: 'us' },
      });

      enableEnterKey(autocompleteEl);

      autocompleteObj.addListener('place_changed', onPlaceChanged);
    }
  }, []);

  return (
    <InputWrapper label={label} secondaryLabel={secondaryLabel} required={required} error={error}>
      <TextField
        style={{ margin: '0px' }}
        id={id || 'inputAutocomplete'}
        variant="outlined"
        fullWidth
        value={localValue}
        onChange={handleChange}
        error={!!error}
        placeholder=""
      />
    </InputWrapper>
  );
};

export default SimpleAddressInput;
