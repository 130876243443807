import firebase from 'firebase/compat/app';
import _firestore from '@google-cloud/firestore';
import { BaseDocument, BaseRepository, FieldFunctions } from '../base/repository';
import { Asset } from '../asset/model';
import { filterScheduledSpecials } from './utils';

interface Theme {
  backgroundColor: string;
  textColor: string;
  heroAlignCTA?: 'left' | 'right' | 'center';
}

interface Schedule {
  //start of fleshing out more comprehensive and flexible specials management system (ie, repeatability, can manually turn on/off, etc.)
  active: boolean;
  countdown?: boolean;
  startDate?: number;
  endDate?: number;
}

export interface Special extends BaseDocument<Special> {
  name: string; //simple name for reference, linking
  title?: string; //name with extra adornment for display purposes
  tag: string;
  position: number; // order of importance on homepage and nav
  type: 'calendar' | 'evergreen' | 'lifestyle' | 'seasonal'; //lifestyle includes allergen-related
  slug: string;
  description: string;
  images?: {
    thumbnail?: Asset;
    hero?: Asset; //for hompage hero background
    preview?: Asset; //for "coming soon" section
  };
  query?: Record<string, any>;
  schedule: Schedule;
  theme?: Theme;
  shopContent?: {
    dashboard: {
      backgroundColor: string;
      subtitle: string;
    };
    icon: string;
    page: {
      bannerDescription: string;
      bannerHeadline: string;
      navLabel: string;
      title: string;
    };
    popup: {
      show: boolean;
      position: number;
      admin: {
        description: string;
        imageUrl: string;
        title: string;
      };
      shop: {
        description: string;
        headline: string;
        title: string;
      };
    };
    // There is a possibility that a special can have a schedule separate from marketplace
    schedule: Schedule;
  };
  //props unique to specials page that should only show in dedicated shop (ie Mark's Favorites)
  isUniqueEvent?: boolean;
  shopId?: string;
  //props unique to specials page that should only show in Marketplace and not in Castiron shop
  isMarketplaceEvent?: boolean;

  //legacy
  subtitlePrefix?: string; //title for sections of featured products or shops (ie "Mom's Favorite [Shops / Products]" --> subtitlePrefix === "Mom's Favorite")
  featuredProducts?: string[]; //array of IDs of products to display as featured on specials page
  featuredShops?: string[];
  pageDisplay?: string;
}

export class SpecialRepository extends BaseRepository<Special> {
  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, fieldFunctions?: FieldFunctions) {
    super(firestore, 'specials', fieldFunctions);
  }

  public async findByTag(tag: string): Promise<Special | null> {
    const result = await this.find({
      where: [{ field: 'tag', operator: '==', value: tag }],
    });

    return this.firstOrNull(result);
  }

  public async findActive(tz: string): Promise<Special[]> {
    return this.find({
      where: [{ field: 'schedule.active', operator: '==', value: true }],
    }).then(res => res.filter(s => filterScheduledSpecials(s, tz)).sort((a, b) => a.position - b.position));
  }

  public async findActiveShopEvents(tz: string): Promise<Special[]> {
    return this.find({
      where: [{ field: 'shopContent.schedule.active', operator: '==', value: true }],
    }).then(res => res.filter(s => filterScheduledSpecials(s, tz, true)).sort((a, b) => a.position - b.position));
  }

  public async findBySlug(slug: string): Promise<Special | null> {
    const result = await this.find({
      where: [{ field: 'slug', operator: '==', value: slug }],
    });

    return this.firstOrNull(result);
  }

  public async findCountdown(): Promise<Special[]> {
    return this.find({
      where: [{ field: 'schedule.countdown', operator: '==', value: true }],
    });
  }
}
