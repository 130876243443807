import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface Props {
  content: string;
  customFont?: string;
  maxLines?: number;
  className?: string;
}

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  maxLines: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    WebkitLineClamp: props => props.maxLines,
    overflow: 'hidden',
  },
  text: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.branding.v2.gray[500],
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    '& p': {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      color: 'inherit',
      marginTop: '0',
      marginBottom: '0',
    },
  },
}));

const RichText: React.FC<Props> = (props: Props) => {
  const { content, customFont, maxLines, className } = props;
  const classes = useStyles(props);

  return (
    <div
      className={`${classes.text} ${maxLines ? classes.maxLines : ''} ${className ? className : ''}`}
      style={customFont ? { fontFamily: `${customFont}` } : { fontFamily: 'Nunito Sans' }}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default RichText;
