import React, { useCallback } from 'react';
import firebase from 'firebase/compat/app';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { loginWithApple, loginWithGoogle, loginWithFacebook } from '@castiron/castiron-firebase';
import { GoogleGIcon } from '@castiron/components';
import { useAppDispatch } from '../../../hooks';
import { signOutAction } from '../../../store/reducers/users';
import { getShopAction } from '../../../store/reducers/shops';
import AppleIcon from '@material-ui/icons/Apple';

const useStyles = makeStyles(theme => ({
  socialButton: {
    textAlign: 'center',
    '& button': {
      border: 'none',
      borderRadius: '50%',
      padding: '0',
      height: '75px',
      width: '75px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.15)',
      '& img': {
        maxWidth: '46px',
      },
    },
  },
  socialContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: 32,
    margin: '32px 0',
  },
  disabledButton: {
    opacity: 0.5,
  },
}));

export interface ExistingAccountError {
  email: string;
}

interface Props {
  onAuth: (user: firebase.User) => Promise<void>;
  onExistingAccount?: (err: ExistingAccountError) => Promise<void>;
  rerequestFacebook?: boolean;
}

const SocialLoginButtons: React.FC<Props> = (props: Props) => {
  const { onAuth, onExistingAccount, rerequestFacebook } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const loginWithGoogleAction = useCallback(async () => {
    const result: firebase.User = await loginWithGoogle(onExistingAccount);
    const shop = await dispatch(getShopAction(result?.uid));
    if (shop?.payload) {
      onAuth(result);
    } else {
      dispatch(signOutAction('/?invalidEmail=true'));
    }
  }, [onExistingAccount]);

  const loginWithAppleAction = useCallback(async () => {
    const result: firebase.User = await loginWithApple(onExistingAccount);
    const shop = await dispatch(getShopAction(result?.uid));
    if (shop?.payload) {
      onAuth(result);
    } else {
      dispatch(signOutAction('/?invalidEmail=true'));
    }
  }, [onExistingAccount]);

  const loginWithFacebookAction = useCallback(async () => {
    const result: firebase.User = await loginWithFacebook({
      onExistingAccount,
      rerequest: !!rerequestFacebook,
    });
    onAuth(result);
  }, [onExistingAccount, rerequestFacebook]);

  return (
    <div className={classes.socialContainer}>
      <div className={classes.socialButton}>
        <Button variant="outlined" onClick={loginWithFacebookAction}>
          <img src="/assets/img/facebook-f-logo.svg" alt="Facebook logo" />
        </Button>
      </div>
      {/* <div className={classes.socialButton}>
        <Button variant="outlined" onClick={loginWithTwitterAction}>
          <img src="/assets/img/twitter-bird-logo.svg" style={{ maxWidth: '44px' }} alt="Twitter logo" />
        </Button>
      </div> */}
      <div className={classes.socialButton}>
        <Button variant="outlined" onClick={loginWithGoogleAction}>
          <GoogleGIcon style={{ width: '50px', height: '50px' }} />
        </Button>
      </div>
      <div className={classes.socialButton}>
        <Button variant="outlined" onClick={loginWithAppleAction}>
          <AppleIcon style={{ width: '50px', height: '50px' }} />
        </Button>
      </div>
    </div>
  );
};

export default SocialLoginButtons;
