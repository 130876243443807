import React, { useEffect } from 'react';
import { Grid, useTheme } from '@material-ui/core';
import { InsertDriveFileOutlined, Web } from '@material-ui/icons';
import { DuplicateIcon, Typography } from '@castiron/components';
import { useAppSelector } from '../../../hooks';
import { ProductModalProps, Step } from './AddProductModal';
import ModalChoice from '../../RootModal/ModalChoice';

const StartingPoint: React.FC<ProductModalProps> = (props: ProductModalProps) => {
  const { context, productType, fromChecklist, setTitle, setStep } = props;
  const theme = useTheme();

  const { products } = useAppSelector(state => ({
    products: state.products.products,
  }));

  const typeProducts = products.filter(p => p.type === productType);

  const templateLocation = !fromChecklist
    ? context
    : productType === 'custom'
    ? 'order-forms'
    : productType === 'standard'
    ? 'products'
    : 'events';

  useEffect(() => {
    setTitle(null);
  }, []);

  return (
    <>
      <Typography variant="h3">{`Create a New ${
        context === 'order-forms' ? 'Order Form' : context === 'products' ? 'Product' : 'Event'
      }`}</Typography>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="body1">Select a starting point.</Typography>
        </Grid>
        <Grid item>
          <ModalChoice
            icon={<InsertDriveFileOutlined />}
            title="Start From Scratch"
            description={
              !!context || fromChecklist
                ? context === 'events'
                  ? 'Create a brand new blank class or event.'
                  : `Create an optimal ${context === 'order-forms' ? 'order form' : 'product'} in 3 steps.`
                : 'Select a product type and start with a blank template.'
            }
            action={() => setStep(!!context || fromChecklist ? 'nameAndPrice' : 'productType')}
          />
        </Grid>
        {context !== 'events' && (
          <Grid item>
            <ModalChoice
              icon={<Web />}
              title="Use a Template"
              description={`Customize relevant ${
                context === 'order-forms' ? 'order form' : 'product'
              } templates in detail.`}
              action={() => window.open(`/${templateLocation}?tab=Templates`, '_self')}
            />
          </Grid>
        )}
        {typeProducts.length > 0 && (
          <Grid item>
            <ModalChoice
              icon={<DuplicateIcon style={{ color: theme.branding.blue.primary }} />}
              title={`Duplicate ${
                context === 'order-forms' ? 'an Order Form' : context === 'products' ? 'a Product' : 'an Event'
              }`}
              description={
                context === 'events'
                  ? 'Start from an existing class or event.'
                  : `Copy and edit an similar existing ${context === 'order-forms' ? 'order form' : 'product'}.`
              }
              action={() => setStep('duplicate')}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default StartingPoint;
