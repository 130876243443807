import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const EmptyQuotesIcon = (props: SvgIconProps) => (
  <SvgIcon width="93" height="104" viewBox="0 0 93 104" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_941_38437)">
      <path
        d="M44.921 43.6066H58.2888C58.3323 43.6066 58.3741 43.5894 58.4049 43.5588C58.4356 43.5281 58.4529 43.4865 58.4529 43.4432C58.4529 43.3999 58.4356 43.3583 58.4049 43.3277C58.3741 43.297 58.3323 43.2798 58.2888 43.2798H44.921C44.8775 43.2798 44.8357 43.297 44.8049 43.3277C44.7741 43.3583 44.7568 43.3999 44.7568 43.4432C44.7568 43.4865 44.7741 43.5281 44.8049 43.5588C44.8357 43.5894 44.8775 43.6066 44.921 43.6066Z"
        fill="#E4E4E7"
      />
      <path
        d="M51.0797 28.0531C50.7845 28.2026 50.5595 28.4608 50.4527 28.7728C50.3458 29.0848 50.3655 29.426 50.5075 29.7238C50.5373 29.7839 50.5721 29.8414 50.6114 29.8958L48.0945 33.531L49.4981 35.4513L52.4051 30.1005C52.619 29.9206 52.767 29.6753 52.826 29.4028C52.8851 29.1304 52.8519 28.8461 52.7316 28.5945C52.6618 28.4519 52.5644 28.3244 52.4451 28.2193C52.3258 28.1142 52.1869 28.0335 52.0362 27.9819C51.8856 27.9302 51.7262 27.9086 51.5672 27.9183C51.4082 27.9279 51.2526 27.9687 51.1094 28.0382C51.0994 28.043 51.0895 28.048 51.0797 28.0531Z"
        fill="#FFB6B6"
      />
      <path
        d="M36.1482 28.7859C35.8745 28.9416 35.6377 29.1542 35.4539 29.4091C35.2702 29.664 35.1437 29.9554 35.0831 30.2633C35.0226 30.5713 35.0293 30.8886 35.103 31.1937C35.1766 31.4988 35.3154 31.7845 35.5099 32.0314L38.6341 35.9985L45.5516 41.0887C45.5516 41.0887 50.4234 36.8518 50.4624 35.5349C50.5015 34.218 49.9801 33.9391 50.5015 34.218C51.0228 34.4969 51.4975 34.2878 51.3186 34.1406C51.1396 33.9934 51.2027 33.9771 51.3113 33.7258C51.4198 33.4744 51.8012 32.2892 51.8012 32.2892L49.9015 30.7456C49.9015 30.7456 48.1283 31.8163 48.0115 32.3199C47.8947 32.8234 48.5329 32.5988 47.8947 32.8234C47.2566 33.048 46.3129 32.3928 47.0687 33.1039C47.8246 33.8149 47.8007 34.1571 47.1745 34.2106C46.5483 34.2641 45.5599 35.9992 45.5599 35.9992L38.8035 29.1519C38.4637 28.8075 38.0188 28.5848 37.5385 28.5186C37.0581 28.4524 36.5692 28.5464 36.1482 28.7859Z"
        fill="#E0ECFF"
      />
      <path
        d="M35.4691 103.841H14.7233C14.2964 103.841 13.887 103.672 13.5851 103.371C13.2832 103.07 13.1133 102.663 13.1128 102.238V84.8541C13.1133 84.4291 13.2832 84.0216 13.5851 83.7211C13.887 83.4205 14.2964 83.2515 14.7233 83.251H35.4691C35.8961 83.2515 36.3055 83.4205 36.6074 83.7211C36.9093 84.0216 37.0792 84.4291 37.0797 84.8541V102.238C37.0792 102.663 36.9093 103.07 36.6074 103.371C36.3055 103.672 35.8961 103.841 35.4691 103.841Z"
        fill="#E0ECFF"
      />
      <path d="M3.75503 79.7021L3.74683 81.5023L10.874 82.3911L10.886 79.734L3.75503 79.7021Z" fill="#FFB6B6" />
      <path
        d="M4.59484 79.2362L4.76139 82.2435L3.46742 82.2376L1.47132 85.087C1.37275 85.2278 1.23157 85.3335 1.06838 85.3887C0.905193 85.4439 0.728548 85.4457 0.564237 85.3939C0.399926 85.3421 0.256554 85.2394 0.155048 85.1007C0.0535415 84.9621 -0.000782962 84.7947 8.52756e-06 84.6232L0.01633 81.0438L1.29908 80.4327L0.0202426 80.186L0.0263944 78.8364L4.59484 79.2362Z"
        fill="#27272A"
      />
      <path
        d="M63.2747 42.1807C58.8979 41.0962 55.1403 38.9644 52.2026 35.4453C51.9787 35.3087 51.8183 35.0893 51.7567 34.8351C51.6951 34.581 51.7372 34.3129 51.8739 34.0897L56.9838 25.7657C57.121 25.5427 57.3414 25.3831 57.5967 25.3218C57.852 25.2604 58.1213 25.3024 58.3456 25.4385L69.4176 32.1739C69.6416 32.3105 69.802 32.5299 69.8636 32.784C69.9252 33.0382 69.883 33.3063 69.7464 33.5295L64.6365 41.8535C64.4993 42.0764 64.2788 42.2361 64.0235 42.2974C63.7682 42.3588 63.4989 42.3168 63.2747 42.1807Z"
        fill="#E0ECFF"
      />
      <path
        d="M60.3224 39.5078C58.018 39.2574 55.623 37.4239 53.2043 35.1777C52.9946 35.0498 52.8444 34.8443 52.7867 34.6063C52.729 34.3682 52.7684 34.1172 52.8965 33.9081L57.288 26.7543C57.4165 26.5456 57.6229 26.396 57.862 26.3386C58.1011 26.2811 58.3533 26.3204 58.5634 26.4478L68.416 32.4415C68.6257 32.5694 68.7759 32.7749 68.8336 33.0129C68.8913 33.2509 68.8518 33.502 68.7238 33.7111L66.0033 38.1427C65.431 39.0727 64.5115 39.7387 63.4465 39.9946C62.3814 40.2505 61.2579 40.0755 60.3224 39.5078Z"
        fill="white"
      />
      <path
        d="M62.3432 30.9122L58.9345 36.4997C58.9076 36.5437 58.8723 36.5821 58.8306 36.6125C58.7888 36.643 58.7415 36.665 58.6912 36.6772C58.6409 36.6894 58.5887 36.6917 58.5376 36.6838C58.4864 36.676 58.4373 36.6582 58.3931 36.6314C58.3489 36.6047 58.3104 36.5695 58.2798 36.528C58.2492 36.4864 58.2271 36.4393 58.2149 36.3892C58.2026 36.3392 58.2003 36.2872 58.2082 36.2363C58.2161 36.1854 58.234 36.1365 58.2609 36.0925L61.6696 30.505C61.6965 30.461 61.7318 30.4226 61.7735 30.3922C61.8152 30.3617 61.8626 30.3397 61.9129 30.3275C61.9631 30.3153 62.0153 30.313 62.0665 30.3209C62.1176 30.3287 62.1667 30.3465 62.211 30.3733C62.2552 30.4 62.2937 30.4352 62.3243 30.4767C62.3549 30.5183 62.3769 30.5654 62.3892 30.6155C62.4015 30.6655 62.4037 30.7175 62.3958 30.7684C62.3879 30.8193 62.37 30.8682 62.3432 30.9122Z"
        fill="#005FFF"
      />
      <path
        d="M63.8235 31.8072L60.4148 37.3947C60.3605 37.4835 60.273 37.5471 60.1716 37.5717C60.0701 37.5963 59.9631 37.5798 59.8738 37.5259C59.7846 37.472 59.7205 37.385 59.6957 37.284C59.6708 37.1831 59.6872 37.0765 59.7413 36.9875L63.15 31.4C63.2043 31.3113 63.2918 31.2476 63.3932 31.2231C63.4946 31.1985 63.6017 31.2149 63.691 31.2689C63.7802 31.3228 63.8443 31.4098 63.8691 31.5108C63.894 31.6117 63.8776 31.7183 63.8235 31.8072V31.8072Z"
        fill="#005FFF"
      />
      <path
        d="M40.7732 49.6695C40.7732 49.6695 40.0542 60.7065 39.46 62.4158C38.8149 64.2656 38.8986 67.0731 37.93 69.7612C37.6313 70.5913 37.3259 71.4329 37.0206 72.268C36.9599 72.4363 36.8975 72.6046 36.8368 72.7729C36.4379 73.8596 36.039 74.9283 35.6581 75.9382C35.6039 76.0804 35.5498 76.2209 35.4972 76.3598C35.4956 76.3631 35.4956 76.3664 35.494 76.368C34.2365 79.6608 33.1597 82.2509 32.7296 82.679C32.2699 83.1366 29.1641 83.3262 25.3261 83.3785C18.2132 83.4732 8.58374 83.099 8.58374 83.099L8.59851 79.2474C8.59851 79.2474 8.68387 79.2228 8.84311 79.1755C9.13695 79.0905 9.68359 78.932 10.3977 78.7212C13.3804 77.8453 19.2917 76.082 21.9986 75.1081C22.7636 74.8336 23.2725 74.6211 23.3874 74.5084C24.0604 73.8547 24.3576 74.5084 24.7844 75.162C25.2112 75.8157 25.3425 75.162 25.9006 74.6816C26.4588 74.2012 27.7162 74.8613 27.7162 74.8613C27.7162 74.8613 27.7277 74.4267 27.7458 73.7338C27.772 72.7108 27.8114 71.1192 27.8508 69.5259C27.8492 69.5243 27.8492 69.5243 27.8508 69.5243C27.8656 68.9245 27.8804 68.3248 27.8935 67.7561C27.905 67.3149 27.9148 66.8917 27.9247 66.4995C27.951 65.3245 27.969 64.4356 27.969 64.2133C27.969 63.4878 27.969 64.0516 28.5452 63.6136C28.6313 63.5464 28.713 63.4738 28.7898 63.3963C28.9128 63.2864 29.0259 63.1662 29.128 63.0368C29.1706 62.9452 28.9063 63.0694 28.5633 63.0008C28.3773 62.9633 28.2053 62.8757 28.0659 62.7475C27.3419 62.0988 28.1233 60.1084 28.1233 60.1084C26.4785 56.8336 26.5967 54.1471 27.3666 52.0783C27.9418 50.5922 28.8555 49.2592 30.0357 48.1841C30.4853 47.7586 30.9745 47.3767 31.4967 47.0435L31.5476 46.7739L32.1041 43.7785C33.3216 43.1049 34.7285 42.8504 36.1063 43.0546C37.1376 43.2041 38.1361 43.5262 39.0594 44.0073C39.8136 44.3846 40.5214 44.8474 41.1689 45.3865L40.7732 49.6695Z"
        fill="#27272A"
      />
      <path d="M25.6482 99.6737L27.449 99.8404L28.9661 92.8514L26.3084 92.6055L25.6482 99.6737Z" fill="#FFB6B6" />
      <path
        d="M30.8353 102.413L28.1595 100.182L28.2794 98.8995L27.6572 98.8782L25.7284 98.8162L25.2556 98.7998L24.4529 103.295L25.8039 103.42L26.1634 102.173L26.6608 103.5L30.241 103.831C30.4127 103.847 30.5849 103.808 30.7326 103.72C30.8803 103.631 30.9957 103.498 31.0621 103.34C31.1284 103.181 31.1422 103.006 31.1014 102.839C31.0606 102.672 30.9674 102.523 30.8353 102.413H30.8353Z"
        fill="#27272A"
      />
      <path
        d="M35.6583 75.9382C34.9737 79.651 32.0731 88.3527 30.3199 93.4463C29.5204 95.7733 28.9623 97.3486 28.9623 97.3486L28.0512 97.0626L26.5147 96.5789L25.9533 96.4025L25.2737 96.1884C25.2737 96.1884 25.4329 87.8184 25.3262 83.3784C25.2901 81.9469 25.2277 80.924 25.1243 80.7213C24.6975 79.8879 25.4116 79.7997 26.1651 79.5889C26.9202 79.3781 26.3309 79.061 26.037 78.3845C25.8384 77.9335 26.2291 77.3338 26.5065 76.9906C26.583 76.8942 26.6646 76.802 26.7511 76.7144C26.7511 76.7144 24.8305 76.064 22.6587 75.3303C22.657 75.3319 22.657 75.3303 22.657 75.3303C22.4387 75.2568 22.2187 75.1832 21.9988 75.1081C22.7637 74.8335 23.2726 74.6211 23.3875 74.5083C24.0606 73.8547 24.3577 74.5083 24.7845 75.162C25.2113 75.8156 25.3426 75.162 25.9008 74.6816C26.4589 74.2011 27.7164 74.8613 27.7164 74.8613C27.7164 74.8613 27.7278 74.4266 27.7459 73.7338C27.7722 72.7108 27.8116 71.1191 27.851 69.5259C27.8493 69.5242 27.8493 69.5242 27.851 69.5242C27.8657 68.9245 27.8805 68.3248 27.8936 67.7561C29.0509 68.6385 30.1738 69.5046 31.1916 70.3053C31.1932 70.307 31.1965 70.3086 31.1965 70.3119C33.6441 72.2369 35.481 73.7828 35.7387 74.273C35.8552 74.4953 35.8158 75.0852 35.6583 75.9382Z"
        fill="#27272A"
      />
      <path
        d="M28.9378 47.851C28.1683 47.6173 27.3591 48.4063 27.3826 49.2069C27.406 50.0075 28.0482 50.6853 28.788 51.0001C29.5278 51.315 41.2549 53.2811 42.1226 52.8369C42.9904 52.3927 43.6209 51.3273 43.2378 50.4339C42.991 49.8587 42.459 47.1838 41.9871 46.7708C40.3987 45.3803 41.8564 43.5666 42.8372 41.701C43.7047 40.0508 45.5751 38.7886 45.5565 36.9263C45.5431 35.5877 44.526 34.4971 43.5737 33.552L40.5459 30.5471L37.6715 27.6944C37.0573 27.0848 34.4222 26.7624 33.9504 27.0456C32.9644 27.6376 30.5247 37.501 30.1761 38.4006C30.0642 38.6895 30.7916 42.386 31.0881 42.4794C31.9075 45.0642 30.4665 45.563 28.9002 47.78"
        fill="#E0ECFF"
      />
      <path
        d="M52.4613 32.9198C52.14 32.9997 51.8628 33.2013 51.6886 33.4817C51.5143 33.762 51.4569 34.099 51.5284 34.4208C51.544 34.486 51.565 34.5498 51.5911 34.6116L48.3215 37.5946L49.2584 39.7788L53.2935 35.2103C53.5423 35.0825 53.7417 34.8763 53.8604 34.6239C53.9791 34.3715 54.0106 34.0871 53.9499 33.815C53.9138 33.6605 53.8476 33.5146 53.7549 33.3856C53.6622 33.2566 53.5449 33.147 53.4097 33.0632C53.2745 32.9793 53.124 32.9228 52.9668 32.8968C52.8096 32.8708 52.6488 32.8759 52.4936 32.9118C52.4828 32.9143 52.472 32.917 52.4613 32.9198Z"
        fill="#FFB6B6"
      />
      <path
        d="M37.7431 30.3112C37.4414 30.402 37.1629 30.5565 36.9266 30.7641C36.6902 30.9717 36.5015 31.2275 36.3733 31.5141C36.2451 31.8008 36.1805 32.1116 36.1837 32.4253C36.187 32.7391 36.2581 33.0485 36.3922 33.3325L38.5464 37.8944L44.1458 44.3952C44.1458 44.3952 49.8458 41.3497 50.1796 40.0748C50.5134 38.7999 50.0678 38.412 50.5133 38.7999C50.9589 39.1878 51.4685 39.0896 51.3271 38.9063C51.1858 38.723 51.251 38.7211 51.4132 38.5003C51.5754 38.2795 52.2133 37.2092 52.2133 37.2092L50.7084 35.2818C50.7084 35.2818 48.7396 35.9309 48.5127 36.3957C48.2858 36.8605 48.9582 36.7836 48.2858 36.8605C47.6133 36.9374 46.8406 36.0888 47.4177 36.95C47.9947 37.8113 47.8946 38.1395 47.2722 38.0523C46.6499 37.9651 45.2968 39.4363 45.2968 39.4363L40.2491 31.2587C39.9952 30.8474 39.6117 30.5314 39.1583 30.3599C38.7049 30.1885 38.2073 30.1714 37.7431 30.3112Z"
        fill="#E0ECFF"
      />
      <path
        d="M35.8654 25.8114C38.576 25.8114 40.7733 23.624 40.7733 20.9257C40.7733 18.2274 38.576 16.04 35.8654 16.04C33.1549 16.04 30.9575 18.2274 30.9575 20.9257C30.9575 23.624 33.1549 25.8114 35.8654 25.8114Z"
        fill="#FFB6B6"
      />
      <path
        d="M32.0729 17.3137C32.1234 16.874 32.4261 16.3306 32.6367 15.9408C32.8473 15.5511 33.2539 15.2328 33.6976 15.2599C33.8789 15.2808 34.0525 15.3451 34.2035 15.4472C34.3544 15.5494 34.4783 15.6863 34.5646 15.8464C34.734 16.1688 34.8263 16.5258 34.8343 16.8895C34.8807 17.701 34.7446 18.5126 34.4359 19.2651C34.1272 20.0176 33.6537 20.692 33.0499 21.2392C32.7059 21.5463 32.292 21.8196 31.83 21.83C31.6202 21.825 31.4136 21.7783 31.2222 21.6926C31.0308 21.6069 30.8586 21.484 30.7157 21.3311C29.968 20.5856 29.8549 19.3602 30.2542 18.3846C30.6535 17.4091 30.8317 17.0295 31.7446 16.4966"
        fill="#27272A"
      />
      <path
        d="M38.9674 24.3405C38.8854 24.7752 39.6405 26.8424 39.3992 27.2149C39.3992 27.2149 36.9005 28.0789 35.9451 28.141C34.7779 28.2162 33.8392 27.7395 32.7475 27.0221C32.7278 26.6329 32.6146 26.2541 32.4176 25.9174C32.4341 26.1782 32.3809 26.4388 32.2633 26.6724C30.6644 25.4043 30.0642 21.9172 31.6451 20.2978L33.2866 16.5393C32.7941 16.7027 34.2808 14.1778 35.8485 16.0064C37.8101 14.0177 41.6103 16.8807 42.0865 19.4381C41.6283 18.8609 41.3897 18.9289 41.2722 19.327C41.1371 18.9922 40.9232 18.6946 40.6484 18.4593C40.7851 18.7281 40.8549 19.0257 40.852 19.327C37.7576 19.5182 38.3335 21.5963 40.8698 24.7093C39.9319 24.1281 40.5648 26.6326 40.064 27.2313C40.0394 26.8129 39.0561 24.7474 38.9674 24.3405Z"
        fill="#27272A"
      />
      <path
        d="M70.9071 74.9987H84.2749C84.3184 74.9987 84.3602 74.9815 84.391 74.9508C84.4217 74.9202 84.439 74.8786 84.439 74.8353C84.439 74.7919 84.4217 74.7504 84.391 74.7197C84.3602 74.6891 84.3184 74.6719 84.2749 74.6719H70.9071C70.8635 74.6719 70.8218 74.6891 70.791 74.7197C70.7602 74.7504 70.7429 74.7919 70.7429 74.8353C70.7429 74.8786 70.7602 74.9202 70.791 74.9508C70.8218 74.9815 70.8635 74.9987 70.9071 74.9987Z"
        fill="#E4E4E7"
      />
      <path
        d="M65.1615 19.6015H78.5293C78.5728 19.6015 78.6146 19.5843 78.6453 19.5536C78.6761 19.523 78.6934 19.4814 78.6934 19.4381C78.6934 19.3947 78.6761 19.3532 78.6453 19.3225C78.6146 19.2919 78.5728 19.2747 78.5293 19.2747H65.1615C65.1179 19.2747 65.0762 19.2919 65.0454 19.3225C65.0146 19.3532 64.9973 19.3947 64.9973 19.4381C64.9973 19.4814 65.0146 19.523 65.0454 19.5536C65.0762 19.5843 65.1179 19.6015 65.1615 19.6015Z"
        fill="#E4E4E7"
      />
      <path
        d="M75.6019 16.7462C72.8916 13.1575 71.325 9.14435 71.2737 4.56905C71.1864 4.32249 71.2009 4.05151 71.314 3.81557C71.4271 3.57962 71.6297 3.39798 71.8771 3.3105L81.1155 0.0565333C81.3632 -0.0303928 81.6354 -0.0159669 81.8724 0.0966486C82.1094 0.209264 82.2919 0.410871 82.3797 0.65724L86.708 12.8344C86.7953 13.081 86.7808 13.352 86.6677 13.5879C86.5545 13.8239 86.352 14.0055 86.1045 14.093L76.8662 17.3469C76.6185 17.4339 76.3463 17.4195 76.1093 17.3068C75.8723 17.1942 75.6898 16.9926 75.6019 16.7462Z"
        fill="#E0ECFF"
      />
      <path
        d="M75.0031 12.8187C73.373 11.178 72.6753 8.25162 72.2206 4.99007C72.1388 4.75916 72.1524 4.50538 72.2583 4.28441C72.3643 4.06344 72.5539 3.89333 72.7857 3.8114L80.7253 1.01489C80.9572 0.933475 81.2122 0.946978 81.4342 1.05244C81.6561 1.1579 81.827 1.34671 81.9093 1.57745L85.7609 12.4135C85.8427 12.6444 85.8291 12.8982 85.7231 13.1192C85.6172 13.3401 85.4275 13.5103 85.1957 13.5922L80.2773 15.3246C79.244 15.6872 78.1085 15.627 77.1197 15.1572C76.131 14.6875 75.3698 13.8464 75.0031 12.8187Z"
        fill="white"
      />
      <path
        d="M82.0156 7.41481L75.8309 9.61245C75.7325 9.64742 75.6242 9.64204 75.5297 9.59749C75.4353 9.55294 75.3625 9.47287 75.3274 9.3749C75.2922 9.27693 75.2977 9.16908 75.3424 9.07507C75.3872 8.98107 75.4676 8.90861 75.566 8.87364L81.7507 6.67601C81.8491 6.64117 81.9574 6.64663 82.0517 6.69119C82.146 6.73576 82.2188 6.81578 82.2539 6.91369C82.289 7.0116 82.2836 7.11937 82.2389 7.21334C82.1942 7.3073 82.1139 7.37977 82.0156 7.41481Z"
        fill="#005FFF"
      />
      <path
        d="M76.4651 7.10317L75.1045 7.58665C75.0557 7.60403 75.004 7.61167 74.9523 7.60914C74.9006 7.60661 74.8499 7.59396 74.8031 7.57191C74.7563 7.54986 74.7143 7.51885 74.6796 7.48064C74.6448 7.44243 74.618 7.39778 74.6006 7.34924C74.5832 7.3007 74.5755 7.24922 74.5781 7.19774C74.5807 7.14626 74.5935 7.0958 74.6157 7.04923C74.6379 7.00267 74.6691 6.96091 74.7075 6.92636C74.7459 6.8918 74.7908 6.86512 74.8396 6.84785L76.2002 6.36437C76.2986 6.32952 76.4069 6.33498 76.5012 6.37955C76.5955 6.42411 76.6683 6.50414 76.7034 6.60205C76.7385 6.69995 76.7331 6.80773 76.6884 6.90169C76.6438 6.99566 76.5634 7.06812 76.4651 7.10317Z"
        fill="#005FFF"
      />
      <path
        d="M83.1803 10.6629L76.9956 12.8605C76.8972 12.8953 76.7889 12.8899 76.6946 12.8453C76.6002 12.8007 76.5275 12.7207 76.4924 12.6228C76.4573 12.5249 76.4627 12.4171 76.5074 12.3232C76.552 12.2292 76.6323 12.1567 76.7307 12.1217L82.9154 9.92406C83.0138 9.88921 83.122 9.89467 83.2164 9.93924C83.3107 9.98381 83.3834 10.0638 83.4185 10.1617C83.4536 10.2596 83.4483 10.3674 83.4036 10.4614C83.3589 10.5553 83.2786 10.6278 83.1803 10.6629Z"
        fill="#005FFF"
      />
      <path
        d="M82.5979 9.03886L76.4132 11.2365C76.3148 11.2715 76.2064 11.2661 76.112 11.2215C76.0176 11.177 75.9448 11.0969 75.9096 10.9989C75.8745 10.901 75.8799 10.7931 75.9247 10.6991C75.9694 10.6051 76.0499 10.5327 76.1483 10.4977L82.333 8.30006C82.3817 8.28268 82.4334 8.27504 82.4851 8.27757C82.5368 8.2801 82.5876 8.29275 82.6344 8.3148C82.6812 8.33685 82.7231 8.36786 82.7579 8.40607C82.7926 8.44428 82.8195 8.48893 82.8369 8.53747C82.8543 8.58601 82.8619 8.6375 82.8593 8.68897C82.8567 8.74045 82.844 8.79091 82.8218 8.83748C82.7996 8.88405 82.7684 8.9258 82.73 8.96035C82.6916 8.99491 82.6467 9.02159 82.5979 9.03886Z"
        fill="#005FFF"
      />
      <path
        d="M71.7988 5.87483C72.7054 5.87483 73.4404 5.14321 73.4404 4.2407C73.4404 3.33819 72.7054 2.60657 71.7988 2.60657C70.8922 2.60657 70.1572 3.33819 70.1572 4.2407C70.1572 5.14321 70.8922 5.87483 71.7988 5.87483Z"
        fill="#005FFF"
      />
      <path
        d="M81.0189 71.98C78.3086 68.3913 76.742 64.3781 76.6907 59.8028C76.6034 59.5562 76.6179 59.2853 76.731 59.0493C76.8441 58.8134 77.0466 58.6318 77.2941 58.5443L86.5325 55.2903C86.7801 55.2034 87.0524 55.2178 87.2894 55.3304C87.5264 55.443 87.7088 55.6446 87.7967 55.891L92.1249 68.0682C92.2123 68.3148 92.1978 68.5857 92.0846 68.8217C91.9715 69.0576 91.769 69.2393 91.5215 69.3267L82.2832 72.5807C82.0355 72.6676 81.7633 72.6532 81.5263 72.5406C81.2893 72.428 81.1068 72.2264 81.0189 71.98Z"
        fill="#E0ECFF"
      />
      <path
        d="M80.4204 68.0523C78.7903 66.4116 78.0925 63.4853 77.6378 60.2237C77.556 59.9928 77.5696 59.739 77.6755 59.518C77.7815 59.2971 77.9712 59.127 78.2029 59.045L86.1425 56.2485C86.3745 56.1671 86.6294 56.1806 86.8514 56.2861C87.0734 56.3915 87.2443 56.5804 87.3266 56.8111L91.1781 67.6471C91.2599 67.8781 91.2463 68.1318 91.1403 68.3528C91.0344 68.5738 90.8447 68.7439 90.6129 68.8258L85.6945 70.5582C84.6613 70.9208 83.5257 70.8607 82.5369 70.3909C81.5482 69.9211 80.787 69.0801 80.4204 68.0523Z"
        fill="white"
      />
      <path
        d="M87.4329 62.6486L81.2482 64.8462C81.1497 64.8812 81.0414 64.8758 80.947 64.8313C80.8525 64.7867 80.7797 64.7066 80.7446 64.6087C80.7095 64.5107 80.7149 64.4028 80.7596 64.3088C80.8044 64.2148 80.8848 64.1424 80.9832 64.1074L87.1679 61.9098C87.2663 61.8749 87.3746 61.8804 87.4689 61.925C87.5633 61.9695 87.636 62.0496 87.6711 62.1475C87.7062 62.2454 87.7008 62.3531 87.6562 62.4471C87.6115 62.5411 87.5312 62.6135 87.4329 62.6486Z"
        fill="#005FFF"
      />
      <path
        d="M81.8823 62.3368L80.5217 62.8203C80.4233 62.8552 80.315 62.8497 80.2207 62.8051C80.1264 62.7606 80.0536 62.6805 80.0185 62.5826C79.9834 62.4847 79.9888 62.377 80.0335 62.283C80.0781 62.189 80.1585 62.1166 80.2568 62.0815L81.6174 61.598C81.6662 61.5807 81.7179 61.573 81.7696 61.5755C81.8213 61.5781 81.872 61.5907 81.9188 61.6128C81.9656 61.6348 82.0076 61.6658 82.0423 61.704C82.0771 61.7423 82.1039 61.7869 82.1213 61.8354C82.1387 61.884 82.1464 61.9355 82.1438 61.9869C82.1412 62.0384 82.1284 62.0889 82.1062 62.1355C82.084 62.182 82.0528 62.2238 82.0144 62.2583C81.976 62.2929 81.9311 62.3196 81.8823 62.3368Z"
        fill="#005FFF"
      />
      <path
        d="M88.0151 64.2725L81.8304 66.4701C81.732 66.5051 81.6237 66.4997 81.5292 66.4552C81.4348 66.4106 81.362 66.3306 81.3269 66.2326C81.2918 66.1346 81.2972 66.0268 81.3419 65.9328C81.3867 65.8388 81.4671 65.7663 81.5655 65.7313L87.7502 63.5337C87.799 63.5163 87.8507 63.5087 87.9024 63.5112C87.9541 63.5137 88.0048 63.5264 88.0516 63.5484C88.0984 63.5705 88.1404 63.6015 88.1751 63.6397C88.2099 63.6779 88.2367 63.7226 88.2541 63.7711C88.2715 63.8197 88.2792 63.8711 88.2766 63.9226C88.274 63.9741 88.2612 64.0246 88.239 64.0711C88.2168 64.1177 88.1856 64.1594 88.1472 64.194C88.1088 64.2285 88.0639 64.2552 88.0151 64.2725Z"
        fill="#005FFF"
      />
      <path
        d="M77.5444 60.9451C78.451 60.9451 79.186 60.2135 79.186 59.311C79.186 58.4085 78.451 57.6769 77.5444 57.6769C76.6378 57.6769 75.9028 58.4085 75.9028 59.311C75.9028 60.2135 76.6378 60.9451 77.5444 60.9451Z"
        fill="#005FFF"
      />
      <path
        d="M51.5586 29.8798C52.4652 29.8798 53.2001 29.1482 53.2001 28.2457C53.2001 27.3432 52.4652 26.6116 51.5586 26.6116C50.6519 26.6116 49.917 27.3432 49.917 28.2457C49.917 29.1482 50.6519 29.8798 51.5586 29.8798Z"
        fill="#005FFF"
      />
      <path
        d="M34.5021 20.3381C34.4625 20.338 34.4243 20.3237 34.3944 20.2978C34.3646 20.2719 34.3452 20.2362 34.3397 20.1972C34.3342 20.1581 34.3431 20.1184 34.3648 20.0854C34.3864 20.0524 34.4192 20.0283 34.4573 20.0175C34.5004 19.9468 34.4827 19.6492 34.4685 19.4088C34.4151 18.5061 34.3258 16.9954 35.7992 15.9755C35.8349 15.9508 35.8791 15.9412 35.9219 15.9489C35.9647 15.9565 36.0028 15.9808 36.0276 16.0164C36.0525 16.052 36.0621 16.0959 36.0544 16.1386C36.0467 16.1812 36.0223 16.2191 35.9866 16.2438C34.6644 17.1591 34.7437 18.5014 34.7963 19.3896C34.8263 19.8974 34.848 20.2642 34.5386 20.334C34.5266 20.3367 34.5144 20.3381 34.5021 20.3381Z"
        fill="#005FFF"
      />
      <path
        d="M2.34107 103.806C2.34099 103.831 2.34599 103.856 2.35578 103.88C2.36557 103.904 2.37995 103.925 2.39811 103.943C2.41627 103.961 2.43783 103.976 2.46157 103.985C2.4853 103.995 2.51074 104 2.53642 104H44.1157C44.1675 104 44.2172 103.98 44.2538 103.943C44.2904 103.907 44.311 103.857 44.311 103.806C44.311 103.754 44.2904 103.705 44.2538 103.668C44.2172 103.632 44.1675 103.611 44.1157 103.611H2.53642C2.51074 103.611 2.48531 103.616 2.46157 103.626C2.43784 103.635 2.41627 103.65 2.39812 103.668C2.37996 103.686 2.36557 103.707 2.35578 103.731C2.34599 103.755 2.34099 103.78 2.34107 103.806Z"
        fill="#E4E4E7"
      />
      <path
        d="M34.5786 16.7665C33.5766 16.3526 32.5095 16.1172 31.4257 16.0709C30.566 16.0347 29.5404 16.2255 29.191 17.0082C28.979 17.483 29.0919 18.0396 29.2858 18.522C29.4798 19.0044 29.7519 19.4602 29.8642 19.9676C30.1061 21.0608 29.5351 22.2193 28.6589 22.9208C27.7826 23.6223 26.6517 23.9319 25.5361 24.0726C26.3743 24.3822 27.2252 24.6947 28.1161 24.7689C29.0069 24.8432 29.9583 24.6504 30.6235 24.0558C31.3432 23.4124 31.6198 22.413 31.7422 21.4577C31.8647 20.5024 31.871 19.5186 32.1963 18.6117C32.5216 17.7047 33.2688 16.8601 34.2328 16.7797"
        fill="#27272A"
      />
    </g>
    <defs>
      <clipPath id="clip0_941_38437">
        <rect width="92.1818" height="104" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
