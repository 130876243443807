import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CheckCircleOutline, ErrorOutline, NotInterested } from '@material-ui/icons';
import clsx from 'clsx';
import Typography from '../Typography';
import { AvailabilitySubtype } from '@castiron/domain';

interface Props {
  status: AvailabilitySubtype;
  variant?: 'short';
  iconOnly?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  checkCircleOutlineIcon: {
    color: theme.branding.green.primary,
    backgroundColor: theme.branding.green.light,
  },
  icon: {
    height: 16,
    width: 16,
  },
  iconContainer: {
    borderRadius: 100,
    height: 32,
    width: 32,
    marginRight: 8,
  },
  iconOnlyContainer: {
    marginRight: 0,
  },
  errorOutlineIcon: {
    color: theme.branding.orange.primary,
    backgroundColor: theme.branding.yellow.light,
  },
  notInterestedIcon: {
    color: theme.branding.red.primary,
    backgroundColor: theme.branding.red.light,
  },
}));

const AvailabilityStatusLabel: React.FC<Props> = (props: Props) => {
  const { status, variant, iconOnly = false } = props;

  const classes = useStyles();

  const availabilityStatuses = {
    available: {
      iconClass: classes.checkCircleOutlineIcon,
      icon: <CheckCircleOutline className={classes.icon} />,
      labelLong: 'Available',
      labelShort: 'Available',
    },
    limited: {
      iconClass: classes.errorOutlineIcon,
      icon: <ErrorOutline className={classes.icon} />,
      labelLong: 'Limited Availability',
      labelShort: 'Limited',
    },
    unavailable: {
      iconClass: classes.notInterestedIcon,
      icon: <NotInterested className={classes.icon} />,
      labelLong: 'Not Available',
      labelShort: 'Not Available',
    },
  };

  return (
    <Grid container alignItems="center" wrap="nowrap" className="availabilityListing">
      <Grid
        container
        item
        className={clsx(availabilityStatuses[status].iconClass, classes.iconContainer, iconOnly && classes.iconOnlyContainer)}
        alignContent="center"
        justify="center"
      >
        {availabilityStatuses[status].icon}
      </Grid>
      {!iconOnly && (
        <Grid item>
          <Typography variant="body1">
            {variant === 'short' ? availabilityStatuses[status].labelShort : availabilityStatuses[status].labelLong}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default AvailabilityStatusLabel;
