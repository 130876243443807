import {BaseProduct, FulfillmentOption, FulfillmentType, ProductSearchResult} from '@castiron/domain';
import _ from 'lodash';
import moment from 'moment-timezone';

export const dietaryOptions = [
  { label: 'Dairy Free', value: 'dairyFree' },
  { label: 'Gluten Free', value: 'glutenFree' },
  { label: 'Keto', value: 'keto' },
  { label: 'Kosher', value: 'kosher' },
  { label: 'Low/No Carb', value: 'lowNoCarb' },
  { label: 'Paleo', value: 'paleo' },
  { label: 'Wheat', value: 'wheat' },
  { label: 'Whole30', value: 'whole30' },
  { label: 'Vegan', value: 'vegan' },
  { label: 'Vegetarian', value: 'vegetarian' },
  { label: 'Organic', value: 'organic' },
  { label: 'Local', value: 'local' },
];

export const allergenOptions = [
  { label: 'Milk', value: 'milk' },
  { label: 'Eggs', value: 'eggs' },
  { label: 'Fish', value: 'fish' },
  { label: 'Shellfish', value: 'shellfish' },
  { label: 'Tree Nuts', value: 'treeNuts' },
  { label: 'Wheat', value: 'wheat' },
  { label: 'Peanuts', value: 'peanuts' },
  { label: 'Soy Beans', value: 'soyBeans' },
  { label: 'Sesame', value: 'sesame' },
];

//TODO - this currently just uses the "value" of these items
//we could transform these into the correct name on the front-end, but that seems improper
const getList = (array, obj): string => {
  const arrayLength = array.length;
  const tempArray = [];
  let listStr = '';
  for (let i = 0; i < arrayLength; i++) {
    const strVal = array[i];
    const result = obj.filter(item => {
      if (item.value === strVal) {
        return item.label;
      }
    });
    if (result.length > 0 && result[0].label) {
      tempArray.push(result[0].label);
    }
  }
  listStr = tempArray.join(', ');
  return listStr;
};

export const getDietaryList = (dietary: string[]) => getList(dietary, dietaryOptions);

export const getAllergensList = (allergens: string[]) => getList(allergens, allergenOptions);

/* Also in domain/src/product/utils */
export const hasSchedule = (product): boolean => product?.schedule?.startTime && product?.schedule?.endTime && product?.status !== 'deleted' && product?.status !== 'hidden';

/* Also in domain/src/product/utils */
export const getProductStatus = (product, timeZone) => {
  const now = moment()
    .tz(timeZone || 'America/Chicago')
    .unix();
    let status = '';
    if (hasSchedule(product)) {
      const isScheduleActive = product?.schedule?.startTime <= now && now <= product?.schedule?.endTime;
      const isScheduleFuture = now < product?.schedule?.startTime;
      status = isScheduleActive ? 'active' : isScheduleFuture ? 'scheduled' : 'inactive';
    } else {
      status = product?.status;
    }
  return status;
};

export const getProductFulfillmentTypes = (
  product: BaseProduct | ProductSearchResult,
  shopFulfillments: FulfillmentOption[],
  isPresale: boolean,
  context?: 'productCard' | 'productModal',
): string | FulfillmentType[] => {
  const fulfillmentTypes: FulfillmentType[] = ['pickup', 'delivery', 'shipping'];
  const shopFulfillment = { pickup: false, delivery: false, shipping: false };
  shopFulfillments.forEach(fulfillment => (shopFulfillment[fulfillment.type] = true));
  const shopFulfillmentTypes = fulfillmentTypes.filter(f => shopFulfillment[f] == true);
  const fulfillmentOptions = (product?.fulfillment && !isPresale
    ? /* While a product may have all fulfillment types on, their shop might not have all those fulfillments currently available */
      _.intersection(
        fulfillmentTypes.filter(f => product?.fulfillment[f] == true),
        shopFulfillmentTypes,
      )
    : shopFulfillmentTypes
  );
  if (context === 'productCard') {
    // Returns "Pickup, Delivery, Shipping"
    return fulfillmentOptions.length === 0
      ? 'No Fulfillment Options'
      : fulfillmentOptions.map(option => _.capitalize(option)).join(', ');
  } else if (context === 'productModal') {
    // Returns "Pickup, Delivery, & Shipping available."
    return fulfillmentOptions.length === 0
      ? 'Sorry, there are no fulfillment options currently available for this product.'
      : `${
          fulfillmentOptions.length === 1
            ? _.capitalize(fulfillmentOptions[0])
            : fulfillmentOptions.length === 2
            ? `${_.capitalize(fulfillmentOptions[0])} & ${fulfillmentOptions[1]}`
            : fulfillmentOptions.length === 3
            ? `${_.capitalize(fulfillmentOptions[0])}, ${fulfillmentOptions[1]}, & ${fulfillmentOptions[2]}`
            : ''
        } available.`;
  } else {
    // Returns ["pickup", "delivery", "shipping"]
    return fulfillmentOptions;
  }
};
