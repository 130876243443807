import React, {ReactNode} from 'react';
import { titleCase } from '@castiron/utils';
import Typography from '@material-ui/core/Typography';
type Props = {
  name: string;
  label: string;
  children?: ReactNode;
};

const Label: React.FC<Props> = (props: Props) => {
  return (
    <Typography style={{ fontSize: 14, fontWeight: 'bold' }} variant="caption">
      {props.label || titleCase(props.name)}
    </Typography>
  );
};

export default Label;
