import { ButtonV2, DropDownOption, Dropdown, Pill, Typography } from '@castiron/components';
import { ChecklistValues, MessageType } from '@castiron/domain';
import {
  Coupon,
  NewsletterMarketingSend,
  Product,
  SubscriberCouponMarketingSend,
  WelcomeSeriesMarketingSend,
} from '@castiron/domain/src';
import { useTracking } from '@castiron/utils';
import { useConfig } from '@castiron/castiron-firebase';
import { Divider, Grid, Paper, Theme, makeStyles, useTheme } from '@material-ui/core';
import Dinero from 'dinero.js';
import _ from 'lodash';
import moment from 'moment';
import React, { ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AbandonedCartImage from '../../../assets/img/marketingAutomation/abandoned-cart.png';
import AbandonedOrderFormImage from '../../../assets/img/marketingAutomation/abandoned-order-form.png';
import NewSubscriberImage from '../../../assets/img/marketingAutomation/new-subscriber.png';
import ShopUpdateImage from '../../../assets/img/marketingAutomation/shop-update.png';
import ThankYouImage from '../../../assets/img/marketingAutomation/thank-you.png';
import {
  couponRepository,
  marketingSendRepository,
  shopRepository,
  transactionRepository,
  sendLogRepository,
} from '../../../domain';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { productHasImages } from '../../../lib/imageUtils';
import { getCouponAction } from '../../../store/reducers/coupons';
import { openModal } from '../../../store/reducers/modalConductor';
import { getShopAction, updateChecklistAction } from '../../../store/reducers/shops';
import Spinner from '../../Spinner';
import { defaultMsg } from '../EditUpdateEmail';
import { getService } from '../../../firebase';

interface Props {}

const useStyles = makeStyles((theme: Theme) => ({
  automationContent: {
    padding: '24px 16px',
    gap: '16px',
    height: '100%',
  },
  emailContainer: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '12px',
    textAlign: 'left',
    overflow: 'hidden',
  },
  sectionHeader: {
    marginBottom: 16,
  },
  emailsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '16px 24px',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
      gap: '16px',
    },
  },
  menuContainer: {
    color: theme.branding.v2.gray[500],
    '&:hover': {
      color: theme.branding.v2.gray[800],
    },
    position: 'absolute',
    top: '8px',
    right: '8px',
    height: '40px',
    width: '40px',
  },
  statsSection: {
    textAlign: 'center',
    width: '100%',
  },
  statusDecorator: {
    marginRight: 4,
    fontSize: 20,
  },
  statusPill: {
    position: 'absolute',
    bottom: 8,
    left: 8,
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  },
}));

export const sendFrequencyLabel = frequency =>
  frequency === 'weekly' ? 'weekly' : frequency === 'biweekly' ? 'biweekly' : 'monthly';

const getCouponValue = (coupon: Coupon): string => {
  return `${
    coupon.discount.type === 'amount'
      ? `${Dinero({ amount: coupon.discount.value }).toFormat('$0.00')} Off`
      : `${coupon.discount.value}% Off`
  }`;
};

export const getTotalSends = async (sendType: MessageType, shopId: string) => {
  const sends = await Promise.resolve(sendLogRepository.findSendsByType(shopId, sendType));
  const total = sendType === 'welcome_series' ? _.uniqBy(sends, 'customerId')?.length : sends.length;
  return total;
};

export const getTotalSendsAndLastSent = async (sendType: MessageType, shopId: string) => {
  const sends = await Promise.resolve(sendLogRepository.findSendsByType(shopId, sendType));
  const total = sendType === 'welcome_series' ? _.uniqBy(sends, 'customerId')?.length : sends.length;
  return {
    total,
    lastSent: sends.length > 0 ? sends[0]?.createdAt : null,
  };
};

export const getTotalRevenueBySend = async (utmSource: string, findBy: 'source' | 'campaign', shopId: string) => {
  const transactions = await Promise.resolve(transactionRepository.findTransactionsByUtm(shopId, utmSource, findBy));

  const totals = transactions.map(t => t.totals.totalWithTax);
  const revenue = !_.isEmpty(totals) ? totals.reduce((acc, curr) => acc + curr, 0) : 0;

  return revenue;
};

export const getNewsletterStats = async (
  shopId: string,
): Promise<{ totalSends: number; totalRevenue: number; lastSent: number }> => {
  const newsletterSeriesTotalP = getTotalSendsAndLastSent('newsletter_series', shopId);
  const newsletterSeries1TotalRevenueP = getTotalRevenueBySend('newsletter_series_1', 'campaign', shopId);
  const newsletterSeries2TotalRevenueP = getTotalRevenueBySend('newsletter_series_2', 'campaign', shopId);
  const originalNewsletterTotalSendsP = getTotalSends('newsletter', shopId);
  const originalNewsletterTotalRevenueP = getTotalRevenueBySend('shop-newsletter', 'source', shopId);

  const [
    newsletterSeriesTotal,
    newsletterSeries1TotalRevenue,
    newsletterSeries2TotalRevenue,
    originalNewsletterTotalSends,
    originalNewsletterTotalRevenue,
  ] = await Promise.all([
    newsletterSeriesTotalP,
    newsletterSeries1TotalRevenueP,
    newsletterSeries2TotalRevenueP,
    originalNewsletterTotalSendsP,
    originalNewsletterTotalRevenueP,
  ]);
  const newsletterSeriesTotalSends = newsletterSeriesTotal.total;
  const totalSends = newsletterSeriesTotalSends + originalNewsletterTotalSends;
  const totalRevenue = newsletterSeries1TotalRevenue + newsletterSeries2TotalRevenue + originalNewsletterTotalRevenue;
  return {
    totalSends: totalSends || 0,
    totalRevenue: totalRevenue || 0,
    lastSent: newsletterSeriesTotal.lastSent,
  };
};

export const getWelcomeSeriesStats = async (shopId: string): Promise<{ totalSends: number; totalRevenue: number }> => {
  const welcomeSeriesTotalSendsP = getTotalSends('welcome_series', shopId);
  const welocmeSeriesTotalRevenueSubscribersP = getTotalRevenueBySend(
    'welcome_series_new_subscriber',
    'campaign',
    shopId,
  );
  const welocmeSeriesTotalRevenueSocialP = getTotalRevenueBySend('welcome_series_social', 'campaign', shopId);
  const welocmeSeriesTotalRevenueProductsP = getTotalRevenueBySend('welcome_series_products', 'campaign', shopId);
  const SubscriberTotalSendsP = getTotalSends('subscriber_coupon', shopId);
  const SubscriberTotalRevenueP = getTotalRevenueBySend('subscriber-coupon', 'source', shopId);

  const [
    welcomeSeriesTotalSends,
    welocmeSeriesTotalRevenueSubscribers,
    welocmeSeriesTotalRevenueSocial,
    welocmeSeriesTotalRevenueProducts,
    SubscriberTotalSends,
    SubscriberTotalRevenue,
  ] = await Promise.all([
    welcomeSeriesTotalSendsP,
    welocmeSeriesTotalRevenueSubscribersP,
    welocmeSeriesTotalRevenueSocialP,
    welocmeSeriesTotalRevenueProductsP,
    SubscriberTotalSendsP,
    SubscriberTotalRevenueP,
  ]);
  const totalSends = welcomeSeriesTotalSends + SubscriberTotalSends;
  const totalRevenue =
    welocmeSeriesTotalRevenueSubscribers +
    welocmeSeriesTotalRevenueSocial +
    welocmeSeriesTotalRevenueProducts +
    SubscriberTotalRevenue;
  return {
    totalSends: totalSends || 0,
    totalRevenue: totalRevenue || 0,
  };
};

const Automations: React.FC<Props> = (props: Props) => {
  const {} = props;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const ffconfig = useConfig();

  const { coupons, shop, sendGridConfig, products } = useAppSelector(state => ({
    coupons: state.coupons.coupons,
    shop: state.shops.shop,
    sendGridConfig: state.shops?.account?.sendGridConfig,
    products: state.products.products,
  }));

  const [newsletterMarketingSend, setNewsletterMarketingSend] = useState<{
    enabled: boolean;
    totalSends: number;
    totalRevenue: number;
  }>(null);
  const [subscriberMarketingSend, setSubscriberMarketingSend] = useState(null);
  const [getNewsletterMarketingSendLoading, setGetNewsletterMarketingSendLoading] = useState<boolean>(true);
  const [getSubscriberMarketingSendLoading, setGetSubscriberMarketingSendLoading] = useState<boolean>(true);
  const [welcomeSeriesLoading, setWelcomeSeriesLoading] = useState<boolean>(true);
  const [welcomeCoupon, setWelcomeCoupon] = useState<Coupon | null>(null);
  const [coupon, setCoupon] = useState<Coupon | null>(null);
  const [thankYouStats, setThankYouStats] = useState(null);
  const [welcomeStats, setWelcomeStats] = useState(null);

  const welcomeSeriesFeatureFlag = ffconfig?.featureFlag('feature_welcome_series', shop);

  const sendPreviewEmailService = getService('messaging', 'sendpreviewemail');

  useEffect(() => {
    dispatch(getCouponAction(shop.id));
  }, [shop]);

  const getNewsletterMarketingSend = async () => {
    setGetNewsletterMarketingSendLoading(true);
    const newsLetterStats = await getNewsletterStats(shop.id);
      setNewsletterMarketingSend({
        enabled: shop?.config?.marketing?.newsletterSeries?.enabled !== false,
        totalSends: newsLetterStats?.totalSends,
        totalRevenue: newsLetterStats?.totalRevenue,
      });

    setGetNewsletterMarketingSendLoading(false);
  };

  const getSubscriberMarketingSend = async () => {
    setGetSubscriberMarketingSendLoading(true);
    const subscriberMarketingSendResponse = await marketingSendRepository.listByType(shop.id, 'subscriber_coupon');
    const send = subscriberMarketingSendResponse[0] as SubscriberCouponMarketingSend;

    const totalSends = await getTotalSends('subscriber_coupon', shop.id);
    const totalRevenue = await getTotalRevenueBySend('subscriber-coupon', 'source', shop.id);
    if (!!subscriberMarketingSendResponse.length) {
      setSubscriberMarketingSend({
        ...send,
        totalSends,
        totalRevenue,
      });

      const getCouponResponse = await couponRepository.get(send.couponId);
      setCoupon(getCouponResponse);
    }

    setGetSubscriberMarketingSendLoading(false);
  };

  const getThankYouStats = async () => {
    const totalSends = await getTotalSends('thank_you_coupon', shop.id);
    const totalRevenue = await getTotalRevenueBySend('thank_you_coupon', 'campaign', shop.id);

    setThankYouStats({
      totalSends,
      totalRevenue,
    });
  };

  const getWelcomeSeriesCoupon = async () => {
    setWelcomeSeriesLoading(true);
    const couponId = shop?.config?.marketing?.welcomeSeriesCoupon?.couponId;
    const welcomeSeriesStats = await getWelcomeSeriesStats(shop.id);
    setWelcomeStats({
      totalSends: welcomeSeriesStats?.totalSends,
      totalRevenue: welcomeSeriesStats?.totalRevenue,
    });

    if (couponId) {
      const getCouponResponse = await couponRepository.get(couponId);
      setWelcomeCoupon(getCouponResponse);
    } else {
      const subscriberMarketingSendResponse = await marketingSendRepository.findActiveOfTypeForShop(shop.id, 'subscriber_coupon');
      if (!!subscriberMarketingSendResponse.length) {
        const marketingSend = subscriberMarketingSendResponse[0] as SubscriberCouponMarketingSend;
        const getCouponResponse = await couponRepository.get(marketingSend.couponId);
        setWelcomeCoupon(getCouponResponse);
      }
    }
    setWelcomeSeriesLoading(false);
  };

  useEffect(() => {
    if (shop?.id) {
      getNewsletterMarketingSend();
      getWelcomeSeriesCoupon();
      getSubscriberMarketingSend();
      getThankYouStats();
    }

    return function cleanup() {
      getNewsletterMarketingSend();
      getWelcomeSeriesCoupon();
      getSubscriberMarketingSend();
      getThankYouStats();
    };
  }, [shop]);

  const generateEmailContainer = ({
    menuOptions,
    imageSrc,
    status,
    name,
    subheader,
    cardClick,
    handleSetUpClick,
    loading = false,
    index,
    pillText,
    totalSends,
    totalRevenue,
  }: {
    menuOptions?: DropDownOption[];
    imageSrc: string;
    status: string;
    name: string;
    subheader: string;
    cardClick?: () => void;
    handleSetUpClick?: () => void;
    loading?: boolean;
    index: number;
    pillText?: string;
    totalSends?: string | number;
    totalRevenue?: string | number;
  }): ReactNode => {
    return (
      <Grid
        key={`automation-${index}`}
        container
        item
        direction="column"
        justify="flex-start"
        wrap="nowrap"
        className={classes.emailContainer}
        style={{ cursor: cardClick ? 'pointer' : null }}
      >
        <Grid container item style={{ position: 'relative' }}>
          <img
            src={imageSrc}
            alt={name}
            style={{ width: '100%', height: '100%' }}
            onClick={cardClick ? cardClick : null}
          />
          {!loading && (
            <Pill
              className={classes.statusPill}
              variant="white"
              content={
                <>
                  <span
                    onClick={cardClick ? cardClick : null}
                    className={classes.statusDecorator}
                    style={{
                      color:
                        status === 'active'
                          ? theme.branding.v2.green[500]
                          : status === 'inactive'
                          ? theme.branding.v2.red[500]
                          : theme.branding.v2.yellow[500],
                    }}
                  >
                    •
                  </span>
                  {_.capitalize(status)}
                </>
              }
            />
          )}
          {menuOptions && (
            <Paper className={classes.menuContainer}>
              <Grid container style={{ height: '100%' }} justify="center" alignItems="center">
                <Grid item>
                  <Dropdown
                    variant="ellipsis"
                    options={menuOptions}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  />
                </Grid>
              </Grid>
            </Paper>
          )}
        </Grid>
        <Grid
          container
          item
          direction="column"
          wrap="nowrap"
          justify="space-between"
          className={classes.automationContent}
          onClick={cardClick ? cardClick : null}
        >
          <Grid
            container
            item
            direction="column"
            wrap="nowrap"
            justify={status === 'inactive' ? 'space-between' : 'flex-start'}
            style={{ gap: '8px' }}
            xs
          >
            <Grid container item direction="column" wrap="nowrap">
              <Typography variant="subtitle1">{name}</Typography>
              <Typography variant="body2">{subheader}</Typography>
            </Grid>
            {status === 'inactive' ? (
              <Grid container item>
                <ButtonV2
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleSetUpClick}
                  style={{ marginTop: '16px' }}
                >
                  Activate
                </ButtonV2>
              </Grid>
            ) : (
              pillText && <Pill content={pillText} variant="gray" />
            )}
          </Grid>
          <Grid container item direction="column" style={{ gap: '16px' }}>
            <Divider />
            <Grid container item direction="row" wrap="nowrap" alignItems="center" style={{ gap: '16px' }}>
              <Grid
                container
                item
                direction="column"
                wrap="nowrap"
                justify="center"
                alignItems="center"
                className={classes.statsSection}
              >
                <Typography variant="subtitle1">{totalSends}</Typography>
                <Typography variant="body2">Sent</Typography>
              </Grid>
              <Grid container item direction="column" wrap="nowrap" justify="center" alignItems="center">
                <Typography variant="subtitle1">
                  {typeof totalRevenue === 'number'
                    ? Dinero({ amount: totalRevenue }).toFormat('$0,0.00')
                    : totalRevenue}
                </Typography>
                <Typography variant="body2">Revenue</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const manageNewsletterMarketingSend = newsletterMarketingSend?.enabled
    ? () => {
        history.push('/marketing/edit-newsletter-series')
      }
    : async () => {
      trackEvent('Automation Turned On', { type: 'Newsletter Series' });
      if (!shop?.config?.marketing?.newsletterSeries?.enabled) {
        await shopRepository.updateProps(shop.id, {
          'config.marketing.newsletterSeries.enabled': true,
        });
      }
      await dispatch(getShopAction(shop.id));
      };

  const manageSubscriberMarketingSend = subscriberMarketingSend
    ? async () => {
        await marketingSendRepository.activate(subscriberMarketingSend.id);
        await getSubscriberMarketingSend();
        trackEvent('Newsletter Status Changed', {
          newsletterMarketingSend: {
            ...subscriberMarketingSend,
            status: 'active',
          },
        });
      }
    : async () => {
        const newCoupon = {
          code: 'THANKYOU',
          discount: {
            type: 'percent' as 'percent',
            value: 10,
          },
          duration: {
            startDate: moment().unix(),
          },
          metrics: {
            totalRevenue: 0,
            totalUses: 0,
          },
          maximumPerCustomer: 1,
          shopId: shop.id,
          status: 'active',
        };
        const createCouponResponse = await couponRepository.create(newCoupon);
        if (!shop.checklistCompletions?.includes(ChecklistValues.CouponCreate)) {
          dispatch(updateChecklistAction({ shop, items: [ChecklistValues.CouponCreate] }));
        }
        trackEvent('Coupon Created', { coupon: newCoupon });

        const newMarketingSend = {
          shopId: shop.id,
          type: 'subscriber_coupon',
          templateId: 'bfihusWJ8zjV5rxbcQQB',
          couponId: createCouponResponse.id,
          status: 'active',
          artisanMessage: defaultMsg,
        } as SubscriberCouponMarketingSend;
        await marketingSendRepository.create(newMarketingSend);
        if (!shop.checklistCompletions?.includes(ChecklistValues.EmailMarketing)) {
          dispatch(updateChecklistAction({ shop, items: [ChecklistValues.EmailMarketing] }));
        }
        trackEvent('Subscriber Coupon Created', { newsletterMarketingSend: newMarketingSend });
        await getSubscriberMarketingSend();
      };

  const thankYouCouponEnabled = shop?.config?.marketing?.thankYouCoupon?.enabled !== false;
  const noExistingThankYouCoupon = !shop?.config?.marketing?.thankYouCoupon?.couponId;
  const existingThankYouCoupon = coupons.find(c => c.id === shop?.config?.marketing?.thankYouCoupon?.couponId);
  const isExistingThankYouCouponActive = existingThankYouCoupon?.status === 'active';
  const isThankYouOfferActive = thankYouCouponEnabled && (noExistingThankYouCoupon || isExistingThankYouCouponActive);

  const manageThankYouCouponSend = isThankYouOfferActive
    ? () => {
        dispatch(
          openModal({
            modalType: 'MANAGE_COUPON_MODAL',
            modalProps: {
              couponId: shop?.config?.marketing?.thankYouCoupon?.couponId || '',
              variant: 'thank-you',
            },
          }),
        );
      }
    : async () => {
        trackEvent('Automation Turned On', { type: 'Thank You' });
        if (!shop?.config?.marketing?.thankYouCoupon?.enabled) {
          await shopRepository.updateProps(shop.id, {
            'config.marketing.thankYouCoupon.enabled': true,
          });
        }
        const thankYouCoupon = shop?.config?.marketing?.thankYouCoupon?.couponId
          ? await couponRepository.get(shop?.config?.marketing?.thankYouCoupon?.couponId)
          : null;
        if (thankYouCoupon && thankYouCoupon?.status !== 'active') {
          await couponRepository.updateProps(thankYouCoupon.id, {
            status: 'active',
          });
        }
        await dispatch(getShopAction(shop.id));
        await dispatch(getCouponAction(shop.id));
      };

  const automations = [
    {
      menuOptions: [
        {
          label: `${newsletterMarketingSend?.enabled ? 'Manage' : 'Activate'} Automation`,
          onClick: manageNewsletterMarketingSend,
        },
        {
          label: 'Send Myself Test Emails',
          onClick: () => {
            dispatch(
              openModal({
                modalType: 'AUTOMATION_TEST_MODAL',
                modalProps: {
                  marketingSendId: 'newsletter-series',
                },
              }),
            );
          },
        },
      ],
      cardClick: () => history.push('/marketing/edit-newsletter-series'),
      imageSrc: ShopUpdateImage,
      status: newsletterMarketingSend?.enabled ? 'active' : 'inactive',
      name: 'Newsletter',
      subheader: 'Automatically send a twice weekly newsletter with relevant content & products.',
      handleSetUpClick: manageNewsletterMarketingSend,
      loading: getNewsletterMarketingSendLoading,
      pillText: 'Twice Weekly',
      totalSends: newsletterMarketingSend?.totalSends || 0,
      totalRevenue: newsletterMarketingSend?.totalRevenue || 0,
    },
    {
      menuOptions: welcomeSeriesFeatureFlag
        ? [
            {
              label: 'Manage Automation',
              onClick: () => history.push('/marketing/edit-welcome-series-emails'),
            },
            {
              label: 'Send Myself Test Emails',
              onClick: () => {
                dispatch(
                  openModal({
                    modalType: 'AUTOMATION_TEST_MODAL',
                    modalProps: {
                      marketingSendId: 'welcome-series',
                    },
                  }),
                );
              },
            },
          ]
        : [
            {
              label: `${subscriberMarketingSend?.status === 'active' ? 'Manage' : 'Activate'} Automation`,
              onClick:
                subscriberMarketingSend?.status === 'active'
                  ? () => history.push('/marketing/edit-new-subscriber-email')
                  : manageSubscriberMarketingSend,
            },
            // { label: 'Edit Coupon Amount', onClick: () => {} },
            ...(subscriberMarketingSend
              ? [
                  {
                    label: 'Send Myself a Test Email',
                    onClick: () => {
                      dispatch(
                        openModal({
                          modalType: 'AUTOMATION_TEST_MODAL',
                          modalProps: {
                            marketingSendId: subscriberMarketingSend?.id,
                          },
                        }),
                      );
                    },
                  },
                ]
              : []),
          ],
      cardClick: welcomeSeriesFeatureFlag ? () => history.push('/marketing/edit-welcome-series-emails') : null,
      imageSrc: NewSubscriberImage,
      status: welcomeSeriesFeatureFlag
        ? 'active'
        : subscriberMarketingSend?.status === 'active'
        ? 'active'
        : 'inactive',
      name: welcomeSeriesFeatureFlag
        ? 'Welcome Series'
        : `New Subscriber Coupon${coupon ? ` - ${getCouponValue(coupon)}` : ' - 10% Off'}`,
      subheader: welcomeSeriesFeatureFlag
        ? 'Automatically send 3 sequential emails to introduce a new customer to your shop.'
        : 'Automatically send a coupon to new email subscribers when they sign up.',
      handleSetUpClick: welcomeSeriesFeatureFlag ? () => {} : manageSubscriberMarketingSend,
      loading: welcomeSeriesLoading,
      pillText: welcomeSeriesFeatureFlag
        ? welcomeCoupon
          ? getCouponValue(welcomeCoupon)
          : ''
        : coupon
        ? getCouponValue(coupon)
        : '10% Off',
      totalSends: welcomeSeriesFeatureFlag ? welcomeStats?.totalSends || 0 : subscriberMarketingSend?.totalSends || 0,
      totalRevenue: welcomeSeriesFeatureFlag
        ? welcomeStats?.totalRevenue || 0
        : subscriberMarketingSend?.totalRevenue || 0,
    },
    {
      menuOptions: [
        {
          label: `${isThankYouOfferActive ? 'Manage' : 'Activate'} Automation`,
          onClick: manageThankYouCouponSend,
        },
        {
          label: 'Send Myself a Test Email',
          onClick: () => {
            dispatch(
              openModal({
                modalType: 'AUTOMATION_TEST_MODAL',
                modalProps: {
                  marketingSendId: 'thank-you',
                },
              }),
            );
          },
        },
      ],
      cardClick: () =>
        dispatch(
          openModal({
            modalType: 'MANAGE_COUPON_MODAL',
            modalProps: {
              couponId: shop?.config?.marketing?.thankYouCoupon?.couponId || '',
              variant: 'thank-you',
            },
          }),
        ),
      imageSrc: ThankYouImage,
      status: isThankYouOfferActive ? 'active' : 'inactive',
      name: 'Thank You Offer',
      subheader: 'Automatically send a promotional offer after a customer receives their order.',
      handleSetUpClick: manageThankYouCouponSend,
      pillText: existingThankYouCoupon ? getCouponValue(existingThankYouCoupon) : '5% Off',
      totalSends: thankYouStats?.totalSends || 0,
      totalRevenue: thankYouStats?.totalRevenue || 0,
    },
    {
      imageSrc: AbandonedCartImage,
      status: 'Coming Soon!',
      name: 'Abandoned Cart',
      subheader: 'Automatically send a reminder to shoppers who left products in their cart.',
      totalSends: '-',
      totalRevenue: '-',
    },
    {
      imageSrc: AbandonedOrderFormImage,
      status: 'Coming Soon!',
      name: 'Abandoned Custom Order Form',
      subheader: 'Automatically send a reminder to shoppers who didn’t complete their custom order request.',
      totalSends: '-',
      totalRevenue: '-',
    },
  ];

  return (
    <Grid container style={{ gap: '40px' }}>
      <Spinner show={getNewsletterMarketingSendLoading || getSubscriberMarketingSendLoading} />
      <Grid container direction="column">
        {/* <Typography variant="h3" className={classes.sectionHeader}>
          Marketing
        </Typography> */}
        <Grid container className={classes.emailsContainer}>
          {automations.map((automation, index) => generateEmailContainer({ ...automation, index }))}
        </Grid>
      </Grid>
      {/* <Grid container direction="column">
        <Typography variant="h3" className={classes.sectionHeader}>
          Transactional
        </Typography>
        <Grid container className={classes.emailsContainer}>
        </Grid>
      </Grid> */}
    </Grid>
  );
};

export default Automations;
