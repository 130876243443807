import {Attribution, getSource, MarketplaceIdentifier, GATracking} from "@castiron/domain";
import { clientSide } from "../ssr";
import React, { useEffect, useState } from "react";
import { propagateAttribution } from "./propagateAttribution";
import getCookie from "../getCookie";
import {removeEmpty} from "../removeEmpty";

declare global {
  interface Window { analytics: any; }
}
export const SESSION_ATTRIBUTION_KEY = 'castiron-session-attribution';

export const getGATracking = (gaStreamId: string): GATracking => {
  let sessionId = undefined;
  let clientId = undefined;

  try {
    const sessionText = getCookie(`_ga_${gaStreamId}`);
    if (sessionText) {
      sessionId = sessionText.split('.')[2];
    }

    const clientText = getCookie('_ga');
    if (clientText) {
      clientId = clientText.split('.').slice(2).join('.');
    }
  } catch (err) {
    console.error('Error getting GA4 tracking info', err);
  }

  return removeEmpty({
    ga_client_id: clientId,
    ga_session_id: sessionId
  });
}

export const determineAttribution = (config: { gaStreamId: string }): Attribution => clientSide(() => {
  const gaTracking = getGATracking(config.gaStreamId);

  try {
    const attribJson = sessionStorage.getItem(SESSION_ATTRIBUTION_KEY);

    let attrib: Attribution;
    if (attribJson) {
      const a = JSON.parse(attribJson);
      attrib = {
        ...a,
        gaTracking
      }
    } else {
      const referrer = window && window.document.referrer;
      const params = new URLSearchParams(window?.location?.search || '');

      attrib = removeEmpty({
        referringSourceUrl: referrer,
        source: getSource((referrer)),
        gaTracking,
        userAgent: window && window.navigator.userAgent,
        entryUrl: window && window.location.href,
        utmParams: {
          campaign: params.get('utm_campaign'),
          source: params.get('utm_source'),
          term: params.get('utm_term'),
          medium: params.get('utm_medium'),
          content: params.get('utm_content')
        }
      } as Attribution)
    }

    return attrib;
  } catch (err) {
    console.error('Error getting attribution', err);
    return {
      source: "unknown",
      referringSourceUrl: window?.document.referrer,
      gaTracking
    } as Attribution;
  }
});

export interface AttributionData {
  attribution: Attribution;
  updateAttribution?: (a: Attribution) => Attribution;
  tagAttribution?: (tag: string) => void;
}


export const AttributionContext = React.createContext<AttributionData>(undefined);

export const AttributionConfig: React.FC<{gaStreamId: string; marketplaceId: MarketplaceIdentifier | 'all'}> = (props) => {
  const [attribution, setAttribution] = useState<Attribution>();

  useEffect(() => {
    console.debug('Attribution', attribution);

    if (!attribution) {
      if (window?.analytics) {
        const timeout = setTimeout(() => {
          console.warn('Segment load timed out, propagating attribution anyway')
          propagateAttribution(props).then(a => setAttribution({
            ...a,
            marketplace: props.marketplaceId
          }));
        }, 5000);

        window.analytics.ready(() => {
          propagateAttribution(props).then(a => {
            setAttribution({
              ...a,
              marketplace: props.marketplaceId
            });
            clearTimeout(timeout);
          });
        });
      } else {
        propagateAttribution(props).then(a => setAttribution({
          ...a,
          marketplace: props.marketplaceId
        }));
      }
    }
  }, [attribution]);

  return <AttributionContext.Provider value={{
    attribution,
    updateAttribution: (a) => {
      setAttribution(a);
      sessionStorage.setItem(SESSION_ATTRIBUTION_KEY, JSON.stringify({
        ...a,
        marketplace: props.marketplaceId
      }));
      return a;
    },
    tagAttribution: (tag) => {
      setAttribution(a => {
        if (!a?.tags?.includes(tag)) {
          const newAtt = {
            ...a,
            tags: a?.tags ? [...a.tags, tag] : [tag],
            marketplace: props.marketplaceId
          };
          sessionStorage.setItem(SESSION_ATTRIBUTION_KEY, JSON.stringify(newAtt));
          return newAtt;
        } else {
          return {
            ...a,
            marketplace: props.marketplaceId
          };
        }
      });
    }
  }}>{props.children}</AttributionContext.Provider>
}
