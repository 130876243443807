import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { StickyFooterProps } from '../Onboarding/OnboardingComponents/OnboardingFooter';
import OnboardingWrapper from '../Onboarding/OnboardingComponents/OnboardingWrapper';
import SubscriptionCheckout from '../Onboarding/OnboardingComponents/SubscriptionCheckout';
import { LayoutPageProps } from '../Layout';
import { useAppSelector } from '../../hooks';

interface Props extends LayoutPageProps {}

const Unpaid: React.FC<Props> = (props: Props) => {
  const { setDisplayNav } = props;
  const history = useHistory();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const pathname = urlParams.get('pathname');

  const [header, setHeader] = useState('');
  const [subHeader, setSubHeader] = useState('');
  const [stickyFooterProps, setStickyFooterProps] = useState<StickyFooterProps>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { account } = useAppSelector(state => ({
    account: state.shops.account,
  }));

  useEffect(() => {
    setDisplayNav(false);
    localStorage.setItem('seenEarlyAdopterPage', 'true');
    // Navigate Home if a user happens to navigate to this page but they've already selected a plan
    if (['Castiron', 'Castiron Early Adopter'].includes(account?.tier?.name)) {
      history.push('/');
    }
    return () => {
      setDisplayNav(true);
    };
  }, [])

  const goHome = () => {
    history.push(pathname);
  };

  return (
    <OnboardingWrapper
      step={5}
      header={''}
      subHeader={''}
      stickyFooterProps={stickyFooterProps}
      isLoading={isLoading}
      prevStep={goHome}
      nextStep={goHome}
      isInternational={false}
      fromTapToPayApp={false}
      isUnpaidPage={true}
    >
      <SubscriptionCheckout
        setHeader={setHeader}
        setSubHeader={setSubHeader}
        setStickyFooterProps={setStickyFooterProps}
        setLoading={setIsLoading}
        onFinishOnboarding={goHome}
        isUnpaidPage={true}
      />
    </OnboardingWrapper>
  );
};

export default Unpaid;
