import React, {useEffect, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Theme,
  useTheme
} from "@material-ui/core";
import {calculateTotals, Transaction} from "@castiron/domain";
import ModalWrapper from "../../RootModal/ModalWrapper";
import {Button, Checkbox, Typography} from "@castiron/components";
import Close from "@material-ui/icons/Close";
import {closeModal} from "../../../store/reducers/modalConductor";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {Formik, FormikProps} from "formik";
import AdminForm from "../../AdminForm";
import moment from "moment/moment";
import * as yup from "yup";
import Dinero from "dinero.js";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import _ from "lodash";
import {getService} from "../../../firebase";
import {useHistory} from "react-router-dom";
import {transactionRepository} from "../../../domain";
import { useTracking } from '@castiron/utils';

export interface Props {
  show: boolean;
  transaction: Transaction;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginLeft: 8,
    padding: 16,
  },
  buttonsContainer: {
    padding: '12px 24px',
    borderTop: `1px solid ${theme.branding.gray[300]}`,
    minHeight: 80,
    [theme.breakpoints.down('xs')]: {
      marginTop: 'auto',
    },
  },
  closeIcon: {
    height: '24px',
    width: '24px',
  },
  content: {
    padding: '24px',
    [theme.breakpoints.up(510)]: {
      width: '450px',
      height: '440px',
    },
  },
  header: {
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
    height: '80px',
  },
  paperClass: {
    borderRadius: 32,
    width: 800,
  },
  select: {
    margin: '4px 0',
    width: '100%',
  },
  totalContainer: {
    backgroundColor: theme.branding.gray[200],
    borderRadius: '16px',
    padding: '24px',
  },
  selectBox: {
    marginTop: '16px',
    marginBottom: '8px'
  },
  selectOption: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  selectOptionContainer: {
    whiteSpace: 'break-spaces',
    '&:hover': {
      background: '#E6E6EA4D',
    },
    '&.Mui-selected': {
      background: '#E6E6EA4D',
    },
  },
  notificationsTitle: {
    marginTop: '32px',
    marginBottom: '16px'
  },
  notificationItem: {
    marginBottom: '16px'
  },
  notificationEmail: {
    color: theme.branding.gray["600"]
  }
}));

const markAsPaidSchema = yup.object().shape({
  date: yup.number(),
  type: yup.string(),
  sendCustomerReceipt: yup.boolean(),
  sendArtisanReceipt: yup.boolean()
});

const initialValues = {
  date: null,
  type: '',
  sendCustomerReceipt: false,
  sendArtisanReceipt: false
};

const addPaymentService = getService('orders', 'addpayment', {version: 2})

const MarkAsPaidModal: React.FC<Props> = (props: Props) => {
  const {show, transaction} = props;

  console.debug("TRANSACTION", {transaction})

  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const formRef = useRef<FormikProps<any>>();
  const history = useHistory();
  const { trackEvent } = useTracking();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [tx, setTx] = useState<Transaction>(transaction);

  const {shop} = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  useEffect(() => {
    if (transaction && transaction.order && !transaction.totals) {
      const totals = calculateTotals({
        order: transaction.order,
        paymentSettings: shop.paymentSettings
      });
      setTx((t) => ({
        ...t,
        totals
      }));
      transactionRepository.updateProps(transaction.id, {
        totals
      });
    }
  }, [transaction]);

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const onDateChange = (time) => {
    formRef.current.setFieldValue('date', time.unix());
  }

  const onTypeChange = (event) => {
    formRef.current.setFieldValue('type', event.target.value);
  }

  const handleSubmit = async (values) => {
    setIsSubmitting(true);

    await addPaymentService({
      transactionId: tx.id,
      paymentType: values.type,
      paidAt: values.date,
      amount: tx.totals.total,
      comms: {
        sendArtisanReceipt: values.sendArtisanReceipt,
        sendCustomerReceipt: values.sendCustomerReceipt
      }
    });

    trackEvent('Quote Marked as Paid', {
      transactionId: tx.id,
      paymentType: values.type,
    });

    history.push('/quotes/edit/' + tx.id, {
      markedAsPaid: true
    })

    setIsSubmitting(false);

    dispatch(closeModal());
  }

  return <ModalWrapper size="xs" show={show}>
    <Formik
      validationSchema={markAsPaidSchema}
      initialValues={initialValues}
      innerRef={formRef}
      onSubmit={handleSubmit}
    >
      {({dirty, errors, isValid, touched, values, setFieldValue, submitForm}) => (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DialogTitle className={classes.header}>
            <Grid container justify='space-between' alignItems='center'>
              <Grid item>
                <Grid container spacing={2} alignItems='center'>
                  <Grid item>
                    <Typography variant='h3'>Mark As Paid</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <IconButton>
                  <Close className={classes.closeIcon} onClick={handleClose}/>
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <Grid container item>
              <AdminForm style={{width: '100%'}}>
                <Grid item container className={classes.totalContainer} spacing={2}>
                  <Grid item container direction="row" justify="space-between">
                    <Grid item>
                      <Typography variant="subtitle1">Total</Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle1">{Dinero({amount: tx?.totals?.total || 0}).toFormat('$0.00')}</Typography>
                    </Grid>
                  </Grid>
                  {tx.order?.paymentDueDate && <Grid item>
                    <Typography
                      variant="body2">Due {moment.unix(tx?.order?.paymentDueDate).format('MMM DD, YYYY')}</Typography>
                  </Grid>}
                </Grid>
                <Grid item>

                </Grid>

                <Grid container item wrap='nowrap' alignItems='flex-start' style={{margin: '24px 0'}} direction="column"
                      xs={12} justify="space-between">
                  <Grid item>
                    <FormLabel required>Date Paid</FormLabel>
                  </Grid>
                  <Grid item>
                    <KeyboardDatePicker
                      autoOk
                      disablePast={false}
                      disableToolbar
                      variant="inline"
                      format="MM/DD/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      inputVariant="outlined"
                      value={values.date ? moment.unix(values.date) : null}
                      style={{width: '100%'}}
                      onChange={onDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'Fulfillment due date',
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid item container direction="column" xs={12}>
                  <Grid item>
                    <FormLabel required>Payment Method</FormLabel>
                  </Grid>
                  <Grid item>
                    <Select
                      variant="outlined"
                      placeholder="Select a method"
                      value={values.type}
                      onChange={onTypeChange}
                      fullWidth
                    >
                      {['credit-card', 'cash', 'check', 'venmo', 'paypal', 'square', 'other'].map(type => (
                        <MenuItem className={classes.selectOptionContainer} key={type} value={type}>
                          <Typography className={classes.selectOption} variant="body1">
                            {_.capitalize(type).replace(/-/g, ' ')}{type === 'other' && ' POS'}
                          </Typography>
                        </MenuItem>))}
                    </Select>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" className={classes.notificationsTitle}>Notifications</Typography>
                </Grid>
                {tx?.customerObj?.email &&
                  <Grid item container direction="row" xs={12} className={classes.notificationItem}>
                    <Grid item xs={1}>
                      <Checkbox onChange={(event) => setFieldValue('sendCustomerReceipt', event.target.checked)}/>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography variant="body2">Send my customer a payment confirmation email</Typography>
                      <Typography variant="body2"
                                  className={classes.notificationEmail}>{tx.customerObj.email}</Typography>
                    </Grid>
                  </Grid>
                }
                <Grid item container direction="row" xs={12} className={classes.notificationItem}>
                  <Grid item xs={1}>
                    <Checkbox onChange={(event) => setFieldValue('sendArtisanReceipt', event.target.checked)}/>
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant="body2">Send me a copy of the payment confirmation email</Typography>
                    <Typography variant="body2" className={classes.notificationEmail}>{shop.email}</Typography>
                  </Grid>
                </Grid>
              </AdminForm>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.button}
              onClick={handleClose}
              variant='outlined'
            >
              Cancel
            </Button>
            <Button
              className={classes.button}
              disabled={!dirty || !isValid || isSubmitting}
              type='submit'
              variant='contained'
              onClick={submitForm}
            >
              Update
            </Button>
          </DialogActions>
        </MuiPickersUtilsProvider>
      )}
    </Formik>
  </ModalWrapper>;
}

export default MarkAsPaidModal;
