import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Button, Grid, TextField, Theme} from "@material-ui/core";
import {getService} from "../../firebase";
import {Typography} from "@castiron/components";


const productGenService = getService('products', 'generateproduct');

interface Props {

}

const GenerateProduct: React.FC<Props> = (props: Props) => {
  const {  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>();
  const [notes, setNotes] = useState<string>();
  const [result, setResult] = useState<any>();

  const onSubmit = async () => {
    setLoading(true);
    const resp = await productGenService({
      title,
      notes
    });
    setResult(resp);
    setLoading(false);
  }

  return <Grid container xs={12} direction="column" style={{width: '100%'}}>
    <Grid item container direction="row" xs={12} style={{width: '100%'}}>
      <Grid item xs={12}><TextField label="Title" variant="outlined" id="title" fullWidth={true} onChange={(e) => setTitle(e.target.value)}/></Grid>
    </Grid>
    <Grid item container direction="row" xs={12} style={{width: '100%'}}>
      <Grid item xs={12}><TextField label="Notes" variant="outlined" id="notes" fullWidth={true} onChange={(e) => setNotes(e.target.value)}/></Grid>
    </Grid>
    <Grid item container direction="row" xs={12} style={{width: '100%'}}>
      <Grid item xs={12}><Button value="Generate" disabled={loading} onClick={onSubmit}>Generate</Button></Grid>
    </Grid>
    {result && (<>
      <Grid item container xs={12} style={{width: '100%'}}>
        <Grid item xs={3}><Typography variant="h4">Title:</Typography></Grid>
        <Grid item xs={9}>{result?.title}</Grid>
      </Grid>
      <Grid item container xs={12} style={{width: '100%'}}>
        <Grid item xs={3}><Typography variant="h4">Description:</Typography></Grid>
        <Grid item xs={9}>{result?.description}</Grid>
      </Grid>
      <Grid item container xs={12} style={{width: '100%'}}>
        <Grid item xs={3}><Typography variant="h4">Price:</Typography></Grid>
        <Grid item xs={9}>{result?.price}</Grid>
      </Grid>
      <Grid item container xs={12} style={{width: '100%'}}>
        <Grid item>
          <img src={result?.imageUrl}/>
        </Grid>
      </Grid>
    </>)}
    </Grid>;
}

export default GenerateProduct;
