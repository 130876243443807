import { nanoid } from 'nanoid';

export const getFulfillmentTemplates = (address, fulfillmentOptions) => {
  const emptyAddress = {
    fullAddress: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    region: '',
    regionName: '',
    country: '',
    postalCode: '',
  };

  const baseOption = {
    status: 'active',
    postalCode: '',
    leadTime: {
      increments: 0,
      incrementType: 'hour',
    },
    source: 'onboarding',
    address: emptyAddress,
    useBusinessAddress: false,
    afterPurchaseDetails: '',
    minimum: 0,
    sendPickupReminderEmail: false,
    description: '',
  };

  const pickup = {
    ...baseOption,
    id: nanoid(),
    displayName: 'Local Pickup',
    type: 'pickup',
    fee: 0,
    address: address || emptyAddress,
    useBusinessAddress: true,
    leadTime: {
      increments: 1,
      incrementType: 'day',
    },
    schedule: {
      dates: [],
      id: nanoid(),
      type: 'flexible',
      description: `I'll reach out after the order to finalize details.`,
    },
  };
  const delivery = {
    ...baseOption,
    id: nanoid(),
    displayName: 'Local Delivery',
    type: 'delivery',
    description: `I'll reach out after the order to finalize details.`,
    postalCode: address?.postalCode || '',
    leadTime: {
      increments: 1,
      incrementType: 'day',
    },
    schedule: {
      dates: [],
      id: nanoid(),
      type: 'flexible',
      description: `I'll reach out after the order to finalize details.`,
    },
  };
  const localShipping = {
    ...baseOption,
    id: nanoid(),
    displayName: `Shipping - Within ${address?.regionName || 'My State'} Only`,
    type: 'shipping',
    description: 'You will receive a notification from me when your order ships.',
  };
  const nationwideShipping = {
    ...baseOption,
    id: nanoid(),
    displayName: `Shipping - Nationwide`,
    type: 'shipping',
    description: 'You will receive a notification from me when your order ships.',
  };

  const fulfillmentTemplates: any[] = [delivery, pickup];
  if (fulfillmentOptions.includes('local-shipping')) {
    fulfillmentTemplates.push(localShipping);
  }
  if (fulfillmentOptions.includes('nationwide-shipping')) {
    fulfillmentTemplates.push(nationwideShipping);
  }

  return fulfillmentTemplates;
};
