import { useContext } from 'react';
import FeatureContext, { FeatureContextData } from './FeatureContext';
import { useSelector } from 'react-redux';
import { FeatureName, Shop } from '@castiron/domain';

const useFeatures: () => FeatureName[] = () => {
  const { features } = useContext<FeatureContextData>(FeatureContext);
  const { shop } = useSelector(state => ({
    //@ts-ignore
    shop: state.shops.shop as Shop,
  }));

  const tierFeatures = features || [];
  const tf = shop?.tags?.filter(t => t.startsWith('feature:')).map(t => t.substring(8));
  const tagFeatures = (tf || []) as FeatureName[];

  return [...tierFeatures, ...tagFeatures];
};

export default useFeatures;
