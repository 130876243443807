import React, { useEffect, useState, ReactNode } from 'react';
import { Box, Grid, IconButton, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomerDetailsBox from '../../CustomerDetailsBox';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useHistory, useLocation } from 'react-router';
import { updateCustomerAction, getCustomersAction } from '../../../store/reducers/customers';
import PencilIcon from '../../../assets/img/pencil-icon.png';
import { openModal } from '../../../store/reducers/modalConductor';
import { Customer } from '@castiron/domain';
import Close from '@material-ui/icons/Close';
import * as yup from 'yup';
import { Formik } from 'formik';
import AddSingleCustomer from '../AddCustomers/AddSingleCustomer';
import { Button, Typography } from '@castiron/components';
import AdminForm from '../../AdminForm';
import Spinner from '../../Spinner';

type Props = {
  customer: Customer;
  setCustomer?: (customer: Customer) => void;
  isEditable?: boolean;
  setFooterCTAs?: (ctas: ReactNode[]) => void;
  context?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: '1px solid #7777773D',
    borderRadius: 8,
    padding: 28,
  },
  deleteIcon: {
    cursor: 'pointer',
    color: theme.palette.error.main,
    position: 'absolute',
    top: 10,
    right: 50,
    zIndex: 1,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  icon: {
    height: 36,
    width: 'auto',
  },
  itemBox: {
    borderRadius: 8,
    border: `1px solid ${theme.branding.gray[400]}`,
    marginBottom: 20,
    paddingBottom: 32,
    width: '100%',

    '& >form': {
      paddingLeft: 16,
      paddingRight: 16,
    }
  },
  itemHeader: {
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
    padding: '0px 16px',
  },
  menuIcon: {
    cursor: 'pointer',
    color: theme.palette.error.main,
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 1,
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    marginTop: 18,
    marginBottom: 13,
  },
  closeButton: {
    position: 'absolute',
    right: 3,
    top: 3,
    zIndex: 1,
  },
  editImg: {
    width: 33,
    height: 33,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    padding: '16px 80px',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 20px',
    },
  },
  button: {
    minWidth: 80,
  },
}));

const CustomerDetails: React.FC<Props> = (props: Props) => {
  const { customer, setCustomer, isEditable = false, setFooterCTAs, context } = props;
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const startingMode = params.get('mode');
  const [editMode, setEditMode] = useState(startingMode === 'edit');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const submit = async values => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setIsSubmitting(true);
    const newCustomerData = {
      ...customer,
      ...values,
      subscriberOrigination: values.subscribed ? 'admin-update-customer-form' : '',
    };

    await dispatch(updateCustomerAction(newCustomerData));
    await dispatch(getCustomersAction(shop.id));
    setCustomer(newCustomerData);
    setEditMode(false);
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (startingMode === 'message') {
      dispatch(
        openModal({
          modalType: 'CONTACT_MODAL',
          modalProps: {
            show: true,
            email: customer?.email,
          },
        }),
      );
    }
  }, [location, history, dispatch]);

  const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?([\s.-])?\d{3}([\s.-])?\d{4}$/;
  const customerSchema = yup.object().shape({
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup
      .string()
      .email('Invalid email')
      .required("Please enter your contact's email"),
    mobileNumber: yup.string().matches(phoneRegExp, 'Please enter a valid 10-digit phone number'),
    notes: yup.string(),
    subscribed: yup.boolean(),
  });

  return (
    <Grid style={{ position: 'relative' }} item xs={12}>
      <Spinner show={isSubmitting} size={'fullscreen'} />
      {isEditable && (
        <IconButton
          onClick={(): void => setEditMode(!editMode)}
          className={classes.closeButton}
        >
          {editMode ? <Close /> : <img className={classes.editImg} src={PencilIcon} />}
        </IconButton>
      )}
      {editMode ? (
        <Box className={classes.itemBox}>
          <Grid container className={classes.itemHeader} alignItems="center">
            <Typography className={classes.title}>Customer</Typography>
          </Grid>
          <Formik
            initialValues={{
              firstName: customer?.firstName || '',
              lastName: customer?.lastName || '',
              email: customer?.email || '',
              mobileNumber: customer?.mobileNumber || '',
              notes: customer?.notes || '',
              subscribed: customer?.subscribed,
            }}
            validationSchema={customerSchema}
            onSubmit={submit}
            validateOnMount
          >
            {({ values, setFieldValue, isValid }) => (
              <AdminForm>
                <AddSingleCustomer
                  smallView
                  isOrder
                  setFooterCTAs={setFooterCTAs}
                  context={context}
                />
              </AdminForm>
            )}
          </Formik>
        </Box>
      ) : (
        <CustomerDetailsBox {...{ customer }} />
      )}
    </Grid>
  );
};

export default CustomerDetails;
