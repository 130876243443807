import { Banner, Typography, useFeatures } from '@castiron/components';
import { ChecklistValues } from '@castiron/domain';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getProductsAction } from '../../store/reducers/products';
import { LayoutPageProps } from '../Layout';
import HeaderTabs from '../Layout/Header/HeaderTabs';
import AvatarMenu from '../Menus/AvatarMenu';
import Spinner from '../Spinner';
import AddProductButton from './AddProductButton';
import EmptyEvent from './EmptyEvent';
import OrganizeCategories from './OrganizeCategories';
import ProductList from './ProductList';
import Templates, { categoryTemplates } from './Templates';

const useStyles = makeStyles((theme: Theme) => ({
  bannerColor: {
    color: theme.branding.v2.blue[500],
  },
  buttonContainer: {
    marginTop: 16,
    padding: '16px 24px',
    backgroundColor: theme.branding.v2.gray[0],
    bottom: '0',
    borderTop: `1px solid ${theme.branding.v2.gray[300]}`,
    display: 'flex',
    flexDirection: 'row',
    position: 'sticky',
    width: '100%',
    zIndex: 1000,
  },
  container: {
    width: '100%',
  },
}));

const Events: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setHeaderCTAs, setFooterCTAs } = props;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const initialTabValue = urlParams.get('tab');

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const features = useFeatures();
  const [templates, setTemplates] = useState<categoryTemplates[]>([]);
  const [isTemplatesLoading, setIsTemplatesLoading] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState(initialTabValue || 'Events');

  const { account, shop, isProductsLoading, products } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
    isProductsLoading: state.products.loading,
    products: state.products.products,
  }));

  const eventProducts = products.filter(product => product.type === 'event');
  const isProductsEmpty = eventProducts.length === 0;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const showTemplates = false;
  const hasOnboardingProducts = eventProducts && eventProducts.some(p => p.source === 'onboarding');

  useEffect(() => {
    setPageTitle('Events');

    return () => {
      setPageTitle('');
    };
  }, []);

  useEffect(() => {
    if (selectedTab !== 'Categories') {
      if (isMobile) {
        setHeaderCTAs([<AvatarMenu />]);
        setFooterCTAs([<AddProductButton fullWidth variant="contained" context="events" />]);
      } else {
        setHeaderCTAs([<AddProductButton variant="contained" context="events" />]);
        setFooterCTAs([]);
      }
    }
  }, [isMobile, products]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getProducts = async () => {
      dispatch(getProductsAction(shop.id));
    };

    if (shop?.id) getProducts();
  }, [shop]);

  const allCategories = [
    'Appetizers & Snacks',
    'Cooking Classes',
    'Desserts & Baked Goods',
    'Drinks',
    'Hot Food',
    'Shelf-Stable Products',
    'Whole Ingredients',
  ];

  const getTemplatesByPersona = async () => {
    // const templates = [];
    // if (account?.onboardingQuestions?.persona === 'other') {
    //   for (let i in allCategories) {
    //     const categoryName = allCategories[i];
    //     const categoryTemplates = (await productTemplateRepository.findActiveByCategory(categoryName)).filter(
    //       t => t.template?.type === 'standard',
    //     );
    //     if (categoryTemplates?.length > 0) {
    //       templates.push({
    //         categoryName,
    //         categoryTemplates,
    //       });
    //     }
    //   }
    // } else {
    //   const personaTemplates = await productTemplateRepository.findByPersona(account?.onboardingQuestions?.persona);
    //   for (let i in allCategories) {
    //     const categoryName = allCategories[i];
    //     const categoryTemplates = personaTemplates.filter(
    //       personaTemplate =>
    //         personaTemplate?.category === categoryName && personaTemplate.template?.type === 'standard',
    //     );
    //     if (categoryTemplates?.length > 0) {
    //       templates.push({
    //         categoryName,
    //         categoryTemplates,
    //       });
    //     }
    //   }
    // }
    // if (templates.length === 0) {
    //   for (let i in allCategories) {
    //     const categoryName = allCategories[i];
    //     const categoryTemplates = (await productTemplateRepository.findActiveByCategory(categoryName)).filter(
    //       t => t.template?.type === 'standard',
    //     );
    //     if (categoryTemplates?.length > 0) {
    //       templates.push({
    //         categoryName,
    //         categoryTemplates,
    //       });
    //     }
    //   }
    // }
    // setTemplates(templates);
    setIsTemplatesLoading(false);
  };

  const getTemplates = async () => {
    // const templates = [];
    // let shopCategories = getArtisanCategories(shop);
    // if (shopCategories.length === 0) {
    //   shopCategories = allCategories;
    // }
    // for (let i in shopCategories) {
    //   const categoryName = shopCategories[i];
    //   const categoryTemplates = (await productTemplateRepository.findActiveByCategory(categoryName)).filter(
    //     t => t.template?.type === 'standard',
    //   );
    //   if (categoryTemplates?.length > 0) {
    //     templates.push({
    //       categoryName,
    //       categoryTemplates,
    //     });
    //   }
    // }
    // setTemplates(templates);
    setIsTemplatesLoading(false);
  };

  useEffect(() => {
    account?.onboardingQuestions?.persona ? getTemplatesByPersona() : getTemplates();
  }, [shop]);

  var categoryNames = [];
  eventProducts.map(p => {
    if (!categoryNames.includes(p?.category?.name) && p?.category?.name != undefined) {
      categoryNames.push(p?.category?.name);
    }
  });

  const tabs = [
    {
      value: 'Events',
      content: <ProductList context="events" />,
    },
    // {
    //   value: 'Templates',
    //   content: <Templates templates={templates} context="events" />,
    // },
  ];

  if (categoryNames.length > 0) {
    tabs.splice(1, 0, {
      value: 'Categories',
      content: (
        <OrganizeCategories
          setHeaderCTAs={setHeaderCTAs}
          setFooterCTAs={setFooterCTAs}
          AddProductButton={<AddProductButton fullWidth variant="contained" context="events" />}
          context="events"
          products={eventProducts}
        />
      ),
    });
  }

  const templatesContent = isProductsEmpty ? (
    <Grid container style={isMobile ? {} : { marginTop: -24 }}>
      <Templates templates={templates} context="events" />
    </Grid>
  ) : (
    <HeaderTabs initialTabValue={initialTabValue || 'Events'} tabs={tabs} setSelectedTab={setSelectedTab} />
  );

  const productsContent = isProductsEmpty ? (
    <EmptyEvent />
  ) : (
    <HeaderTabs initialTabValue={initialTabValue || 'Events'} tabs={tabs} setSelectedTab={setSelectedTab} />
  );

  return (
    <Grid justify="center" className={classes.container} container>
      <Helmet>
        <title>Events | Castiron</title>
      </Helmet>
      <Spinner show={isProductsLoading || isTemplatesLoading} />
      <Grid container>
        {hasOnboardingProducts && !shop.checklistCompletions?.includes(ChecklistValues.StripeConnection) && (
          <Grid container item>
            <Banner variant="info-blue" customIcon={<ShoppingCartOutlinedIcon className={classes.bannerColor} />}>
              <Typography variant="body2" className={classes.bannerColor}>
                Based on the products & services you offer, we created these events to get you started!
              </Typography>
            </Banner>
          </Grid>
        )}
        {showTemplates ? templatesContent : productsContent}
      </Grid>
    </Grid>
  );
};

export default Events;
