import React, { ReactNode, useEffect, useState } from 'react';
import { Tab, Tabs, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { TabContext, TabPanel } from '@material-ui/lab';
import { useTracking } from '@castiron/utils';

export interface tabProps {
  label?: ReactNode;
  value: string;
  content: ReactNode;
}

interface Props {
  handleTabChange?: () => void;
  setSelectedTab?: (selectedTab: string) => void;
  initialTabValue: string;
  overrideTabValue?: string;
  tabs: tabProps[];
  centerTabs?: boolean;
  tabNavClassName?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  tabNav: {
    width: '100%',
    borderBottom: `1px solid ${theme.branding.gray[300]}`,
    '& .MuiTabs-flexContainer': {
      borderBottom: 'none',
    },
    '& .MuiTabs-scroller': {
      flex: 'initial',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.branding.gray[100],
      marginTop: -1,
      padding: 0,
      position: 'sticky',
      top: 64,
      zIndex: 50,
    },
  },
  tabPanel: {
    padding: 0,
    width: '100%',
  },
}));

const HeaderTabs: React.FC<Props> = (props: Props) => {
  const { handleTabChange, initialTabValue, overrideTabValue, tabs, setSelectedTab, centerTabs = false, tabNavClassName } = props;

  const classes = useStyles();
  const theme = useTheme();
  const { trackEvent } = useTracking();

  const [tab, setTab] = useState<string>(initialTabValue);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onTabChange = (event, selectedTab: string): void => {
    trackEvent('Header Tab Selected', { tab: selectedTab });
    setTab(selectedTab);
    setSelectedTab && setSelectedTab(selectedTab);
    handleTabChange && handleTabChange();
  };

  useEffect(() => {
    if (overrideTabValue) {
      onTabChange(undefined, overrideTabValue);
    }
  }, [overrideTabValue]);

  return (
    <TabContext value={tab}>
      <Tabs
        variant="scrollable"
        scrollButtons='auto'
        indicatorColor="primary"
        value={tab}
        onChange={onTabChange}
        aria-label="header-tabs"
        className={tabNavClassName || classes.tabNav}
        style={isMobile || centerTabs ? {justifyContent: 'center'} : {}}
      >
        {tabs.map((tab, index) => (
          <Tab
            role="button"
            tabIndex={index}
            id={`${tab.value}-tab`}
            aria-pressed={false}
            value={tab.value}
            label={tab.label || tab.value}
            key={`tab-${tab.value}-${index}`}
          />
        ))}
      </Tabs>
      {tabs.map((tab, index) => (
        <TabPanel value={tab.value} key={`tab-panel-${tab.value}-${index}`} className={classes.tabPanel}>
          {tab.content}
        </TabPanel>
      ))}
    </TabContext>
  );
};

export default HeaderTabs;
