import React, { ReactNode } from 'react';
import { Chip, Logo, ProgressBar, Typography } from '@castiron/components';
import { Divider, Grid } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import OnboardingFooter from './OnboardingFooter';
import { StickyFooterProps } from './OnboardingFooter';

interface Props {
  children: ReactNode;
  step: number;
  header: string;
  subHeader: string;
  stickyFooterProps?: StickyFooterProps;
  personaHasTemplates?: boolean;
  isLoading?: boolean;
  isInternational?: boolean;
  fromTapToPayApp: boolean;
  prevStep?: () => void;
  nextStep?: () => void;
  isUnpaidPage?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: 2,
  },
  childContainer: {
    width: 588,
    margin: '0px 16px',
  },
  chip: {
    color: theme.branding.v2.blue[500],
    background: theme.branding.v2.blue[50],
    marginTop: 10,
    marginBottom: 10,
  },
  header: {
    fontSize: 24,
  },
  logo: {
    height: 24,
    width: 135,
  },
  logoContainer: {
    paddingTop: 24,
  },
  progressBar: {
    padding: '24px 0px',
  },
  stickyFooterContainer: {
    borderTop: `1px solid ${theme.branding.v2.gray[200]}`,
    backgroundColor: theme.branding.v2.gray[0],
    position: 'fixed',
    bottom: 0,
    zIndex: 50,
    padding: '0px 24px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0px 16px',
    },
  },
}));

const OnboardingWrapper: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const {
    children,
    step,
    header,
    subHeader,
    prevStep,
    nextStep,
    stickyFooterProps,
    isLoading,
    isInternational,
    fromTapToPayApp,
    isUnpaidPage = false,
  } = props;
  const numOfSteps = fromTapToPayApp ? 4 : 5;

  const theme = useTheme();

  return (
    <Grid container>
      <Grid container item justify="center" alignItems="center" className={classes.logoContainer}>
        <Logo className={classes.logo} />
      </Grid>
      <Grid item xs={12} className={classes.progressBar}>
        {isUnpaidPage ? (
          <Divider style={{ color: theme.branding.v2.gray[200] }} />
        ) : (
          <ProgressBar
            fullwidth
            barHeight={10}
            borderRadiusRight={step == numOfSteps ? 1 : 100}
            borderRadiusLeft={1}
            percentage={step / numOfSteps}
          />
        )}
      </Grid>
      <Grid container justify="center" alignItems="center">
        <Grid container item className={classes.childContainer}>
          {!stickyFooterProps?.isFinalStep && !isUnpaidPage && (
            <Grid item container xs={12}>
              {!isInternational && (
                <Grid item xs={12}>
                  <Chip className={classes.chip}>Step {step}</Chip>
                </Grid>
              )}
              <Grid container item xs={12} alignContent="center">
                <Typography variant="h2" className={classes.header}>
                  {header}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">{subHeader}</Typography>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item className={classes.stickyFooterContainer}>
        <OnboardingFooter
          step={step}
          nextStep={nextStep}
          onNextClick={stickyFooterProps?.onNextClick}
          onBackClick={stickyFooterProps?.onBackClick}
          prevStep={prevStep}
          isFinalStep={stickyFooterProps?.isFinalStep}
          isLoading={isLoading}
          isInternational={isInternational}
          isUnpaidPage={isUnpaidPage}
        />
      </Grid>
    </Grid>
  );
};

export default OnboardingWrapper;
