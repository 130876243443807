import { useConfig } from '@castiron/castiron-firebase';
import { Banner } from '@castiron/components';
import { makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router';
import { useAppSelector } from '../../../hooks';

const useStyles = makeStyles((theme: Theme) => ({
  banner: {
    cursor: 'pointer',
    borderRadius: 0,
  },
}));

const EarlyAdopterBanner: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const { account } = useAppSelector(state => ({
    account: state.shops.account,
  }));

  const ffconfig = useConfig();
  const earlyAdopterDiscountEnd = ffconfig && ffconfig.config().getNumber('feature_castiron_early_adopter_end_date');
  const todayUnix = moment().unix();
  const isEarlyAdopter = account?.isEarlyAdopter() && todayUnix < earlyAdopterDiscountEnd;

  return (
    isEarlyAdopter && (
      <div onClick={() => history.push(`/store/plans/select?pathname=${location.pathname}`)}>
        <Banner variant="error" className={classes.banner}>
          <Typography variant="body2" style={{ color: theme.branding.v2.red[500] }}>
            Please select your new subscription before January 1st to retain access to your account.
            <span style={{ marginLeft: 16 }}>
              <b>
                <u>Subscribe</u>
              </b>
            </span>
          </Typography>
        </Banner>
      </div>
    )
  );
};

export default EarlyAdopterBanner;
