import { Typography } from '@castiron/components';
import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { getService } from '../../../firebase';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { openModal } from '../../../store/reducers/modalConductor';
import SimpleActionAlert from '../../SimpleAlert/SimpleActionAlert';

const sendPreviewEmailService = getService('messaging', 'sendpreviewemail');

const useStyles = makeStyles((theme: Theme) => ({}));

export interface Props {
  marketingSendId: string;
}

const AutomationTest: React.FC<Props> = (props: Props) => {
  const { marketingSendId } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const emailCount = marketingSendId === 'welcome-series' ? 3 : marketingSendId === 'newsletter-series' ? 2 : 1;

  const handleSendEmail = async () => {
    try {
      await sendPreviewEmailService({ to: shop.email, marketingSendId, shopId: shop.id });
      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT_V2',
          modalProps: {
            show: true,
            content: `Check your inbox! Test email${emailCount > 1 ? 's' : ''} sent!`,
            buttonText: 'Close',
          },
        }),
      );
    } catch (err) {
      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT_V2',
          modalProps: {
            show: true,
            content: `Error sending test email${emailCount > 1 ? 's' : ''}.`,
            buttonText: 'Close',
          },
        }),
      );
    }
  };

  return (
    <SimpleActionAlert
      show
      title={`Send Test Email${emailCount > 1 ? 's' : ''}`}
      content={
        <Typography variant="body1" style={{ textAlign: 'center' }}>
          {emailCount}{emailCount > 1 ? ' consecutive ' : ' '}email{emailCount > 1 ? 's' : ''} will be sent to <b>{shop.email}</b>
        </Typography>
      }
      buttonText={`Send Test Email${emailCount > 1 ? 's' : ''}`}
      actionText="Sending"
      color="default"
      handleAction={handleSendEmail}
    />
  );
};

export default AutomationTest;
