import Input from './Input';
import Label from './Label';
import ValidationError from './Errors/ValidationError';
import SubmissionError from './Errors/SubmissionError';
import Date from './Date';
import DateTime from './DateTime';
import Checkbox from './Checkbox/Checkbox';
import LogFormikErrors from './LogFormikErrors'

const Forms = {
  Input,
  Label,
  ValidationError,
  SubmissionError,
  Date,
  DateTime,
  Checkbox,
  LogFormikErrors
};

export default Forms;
