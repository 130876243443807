import { ButtonV2, Chip, DateInput, TimeInput, Typography, useFeatures } from '@castiron/components';
import { defaultTimeZone, getProductStatus, hasSchedule } from '@castiron/utils';
import { Box, FormControl, Grid, MenuItem, Select, Theme, makeStyles, useTheme } from '@material-ui/core';
import { nanoid } from '@reduxjs/toolkit';
import { useFormikContext } from 'formik';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { openModal } from '../../../../../src/store/reducers/modalConductor';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import Tooltip from '../../../Tooltip';

type Props = {
  context: 'presale' | 'product' | 'bulkProductActions';
};

const useStyles = makeStyles((theme: Theme) => ({
  requiredDecorator: {
    color: theme.branding.v2.red[500],
  },
  statusDecorator: {
    color: theme.branding.v2.green[500],
    marginRight: 16,
    fontSize: 22,
  },
}));

const ProductStatus: React.FC<Props> = (props: Props) => {
  const { context } = props;

  const classes = useStyles();
  const theme = useTheme();
  const features = useFeatures();
  const dispatch = useAppDispatch();

  const { errors, initialValues, values, setFieldValue }: any = useFormikContext();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const timeZone = shop?.config?.timeZone || defaultTimeZone;
  /* set default to noon today */
  const defaultStartTime = moment()
    .tz(timeZone)
    .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
    .unix();
  const defaultEndTime = moment()
    .tz(timeZone)
    .set({ hour: 12, minute: 30, second: 0, millisecond: 0 })
    .unix();
  const [showSchedule, setShowSchedule] = useState<boolean>(hasSchedule(values));

  const statusOptions = [
    {
      label: (
        <Typography variant="body1">
          <span className={classes.statusDecorator}>•</span>Active
        </Typography>
      ),
      value: 'active',
    },
    {
      label: (
        <Typography variant="body1">
          <span className={classes.statusDecorator} style={{ color: theme.branding.v2.red[500] }}>
            •
          </span>
          Inactive
        </Typography>
      ),
      value: 'inactive',
    },
    ...(((context === 'product') && features.includes('admin.products.schedule')) || context === 'presale' || context === 'bulkProductActions'
      ? [
          {
            label: (
              <Typography variant="body1">
                <span className={classes.statusDecorator}>◦</span>Scheduled
              </Typography>
            ),
            value: 'scheduled',
          },
        ]
      : []),
  ];

  const visibilityChip = (
    <Chip colorScheme={getProductStatus(values, timeZone) === 'active' ? 'green' : 'red'}>
      {getProductStatus(values, timeZone) === 'active' ? 'Visible' : 'Not Visible'}
    </Chip>
  );

  const handleStatusChange = async event => {
    const status = event.target.value;
    const isSchedule = event.target.value === 'scheduled';
    if (isSchedule) {
      if ((context === 'product' && features.includes('admin.products.schedule')) || context === 'presale' || context === 'bulkProductActions') {
        setShowSchedule(true);
        await setFieldValue('schedule', {
          id: nanoid(),
          startTime: defaultStartTime,
          endTime: defaultEndTime,
        });
      } else {
        dispatch(
          openModal({
            modalType: 'LEGACY_GATING_MODAL',
            modalProps: {
              show: true,
            },
          }),
        );
      }
    } else {
      setShowSchedule(false);
      await setFieldValue('schedule', {});
      await setFieldValue('status', status);
    }
  };

  const handleDayChange = async (day: moment.Moment | null, field: 'startTime' | 'endTime') => {
    if (day && day.isValid()) {
      const initialStartTime = values?.schedule?.startTime;
      const initialEndTime = values?.schedule?.endTime;
      const newTime = moment.unix(field === 'startTime' ? initialStartTime : initialEndTime);
      newTime.set({
        year: day.get('year'),
        month: day.get('month'),
        date: day.get('date'),
      });
      await setFieldValue(`schedule.${field}`, newTime.tz(timeZone, true).unix());
    }
  };

  const handleTimeChange = async (time: moment.Moment | null, field: 'startTime' | 'endTime') => {
    if (time && time.isValid()) {
      const initialStartTime = values?.schedule?.startTime;
      const initialEndTime = values?.schedule?.endTime;
      const newTime = moment.unix(field === 'startTime' ? initialStartTime : initialEndTime);
      newTime.set({
        hour: time.get('hour'),
        minute: time.get('minute'),
        second: 0,
        millisecond: 0,
      });
      await setFieldValue(`schedule.${field}`, newTime.tz(timeZone, true).unix());
    }
  };

  return (
    <Box>
      {values.status === 'archived' ? (
        <ButtonV2
          variant="outlined"
          fullWidth
          onClick={() => {
            setFieldValue('schedule', {});
            setFieldValue('status', 'active');
          }}
        >
          Activate
        </ButtonV2>
      ) : (
        <>
          {context !== 'bulkProductActions' && (
            <>
              <Grid container alignItems="center" style={{ marginBottom: 8 }}>
                <Typography variant="subtitle2">Visibility</Typography>
                <Tooltip arrow title={`Is this ${context === 'presale' ? 'presale' : values.type === 'event' ? 'event' : 'product'} currently visible on your website?`} />
              </Grid>
              {visibilityChip}
            </>
          )}
          <FormControl variant="outlined" style={{ width: '100%' }}>
            <Typography variant="subtitle2">
              {context === 'bulkProductActions' ? (
                'New Product Status'
              ) : (
                <>
                  Status <span className={classes.requiredDecorator}>*</span>
                </>
              )}
            </Typography>
            <Select
              value={hasSchedule(values) ? 'scheduled' : values?.status}
              onChange={handleStatusChange}
              style={{
                height: '56px',
              }}
            >
              {statusOptions.map((option, index) => (
                <MenuItem key={`status-label-${index}`} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {showSchedule && (
              <>
                <Grid container direction="column" style={{ marginBottom: -8, marginTop: 16, gap: 8 }}>
                  <Grid
                    container
                    item
                    direction={context === 'bulkProductActions' ? 'row' : 'column'}
                    wrap="nowrap"
                    style={context === 'bulkProductActions' ? { gap: '8px' } : {}}
                  >
                    <DateInput
                      allowKeyboardInput={false}
                      disablePast
                      disablePastDateError={values?.schedule?.startTime === initialValues?.schedule?.startTime}
                      label="Start Date"
                      selectedDate={moment.unix(values?.schedule?.startTime)}
                      onChange={date => handleDayChange(date, 'startTime')}
                      required
                      addErrorStyling={moment
                        .unix(values?.schedule?.startTime)
                        .isAfter(moment.unix(values?.schedule?.endTime), 'day')}
                    />
                    <TimeInput
                      label="Start Time"
                      name="schedule.startTime"
                      required
                      onChange={time => handleTimeChange(time, 'startTime')}
                      timeZone={timeZone}
                      addErrorStyling={!!errors?.schedule}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    direction={context === 'bulkProductActions' ? 'row' : 'column'}
                    wrap="nowrap"
                    style={context === 'bulkProductActions' ? { gap: '8px' } : {}}
                  >
                    <DateInput
                      allowKeyboardInput={false}
                      disablePast
                      disablePastDateError={values?.schedule?.endTime === initialValues?.schedule?.endTime}
                      label="End Date"
                      selectedDate={moment.unix(values?.schedule?.endTime)}
                      onChange={date => handleDayChange(date, 'endTime')}
                      required
                      addErrorStyling={moment
                        .unix(values?.schedule?.startTime)
                        .isAfter(moment.unix(values?.schedule?.endTime), 'day')}
                    />
                    <TimeInput
                      label="End Time"
                      name="schedule.endTime"
                      required
                      onChange={time => handleTimeChange(time, 'endTime')}
                      timeZone={timeZone}
                      addErrorStyling={!!errors?.schedule}
                    />
                  </Grid>
                </Grid>
                {errors?.schedule && (
                  <Typography style={{ color: '#ff2b26' }} variant="caption3">{`${errors?.schedule?.startTime || ''}${
                    errors?.schedule?.startTime ? ', ' : ''
                  }${errors?.schedule?.endTime || ''}`}</Typography>
                )}
              </>
            )}
          </FormControl>
        </>
      )}
    </Box>
  );
};

export default ProductStatus;
