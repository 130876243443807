import React from 'react';
import { CheckboxInput, NumberInput, SwitchInput, Typography } from '@castiron/components';
import { Box, Grid, Theme, makeStyles, useTheme } from '@material-ui/core';
import { useFormikContext } from 'formik';
import _ from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  checkbox: {
    paddingLeft: 0,
  },
}));

const InventoryInput: React.FC = () => {
  const { values, errors, touched } = useFormikContext<any>();
  const isEvent = _.get(values, 'type') === 'event';

  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box style={{ marginTop: '16px' }}>
      {isEvent && (
        <Typography variant="subtitle2" style={{ marginBottom: 8 }}>
          Event Settings
        </Typography>
      )}
      <Grid container direction="column" spacing={1}>
        <Grid container item direction="column">
          <CheckboxInput
            className={classes.checkbox}
            name="useInventory"
            variant="label-right"
            label={
              isEvent ? (
                <>
                  <Typography variant="body2">Limit Event Size</Typography>
                  <Typography style={{ color: theme.branding.v2.gray[500] }} variant="body2">
                    Set a limit for how many tickets are available to manage event size.
                  </Typography>
                </>
              ) : (
                'Track Inventory'
              )
            }
          />
        </Grid>
        {_.get(values, 'useInventory') && (
          <Grid item>
            <NumberInput
              label={isEvent ? 'Number of Tickets Available' : 'Inventory'}
              name="inventory"
              required
              error={touched.inventory && errors.inventory}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default InventoryInput;
