export { default as capitalize } from './capitalize';
export { default as titleCase } from './titleCase';
export { default as trackEvent, trackUser, trackPage } from './events/trackEvent';
export { default as exitIntent } from './events/exitIntent';
export { useWindowSize } from './useWindowSize';
export { TimeConversion, CurrencyConversion } from './serialize';
export { useTracking } from './hooks/useTracking';
export { removeEmpty } from './removeEmpty'
export { removeEmptyStrings } from './removeEmptyStrings'
export { default as removeTags } from './removeTags';
export { download } from './dowload';
export { toQueryString } from './urlUtils';
export { determineAttribution, AttributionContext, AttributionConfig, getGATracking, SESSION_ATTRIBUTION_KEY } from './attribution'
export { propagateAttribution } from './attribution/propagateAttribution';
export * from './textUtils';
export { defaultTimeZone, timeFormat, timeZoneOptions, formatTimeZone, sharesDayWithRange } from './timeUtils';
export { clientSide } from './ssr';
export { stateShippingList } from "./stateUtils";
export { default as getArtisanCategories } from './getArtisanCategories';
export { default as formatArtisanCategory } from './formatArtisanCategory';
export * from './productUtils';
export { findFontFamily } from './findFontFamily';
export { createTrackingNumberUrl } from './createTrackingNumberUrl';

export type { AttributionData } from './attribution'
export type { UnregisterHolder } from './hooks/useTracking';
export { countryList } from './countryList';

export function isset(variable: any): boolean {
    return (variable === null) ? false : typeof variable !== typeof undefined;
}