import React, { useEffect } from 'react';
import { Grid, Link, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { LayoutPageProps } from '../Layout';
import AvatarMenu from '../Menus/AvatarMenu';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: 17,
    },
  },
  subtitle: {
    fontSize: 16,
    lineHeight: '25px',
    color: theme.branding.gray[700],
    marginBottom: 14,
  },
}));

const ContactUs: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;

  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setPageTitle('Contact Us');

    return () => {
      setPageTitle('');
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setHeaderCTAs([<AvatarMenu />]);
    } else {
      setHeaderCTAs([]);
    }
  }, [isMobile]);

  useEffect(() => {
    setFooterCTAs([]);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Contact Us | Castiron</title>
      </Helmet>
      <Grid container>
        <Grid className={classes.container} item xs={12} lg={8}>
          <Typography paragraph className={classes.subtitle}>
            Have a question or feedback? We’d love to hear from you
          </Typography>
          <Typography paragraph>
            Castiron is dedicated to providing software which empowering cooks, bakers, chefs and food artisans to
            fulfill their dreams of entreprenuerial business success.
          </Typography>
          <Typography paragraph>
            Our cooks come first. Without our creators, we are nothing. We will always put you first and constantly ask
            ourselves “Is it good for our cooks?” before making decisions.
          </Typography>
          <Typography paragraph>
            We are always open to ideas, thoughts questions or comments you may have about our software. You can reach
            out to us with thoughts at the following address:{' '}
            <Link href="mailto:support@castiron.me">support@castiron.me</Link>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactUs;
