import * as React from 'react';
import SvgIcon, { SvgIconProps } from '../SvgIcon';

export const GiftOutlinedIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 22 22" {...props}>
    <svg width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 2C6.10218 2 5.72064 2.15804 5.43934 2.43934C5.15804 2.72064 5 3.10218 5 3.5C5 3.89782 5.15804 4.27936 5.43934 4.56066C5.72064 4.84196 6.10218 5 6.5 5H9.65402C9.53195 4.68061 9.37741 4.32942 9.18628 3.98192C8.55732 2.83834 7.6939 2 6.5 2ZM3 3.5C3 4.02384 3.11743 4.53557 3.33772 5H1C0.447715 5 0 5.44772 0 6V11C0 11.5523 0.447715 12 1 12H2V21C2 21.5523 2.44772 22 3 22H11H19C19.5523 22 20 21.5523 20 21V12H21C21.5523 12 22 11.5523 22 11V6C22 5.44772 21.5523 5 21 5H18.6623C18.8826 4.53557 19 4.02384 19 3.5C19 2.57174 18.6313 1.6815 17.9749 1.02513C17.3185 0.368749 16.4283 0 15.5 0C13.1939 0 11.8073 1.66166 11.0613 3.01808C11.0405 3.0559 11.0201 3.09368 11 3.13141C10.9799 3.09368 10.9595 3.0559 10.9387 3.01808C10.1927 1.66166 8.8061 0 6.5 0C5.57174 0 4.6815 0.368749 4.02513 1.02513C3.36875 1.6815 3 2.57174 3 3.5ZM12.346 5H15.5C15.8978 5 16.2794 4.84196 16.5607 4.56066C16.842 4.27936 17 3.89782 17 3.5C17 3.10218 16.842 2.72064 16.5607 2.43934C16.2794 2.15804 15.8978 2 15.5 2C14.3061 2 13.4427 2.83834 12.8137 3.98192C12.6226 4.32942 12.4681 4.68061 12.346 5ZM12 7V10H19H20V7H15.5H12ZM6.5 7H10V10H3H2V7H6.5ZM12 20H18V12H12V20ZM10 12V20H4V12H10Z"
      />
    </svg>
  </SvgIcon>
);
