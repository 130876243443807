import React, { ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Grid, IconButton, useMediaQuery } from '@material-ui/core';
import { Transaction, TransactionContext } from '@castiron/domain';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { ArrowBack, Menu } from '@material-ui/icons';
import { OrderStatusPill, ProFeatureChip, Typography } from '@castiron/components';
import { useAppSelector } from '../../../hooks';
import { useConfig } from '@castiron/castiron-firebase';
import TrialExpirationBanner from '../../SelectPlan/PlanComponents/TrialExpirationBanner';
import { showFirstMonthPromoBlock } from '../../ReferralPrompts/PromoBlock';
import EarlyAdopterBanner from '../../SelectPlan/PlanComponents/EarlyAdopterBanner';

export interface HeaderProps {
  pageTitle: string;
  pageIsProFeature: boolean;
  backLocation?: boolean | string;
  headerCTAs: ReactNode[];
  setDrawerOpen?: (setValue: boolean) => void;
  longTitle?: boolean;
  transaction?: Transaction;
  transactionContext?: TransactionContext;
}

const useStyles = makeStyles((theme: Theme) => ({
  backArrow: {
    height: '24px',
    width: '24px',
    [theme.breakpoints.down('sm')]: {
      height: '20px',
      width: '20px',
    },
  },
  backArrowContainer: {
    height: '48px',
    width: '48px',
    /* i have no idea why this isn't centering with even padding */
    padding: '10px 12px 14px',
    border: `1px solid ${theme.branding.gray[300]}`,
    borderRadius: '12px',
    [theme.breakpoints.down('sm')]: {
      height: '40px',
      width: '40px',
      /* even more pronounced here, no idea why */
      padding: '4px 10px 16px',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  headerContainer: {
    position: 'sticky',
    top: '0',
    backgroundColor: theme.branding.gray[100],
    borderBottom: `1px solid ${theme.branding.gray[300]}`,
    zIndex: 50,
  },
  headerContent: {
    height: '80px',
    padding: '16px 76px',
    [theme.breakpoints.down('sm')]: {
      height: '64px',
      padding: '12px',
    },
  },
  headerTitle: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  headerTitleContainer: {
    width: '100%',
    textAlign: 'center',
  },
}));

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const {
    pageTitle,
    pageIsProFeature,
    headerCTAs,
    backLocation,
    setDrawerOpen,
    longTitle,
    transaction,
    transactionContext,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const config = useConfig();

  const { account, userState } = useAppSelector(state => ({
    account: state.shops.account,
    userState: state.shops.userState,
  }));

  const onHomePage = location.pathname === '/' || location.pathname === '/new';

  /* Don't show for legacy users trialing Basic */
    const showTrialExpirationBanner =
       onHomePage &&
       (userState === 'inTrial' || (userState === 'legacyInTrial' && account?.subscription?.plan?.name !== 'Basic')) && account?.createdAt >= 1734325200; // only show for new accounts created after December 15th at midnight

  const showPromoBanner = onHomePage && showFirstMonthPromoBlock(account);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleBackArrowClick = () => {
    /* will allow us to override the back location in the future, if need be */
    if (typeof backLocation === 'string') {
      history.push(backLocation);
    } else {
      history.goBack();
    }
  };

  const backArrow = (
    <Box className={classes.backArrowContainer} onClick={handleBackArrowClick}>
      <ArrowBack className={classes.backArrow} />
    </Box>
  );

  const ctaCount = headerCTAs.length;

  /* TODO:
   * got the closest I could with typographies
   * fix header variants with new typography changes come in
   */

  const desktopHeader = (
    <Grid container className={classes.headerContent} alignItems="center">
      <Grid item xs={longTitle ? 8 : 5}>
        <Grid container alignItems="center" spacing={3} wrap="nowrap">
          {backLocation && <Grid item>{backArrow}</Grid>}
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography variant="h2">{pageTitle}</Typography>
              </Grid>
              {transaction && (
                <Grid item>
                  <OrderStatusPill transaction={transaction} context={transactionContext} />
                </Grid>
              )}
              {pageIsProFeature && !account.isCastironPlus() && account.isInTrial() && (
                <Grid item>
                  <ProFeatureChip />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={longTitle ? 4 : 7} justify="flex-end" spacing={1} wrap="nowrap" alignItems="center">
        {headerCTAs.map((cta, index) => (
          <Grid item key={`headerCTA${index}`}>
            {cta}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );

  const mobileHeader = (
    <Grid container className={classes.headerContent}>
      <Grid item container xs={ctaCount > 1 ? 4 : 3} alignItems="center">
        <Grid item>
          <IconButton onClick={(): void => setDrawerOpen(true)}>
            <Menu />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item container xs={ctaCount > 1 ? 4 : 6} alignItems="center">
        <Grid item className={classes.headerTitleContainer}>
          <Grid container justify="center" alignItems="center" spacing={1}>
            <Grid item>
              <Typography variant="h3" className={classes.headerTitle}>
                {pageTitle}
              </Typography>
            </Grid>
            {pageIsProFeature && !account.isCastironPlus() && account.isInTrial() && (
              <Grid item>
                <ProFeatureChip variant="small" />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={ctaCount > 1 ? 4 : 3} alignContent="center">
        <Grid container justify="flex-end" alignItems="center" spacing={ctaCount > 1 ? 2 : 0}>
          {headerCTAs.map((cta, index) => (
            <Grid item key={`headerCTA${index}`}>
              {cta}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Box className={classes.headerContainer}>
      <EarlyAdopterBanner />
      {(showTrialExpirationBanner || showPromoBanner) && <TrialExpirationBanner />}
      {isMobile ? mobileHeader : desktopHeader}
    </Box>
  );
};

export default Header;
