import { CustomProduct, FulfillmentOption, Product, Shop, ShopTheme, TicketedEvent } from '@castiron/domain';
import { getAllergensList, getProductFulfillmentTypes, getDietaryList } from '@castiron/utils';
import { Avatar, Divider, Grid } from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';
import { CollapsableCard } from '../Cards';
import Typography from '../Typography';
import Dinero from 'dinero.js';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ShoppingBasketOutlinedIcon from '@material-ui/icons/ShoppingBasketOutlined';
import Banner from '../Banner';
import LocationOnOutlined from '@material-ui/icons/LocationOnOutlined';
import { ButtonV2 } from '../Button';
import { CalendarIcon, Link, Pill } from '..';
import _ from 'lodash';
import { Moment } from 'moment';
import momentTimezone from 'moment-timezone';

interface Props {
  product: Product | CustomProduct | TicketedEvent;
  readonly?: boolean;
  sectionRoundness?: string;
  themeProps?: ShopTheme;
  shopFulfillments?: FulfillmentOption[];
  onFulfillmentsClick?: () => void;
  hasPresale?: boolean;
  seller: Shop;
  timeZone?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  collapsableCard: {
    marginBottom: '0 !important',
  },
  container: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    backgroundColor: theme.branding.v2.gray[0],
    padding: '24px 24px 0px 24px',
  },
  contentClassName: {
    padding: '16px 0px !important',
  },
  description: {
    marginBottom: 24,
    '& p': {
      marginTop: '0',
      marginBottom: '0',
    },
  },
  fulfillmentBanner: {
    margin: '16px 0px',
  },
  headerClassName: {
    padding: '24px 0px !important',
  },
  icon: {
    height: 16,
    width: 16,
  },
  iconContainer: {
    borderRadius: 32,
    height: 32,
    marginRight: 8,
    padding: 8,
    width: 32,
  },
  priceSection: {
    gap: 8,
    margin: '8px 0px 24px 0px',
  },
}));

const ProductInformation: React.FC<Props> = (props: Props) => {
  const { product, readonly, sectionRoundness, themeProps, shopFulfillments = [], onFulfillmentsClick, hasPresale = false, seller, timeZone } = props;

  const classes = useStyles();
  const theme = useTheme();

  let allergens = product?.allergen || [];
  let dietary = product?.dietary || [];

  const productFFTypes = getProductFulfillmentTypes(product, shopFulfillments, hasPresale, 'productModal');
  const isEvent = product?.type === 'event';
  const isNourysh = seller?.discriminator === 'nourysh';

  const sellerSection = (
    <Grid container item wrap="nowrap" style={{ gap: '8px', marginTop: '16px' }} alignItems="center">
      <Avatar
        alt={seller?.businessName}
        src={seller?.logoImageObj?.mediumVersion?.downloadUrl || seller?.logoImageObj?.downloadUrl}
      />
      <Grid container item direction="column" wrap="nowrap">
        <Link
          href={isNourysh ? `https://shop.nourysh.com/${seller?.websiteUrl}` : `https://shop.castiron.me/${seller?.websiteUrl}`}
          underline
          style={{ color: 'inherit', textDecoration: 'underline' }}
          target='_blank'
        >
          {seller?.businessName}
        </Link>
        {seller?.physicalAddress?.city && (seller?.physicalAddress?.regionName || seller?.physicalAddress?.region) && (
          <Typography variant="body1">
            {seller?.physicalAddress?.city}, {seller?.physicalAddress?.regionName || seller?.physicalAddress?.region}
          </Typography>
        )}
      </Grid>
    </Grid>
  );

  let fulfillmentSection = <></>;
  if (product?.type === 'standard') {
    if (!isNourysh) {
      fulfillmentSection = (
        <Banner
          backgroundColor={theme.branding.v2.gray[50]}
          className={classes.fulfillmentBanner}
          customIcon={
            <LocationOnOutlined style={{ color: theme.branding.v2[themeProps?.shopButtonColor || 'blue'][500] }} />
          }
          variant="info-white"
        >
          <Grid container item wrap="nowrap" justify="space-between">
            <Typography variant="body2">{productFFTypes}</Typography>
            {onFulfillmentsClick &&
              productFFTypes !== 'Sorry, there are no fulfillment options currently available for this product.' && (
                <ButtonV2 onClick={onFulfillmentsClick} variant="text">
                  Details
                </ButtonV2>
              )}
          </Grid>
        </Banner>
      );
    } else if (
      isNourysh &&
      productFFTypes === 'Sorry, there are no fulfillment options currently available for this product.'
    ) {
      fulfillmentSection = (
        <Banner
          backgroundColor={theme.branding.v2.gray[50]}
          className={classes.fulfillmentBanner}
          customIcon={
            <LocationOnOutlined style={{ color: theme.branding.v2[themeProps?.shopButtonColor || 'blue'][500] }} />
          }
          variant="info-white"
        >
          <Grid container item wrap="nowrap" justify="space-between">
            <Typography variant="body2">{productFFTypes}</Typography>
          </Grid>
        </Banner>
      );
    }
  }

  const priceSection = product?.type === 'custom' && (
    <Grid className={classes.priceSection} container direction="column" item wrap="nowrap">
      <Grid container item alignItems="center">
        <Grid
          alignItems="center"
          className={classes.iconContainer}
          container
          item
          justify="center"
          style={{ backgroundColor: theme.branding.v2[themeProps?.shopButtonColor || 'blue'][50] }}
        >
          <AttachMoneyIcon
            className={classes.icon}
            style={{ color: theme.branding.v2[themeProps?.shopButtonColor || 'blue'][500] }}
          />
        </Grid>
        <Grid item>
          <Typography style={{paddingTop: 4}} variant="body1">
            {product?.startingPrice
              ? `Starting at: ${Dinero({ amount: product?.startingPrice }).toFormat('$0.00')}`
              : 'Pricing Upon Request'}
          </Typography>
        </Grid>
      </Grid>
      {product?.minimum && (
        <Grid container item alignItems="flex-start" wrap="nowrap">
          <Grid
            alignItems="center"
            className={classes.iconContainer}
            container
            item
            justify="center"
            style={{ backgroundColor: theme.branding.v2[themeProps?.shopButtonColor || 'blue'][50] }}
          >
            <ShoppingBasketOutlinedIcon
              className={classes.icon}
              style={{ color: theme.branding.v2[themeProps?.shopButtonColor || 'blue'][500] }}
            />
          </Grid>
          <Grid item>
            <Typography style={{ paddingTop: 4, wordBreak: 'break-word' }} variant="body1">
              Minimum: {product?.minimum}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );

  const formatDate = (date: Moment, dateFormat) => {
    return date.tz(timeZone).format(dateFormat);
  };

  const eventDetailsSection = () => {
    if (!isEvent) return;
    const event = product as TicketedEvent;
    const inPerson = !_.isEmpty(event?.eventDetails?.location?.address);
    const dateFormat = 'dddd, MMMM D, YYYY';
    const startTimeFormat = 'h:mma';
    const endTimeFormat = 'h:mma z';
    const startDateMoment = momentTimezone.unix(event?.eventDetails?.date?.startTime);
    const endDateMoment = momentTimezone.unix(event?.eventDetails?.date?.endTime);
    const dateText = formatDate(startDateMoment, dateFormat);
    const timeText = `${formatDate(startDateMoment, startTimeFormat)} - ${formatDate(endDateMoment, endTimeFormat)}`;
    const iconStyles = {
      width: 20,
      height: 20,
      color: theme.branding.v2[themeProps?.shopButtonColor || 'gray'][500],
    };
    const address = event?.eventDetails?.location?.address;
    const addressText = address?.addressLine1
      ? `${address?.addressLine1}${address?.addressLine2 ? ` ${address?.addressLine2}` : ''}, ${address?.city}, ${
          address?.region
        } ${address?.postalCode}`
      : address?.fullAddress || '';
    return (
      <Grid container item direction="column" style={{ gap: '8px', marginTop: 32 }}>
        <Typography variant="h5">When & Where</Typography>
        <Grid container item wrap="nowrap" alignItems="flex-start" style={{ gap: '8px' }}>
          <CalendarIcon style={iconStyles} />
          <Grid container item direction="column">
            <Typography variant="body1">{dateText}</Typography>
            <Typography variant="body1">{timeText}</Typography>
          </Grid>
        </Grid>
        <Grid container item wrap="nowrap" alignItems="flex-start" style={{ gap: '8px' }}>
          <LocationOnOutlined style={iconStyles} />
          {inPerson ? (
            <Grid container item direction="column">
              {event?.eventDetails?.location?.name && (
                <Typography variant="body1">{event?.eventDetails?.location?.name}</Typography>
              )}
              <a
                href={`https://maps.google.com/?q=${addressText}"`}
                target="_blank"
                style={{ color: theme.branding.v2[themeProps?.shopButtonColor || 'gray'][500] }}
              >
                <Typography
                  variant="body1"
                  style={{ color: theme.branding.v2[themeProps?.shopButtonColor || 'gray'][500] }}
                >
                  {addressText}
                </Typography>
              </a>
            </Grid>
          ) : (
            <Typography variant="body1">Online</Typography>
          )}
        </Grid>
      </Grid>
    );
  };

  const allergensSection = allergens.length > 0 && (
    <>
      <Divider />
      <CollapsableCard
        className={classes.collapsableCard}
        contentClassName={classes.contentClassName}
        headerClassName={classes.headerClassName}
        noBorders
        noScroll
        title="Allergens"
      >
        <Typography variant="body1">{getAllergensList(allergens)}</Typography>
      </CollapsableCard>
    </>
  );

  const dietarySection = dietary.length > 0 && (
    <>
      <Divider />
      <CollapsableCard
        className={classes.collapsableCard}
        contentClassName={classes.contentClassName}
        headerClassName={classes.headerClassName}
        noBorders
        noScroll
        title="Dietary Information"
      >
        <Typography variant="body1">{getDietaryList(dietary)}</Typography>
      </CollapsableCard>
    </>
  );

  return (
    <Grid
      className={product?.type === 'custom' ? classes.container : ''}
      container
      direction="column"
      item
      wrap="nowrap"
      style={{ borderRadius: sectionRoundness }}
    >
      {isEvent && (
        <div style={{ marginBottom: 8 }}>
          <Pill
            content={
              _.isEmpty((product as TicketedEvent)?.eventDetails?.location?.address)
                ? 'Virtual Event'
                : 'In Person Event'
            }
            themeProps={themeProps}
          />
        </div>
      )}
      <Typography style={{ wordBreak: 'break-word' }} variant="h2">
        {product.title}
      </Typography>
      {sellerSection}
      {fulfillmentSection}
      {priceSection}
      {eventDetailsSection()}
      {isEvent && product.description && (
        <Typography variant="h5" style={{ marginBottom: 8, marginTop: 32 }}>
          About this Event
        </Typography>
      )}
      {product.description ? (
        <Typography
          paragraph
          variant="body1"
          component="div"
          className={classes.description}
          dangerouslySetInnerHTML={{ __html: product.description }}
        />
      ) : (
        readonly && (
          <Typography variant="body1">
            {isEvent ? (
              <>
                Your event description will appear here. Use this space to provide key details about your event, including:
                <br />
                &bull; What attendees can expect from your class or event.
                <br />
                &bull; Any preparations they need to make in advance.
                <br />
                &bull; Additional information that will help customers decide if this offering is right for them.
                <br />
              </>
            ) : (
              <>
                Your description appears here. This is a great place to cover details such as:
                <br />
                &bull; Telling your customers what makes this item unique.
                <br />
                &bull; Outlining the ordering process and any associated minimums or add-ons.
                <br />
                &bull; Providing details about packaging and any other important details.
                <br />
              </>
            )}
          </Typography>
        )
      )}
      {allergensSection}
      {dietarySection}
    </Grid>
  );
};

export default ProductInformation;
