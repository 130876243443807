import React, { useEffect } from 'react';
import { Button, Grid, makeStyles, Theme, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import ShareItem from './ShareItem';
import { getCouponAction } from '@castiron/client-admin/src/store/reducers/coupons';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: '24px',
    'overflow-x': 'scroll',
    width: 'calc(100vw - 30px)',
    [theme.breakpoints.up('md')]: {
      width: 'calc(100vw - 360px)',
    },
  },
  headerContainer: {
    marginBottom: '16px',
  },
  header: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 700,
  },
  itemContainer: {
    'overflow-x': 'scroll',
  },
  subheader: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
    color: theme.branding.gray[600],
    marginBottom: '16px',
  },
  title: {
    color: '#000',
    fontWeight: 700,
    fontSize: '14px',
    marginBottom: 0,
  },
  addCouponContainer: {
    backgroundColor: '#F8F8F8',
    borderRadius: '8px',
    marginBottom: '20px',
    marginRight: '20px',
    minWidth: '16em',
    maxWidth: '16em',
    padding: '16px',
  },
  addCouponText: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: '16px',
  },
  addCouponButton: {
    backgroundColor: theme.branding.gray[200],
  },
}));

const CouponShare: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { coupons, shop } = useAppSelector(state => ({
    shop: state.shops.shop,
    coupons: state.coupons.coupons,
  }));

  const shopUrl = `${process.env.REACT_APP_SHOP_URL}${shop?.websiteUrl}`;
  const shopHashtag = shop?.websiteUrl.replaceAll('-', '');

  useEffect(() => {
    if (shop) {
      dispatch(getCouponAction(shop.id));
    }
  }, [shop]);

  return (
    <Grid className={classes.container} container direction="column">
      <Grid className={classes.headerContainer}>
        <Typography className={classes.header}>Share a coupon</Typography>
        <Typography className={classes.subheader}>
          Encourage new and repeat customers with coupon savings. You'll be able to customize your post content before
          posting.
        </Typography>
      </Grid>
      <Grid className={classes.itemContainer} container direction="row" wrap="nowrap">
        {coupons.map((coupon, i) => {
          const couponShareText = `Take ${coupon.discount.type === 'amount' ? '$' : ''}${
            coupon.discount.type === 'amount' ? coupon.discount.value / 100 : coupon.discount.value
          }${coupon.discount.type === 'percent' ? '%' : ''} off on your next purchase from ${
            shop.businessName
          } with code ${coupon.code}. #${shopHashtag} #shoplocal #supportlocal`;

          return (
            <ShareItem
              key={coupon.id}
              img={shop?.logoImageObj?.downloadUrl}
              pinterestImg={shop?.logoImageObj?.downloadUrl ? shop?.logoImageObj?.downloadUrl : ''}
              shareUrl={shopUrl}
              text={couponShareText}
              itemType="coupon"
              item={coupon}
            />
          );
        })}
        {coupons.length < 10 && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.addCouponContainer}
          >
            <Typography className={classes.addCouponText}>
              Coupons help bring in new customers to try your products and encourage previous shoppers to shop again.
            </Typography>
            <Button
              onClick={() => {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                history.push('/store/coupons');
              }}
              className={classes.addCouponButton}
              variant="outlined"
              color="primary"
            >
              Create a new coupon
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CouponShare;
