import React from 'react';
import { Box, Grid, IconButton, useMediaQuery } from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { DeleteOutlined } from '@material-ui/icons';
import { FieldArrayRenderProps, FormikErrors, useFormikContext } from 'formik';
import { AutocompleteInput, IntegerInput, MoneyInput } from '@castiron/components';
import { Product } from '@castiron/domain';
import { useAppSelector } from '../../../hooks';
import { FormValues, LineItem } from '.';
import { getProductStatus } from '@castiron/utils';

interface Props {
  arrayHelpers: FieldArrayRenderProps;
  index: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: '4px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
      marginBottom: '16px',
      border: `1px solid ${theme.branding.v2.gray[200]}`,
      borderRadius: '12px',
    }
  },
  deleteContainer: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      borderTop: `1px solid ${theme.branding.v2.gray[200]}`,
      padding: '8px 0',
      marginTop: '8px',
    }
  },
  deleteIcon: {
    color: theme.branding.v2.red[500],
  },
}));

const LineItemComponent: React.FC<Props> = (props: Props) => {
  const { arrayHelpers, index } = props;
  const classes = useStyles();
  const theme = useTheme();

  const { products, shop } = useAppSelector(state => ({
    products: state.products.products,
    shop: state.shops.shop,
  }));

  const { errors, touched, values, setFieldValue } = useFormikContext<FormValues>();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const autocompleteProducts = products
    .filter(product => {
      const status = getProductStatus(product, shop?.config?.timeZone);
      return  (status === 'active' || status === 'scheduled') && product.type === 'standard';
    })
    .map(product => product.title);

  const onProductSelect = (index: number, newValue: string) => {
    const matchingProduct = products.find(product => {
      const status = getProductStatus(product, shop?.config?.timeZone);
      return (status === 'active' || status === 'scheduled') && product.type === 'standard' && product.title === newValue;
    });
    if (matchingProduct) {
      setFieldValue(`lineItems[${index}].price`, (matchingProduct as Product).price);
    }
  };

  return (
    <Box className={classes.container}>
      <Grid container spacing={1} justify='flex-end'>
        <Grid item xs={12} md={values.lineItems.length > 1 ? 5 : 6}>
          <AutocompleteInput
            label={(isMobile || index === 0) && 'Title'}
            name={`lineItems[${index}].title`}
            required
            options={autocompleteProducts}
            autofocus
            onOptionSelected={(newValue) => onProductSelect(index, newValue)}
            error={
              touched.lineItems &&
              touched.lineItems[index]?.title &&
              errors.lineItems &&
              (errors.lineItems[index] as FormikErrors<LineItem>)?.title
            }
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <MoneyInput
            label={(isMobile || index === 0) && 'Price'}
            name={`lineItems[${index}].price`}
            required
            error={
              touched.lineItems &&
              touched.lineItems[index]?.price &&
              errors.lineItems &&
              (errors.lineItems[index] as FormikErrors<LineItem>)?.price
            }
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <IntegerInput
            label={(isMobile || index === 0) && 'Quantity'}
            name={`lineItems[${index}].quantity`}
            required
            positiveOnly
            error={
              touched.lineItems &&
              touched.lineItems[index]?.quantity &&
              errors.lineItems &&
              (errors.lineItems[index] as FormikErrors<LineItem>)?.quantity
            }
          />
        </Grid>
        {
          values.lineItems.length > 1 &&
          <Grid item xs={12} md={1}>
            <Box className={classes.deleteContainer}>
              <Grid container justify='flex-end' alignItems='center' style={{ height: '100%' }}>
                <Grid item>
                  <IconButton onClick={() => arrayHelpers.remove(index)}>
                    <DeleteOutlined className={classes.deleteIcon} />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        }
      </Grid>
    </Box>
  );
};

export default LineItemComponent;