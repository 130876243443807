import React, { ReactNode } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Field, getIn, useFormikContext } from 'formik';
import InputWrapper, { BaseInputProps } from '../InputWrapper';
import { Grid, TextField, Typography } from '@material-ui/core';

interface Props extends BaseInputProps {
  autoFocus?: boolean;
  name?: string;
  onBlur?: (event: any) => void;
  onChange?: (event: any) => void;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
  maxLengthPadding?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  multiline?: boolean;
  rows?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    fontSize: 16,
    fontWeight: 400,
    padding: 12,
  },
  inputMaxLengthPadding: {
    paddingBottom: 40,
  },
  inputRoot: {
    minHeight: 56,
    width: '100%',
    border: 'none',
    borderRadius: '12px',
  },
  maxLength: {
    bottom: 8,
    position: 'absolute',
    right: 8,
    textAlign: 'right',
    color: theme.branding.v2.gray[500]
  }
}));

const TextInput: React.FC<Props> = (props: Props) => {
  const { autoFocus, error, label, secondaryLabel, name, onBlur, onChange, required, className, placeholder, disabled = false, maxLength, maxLengthPadding = true, startAdornment, helpText, endAdornment, multiline = false, rows } = props;
  const classes = useStyles();
  const formik = useFormikContext();

  return (
    <InputWrapper label={label} secondaryLabel={secondaryLabel} required={required} error={error} helpText={helpText}>
      <Grid container direction="column" style={{ position: 'relative' }}>
        <Field
          style={{ margin: '0px' }}
          as={TextField}
          autoFocus={autoFocus}
          disabled={disabled}
          error={!!error}
          inputProps={{
            maxLength,
          }}
          InputProps={{
            classes: {
              root: `${className && className} ${classes.inputRoot}`,
              input: `${classes.input} ${maxLength && maxLengthPadding && classes.inputMaxLengthPadding}`,
            },
            startAdornment,
            endAdornment,
          }}
          name={name}
          placeholder={placeholder}
          {...(onBlur ? { onBlur } : {})}
          {...(onChange ? { onChange } : {})}
          type='text'
          variant='outlined'
          multiline={multiline}
          {...(rows ? { rows } : {})}
        >
        </Field>
        {maxLength && (
          <Typography className={classes.maxLength} variant="caption">{getIn(formik.values, name).length}/{maxLength}</Typography>
        )}
      </Grid>
    </InputWrapper>
  );
}

export default TextInput;
