import React from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles, Theme } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { ButtonV2, Typography } from '@castiron/components';
import ModalWrapper from '../../RootModal/ModalWrapper';
import { productRepository, shopRepository } from '../../../domain';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeModal } from '../../../store/reducers/modalConductor';

export type Props = {
  categoryName?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  border: {
    borderTop: `1px solid ${theme.branding.v2.gray[200]}`,
  },
  delete: {
    backgroundColor: theme.palette.error.main,
  },
  dialogContent: {
    minWidth: 400,
    paddingTop: 24,
    borderTop: `1px solid ${theme.branding.v2.gray[200]}`,
  },
}));

const DeleteCategoryModal: React.FC<Props> = (props: Props) => {
  const { categoryName } = props;
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const { shop, products } = useAppSelector(state => ({
    shop: state.shops.shop,
    products: state.products.products,
  }));

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const submit = async () => {
    var currentCategory = shop?.categories?.find(cat => cat.name === categoryName);
    const standardCategoryLayout = shop?.standardCategoryLayout?.find(scl => scl.name === categoryName);
    const customCategoryLayout = shop?.customCategoryLayout?.find(ccl => ccl.name === categoryName);
    const productCategoryLayout = shop?.productCategoryLayouts?.find(pcl => pcl.categoryName === categoryName);
    const customProductCategoryLayout = shop?.customProductCategoryLayouts?.find(
      cpcl => cpcl.categoryName === categoryName,
    );
    const updatedShop = {
      ...shop,
      ...(standardCategoryLayout && {
        standardCategoryLayout: [...shop?.standardCategoryLayout?.filter(cat => cat.name !== categoryName)],
      }),
      ...(customCategoryLayout && {
        customCategoryLayout: [...shop?.customCategoryLayout?.filter(cat => cat.name !== categoryName)],
      }),
      ...(productCategoryLayout && {
        productCategoryLayouts: [...shop?.productCategoryLayouts?.filter(cat => cat.categoryName !== categoryName)],
      }),
      ...(customProductCategoryLayout && {
        customProductCategoryLayouts: [
          ...shop?.customProductCategoryLayouts?.filter(cat => cat.categoryName !== categoryName),
        ],
      }),
      categories: [...shop.categories?.filter(cat => cat.name !== categoryName)],
    };
    await shopRepository.updateProps(shop.id, {
      categories: updatedShop.categories,
      ...(updatedShop.standardCategoryLayout && { standardCategoryLayout: updatedShop.standardCategoryLayout }),
      ...(updatedShop.customCategoryLayout && { customCategoryLayout: updatedShop.customCategoryLayout }),
      ...(updatedShop.productCategoryLayouts && { productCategoryLayouts: updatedShop.productCategoryLayouts }),
      ...(updatedShop.customProductCategoryLayouts && {
        customProductCategoryLayouts: updatedShop.customProductCategoryLayouts,
      }),
    });

    await productRepository.batchUpdate(
      products
        .filter(p => p.category?.id === currentCategory.id || p.category?.name === categoryName)
        .map(product => ({ id: product.id, category: null })),
    );
    window.location.reload();
    dispatch(closeModal());
  };

  return (
    <ModalWrapper size="xs" show={true}>
      <DialogTitle id="alert-dialog-title">
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h2">Delete</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container justify="center" alignItems="center" alignContent="center" style={{ textAlign: 'center' }}>
          <Grid item xs={12}>
            <Typography variant="h2" style={{ fontSize: 36, padding: '15px 0px' }}>
              🗑️
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              style={{ fontSize: 21, padding: '15px 0px' }}
            >{`Delete ${categoryName} Category?`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" style={{ padding: '15px 0px' }}>
              {
                <>
                  Deleting this category will delete it across your entire website. All products and order forms
                  currently in this category will be moved to your <b>Other</b> category.
                </>
              }
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.border}>
        <ButtonV2 variant="outlined" onClick={handleClose} color="primary">
          Cancel
        </ButtonV2>
        <ButtonV2 variant="contained" onClick={submit} color="primary" className={classes.delete}>
          Yes, Delete
        </ButtonV2>
      </DialogActions>
    </ModalWrapper>
  );
};

export default DeleteCategoryModal;
