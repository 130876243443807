import React from 'react';
import { Box } from '@material-ui/core';
import DynamicSelect from './DynamicSelect';
import InputLabel from '../../../InputLabel';
import Tooltip from '../../../Tooltip';
import { ProductPageContext } from '@castiron/domain';

type Props = {
  classes: any;
  value: any;
  onChange: (e) => void;
  categories?: any[];
  context?: ProductPageContext;
};

const CategoryInput: React.FC<Props> = (props: Props) => {
  const { classes, onChange, value, categories, context } = props;

  return (
    <Box>
      <InputLabel
        containerClass={classes.categoryLabel}
        primaryLabel={`${context === 'events' ? 'Event' : 'Product'} Category`}
        secondaryLabel={
          <Tooltip
            arrow
            title={`If you would like to categorize your ${
              context === 'events' ? 'events' : 'products'
            } in your external store, create categories here.`}
          />
        }
      />
      <DynamicSelect value={value} options={categories} onChange={onChange} />
    </Box>
  );
};

export default CategoryInput;
