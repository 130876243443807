import React, { ReactNode } from 'react';
import { DialogActions } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';

interface Props {
  children: ReactNode;
  hideBorder?: boolean;
}

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  actionsContainer: {
    background: theme.branding.v2.gray[0],
    borderTop: props => (props?.hideBorder ? 'none' : `1px solid ${theme.branding.v2.gray[200]}`),
    marginTop: 'auto',
    padding: '16px 24px',
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
  },
}));

const ModalActions: React.FC<Props> = (props: Props) => {
  const { children, hideBorder } = props;
  const classes = useStyles(props);

  return <DialogActions className={classes.actionsContainer}>{children}</DialogActions>;
};

export default ModalActions;
