import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Typography, Grid } from '@material-ui/core';
import LoginForm from './LoginForm';
import '../Auth.scss';
import { setIsOnboarding } from '../../../store/reducers/shops';

const {REACT_APP_ADMIN_URL} = process.env;

type Props = {
  setupChecklistMode: string;
};

const signupUrl = `${REACT_APP_ADMIN_URL}signup`;

const Login: React.FC<Props> = (props: Props) => {
  const { myself, isOnboarding } = useAppSelector(state => ({
    myself: state.users.me,
    isOnboarding: state.shops.isOnboarding,
  }));
  const dispatch = useAppDispatch();

  if (myself) {
    if (isOnboarding) {
      dispatch(setIsOnboarding(false));
    }

    return <Redirect to={location.pathname} />;
  }

  return (
    <Grid item xs>
      <LoginForm />
      <Grid container justify="center" className="auth-footer">
        <Grid container>
          {/* <Grid item xs style={{ textAlign: 'center', marginBottom: '32px', marginTop: '24px' }}>
            <Typography variant="caption" color="textSecondary">
              <Link to="/forgot-password/start">Forget Password?</Link>
            </Typography>
          </Grid> */}
        </Grid>
        <Grid container direction="column">
          <Grid item xs>
            <Typography align="center">
              Forgot your password? <Link to="/forgot-password">Reset your password</Link>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography align="center">
              Dont have an account? <a href={signupUrl}>Sign Up</a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
