import * as yup from 'yup';
import { attributionSchema } from '../attribution/model';
import { couponSchema } from '../coupon/model';
import { customerSchema } from '../customer/model';
import { selectedInputFieldValueSchema } from '../inputfield/model';
import { orderSchema } from '../order/model';
import { addressSchema } from '../address';
import { Transaction } from './index';

export const cartItemSchema = yup
  .object({
    quantity: yup.number().required(),
    shopId: yup.string().nullable(),
    product: yup.object().required(),
    selectedVariationValues: yup
      .array()
      .of(selectedInputFieldValueSchema)
      .nullable(),
    fromPage: yup.string().nullable(),
  })
  .default(undefined);

export const transactionTotalsSchema = yup
  .object({
    stripeFees: yup.number().nullable(),
    castIronFees: yup.number().nullable(),
    totalCustomerFees: yup.number().nullable(),
    castironCustomerFees: yup.number().nullable(),
    totalArtisanFees: yup.number().nullable(),
    castironArtisanFees: yup.number().nullable(),
    totalFees: yup.number().nullable(),
    customerPaidStripeFees: yup.boolean().nullable(),
    customerFeePercent: yup.number().nullable(),
    artisanFeePercent: yup.number().nullable(),
    applicationFees: yup.number().nullable(),
    takeRate: yup.number().nullable(),
    subtotal: yup.number().required(),
    taxes: yup.number().required(),
    fulfillmentFee: yup.number().required(),
    coupon: yup.number().required(),
    tip: yup.number().required(),
    totalWithTax: yup.number().required(),
    totalWithoutFees: yup.number().required(),
    totalWithoutFeesOrTip: yup.number().required(),
    total: yup.number().required(),
    subTotalsWithFulfillment: yup.number().nullable(),
    subTotalWithCoupon: yup.number().nullable(),
    subTotalWithTip: yup.number().nullable(),
    tippingPresetPercentage: yup.number().nullable(),
  })
  .default(undefined);

export const transactionSchema: yup.AnyObjectSchema = yup
  .object()
  .shape({
    shopId: yup.string().required(),
    type: yup
      .string()
      .oneOf(['transaction', 'sub-transaction'])
      .required(),
    parentId: yup.string().nullable(),
    customer: yup.string().nullable(),
    customerObj: customerSchema.nullable(),
    order: orderSchema,
    products: yup
      .array()
      .of(cartItemSchema)
      .nullable(),
    totals: transactionTotalsSchema,
    transactionStatus: yup
      .string()
      .oneOf(['requested', 'pending', 'succeeded', 'failed'])
      .required(),
    notes: yup.string().nullable(),
    status: yup
      .string()
      .oneOf([
        'active',
        'inactive',
        'deleted',
        'open',
        'fulfilled',
        'completed',
        'canceled',
        'proposed',
        'rejected',
        'agreed',
      ])
      .required(),
    coupon: couponSchema, // Deprecated, moving Order.payments[].coupon
    processor: yup
      .object({
        name: yup
          .string()
          .oneOf(['stripe', 'venmo', 'paypal'])
          .required(),
        transactionIdentifier: yup.string().required(),
      })
      .default(undefined), // Deprecated, moving Order.payments[].processor
    isGift: yup.boolean().nullable(),
    shippingInfo: yup
      .object({
        recipientName: yup.string().required(),
        message: yup.string().nullable(),
        address: addressSchema(true),
      })
      .default(undefined),
    tier: yup
      .object({
        id: yup.string().required(),
        name: yup.string().required(),
        castironTakeRate: yup.number().required(),
        takeRateLevels: yup
          .array()
          .of(
            yup
              .object({
                type: yup
                  .string()
                  .oneOf(['standard', 'custom', 'invoice', 'event', 'all'])
                  .required(),
                orderTotalMinimum: yup.number().nullable(),
                castironTakeRate: yup.number().required(),
              })
              .default(undefined),
          )
          .nullable(),
        payoutFrequency: yup
          .string()
          .oneOf(['daily', 'weekly', 'monthly'])
          .required(),
      })
      .default(undefined),
    artisanNotes: yup.string().nullable(),
    legalInfo: yup
      .object({
        hasAgreedToOrderDetails: yup.boolean().required(),
        hasAgreedToTerms: yup.boolean().required(),
      })
      .default(undefined),
    fulfillOrderInfo: yup
      .object({
        note: yup.string().required(),
        trackingNumber: yup.string().nullable(),
        shippingCarrier: yup
          .string()
          .oneOf(['usps', 'ups', 'fedex', 'dhlexpress', 'other'])
          .nullable(),
        shippingCarrierLabel: yup.string().nullable(),
        sendArtisanEmail: yup.boolean().nullable(),
        sendCustomerEmail: yup.boolean().nullable(),
        trackingNumberUrl: yup.string().nullable(),
        orderFulfilledAt: yup.number().nullable(),
      })
      .default(undefined),
    rejectionNote: yup.string().nullable(),
    attribution: attributionSchema,
    isArchived: yup.boolean().nullable(),
    pickupReminderSent: yup.boolean().nullable(),
    thankYouCoupon: yup
      .object({
        lastSentDate: yup.number().required(),
        timesSent: yup.number().required(),
      })
      .default(undefined),
  })
  .default(undefined);
