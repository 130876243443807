import * as yup from 'yup';

export interface TaxonomyCategory {
  top: string;
  mid: string;
  leaf: string;
}

export const taxonomyCategorySchema = yup.object().shape({
  top: yup.string(),
  mid: yup.string(),
  leaf: yup.string(),
});
