import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, Theme, Tooltip as MUITooltip, Typography } from "@material-ui/core";
import SvgIcon from "../SvgIcon";
import InfoIcon from "../assets/info.svg";

interface Props {
  title: string;
  display?: boolean;
  toolTip?: string;
  className?: string;
  children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  sectionHead: {
    fontWeight: 600,
    marginBottom: '4px',
  },
  infoIcon: {
    color: theme.branding.graphite,
    cursor: 'pointer',
  },
  tooltips: {
    fontSize: 12,
    lineHeight: 1.3,
  },
}));

const CardItem: React.FC<Props> = (props: Props) => {
  const { children, title, display, toolTip, className } = props;
  const classes = useStyles();

  const shouldDisplay = display === undefined ? true : display;

  return <>{shouldDisplay && <Grid item className={className}>
    <Typography variant="body1" className={classes.sectionHead}>
      {title}
      {toolTip && <MUITooltip title={<span className={classes.tooltips}>{toolTip}</span>}>
        <IconButton>
          <SvgIcon className={classes.infoIcon}>
            <InfoIcon />
          </SvgIcon>
        </IconButton>
      </MUITooltip>}
    </Typography>
    <Typography component="div" variant="body2">{children}</Typography>
  </Grid>}</>;
}

export default CardItem;
