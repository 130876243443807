import { ButtonV2, ImageRadioOrCheckboxInput, Pill, SearchInput, Typography } from '@castiron/components';
import { BaseProduct, FulfillmentOption, getFulfillmentDateInfo, getImageUrl } from '@castiron/domain';
import { Grid } from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import DriveEtaOutlinedIcon from '@material-ui/icons/DriveEtaOutlined';
import LocalShippingOutlined from '@material-ui/icons/LocalShippingOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import StorefrontOutlined from '@material-ui/icons/StorefrontOutlined';
import { getIn, useFormikContext } from 'formik';
import Fuse from 'fuse.js';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import GeneralModal from '../../RootModal/GeneralModal';

type Props = {
  context: 'product' | 'fulfillment';
  items: BaseProduct[] | FulfillmentOption[];
  onClose: () => void;
  show: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  emptySearch: {
    width: '100%',
    padding: '40px 0px 150px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fulfillmentOptionBackground: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  fulfillmentOptionChip: {
    position: 'absolute',
    bottom: 4,
    right: 4,
    padding: '4px 12px',
    background: theme.branding.v2.gray[200],
    borderRadius: 100,
  },
  searchIcon: {
    height: 36,
    width: 36,
    marginBottom: 16,
    color: theme.branding.gray[600],
  },
}));

const SearchModal: React.FC<Props> = (props: Props) => {
  const { context, items, onClose, show } = props;
  const classes = useStyles();
  const theme = useTheme();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const { setFieldValue, values }: any = useFormikContext();

  const [itemsSelected, setItemsSelected] = useState<string[]>(getIn(values, `${context}Ids`));
  const [emptySearch, setEmptySearch] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [activeItems, setActiveItems] = useState<BaseProduct[] | FulfillmentOption[]>([]);
  const [formattedItems, setFormattedItems] = useState([]);

  const fuseOptions = {
    keys: [context === 'product' ? 'title' : 'displayName'],
    threshold: 0.5,
    useExtendedSearch: true,
  };

  useEffect(() => {
    const orderedItems = items
      .map(item => ({
        ...item,
        lastEdited: item.updatedAt ? item.updatedAt : item.createdAt,
      }))
      .sort((a, b) => {
        return b.lastEdited - a.lastEdited;
      });

    setActiveItems(orderedItems);
    setFormattedItems(formatItems(orderedItems));
  }, []);

  const formatItems = (itemsArray: BaseProduct[] | FulfillmentOption[]) => {
    const checkboxOptions = itemsArray.map(item => {
      let fulfillmentDateInfo;
      if (context === 'fulfillment') {
        fulfillmentDateInfo = getFulfillmentDateInfo(item, 2);
      }
      return context === 'product'
        ? {
            label: item.title,
            background: getImageUrl(item, 'mediumVersion'),
            value: item,
          }
        : {
            label: (
              <Grid container item direction="column" wrap="nowrap">
                <Typography variant="subtitle1">{item.displayName}</Typography>
                {fulfillmentDateInfo && <Typography variant="body2">{fulfillmentDateInfo}</Typography>}
              </Grid>
            ),
            background: (
              <Grid
                className={classes.fulfillmentOptionBackground}
                container
                direction="column"
                justify="center"
                alignContent="center"
              >
                {item.type === 'pickup' ? (
                  <StorefrontOutlined />
                ) : item.type === 'delivery' ? (
                  <DriveEtaOutlinedIcon />
                ) : (
                  <LocalShippingOutlined />
                )}
                <Typography variant="caption" className={classes.fulfillmentOptionChip}>
                  {_.capitalize(item.type)}
                </Typography>
              </Grid>
            ),
            value: item,
          };
    });

    return checkboxOptions;
  };

  const handleSearchChange = (event: any) => {
    setEmptySearch(false);
    const fuse = new Fuse(activeItems as readonly FulfillmentOption[], fuseOptions);

    if (event.target.value === '') {
      setFormattedItems(formatItems(activeItems));
    } else {
      const result = fuse.search(event.target.value);

      if (_.isEmpty(result)) {
        setEmptySearch(true);
        setSearchValue(event.target.value);
      } else {
        //get rid of extra formatting so we can reuse function
        const formattedSearch = [];
        result.forEach(o => formattedSearch.push(o.item));

        setFormattedItems(formatItems(formattedSearch));
      }
    }
  };

  const handleCheckboxChange = value => {
    const currentSelections = itemsSelected;
    let newSelections = [];
    if (currentSelections.includes(value)) {
      newSelections = currentSelections.filter(item => item !== value);
    } else {
      newSelections = [...currentSelections, value];
    }
    setItemsSelected(newSelections);
  };

  const handleAdd = async () => {
    await setFieldValue(`${context}Ids`, itemsSelected);
    handleClose(false);
  };

  const handleClose = async (resetItemsSelected: boolean) => {
    if (resetItemsSelected) {
      setItemsSelected(getIn(values, `${context}Ids`));
    }
    setEmptySearch(false);
    setSearchValue('');
    setFormattedItems(formatItems(activeItems));
    onClose();
  };

  return (
    <GeneralModal
      header={
        <Grid container item wrap="nowrap" style={{ gap: 8 }}>
          <Typography variant="h3">Add {_.capitalize(context)}s</Typography>
          <Pill content={`${itemsSelected?.length} Selected`} variant="blue" />
        </Grid>
      }
      content={
        <Grid container item direction="column" wrap="nowrap" style={{ gap: 16, textAlign: 'left' }}>
          <Typography style={{ color: theme.branding.v2.gray[700] }} variant="body2">
            Select the specific {context}s available for this presale.
          </Typography>
          <SearchInput onChange={handleSearchChange} />
          {emptySearch ? (
            <Grid container className={classes.emptySearch}>
              <SearchOutlinedIcon className={classes.searchIcon} />
              <Typography variant="body3">
                No results for <span style={{ fontWeight: 700 }}>"{searchValue}"</span>
              </Typography>
            </Grid>
          ) : (
            <Grid container item>
              <ImageRadioOrCheckboxInput
                options={formattedItems}
                onChange={handleCheckboxChange}
                value={itemsSelected}
              />
            </Grid>
          )}
        </Grid>
      }
      actions={[
        <ButtonV2 variant="outlined" onClick={() => handleClose(true)}>
          Cancel
        </ButtonV2>,
        <ButtonV2 variant="contained" onClick={handleAdd}>
          Add
        </ButtonV2>,
      ]}
      onClose={() => handleClose(true)}
      show={show}
    />
  );
};

export default SearchModal;
