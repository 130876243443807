import { Box, Button, ButtonGroup as MUIButtonGroup, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactNode } from 'react';
import InputLabel from '../InputLabel';

type Props = {
  value: string | boolean;
  onChange: (status: any) => void;
  buttons?: string[];
  label?: ReactNode;
  children?: ReactNode;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    marginTop: 0,
    height: 48,
    marginBottom: 15,
    fontWeight: 600,
    fontSize: 16,
    borderColor: `${theme.branding.iron}3D`,
    backgroundColor: theme.palette.common.white,
  },
  buttonActive: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  label: {
    fontSize: 14,
    fontWeight: 700,
  },
  subLabel: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.branding.graphite,
  },
}));

const ButtonGroup: React.FC<Props> = (props: Props) => {
  const { value, onChange, buttons, label } = props;
  const classes = useStyles();

  return (
    <Box>
      <InputLabel primaryLabel={label} />
      <MUIButtonGroup fullWidth>
        {buttons.map(button => (
          <Button
            key={button}
            onClick={(): void => onChange(button)}
            className={`${classes.button} ${value === button && classes.buttonActive}`}
          >
            {button.charAt(0).toUpperCase() + button.slice(1)}
          </Button>
        ))}
      </MUIButtonGroup>
    </Box>
  );
};

export default ButtonGroup;
