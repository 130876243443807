import React, { useState } from 'react';
import { Grid, IconButton, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { Button, Typography } from '@castiron/components';
import { useTracking } from '@castiron/utils';
import CloseIcon from '@material-ui/icons/Close';
import ModalWrapper from '../../RootModal/ModalWrapper';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getService } from "../../../firebase";
import { closeModal } from '../../../store/reducers/modalConductor';
import Spinner from '../../Spinner';

const useStyles = makeStyles((theme: Theme) => ({
  activeButtonYes: {
    width: '100%',
    backgroundColor: theme.branding.blue.light,
    border: `1px solid ${theme.branding.blue.primary}`,
    borderRadius: `12px 0px 0px 12px`,
    color: theme.branding.blue.primary,
  },
  activeButtonNo: {
    width: '100%',
    backgroundColor: theme.branding.blue.light,
    border: `1px solid ${theme.branding.blue.primary}`,
    borderRadius: `0px 12px 12px 0px`,
    color: theme.branding.blue.primary,
  },
  body: {
    padding: 16,
  },
  buttonYes: {
    width: '100%',
    border: `1px solid ${theme.branding.gray[300]}`,
    borderRadius: `12px 0px 0px 12px`,
    color: theme.branding.gray[800],
  },
  buttonNo: {
    width: '100%',
    border: `1px solid ${theme.branding.gray[300]}`,
    borderRadius: `0px 12px 12px 0px`,
    color: theme.branding.gray[800],
  },
  buttonStripe: {
    width: '100%',
  },
  container: {
    borderRadius: 16,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      maxHeight: 0,
    },
  },
  header: {
    padding: 15,
    borderBottom: `1px solid ${theme.branding.gray[300]}`,
  },
  text: {
    margin: '10px 0',
  },
  underline: {
    textDecoration: 'underline',
  }
}));

export interface Props {
  redirectUrl?: string;
}

const stripeCreateAccountService = getService('stripe', 'stripecreateaccountv2', { version: 2 });

const PaymentModal: React.FC<Props> = (props) => {
  const { redirectUrl } = props;
  const { trackEvent } = useTracking();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [taxInfo, setTaxInfo] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { account, stripeLoginLink } = useAppSelector(state => ({
    account: state.shops.account,
    stripeLoginLink: state.shops.stripe.stripeLink,
  }));

  const handleYes = (): void => {
    setTaxInfo('yes');
  };

  const handleNo = (): void => {
    setTaxInfo('no');
  }

  const handleStripeConnect = async () => {
    try {
      const body = {
        redirectUrl,
      };
      setIsLoading(true);
      if (!account.stripeAccountId) {
        stripeCreateAccountService(body).then(r => {
          console.debug('stripe create account response', r);
          trackEvent('Stripe Setup Started');
          window.location.href = r.url;
        });
      } else {
        window.location.href = stripeLoginLink;
      }
    } catch (err) {
      setIsLoading(false);
      console.error('Error in handleStripeConnect: ', err);
    }
  };

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  return (
    <>
      {isLoading ? (
        <Spinner show={isLoading} />
      ) : (
        <ModalWrapper size="sm" show={true}>
          <Grid container className={classes.container} xs={12}>
            <Grid container justify='space-between' alignItems='center' xs={12} className={classes.header}>
              <Grid item>
                <Typography variant='h2'>Getting Started with Stripe</Typography>
              </Grid>
              <Grid item >
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container xs={12} className={classes.body}>
              <Grid xs={12}>
                <Grid item xs={12} style={{ marginBottom: 6 }}>
                  <Typography variant='subtitle2' style={{ fontWeight: 600 }}>Do you have an EIN or Tax-ID?</Typography>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    <Button variant='outlined' onClick={handleYes} className={taxInfo === 'yes' ? classes.activeButtonYes : classes.buttonYes}>Yes</Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button variant='outlined' onClick={handleNo} className={taxInfo === 'no' ? classes.activeButtonNo : classes.buttonNo}>No</Button>
                  </Grid>
                </Grid>
              </Grid>
              {taxInfo !== '' &&
                <Grid xs={12} style={{ margin: '16px 0px' }}>
                  <Typography variant='body2'>
                    <Grid xs={12} className={classes.text}>
                      You’ll soon be given a choice to set up your account as a <span className={classes.underline}>Business</span> or <span className={classes.underline}>Individual</span>.
                    </Grid>
                    <Grid xs={12} className={classes.text}>
                      Based on your response to the question above, <b>we recommend setting up your account as a <span className={classes.underline}>{taxInfo === 'yes' ? 'Business' : 'Individual'}</span>.</b>
                    </Grid>
                    <Grid xs={12} className={classes.text}>
                      Please note that you will be asked to provide the following:
                      <ul style={{ minHeight: 110 }}>
                        {taxInfo === 'yes' && <li>Your EIN or Tax-ID</li>}
                        <li>Legal Name</li>
                        <li>Date of Birth</li>
                        <li>Bank Account Information (to get paid)</li>
                        <li>Official ID (i.e. a Driver’s License or Passport.)</li>
                      </ul>
                    </Grid>
                    <Grid xs={12} className={classes.text}>
                      When you’re ready to continue, click the button below.
                    </Grid>
                  </Typography>
                  <Button variant='contained' className={classes.buttonStripe} onClick={handleStripeConnect}>Connect to Stripe</Button>
                </Grid>
              }
            </Grid>
          </Grid >
        </ModalWrapper >
      )}
    </>
  );
};

export default PaymentModal;
