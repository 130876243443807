import {
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';
import { ButtonV2 } from '../Button';
import { CloseIcon } from '../Icons';
import Typography from '../Typography';
import { stripHtml } from '@castiron/utils';

interface Props {
  handleClose: () => void;
  text: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    backgroundColor: theme.branding.v2.gray[0],
    borderTop: `1px solid ${theme.branding.v2.gray[200]}`,
    bottom: 0,
    marginTop: 'auto',
    padding: 24,
    position: 'sticky',
  },
  container: {
    '& .MuiDialog-paper': {
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',

      '&::-webkit-scrollbar': {
        display: 'none',
      },
      borderRadius: 12,
      maxHeight: '80vh',
      [theme.breakpoints.down('xs')]: {
        borderRadius: 0,
        maxHeight: 'none',
      },
    },
  },
  text: {
    padding: 24,
  },
  title: {
    backgroundColor: theme.branding.v2.gray[0],
    borderBottom: `1px solid ${theme.branding.v2.gray[200]}`,
    padding: 24,
    position: 'sticky',
    top: 0,
  },
}));

const PoliciesModal: React.FC<Props> = (props: Props) => {
  const { handleClose, text } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isTextEmpty = stripHtml(text).length === 0;

  const defaultPolicies =
    'Order Confirmation\n\nOrders are confirmed upon receipt of payment. Artisan reserves the right to cancel the order with appropriate lead time. Performance of this agreement is contingent upon our ability to complete the Agreement and is subject to labor disputes or strikes, accidents, Acts of God, and other causes beyond our control. Sometimes, things are out of our control.\n\nCare and handling\n\nWe are not responsible for any damage to your custom order after it has left our hands. You are responsible for providing a safe and appropriate environment for your custom order.\n\nFulfillment\n\nAll fulfillment details will be worked out before purchase and are included in the total purchase price.\n\nPhotography and marketing\n\nWe reserve the right to use photos and videos of your order at our discretion without compensation.\n\nPayment\n\nFull payment is required at the time of purchase. All sales are final.\n\nAllergens\n\nThe product may come into contact with common allergens. You are responsible for notifying anyone enjoying your custom order of this risk and we cannot be held responsible for any adverse reactions.';

  return (
    <Dialog className={classes.container} fullScreen={isMobile} maxWidth="md" open onClose={handleClose}>
      <DialogTitle className={classes.title}>
        <Grid container justify="space-between">
          <Typography variant="h2">Policies</Typography>
          <IconButton aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContentText className={classes.text} variant="body1">
        {(isTextEmpty ? defaultPolicies : text)?.split('\n').map((item, key) => {
          return (
            <span key={key}>
              {item}
              <br />
            </span>
          );
        })}
      </DialogContentText>
      <DialogActions className={classes.actions}>
        <ButtonV2 onClick={handleClose} variant="contained">
          Close
        </ButtonV2>
      </DialogActions>
    </Dialog>
  );
};

export default PoliciesModal;
