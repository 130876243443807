import React, {ReactElement, useContext, useEffect, useRef, useState} from 'react';
import { ButtonBase, Grid, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Button, Checkbox, CollapsableCard, ImageUploader, TextAreaInput, Typography } from '@castiron/components';
import { ShopSeoMetadata } from '@castiron/domain';
import { updateShopAction } from '../../../store/reducers/shops';
import { openModal } from '../../../store/reducers/modalConductor';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { LayoutPageProps } from '../../Layout';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import AdminForm from '../../AdminForm';
import {
  AttributionContext,
  AttributionData,
  formatArtisanCategory,
  removeEmpty,
  stripHtml,
  useTracking
} from '@castiron/utils';
import { useHistory } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { upload } from '@castiron/castiron-firebase';
import { useDropzone } from 'react-dropzone';
import ToolTip from '../../Tooltip';
import { getShopLink } from '../../../lib/domainUtils';

interface Props extends LayoutPageProps {
  browseButtonRef?: React.MutableRefObject<any>;
}

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    padding: 16,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '24px',
  },
  changeImageButton: {
    padding: 16,
  },
  checkbox: {
    fontSize: 14,
    lineHeight: '24px',
    color: theme.branding.gray[800],
    margin: '4px 0 16px 0',

    '& label': {
      fontWeight: 400,
    },
  },
  footerButton: {
    margin: '0px 4px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  container: {
    '& > form': {
      width: '652px',

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },

    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
  },
  imageContainer: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 12,
  },
  imageUploader: {
    marginBottom: 24,
  },
  socialImage: {
    maxHeight: 315,
    maxWidth: 600,

    [theme.breakpoints.down('sm')]: {
      maxHeight: 315,
      maxWidth: '100%',
    },
  },
  infoIcon: {
    color: theme.branding.gray[800],
    marginLeft: 6,
    height: 16,
    width: 16,
    display: 'flex',
    alignContent: 'center',
  },
  label: {
    color: theme.branding.gray[800],
    fontSize: 14,
    fontWeight: 600,
    fontFamily: 'Nunito Sans,sans-serif',
    lineHeight: '24px',
  },
  labelContainer: {
    marginBottom: 4,
    '& label': {
      marginBottom: 0,
    },
  },
  previewBox: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 12,
    marginBottom: 16,
    padding: 16,
    wordBreak: 'break-all',
  },
  previewBoxTitle: {
    color: theme.branding.blueLink,
    marginBottom: 4,
  },
  previewBoxDescription: {
    color: theme.branding.gray[600],
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '24px',
  },
  previewBoxUrl: {
    color: theme.branding.green.dark,
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '20px',
    marginBottom: 4,
  },
  seoCard: {
    marginBottom: 24,
  },
  seoPreview: {},
  socialPreview: {
    wordBreak: 'break-all',
  },
  socialPreviewUrl: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.branding.gray[500],
    marginTop: 16,
  },
  socialPreviewTitle: {
    color: theme.branding.gray[800],
    fontSize: 21,
    fontWeight: 700,
    lineHeight: '32px',
  },
  socialPreviewDescription: {},
  textArea: {
    marginBottom: 16,
  },
}));

const seoSchema = () =>
  yup.object().shape({
    shopDescription: yup.string(),
    shopTitle: yup.string(),
    socialPageDescription: yup.string(),
    socialImage: yup.string(),
    socialPageTitle: yup.string(),
  });

const SeoPage: React.FC<LayoutPageProps> = (props: Props) => {
  const { setPageTitle, setPageIsProFeature, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const history = useHistory();
  const formRef = useRef<FormikProps<any>>();
  let socialImageUrl;

  const [seoExpanded, setSeoExpanded] = useState(true);
  const [socialExpanded, setSocialExpanded] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSameTitle, setIsSameTitle] = useState(true);
  const [isSameDescription, setIsSameDescription] = useState(true);
  const [socialImage, setSocialImage] = useState<string | undefined>(undefined);

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const { tagAttribution } = useContext<AttributionData>(AttributionContext);

  useEffect(() => {
    tagAttribution('HERE');
  }, []);

  const shopLink = getShopLink(shop);

  useEffect(() => {
    setPageTitle('SEO');
    setPageIsProFeature(true);
    setBackLocation(true);
    setHeaderCTAs([]);
    setFooterCTAs([
      <Button variant="outlined" onClick={() => history.push('/store/dashboard')} className={classes.footerButton}>
        Discard
      </Button>,
      <Button
        variant="contained"
        onClick={async () => {
          if (!formRef.current.isSubmitting) {
            setIsSubmitting(true);

            await formRef.current.validateForm();
            if (!formRef.current.isValid) {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
              });
            }

            formRef.current.submitForm();
          }
        }}
        className={classes.footerButton}
        disabled={isSubmitting}
      >
        Save
      </Button>,
    ]);

    return () => {
      setBackLocation(false);
      setFooterCTAs([]);
      setPageTitle('');
      setPageIsProFeature(false);
    };
  }, []);

  useEffect(() => {
    if (shop?.seoMetadata?.socialImage) {
      setSocialImage(shop?.seoMetadata?.socialImage);
    } else if (shop?.logoImageObj?.downloadUrl) {
      setSocialImage(shop?.logoImageObj?.downloadUrl);
    }

    if (shop?.seoMetadata?.shopTitle !== shop?.seoMetadata?.socialPageTitle) {
      setIsSameTitle(false);
    }

    if (shop?.seoMetadata?.shopDescription !== shop?.seoMetadata?.socialPageDescription) {
      setIsSameDescription(false);
    }
  }, [shop]);

  const handleFileDrop = (files): void => {
    const newURL = URL.createObjectURL(files[0]);
    setSocialImage(newURL);
    formRef?.current?.setFieldValue('socialImage', files[0]);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: handleFileDrop,
    maxFiles: 1,
    noClick: true,
  });

  const seoTitle = () => {
    let title;
    if (shop?.seoMetadata?.shopTitle) {
      title = shop?.seoMetadata?.shopTitle;
    } else {
      const themes: string[] = formatArtisanCategory(shop);
      let city = shop?.seoMetadata?.address?.addressLocality || shop?.physicalAddress?.city;
      if (city) {
        city = city
          .toLowerCase()
          .split(' ')
          .map(s => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
      }

      if (themes.length > 0 && city) {
        if (themes.includes('baking')) {
          title = `Bakery in ${city} | ${shop?.businessName}`;
        } else if (themes.includes('desserts')) {
          title = `Desserts in ${city} | ${shop?.businessName}`;
        } else if (themes.includes('charcuterie')) {
          title = `Charcuterie in ${city} | ${shop?.businessName}`;
        } else if (themes.includes('prepared foods')) {
          title = `Meal prep in ${city} | ${shop?.businessName}`;
        } else {
          title = `${shop?.businessName} in ${city}`;
        }
      } else {
        title = `Home | ${shop?.businessName}`;
      }
    }

    return title;
  };

  const initialValues = {
    shopDescription: shop?.seoMetadata?.shopDescription || shop?.description || '',
    shopTitle: seoTitle(),
    socialPageDescription: shop?.seoMetadata?.socialPageDescription || shop?.description || '',
    socialPageTitle: shop?.seoMetadata?.socialPageTitle || seoTitle(),
  };

  const updateShop = async (values: any, socialImageUrl?: string) => {
    const { shopDescription, shopTitle, socialPageDescription, socialPageTitle } = values;

    const seoMetadata: ShopSeoMetadata = {
      ...shop?.seoMetadata,
      shopDescription: stripHtml(shopDescription),
      shopTitle: stripHtml(shopTitle),
      socialPageDescription: stripHtml(socialPageDescription),
      socialPageTitle: stripHtml(socialPageTitle),
      socialImage: socialImageUrl ? socialImageUrl : socialImage,
      manuallyUpdated: true,
    };

    const updatedShop = {
      ...shop,
      seoMetadata: removeEmpty(seoMetadata) as ShopSeoMetadata,
    };

    try {
      await dispatch(updateShopAction({ shop: updatedShop }));
      trackEvent('SEO Shop Settings Updated', {
        shopId: shop?.id,
        seoMetadata: removeEmpty({
          shopDescription: stripHtml(shopDescription),
          shopTitle: stripHtml(shopTitle),
          socialPageDescription: stripHtml(socialPageDescription),
          socialPageTitle: stripHtml(socialPageTitle),
          socialImage: socialImageUrl ? socialImageUrl : socialImage,
        }),
      });
      dispatch(
        openModal({
          modalType: 'SIMPLE_ALERT',
          modalProps: {
            show: true,
            celebrate: true,
            content: <>SEO settings were updated</>,
          },
        }),
      );
      history.push('/store/dashboard');
    } catch (error) {
      console.error(error);
    }

    setIsSubmitting(false);
  };

  const handleFileUploadSuccess = async (downloadUrl, metadata, options) => {
    socialImageUrl = downloadUrl;

    if (formRef.current) {
      updateShop(formRef.current.values, socialImageUrl);
    }
  };

  const handleFileChange = async values => {
    const file = values.socialImage;
    const id = nanoid();

    if (file) {
      const metadata = {
        shopId: shop.id,
        originalFilename: id,
        id,
      };
      const options = {
        folder: `user/${shop.id}`,
      };
      const callbacks = {
        success: handleFileUploadSuccess,
      };
      const context = {
        shop: shop,
      };
      try {
        await upload(file, metadata, options, callbacks, context);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onSubmit = async (values: any) => {
    setIsSubmitting(true);

    if (values.socialImage && socialImage !== shop?.seoMetadata?.socialImage) {
      await handleFileChange(values);
    } else {
      await updateShop(values);
    }

    setIsSubmitting(false);
  };

  const handleTitleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSameTitle(event.target.checked);
    if (event.target.checked) {
      formRef.current.setFieldValue('socialPageTitle', stripHtml(formRef.current.values.shopTitle));
    }
  };

  const handleDescriptionCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSameDescription(event.target.checked);
    if (event.target.checked) {
      formRef.current.setFieldValue('socialPageDescription', stripHtml(formRef.current.values.shopDescription));
    }
  };

  return (
    <Grid container justify="center" className={classes.container}>
      <Formik initialValues={initialValues} validationSchema={seoSchema} onSubmit={onSubmit} innerRef={formRef}>
        {({ setFieldValue, values }): ReactElement => (
          <AdminForm>
            <Grid container item xs={12} direction="column">
              <CollapsableCard
                expanded={seoExpanded}
                handleExpand={() => {
                  setSeoExpanded(!seoExpanded);
                }}
                id="seo-card"
                title="SEO"
                noScroll
                className={classes.seoCard}
              >
                <Grid container item xs={12} className={classes.seoPreview}>
                  <Typography variant="subtitle2" className={classes.label}>
                    Search Preview
                  </Typography>
                  <Grid container item xs={12} className={classes.previewBox} direction="column">
                    <Typography variant="subtitle1" className={classes.previewBoxTitle}>
                      {stripHtml(values.shopTitle)}
                    </Typography>
                    <Typography variant="caption2" className={classes.previewBoxUrl}>
                      {shopLink}
                    </Typography>
                    <Typography variant="body2" className={classes.previewBoxDescription}>
                      {stripHtml(values.shopDescription)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" className={classes.labelContainer}>
                  <label className={classes.label} id="shopTitleLabel">
                    SEO Title
                  </label>
                  <ToolTip
                    arrow
                    title="The header and most prominent text on search results. We recommend the following format: Your
                        Product in Your City | Your Business Name (i.e. Custom Cookies in Nashville | The Cookie
                        Project)"
                  >
                    <InfoOutlinedIcon className={classes.infoIcon} />
                  </ToolTip>
                </Grid>
                <TextAreaInput
                  aria-labelledby="shopTitleLabel"
                  name="shopTitle"
                  rowCount={3}
                  className={classes.textArea}
                  onChange={e => {
                    setFieldValue('shopTitle', e.target.value.slice(0, 80));
                    if (isSameTitle) {
                      setFieldValue('socialPageTitle', stripHtml(e.target.value).slice(0, 80));
                    }
                  }}
                  value={values.shopTitle}
                  maxLength={80}
                />
                <Grid container alignItems="center" className={classes.labelContainer}>
                  <label className={classes.label} id="shopDescLabel">
                    SEO Description
                  </label>
                  <ToolTip
                    arrow
                    title="Write a brief description about your business, focusing on the needs of your potential customers and why they should shop with you!"
                  >
                    <InfoOutlinedIcon className={classes.infoIcon} />
                  </ToolTip>
                </Grid>
                <TextAreaInput
                  aria-labelledby="shopDescLabel"
                  name="shopDescription"
                  rowCount={6}
                  maxLength={300}
                  value={values.shopDescription}
                  onChange={e => {
                    setFieldValue('shopDescription', e.target.value.slice(0, 300));
                    if (isSameDescription) {
                      setFieldValue('socialPageDescription', stripHtml(e.target.value).slice(0, 300));
                    }
                  }}
                />
              </CollapsableCard>
            </Grid>
            <Grid container item xs={12}>
              <CollapsableCard
                expanded={socialExpanded}
                handleExpand={() => {
                  setSocialExpanded(!socialExpanded);
                }}
                id="social-card"
                title="Social Media"
                noScroll
              >
                <Grid container item xs={10} className={classes.socialPreview}>
                  <Typography variant="subtitle2" className={classes.label}>
                    Facebook Preview
                  </Typography>
                  <Grid container item xs={12} className={classes.previewBox} direction="column">
                    <Grid container justify="center" alignItems="center">
                      <img
                        src={socialImage || 'https://placehold.co/600x400'}
                        alt="social media preview"
                        className={classes.socialImage}
                      />
                    </Grid>
                    <Typography variant="body1" className={classes.socialPreviewUrl}>
                      {shopLink.toUpperCase()}
                    </Typography>
                    <Typography variant="h5" className={classes.socialPreviewTitle}>
                      {stripHtml(values.socialPageTitle)}
                    </Typography>
                    <Typography variant="body1" className={classes.socialPreviewDescription}>
                      {stripHtml(values.socialPageDescription)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={10} className={classes.imageUploader} direction="column">
                  <Grid container alignItems="center" className={classes.labelContainer}>
                    <label className={classes.label}>Social Photo</label>
                    <ToolTip
                      arrow
                      title="Facebook automatically resizes and formats your photos when they are uploaded for the timeline to be 500 pixels wide and to fit the 1.91:1 aspect ratio. Recommended size: 1200 x 630 pixels, Minimum size: 600 x 315 pixels"
                    >
                      <InfoOutlinedIcon className={classes.infoIcon} />
                    </ToolTip>
                  </Grid>
                  {!socialImage ? (
                    <ImageUploader onFileDrop={handleFileDrop} />
                  ) : (
                    <Grid
                      container
                      item
                      xs={12}
                      className={classes.imageContainer}
                      justify="center"
                      alignItems="center"
                    >
                      <img src={socialImage} alt="social media preview" className={classes.socialImage} />
                      <Grid
                        container
                        item
                        xs={12}
                        justify="center"
                        {...getRootProps()}
                        className={classes.buttonContainer}
                      >
                        <input {...getInputProps()} />
                        <ButtonBase onClick={open}>
                          <Typography variant="subtitle2" color="primary">
                            Replace Photo
                          </Typography>
                        </ButtonBase>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid container alignItems="center" className={classes.labelContainer}>
                  <label className={classes.label} id="socialTitleLabel">
                    Social Page Title
                  </label>
                  <ToolTip
                    arrow
                    title="The header and most prominent text. We recommend the following format: Your Product in Your City | Your Business Name (i.e. Custom Cookies in Nashville | The Cookie Project)"
                  >
                    <InfoOutlinedIcon className={classes.infoIcon} />
                  </ToolTip>
                </Grid>
                <TextAreaInput
                  aria-labelledby="socialTitleLabel"
                  name="socialPageTitle"
                  rowCount={2}
                  disabled={isSameTitle}
                  maxLength={80}
                  onChange={e => {
                    setFieldValue('socialPageTitle', e.target.value.slice(0, 80));
                  }}
                  value={isSameTitle ? values.shopTitle : values.socialPageTitle}
                />
                <Grid item xs={12} className={classes.checkbox}>
                  <Checkbox
                    name="sameAsSeoTitle"
                    label="Same as SEO Title"
                    checked={isSameTitle}
                    onChange={e => handleTitleCheckbox(e)}
                  />
                </Grid>
                <Grid container alignItems="center" className={classes.labelContainer}>
                  <label className={classes.label} id="socialDescLabel">
                    Social Page Description
                  </label>
                  <ToolTip
                    arrow
                    title="Write a brief description about your business, focusing on the needs of your potential customers and why they should shop with you!"
                  >
                    <InfoOutlinedIcon className={classes.infoIcon} />
                  </ToolTip>
                </Grid>
                <TextAreaInput
                  aria-labelledby="socialDescLabel"
                  name="socialPageDescription"
                  rowCount={5}
                  disabled={isSameDescription}
                  maxLength={300}
                  onChange={e => {
                    setFieldValue('socialPageDescription', e.target.value.slice(0, 300));
                  }}
                  value={isSameDescription ? values.shopDescription : values.socialPageDescription}
                />
                <Grid item xs={12} className={classes.checkbox} style={{ marginBottom: 0 }}>
                  <Checkbox
                    name="sameAsSeoDescription"
                    label="Same as SEO Description"
                    checked={isSameDescription}
                    onChange={e => handleDescriptionCheckbox(e)}
                  />
                </Grid>
              </CollapsableCard>
            </Grid>
          </AdminForm>
        )}
      </Formik>
    </Grid>
  );
};

export default SeoPage;
