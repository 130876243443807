import React from 'react';
import { Grid, IconButton, makeStyles } from '@material-ui/core';
import ModalWrapper from '../RootModal/ModalWrapper';
import { useAppDispatch } from '../../hooks';
import { closeModal } from '../../store/reducers/modalConductor';
import { Button, Typography } from '@castiron/components';
import { Close } from '@material-ui/icons';

export type Props = {
  setIsModalPopped: (isModalPopped: boolean) => void;
  type: 'product-description' | 'aboutme' | 'social-post';
};

const useStyles = makeStyles(theme => ({
  title: {
    '& h2': {
      fontWeight: 800,
    },
  },
  button: {
    fontWeight: 600,
  },
  closeIcon: {
    cursor: 'pointer',
    fontSize: 32,
  },
  header: {
    padding: 24,
    borderBottom: `1px solid ${theme.branding.gray[300]}`,
  },
  content: {
    margin: '32px 40px',
    width: 'auto',
  },
  buttonsContainer: {
    padding: '12px 24px',
    borderTop: `1px solid ${theme.branding.gray[300]}`,
    minHeight: 80,
    [theme.breakpoints.down('xs')]: {
      marginTop: 'auto',
    },
  },
}));

const AIGenerationModal: React.FC<Props> = (props: Props) => {
  const { setIsModalPopped, type } = props;

  const classes = useStyles();
  const dispatch = useAppDispatch();

  const handleClose = (): void => {
    dispatch(closeModal());
    setIsModalPopped(false);
  };

  const text =
    type === 'product-description' ? (
      <>
        Castiron uses AI to help you write world-class product descriptions. We can provide suggestions with as little
        as a title, but if you want an even more tailored description:
        <ul>
          <li>Make your title as detailed as possible, while still keeping it as short as you can.</li>
          <li>Add any applicable allergen and dietary information before clicking "Write it for Me"</li>
        </ul>
        P.S. Don't like our recommendation? Not a problem! You can adjust the description we provided, or click the
        button again to generate a different description!
      </>
    ) : type == 'social-post' ? (
      <>
        Castiron uses AI to help you write world-class social media posts. We can provide suggestions with as little as
        a title, but if you want an even more tailored description:
        <ul>
          <li>Make your title as detailed as possible, while still keeping it as short as you can.</li>
          <li>Add any applicable allergen and dietary information before clicking "Write it for Me"</li>
        </ul>
        P.S. Don't like our recommendation? Not a problem! You can adjust the social post we provided, or click the
        button again to generate a different description!
      </>
    ) : (
      <>
        <div>
          Have writer's block? Let us help get you started! We'll use our magical AI powers to generate an awesome shop
          description that you can update as you see fit.
        </div>
        <br />
        <div>
          We'll use all the information you've given us as you created your shop as a starting point, and then you can
          edit anything you'd like!
        </div>
      </>
    );

  return (
    <ModalWrapper size="xs" show={true}>
      <Grid container direction="column">
        <Grid container item justify="space-between" alignItems="center" className={classes.header}>
          <Grid item>
            <Typography variant="h3">Castiron’s AI Descriptions</Typography>
          </Grid>
          <Grid item>
            <IconButton className={classes.closeIcon} onClick={handleClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container item className={classes.content}>
          <Typography variant="body1">{text}</Typography>
        </Grid>
        <Grid container item justify="flex-end" className={classes.buttonsContainer}>
          <Button className={classes.button} onClick={handleClose} variant="contained">
            Ok
          </Button>
        </Grid>
      </Grid>
    </ModalWrapper>
  );
};

export default AIGenerationModal;
