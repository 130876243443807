import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { User } from '../../models/User';
import { signOut as signOutFirebase } from '@castiron/castiron-firebase';
import axios from 'axios';

export interface UserState {
  me: User;
  users: User[];
  user: User;
  loading: boolean;
  hasLoaded: boolean;
  error: string;
  justLoggedIn: boolean;
}

const initialState: UserState = {
  me: null,
  users: [],
  user: null,
  loading: false,
  hasLoaded: false,
  error: '',
  justLoggedIn: true,
};

const logoutService = async () => {
  return axios.post(
    '/auth/logout',
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    },
  );
};

const signOut = redirect => {
  signOutFirebase(async () => {
    await logoutService();
    window.location = redirect;
  });
  return null;
};

const getMyself = (user: User) => {
  return user;
};

export const getMyselfAction = createAsyncThunk('users/getMyself', getMyself);
export const signOutAction = createAsyncThunk('users/signOut', signOut);

const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setJustLoggedIn(state, action) {
      state.justLoggedIn = action.payload;
    },
  },
  extraReducers: builder => {
    // you can mutate state directly, since it is using immer behind the scenes\
    builder
      .addCase(getMyselfAction.pending, state => {
        state.loading = true;
      })
      .addCase(getMyselfAction.fulfilled, (state, action) => {
        state.me = action.payload;
        state.hasLoaded = true;
        state.loading = false;
      })

      .addCase(signOutAction.pending, state => {
        state.loading = true;
      })
      .addCase(signOutAction.fulfilled, state => {
        state.me = null;
      });
  },
});

export const { setJustLoggedIn } = userSlice.actions;

export default userSlice.reducer;
