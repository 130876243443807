import React, { useEffect, useState } from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid, useMediaQuery, IconButton, CircularProgress } from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import { Typography, ButtonV2, Banner } from '@castiron/components';
import { useTracking } from '@castiron/utils';
import { getService } from '../../../../firebase';
import { useAppDispatch } from '../../../../hooks';
import { closeModal, openModal } from '../../../../store/reducers/modalConductor';
import ModalWrapper from '../../../RootModal/ModalWrapper';

const shopIpService = getService('shops', 'getshopip', { version: 2 });
const provisionCustomDomain = getService('shops', 'provisioncustomdomain', { version: 2 });

export type Props = {
  domainName: string;
  show?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    padding: '8px 24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      padding: '16px 16px 50px',
    }
  },
  content: {
    padding: 24
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
    padding: 5
  },
  cnameRow: {
    '& .MuiTableCell-root': {
      borderBottom: 'none'
    }
  },
  copyButton: {
    padding: '18px 0px',
    minWidth: 53,
    [theme.breakpoints.up('sm')]: {
    }
  },
  dataCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dataCellMultiline: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    wordBreak: 'break-all',
  },
  deleteButton: {
    border: `1px solid ${theme.branding.gray[400]}`,
    color: theme.branding.red.primary,
    '&:hover': {
      border: `1px solid ${theme.branding.gray[500]}`,
      backgroundColor: theme.branding.gray[200]
    }
  },
  errorBox: {
    marginBottom: 24
  },
  errorMessage: {
    color: theme.branding.red.dark
  },
  infoBox: {
    backgroundColor: theme.branding.blue.light,
    borderRadius: 12,
    padding: 24,
    marginBottom: 24
  },
  infoBullets: {
    padding: '0 0 0 18px'
  },
  loadingSpinner: {
    color: theme.branding.gray[100],
  },
  modalHeader: {
    borderBottom: `1px solid ${theme.branding.gray[400]}`,
    padding: '22px 24px',
  },
  modalWrapper: {
    [theme.breakpoints.up('sm')]: {
      width: 504
    }
  },
  nextButtonLoading: {
    padding: '12px 45px'
  },
  rowBorder: {
    borderBottom: `1px solid ${theme.branding.gray[400]}`
  },
}));

const VerifyDomainModal: React.FC<Props> = (props: Props) => {
  const { domainName, show } = props;

  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();

  const [loading, setLoading] = useState(false);
  const [shopIp, setShopIp] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [isARecordCopied, setIsARecordCopied] = useState(false);
  const [isCNAMECopied, setIsCNAMECopied] = useState(false);

  useEffect(() => {
    shopIpService({}).then(r => setShopIp(r.ip)).catch(err => console.error('ip error: ', err))
  }, [])

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const handleSubmit = async () => {
    setLoading(true);
    trackEvent('Verify Domain Clicked');

    try {
      const provisionRes = await provisionCustomDomain({ domainName: domainName });

      if (provisionRes.success) {
        dispatch(
          openModal({
            modalType: 'DOMAIN_PROCESSING_MODAL',
            modalProps: {
              show: true
            },
          })
        );
      }
    } catch (error) {
      setErrorMessage(error.message);
    }

    setLoading(false);
  };

  const handleCopy = (type: string) => {
    const toCopy = type === 'CNAME' ? domainName : shopIp;

    navigator.clipboard.writeText(toCopy);
    type === 'CNAME' ? setIsCNAMECopied(true) : setIsARecordCopied(true);
    setTimeout(() => {
      type === 'CNAME' ? setIsCNAMECopied(false) : setIsARecordCopied(false);
    }, 2000);
  }

  return (
    <ModalWrapper fullScreen={isMobile} show={show} onClose={handleClose} paperClass={classes.modalWrapper}>
      <DialogTitle className={classes.modalHeader}>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Typography variant='h3'>Connect a Domain</Typography>
        </Grid>
        <IconButton className={classes.closeIcon} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {!!errorMessage &&
          <Banner variant='error' className={classes.errorBox}>
            <Typography className={classes.errorMessage}>There was an error when processing your request: {errorMessage}.</Typography>
          </Banner>
        }
        <Grid className={classes.infoBox}>
          <Typography variant='h3'>Finish Connecting in 4 Steps 😎</Typography>
          <Typography variant='placeholder2' style={{ paddingBottom: 16, color: theme.branding.gray[600] }}>{domainName}</Typography>
          <Grid>
            <ul className={classes.infoBullets}>
              <li><Typography>Head to your domain provider’s website.</Typography></li>
              <li><Typography>Log in to your account.</Typography></li>
              <li><Typography>Change your DNS settings to the values below.</Typography></li>
              <li><Typography>When finished, press the <strong>Verify Domain</strong> button.</Typography></li>
            </ul>
          </Grid>
          <Typography>More instructions available in our <a target='_blank' href='https://castiron.helpscoutdocs.com/article/111-custom-domains-with-castiron'>domain guide</a>.</Typography>
        </Grid>
        <Grid container direction='column'>
          <Grid container item direction='row' className={classes.rowBorder}>
            <Grid item xs={3} sm={2}>
              <Typography variant='button' className={classes.dataCell}>Type</Typography>
            </Grid>
            <Grid item xs={2} sm={3}>
              <Typography variant='button' className={classes.dataCell}>Name</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='button' className={classes.dataCell}>Value</Typography>
            </Grid>
            <Grid item xs={3}><></></Grid>
          </Grid>

          <Grid container item direction='row' alignItems='center' className={classes.rowBorder}>
            <Grid item xs={3} sm={2} >
              <Typography variant='body1' className={classes.dataCell}>A</Typography>
            </Grid>
            <Grid item xs={2} sm={3}>
              <Typography variant='body1' className={classes.dataCell}>@</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='body1' className={classes.dataCellMultiline}>{!!shopIp ? shopIp : <Typography variant='placeholder1' style={{ color: theme.branding.gray[600] }}>loading...</Typography>}</Typography>
            </Grid>
            <Grid container item xs={3} justify='flex-end'>
              <ButtonV2
                onClick={() => handleCopy('A Record')}
                className={classes.copyButton}
                disabled={!shopIp}
              >
                {isARecordCopied ? 'Copied!' : 'Copy'}
              </ButtonV2>
            </Grid>
          </Grid>

          <Grid container item direction='row' alignItems='center'>
            <Grid xs={3} sm={2}>
              <Typography variant='body1' className={classes.dataCell}>CNAME</Typography>
            </Grid>
            <Grid item xs={2} sm={3}>
              <Typography variant='body1' className={classes.dataCell}>www</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='body1' className={classes.dataCellMultiline}>{domainName}</Typography>
            </Grid>
            <Grid container item xs={3} justify='flex-end'>
              <ButtonV2
                onClick={() => handleCopy('CNAME')}
                className={classes.copyButton}
              >
                {isCNAMECopied ? 'Copied!' : 'Copy'}
              </ButtonV2>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <ButtonV2
          variant='outlined'
          disabled={loading}
          onClick={handleClose}
          className={classes.deleteButton}
        >
          Delete
        </ButtonV2>
        <ButtonV2
          variant='contained'
          onClick={handleSubmit}
          className={`${loading && classes.nextButtonLoading}`}
        >
          {loading ? <CircularProgress className={classes.loadingSpinner} size={32} /> : 'Verify Domain'}
        </ButtonV2>
      </DialogActions>
    </ModalWrapper>
  );
};

export default VerifyDomainModal;