import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { AutocompleteInput, Checkbox, EmailInput, PhoneInput, TextInput } from '@castiron/components';
import { useFormikContext } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getCustomersAction } from '../../../store/reducers/customers';
import { FormValues } from '.';

interface Props {
  showCustomerForm: boolean;
  setShowCustomerForm: (boolean) => void;
};

const Customer: React.FC<Props> = (props: Props) => {
  const { showCustomerForm, setShowCustomerForm } = props;
  const dispatch = useAppDispatch();

  const { shop, customers } = useAppSelector(state => ({
    shop: state.shops.shop,
    customers: state.customers.customers,
  }));

  const { errors, touched, setFieldValue } = useFormikContext<FormValues>();

  const autocompleteCustomers = customers.filter(cust => cust.status === 'active' && cust.fullName()).map(cust => cust.fullName());

  const onCustomerSelect = (newValue) => {
    const selectedCustomer = customers.find(cust => cust.status === 'active' && cust.fullName() === newValue);
    if (selectedCustomer) {
      setFieldValue('customerEmail', selectedCustomer.email);
      if (selectedCustomer.mobileNumber) {
        setFieldValue('customerPhoneNumber', selectedCustomer.mobileNumber);
      }
    }
  };

  useEffect(() => {
    dispatch(getCustomersAction(shop.id));
  }, [shop]);

  return (
    <Grid container direction='column' spacing={1}>
      <Grid item>
        <Checkbox
          label='Include Customer Information'
          onChange={(event, checked) => setShowCustomerForm(checked)}
        />
      </Grid>
      {
        showCustomerForm &&
        <>
          <Grid item>
            <AutocompleteInput
              label='Name'
              name='customerName'
              required
              options={autocompleteCustomers}
              onOptionSelected={onCustomerSelect}
              error={touched.customerName && errors.customerName}
            />
          </Grid>
          <Grid item>
            <EmailInput
              label='Email'
              name='customerEmail'
              required
              error={touched.customerEmail && errors.customerEmail}
            />
          </Grid>
          <Grid item>
            <PhoneInput
              label='Phone Number'
              name='customerPhoneNumber'
              error={touched.customerPhoneNumber && errors.customerPhoneNumber}
            />
          </Grid>
        </>
      }
    </Grid>
  );
};

export default Customer;