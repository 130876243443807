import { Attribution } from '../attribution';
import {BaseDocument, BaseRepository, FieldFunctions} from "../base/repository";
import firebase from "firebase/compat/app";
import _firestore from "@google-cloud/firestore";

export interface Session extends BaseDocument<Session> {
  auth?: {
    token: string;
  };
  userId?: string;
  attribution?: Attribution;
  ttl: number;
}

export class SessionRepository extends BaseRepository<Session> {
  constructor(
    firestore: firebase.firestore.Firestore | _firestore.Firestore,
    fieldFunctions?: FieldFunctions,
  ) {
    super(firestore, 'sessions', fieldFunctions);
  }
}