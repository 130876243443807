import React, { ReactElement } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

type Props = {
  icon: ReactElement;
  lineHeight?: string;
  offset?: string;
  showTopLine?: boolean;
  showBottomLine?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  iconContainer: {
    position: 'relative',
    display: 'inline-block',
    textAlign: 'center',
  },
  line: {
    content: '""',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '1px' /* Adjust the thickness of the line */,
    backgroundColor: `${theme.branding.v2.gray[400]}`,
  },
  topLine: (props: Props) => ({
    top: props.offset || 0,
    height: props.lineHeight || '50%',
  }),
  bottomLine: (props: Props) => ({
    bottom: props.offset || 0,
    height: props.lineHeight || '50%',
  }),
}));

const IconWithLines: React.FC<Props> = (props: Props) => {
  const { icon, showTopLine = true, showBottomLine = true } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.iconContainer}>
      {showTopLine && <div className={`${classes.line} ${classes.topLine}`} />}
      {showBottomLine && <div className={`${classes.line} ${classes.bottomLine}`} />}
      {icon}
    </div>
  );
};

export default IconWithLines;
