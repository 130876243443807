import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { BaseInputProps, InputWrapper } from '@castiron/components';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import ReactQuill from 'react-quill';

interface Props extends BaseInputProps {
  name: string;
  placeholder?: string;
  height?: number;
};

type QuillProps = {
  id: string;
  placeholder?: string;
  value: string;
  onChange: (string) => void;
  onBlur?: (event: any) => void;
  height?: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  editorContainer: {
    /* the toolbar doesn't get recognized by the grid due to absolute positioning,
     * so add some padding to account for it
     */
    paddingBottom: '44px',
  },
  quillEditor: {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    ['& .ql-container']: {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
    ['& .ql-toolbar']: {
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
    ['& .ql-editor']: {
      fontSize: 16,
      wordBreak: 'break-word',
    }
  },
}));

const RichTextEditor: React.FC<QuillProps> = (props: QuillProps) => {
  const { id, placeholder, value, height, onChange, onBlur } = props;
  const classes = useStyles();

  return (
    /* toolbar relies on absolute position, parent needs relative positioning */
    <Box style={{ position: 'relative' }}>
      <ReactQuill
        id={id}
        placeholder={placeholder}
        modules={{
          toolbar: [
            ['bold', 'underline', 'italic'],
            [{ list: 'ordered' }, { list: 'bullet' }],
          ],
        }}
        value={value}
        onChange={onChange}
        style={{
          height: height ? `${height}px` : '250px',
        }}
        onBlur={onBlur}
        className={classes.quillEditor}
      />
    </Box>
  );
};

const RichTextInput: React.FC<Props> = (props: Props) => {
  const { name, placeholder, height } = props;
  const classes = useStyles();
  const { setFieldValue, setFieldTouched, values } = useFormikContext();

  return (
    <InputWrapper {...props}>
      <Box className={classes.editorContainer}>
        <RichTextEditor
          id={`richeditor-${name}`}
          placeholder={placeholder}
          height={height}
          value={_.get(values, name)}
          onChange={(text) => setFieldValue(name, text)}
          onBlur={() => {
            setFieldTouched(name, true);
          }}
        />
      </Box>
    </InputWrapper>
  );
};

export default RichTextInput;
