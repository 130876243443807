import { CustomProduct, Product } from '@castiron/domain';
import { Box, Grid, useMediaQuery } from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { NoPhotoIcon } from '../Icons';
import SoldOutBanner from './SoldOutBanner';
import { TicketedEvent } from '@castiron/domain/src/product';

interface Props {
  isPreview?: boolean;
  product: Product | CustomProduct | TicketedEvent;
  sectionRoundness?: string;
  timeZone?: string;
}

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  image: {
    aspectRatio: '1/1',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  imageContainer: {
    backgroundColor: theme.branding.v2.gray[0],
    position: 'relative',
    height: 504,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: 393,
    },
  },
  imageContainerBorder: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
  },
  noPhotoIcon: {
    color: theme.branding.v2.gray[400],
    height: 42,
    width: 42,
  },
  roundedCorners: {
    borderRadius: props => props.sectionRoundness,
    overflow: 'hidden',
  },
}));

const ProductImages: React.FC<Props> = (props: Props) => {
  const { isPreview = false, product, sectionRoundness = '12px', timeZone } = props;

  const classes = useStyles(props);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isCustom = product?.type === 'custom';
  const addRoundedCorners = isCustom && !isMobile && !isPreview;

  const Image = image =>
    image ? (
      <Box
        key={image?.id}
        className={`${classes.imageContainer} ${addRoundedCorners ? classes.roundedCorners : ''} ${product?.type ===
          'custom' && classes.imageContainerBorder}`}
      >
        <img className={classes.image} src={image?.downloadUrl} />
        <SoldOutBanner product={product} timeZone={timeZone} />
      </Box>
    ) : (
      <SoldOutBanner product={product} timeZone={timeZone} />
    );

  return product?.images?.length > 1 ? (
    <Carousel
      autoPlay={false}
      animation="fade"
      swipe={true}
      navButtonsAlwaysInvisible={isMobile}
      navButtonsAlwaysVisible={!isMobile}
      indicators={true}
      fullHeightHover={false}
    >
      {product?.images.map(image => Image(image))}
    </Carousel>
  ) : product?.images && product?.images[0] ? (
    Image(product?.images[0])
  ) : (
    Image(product?.imageObj)
  );
};

export default ProductImages;
