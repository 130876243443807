import React, { ReactElement, ReactNode } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles, Theme } from '@material-ui/core/styles';
import ModalChoiceIcon, { ModalChoiceIconColor } from "./ModalChoiceIcon";

interface Props {
  icon: ReactElement;
  iconColor?: ModalChoiceIconColor;
  title: string;
  description: ReactNode;
  action: () => void;
  className?: string;
  pill?: ReactNode;
  banner?: ReactNode;
  disabled?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: '16px',
    padding: '24px 24px 24px 16px',
    '& :hover': {
      cursor: 'pointer',
    },
  },
  description: {
    color: theme.branding.gray[600],
  },
  disabled: {
    opacity: 0.5,
  },
}));

const ModalChoice: React.FC<Props> = (props: Props) => {
  const { icon, iconColor = 'blue', title, description, action, className, pill, banner, disabled } = props;
  const classes = useStyles();

  return (
    <Box className={`${classes.container} ${className ? className : ''}`} onClick={() => {if (!disabled) {action()}}}>
      <Grid className={disabled ? classes.disabled : ''} container spacing={2}>
        <Grid item>
          <ModalChoiceIcon icon={icon} color={iconColor} />
        </Grid>
        <Grid item xs>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="subtitle1">{title}</Typography>
            </Grid>
            <Grid item>
              {typeof description === 'string' ? (
                <Typography variant="body2" className={classes.description}>
                  {description}
                </Typography>
              ) : (
                description
              )}
            </Grid>
            {pill && <Grid item>{pill}</Grid>}
          </Grid>
        </Grid>
      </Grid>
      {banner && <Grid item>{banner}</Grid>}
    </Box>
  );
};

export default ModalChoice;