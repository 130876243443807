import { Checkbox, FormControlLabel, Grid, GridSize, Radio, useTheme } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FormikErrors } from 'formik';
import React, { ReactNode } from 'react';
import { NoPhotoIcon } from '../../Icons';
import Typography from '../../Typography';

export interface ImageRadioOrCheckboxOption {
  background?: string | ReactNode;
  value: any;
  label?: string;
  context?: any;
}

interface Props {
  label?: string;
  required?: boolean;
  options?: ImageRadioOrCheckboxOption[];
  value?: string | string[];
  error?: string | FormikErrors<any> | string[] | FormikErrors<any>[];
  optionClassName?: string;
  optionSizeObj?: { xs?: GridSize; sm?: GridSize; md?: GridSize; lg?: GridSize };
  onChange?: (value: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  checkboxInput: {
    position: 'absolute',
    left: 16,
    top: 16,
    height: 16,
    width: 16,
    backgroundColor: theme.branding.v2.gray[0],
    borderRadius: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  formControl: {
    maxWidth: '100%',
    width: '100%',
    position: 'relative',
    '& .MuiFormControlLabel-label': {
      padding: 0,
      width: '100%',
    },
    '& .MuiFormControlLabel-root': {
      width: '100%',
    },
  },
  gridWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    height: 'fit-content',
    justifyContent: 'flex-start',
  },
  imageSelected: {
    border: `2px solid ${theme.branding.v2.blue[500]} !important`,
    borderRadius: 16,
  },
  individualWrapper: {
    position: 'relative',
    alignItems: 'flex-start',
  },
  label: {
    wordBreak: 'break-word',
    fontWeight: 600,
  },
  labelContainer: {
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      '-webkit-line-clamp': 2,
    },
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    aspectRatio: '1/1',
    alignItems: 'center',
    justifyContent: 'center',
    objectFit: 'contain',
    minHeight: '70px',
    minWidth: '70px',
    width: '100%',
    border: `2px solid ${theme.branding.v2.gray[0]}`,
    background: theme.branding.v2.gray[100],
    borderRadius: 12,
  },
  radioIcon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    backgroundColor: theme.branding.v2.gray[0],
    border: `1px solid ${theme.branding.v2.gray[500]}`,
  },
  radioIconActive: {
    backgroundColor: `${theme.branding.blue.primary} !important`,
    border: 'none !important',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: `radial-gradient(${theme.branding.v2.gray[0]},${theme.branding.v2.gray[0]} 28%,transparent 32%)`,
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: theme.branding.v2.blue[500],
    },
  },
  radioInput: {
    position: 'absolute',
    left: 3,
    top: 3,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    aspectRatio: '1/1',
    border: `2px solid ${theme.branding.v2.gray[0]}`,
    borderRadius: 12,
    objectFit: 'cover',
  },
}));

const ImageRadioOrCheckboxInput: React.FC<Props> = (props: Props) => {
  const { options, value, onChange, optionClassName, optionSizeObj } = props;

  const classes = useStyles();
  const theme = useTheme();

  const inputType: 'radio' | 'checkbox' = typeof value === 'string' ? 'radio' : 'checkbox';

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.target.value);
  };

  return (
    <Grid container className={classes.gridWrapper}>
      {options.map((option, index) => {
        const optionValue = option.value?.id || option.value;

        return (
          <Grid
            container
            item
            className={classes.individualWrapper}
            key={index}
            xs={optionSizeObj?.xs || 6}
            sm={optionSizeObj?.sm || 4}
            md={optionSizeObj?.md || 6}
            lg={optionSizeObj?.lg || 4}
          >
            <FormControlLabel
              className={classes.formControl}
              style={{ margin: 0 }}
              key={`option${option.value}`}
              value={optionValue}
              checked={inputType === 'radio' ? optionValue === value : value?.includes(optionValue)}
              control={
                inputType === 'radio' ? (
                  <Radio
                    className={classes.radioInput}
                    color="primary"
                    checkedIcon={<span className={clsx(classes.radioIcon, classes.radioIconActive)} />}
                    icon={<span className={classes.radioIcon} />}
                    onChange={handleChange}
                  />
                ) : (
                  <Checkbox className={classes.checkboxInput} color="primary" onChange={handleChange} />
                )
              }
              label={
                <Grid style={{ width: '100%' }}>
                  <div
                    className={`${optionClassName} ${classes.optionContainer} ${inputType === 'radio' &&
                      optionValue === value &&
                      classes.imageSelected}`}
                  >
                    {typeof option.background === 'string' && !!option.background ? (
                      <img className={classes.thumbnail} src={option.background as string} />
                    ) : !!option.background ? (
                      option.background
                    ) : (
                      <NoPhotoIcon style={{ color: theme.branding.v2.gray[400] }} />
                    )}
                  </div>
                  {option.label && (
                    <div style={{ marginLeft: '8px' }} className={classes.labelContainer}>
                      <Typography variant="subtitle3" className={classes.label}>
                        {option.label}
                      </Typography>
                    </div>
                  )}
                </Grid>
              }
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ImageRadioOrCheckboxInput;
