import { ButtonBase, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import BasketIcon from '../../assets/img/basket.svg';
import EmailIcon from '../../assets/img/email.svg';
import { useAppDispatch } from '../../hooks';
import { SvgIcon } from '@castiron/components';
import { openModal } from '../../store/reducers/modalConductor';
import { useHistory } from 'react-router';
import RequireStripe from '../RequireStripe';

type Props = {
  onClose: (event: React.MouseEvent<HTMLElement>) => void;
  id: number;
};

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    height: 36,
    width: 'auto',
  },
  buttonBase: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingRight: 8,
    '&:hover': {
      backgroundColor: `${theme.branding.gray[600]}4D`,
    },
  },
  menuLabel: {
    marginLeft: 8,
    fontWeight: 600,
    fontSize: 14,
  },
  delete: {
    color: theme.palette.error.main,
  },
}));

const CustomerActionsMenu: React.FC<Props> = (props: Props) => {
  const { onClose, id } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    dispatch(
      openModal({
        modalType: 'DELETE_MODAL',
        modalProps: {
          show: true,
          itemName: 'customer',
        },
      }),
    );
    onClose(event);
  };

  const handleEditClick = (event: React.MouseEvent<HTMLElement>) => {
    history.push(`/contacts/details/${id}?mode=edit`);
    onClose(event);
  };

  const handleMessageClick = (event: React.MouseEvent<HTMLElement>) => {
    history.push(`/contacts/details/${id}?mode=message`);
    onClose(event);
  };

  const handleLatestOrderClick = (event: React.MouseEvent<HTMLElement>) => {
    history.push(`/contacts/details/${id}?mode=latest`);
    onClose(event);
  };

  return (
    <>
      {/* <ButtonBase onClick={handleEditClick} focusRipple className={classes.buttonBase}>
        <SvgIcon className={classes.icon}>
          <PencilIcon />
        </SvgIcon>
        <Typography className={classes.menuLabel}>Edit</Typography>
      </ButtonBase> */}
      <RequireStripe>
        <ButtonBase onClick={handleMessageClick} focusRipple className={classes.buttonBase}>
          <SvgIcon className={classes.icon}>
            <EmailIcon />
          </SvgIcon>
          <Typography className={classes.menuLabel}>Contact</Typography>
        </ButtonBase>
      </RequireStripe>
      <ButtonBase onClick={handleLatestOrderClick} focusRipple className={classes.buttonBase}>
        <SvgIcon className={classes.icon}>
          <BasketIcon />
        </SvgIcon>
        <Typography className={classes.menuLabel}>Latest order</Typography>
      </ButtonBase>
      {/* <ButtonBase focusRipple onClick={handleClick} className={classes.buttonBase}>
        <SvgIcon className={`${classes.icon} ${classes.delete}`}>
          <TrashIcon />
        </SvgIcon>
        <Typography className={`${classes.menuLabel} ${classes.delete}`}>Delete</Typography>
      </ButtonBase> */}
    </>
  );
};

export default CustomerActionsMenu;
