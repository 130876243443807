import { AddressInput, CheckboxInput, CustomSwitch, Link, PhoneInput, TextInput } from '@castiron/components';
import { Address } from '@castiron/domain';
import { FormControlLabel, FormGroup, Grid, Theme, Typography, makeStyles, useTheme } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  addressLine1: {
    '& div': {
      margin: 0,
    },
  },
  addressLineTwoContainer: {
    height: 56,
  },
  addressLine2CTA: {
    color: theme.branding.blue.primary,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  checkbox: {
    paddingLeft: 0,
  },
  inputContainer: {
    marginBottom: 24,
    width: '100%',
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  switch: {
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    '& .MuiSwitch-root': {
      alignSelf: 'flex-start',
    },
    '& .MuiFormControlLabel-label': {
      paddingRight: 0,
    },
  },
  tosErrorBanner: {
    margin: '8px 0px 0px 0px',
  },
}));

const Registration: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [showaddressLine2Input, setShowaddressLine2Input] = useState(false);

  const { errors, setFieldValue, touched, values }: any = useFormikContext();

  const onAddressChange = async (address: Address) => {
    await setFieldValue('address', {
      fullAddress: address.fullAddress || '',
      addressLine1: address.addressLine1 || '',
      addressLine2: address.addressLine2 || '',
      city: address.city || '',
      region: address.region || '',
      regionName: address.regionName || '',
      country: address.country || '',
      postalCode: address.postalCode || '',
    });
  };

  return (
    <Grid container>
      <Typography style={{ marginBottom: 8 }} variant="subtitle1">
        Domain Registration
      </Typography>
      <Grid className={classes.inputContainer} item>
        <TextInput error={touched.fullName && errors.fullName} label="Full Name" name="fullName" required />
      </Grid>
      <Grid className={classes.inputContainer} item>
        <PhoneInput
          error={touched.phoneNumber && errors.phoneNumber}
          label="Phone Number"
          name="phoneNumber"
          required
        />
      </Grid>
      <Grid
        className={classes.inputContainer}
        container
        item
        direction="column"
        alignItems="flex-start"
        justify="center"
      >
        <Grid container className={classes.addressLine1} id="address">
          <AddressInput
            label="Address"
            required
            addressFields={{
              address: 'address.fullAddress',
              addressLine1: 'address.addressLine1',
              city: 'address.city',
              region: 'address.region',
              regionName: 'address.regionName',
              postalCode: 'address.postalCode',
              country: 'address.country',
            }}
            onAddressChange={onAddressChange}
            error={touched.address?.fullAddress && errors.address?.fullAddress}
          />
        </Grid>
        <Grid item style={{ width: '100%', marginTop: 8 }}>
          {showaddressLine2Input ? (
            <Grid>
              <Typography variant="button" style={{ marginTop: 8 }}>
                Apt #, Suite, Floor
              </Typography>
              <TextInput
                name="address.addressLine2"
                className={classes.addressLineTwoContainer}
                placeholder="Apt #, Suite, Floor"
              />
            </Grid>
          ) : (
            <Typography
              variant="button"
              className={classes.addressLine2CTA}
              onClick={() => setShowaddressLine2Input(true)}
            >
              + Add Apt #, Suite, Floor
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid className={classes.inputContainer} item>
        <CheckboxInput
          className={classes.checkbox}
          error={touched.termsOfService && errors.termsOfService}
          label={
            <Typography variant="body2">
              I have read and agree to the&nbsp;
              <Link className={classes.link} target="_blank" href="https://www.castiron.me/terms-conditions">
                Terms of Service
              </Link>
              .
            </Typography>
          }
          name="termsOfService"
          variant="label-right"
        />
      </Grid>
      <Typography style={{ marginBottom: 8 }} variant="subtitle1">
        Domain Renewal
      </Typography>
      <FormGroup>
        <FormControlLabel
          className={classes.switch}
          control={
            <CustomSwitch
              className={classes.switch}
              label={true}
              checked={values?.autoRenew}
              onChange={async e => await setFieldValue('autoRenew', e.target.checked)}
            />
          }
          label={
            <Grid container direction="column">
              <Typography variant="body2">Auto-renew my domain next year</Typography>
              <Typography style={{ color: theme.branding.v2.gray[500], marginTop: 8 }} variant="caption">
                Recommended to avoid interruptions and connection issues. You’ll be notified ahead of renewal.
              </Typography>
            </Grid>
          }
          name="autoRenew"
        />
      </FormGroup>
    </Grid>
  );
};

export default Registration;
