import { Shop } from '@castiron/domain';
import _ from 'lodash';

const artisanCategories = {
  // category is the key and then shop.artisanCategory.name or shop.artisanCategory.subcategories that maps to that category
  'Appetizers & Snacks': [
    // marketplace taxonomy (leaf)
    'charcuterie board',
    'charcuterie cups',
    'charcuterie box',
    'grazing table',
    'other charcuterie',
    'snacks - popcorn',
    // v4
    'appetizers & snacks',
    // v1-v3
    'charcuterie',
    'charcuterie boards',
    'other snacks',
    'pickled products',
    'popcorn',
    'snacks',
    'snack mixes',
  ],
  'Cooking Classes': [
    // marketplace taxonomy (leaf)
    'baking class - general',
    'cookie decorating class',
    'cake decorating class',
    'cooking class - general',
    'charcuterie class',
    'other classes',
    // v4
    'cooking classes'
  ],
  'Desserts & Baked Goods': [
    // marketplace taxonomy (leaf)
    'custom sugar cookies - iced decoration',
    'custom sugar cookies - printed decoration',
    'cookie decorating kits',
    'macarons',
    'macaroons',
    'drop cookies',
    'custom cake - wedding',
    'custom cake - birthday',
    'custom cake - other',
    'sheet cakes',
    'single-tier cakes',
    'tiered cakes',
    'single serve cake',
    'cupcakes',
    'smash cakes',
    'cake pops',
    'other cakes',
    'hot cocoa',
    'cinnamon rolls',
    'other baked goods',
    // v4
    'desserts & baked goods',
    // v1-v3
    'bagels',
    'baked goods',
    'baking',
    'bread',
    'breads',
    'cakes',
    'candy',
    'chocolate',
    'chocolates',
    'cookies',
    'cupcakes',
    'desserts',
    'ice cream',
    'ice-cream',
    'other baked goods',
    'other desserts',
    'pasta-grains',
    'savory pastries',
    'sweet pastries',
  ],
  'Drinks': [
    // marketplace taxonomy (leaf)
    'coffee cold brew',
    'kombucha',
    // v4
    'drinks',
    // v1-v3
    'beverages',
    'coffee',
    'coffee/tea',
    'coffee-tea',
    'juices',
    'other beverages',
    'tea',
  ],
  'Hot Food': [
    // marketplace taxonomy (leaf)
    'prepared meals - individual',
    'prepared meals - subscription',
    'lunch boxes',
    'other meal prep',
    'wedding catering inquiry',
    'business event catering inquiry',
    'other event catering inquiry',
    'private chef inquiry',
    'custom meal plan inquiry',
    // v4
    'hot food',
    // v1-v3
    'event catering',
    'meal prep',
    'meal prep.',
    'meal-prep',
    'meals',
    'other meals',
    'personal-chef',
    'prepared foods',
    'prepared meals',
    'prepared-meals',
    'ready to eat meals',
    'ready-to-eat meals',
    'soups',
  ],
  'Shelf-Stable Products': [
    // marketplace taxonomy (leaf)
    'bbq sauce',
    'hot sauce',
    'other spreads + dips',
    'preserves - jam',
    'other pantry',
    // v4
    'shelf-stable products',
    // v1-v3
    'honey',
    'hot sauce',
    'hot-sauce-salsas',
    'jams, jellies, preserves',
    'other sauce or spices',
    'sauces, spices, & more',
    'sauces & spices',
    'spice mixes',
    'syrups',
  ],
  'Whole Ingredients': [
    // marketplace taxonomy (leaf)
    'fruit basket',
    // v4
    'whole ingredients',
    // v1-v3
    'dairy',
    'eggs-meat',
    'produce',
  ],
};
const getArtisanCategories = (shop: Shop): string[] => {
  let categories = [];
  if (shop?.taxonomy?.length > 0) {
    shop?.taxonomy?.forEach(taxonomy => {
      for (const category in artisanCategories) {
        if (artisanCategories[category].includes(taxonomy?.leaf?.toLowerCase())) {
          categories.push(category);
        }
      }
    });
  } else if (shop.artisanCategory?.name) {
    // onboarding v2, v3, and v4 shops
    const cat = shop.artisanCategory.name;
    const subcats = shop.artisanCategory.subcategories;

    for (const category in artisanCategories) {
      if (artisanCategories[category].includes(cat.toLowerCase())) {
        categories.push(category);
      }
      if (subcats) {
        subcats.forEach(subcat => {
          if (artisanCategories[category].includes(subcat.toLowerCase())) {
            categories.push(category);
          }
        });
      }
    }
  } else if (shop.productTypes?.length) {
    // legacy onboarding v1 shops
    shop.productTypes.forEach(type => {
      for (const category in artisanCategories) {
        if (artisanCategories[category].includes(type.toLowerCase())) {
          categories.push(category);
        }
      }
    });
  }

  return _.uniq(categories);
};

export default getArtisanCategories;
