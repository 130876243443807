import React from "react";
import { DialogTitle, IconButton, Paper } from "@material-ui/core";
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Close } from "@material-ui/icons";
import Typography from '../Typography';

interface Props {
  action: () => void;
  isCustom?: boolean;
  isTemplate?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    height: '56px',
    width: '56px',
  },
  container: {
    borderRadius: 12,
    position: 'absolute',
    top: '16px',
    right: '16px',
    zIndex: 1,
  },
  icon: {
    height: '28px',
    width: '28px',
    color: theme.branding.v2.gray[800],
  },
  templateHeader: {
    background: theme.branding.v2.gray[0],
    borderBottom: `1px solid ${theme.branding.v2.gray[200]}`,
    height: 72,
    position: 'sticky',
    top: 0,
    textAlign: 'center',
    zIndex: 10,
  },
  templateHeaderButton: {
    height: '56px',
    width: '56px',
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));

const OverImageCloseButton: React.FC<Props> = (props: Props) => {
  const { action, isCustom = false, isTemplate = false } = props;
  const classes = useStyles();

  return (isTemplate
    ?  (
      <DialogTitle className={classes.templateHeader}>
        <Typography variant="h3">Template Preview{isCustom && " (Mobile View)"}</Typography>
        <IconButton className={classes.templateHeaderButton} onClick={action}>
          <Close className={classes.icon} />
        </IconButton>
      </DialogTitle>
    )
    :  (
      <Paper elevation={3} className={classes.container}>
        <IconButton className={classes.button} onClick={action}>
          <Close className={classes.icon} />
        </IconButton>
      </Paper>
    )
  );
};

export default OverImageCloseButton;