

export const popChat = () => {
  window.Beacon('navigate', '/ask/chat/');
  window.Beacon('open');
}
export const enableBeacon = (openChat?: boolean) => {
  window.Beacon('init', process.env.REACT_APP_HELPSCOUT_BEACON_KEY);
  window.Beacon('config', {
    position: 'right',
    //hideFABOnMobile: true,
    messaging: {
      chatEnabled: true,
    },
    labels: {
      chatbotName: 'Castiron Help',
      chatHeadingTitle: "We're here to help!",
      chatHeadingSublabel: 'Let us get you started!',
    },
    display: {
      position: 'right'
    }
  });
  if (openChat) {
    popChat();
  }
}

export const hideBeacon = () => window.Beacon('destroy');