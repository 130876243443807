import {Plan, Price} from '../plan';
import {Attribution} from "../attribution";

export type CancellationReason = 'trial-expired' | 'non-payment' | 'manual' | 'terms-of-service';

export interface Cancellation {
  date: number;
  reason: CancellationReason;
}

interface StripePaymentMethod {
  paymentMethodId: string;
}

interface PaymentMethodIntegrations {
  stripe?: StripePaymentMethod;
}

interface PaymentMethod {
  type: 'card' | 'ach';
  integrations: PaymentMethodIntegrations;
}

export interface PaymentCard extends PaymentMethod {
  type: 'card';
  last4: string;
  expirationDate: string;
  brand: string;
}

export interface PlanDiscount {
  code?: string;
  amount: number;
  type: 'percent' | 'fixed';
  appliesToPlans?: string[],
  integrations: {
    stripe: {
      couponId: string;
      promoCodeId: string;
    },
    cello?: {
      referralCode?: string;
    }
  }
}

export type SubscriptionStatus = 'pending' | 'active' | 'trial' | 'canceled' | 'pending-canceled' | 'payment-failed';

export interface Subscription {
  plan: Plan;
  price: Price;
  startDate: number;
  status: SubscriptionStatus;
  lastPaymentDate?: number;
  nextPaymentDate: number;
  lastPaymentFailureDate?: number;
  trialStartDate?: number;
  trialEndDate?: number;
  isTrialOver?: boolean;
  cancellation?: Cancellation;
  paymentMethod?: PaymentCard;
  integrations: {
    stripe: {
      subscriptionId: string;
    };
  };
  discount?: PlanDiscount;
  attribution?: Attribution;
}

/* accounts for subscription grace period */
const subscriptionFeatureActiveStates: SubscriptionStatus[] = ['active', 'pending', 'pending-canceled', 'payment-failed', 'trial'];
export const areSubscriptionFeaturesActive = (subscription: Subscription): boolean => {
  return subscriptionFeatureActiveStates.includes(subscription?.status);
};
