import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Typography } from '@castiron/components';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { openModal } from '../../store/reducers/modalConductor';
import Dinero from 'dinero.js';

type Props = {
  onSignup?: boolean | false;
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    padding: props => (props.onSignup ? '16px 0' : '16px'),
    borderRadius: '12px',
  },
  redeemCouponBox: {
    backgroundColor: theme.branding.v2.green[50],
    borderRadius: 12,
    padding: 16,
    '& p': {
      color: theme.branding.v2.green[500],
      fontSize: 14,
    },
  },
}));

const RedeemCouponBanner: React.FC<Props> = (props: Props) => {
  const { onSignup } = props;
  const classes = useStyles(props);
  const dispatch = useAppDispatch();

  const { discount, subscription } = useAppSelector(state => ({
    discount: state.shops?.discount,
    subscription: state.shops?.account?.subscription,
  }));

  const openRedeemCouponModal = () => {
    dispatch(
      openModal({
        modalType: 'CHANGE_PLANS_MODAL',
        modalProps: {
          open: true,
          couponCode: discount.code,
          step: 2,
          selectedPlan: {
            plan: subscription.plan,
            price: subscription.price,
          },
        },
      }),
    );
  };

  return (
    <Grid container item wrap="nowrap" alignItems="center" className={classes.container}>
      {onSignup ? (
        <Grid container item className={classes.redeemCouponBox} justify="center" alignItems="center" wrap="nowrap">
          <Typography variant="body1">
            You've been invited to try Castiron and save{' '}
            {discount.type === 'fixed' ? Dinero({ amount: discount.amount }).toFormat('$0.00') : discount.amount}
            {discount.type === 'percent' && '%'} on your subscription! ❤️
          </Typography>
        </Grid>
      ) : (
        <Grid container item className={classes.redeemCouponBox} justify="center" alignItems="center" wrap="nowrap">
          <Typography variant="body1">
            Your {discount.type === 'fixed' ? Dinero({ amount: discount.amount }).toFormat('$0.00') : discount.amount}
            {discount.type === 'percent' && '%'} off coupon has been automatically applied! ❤️
          </Typography>
          <Button variant="text" onClick={openRedeemCouponModal}>
            Redeem
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default RedeemCouponBanner;
