import React, { ReactElement } from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles, Theme } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ButtonV2, TextInput, Typography } from '@castiron/components';
import ModalWrapper from '../../RootModal/ModalWrapper';
import { productRepository, shopRepository } from '../../../domain';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { closeModal } from '../../../store/reducers/modalConductor';

export type Props = {
  categoryName: string;
};

interface FormValues {
  categoryName: string;
}
const schema = yup.object({
  categoryName: yup.string().required('Please include a category name.'),
});

const useStyles = makeStyles((theme: Theme) => ({
  border: {
    borderTop: `1px solid ${theme.branding.v2.gray[200]}`,
  },
  dialogContent: {
    minWidth: 400,
    minHeight: 240,
    paddingTop: 24,
    borderTop: `1px solid ${theme.branding.v2.gray[200]}`,
  },
}));

const RenameCategoryModal: React.FC<Props> = (props: Props) => {
  const { categoryName } = props;
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const { shop, products } = useAppSelector(state => ({
    shop: state.shops.shop,
    products: state.products.products,
  }));

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const submit = async (values: FormValues) => {
    if (values.categoryName !== categoryName) {
      shop.standardCategoryLayout;
      var currentCategory = shop?.categories?.find(cat => cat.name === categoryName);
      const standardCategoryLayout = shop?.standardCategoryLayout?.find(scl => scl.name === categoryName);
      const customCategoryLayout = shop?.customCategoryLayout?.find(ccl => ccl.name === categoryName);
      const productCategoryLayout = shop?.productCategoryLayouts?.find(pcl => pcl.categoryName === categoryName);
      const customProductCategoryLayout = shop?.customProductCategoryLayouts?.find(
        cpcl => cpcl.categoryName === categoryName,
      );
      const updatedShop = {
        ...shop,
        ...(standardCategoryLayout && {
          standardCategoryLayout: [
            ...shop?.standardCategoryLayout?.filter(cat => cat.name !== categoryName),
            { name: values.categoryName, position: standardCategoryLayout.position },
          ],
        }),
        ...(customCategoryLayout && {
          customCategoryLayout: [
            ...shop?.customCategoryLayout?.filter(cat => cat.name !== categoryName),
            { name: values.categoryName, position: customCategoryLayout.position },
          ],
        }),
        ...(productCategoryLayout && {
          productCategoryLayouts: [
            ...shop?.productCategoryLayouts?.filter(cat => cat.categoryName !== categoryName),
            { categoryName: values.categoryName, layout: productCategoryLayout.layout },
          ],
        }),
        ...(customProductCategoryLayout && {
          customProductCategoryLayouts: [
            ...shop?.customProductCategoryLayouts?.filter(cat => cat.categoryName !== categoryName),
            { name: values.categoryName, layout: customProductCategoryLayout.layout },
          ],
        }),
        categories: [
          ...shop.categories?.filter(cat => cat.name !== categoryName),
          { name: values.categoryName, id: currentCategory.id, shopId: shop.id },
        ],
      };
      await shopRepository.updateProps(shop.id, {
        categories: updatedShop.categories,
        ...(updatedShop.standardCategoryLayout && { standardCategoryLayout: updatedShop.standardCategoryLayout }),
        ...(updatedShop.customCategoryLayout && { customCategoryLayout: updatedShop.customCategoryLayout }),
        ...(updatedShop.productCategoryLayouts && { productCategoryLayouts: updatedShop.productCategoryLayouts }),
        ...(updatedShop.customProductCategoryLayouts && {
          customProductCategoryLayouts: updatedShop.customProductCategoryLayouts,
        }),
      });

      await productRepository.batchUpdate(
        products
          .filter(p => p.category?.id === currentCategory.id || p.category?.name === categoryName)
          .map(product => ({ id: product.id, category: { name: values.categoryName, id: currentCategory.id } })),
      );
      window.location.reload();
      dispatch(closeModal());
    }
  };

  const initialState = {
    categoryName: categoryName,
  };

  return (
    <Formik onSubmit={submit} validationSchema={schema} initialValues={initialState}>
      {({ errors, touched, submitForm, setFieldValue }): ReactElement => (
        <ModalWrapper size="xs" show={true}>
          <DialogTitle id="alert-dialog-title">
            <Grid container justify="space-between">
              <Grid item>
                <Typography variant="h2">Rename</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextInput
                  label="Category Name"
                  name="categoryName"
                  required
                  error={touched.categoryName && errors.categoryName}
                  autoFocus
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.border}>
            <ButtonV2 variant="outlined" onClick={handleClose} color="primary">
              Cancel
            </ButtonV2>
            <ButtonV2 variant="contained" onClick={submitForm} color="primary">
              Save
            </ButtonV2>
          </DialogActions>
        </ModalWrapper>
      )}
    </Formik>
  );
};

export default RenameCategoryModal;
