import { upload } from '@castiron/castiron-firebase';
import {
  Banner,
  ButtonV2,
  CheckboxInput,
  CollapsableCard,
  CustomSwitch,
  DropDownOption,
  Dropdown,
  Forms,
  TextInput,
  Typography,
} from '@castiron/components';
import { Asset, hasSchedule } from '@castiron/domain';
import { Box, FormControlLabel, FormGroup, Grid, Paper, useMediaQuery } from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import PhotoLibraryOutlined from '@material-ui/icons/PhotoLibraryOutlined';
import { nanoid } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { MAX_UPLOAD_FILE_SIZE } from '../../../constants';
import { useAppSelector } from '../../../hooks';
import ProductInsightTile from '../../Products/EditProduct/FormComponents/ProductInsightTile';
import ProductStatus from '../../Products/EditProduct/FormComponents/ProductStatus';
import Spinner from '../../Spinner';
import { presaleRepository } from '../../../domain';

type Props = {
  presaleId?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  bgColor: {
    /* hack to include alpha using theme color */
    backgroundColor: `${theme.branding.v2.blue[100]}7a !important`,
  },
  card: {
    margin: 0,
  },
  cardContent: {
    padding: 24,
  },
  checkbox: {
    paddingLeft: 0,
  },
  dragging: {
    backgroundColor: theme.branding.blue.light,
  },
  emptyContainer: {
    borderRadius: '16px',
    padding: '24px',
    [theme.breakpoints.up('md')]: {
      padding: '64px',
    },
  },
  emptyIcon: {
    color: theme.branding.v2.blue[200],
    height: '48px',
    width: '48px',
  },
  image: {
    aspectRatio: '1/1',
    height: '100%',
    objectFit: 'cover',
    width: '100%',
  },
  imageContainer: {
    alignSelf: 'center',
    backgroundColor: theme.branding.v2.gray[100],
    border: `1px dashed ${theme.branding.v2.gray[200]}`,
    borderRadius: 16,
    overflow: 'hidden',
    position: 'relative',
    height: 224,
    width: 224,
  },
  lowInventorySwitch: {
    margin: '0 0 8px 0',
  },
  menuContainerNonPrimary: {
    color: theme.branding.v2.gray[500],
    '&:hover': {
      color: theme.branding.v2.gray[800],
    },
    position: 'absolute',
    top: '4px',
    right: '4px',
    height: '40px',
    width: '40px',
  },
}));

const PresaleGeneral: React.FC<Props> = (props: Props) => {
  const { presaleId } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { errors, setFieldValue, touched, values }: any = useFormikContext();

  const [uploading, setUploading] = useState<boolean>(false);
  const [failedToUpload, setFailedToUpload] = useState<string[]>([]);
  const [fileSizeError, setFileSizeError] = useState<boolean>(false);
  const [maxFilesError, setMaxFilesError] = useState<boolean>(false);

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const imageUrl = (asset: Asset) => asset.mediumVersion?.downloadUrl || asset.downloadUrl;

  const handleFile = async (file, handleFileUploadSuccess: (downloadUrl, metadata, options) => void) => {
    const id = nanoid();

    if (file) {
      const metadata = {
        assetType: 'presale',
        id,
        shopId: shop.id,
        originalFilename: file.name,
        contentType: file.type,
        presaleId,
      };
      const options = {
        folder: `user/${shop.id}`,
      };
      const callbacks = {
        success: handleFileUploadSuccess,
      };

      upload(file, metadata, options, callbacks, {});
    }
  };

  const handleFiles = (files: any[]) => {
    if (files.some(file => file.size > MAX_UPLOAD_FILE_SIZE)) {
      setFileSizeError(true);
      return;
    } else {
      setFileSizeError(false);
    }
    if (files.length > 1) {
      setMaxFilesError(true);
      return;
    } else {
      setMaxFilesError(false);
    }
    setUploading(true);
    /* only doing successes right now, will likely end with a never ending wait if something errors
     * maybe add a timeout later and message which images aren't in completed so the user can retry them?
     */
    setFailedToUpload([]);
    const completed = [];
    files.forEach(file =>
      handleFile(file, (downloadUrl, metadata, options) => {
        const image = { id: metadata.id, downloadUrl, metadata, options };
        completed.push(image);
      }),
    );

    const waitForImageUploadsAndSetValues = async (attempts: number = 0) =>
      setTimeout(() => {
        if (
          _.intersection(
            completed.map(file => file.metadata.originalFilename),
            files.map(file => file.name),
          ).length === files.length
        ) {
          setImages([...values?.images, ...completed]);
          /* 10 seconds */
        } else if (attempts > 100) {
          setImages([...values?.images, ...completed]);
          setFailedToUpload(
            _.difference(
              files.map(file => file.name),
              completed.map(file => file.metadata.originalFilename),
            ),
          );
        } else {
          waitForImageUploadsAndSetValues(attempts + 1);
        }
      }, 100);

    waitForImageUploadsAndSetValues();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: handleFiles,
  });

  const setImages = async images => {
    await setFieldValue('images', images);
    /* There was a delay with the updated values being used in submit. Manually providing the updated values to submitNotes helps with that. */
    setUploading(false);
  };

  const imageDropdown = (image: Asset) => {
    const menuOptions: DropDownOption[] = [
      {
        label: 'Delete',
        icon: <DeleteOutlined />,
        onClick: async () => {
          setImages(values?.images.filter(valueImage => image.id !== valueImage.id));
          await presaleRepository.updateProps(presaleId, {
            images: [],
          });
        },
        color: 'error',
      },
    ];
    return (
      <Paper className={classes.menuContainerNonPrimary}>
        <Grid container style={{ height: '100%' }} justify="center" alignItems="center">
          <Grid item>
            <Dropdown
              variant="ellipsis"
              options={menuOptions}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const displayImageCard = (image: Asset, index: number) => (
    <Box className={classes.imageContainer} key={`imageCard${index}-${image?.id}-`}>
      <img src={imageUrl(image)} className={classes.image} />
      {imageDropdown(image)}
    </Box>
  );

  const emptyDropzone = (
    <div
      {...getRootProps({ className: `dropzone ${isDragActive ? classes.dragging : ''}` })}
      style={{ height: '100%' }}
    >
      <Box className={clsx([classes.emptyContainer, classes.bgColor])}>
        <input {...getInputProps()} />
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item>
            <PhotoLibraryOutlined className={classes.emptyIcon} />
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <Typography variant="button" style={{ textAlign: 'center' }}>
                  Drop Your Photo Here
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">JPEG, JPG, or PNG accepted</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="body2">OR</Typography>
          </Grid>
          <Grid item>
            <ButtonV2 variant="contained">Browse Photos</ButtonV2>
          </Grid>
        </Grid>
      </Box>
    </div>
  );

  const scheduledPresaleInfoBanner = hasSchedule(values) && (
    <Banner variant="info-blue">
      <Typography variant="body2" style={{ color: 'inherit' }}>
        Selected products and fulfillments will be available during this schedule regardless of product and fulfillment
        status.
      </Typography>
    </Banner>
  );

  const lowInventoryCount = (
    <CollapsableCard defaultExpanded noScroll title="Display on Website">
      <FormGroup>
        <FormControlLabel
          className={classes.lowInventorySwitch}
          control={
            <CustomSwitch
              label={true}
              checked={values?.showLowInventoryCount}
              onChange={e => setFieldValue('showLowInventoryCount', e.target.checked)}
            />
          }
          label="Show Low Inventory Count"
          name="showLowInventoryCount"
        />
      </FormGroup>
      <Typography style={{ color: theme.branding.v2.gray[500], marginTop: 12 }} variant="body1">
        Show shoppers when you have less than 30 total inventory available left in this presale to drive urgency.
      </Typography>
    </CollapsableCard>
  );

  return (
    <>
      <Spinner size="fullscreen" show={uploading} label="Uploading Image(s)" />
      <Grid container item xs={12} md={8}>
        <CollapsableCard
          className={classes.card}
          contentClassName={classes.cardContent}
          defaultExpanded
          noScroll
          title="Basic Information"
        >
          <Grid container item direction="column" wrap="nowrap" style={{ gap: 24 }}>
            <TextInput
              error={touched.title && errors.title}
              label="Title"
              maxLength={50}
              name="title"
              required={values?.status !== 'inactive' || hasSchedule(values)}
            />
            <TextInput label="Description" multiline name="description" maxLength={120} rows={3} />
            <CheckboxInput
              className={classes.checkbox}
              name="isFeatured"
              label={
                <>
                  <Typography variant="subtitle2">⭐️ Feature This Presale</Typography>
                  <Typography style={{ color: theme.branding.v2.gray[700] }} variant="body2">
                    Up to 4 featured presales will be highlighted on your homepage.
                  </Typography>
                </>
              }
              variant="label-right"
            />
            <Grid container direction="column" wrap="nowrap">
              <Typography variant="subtitle2">Cover Photo</Typography>
              <Typography style={{ color: theme.branding.v2.gray[700], marginBottom: 16 }} variant="body2">
                If no cover photo is chosen for this presale, it will automatically use one of the product photos.
              </Typography>
              {values?.images?.length === 0
                ? emptyDropzone
                : values?.images?.map((image, index) => displayImageCard(image, index))}
              {errors.images && <Forms.SubmissionError msg={errors.images} />}
              {maxFilesError && <Forms.SubmissionError msg="Please select only one cover photo" />}
              {fileSizeError && <Forms.SubmissionError msg="Images must be under 10MB" />}
              {failedToUpload.length > 0 && (
                <Forms.SubmissionError
                  msg={`Failed to upload the following images: ${failedToUpload.join(',')}. Please try again.`}
                />
              )}
            </Grid>
          </Grid>
        </CollapsableCard>
      </Grid>
      <Grid
        container
        item
        direction="column"
        wrap="nowrap"
        xs={12}
        md={4}
        style={{ gap: 24 }}
        justify="flex-end"
      >
        <CollapsableCard
          className={classes.card}
          contentClassName={classes.cardContent}
          defaultExpanded
          noScroll
          title="Availability"
        >
          <Grid container direction="column" wrap="nowrap" style={{ gap: 24 }}>
            <ProductStatus context="presale" />
            {scheduledPresaleInfoBanner}
          </Grid>
        </CollapsableCard>
        {lowInventoryCount}
        <ProductInsightTile context="presale" id={presaleId} />
      </Grid>
    </>
  );
};

export default PresaleGeneral;
