import React, { ReactNode } from 'react';
import { makeStyles, Select as MUISelect, MenuItem } from '@material-ui/core';
import ChevronDownIcon from '../assets/chevron-down.svg';
import SvgIcon from '../SvgIcon';

type Props = {
  options: any;
  fullWidth?: boolean;
  value: string | number;
  name?: string;
  onChange: (value) => void;
  selectClass?: string;
  startAdornment?: ReactNode;
  children?: ReactNode;
};

const useStyles = makeStyles(() => ({
  inputRoot: {
    fontWeight: 600,
    minWidth: 240,
    height: 48,
    fontSize: 14,
    lineHeight: '20px',
    '& .MuiAutocomplete-input': {
      paddingTop: 8,
    },
  },
  icon: {
    width: 36,
    height: 36,
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    pointerEvents: 'none',
  },
  menu: {
    // position: 'relative',
    // top: 40,
  },
}));

const Select: React.FC<Props> = (props: Props) => {
  const { options, value, name, onChange, selectClass, startAdornment } = props;
  const classes = useStyles();

  return (
    <MUISelect
      startAdornment={startAdornment}
      IconComponent={params => (
        <SvgIcon {...params} className={classes.icon}>
          <ChevronDownIcon />
        </SvgIcon>
      )}
      classes={{ selectMenu: classes.menu }}
      className={`${classes.inputRoot} ${selectClass}`}
      name={name}
      variant="outlined"
      onChange={onChange}
      value={value}
    >
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </MUISelect>
  );
};

export default Select;
