import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { productRepository } from '../../domain';
import { BaseProduct, InputField } from '@castiron/domain';
import { removeEmpty } from '@castiron/utils';
import { handleReducerError } from './errorHandler';
import { nanoid } from 'nanoid';

export interface ProductsState {
  products: BaseProduct[];
  loading: boolean;
  error: string;
  haveLoaded: boolean;
}

const initialState: ProductsState = {
  products: [],
  loading: false,
  error: '',
  haveLoaded: false,
};

const generateNewVariationIds = (variations: InputField[]): InputField[] => {
  if (!variations) return undefined;
  return variations.map(v => {
    const values = v.values.map(s => ({
      ...s,
      id: nanoid(),
    }));

    return {
      ...v,
      id: nanoid(),
      values,
    };
  });
};

const getProducts = async (shopId: string) => {
  const response = await productRepository.findByShopId(shopId);
  console.debug('response from getProducts: ', response);
  return response;
};

const getProductById = async (id: string) => {
  const response = await productRepository.get(id);
  console.debug('response from getProductById: ', response);
  return response;
};

const createProduct = async product => {
  const response = await productRepository.create(
    removeEmpty<BaseProduct>({
      ...product,
      variations: generateNewVariationIds(product.variations),
    }) as BaseProduct,
  );
  console.debug('response from createProduct: ', response);
  return response;
};

const createTemplateProduct = async (product: BaseProduct): Promise<BaseProduct> => {
  delete product.updatedAt;
  delete product.id;
  if (product.source === 'onboarding') {
    //actions for automatically created template products
    delete product.category;
    delete product.imageObj;
  }

  const formattedProduct = removeEmpty<BaseProduct>({
    ...product,
    variations: generateNewVariationIds(product.variations),
  }) as BaseProduct;

  const response = await productRepository.create(formattedProduct);
  return response;
};

const duplicateProduct = async (id: string): Promise<BaseProduct> => {
  const product = await productRepository.get(id);
  delete product.id;

  const response = await productRepository.create(
    removeEmpty<BaseProduct>({
      ...product,
      title: `Copy of ${product.title}`,
      variations: generateNewVariationIds(product.variations),
    }) as BaseProduct,
  );

  return response;
};

const updateProduct = async ({ product, deleteSchedule }) => {
  const response = await productRepository.updateProps(product.id, removeEmpty<BaseProduct>(product) as BaseProduct);
  if (deleteSchedule) {
    await productRepository.deleteFields(product.id, ['schedule']);
  }
  // no longer using imageObj so removing lingering objects during product updates
  await productRepository.deleteFields(product.id, ['imageObj']);
  console.debug('response from updateProduct: ', response);
  return response;
};

const deleteProduct = async id => {
  const response = await productRepository.delete(id);
  console.debug('response from deleteProduct:', response);
  return { id };
};

export const getProductsAction = createAsyncThunk('products/getProducts', getProducts);
export const getProductByIdAction = createAsyncThunk('products/getProductById', getProductById);
export const createProductAction = createAsyncThunk('products/createProduct', createProduct);
export const createTemplateProductAction = createAsyncThunk('products/createTemplateProduct', createTemplateProduct);
export const duplicateProductAction = createAsyncThunk('products/duplicateProduct', duplicateProduct);
export const updateProductAction = createAsyncThunk('products/updateProduct', updateProduct);
export const deleteProductAction = createAsyncThunk('products/deleteProduct', deleteProduct);

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(createProductAction.rejected, handleReducerError('Error Creating Product'))
      .addCase(createTemplateProductAction.rejected, handleReducerError('Error Creating Templated Product'))
      .addCase(duplicateProductAction.rejected, handleReducerError('Error Duplicating Product'))
      .addCase(updateProductAction.rejected, handleReducerError('Error Updating Product'))
      .addCase(getProductsAction.rejected, handleReducerError('Error Getting Products'))
      .addCase(getProductByIdAction.rejected, handleReducerError('Error Getting Product'))
      .addCase(getProductsAction.pending, state => {
        state.loading = true;
      })
      .addCase(getProductsAction.fulfilled, (state, action) => {
        state.products = action.payload;
        state.loading = false;
        state.haveLoaded = true;
      })
      .addCase(deleteProductAction.fulfilled, (state, action) => {
        state.products = state.products.filter(product => {
          if (action.payload?.id === product.id) {
            return false;
          }
          return true;
        });
      });
  },
});

export default productsSlice.reducer;
