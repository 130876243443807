import { FeatureName, Shop } from '@castiron/domain';

export const findFontFamily = (
  shop: Shop,
  features: FeatureName[],
  type: 'primary' | 'secondary',
  fontParam?: string,
) => {
  if (!features?.includes('shop.themes')) {
    return ['Nunito Sans', 'sans-serif'].join(',');
  } else {
    const shopFonts = shop?.config?.shopTheme?.shopFont;
    const font = !shopFonts
      ? ['Nunito Sans', 'sans-serif'].join(',')
      : type === 'primary'
      ? shopFonts?.primary
      : shopFonts?.secondary;

    switch (fontParam ? fontParam : font) {
      case 'abril-fatface':
        return ['Abril Fatface', 'cursive'].join(',');
      case 'dela-gothic-one':
        return ['Dela Gothic One', 'cursive'].join(',');
      case 'inter':
        return ['Inter', 'sans-serif'].join(',');
      case 'josefin-sans':
        return ['Josefin Sans', 'sans-serif'].join(',');
      case 'jost':
        return ['Jost', 'sans-serif'].join(',');
      case 'lato':
        return ['Lato', 'sans-serif'].join(',');
      case 'lemon':
        return ['Lemon', 'cursive'].join(',');
      case 'lobster':
        return ['Lobster', 'cursive'].join(',');
      case 'lora':
        return ['Lora', 'serif'].join(',');
      case 'montserrat':
        return ['Montserrat', 'sans-serif'].join(',');
      case 'mulish':
        return ['Mulish', 'sans-serif'].join(',');
      case 'nunito-sans':
        return ['Nunito Sans', 'sans-serif'].join(',');
      case 'open-sans':
        return ['Open Sans', 'sans-serif'].join(',');
      case 'pacifico':
        return ['Pacifico', 'cursive'].join(',');
      case 'philosopher':
        return ['Philosopher', 'sans-serif'].join(',');
      case 'playfair-display':
        return ['Playfair Display', 'serif'].join(',');
      case 'poppins':
        return ['Poppins', 'sans-serif'].join(',');
      case 'pt-serif':
        return ['PT Serif', 'serif'].join(',');
      case 'roboto':
        return ['Roboto', 'sans-serif'].join(',');
      case 'sail':
        return ['Sail', 'cursive'].join(',');
      case 'shrikhand':
        return ['Shrikhand', 'cursive'].join(',');
      case 'syne':
        return ['Syne', 'sans-serif'].join(',');
      case 'ultra':
        return ['Ultra', 'serif'].join(',');
      case 'yeseva-one':
        return ['Yeseva One', 'cursive'].join(',');
      default:
        return font;
    }
  }
};
