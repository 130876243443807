import React, { ReactNode } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

interface InputLabelProps {
  label: ReactNode;
  secondaryLabel?: ReactNode;
  required?: boolean;
  onClick?: () => void;
};

const useStyles = makeStyles<Theme, InputLabelProps>((theme: Theme) => ({
  required: {
    color: theme.branding.red.primary,
  },
  secondaryLabel: {
    color: theme.branding.gray[700],
  },
  clickable: {
    cursor: props => props.onClick ? 'pointer' : 'auto',
  },
}));

const InputLabel: React.FC<InputLabelProps> = (props: InputLabelProps) => {
  const { label, secondaryLabel, required, onClick } = props;
  const classes = useStyles(props);

  return (
    <Grid container direction='column'>
      <Grid item>
        {typeof label === 'string' ? (
          <Typography variant='subtitle2' onClick={onClick} className={classes.clickable}>
            {label}
            {
              required &&
              <span className={classes.required}>&nbsp;*</span>
            }
          </Typography>
        ) : (
          label
        )}
      </Grid>
      {
        secondaryLabel &&
        <Grid item>
          {typeof secondaryLabel === 'string' ? (
            <Typography variant='body2' className={clsx([classes.secondaryLabel, classes.clickable])} onClick={onClick}>
              {secondaryLabel}
            </Typography>
          ) : (
            secondaryLabel
          )}
        </Grid>
      }
    </Grid>
  );
};

export default InputLabel;
