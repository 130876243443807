import { useConfig } from '@castiron/castiron-firebase';
import { AddressInput, CustomSwitch, TextInput, Typography } from '@castiron/components';
import { Address, addressSchema, ChecklistValues, PlanDiscount, Price, PriceFrequency } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { Grid, List, ListItem } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import clsx from 'clsx';
import Dinero from 'dinero.js';
import { Formik } from 'formik';
import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { accountRepository, planRepository, tierRepository } from '../../../domain';
import { getService } from '../../../firebase';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getSubscriptionStatus } from '../../../lib/accountUtils';
import { getShopAction, setDiscountAction } from '../../../store/reducers/shops';
import AdminForm from '../../AdminForm';
import PaymentMethodDisplay from '../../SelectPlan/PlanComponents/PaymentMethodDisplay';
import { SelectedSubscription } from '../../SelectPlan/PlanFlow';
import { StickyFooterProps } from './OnboardingFooter';

const addCouponToSubService = getService('subscriptions', 'addcoupontosub');
const createSubscriptionService = getService('subscriptions', 'createsubscription');
const createSetupIntentService = getService('subscriptions', 'createsetupintent');
const changeSubscriptionService = getService('subscriptions', 'changesubscription');
const getBalanceService = getService('stripe', 'getbalance');
const sendSubscriptionUpgradeDuringTrialEmail = getService('subscriptions', 'sendsubupgradeduringtrialemail');
const sendSubscriptionDowngradeProrationEmail = getService('subscriptions', 'sendsubdowngradeprorationemail');
const validatePromoCodeService = getService('subscriptions', 'validatepromocode');
const getFutureInvoiceService = getService('subscriptions', 'getfutureinvoicetotals');

interface Props {
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setHeader?: (header: string) => void;
  setSubHeader?: (subHeader: string) => void;
  setStickyFooterProps?: (props: StickyFooterProps) => void;
  onFinishOnboarding?: () => void;
  isUnpaidPage?: boolean;
}

type FormValues = {
  address: {
    fullAddress: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    region: string;
    regionName: string;
    country: string;
    postalCode: string;
  };
  discountCode?: string;
};

type Validator = (values: FormValues) => boolean;

const useStyles = makeStyles((theme: Theme) => ({
  addressLine1: {
    '& div': {
      margin: 0,
    },
  },
  addressLineTwoContainer: {
    height: 54,
    alignSelf: 'center',
  },
  addressLine2CTA: {
    color: theme.branding.v2.blue[500],
    '&:hover': {
      cursor: 'pointer',
    },
  },
  annualBanner: {
    backgroundColor: theme.branding.v2.blue[50],
    padding: '21px 16px',
    borderRadius: '12px',
  },
  cardElement: {
    border: 'none',
    padding: 14,
    backgroundColor: theme.branding.v2.gray[0],
    color: theme.branding.v2.gray[900],
    width: '100%',
    borderRadius: 'inherit',
    '& .MuiInputBase-input': {
      ...theme.typography.body4,
      fontSize: 16,
      padding: 0,
      color: theme.branding.v2.gray[900],
    },
    '& ::placeholder': {
      ...theme.typography.body4,
      fontSize: 16,
      opacity: 1,
    },
  },
  changeLink: {
    color: theme.branding.v2.blue[500],
    cursor: 'pointer',
  },
  container: {
    margin: '24px 0px 200px',
    gap: 24,
  },
  creditCardText: {
    paddingBottom: '8px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  discountCodeContainer: {
    '& svg': {
      color: theme.branding.v2.gray[900],
    },

    '& h6': {
      color: theme.branding.v2.blue[500],
    },
  },
  discountCodeError: {
    color: theme.branding.v2.red[500],
    marginTop: 4,
  },
  discountCodeSuccess: {
    color: theme.branding.v2.green[500],
    marginTop: 4,
  },
  discountCodeTitle: {
    color: theme.branding.v2.gray[900] + ' !important',
    marginBottom: '8px',
  },
  discountCodeInput: {
    marginRight: 8,
    width: '100%',
  },
  dueText: {
    ...theme.typography.h4,
    fontSize: '24px',
    lineHeight: '36px',
  },
  earlyAdopterThankYouBanner: {
    background: theme.branding.v2.blue[500],
    borderRadius: 16,
    padding: 16,
  },
  errorBox: {
    backgroundColor: theme.branding.v2.red[50],
    borderRadius: 12,
    padding: '12px 16px',
    margin: '16px 0px',
  },
  errorColor: {
    color: `${theme.branding.v2.red[500]} !important`,
    '& input': {
      color: `${theme.branding.v2.red[500]} !important`,
    },
  },
  errorList: {
    listStyleType: 'disc',
  },
  errorListItem: {
    display: 'list-item',
    color: theme.branding.v2.red[500],
    marginLeft: 8,
  },
  header: {
    textAlign: 'center',
  },
  leftCardElement: {
    borderBottomLeftRadius: 12,
  },
  longCardElement: {
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderRadius: '12px 12px 0px 0px',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      minWidth: 200,
      width: '100%',
    },
  },
  lowerCardElements: {
    display: 'flex',
    flexDirection: 'row',
    border: `1px solid ${theme.branding.v2.gray[200]}`,
    borderTop: 'none',
    borderRadius: '0 0 12px 12px',
    width: '100%',
  },
  paymentInfo: {
    gap: '24px',
  },
  redText: {
    color: theme.branding.v2.red[500],
  },
  rightCardElement: {
    height: 44,
    borderBottomRightRadius: 12,
    borderLeft: `1px solid ${theme.branding.v2.gray[200]}`,
  },
}));

const SubscriptionCheckout: React.FC<Props> = (props: Props) => {
  const { setLoading, setHeader, setStickyFooterProps, setSubHeader, onFinishOnboarding, isUnpaidPage = false } = props;
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const classes = useStyles();
  const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();

  const { account, discount, shop, subscription, testClock, userState } = useAppSelector(state => ({
    account: state.shops.account,
    discount: state.shops.discount,
    shop: state.shops.shop,
    subscription: state.shops.account.subscription,
    testClock: state.debug.stripe?.testClock,
    userState: state.shops.userState,
  }));

  const ffconfig = useConfig();
  const isFirstMonthPromoEnabled = ffconfig?.featureFlag('feature_first_month_promo', shop);
  const earlyAdopterDiscountEnd = ffconfig && ffconfig.config().getNumber('feature_castiron_early_adopter_end_date');
  const todayUnix = moment().unix();
  const isEarlyAdopter = account?.isEarlyAdopter() && todayUnix < earlyAdopterDiscountEnd;

  const annualPrice = isEarlyAdopter ? '$120.00' : '$216.00';
  const monthlyPrice = isEarlyAdopter ? '$10.00' : '$24.00';

  const cardRef = useRef<HTMLBaseElement>();
  const errorRef = useRef<any>();
  const formikRef = useRef<any>();

  let addressText;
  if (account?.billingAddress?.fullAddress) {
    addressText = account.billingAddress.fullAddress;
  } else if (account?.billingAddress?.region) {
    addressText = `${account.billingAddress.city}, ${account.billingAddress.region} ${account.billingAddress.postalCode}`;
  }

  const isPendingCanceled = subscription?.status === 'pending-canceled';
  const useExistingCreditCard = subscription?.paymentMethod && isPendingCanceled;
  const lineItemPriceFormat = '$0,0.00';
  const formatPrice = price => Dinero({ amount: price || 0 }).toFormat(lineItemPriceFormat);

  const [address, setAddress] = useState<Address>();
  const [cardComplete, setCardComplete] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });
  const [changeAddress, setChangeAddress] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [nextChargeDateText, setNextChargeDateText] = useState<string>('');
  const [stripeTotal, setStripeTotal] = useState<number>(0);
  const [planDiscount, setPlanDiscount] = useState<PlanDiscount>();
  const [total, setTotal] = useState<number>(0);
  const [discountAmount, setDiscountAmount] = useState<number>(0);
  const [discountCodeApplied, setDiscountCodeApplied] = useState(false);
  const [discountCodeError, setDiscountCodeError] = useState('');
  const [discountCodeSuccess, setDiscountCodeSuccess] = useState('');
  const [discountCodeInputText, setDiscountCodeInputText] = useState<string>('');
  const [isValidatingCode, setIsValidatingCode] = useState(false);
  const [isAnnual, setIsAnnual] = useState(false);
  const [showaddressLine2Input, setShowaddressLine2Input] = useState(false);
  const [taxes, setTaxes] = useState(0);
  const [stripeErrors, setStripeErrors] = useState([]);
  const [submitErrors, setSubmitErrors] = useState([]);
  const [selectedSub, setSelectedSub] = useState<SelectedSubscription>();
  const [lineItems, setLineItems] = useState([]);

  useEffect(() => {
    dispatch(getShopAction(account.id));
  }, []);

  useEffect(() => {
    setHeader('');
    setSubHeader('');
    setStickyFooterProps({
      onNextClick: async () => {
        if (!formikRef.current.isSubmitting) {
          await formikRef.current.validateForm();
          if (!formikRef.current.isValid) {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
            console.log({ errors: formikRef.current.errors });
          }
          formikRef.current.submitForm();
        }
      },
      isFinalStep: (isUnpaidPage && !isEarlyAdopter) || !isUnpaidPage,
    });

    return () => {
      setStickyFooterProps(undefined);
      setHeader('');
      setSubHeader('');
    };
  }, [formikRef]);

  useEffect(() => {
    findSubscriptionPlan(isAnnual);
  }, [isAnnual]);

  const findSubscriptionPlan = async annual => {
    const castironPlanId = 'hQ1zDSc1uJ2LxJe3teVe';
    const castironTierId = 'jW1TZgc3sn9iKMcWlghl';
    const castironEarlyAdopterPlanId = 'xTIZLDcVLeGoP1Fd39KI';
    const castironEarlyAdopterTierId = 'R3p07UAtSVjoskTkMUjo';
    const plan = await planRepository.get(isEarlyAdopter ? castironEarlyAdopterPlanId : castironPlanId);
    const tier = await tierRepository.get(isEarlyAdopter ? castironEarlyAdopterTierId : castironTierId);
    const price = plan?.prices?.find(p => (annual ? p?.frequency === 'yearly' : p?.frequency === 'monthly'));

    setSelectedSub({
      plan,
      price,
      takeRate: tier?.castironTakeRate,
    });
  };

  useEffect(() => {
    /* balance is negative when already paid */
    const currentBalanceFormatted = formatPrice(currentBalance * -1);
    if (currentBalance * -1 > selectedSub?.price?.amount) {
      const nextChargeDate = calculateNextChargeDate(currentBalance, selectedSub?.price).format('LL');
      setNextChargeDateText(
        `Your ${currentBalanceFormatted} in plan credit will be applied to this and future billing cycles until it runs out. Your card won’t be charged again until ${nextChargeDate}.`,
      );
    } else if (!!currentBalance) {
      setNextChargeDateText(
        `We’ve prorated the remainder of your existing plan and applied the credit of ${currentBalanceFormatted} to your new plan.`,
      );
    } else {
      setNextChargeDateText('');
    }
  }, [selectedSub, currentBalance]);

  useEffect(() => {
    getBalanceService({}).then(resp => setCurrentBalance(resp.balance));
  }, []);

  useEffect(() => {
    if (discount) {
      setPlanDiscount(account?.subscription?.discount);
      setDiscountCodeInputText(discount.code);
      setDiscountCodeApplied(true);
      formikRef?.current?.setFieldValue('discountCode', discount.code);
    }
  }, [discount]);

  useEffect(() => {
    getTotals();
  }, [account, address, selectedSub, discount]);

  useEffect(() => {
    if (
      account &&
      account?.billingAddress &&
      (!!account?.billingAddress?.addressLine1 || !!account?.billingAddress?.fullAddress) &&
      !address
    ) {
      setAddress(account?.billingAddress);
    }
  }, [account, selectedSub]);

  const getTotals = async () => {
    if (selectedSub) {
      const totals = await getFutureInvoiceService({
        address: address,
        planId: selectedSub?.plan.id,
        priceId: selectedSub?.price.id,
        discountCode:
          discount?.code ||
          account.subscription?.discount?.code ||
          account.subscription?.discount?.integrations.stripe.couponId,
      });
      setTaxes(totals.tax);
      setDiscountAmount(totals.discount);
      setStripeTotal(totals.total);
      setPlanDiscount(totals.planDiscount);
    } else {
      setTaxes(0);
    }
  };

  useEffect(() => {
    setTotal(stripeTotal + currentBalance);
  }, [stripeTotal, currentBalance]);

  const planDurationFormat = 'M/D/YY';
  const planDuration = useCallback(
    (frequency: PriceFrequency) => {
      const start = ['inTrial', 'legacyInTrial'].includes(userState)
        ? moment.unix(account.subscription.trialEndDate)
        : moment();
      const end = moment(start).add(1, frequency === 'yearly' ? 'year' : 'month');
      return `${start.format(planDurationFormat)}-${end.format(planDurationFormat)}`;
    },
    [account, userState],
  );

  useEffect(() => {
    const annualFullPrice = selectedSub?.plan?.prices.find(price => price?.frequency === 'monthly')?.amount * 12;
    const planLineItem = {
      left: `${selectedSub?.plan?.name} Plan${
        isPendingCanceled ? '' : ' | ' + planDuration(selectedSub?.price?.frequency)
      }`,
      right: formatPrice(selectedSub?.price?.frequency === 'yearly' ? annualFullPrice : selectedSub?.price?.amount),
    };

    const taxesLineItem = taxes && {
      left: 'Taxes',
      right: formatPrice(taxes),
    };

    const annualDiscountLineItem = selectedSub?.price?.frequency === 'yearly' &&
      selectedSub?.price?.discount && {
        left: 'Annual Discount',
        right: `- ${formatPrice(selectedSub?.price?.discount)}`,
      };

    const currentBalanceLineItem = currentBalance && {
      left: 'Prorated Credit',
      right: formatPrice(currentBalance),
    };

    const lineItemsArr = [planLineItem, annualDiscountLineItem, taxesLineItem, currentBalanceLineItem].filter(x => !!x);
    setLineItems(lineItemsArr);
  }, [account, selectedSub, isPendingCanceled, address, taxes, currentBalance, discount, planDiscount]);

  const finalPriceText = useCallback(() => {
    let left = '';
    let right = '';
    switch (userState) {
      case 'newUser':
        left = `Due in ${isEarlyAdopter ? '16' : '30'} Days:`;
        right = isAnnual ? annualPrice : monthlyPrice;
        break;
      case 'inTrial':
      case 'legacyInTrial':
        const daysUntilCharge = moment.unix(account.subscription.trialEndDate).diff(moment(), 'days');
        left = `Due in ${daysUntilCharge} Days:`;
        right = formatPrice(total);
        break;
      default:
        if (total < 0) {
          left = 'Remaining Plan Credit:';
          right = formatPrice(total);
        } else {
          left = `Due ${
            isPendingCanceled
              ? moment.unix(subscription?.nextPaymentDate).format('MMM D')
              : isEarlyAdopter
              ? 'in 16 Days'
              : 'Today'
          }`;
          right = formatPrice(total);
        }
    }
    return { left, right };
  }, [account, userState, isPendingCanceled, total, isAnnual]);

  const validateThen = async (
    values: FormValues,
    validators: Validator[],
    func: (values: FormValues) => Promise<void>,
  ) => {
    const valid = _.every(
      validators.map(v => v(values)),
      v => v,
    );

    if (valid) {
      return func(values);
    } else {
      setLoading(false);
    }
  };

  const scrollToError = () => {
    let errorEl = document.getElementById('error');
    errorEl.scrollIntoView({ block: 'nearest' });
    return;
  };

  const createNewSubscription = async (values: FormValues) => {
    const cardNumberElement = elements.getElement(CardNumberElement);

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
    });

    if (error) {
      console.error(error);
      return;
    }

    const { clientSecret } = await createSubscriptionService({
      planId: selectedSub?.plan?.id,
      priceId: selectedSub?.price?.id,
      testClock,
      discountCode: discount?.code,
      paymentMethodId: paymentMethod?.id,
      address: {
        addressLine1: values.address.addressLine1,
        addressLine2: values.address.addressLine2,
        city: values.address.city,
        region: values.address.region,
        regionName: values.address.regionName,
        country: values.address.country,
        postalCode: values.address.postalCode,
      },
    });

    if (clientSecret) {
      console.debug('Confirming Card Payment');
      const resp = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            address: {
              line1: values.address.addressLine1,
              line2: values.address.addressLine2,
              city: values.address.city,
              state: values.address.region,
              country: values.address.country,
              postal_code: values.address.postalCode,
            },
          },
        },
      });

      console.debug('Confirmation Response', resp);

      dispatch(setDiscountAction(undefined));

      if (!resp.error) {
        // If credit card succeeds add to checklist
        if (!shop?.checklistCompletions.includes(ChecklistValues.AddCreditCard)) {
          await shop.addToChecklist(ChecklistValues.AddCreditCard);
          await dispatch(getShopAction(shop.id));
        }

        onFinishOnboarding();
        setLoading(false);
      } else {
        setLoading(false);
        resp.error && setSubmitErrors(prev => [...prev, resp.error.message]);
        scrollToError();
      }
    } else {
      dispatch(setDiscountAction(undefined));
      onFinishOnboarding();
      setLoading(false);
    }
  };

  const updateSubscription = useCallback(async () => {
    const elementsTouched = !!cardComplete.cardNumber && !!cardComplete.cardCvc && !!cardComplete.cardExpiry;

    let clientSecret;
    if (
      selectedSub?.plan.id !== account?.subscription?.plan.id ||
      selectedSub?.price.id !== account?.subscription?.price.id
    ) {
      const resp = await changeSubscriptionService({
        planId: selectedSub?.plan.id,
        priceId: selectedSub?.price.id,
        newCard: elementsTouched,
        discountCode: discount?.code,
      });
      clientSecret = resp.clientSecret;
    }

    dispatch(setDiscountAction(undefined));

    return clientSecret;
  }, [account, cardComplete, selectedSub]);

  const updateExistingSubscription = async (values: FormValues) => {
    const cardNumberElement = elements.getElement(CardNumberElement);
    const clientSecret = await updateSubscription();

    if (clientSecret) {
      const resp = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            name: `${shop.owner?.firstName} ${shop.owner?.lastName}`,
            address: {
              line1: values.address.addressLine1,
              line2: values.address.addressLine2,
              city: values.address.city,
              state: values.address.region,
              country: values.address.country,
              postal_code: values.address.postalCode,
            },
          },
        },
      });

      if (!resp.error) {
        if (currentBalance * -1 > selectedSub.price?.amount) {
          sendSubscriptionDowngradeProrationEmail({
            currentBalanceFormatted: Dinero({ amount: currentBalance * -1 }).toFormat(lineItemPriceFormat),
            nextBillingDate: calculateNextChargeDate(currentBalance, selectedSub.price).format('LL'),
          });
        }
        onFinishOnboarding();

        dispatch(setDiscountAction(undefined));
        setLoading(false);
      } else {
        window.scroll(0, 0);
        resp.error && setSubmitErrors([resp.error.message]);
        errorRef.current?.scrollTo({ x: 0, y: 0, behavior: 'smooth' });
        setLoading(false);
      }
    } else {
      onFinishOnboarding();
      dispatch(setDiscountAction(undefined));
      setLoading(false);
    }
  };

  const updateTrialSubscription = async (values: FormValues) => {
    const cardNumberElement = elements.getElement(CardNumberElement);

    await updateSubscription();
    const resp = await createSetupIntentService({});
    const clientSecret = resp.clientSecret;

    if (clientSecret) {
      const resp = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            name: `${shop.owner?.firstName} ${shop.owner?.lastName}`,
            address: {
              line1: values.address.addressLine1,
              line2: values.address.addressLine2,
              city: values.address.city,
              state: values.address.region,
              country: values.address.country,
              postal_code: values.address.postalCode,
            },
          },
        },
      });

      if (resp.setupIntent?.status === 'succeeded' && !resp.error) {
        if (!shop?.checklistCompletions.includes(ChecklistValues.AddCreditCard)) {
          trackEvent('Owner Added Payment Method');
          await shop.addToChecklist(ChecklistValues.AddCreditCard);
          await dispatch(getShopAction(shop.id));
        }
        if (['inTrial', 'legacyInTrial'].includes(userState)) {
          sendSubscriptionUpgradeDuringTrialEmail({});
        }

        if (discount) {
          await addCouponToSubService({
            couponId: discount.code,
          });
          dispatch(setDiscountAction(undefined));
        }

        onFinishOnboarding();

        setLoading(false);
      } else {
        window.scroll(0, 0);
        resp.error && setSubmitErrors([resp.error.message]);
        errorRef.current?.scrollTo({ x: 0, y: 0, behavior: 'smooth' });
        dispatch(setDiscountAction(undefined));
        setLoading(false);
      }
    } else {
      onFinishOnboarding();
      dispatch(setDiscountAction(undefined));
      setLoading(false);
    }
  };

  const updatePaymentMethod = async (values: FormValues) => {
    const cardNumberElement = elements.getElement(CardNumberElement);

    const { clientSecret } = await createSetupIntentService({});
    const setupIntentResp = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardNumberElement,
        billing_details: {
          name: `${shop.owner?.firstName} ${shop.owner?.lastName}`,
          address: {
            line1: values.address.addressLine1,
            line2: values.address.addressLine2,
            city: values.address.city,
            state: values.address.region,
            country: values.address.country,
            postal_code: values.address.postalCode,
          },
        },
      },
    });

    if (setupIntentResp.setupIntent?.status === 'succeeded' && !setupIntentResp.error) {
      onFinishOnboarding();

      setLoading(false);
    } else {
      console.error('setupIntent failed', setupIntentResp.error);
      window.scroll(0, 0);
      setupIntentResp.error && setSubmitErrors([...submitErrors, setupIntentResp.error.message]);
      setLoading(false);
    }
  };

  const applyDiscountCode = useCallback(async () => {
    setDiscountCodeError('');
    setDiscountCodeSuccess('');
    try {
      setIsValidatingCode(true);
      const validateCodeResp = await validatePromoCodeService({
        code: discountCodeInputText,
      });

      if (validateCodeResp.couponValid === false) {
        setDiscountCodeError('Not a valid coupon code.');
        dispatch(setDiscountAction(undefined));
      }
      if (validateCodeResp.appliesToPlans && !validateCodeResp.appliesToPlans?.includes(selectedSub?.plan?.id)) {
        setDiscountCodeError('Sorry, this coupon can only be used on a Castiron Subscription.');
        dispatch(setDiscountAction(undefined));
      }
      if (!validateCodeResp.appliesToPlans || validateCodeResp.appliesToPlans.includes(selectedSub?.plan?.id)) {
        if (!discountCodeApplied) {
          setDiscountCodeError('No coupon code entered.');
          dispatch(setDiscountAction(undefined));
        }
      }
      setIsValidatingCode(false);
    } catch (err) {
      console.error('Error applying discount code', err);
      if (err.message.startsWith('No such coupon')) {
        setDiscountCodeError('Not a valid coupon code.');
        dispatch(setDiscountAction(undefined));
      } else {
        setDiscountCodeError('Sorry, there was an error applying this coupon.');
        dispatch(setDiscountAction(undefined));
      }
      setIsValidatingCode(false);
    }
  }, [discountCodeApplied]);

  const submit = async (values: FormValues) => {
    setLoading(true);
    submitErrors && setSubmitErrors([]);

    try {
      if (values?.discountCode) {
        await applyDiscountCode();
      }

      switch (userState) {
        case 'newUser':
          await validateThen(values, [requireAddress, requireCard], createNewSubscription);
          break;
        case 'legacyNewSubscriber':
          await createNewSubscription(values);
          if (isFirstMonthPromoEnabled) {
            await accountRepository.updateProps(account.id, {
              'config.showFirstMonthPromo': true,
            });
          }
          break;
        case 'currentSubscriber':
          await validateThen(values, [requireAddress, requireCard], updateExistingSubscription);
          break;
        case 'inTrial':
        case 'legacyInTrial':
          await validateThen(values, [requireAddress, requireCard], updateTrialSubscription);
          break;
        case 'subscriptionEnded':
        case 'trialExpired':
        case 'legacyTrialCompleted':
          await validateThen(values, [requireAddress, requireCard], createNewSubscription);
          break;
        default:
          await validateThen(values, [requireAddress, requireCard], updatePaymentMethod);
      }

      trackEvent('Subscription Payment Method Changed', {
        currentSubscription: account?.subscription,
        newSubscription: selectedSub?.plan,
        shopId: shop?.id,
        subscriptionStatus: getSubscriptionStatus(account),
        userState: userState,
      });
      setLoading(false);
    } catch (err) {
      console.error('Error processing subscription', err);
      setSubmitErrors(prev => [...prev, `There was an error processing your subscription: ${err.message}`]);
      setLoading(false);
    }
  };

  const onStripeFieldChange = (e, fieldType) => {
    e.error && setStripeErrors(prev => [...prev, e.error]);

    if (fieldType === 'number') {
      !e.error &&
        setStripeErrors(prev =>
          prev.filter(prevError => {
            return !prevError.code.includes('number');
          }),
        );
    } else if (fieldType === 'expiry') {
      !e.error &&
        setStripeErrors(prev =>
          prev.filter(prevError => {
            return !prevError.code.includes('expiry');
          }),
        );
    } else {
      !e.error &&
        setStripeErrors(prev =>
          prev.filter(prevError => {
            return !prevError.code.includes('cvc');
          }),
        );
    }
  };

  const requireAddress = (values: FormValues) => {
    if (!account?.billingAddress?.postalCode && values.address.fullAddress === '') {
      setSubmitErrors(prev => [...prev, 'Please enter your address']);
      return false;
    }

    return true;
  };

  const requireCard = (values: FormValues) => {
    const elementsTouched = !!cardComplete.cardNumber && !!cardComplete.cardCvc && !!cardComplete.cardExpiry;

    if (!elementsTouched) {
      setSubmitErrors(prev => [...prev, 'Please enter your card details']);
      return false;
    }

    return true;
  };

  const handleCardElementOnChange = e => {
    setCardComplete(cc => {
      return { ...cc, [e.elementType]: e.complete };
    });
  };

  const renderStripeErrors = () => {
    console.log('stripe errors:  ', stripeErrors);
    if (!_.isEmpty(stripeErrors)) {
      return (
        <Grid className={classes.errorBox}>
          <Typography variant="body4" className={classes.errorColor}>
            Please address the following error{stripeErrors.length > 1 && 's'}:
          </Typography>
          <List className={classes.errorList}>
            {stripeErrors.map(error => (
              <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Typography variant="body4" className={classes.errorListItem}>
                  {error.message}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
      );
    }
  };

  const onChangeAddress = setFieldValue => {
    setChangeAddress(true);
    setAddress({
      fullAddress: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      region: '',
      regionName: '',
      postalCode: '',
      country: '',
    });
    setFieldValue('address', {
      fullAddress: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      region: '',
      regionName: '',
      postalCode: '',
      country: '',
    });
  };

  const onAddressChange = useCallback((addr: Address) => {
    setAddress(addr);
  }, []);

  const calculateNextChargeDate = (balance: number, planPrice: Price, renewalDate?: Moment): Moment => {
    let date = renewalDate || moment();
    if (isEarlyAdopter) {
      date = date.add(16, 'days');
    }
    if (balance < planPrice?.amount) {
      return date;
    }
    return calculateNextChargeDate(
      balance - planPrice?.amount,
      planPrice,
      date.add(1, planPrice?.frequency === 'monthly' ? 'month' : 'year'),
    );
  };

  const calculateNextCharge = annual => {
    const nextDate = moment().add(isEarlyAdopter ? 16 : 30, 'days');
    return nextDate.format('LL');
  };

  const getNextPaymentDateAfterTrial = () => {
    if (discount) {
      return calculateNextChargeDate(
        discount.type === 'percent'
          ? currentBalance + (selectedSub?.price?.amount || 0) * (discount.amount / 100)
          : currentBalance + discount.amount,
        selectedSub?.price,
        moment.unix(account?.subscription?.trialEndDate),
      ).format('LL');
    }
    return moment.unix(account?.subscription?.trialEndDate).format('LL');
  };

  const initialValues = {
    address: {
      fullAddress: account?.billingAddress?.fullAddress || '',
      addressLine1: account?.billingAddress?.addressLine1 || '',
      addressLine2: account?.billingAddress?.addressLine2 || '',
      city: account?.billingAddress?.city || '',
      region: account?.billingAddress?.region || '',
      regionName: account?.billingAddress?.regionName || '',
      country: account?.billingAddress?.country || '',
      postalCode: account?.billingAddress?.postalCode || '',
    },
    discountCode: '',
  };

  const paymentSchema = yup.object({
    address: addressSchema(false),
    discountCode: yup.string(),
  });

  return (
    <Grid container direction="column" className={classes.container}>
      {isEarlyAdopter && (
        <Grid container item className={classes.earlyAdopterThankYouBanner}>
          <Typography variant="body2" style={{ color: theme.branding.v2.gray[0] }}>
            <b>Thanks for your ongoing support!</b> Enjoy up to $96 off your subscription every year when you subscribe
            before January 1, 2025*.
          </Typography>
        </Grid>
      )}
      <Grid container direction="column" justify="center" wrap="nowrap" className={classes.header}>
        {userState === 'newUser' && !isEarlyAdopter ? (
          <>
            <Typography variant="body1">Try Castiron</Typography>
            <Typography variant="h4" style={{ fontSize: '24px', lineHeight: '36px' }}>
              FREE for 30 days
            </Typography>
          </>
        ) : (
          <Typography variant="h4" style={{ fontSize: '24px', lineHeight: '36px' }}>
            Select your plan
          </Typography>
        )}
        {isEarlyAdopter ? (
          <>
            <Typography variant="body1">
              <i>
                Standard price: <s>$24/</s>month
              </i>
            </Typography>
            <Typography variant="body1">
              <b>
                Your price: <span className={classes.redText}>$10/month (Save $14/month)</span>
              </b>
            </Typography>
          </>
        ) : (
          userState === 'newUser' && (
            <Typography variant="body1">
              {isAnnual ? (
                <span>
                  Then <s>$24</s> <span className={classes.redText}>$18</span> per month, billed yearly
                </span>
              ) : (
                'Then $24 per month'
              )}
            </Typography>
          )
        )}
      </Grid>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        justify="space-between"
        alignItems="center"
        className={classes.annualBanner}
      >
        <Grid container direction="row" wrap="nowrap" alignItems="center" style={{ gap: '8px' }}>
          <CustomSwitch onChange={() => setIsAnnual(!isAnnual)} value={isAnnual} />
          {isEarlyAdopter ? (
            <Typography variant="body1">Simplify your business with annual billing</Typography>
          ) : (
            <Typography variant="body1">
              <span style={{ fontWeight: 700, color: theme.branding.v2.blue[500] }}>Save $72</span> with annual billing
            </Typography>
          )}
        </Grid>
        {isEarlyAdopter ? (
          <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>
            <b>
              <s>$216</s> <span className={classes.redText}>$120</span>
            </b>
            /year
          </Typography>
        ) : (
          <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>
            $216/year
          </Typography>
        )}
      </Grid>

      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={paymentSchema}
        validateOnMount
        innerRef={formikRef}
      >
        {({ errors, touched, setFieldValue }): ReactElement => {
          return (
            <AdminForm>
              <Grid container item className={classes.paymentInfo} direction="column">
                {!_.isEmpty(submitErrors) && submitErrors.length > 1 ? (
                  <Grid className={classes.errorBox} style={{ marginTop: 0 }}>
                    <Typography variant="h4" className={classes.errorColor}>
                      Error:
                    </Typography>
                    <List className={classes.errorList} style={{ paddingTop: 0, paddingBottom: 0 }}>
                      {submitErrors.map(error => (
                        <ListItem>
                          <Typography variant="body4" className={classes.errorListItem}>
                            {error}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                ) : submitErrors.length === 1 ? (
                  <Grid className={classes.errorBox} style={{ marginTop: 0 }}>
                    <Typography variant="h4" className={classes.errorColor}>
                      Error: <span style={{ fontWeight: 400 }}>{submitErrors[0]}</span>
                    </Typography>
                  </Grid>
                ) : (
                  <></>
                )}

                {useExistingCreditCard ? (
                  <Grid container item direction="column" style={{ marginBottom: 32 }}>
                    <Typography variant="button">Credit/Debit Card</Typography>
                    <Grid item style={{ padding: 12 }}>
                      <PaymentMethodDisplay
                        brand={subscription?.paymentMethod?.brand}
                        last4={subscription?.paymentMethod?.last4}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container item justify="space-between" direction="column">
                    <Grid item className={classes.creditCardText}>
                      <Typography variant="button">Credit/Debit Card Info</Typography>
                    </Grid>
                    <Grid item>
                      <span ref={cardRef}>
                        <CardNumberElement
                          onChange={e => {
                            onStripeFieldChange(e, 'number');
                            handleCardElementOnChange(e);
                          }}
                          options={{
                            classes: {
                              base: clsx([classes.cardElement, classes.longCardElement]),
                            },
                            placeholder: 'Card Number',
                            style: {
                              // for whatever reason you can only edit the placeholder inline
                              base: {
                                fontSize: '16px',
                                '::placeholder': {
                                  fontSize: '16px',
                                  fontWeight: 400,
                                  lineHeight: '24px',
                                  color: theme.branding.gray[700],
                                },
                              },
                            },
                          }}
                        />
                        <Grid container item className={classes.lowerCardElements}>
                          <Grid item xs={6}>
                            <CardExpiryElement
                              onChange={e => {
                                onStripeFieldChange(e, 'expiry');
                                handleCardElementOnChange(e);
                              }}
                              options={{
                                classes: {
                                  base: clsx([classes.leftCardElement, classes.cardElement]),
                                },
                                style: {
                                  base: {
                                    fontSize: '16px',
                                    '::placeholder': {
                                      fontSize: '16px',
                                      fontWeight: 400,
                                      lineHeight: '24px',
                                      color: theme.branding.gray[700],
                                    },
                                  },
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <CardCvcElement
                              onChange={e => {
                                onStripeFieldChange(e, 'cvc');
                                handleCardElementOnChange(e);
                              }}
                              options={{
                                classes: {
                                  base: clsx([classes.rightCardElement, classes.cardElement]),
                                },
                                style: {
                                  base: {
                                    fontSize: '16px',
                                    '::placeholder': {
                                      fontSize: '16px',
                                      fontWeight: 400,
                                      lineHeight: '24px',
                                      color: theme.branding.gray[700],
                                    },
                                  },
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </span>
                      {renderStripeErrors()}
                    </Grid>
                  </Grid>
                )}

                <Grid container item direction="column" id="address" style={{ width: '100%' }}>
                  <Grid container item direction="column" style={{ gap: '8px' }}>
                    <Typography variant="button">Billing Address</Typography>
                    {(!!addressText && !changeAddress && !address) || isPendingCanceled ? (
                      <Grid container item direction="column" alignItems="flex-start" justify="center">
                        <Typography>
                          {addressText}{' '}
                          {!isPendingCanceled && (
                            <span className={classes.changeLink} onClick={() => onChangeAddress(setFieldValue)}>
                              Change
                            </span>
                          )}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid
                        container
                        item
                        direction="column"
                        alignItems="flex-start"
                        justify="center"
                        style={{ width: '100%' }}
                      >
                        <Grid container className={classes.addressLine1} id="address">
                          <AddressInput
                            required
                            addressFields={{
                              address: 'address.fullAddress',
                              addressLine1: 'address.addressLine1',
                              city: 'address.city',
                              region: 'address.region',
                              regionName: 'address.regionName',
                              postalCode: 'address.postalCode',
                              country: 'address.country',
                            }}
                            onAddressChange={onAddressChange}
                            error={touched.address?.fullAddress && errors.address?.fullAddress}
                          />
                        </Grid>
                        <Grid item style={{ width: '100%', marginTop: 8 }}>
                          {showaddressLine2Input ? (
                            <Grid container item direction="column" wrap="nowrap" style={{ gap: '8px' }}>
                              <Typography variant="button" style={{ marginTop: 8 }}>
                                Address Line 2
                              </Typography>
                              <TextInput
                                name="address.addressLine2"
                                className={classes.addressLineTwoContainer}
                                placeholder="Apt #, Suite, Floor"
                              />
                            </Grid>
                          ) : (
                            <Typography
                              variant="button"
                              className={classes.addressLine2CTA}
                              onClick={() => setShowaddressLine2Input(true)}
                            >
                              + Add Address Line 2
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid container item direction="column" id="discountCode" style={{ width: '100%' }}>
                  <Grid container item direction="column" alignItems="flex-start">
                    <Typography variant="subtitle2" className={classes.discountCodeTitle}>
                      Coupon Code
                    </Typography>
                    <Grid container item justify="space-between" alignItems="center" wrap="nowrap">
                      <Grid item className={classes.discountCodeInput}>
                        <TextInput
                          name="discountCode"
                          onChange={e => {
                            setDiscountCodeInputText(e.target.value);
                            setFieldValue('discountCode', e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item justify="flex-start" alignItems="center">
                      {discountCodeError && (
                        <Typography variant="body1" className={classes.discountCodeError}>
                          {discountCodeError}
                        </Typography>
                      )}
                      {discountCodeSuccess && (
                        <Typography variant="body1" className={classes.discountCodeSuccess}>
                          {discountCodeSuccess}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </AdminForm>
          );
        }}
      </Formik>

      {lineItems.map(lineItem => (
        <Grid container justify="space-between">
          <Typography variant="body1">{lineItem.left}</Typography>
          <Typography variant="body1">{lineItem.right}</Typography>
        </Grid>
      ))}

      <Grid container justify="space-between">
        <Typography variant="h4" className={classes.dueText}>
          {finalPriceText().left}
        </Typography>
        <Typography variant="h4" className={classes.dueText}>
          {finalPriceText().right}
        </Typography>
      </Grid>

      <Grid container justify="center">
        {nextChargeDateText && (
          <Typography variant="body2" style={{ color: theme.branding.v2.gray[700], textAlign: 'center' }}>
            {nextChargeDateText}
          </Typography>
        )}
        <Typography variant="body2" style={{ color: theme.branding.v2.gray[700], textAlign: 'center' }}>
          {isUnpaidPage ? (
            isEarlyAdopter ? (
              '* Discounted price available for the lifetime of your account or until you change plans, including changing billing cycle.'
            ) : (
              `You will be charged ${
                isAnnual ? `${annualPrice} per year` : `${monthlyPrice} per month`
              } starting today. You can always manage your plan details in admin.`
            )
          ) : (
            <>
              After your trial ends, you will be charged{' '}
              {isAnnual ? `${annualPrice} per year` : `${monthlyPrice} per month`} starting on{' '}
              {calculateNextCharge(isAnnual)}. You can always cancel before then.
            </>
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SubscriptionCheckout;
