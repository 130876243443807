import React, {useEffect} from "react";
import {useParams} from "react-router";
import {useAppDispatch, useAppSelector} from "../../hooks";
import Spinner from "../Spinner";
import {accountRepository} from "../../domain";
import firebase from "firebase/compat/app";
import {openModal} from "../../store/reducers/modalConductor";
import {useHistory} from "react-router-dom";

interface Props {

}

const MobileNumberVerification: React.FC<Props> = (props: Props) => {
  const {  } = props;

  const { code } = useParams<{code: string}>();

  const { account } = useAppSelector(state => ({
    account: state.shops.account
  }));

  const history = useHistory();
  const dispatch = useAppDispatch();


  useEffect(() => {
    if (code && account && account.config?.messagingPreferences?.sms?.verificationCode === code) {
      accountRepository.updateProps(account.id, {
        'config.messagingPreferences.sms.enabled': true,
        'config.messagingPreferences.sms.verificationCode': firebase.firestore.FieldValue.delete()
      }).then(() => {
        dispatch(openModal({
          modalType: 'SIMPLE_ALERT',
          modalProps: {
            show: true,
            celebration: true,
            content: 'Your mobile number has been verified! You will now receive text notifications for new Orders and Quotes.',
            onClose: () => history.push('/store/notifications'),
          }
        }));
      });
    } else {
      dispatch(openModal({
        modalType: 'SIMPLE_ALERT',
        modalProps: {
          show: true,
          content: 'Invalid verification code.'
        }
      }));
      history.push('/store/notifications');
    }
  },[code, account]);

  return <>
    <Spinner show={true} size="fullscreen" label="Verifying your mobile number"/>
  </>;
}

export default MobileNumberVerification;
