import React, { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { nanoid } from '@reduxjs/toolkit';
import { Box, DialogActions, Grid, Paper } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { DeleteOutlined, PhotoLibraryOutlined } from '@material-ui/icons';
import { upload } from '@castiron/castiron-firebase';
import {
  Button,
  ButtonV2,
  Chip,
  CollapsableCard,
  Dropdown,
  DropDownOption,
  Forms,
  Typography,
} from '@castiron/components';
import { Asset, BaseProduct } from '@castiron/domain';
import clsx from 'clsx';
import { useFormikContext, FormikProps } from 'formik';
import _ from 'lodash';
import { closeModal, openModal } from '../../../../store/reducers/modalConductor';
import { MAX_UPLOAD_FILE_SIZE } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import Spinner from '../../../Spinner';
import AddImage from './AddImage';
import RearrangeModal from './RearrangeModal';
import { convertToAsset } from '../../../../lib/imageUtils';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { productRepository } from '../../../../domain';
import firebase from 'firebase/compat/app';
import { ProductModalProps } from '../../AddProductModal/AddProductModal';

export interface FormValues {
  images: Asset[];
}

const useStyles = makeStyles((theme: Theme) => ({
  bgColor: {
    /* hack to include alpha using theme color */
    backgroundColor: `${theme.branding.v2.blue[100]}7a`,
  },
  dragging: {
    backgroundColor: theme.branding.blue.light,
  },
  emptyContainer: {
    borderRadius: '16px',
    padding: '24px',
    [theme.breakpoints.up('md')]: {
      padding: '64px',
    },
  },
  emptyIcon: {
    color: theme.branding.v2.blue[200],
    height: '48px',
    width: '48px',
  },
  error: {
    color: theme.branding.v2.red[500],
  },
  icon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: theme.branding.v2.gray[0],
    borderRadius: '12px',
    padding: '5px',
  },
  imagesContainer: {
    width: '280px',
    [theme.breakpoints.only('sm')]: {
      width: '516px',
    },
    [theme.breakpoints.only('md')]: {
      width: '296px',
    },
    [theme.breakpoints.only('lg')]: {
      width: '416px',
    },
    [theme.breakpoints.up('xl')]: {
      width: '656px',
    },
  },
  footer: {
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    padding: '12px 24px',
  },
  menuContainer: {
    color: theme.branding.v2.gray[500],
    '&:hover': {
      color: theme.branding.v2.gray[800],
    },
    position: 'absolute',
    top: '16px',
    right: '16px',
    height: '40px',
    width: '40px',
  },
  menuContainerNonPrimary: {
    color: theme.branding.v2.gray[500],
    '&:hover': {
      color: theme.branding.v2.gray[800],
    },
    position: 'absolute',
    top: '4px',
    right: '4px',
    height: '40px',
    width: '40px',
  },
  nonPrimaryBox: {
    position: 'relative',
    borderRadius: '12px',
    width: '105px',
    height: '105px',
    [theme.breakpoints.only('sm')]: {
      height: '117px',
      width: '117px',
    },
    [theme.breakpoints.only('md')]: {
      height: '62px',
      width: '62px',
    },
    [theme.breakpoints.only('lg')]: {
      height: '92px',
      width: '92px',
    },
    [theme.breakpoints.up('xl')]: {
      height: '152px',
      width: '152px',
    },
  },
  nonPrimaryImage: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    borderRadius: '12px',
  },
  onlyContainer: {
    height: '400px',
    position: 'relative',
    display: 'inline-block',
  },
  primaryContainer: {
    position: 'relative',
    height: '250px',
    width: '250px',
    [theme.breakpoints.only('sm')]: {
      height: '250px',
      width: '250px',
    },
    [theme.breakpoints.only('md')]: {
      height: '140px',
      width: '140px',
    },
    [theme.breakpoints.only('lg')]: {
      height: '200px',
      width: '200px',
    },
    [theme.breakpoints.up('xl')]: {
      height: '320px',
      width: '320px',
    },
  },
  primaryImage: {
    borderRadius: '12px',
    objectFit: 'cover',
    height: '100%',
    width: '100%',
  },
  primaryLabel: {
    position: 'absolute',
    bottom: '16px',
    right: '16px',
    padding: '0px 8px 4px',
    borderRadius: '100px',
    zIndex: 1,
  },
}));

export interface Props extends ProductModalProps {
  product: BaseProduct;
  fromProductModal?: boolean;
}

const PhotoSection: React.FC<Props> = props => {
  const { product, fromProductModal = false, setTitle, setFooter, setStep, setStepNumber } = props;
  const classes = useStyles();
  const { errors, values, setFieldValue, submitForm, isSubmitting } = useFormikContext<FormValues>();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const [uploading, setUploading] = useState(false);
  const [rearrangeModalOpen, setRearrangeModalOpen] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [productModalError, setProductModalError] = useState(false);
  const [failedToUpload, setFailedToUpload] = useState<string[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (fromProductModal) {
      setTitle('Upload an Image');
      setStepNumber(3);
      setFooter(
        <DialogActions className={classes.footer}>
          <Grid container justify="flex-end" spacing={1}>
            <Grid item>
              <ButtonV2 variant="outlined" onClick={() => setStep('tagProduct')}>
                Back
              </ButtonV2>
            </Grid>
            <Grid item>
              <ButtonV2 variant="contained" onClick={() => submitForm()} loading={isSubmitting}>
                {product.type == 'standard' ? 'Create Product' : product.type == 'custom' ? 'Create Order Form' : 'Create Event'}
              </ButtonV2>
            </Grid>
          </Grid>
        </DialogActions>,
      );
    }
  }, []);

  const title = (
    <Grid container spacing={1}>
      <Grid item>
        <Typography variant="subtitle2">Photos</Typography>
      </Grid>
      <Grid item>
        <Chip colorScheme="gray">
          <Typography variant="subtitle2">{values.images.length}/9</Typography>
        </Chip>
      </Grid>
    </Grid>
  );

  const handleFile = async (file, handleFileUploadSuccess: (downloadUrl, metadata, options) => void) => {
    const id = nanoid();

    if (file) {
      const metadata = {
        assetType: 'product',
        id,
        shopId: shop.id,
        originalFilename: file.name,
        contentType: file.type,
        productId: product.id,
      };
      const options = {
        folder: `user/${shop.id}`,
      };
      const callbacks = {
        success: handleFileUploadSuccess,
      };

      upload(file, metadata, options, callbacks, {});
    }
  };

  const toManyFilesError = () =>
    dispatch(
      openModal({
        modalType: 'GENERAL_MODAL',
        modalProps: {
          show: true,
          title: 'Photo Limit Exceeded',
          content:
            `A${product.type === 'event' ? 'n event' : ' product'} can support a maximum of 9 photos. We recommend decreasing the number of photos you upload, or removing some existing photos before trying again.`,
          actions: [
            <Button
              variant="contained"
              onClick={() => {
                dispatch(closeModal());
              }}
            >
              Dismiss
            </Button>,
          ],
        },
      }),
    );

  const handleCroppedImage = async (
    croppedImage,
    oldImage,
    onSuccess: (downloadUrl, metadata, options, context) => Promise<void>,
  ) => {
    const id = nanoid();
    const metadata = {
      id: id,
      contentType: oldImage?.metadata?.contentType || 'image/jpeg',
      assetType: 'product',
      shopId: shop.id,
      originalFilename: oldImage?.metadata?.originalFilename || id,
      productId: product.id,
    };

    const callbacks = {
      success: onSuccess,
    };

    const options = {
      folder: `/user/${shop.id}`,
      filename: oldImage?.photo?.metadata?.originalFilename || id,
    };

    const context = {
      shop,
    };

    upload(croppedImage, metadata, options, callbacks, context);
  };

  const handleFiles = (files: any[]) => {
    setProductModalError(false);
    if (files.some(file => file.size > MAX_UPLOAD_FILE_SIZE)) {
      setFileSizeError(true);
      return;
    } else {
      setFileSizeError(false);
    }
    if (files.length == 0) {
      if (!fromProductModal) {
        toManyFilesError();
        return;
      } else {
        setProductModalError(true);
        return;
      }
    }
    /* only doing successes right now, will likely end with a never ending wait if something errors
     * maybe add a timeout later and message which images aren't in completed so the user can retry them?
     */

    const completed = [];

    const waitForImageUploadsAndSetValues = (attempts: number = 0) =>
      setTimeout(() => {
        if (
          _.intersection(
            completed.map(file => file.metadata.originalFilename),
            files.map(file => file.name),
          ).length === files.length
        ) {
          setFieldValue('images', [...values.images, ...completed]);
          setUploading(false);
          /* 10 seconds */
        } else if (attempts > 100) {
          setFieldValue('images', [...values.images, ...completed]);
          setUploading(false);
          setFailedToUpload(
            _.difference(
              files.map(file => file.name),
              completed.map(file => file.metadata.originalFilename),
            ),
          );
        } else {
          waitForImageUploadsAndSetValues(attempts + 1);
        }
      }, 100);

    if (files.length === 1 && !fromProductModal) {
      const tempAsset = convertToAsset(URL.createObjectURL(files[0]), 'product', files[0].name, shop, files[0].type);
      dispatch(
        openModal({
          modalType: 'EDIT_PHOTO_MODAL',
          modalProps: {
            show: true,
            imageLocation: '',
            imageObj: tempAsset,
            onClose: croppedImage => {
              setUploading(true);
              dispatch(closeModal());
              handleCroppedImage(croppedImage, tempAsset, async (downloadUrl, metadata, options, context) => {
                const image = { id: metadata.id, downloadUrl, metadata, options };
                completed.push(image);
              });
              waitForImageUploadsAndSetValues();
            },
            onCancel: () => {
              dispatch(closeModal());
            },
          },
        }),
      );
    } else {
      setUploading(true);
      setFailedToUpload([]);
      files.forEach(file =>
        handleFile(file, (downloadUrl, metadata, options) => {
          const image = { id: metadata.id, downloadUrl, metadata, options };
          completed.push(image);
        }),
      );
      waitForImageUploadsAndSetValues();
    }
  };

  const maxFiles = fromProductModal ? 1 : 9 - values.images.length;
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop: handleFiles,
    maxFiles: maxFiles,
    multiple: maxFiles > 1,
  });

  const imageUrl = (asset: Asset) => asset.mediumVersion?.downloadUrl || asset.downloadUrl;

  const imageDropdown = (image: Asset, primaryImage: boolean) => {
    const menuOptions: DropDownOption[] = [
      // Commenting this out for now to get this out
      // {
      //   label: 'Rearrange Photos',
      //   icon: <DragIndicator />,
      //   onClick: () => setRearrangeModalOpen(true),
      // },
      {
        label: 'Edit Photo',
        icon: <CreateOutlinedIcon />,
        onClick: () => {
          dispatch(
            openModal({
              modalType: 'EDIT_PHOTO_MODAL',
              modalProps: {
                show: true,
                imageLocation: '',
                imageObj: image,
                onClose: croppedImage => {
                  dispatch(closeModal());
                  handleCroppedImage(croppedImage, image, async (downloadUrl, metadata, options, context) => {
                    const img = { id: metadata.id, downloadUrl, metadata, options, shopId: shop.id };
                    const images = values.images;
                    const index = values.images.findIndex(
                      i => i.metadata.originalFilename === metadata.originalFilename,
                    );
                    images[index] = img;
                    setFieldValue('images', images);
                    setUploading(false);
                  });
                },
                onCancel: () => {
                  dispatch(closeModal());
                },
              },
            }),
          );
        },
      },
      {
        label: 'Delete',
        icon: <DeleteOutlined />,
        onClick: async () => {
          const currentProduct = await productRepository.get(product.id);
          const imageObjectToRemove = currentProduct?.images?.find(currImage => image.id === currImage.id);
          setFieldValue(
            'images',
            values.images.filter(valueImage => image.id !== valueImage.id),
          );
          await productRepository.updateProps(product.id, {
            images: firebase.firestore.FieldValue.arrayRemove(imageObjectToRemove),
          });
        },
        color: 'error',
      },
    ];
    return (
      <Paper className={primaryImage ? classes.menuContainer : classes.menuContainerNonPrimary}>
        <Grid container style={{ height: '100%' }} justify="center" alignItems="center">
          <Grid item>
            <Dropdown
              variant="ellipsis"
              options={menuOptions}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const nonPrimaryImage = (image?: Asset) =>
    image ? (
      <Box className={classes.nonPrimaryBox}>
        <img src={imageUrl(image)} className={classes.nonPrimaryImage} />
        {imageDropdown(image, false)}
      </Box>
    ) : (
      <AddImage maxFiles={maxFiles} handleFiles={handleFiles} />
    );

  const ImageDisplay = (
    <>
      {!!values.images.length && fromProductModal && (
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box className={classes.onlyContainer}>
                    <img className={classes.primaryImage} src={imageUrl(values.images[0])} />
                    <Grid className={classes.icon}>
                      <Dropdown
                        variant="ellipsis"
                        options={[
                          {
                            label: 'Delete',
                            icon: <DeleteOutlined />,
                            onClick: async () => {
                              const currentProduct = await productRepository.get(product.id);
                              const imageObjectToRemove = currentProduct?.images?.find(
                                currImage => values.images[0].id === currImage.id,
                              );
                              setFieldValue(
                                'images',
                                values.images.filter(valueImage => values.images[0].id !== valueImage.id),
                              );
                              await productRepository.updateProps(product.id, {
                                images: firebase.firestore.FieldValue.arrayRemove(imageObjectToRemove),
                              });
                            },
                            color: 'error',
                          },
                        ]}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                      />
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
      {!!values.images.length && !fromProductModal && (
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Box className={classes.imagesContainer}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box className={classes.primaryContainer}>
                    <img className={classes.primaryImage} src={imageUrl(values.images[0])} />
                    <Paper elevation={3} className={classes.primaryLabel}>
                      <Typography variant="caption">Primary</Typography>
                    </Paper>
                    {imageDropdown(values.images[0], true)}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    {[1, 2, 3, 4].map(index => (
                      <Grid item xs={6} key={`nonPrimaryImage${index}`}>
                        {nonPrimaryImage(values.images[index])}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                {values.images.length >= 5 &&
                  [5, 6, 7, 8].map(index => (
                    <Grid item xs={6} sm={3} key={`nonPrimaryImage${index}`}>
                      {nonPrimaryImage(values.images[index])}
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
      {!values.images.length && (
        <div
          {...getRootProps({ className: `dropzone ${isDragActive ? classes.dragging : ''}` })}
          style={{ height: '100%' }}
        >
          <Box className={clsx([classes.emptyContainer, classes.bgColor])}>
            <input {...getInputProps()} />
            <Grid container direction="column" alignItems="center" spacing={1}>
              <Grid item>
                <PhotoLibraryOutlined className={classes.emptyIcon} />
              </Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <Typography variant="button" style={{ textAlign: 'center' }}>
                      Drop Your Photos Here
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">JPEG, JPG, or PNG accepted</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="body2">OR</Typography>
              </Grid>
              <Grid item>
                <Button variant="contained">Browse Photos</Button>
              </Grid>
            </Grid>
          </Box>
        </div>
      )}
      {errors.images && <Forms.SubmissionError msg={errors.images} />}
      {fileSizeError && <Forms.SubmissionError msg="Images must be under 10MB" />}
      {failedToUpload.length > 0 && (
        <Forms.SubmissionError
          msg={`Failed to upload the following images: ${failedToUpload.join(',')}. Please try again.`}
        />
      )}
    </>
  );

  return (
    <>
      {fromProductModal ? (
        <>
          <Grid container direction="column" spacing={2} style={{ marginBottom: 10 }}>
            <Grid item>
              <Typography variant="body1">
                {product.type === 'event' ? '' : 'Make it even prettier now. '}You can add more images later.
              </Typography>
              {productModalError && (
                <Typography variant="body1" className={classes.error}>
                  Can only drop one image.
                </Typography>
              )}
            </Grid>
          </Grid>
          {ImageDisplay}
        </>
      ) : (
        <CollapsableCard title={title} defaultExpanded={true} noScroll>
          <Spinner size="fullscreen" show={uploading} label="Uploading Image(s)" />
          <RearrangeModal open={rearrangeModalOpen} close={() => setRearrangeModalOpen(false)} />
          {ImageDisplay}
        </CollapsableCard>
      )}
    </>
  );
};

export default PhotoSection;
