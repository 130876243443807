import React, { useEffect, useState, ReactNode } from 'react';
import { CircularProgress, DialogActions, DialogContent, DialogTitle, Grid, useMediaQuery, IconButton } from '@material-ui/core';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import { Formik } from 'formik';
import * as yup from 'yup';
import {Typography, TextInput, ButtonV2, Banner, useFeatures} from '@castiron/components';
import { useTracking } from '@castiron/utils';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { closeModal, openModal } from '../../../../store/reducers/modalConductor';
import ModalWrapper from '../../../RootModal/ModalWrapper';
import AdminForm from '../../../AdminForm';
import { shopRepository } from '../../../../domain';
import ModalHeader from '../../../RootModal/ModalHeader';
import { useConfig } from '@castiron/castiron-firebase';
import { existingAnnualUser } from '../StartDomainConnectModal';


export type Props = {
  show?: boolean;
};


const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    borderTop: `1px solid ${theme.branding.gray[400]}`,
    padding: '8px 24px',
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      padding: '16px 16px 50px',
    }
  },
  content: {
    padding: 24
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
    padding: 5
  },
  errorBox: {
    marginBottom: 24
  },
  errorMessage: {
    color: theme.branding.red.dark
  },
  loadingSpinner: {
    color: theme.branding.gray[100],
  },
  modalWrapper: {
    [theme.breakpoints.up('sm')]: {
      width: 504
    }
  },
  nextButtonLoading: {
    padding: '12px 45px'
  }
}));

const AddDomainModal: React.FC<Props> = (props: Props) => {
  const { show } = props;

  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();
  const ffconfig = useConfig();

  const domainPattern = /^(?:\*\.)?[a-z0-9]+(?:[\-.][a-z0-9]+)*\.[a-z]{2,6}$/;
  const domainSchema = yup.object().shape({
    domain: yup
      .string()
      .matches(domainPattern, { message: 'Please enter a valid domain in one of the following formats: domain.com, www.domain.com, or subdomain.domain.com' })
  })

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { account, shop, userState } = useAppSelector(state => ({
    account: state.shops.account,
    shop: state.shops.shop,
    userState: state.shops.userState,
  }));

  const hasFreeDomainPerm = useFeatures().includes('shop.freeDomain');

  const isFreeCustomDomainsEnabled = ffconfig?.featureFlag('feature_free_custom_domains', shop) && hasFreeDomainPerm;
  const isExistingAnnualUser = existingAnnualUser(account?.subscription);

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    trackEvent('Custom Domain Added');

    let formattedDomain;
    let domainName = values.domain;
    let domainExists = await shopRepository.findByDomainName(domainName);

    if (domainName.startsWith('www.')) {
      formattedDomain = domainName.slice(4);
    }

    if (!domainExists) {
      dispatch(
        openModal({
          modalType: 'VERIFY_DOMAIN_MODAL',
          modalProps: {
            show: true,
            domainName: formattedDomain || domainName,
          },
        }),
      );
    } else {
      setErrorMessage('This domain is connected to another Castiron account.')
    }

    setLoading(false);
  }

  const openDomainModal = () => {
    dispatch(
      openModal({
        modalType: 'START_DOMAIN_CONNECT_MODAL',
        modalProps: {
          show: true,
        },
      }),
    );
  };

  return (
    <ModalWrapper fullScreen={isMobile} show={show} onClose={handleClose} paperClass={classes.modalWrapper}>
      <ModalHeader backClick={(isFreeCustomDomainsEnabled && !isExistingAnnualUser && userState !== 'legacyNewSubscriber') ? openDomainModal : undefined} title="Connect a Domain" handleClose={handleClose} />
      <Formik
        initialValues={{ domain: '' }}
        validationSchema={domainSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched }): ReactNode => (
          <AdminForm>
            <DialogContent className={classes.content}>
              {!!errorMessage &&
                <Banner variant='error' className={classes.errorBox}>
                  <Typography className={classes.errorMessage}>{errorMessage}</Typography>
                </Banner>
              }
              <TextInput
                label={<Typography variant='button'>Domain</Typography>}
                name='domain'
                placeholder='e.g. domain.com'
                error={touched.domain && errors.domain}
              />
            </DialogContent>
            <DialogActions className={classes.actions}>
              <ButtonV2
                type='submit'
                disabled={values.domain === ''}
                variant='contained'
                className={`${loading && classes.nextButtonLoading}`}
                fullWidth={isMobile}
              >
                {loading ? <CircularProgress className={classes.loadingSpinner} size={32} /> : 'Next'}
              </ButtonV2>
            </DialogActions>
          </AdminForm>
        )}
      </Formik>
    </ModalWrapper>
  );
};

export default AddDomainModal;
