import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { transactions } from '@castiron/castiron-firebase';
import { transactionRepository } from '../../domain';
import { handleReducerError } from './errorHandler';
import { Transaction } from '@castiron/domain';

export interface TransactionsState {
  transactions: Transaction[];
  historicalTransactions: any[];
  loading: boolean;
  error: string;
}

const initialState: TransactionsState = {
  transactions: undefined,
  historicalTransactions: [],
  loading: false,
  error: '',
};

const listTransactions = async (id: string) => {
  const response = await transactionRepository.getStandardTransactions(id);
  console.log('response from listTransactions: ', response);
  return response;
};

const listCustomTransactions = async (id: string) => {
  const response = await transactionRepository.getCustomTransactions(id);
  return response;
};

const listAllTransactions = async (id: string) => {
  const transactions = await transactionRepository.findByShopId(id);
  return transactions;
};

const getTransactionById = async (id: string) => {
  const response = await transactions.get(id);
  return response;
};

const createTransaction = async transaction => {
  const response = await transactions.create(transaction);
  return response;
};

const updateTransaction = async transaction => {
  const response = await transactions.update(transaction);
  return response;
};

export const listTransactionsAction = createAsyncThunk('transactions/listTransactions', listTransactions);
export const listCustomTransactionsAction = createAsyncThunk(
  'transactions/listCustomTransactions',
  listCustomTransactions,
);
export const listAllTransactionsAction = createAsyncThunk('transactions/listAllTransactions', listAllTransactions);
export const createTransactionAction = createAsyncThunk('transactions/createTransaction', createTransaction);
export const updateTransactionAction = createAsyncThunk('transactions/updateTransaction', updateTransaction);
export const getTransactionByIdAction = createAsyncThunk('transactions/getTransactionById', getTransactionById);

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(listTransactionsAction.pending, state => {
        state.loading = true;
      })
      .addCase(listTransactionsAction.fulfilled, (state, action) => {
        state.transactions = action.payload;
        state.loading = false;
      })
      .addCase(listTransactionsAction.rejected, handleReducerError('Error Getting Tansactions'))
      .addCase(listCustomTransactionsAction.pending, state => {
        state.loading = true;
      })
      .addCase(listCustomTransactionsAction.fulfilled, (state, action) => {
        state.transactions = [...state.transactions, ...action.payload];
        state.loading = false;
      })
      .addCase(listAllTransactionsAction.pending, state => {
        state.loading = true;
      })
      .addCase(listAllTransactionsAction.fulfilled, (state, action) => {
        state.transactions = action.payload;
        state.loading = false;
      })
      .addCase(listCustomTransactionsAction.rejected, handleReducerError('Error Getting Custom Tansactions'));
  },
});

export default transactionsSlice.reducer;
