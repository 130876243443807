import React, { ReactNode } from "react";
import { Box, DialogTitle, Grid, IconButton } from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { BackButton, Typography } from "@castiron/components";
import { Close } from "@material-ui/icons";

interface Props {
  backClick?: () => void;
  title?: ReactNode;
  handleClose: () => void;
};

const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  headerContainer: {
    background: theme.branding.v2.gray[0],
    borderBottom: props => props.title && `1px solid ${theme.branding.v2.gray[200]}`,
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  }
}));

const ModalHeader: React.FC<Props> = (props: Props) => {
  const { backClick, title, handleClose } = props;
  const classes = useStyles(props);

  const displayTitle = typeof title === 'string' ? <Typography variant="h3">{title}</Typography> : title;

  return (
    <DialogTitle className={classes.headerContainer}>
      <Grid container justify={backClick || displayTitle ? "space-between" : "flex-end"} alignItems="center" wrap="nowrap">
        {backClick && (
          <Grid item>
            <BackButton action={backClick} />
          </Grid>
        )}
        {displayTitle && <Grid item>{displayTitle}</Grid>}
        <Grid item>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    </DialogTitle>
  );
};

export default ModalHeader;