import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Button, FileUploadIcon } from '@castiron/components';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { getCustomersAction } from '../../store/reducers/customers';
import { openModal } from '../../store/reducers/modalConductor';
import CustomerList from './CustomerList';
import { LayoutPageProps } from '../Layout';
import AvatarMenu from '../Menus/AvatarMenu';
import Spinner from '../Spinner';
import EllipsisMenu, { EllipsisMenuOption } from '../Menus/EllipsisMenu';
import { EmptyContactsIcon } from '@castiron/components/src/Icons';
import EmptyPage, { TipsForYou } from '../EmptyPage';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
  },
  icon: {
    height: 120,
    width: 160,
  },
  headerButton: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}));

const Customers: React.FC<LayoutPageProps> = (props: LayoutPageProps) => {
  const { setPageTitle, setBackLocation, setHeaderCTAs, setFooterCTAs } = props;

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const history = useHistory();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { shop, isCustomersLoading, customers } = useAppSelector(state => ({
    isCustomersLoading: state.customers.loading,
    shop: state.shops.shop,
    customers: state.customers.customers,
  }));

  const openExportModal = () => {
    dispatch(
      openModal({
        modalType: 'EXPORT_CONTACT_LIST_MODAL',
        modalProps: {
          show: true,
        },
      }),
    );
  };

  const ellipsisOptions: EllipsisMenuOption[] = [
    {
      display: 'Export Contact List',
      icon: <FileUploadIcon />,
      action: openExportModal,
    },
  ];

  useEffect(() => {
    setPageTitle('Contacts');

    return () => {
      setPageTitle('');
    };
  }, []);

  useEffect(() => {
    if (shop?.id) dispatch(getCustomersAction(shop.id));
  }, [shop]);

  useEffect(() => {
    const isCustomersEmpty = customers.length === 0;
    if (isMobile) {
      if (isCustomersEmpty) {
        setHeaderCTAs([<AvatarMenu />]);
        setFooterCTAs([
          <Button onClick={() => history.push('/contacts/add')} fullWidth variant="contained">
            Add Contacts
          </Button>,
        ]);
      } else {
        setHeaderCTAs([<EllipsisMenu options={ellipsisOptions} />, <AvatarMenu />]);
      }
    } else {
      setHeaderCTAs([
        !isCustomersEmpty && (
          <Button variant="outlined" onClick={openExportModal} className={classes.headerButton}>
            Export Contact List
          </Button>
        ),
        <Button variant="contained" onClick={() => history.push('/contacts/add')} className={classes.headerButton}>
          Add Contacts
        </Button>,
      ]);
    }
  }, [isMobile, customers]);

  const tipsForYou: TipsForYou[] = [
    {
      icon: 'article',
      title: 'Start or Grow Your Contact List',
      description: 'Learn 5 ways to grow your contact list. Fast.',
      ctaAction: () =>
        window.open('https://castiron.me/blog/how-to-build-an-email-list-for-your-food-business', '_blank').focus(),
    },
  ];

  const emptyContacts = () => (
    <Grid className={classes.headerButton}>
      <EmptyPage
        icon={<EmptyContactsIcon className={classes.icon} />}
        title="Add Contacts"
        description="Add your contact list to Castiron to include them in email marketing campaigns. "
        ctaAction={() => window.open('/contacts/add', '_self').focus()}
        ctaText="Add Contacts"
        tipsForYou={tipsForYou}
        page="Contacts"
      />
    </Grid>
  );

  return (
    <Grid justify="center" className={classes.container} container>
      <Helmet>
        <title>Contacts | Castiron</title>
      </Helmet>
      <Spinner show={isCustomersLoading} />
      {customers.length === 0 ? (
        emptyContacts()
      ) : (
        <CustomerList
          customerData={customers}
          pageSize={15}
          sortModel={[{ field: 'lastOrder', sort: 'desc' }]}
          isMobile={isMobile}
          showAnalytics={true}
          setFooterCTAs={setFooterCTAs}
          isSubcomponent={false}
          source="customers"
        />
      )}
    </Grid>
  );
};

export default Customers;
