import React, {useEffect} from "react";
import {useFormikContext} from "formik";


const LogFormikErrors: React.FC = () => {

  const formik = useFormikContext();

  useEffect(() => {
      if (!formik.isValid) {
        console.debug('Formik Errors', formik.errors);
      }
  },[formik]);

  return <></>;
}

export default LogFormikErrors;
