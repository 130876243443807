import React, { ReactElement, } from "react";
import { useHistory } from "react-router-dom";
import { Tooltip, Typography } from "@material-ui/core";
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useFeatures } from "@castiron/components";
import { FeatureName } from "@castiron/domain";
import { useTracking } from "@castiron/utils";

interface Props {
  feature?: FeatureName;
  featureName: string;
  children: ReactElement;
};

const useStyles = makeStyles((theme: Theme) => ({
  upgradeLink: {
    color: 'inherit',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const FeatureTooltip: React.FC<Props> = (props: Props) => {
  const { feature, featureName, children } = props;
  const classes = useStyles();
  const features = useFeatures();
  const history = useHistory();
  const { trackEvent } = useTracking();

  const onUpgradeClick = () => {
    trackEvent('Gated Feature Upgrade Clicked', { feature: featureName });
    history.push('/store/plans');
  };

  const title = (
    <Typography variant='caption' style={{ color: 'inherit' }}>
      <span onClick={onUpgradeClick} className={classes.upgradeLink}>
        Upgrade to Pro
      </span> to unlock {featureName} and many other features.
    </Typography>
  );

  return feature && !features.includes(feature) ? (
    <Tooltip title={title} arrow enterTouchDelay={0} leaveDelay={500} interactive>
      {children}
    </Tooltip>
  ) : (
    children
  );
};

export default FeatureTooltip;