import React, { MouseEvent, MutableRefObject, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Hidden } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { FormikProps } from 'formik';
import { backendStateToFrontendState, FrontendTransactionState, Transaction } from '@castiron/domain';
import { useTracking } from '@castiron/utils';
import { getService } from '../../firebase';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { openModal } from '../../store/reducers/modalConductor';
import { listCustomTransactionsAction, listTransactionsAction } from '../../store/reducers/transactions';
import { transactionRepository } from '../../domain';
import Dropdown, { DropDownOption } from '../Dropdown';
import EllipsisMenu, { EllipsisMenuOption } from '../Menus/EllipsisMenu';
import Spinner from '../Spinner';
import { QuoteValues } from './EditQuote';
import { availableQuoteActions, prepareQuoteSegmentData, validateQuote } from './QuoteUtils';

type Props = {
  transaction: Transaction;
  editing: boolean;
  formikRef: MutableRefObject<FormikProps<QuoteValues>>;
};

const sendQuoteUpdatedService = getService('orders', 'sendquoteupdatedemail');

const QuoteActionsDropdown: React.FC<Props> = (props: Props) => {
  const { transaction, editing, formikRef } = props;

  const history = useHistory();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTracking();

  const { shop } = useAppSelector(state => ({
    shop: state.shops.shop,
  }));

  const [showSpinner, setShowSpinner] = useState(false);

  const trackAction = (action: string): void => {
    trackEvent('Quote Action Clicked', {
      action,
      url: window.location.href,
      ...prepareQuoteSegmentData(transaction),
    });
  };

  const existingStatus = backendStateToFrontendState(transaction, 'quote');
  const trackStatusChange = (newStatus: FrontendTransactionState): void => {
    trackEvent('Quote Status Changed', {
      url: window.location.href,
      previousStatus: existingStatus,
      newStatus,
      ...prepareQuoteSegmentData(transaction),
    });
  };

  const actions = availableQuoteActions(transaction)
    .filter(a => !(editing && a === 'sendQuote'))
    .filter(a => !(a == 'markAsPaid' && formikRef?.current?.values?.subTransactions?.hasSubTransactions));

  const formikForm = formikRef.current;

  const sendQuote = async (event: MouseEvent<HTMLButtonElement>) => {
    const sendQuoteTx = Sentry.startTransaction({
      op: 'submit',
      name: 'Send Quote',
    });
    Sentry.getCurrentHub().configureScope(scope => {
      scope.setSpan(sendQuoteTx);
      scope.setUser({
        id: shop.id,
        email: shop.email,
        username: shop.websiteUrl,
      });
    });

    if (editing && formikForm) {
      const span = sendQuoteTx.startChild({
        op: 'submit',
        description: 'Submit Form',
      });
      await formikForm.submitForm();
      span.finish();
    }

    /* I hate that I'm doing this but there is no guarantee the transaction in the props is up to date enough for our purposes here
     * get the most recent from the source of truth after the form is submitted
     */
    const upToDateTransaction = await transactionRepository.get(transaction.id);

    trackAction('sendQuote');
    if (validateQuote(upToDateTransaction).length > 0) {
      history.push(`/quotes/edit/${upToDateTransaction.id}?error=missingRequired`);
      window.scrollTo(0, 0);
      return;
    }
    const subtotal = upToDateTransaction.totals?.subtotal || 0;
    if (subtotal < 50) {
      history.push(`/quotes/edit/${upToDateTransaction.id}?error=illegalTotal`);
      window.scrollTo(0, 0);
      return;
    }

    dispatch(
      openModal({
        modalType: 'QUOTE_SEND_MODAL',
        modalProps: {
          show: true,
          transaction: upToDateTransaction,
        },
      }),
    );
  };

  const resendQuote = async (event: MouseEvent<HTMLButtonElement>) => {
    trackAction('resendQuote');

    if (editing && formikForm) {
      /* this should do everything we do in the else below for us */
      await formikForm.submitForm();
    } else {
      setShowSpinner(true);
      if (validateQuote(transaction).length > 0) {
        history.push(`/quotes/edit/${transaction.id}?error=missingRequired`);
        window.scrollTo(0, 0);
        return;
      }
      const subtotal = transaction.totals?.subtotal || 0;
      if (subtotal < 0.5) {
        history.push(`/quotes/edit/${transaction.id}?error=illegalTotal`);
        window.scrollTo(0, 0);
        return;
      }

      try {
        await sendQuoteUpdatedService({ transactionId: transaction.id });
        setShowSpinner(false);
      } catch (error) {
        setShowSpinner(false);
      }
    }
  };

  const openContactModal = (event: MouseEvent<HTMLButtonElement>): void => {
    trackAction('sendMessage');
    dispatch(
      openModal({
        modalType: 'CONTACT_MODAL',
        modalProps: {
          show: true,
          email: transaction.customerObj?.email,
          customerId: transaction.customerObj?.id,
        },
      }),
    );
  };

  const cancel = async (event: MouseEvent<HTMLButtonElement>) => {
    trackAction('cancel');
    dispatch(
      openModal({
        modalType: 'REJECT_CUSTOM_ORDER',
        modalProps: {
          show: true,
          transaction,
        },
      }),
    );
  };

  const setArchived = (isArchived: boolean) => async (event: MouseEvent<HTMLButtonElement>) => {
    trackAction(isArchived ? 'archive' : 'unarchive');
    await transactionRepository.updateProps(transaction.id, {
      isArchived: isArchived,
    });
    /* hack a new transaction here to confirm new status */
    trackStatusChange(backendStateToFrontendState({ ...transaction, isArchived }, 'quote'));
    dispatch(listTransactionsAction(shop.id));
    dispatch(listCustomTransactionsAction(shop.id));
    if (isArchived) {
      history.push('/quotes');
    }
  };

  const viewQuote = (event: MouseEvent<HTMLButtonElement>) => {
    trackAction('view');
    history.push(`/orders/edit/${transaction.id}`);
  };

  const markAsPaid = async event => {
    await formikForm.setFieldValue('markedAsPaid', true);
    await formikForm.submitForm();
    const newTx = await transactionRepository.get(transaction.id);
    dispatch(
      openModal({
        modalType: 'MARK_AS_PAID_MODAL',
        modalProps: {
          show: true,
          transaction: newTx,
        },
      }),
    );
  };

  const actionDropdownMapping = {
    sendQuote: {
      label: `${editing ? 'Save and s' : 'S'}end quote`,
      onClick: sendQuote,
    },
    resendQuote: {
      label: `${editing ? 'Save and r' : 'R'}e-send quote`,
      onClick: resendQuote,
    },
    sendMessage: {
      label: 'Send message to customer',
      onClick: openContactModal,
    },
    cancel: {
      label: 'Cancel quote',
      onClick: cancel,
    },
    archive: {
      label: 'Archive quote',
      onClick: setArchived(true),
    },
    unarchive: {
      label: 'Unarchive quote',
      onClick: setArchived(false),
    },
    view: {
      label: 'View order',
      onClick: viewQuote,
    },
    markAsPaid: {
      label: 'Mark As Paid',
      onClick: markAsPaid,
    },
  };
  /* in hindsight, these (dropdown and ellipsis) should probably use the same props to simplify this */
  const actionEllipsisMapping = {
    sendQuote: {
      display: `${editing ? 'Save and s' : 'S'}end quote`,
      action: sendQuote,
    },
    resendQuote: {
      display: `${editing ? 'Save and r' : 'R'}e-send quote`,
      action: resendQuote,
    },
    sendMessage: {
      display: 'Send message to customer',
      action: openContactModal,
    },
    cancel: {
      display: 'Cancel quote',
      action: cancel,
    },
    archive: {
      display: 'Archive quote',
      action: setArchived(true),
    },
    unarchive: {
      display: 'Unarchive quote',
      action: setArchived(false),
    },
    view: {
      display: 'View order',
      action: viewQuote,
    },
    markAsPaid: {
      display: 'Mark As Paid',
      action: markAsPaid,
    },
  };

  /*
   * Save exists as an action. I don't think it needs to, but too much to do already.
   * Filtering out undefineds for now, will take a closer look next time I'm here
   */
  const actionMenuDropDownOptions = actions
    .map(action => actionDropdownMapping[action] as DropDownOption)
    .filter(a => !!a);
  const actionMenuEllipsisOptions = actions
    .map(action => actionEllipsisMapping[action] as EllipsisMenuOption)
    .filter(a => !!a);

  return (
    <>
      <Spinner show={showSpinner} size="fullscreen" />
      <Hidden smDown>
        <Dropdown title="Actions" options={actionMenuDropDownOptions} />
      </Hidden>
      <Hidden mdUp>
        <EllipsisMenu options={actionMenuEllipsisOptions} />
      </Hidden>
    </>
  );
};

export default QuoteActionsDropdown;
